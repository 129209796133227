import React from "react";
import * as ANTD from "antd";
import "../TransportCheckout/TransportCheckout.scss";
import { MailOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';

const TransportCheckout = () => {
    const { Option } = ANTD.Select;
    function onChange(date, dateString) {
        console.log(date, dateString);
    }
    return (
        <div>
            <div className="transport-checkout-container">
                <ANTD.Row gutter={[16, 16]}>
                    <ANTD.Col xs={24} md={17}>
                        <ANTD.Card>
                            <ANTD.Row gutter={16} className="transport-card">
                                <ANTD.Col md={4} className="bus-img">
                                    <img src={require('../../../assets/images/transport/transportbus.png').default} alt="" />
                                </ANTD.Col>
                                <ANTD.Col md={20}>
                                    <ul>
                                        <li className="transport-company-name">
                                            <p className="company-name">Saudi Public Transport Company</p>
                                            <p className="class-type">Normal</p>
                                        </li>
                                        <li className="transport-company-name">
                                            <p><MailOutlined /> marketing-mak@saptco.com.sa</p>
                                            <p><PhoneOutlined /> 12520300</p>
                                        </li>
                                        <li className="transport-company-name">
                                            <p><EnvironmentOutlined /> Jeddah - Makkah - Madinah - Madinah Airport</p>
                                        </li>
                                        <li className="transport-company-name">
                                            <p>Vehicle : Bus</p>
                                            <p>Transfer Type : Public Transportation</p>
                                        </li>
                                    </ul>
                                </ANTD.Col>
                            </ANTD.Row>
                            <div className="description-block">
                                <div className="description-card">
                                    <div className="description-text">
                                        <p><span>Company description</span> Transportation Companies for Umra</p>
                                    </div>
                                    <div className="view-more-btn">
                                        <button>view more</button>
                                    </div>
                                </div>
                            </div>
                        </ANTD.Card>
                        <ANTD.Card title="group leader information" style={{ marginTop: "20px" }}>
                            <ANTD.Form
                                layout="vertical"
                            >
                                <ANTD.Row gutter={16}>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item label="Title :" required>
                                            <ANTD.Select
                                                placeholder="Select"
                                            >
                                                <Option value="male">Mr.</Option>
                                                <Option value="female">Ms.</Option>
                                            </ANTD.Select>
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item
                                            label="Given Name :" required
                                        >
                                            <ANTD.Input placeholder="Enter first name" />
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item
                                            label="Surname :" required
                                        >
                                            <ANTD.Input placeholder="Enter Surname" />
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item
                                            label="Date of birth :" required
                                        >
                                            <ANTD.DatePicker onChange={onChange} placeholder="select date of birth" style={{ width: "100%" }} />
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item label="Nationality :">
                                            <ANTD.Select
                                                placeholder="Select"
                                            >
                                                <Option value="india">India</Option>
                                                <Option value="dubai">Dubai</Option>
                                                <Option value="saudi">Saudi</Option>
                                            </ANTD.Select>
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                    <ANTD.Col xs={24} md={8}>
                                        <ANTD.Form.Item
                                            label="Customer Reference No. :"
                                        >
                                            <ANTD.Input placeholder="Customer Reference No." />
                                        </ANTD.Form.Item>
                                    </ANTD.Col>
                                </ANTD.Row>
                                <div className="flight-booking-details-form">
                                    <div className="flight-booking-details-header">
                                        <h2>Flight booking details</h2>
                                    </div>
                                    <ANTD.Row gutter={16}>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Flight Number :" required
                                            >
                                                <ANTD.Input placeholder="Enter Flight Number" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Flight Date :"
                                            >
                                                <ANTD.DatePicker onChange={onChange} placeholder="Enter Flight Date" style={{ width: "100%" }} />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Flight Time :"
                                            >
                                                <ANTD.Input placeholder="Enter Flight time" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Arrival Airport Code :"
                                            >
                                                <ANTD.Input placeholder="Enter Airport Code" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Passport Number :" required
                                            >
                                                <ANTD.Input placeholder="Enter Passport Number" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                    </ANTD.Row>
                                </div>
                                <div className="flight-booking-details-form">
                                    <div className="flight-booking-details-header">
                                        <h2>Hotel booking details</h2>
                                    </div>
                                    <ANTD.Row gutter={16}>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Hotel Name :"
                                            >
                                                <ANTD.Input placeholder="Enter Hotel Name" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Hotel Contact No. :"
                                            >
                                                <ANTD.Input placeholder="Enter Contact No." />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Col xs={24} md={8}>
                                            <ANTD.Form.Item
                                                label="Hotel Email ID:"
                                                name="email"
                                            >
                                                <ANTD.Input placeholder="Enter Email ID" />
                                            </ANTD.Form.Item>
                                        </ANTD.Col>
                                        <ANTD.Form.Item name="remember" valuePropName="checked">
                                            <ANTD.Checkbox>I accept the <span style={{ color: "#0775e2" }}>Terms and Conditions</span></ANTD.Checkbox>
                                        </ANTD.Form.Item>
                                    </ANTD.Row>
                                </div>
                                <ANTD.Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
                                    <ANTD.Button type="primary">Proceed to Pay</ANTD.Button>
                                </ANTD.Form.Item>
                            </ANTD.Form>
                        </ANTD.Card>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={7}>
                        <ANTD.Card title="Booking Summary">
                            <div className="starts-on">
                                <p>Starts On Nov 30, 2020</p>
                                <ul>
                                    <li>1 x Bus (Model-2017)</li>
                                    <li>2 traveller</li>
                                </ul>
                            </div>
                            <div className="charges">
                                <ul>
                                    <li>
                                        <p>Price</p>
                                        <p><span style={{ fontSize: "13px" }}>SAR</span> 4,733</p>
                                    </li>
                                    <li>
                                        <p>Taxes and Fees</p>
                                        <p><span style={{ fontSize: "13px" }}>SAR</span> 600</p>
                                    </li>
                                    <li>
                                        <p>VAT (15%) on Service Charges</p>
                                        <p><span style={{ fontSize: "13px" }}>SAR</span> 71</p>
                                    </li>
                                    <li>
                                        <p className="total-price">Total Price</p>
                                        <p style={{ fontSize: "13px" }}>SAR <span>5,444</span></p>
                                    </li>
                                </ul>
                            </div>
                        </ANTD.Card>
                    </ANTD.Col>
                </ANTD.Row>
            </div>
        </div>
    )
}
export default TransportCheckout;