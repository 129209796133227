import moment from "moment";

export const getDateRange = (checkInDate, checkOutDate) => {
  let end = moment(checkOutDate, "YYYY-MM-DD");
  let start = moment(checkInDate, "YYYY-MM-DD");
  const NoOfNights = moment.duration(end.diff(start)).asDays();
  let dataArray = [];
  for (let i = 0; i <= NoOfNights; i++) {
    if (i == 0) {
      dataArray.push(moment(checkInDate).format("DD-MM-YYYY"));
    } else if (i == NoOfNights) {
      dataArray.push(moment(checkOutDate).format("DD-MM-YYYY"));
    } else {
      dataArray.push(
        moment(checkInDate).clone().add(i, "days").format("DD-MM-YYYY")
      );
    }
  }
  return dataArray;
};
