const CryptoJs = require("crypto-js");
const secret = "123sdfjk";
class EncHelper {
  static setItem = (key, data) => {
    let enc_data = CryptoJs.AES.encrypt(data, secret, {
      mode: CryptoJs.mode.ECB,
    });
    sessionStorage.setItem(key, enc_data);
  };

  static getItem = (key) => {
    let data = sessionStorage.getItem(key);
    if (!data || data == null) {
      return data;
    }
    let dec_data_bytes = CryptoJs.AES.decrypt(data, secret, {
      mode: CryptoJs.mode.ECB,
    });
    let dec_data = dec_data_bytes.toString(CryptoJs.enc.Utf8);

    return dec_data;
  };

  static encryptData = (data) => {
    let enc_data = CryptoJs.AES.encrypt(data, secret, {
      mode: CryptoJs.mode.ECB,
    });
    return enc_data;
  };

  static decryptData = (data) => {
    let dec_data_bytes = CryptoJs.AES.decrypt(data, secret, {
      mode: CryptoJs.mode.ECB,
    });
    let dec_data = dec_data_bytes.toString(CryptoJs.enc.Utf8);

    return dec_data;
  };
}

export default EncHelper;
