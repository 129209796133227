import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import moment from "moment";

const TopFlights = ({ dealsList }) => {
  let tomorrowDate = moment().add(1, "days").format("YYYY-MM-DD");

  let filteredList = dealsList
    ? dealsList?.filter((item) => item.serviceType == 2)
    : [];

  return filteredList.length > 0 ? (
    <>
      <div className="container">
        <Row>
          <Col md={24} xs={24}>
            <h2 className="top-fl-route">Top Flight Routes</h2>
          </Col>
        </Row>
        <div className="sec-bg-top">
          <Row>
            {filteredList.map((route, index) => {
              return (
                <Col
                  key={"flight" + index}
                  md={12}
                  xs={24}
                  className="gap-cols-sec"
                >
                  <div className="tab-content current">
                    <ul>
                      <li className="top-flight-li">
                        {/* <a className="hm_link">{`${route.SourceName} to ${route.DestinationName} (${route.Source} to ${route.Destination})`}</a> */}
                        <a className="hm_link">{`${route.FromAirportCode} to ${route.ToAirportCode} (${route.FromAirportCode} to ${route.ToAirportCode})`}</a>
                        <Link
                          to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${route.FromAirportCode}&destination=${route.ToAirportCode}`}
                          className="srch_btn"
                        >
                          Search Flight
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  ) : null;
};

export default TopFlights;
