import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  DatePicker,
  Input,
  Table,
  Spin,
} from "antd";

import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import moment from "moment";

import excel from "../../../src/assets/vector-icons/excel.png";

import search from "../../../src/assets/vector-icons/search.png";
import "./TransactionReports.scss";

import { CloseOutlined } from "@ant-design/icons";

let dateFormat = "DD-MM-YYYY";
let oriDateFormat = "YYYY-MM-DD";

const TransactionReports = (props) => {
  const { user } = useAuthContext();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [form] = Form.useForm();
  const [Details, setDetails] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getallTopUpReports();
  }, []);
  let userID = 1;
  if (user) {
    if (user.UserID) {
      userID = user.UserID;
    }
  }
  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = Details.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(Details);
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const handleExcel = () => {
    let excelData = tableData.map((item) => {
      return {
        PgType: item.PgType,

        amount: item.amount,
        orderId: item.orderId,
        paymentMode: item.paymentMode,
        status: item.status,
      };
    });
    props.exportExcel(excelData, "TransactionReports");
  };

  const getallTopUpReports = () => {
    APIClient.get(`admin/pgReportsByUserId/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item.orderId.includes("SYT"));
          setDetails(data);
          setTableData(data);
        }
      })
      .catch(() => {
        setDetails([]);
        setTableData([]);
      });
  };

  const OnFinish = (values) => {
    if (values.fromdate && values.todate) {
      values.fromdate = values.fromdate.format("YYYY-MM-DD");
      values.todate = values.todate.format("YYYY-MM-DD");

      APIClient.get(
        `admin/pgReportsBetweenDates?fromDate=${values.fromdate}&toDate=${values.todate}&UserId=${userID}`
      )
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.filter((item) => item.orderId.includes("SYT"));

            setDetails(data);
            setTableData(data);
          } else {
            setDetails([]);
            setTableData([]);
          }
        })
        .catch(() => {
          setDetails([]);
          setTableData([]);
        });
    }
  };

  const disabledDates = (currentDate) => {
    return currentDate > moment();
  };

  const columns = [
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    {
      key: "Status",
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status?.localeCompare(b.status),
    },
    {
      title: "Price",
      dataIndex: "amount",
      key: "Price",
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: "OrderId",
      dataIndex: "orderId",
      key: "orderId",
      sorter: (a, b) => a.orderId?.localeCompare(b.orderId),
    },
    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
  ];

  return (
    <div>
      <div className="manage-markup-section ourteam-bg">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false} className="retrieve-booking">
              <div className="card-add-heading">
                <div className="rows-count mb-1">
                  <h5>Transaction Reports</h5>
                </div>
              </div>
              <Form form={form} layout={"vertical"} onFinish={OnFinish}>
                <Row gutter={[16, 16]} align="middle">
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      name="fromdate"
                      label="From Date"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        disabledDate={disabledDates}
                        style={{ width: "100%" }}
                        format={dateFormat}
                        placeholder="From Date"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                      name="todate"
                      label="To Date"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <DatePicker
                        disabledDate={disabledDates}
                        style={{ width: "100%" }}
                        format={dateFormat}
                        placeholder="To Date"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={6} sm={12} xs={24}>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </div>

          <div className="card-bt-gap">
            <Card bordered={false} className="retrieve-booking">
              {Details.length > 0 ? (
                <>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>View Reports</h5>
                        <p>{tableData.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                    </div>
                  </div>

                  <Table
                    bordered
                    scroll={{ x: true }}
                    dataSource={tableData}
                    columns={columns}
                  />
                </>
              ) : loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : (
                <b>No Results Found!</b>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UpdatedComponent(TransactionReports);
