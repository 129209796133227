export const getDepositType = (key) => {
  switch (key) {
    case 1:
      return "Cash Deposit Bank";
    case 2:
      return "NEFT";
    case 3:
      return "Instant Recharge";
    case 4:
      return "IMPS";
    case 5:
      return "Credit";
    case 6:
      return "Direct NetTransfer";
    case 7:
      return "GooglePay Transfer";
    case 8:
      return "PhonePay Transfer";
    case 9:
      return "AmazonPay Transfer";
    case 10:
      return "RTGS (Real-Time Gross Settlement)";
    case 11:
      return "Cash Deposit ATM";
    case 12:
      return "Virtual Cash";
    case 13:
      return "Bank Transfer";

    default:
      return;
  }
};
export const getPaymentType = (key) => {
  switch (key) {
    case 1:
      return "Debit Card";
    case 2:
      return "Credit Card";
    case 3:
      return "Net Banking";
    case 4:
      return "UPI";
    case 5:
      return "Amex";
    default:
      return;
  }
};

export const getStatus = (key) => {
  switch (key) {
    case 1:
      return "Pending";
    case 2:
      return "Accepted";
    case 3:
      return "Rejected";
    default:
      return;
  }
};

export const getAddRevoke = (key) => {
  switch (key) {
    case 1:
      return "Added";
    case 2:
      return "Revoked";

    default:
      return;
  }
};
