import { Card, Col, Form, Select, Table, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { getTypeSign } from "../../admin/AdminMarkup/commissionhelper";
import "./Commissiondetails.scss";

const { Option } = Select;

const Commissiondetails = () => {
  const { user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [agentComList, setAgentComList] = useState([]);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (user && user?.Membership && user?.Role?.RoleId) {
      getAgentCommission(user?.Membership, user?.Role?.RoleId);
    }
  }, [user]);

  const getAgentCommission = (memberShipId, roleType) => {
    setIsLoading(true);
    APIClient.get(
      `admin/agentCommissions?memberShipId=${memberShipId}&roleType=${roleType}`
    )
      .then((res) => {
        if (res.status == 200 && Object.keys(res?.data).length > 0) {
          let data = Object.keys(res.data)
            .map((type) => {
              return res.data[type].map((item) => ({
                serviceType: type,
                ...item,
              }));
            })
            .reduce((acc, cur) => acc.concat(cur), []);

          setAgentComList(data);
          setTableData(data);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };
  const handleChange = (value) => {
    if (value === "All") {
      setTableData(agentComList);
    } else {
      let filteredList = agentComList.filter(
        (item) => item.serviceType === value
      );
      setTableData(filteredList);
    }
  };

  const tableHeader = [
    {
      title: "Service",
      dataIndex: "serviceType",
    },
    {
      title: "Operator",
      dataIndex: "OperatorID",
      render: (val) =>
        val && val?.OperatorName ? <span>{val.OperatorName} </span> : "",
    },
    {
      title: "Category",
      render: (_, currentData) =>
        currentData.Rating ? <span>{currentData.Rating} star </span> : "N/A",
    },

    {
      title: "Commission",
      render: (_, currentData) =>
        currentData.serviceType === "Flight"
          ? getCommission(
              currentData.BaseFareCommission,
              currentData.BaseFareCommissionType
            )
          : getCommission(currentData.Commission, currentData.CommissionType),
    },
  ];

  const getCommission = (value, type) => <>{value + " " + getTypeSign(type)}</>;

  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <div className="card-bt-gap">
          <Card bordered={false} className="retrieve-booking">
            <div className="card-add-heading">
              <h5 className="mb-3">Commission Details</h5>
            </div>
            <Form layout={"vertical"}>
              <Row>
                <Col md={8} sm={12} xs={24}>
                  <Form.Item name="serviceType" label="Service Type">
                    <Select
                      placeholder="Please Select"
                      onSelect={handleChange}
                      defaultValue="All"
                    >
                      <Option value="All">All</Option>
                      <Option value="Flight">Flight</Option>
                      <Option value="Hotel">Hotel</Option>
                      <Option value="Bus">Bus</Option>
                      <Option value="Activity">Activities</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {isLoading ? (
              <Spin />
            ) : (
              <Table
                scroll={{ x: true }}
                columns={tableHeader}
                dataSource={tableData}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50"],
                }}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Commissiondetails;
