import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Layout,
  Col,
  Input,
  Form,
  Modal,
  message,
  Popconfirm,
  Row,
  Select,
  Table,
} from "antd";
import ApiClient from "../../helpers/ApiClient";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import {
  PlusCircleFilled,
  EditOutlined,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "./OperatorsService.scss";
const { Content } = Layout;
const { Option } = Select;

const BusOperatorsService = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [OperatorsForm] = Form.useForm();
  const [operators, setOperators] = useState([]);
  const [tableList, setTableList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [currID, setCurrID] = useState(-1);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const getOperators = () => {
    ApiClient.get("admin/operators")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data
            .filter((item) => item.ServiceType === 3)
            .map((item, index) => {
              return {
                SrNo: index + 1,
                ...item,
                ServiceType: getServiceName(item.ServiceType),

                BusTypeName: item.BusType ? getBusType(item.BusType) : null,
              };
            });
          setOperators(data);
          setTableList(data);
        }
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getOperators();
  }, []);

  const getBusType = (num) => {
    if (num == 1) {
      return "RTC Buses";
    } else if (num == 2) {
      return " Private Travels";
    }
  };

  const AddOperators = async (values) => {
    let data = {
      ServiceType: 3,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };
    const response = await ApiClient.post("admin/operator", data);
    if (response.status === 200) {
      message.success("Operator added successfully !");
      getOperators();
      setModalVisible(false);
    } else {
      message.error("Something went wrong");
    }
  };

  const UpdateOperator = async (values) => {
    let data = {
      ServiceType: 3,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    };
    const response = await ApiClient.put("admin/operator/" + currID, {}, data);
    if (response.status === 200) {
      message.success("Operator updated successfully !");
      getOperators();
      setModalVisible(false);
    } else {
      message.error("Something went wrong");
    }
  };

  const DeleteOperator = async (id) => {
    const response = await ApiClient.delete("admin/operator/" + id);
    if (response.status === 200) {
      message.success("Operator Deleted successfully !");
      getOperators();
    } else {
      message.error("Something went wrong");
    }
  };

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const showModal = () => {
    if (isEdit) {
      setCurrID(-1);
      setIsEdit(false);

      OperatorsForm.resetFields();
    }
    setModalVisible(true);
  };

  const tableActions = (text, record) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setCurrID(text);
              setIsEdit(true);
              setModalVisible(true);
              OperatorsForm.setFieldsValue({
                BusType: record.BusType,
                OperatorName: record.OperatorName,
              });
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => DeleteOperator(record.OperatorServiceID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columnsserviceoper = [
    {
      title: "Sr. No.",
      dataIndex: "SrNo",
      sorter: (a, b) => a.SrNo - b.SrNo,
    },
    {
      title: "Service Name",
      dataIndex: "ServiceType",
      sorter: (a, b) => a.ServiceType.localeCompare(b.ServiceType),
    },
    {
      title: "Bus Type ",
      dataIndex: "BusTypeName",
      sorter: (a, b) => a.BusTypeName.localeCompare(b.BusTypeName),
    },
    {
      title: "Operator Name",
      dataIndex: "OperatorName",
      sorter: (a, b) => a.OperatorName.localeCompare(b.OperatorName),
    },

    {
      title: "Actions",
      dataIndex: "OperatorServiceID",
      render: (text, record) => tableActions(text, record),
    },
  ];

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = tableList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableList(searchResults);
    } else {
      setTableList(operators);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
    setTableList(operators);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const getServiceName = (id) => {
    switch (id) {
      case 3:
        return "Bus";

      case 1:
        return "Flight";

      default:
        break;
    }
  };
  let key = 0;
  const keyGenerate = () => {
    return key++;
  };
  return (
    <div className="promo providerapi">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <h5>View Bus Operator</h5>
                      <p>{tableList.length} rows found !</p>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => {
                          setTableList([]);
                          getOperators();
                        }}
                      />
                      <div onClick={showModal} className="add-deposit-icon">
                        <PlusCircleFilled />
                      </div>
                    </div>
                  </div>

                  <Table
                    bordered
                    scroll={{ x: true }}
                    dataSource={tableList}
                    columns={columnsserviceoper}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                    rowKey={keyGenerate}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Add Bus Operator</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <div>
            <Button
              key="clear"
              type="danger"
              onClick={() => OperatorsForm.resetFields()}
            >
              Clear
            </Button>
            <Button key="add" type="primary" onClick={OperatorsForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
          </div>,
        ]}
      >
        <Form
          {...formItemLayout}
          layout="vertical"
          form={OperatorsForm}
          onFinish={isEdit ? UpdateOperator : AddOperators}
        >
          <Row>
            <Col md={24} xs={24}>
              <Form.Item
                label="Bus Type"
                name="BusType"
                rules={[{ required: true }]}
              >
                <Select placeholder="Please Select">
                  <Option value={1}>RTC Buses</Option>
                  <Option value={2}>Private Travels</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item
                label="Operator Name"
                name="OperatorName"
                rules={[
                  {
                    required: true,
                    message: "Please input your Operator Name",
                  },
                ]}
              >
                <Input placeholder="Operator Name" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default BusOperatorsService;
