import React from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import "./unauth.scss";
const NoResultFound = () => {
  return (
    <div className="error-404">
      <Result
        status="500"
        title="No Results Found"
        subTitle=""
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  );
};

export default NoResultFound;
