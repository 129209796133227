import React, { useEffect, useState } from "react";
import { Button, Card, Col, Input, InputNumber, Row, Form, Select, Table, Layout, message, Modal, Popconfirm, Popover } from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import { getRoleType, getTypeSign } from "./commissionhelper";
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Content } = Layout;

const ActivitiesCommissions = (props) => {
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [roleType, setRoleType] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [hotelCommissions, setHotelCommissions] = useState([]);
  const [actCommisionForm] = Form.useForm();
  const [isEdit, setIsEdit] = useState(false);
  const [toBeEditable, setToBeEditable] = useState(-1);
  const [membershipList, setMembershipList] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const handleRoleType = (val) => setRoleType(val);

  const getActivitiesCommissions = () => {
    ApiClient.get("admin/activitiesCommissionMarkup")
      .then((res) => {
        if (res.status == 200) {
          let mapData = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
              RoleTypeName: getRoleType(item.RoleType),
              MembershipID: item?.MembershipID?.MembershipID ?? "",
              MembershipName: item?.MembershipID?.Name ?? "",
              ProviderID: item?.ProviderID?.ProviderID ?? "",
              ProviderName: item?.ProviderID?.Provider ?? "",
              CategoryID: item?.CategoryID?.categoryId ?? "",
              CategoryName: item?.CategoryID?.categoryName ?? "",
            };
          });

          setHotelCommissions(mapData);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getActivitiesCommissions();
    getMembership();
    getProviders();
    getActivitiesCategory();
  }, []);

  const getActivitiesCategory = () => {
    ApiClient.get(`extranet/categories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
        }
      })
      .catch(() => {});
  };

  const getMembership = () => {
    ApiClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          setMembershipList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProviders = () => {
    ApiClient.get("admin/providers")
      .then((res) => {
        if (res.statusCode == 200) {
          let filterProvider = res.data.filter((item) => item.ServiceType === 4);
          setProvidersList(filterProvider);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const EditRecord = (rec) => {
    handleRoleType(rec.RoleType);
    actCommisionForm.setFieldsValue({
      ...rec,
    });
    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "Sno",
    },

    {
      title: "Role",
      dataIndex: "RoleTypeName",
      sorter: (a, b) => a.RoleTypeName.localeCompare(b.RoleTypeName),
    },

    {
      title: "Membership",
      dataIndex: "MembershipName",
      sorter: (a, b) => a.MembershipName.localeCompare(b.MembershipName),
    },
    {
      title: "Category",
      dataIndex: "CategoryName",
      sorter: (a, b) => a.CategoryName.localeCompare(b.CategoryName),
    },
    {
      title: "Provider",
      dataIndex: "ProviderName",
      sorter: (a, b) => a.ProviderName.localeCompare(b.ProviderName),
    },

    {
      title: "Markup",
      dataIndex: "Markup",
      render: (value, currentData, _) => <>{value + " " + getTypeSign(currentData.MarkupType)}</>,
    },
    {
      title: "Star Rating",
      dataIndex: "Rating",
    },
    {
      title: "Commission",
      dataIndex: "Commission",
      render: (value, currentData, _) => <>{value + " " + getTypeSign(currentData.CommissionType)}</>,
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const tableActions = (currentRec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              setToBeEditable(currentRec.ID);
              setIsEdit(true);
              EditRecord(currentRec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        &nbsp;
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm title="Are you sure to delete?" onConfirm={() => DeleteHotelCommission(currentRec.ID)}>
            <DeleteOutlined style={{ color: "red", fontSize: "20px", textAlign: "center" }} />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const onFormSubmit = (val) => {
    let data = {
      CreatedBy: 1,
      ModifiedBy: 1,
      ...val,
      MembershipID: val.RoleType === 5 ? val.MembershipID : 1,
      Commission: val.RoleType === 5 ? val.Commission : 0,
      CommissionType: val.RoleType === 5 ? val.CommissionType : 0,
    };

    isEdit ? UpdateHotelCommission(data) : AddHotelCommission(data);
  };

  const AddHotelCommission = (data) => {
    ApiClient.post("admin/activitiesCommissionMarkup", data)
      .then((response) => {
        if (response.status == 200) {
          getActivitiesCommissions();
          message.success("Activities  Commission Added successfully", 3);
          setAddMarkupModal(false);
        } else {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const UpdateHotelCommission = (data) => {
    ApiClient.put("admin/activitiesCommissionMarkup/" + toBeEditable, {}, data)
      .then((response) => {
        if (response.status == 200) {
          getActivitiesCommissions();
          message.success("Activities  Commission Updated successfully");
          setAddMarkupModal(false);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteHotelCommission = (id) => {
    ApiClient.delete("admin/activitiesCommissionMarkup/" + id)
      .then((res) => {
        if (res.status == 200) {
          getActivitiesCommissions();
          message.success("Activities  Commission Deleted Successfully");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = hotelCommissions.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setHotelCommissions(searchResults);
    } else {
      getActivitiesCommissions();
    }
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = hotelCommissions.map((item) => {
      return {
        S_No: item.Sno,
        Role: item.RoleTypeName,
        Membership: item.MembershipName,
        Commission: item.Commission,
        Markup: item.Markup,
        Provider_Name: item.ProviderName,
        Category_Name: item.CategoryName,
        Star_Rating: item.Rating,
      };
    });

    props.exportExcel(excelData, "Activities CommissionDetails");
  };

  return (
    <>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Activities Commission <HelpInfoHelper screenName={"/admin/activitiescommissions"} />
                    </h5>
                    <p>{hotelCommissions.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && <Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                  &nbsp;&nbsp;
                  <img src={search} alt="search" onClick={(e) => searchData()} />
                  <img src={excel} alt="excel" onClick={handleExcel} />
                  <img src={exchange} alt="exchange" onClick={() => getActivitiesCommissions()} />
                  <div
                    onClick={(e) => {
                      if (isEdit) {
                        setIsEdit(false);
                      }
                      handleRoleType(1);
                      setAddMarkupModal(true);
                      actCommisionForm.resetFields();
                    }}
                    className="add-icon cursor-pointer"
                  >
                    <PlusOutlined />
                  </div>
                </div>
              </div>
              <div>
                <Table scroll={{ x: true }} bordered dataSource={hotelCommissions} columns={tableColumns} />
              </div>
            </Card>
          </div>
        </Content>
      </div>

      <Modal
        width={"800px"}
        className="markup-modal"
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Activities Commission</strong>
            </h6>
          </div>,
        ]}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={[
          <div>
            <Button key="add" type="primary" htmlType="submit" onClick={actCommisionForm.submit}>
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button key="submit" type="danger" onClick={() => actCommisionForm.resetFields()}>
              Clear
            </Button>
          </div>,
        ]}
      >
        <Form layout={"vertical"} form={actCommisionForm} onFinish={onFormSubmit}>
          <div>
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Role Type"
                  name="RoleType"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select placeholder="Please Select" onSelect={(val) => handleRoleType(val)}>
                    <Option value={2}>Users</Option>
                    <Option value={4}>Guest</Option>
                    <Option value={5}>Partner</Option>
                  </Select>
                </Form.Item>
              </Col>
              {roleType === 5 && (
                <Col className="gutter-row" md={8} xs={24}>
                  <Form.Item label="Membership" name="MembershipID" rules={[{ required: true }]}>
                    <Select placeholder="Select Membership" showSearch filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {membershipList.map((data, index) => (
                        <Option key={index} value={data.MembershipID}>
                          {data.Name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="Provider" name="ProviderID" rules={[{ required: true }]}>
                  <Select placeholder="Select Provider">
                    {providersList.map((data, index) => (
                      <Option key={index} value={data.ProviderID}>
                        {data.Provider}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item label="Category" name="CategoryID" rules={[{ required: true }]}>
                  <Select 
                  showSearch
                  placeholder="Please Select"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  >
                    {categoryList.map((item) => (
                      <Option key={item.categoryId} value={item.categoryId}>
                        {item.categoryName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Star Ratings"
                  name="Rating"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select placeholder="Please Select">
                    <Option value={0}>0 Star</Option>
                    <Option value={1}>1 Star</Option>
                    <Option value={2}>2 Star</Option>
                    <Option value={3}>3 Star</Option>
                    <Option value={4}>4 Star</Option>
                    <Option value={5}>5 Star</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div className="seperation-line">
            <Row gutter={16}>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup Type"
                  name="MarkupType"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select placeholder="Pleaase select">
                    <Option value={0}>Fixed</Option>
                    <Option value={1}>Percentage</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Markup"
                  name="Markup"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                </Form.Item>
              </Col>

              {roleType === 5 && (
                <>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item label="Commission Type" name="CommissionType" rules={[{ required: true }]}>
                      <Select placeholder="Please select">
                        <Option value={0}>Fixed</Option>
                        <Option value={1}>Percentage</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={8} xs={24}>
                    <Form.Item label="Commission" name="Commission" rules={[{ required: true }]}>
                      <InputNumber style={{ width: "100%" }} min={0} type="number" />
                    </Form.Item>
                  </Col>
                </>
              )}
             
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Private Transfers Markup"
                  name="PrivateTransfersMarkup"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
                </Col>
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Sharing Transfers Markup"
                  name="SharingTransfersMarkup"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
            
              </Col>
              {roleType === 5 && (
                <>
            
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Private Transfers Commission"
                  name="PrivateTransfersCom"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
                </Col>
                <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Sharing Transfers Commission"
                  name="SharingTransfersCom"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} min={0} type="number" />
                  
                </Form.Item>
            
              </Col></>
              )}
            </Row>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default UpdatedComponent(ActivitiesCommissions);
