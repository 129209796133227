import React from "react";
import "../TransportSearch/TransportSearch.scss";
import Banner from "../../../components/banner/Banner";
import * as ANTD from 'antd';
import { Link } from 'react-router-dom';
import Transportation from '../../../components/Transportation/Transportation';

const TransportSearch = () => {
    return (
        <div className="holidays_search_container transport_search">
            <section className="holidays_banner">
                {/* <div className="heading_text">
                    <h2>We make Transport easy for you</h2>
                </div> */}
                <div>
                    <Banner />
                </div>
                <div className="hotel_search">
                    <Transportation />
                </div>
            </section>
        </div>
    );
}

export default TransportSearch;