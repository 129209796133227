import React, { useState } from "react";
import * as ANTD from "antd";
import "../Holidaysnearplaces/Holidaysnearplaces.scss";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import {
  QuestionCircleOutlined,
  FieldTimeOutlined,
  DisconnectOutlined,
  RightOutlined,
  AntDesignOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Carousel } from "antd";
import { Switch } from "antd";
import { Link } from "react-router-dom";

const Holidaysnearplaces = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { Option } = ANTD.Select;

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  const contentprice = (
    <div>
      <p>Price Guarantee</p>
      <p>
        Find a cheaper ticket, tour or activity and we'll refund DOUBLE the
        difference! (T&Cs apply)
      </p>
    </div>
  );

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container cms-pages-width-places">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <Carousel autoplay>
                  <div>
                    <img
                      className="slider-img-holidays"
                      src={
                        require("../../../assets/images/holidays/near-3.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="slider-img-holidays"
                      src={
                        require("../../../assets/images/holidays/near-4.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      className="slider-img-holidays"
                      src={
                        require("../../../assets/images/holidays/near-3.jpg")
                          .default
                      }
                      alt=""
                    />
                  </div>
                </Carousel>
              </ANTD.Col>
            </ANTD.Row>

            {/*--near--places--start--*/}

            <ANTD.Row className="bread-cubs">
              <ANTD.Col md={18} xs={24}>
                <ANTD.Breadcrumb>
                  <ANTD.Breadcrumb.Item>
                    <a href="#rates-tour">TOUR RATES</a>
                  </ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>
                    <a href="#incluopt">INCLUSIONS</a>
                  </ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>
                    <a href="#tmint">TIMINGS</a>
                  </ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>
                    <a href="#infoint">IMPORTANT INFORMATION</a>
                  </ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>
                    <a href="#pobook">BOOKING POLICY</a>
                  </ANTD.Breadcrumb.Item>

                  <ANTD.Breadcrumb.Item>
                    <a href="#reviewbox">REVIEWS</a>
                  </ANTD.Breadcrumb.Item>
                </ANTD.Breadcrumb>
              </ANTD.Col>
              <ANTD.Col md={6} xs={24}>
                <ANTD.Button className="book-cart-all">
                  <a href="#rates-tour">Book Now</a>
                </ANTD.Button>
              </ANTD.Col>
            </ANTD.Row>

            {/*--close--bread--*/}

            <ANTD.Row className="holidays-near">
              <ANTD.Col md={24} xs={24}>
                <h3>Hyderabad Salar Jung Museum and Nizam Museum Day Tour</h3>

                <ANTD.Row>
                  <ANTD.Col md={18} xs={24}>
                    <ul className="review-holi">
                      <li>
                        <i
                          className="fa fa-star-o star-rating"
                          aria-hidden="true"
                        ></i>
                        &nbsp;4.3
                      </li>
                      <li>(9 reviews)</li>
                    </ul>
                  </ANTD.Col>
                  <ANTD.Col md={6} xs={24}></ANTD.Col>
                </ANTD.Row>
                {/*--first--row--close--*/}

                {/*new--section--start*/}
                <ANTD.Row className="new-sec-1key">
                  <ANTD.Col md={24} xs={24}>
                    <h4 className="deatils-key">Key Details</h4>
                    <ANTD.Row>
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-calendar tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">
                            Availability: Daily
                          </span>
                        </div>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-hourglass-half tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">Duration:</span>
                        </div>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-clock-o tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">Starting Time:</span>
                        </div>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-car tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">
                            Pickup & Drop Back
                          </span>
                        </div>
                      </ANTD.Col>
                    </ANTD.Row>

                    <ANTD.Row className="key-row-2">
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-flash tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">
                            Instant Confirmation
                          </span>
                        </div>
                      </ANTD.Col>

                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-check-square-o tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">Check Rooms</span>
                        </div>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-print tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">
                            Printed Voucher Accepted
                          </span>
                        </div>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <div className="key-option">
                          <i
                            className="fa fa-language tour-favi"
                            aria-hidden="true"
                          ></i>
                          <span className="aling-middle">Language</span>
                        </div>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row className="new-sec-1key des-row-key">
                  <ANTD.Col md={24} xs={24}>
                    <h4 className="deatils-key">DESCRIPTION</h4>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets
                    </p>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="new-sec-1key des-row-key">
              <ANTD.Col md={24} xs={24}>
                <h3 className="deatils-key" id="rates-tour">
                  Tour Rates
                </h3>
                <Table
                  responsive="xl"
                  className="groupfrom "
                  striped
                  bordered
                  hover
                >
                  <thead>
                    <tr>
                      <th>Select Your Option</th>
                      <th>Transfer Option</th>
                      <th>Tour Date</th>
                      <th>Adult</th>
                      <th>Child</th>
                      <th>Infant</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="dob">
                          <Form.Control
                            type="date"
                            name="dob"
                            placeholder="Date of Birth"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Desert Safari</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="dob">
                          <Form.Control
                            type="date"
                            name="dob"
                            placeholder="Date of Birth"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Dinner creust</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                            <option>Hyderabad City</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                            <option>Sharing Transfers</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="dob">
                          <Form.Control
                            type="date"
                            name="dob"
                            placeholder="Date of Birth"
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group>
                          <Form.Control as="select" size="sm">
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                          </Form.Control>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </ANTD.Col>
              <ANTD.Col md={24} xs={24} className="emailflyer">
                <ANTD.Button className="flyerbtn-1">
                  Add To Email Flyer
                </ANTD.Button>
                &nbsp;
                <Link to="/holidaystotal">
                  <ANTD.Button className="flyerbtn-2">Add To cart</ANTD.Button>
                </Link>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="new-sec-1key des-row-key">
              <ANTD.Col md={24} xs={24}>
                <h4 className="deatils-key" id="incluopt">
                  INCLUSIONS
                </h4>
                <ul className="inclu">
                  <li>Hotel Pick up in 4x4 Land Cruiser Vehicle</li>
                  <li>Dune bashing in fully air-conditioned 4x4 Vehicle</li>
                  <li>
                    Unlimited refreshments (tea, coffee, water, soft drink
                    without alcohol)
                  </li>
                  <li>Sunset photography opportunity</li>
                  <li>Tanoura show by male artist</li>
                  <li>Belly dancing</li>
                  <li>Sand boarding (on request with an additional charge)</li>
                </ul>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="new-sec-1key des-row-key">
              <ANTD.Col md={24} xs={24}>
                <h4 className="deatils-key" id="tmint">
                  Timings
                </h4>
                <Table responsive="xl" striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Duration</th>
                      <th>Departure Point</th>
                      <th>Reporting Point</th>
                      <th>Tour Language</th>
                      <th>Meals</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>5 - 6 Hours (Approx)</td>
                      <td>
                        Hotel (Centrally located in City Center Area Dubai /
                        Sharjah Sahara Center Dubai Side){" "}
                      </td>
                      <td>Hotel Lobby </td>
                      <td>English / Arabic</td>
                      <td>BBQ buffet dinner</td>
                    </tr>
                    <tr>
                      <td>5 - 6 Hours (Approx)</td>
                      <td>
                        Hotel (Centrally located in City Center Area Dubai /
                        Sharjah Sahara Center Dubai Side){" "}
                      </td>
                      <td>Hotel Lobby </td>
                      <td>English / Arabic</td>
                      <td>BBQ buffet dinner</td>
                    </tr>
                    <tr>
                      <td>5 - 6 Hours (Approx)</td>
                      <td>
                        Hotel (Centrally located in City Center Area Dubai /
                        Sharjah Sahara Center Dubai Side){" "}
                      </td>
                      <td>Hotel Lobby </td>
                      <td>English / Arabic</td>
                      <td>BBQ buffet dinner</td>
                    </tr>
                  </tbody>
                </Table>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="new-sec-1key des-row-key">
              <ANTD.Col md={24} xs={24}>
                <h4 className="deatils-key" id="infoint">
                  IMPORTANT INFORMATION
                </h4>
                <ul className="importinfo">
                  <li>
                    Transfer option is available for this Tour activity on
                    Sharing and Private depending on the Booking selection.
                  </li>
                  <li>
                    The Pick up will be arranged from your Dubai hotel /
                    Residence only and not from the Airport.
                  </li>
                  <li>
                    Pick up from Areas other than Dubai Hotels / Residence will
                    be done on request with an additional surcharge.
                  </li>
                  <li>
                    Sharing transfers is available from Deira, Bur Dubai, Sheikh
                    Zayed upto Marina.
                  </li>
                  <li>Tanoura show by male artist</li>
                  <li>
                    Pregnant Women or people suffering from backache are not
                    recommended for this Tour.
                  </li>
                  <li>Sand boarding (on request with an additional charge)</li>
                </ul>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="new-sec-1key des-row-key">
              <ANTD.Col md={24} xs={24}>
                <h4 className="deatils-key" id="pobook">
                  BOOKING POLICY
                </h4>
                <ul className="inclu">
                  <li>Hotel Pick up in 4x4 Land Cruiser Vehicle</li>
                  <li>Dune bashing in fully air-conditioned 4x4 Vehicle</li>
                  <li>
                    Unlimited refreshments (tea, coffee, water, soft drink
                    without alcohol)
                  </li>
                  <li>Sunset photography opportunity</li>
                  <li>Tanoura show by male artist</li>
                  <li>Belly dancing</li>
                  <li>Sand boarding (on request with an additional charge)</li>
                </ul>
              </ANTD.Col>
            </ANTD.Row>

            {/*--near--places--end--*/}

            {/*--reviewa----open--*/}
            <ANTD.Row>
              <ANTD.Col md={18} xs={24}>
                <h3 className="booking-option" id="reviewbox">
                  Reviews
                </h3>
              </ANTD.Col>

              <ANTD.Col md={18} xs={24}>
                <ul className="start-review-rating">
                  <li>4.3</li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star" aria-hidden="true"></i>
                  </li>
                  <li>
                    <i className="fa fa-star-half-o" aria-hidden="true"></i>
                  </li>
                  <li className="review-count">9 reviews</li>
                </ul>
              </ANTD.Col>

              <ANTD.Col md={18} xs={24}>
                <ANTD.Row className="gallery-wrapper">
                  <ANTD.Col md={18} xs={24}>
                    <div className="">
                      <h6>
                        <b>Photo Gallery</b>
                      </h6>
                      <p>21 Photos</p>
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={6} xs={24} className="view-more-photos">
                    <ANTD.Button>View More</ANTD.Button>
                  </ANTD.Col>

                  <ANTD.Col md={4} xs={24}>
                    {" "}
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-4.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    {" "}
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    {" "}
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-4.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    {" "}
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    {" "}
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-4.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                </ANTD.Row>
                {/*--wrapper----close--*/}

                {/*--form----close--*/}
                <ANTD.Row className="review-help-center">
                  <ANTD.Col md={18} xs={24}>
                    <p>
                      <b>sangaya</b>
                    </p>
                    <div className="persons-rating">
                      <p>
                        {" "}
                        <i
                          className="fa fa-star five-star"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-star five-star"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-star five-star"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-star five-star"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p>
                        {" "}
                        <i
                          className="fa fa-star five-star"
                          aria-hidden="true"
                        ></i>
                      </p>
                      <p>
                        <b>Highly recommended</b>
                      </p>
                    </div>
                  </ANTD.Col>

                  <ANTD.Col md={6} xs={24} className="date-of-review">
                    <p>12 Jan 2020</p>
                  </ANTD.Col>

                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/ramoji-film-city-1.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-4.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/ramoji-film-city-1.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-3.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <div>
                      <img
                        className="slider-img-holidays-1"
                        src={
                          require("../../../assets/images/holidays/near-4.jpg")
                            .default
                        }
                        alt=""
                      />
                    </div>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Col>
              {/*--col-md-18----close--*/}

              <ANTD.Col md={6} xs={24}></ANTD.Col>
            </ANTD.Row>

            {/*--reviewa----close--*/}

            {/*--faqs----end--*/}

            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <h3 className="booking-option">
                  While you're here, check these out too!
                </h3>
              </ANTD.Col>
            </ANTD.Row>

            {/*-- check these out too----end--*/}

            <ANTD.Row>
              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/holidays-city-2.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">Hussain Sagar Lake Boat Ride</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>

              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/holidays-city-3.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">Hussain Sagar Lake Boat Ride</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>

              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/holidays-city-3.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">Hussain Sagar Lake Boat Ride</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>

            {/*--india----end--*/}

            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <h3 className="booking-option">Top Activities in INDIA</h3>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row>
              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/trecking-1.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">The Giant Swing in Rishikesh</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>

              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/trecking-2.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">Madame Tussauds Delhi</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>

              <ANTD.Col md={8} xs={24}>
                <ANTD.Card
                  className="body-card"
                  hoverable
                  style={{ width: "90%" }}
                  cover={
                    <img
                      alt="example"
                      src={
                        require("../../../assets/images/holidays/trecking-3.png")
                          .default
                      }
                    />
                  }
                >
                  <ul className="locations-hol">
                    <li>
                      <a href="#">Bungee Jumping in Rishikesh</a>
                    </li>
                    <li>
                      <span>
                        <i className="fa fa-star-o" aria-hidden="true"></i>
                        &nbsp;
                        <span className="hol-1">4.3</span> |
                        <span className="hol-2">(9 reviews)</span>
                        <span className="hol-2">200+ Booked</span>
                      </span>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <del>
                          <span>₹</span> 5,100
                        </del>
                      </p>
                    </li>
                    <li>
                      <p className="mg-bot">
                        <span>
                          <b>₹ 4,800</b>
                        </span>
                      </p>
                      <p className="mg-bot">
                        <span>Available from 26 Sep 2020</span>
                      </p>
                    </li>
                  </ul>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>

            {/*--india----end--*/}
          </Content>
        </div>
      </div>
      {/* 
      <div className="promo providerapi">
        <ANTD.Layout className="places-promo-btn">
          <div className="">
            <Content className="admin-container cms-pages-width-places">
              <ANTD.Row className="ft-top">
                <ANTD.Col md={10} xs={24}>
                  <p className="credits-p">
                    Complete all required fields to proceed to the next step
                  </p>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <div className="cart-btn">
                    <Link to="/holidaysshoppingcart">
                      <ANTD.Button className="addtocart">
                        {" "}
                        Add To Cart
                      </ANTD.Button>
                      &nbsp;
                    </Link>
                    <Link to="/holidaystotal">
                      <ANTD.Button className="book-cart">Book Now</ANTD.Button>
                    </Link>
                  </div>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <p className="credits-p">
                    <a href="">
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                      &nbsp;Edit selected options
                    </a>
                  </p>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>*/}
    </div>
  );
};
export default Holidaysnearplaces;
