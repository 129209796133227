import React, { useState } from "react";
import hotelNoImg from "../../../assets/images/hotels/no_photo.png";

const HotelCardImage = ({ hotelDetailsRespObj, hotelRoom, onHandleModal }) => {
  const [showImage, setShowImage] = useState(true);
  return hotelDetailsRespObj?.images?.length > 0 ? (
    <div className="hotel_image_">
      <img
        src={
          showImage
            ? hotelDetailsRespObj?.images[
                Math.floor(Math.random() * hotelDetailsRespObj?.images?.length)
              ]
            : hotelNoImg
        }
        alt={hotelRoom.roomName}
        onError={() => {
          setShowImage(false);
        }}
      />
      {showImage && (
        <div className="gallery-icon">
          <i
            className="fa fa-picture-o"
            aria-hidden="true"
            onClick={() =>
              onHandleModal({
                roomName: hotelRoom.roomName,
                images: hotelDetailsRespObj?.images,
              })
            }
          ></i>
        </div>
      )}
    </div>
  ) : null;
};

export default HotelCardImage;
