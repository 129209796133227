import React from "react";
import * as ANTD from "antd";

import "../Travellersvisadetails/Travellersvisadetails.scss";
import { Link } from "react-router-dom";

const Travellersvisadetails = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  return (
    <div>
      <div className="promo providerapi">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container ">
              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <h1 className="country-page-head">
                    UNITED ARAB EMIRATES Visa Online
                  </h1>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <div className="promo providerapi tab-bred ">
        <ANTD.Layout className="ourteam-bg ">
          <Content className="admin-container cms-pages-width-1">
            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <ANTD.Breadcrumb className="traveller-pay">
                  <ANTD.Breadcrumb.Item>Itinerary</ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>Traveller Details</ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>Make Payment</ANTD.Breadcrumb.Item>
                  <ANTD.Breadcrumb.Item>Upload Documents</ANTD.Breadcrumb.Item>
                </ANTD.Breadcrumb>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </ANTD.Layout>
      </div>

      <div className="promo providerapi">
        <ANTD.Layout className="ourteam-bg cms-pages-width-1">
          <Content className="admin-container">
            {/* second--startt */}

            <ANTD.Row className="details-passenger">
              <ANTD.Col md={18} xs={24}>
                <ANTD.Row className="details-passenger">
                  <ANTD.Col md={24} xs={24}>
                    <h4 className="first-itinery">Itinerary</h4>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Form>
                  <ANTD.Row>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.Select size={"large"} placeholder="Visa type">
                          <ANTD.Select.Option value="demo">
                            Visa type
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            96 Hours Transit Visa
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            14 Days Tourist Visa
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            14 Days Tourist Visa (Express)
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            30 Days Tourist Visa (Popular){" "}
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            30 Days Tourist Visa (Express)
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            90 Days Tourist Visa
                          </ANTD.Select.Option>
                        </ANTD.Select>
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.DatePicker
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Onward Date"
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.DatePicker
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Return Date"
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.Select size={"large"} placeholder="Travellers">
                          <ANTD.Select.Option value="demo">
                            Travellers
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            1
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            2
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            3
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            4{" "}
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            5
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            6
                          </ANTD.Select.Option>
                        </ANTD.Select>
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </ANTD.Form>

                <h4 className="first-itinery">Travellers Details</h4>
                <p>Primary Applicant</p>
                <ANTD.Form>
                  <ANTD.Row>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.Select size={"large"} placeholder="Title">
                          <ANTD.Select.Option value="demo">
                            Title
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            Mr
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            Ms
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            Mrs
                          </ANTD.Select.Option>
                          <ANTD.Select.Option value="demo">
                            Mstr{" "}
                          </ANTD.Select.Option>
                        </ANTD.Select>
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.Input
                          style={{ width: "100%", height: "40px" }}
                          placeholder="First Name"
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.Input
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Last Name"
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>

                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item className="one-item-form">
                        <ANTD.DatePicker
                          style={{ width: "100%", height: "40px" }}
                          placeholder="Date of Birth
"
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </ANTD.Form>

                {/* --third--roww-close */}

                <ANTD.Row>
                  <ANTD.Col md={12} xs={24}>
                    <ANTD.Form.Item className="one-item-form">
                      <ANTD.Checkbox>
                        I need a busines GST invoice
                      </ANTD.Checkbox>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                  <ANTD.Col md={12} xs={24}>
                    <ANTD.Form.Item className="one-item-form">
                      <ANTD.Checkbox>
                        I accept the rules of this trip
                      </ANTD.Checkbox>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row>
                  <ANTD.Col md={24} xs={24}>
                    <ANTD.Button className="btn-continue">Continue</ANTD.Button>
                  </ANTD.Col>
                </ANTD.Row>

                {/* --third--roww-close */}

                <ANTD.Row className="details-passenger">
                  <ANTD.Col md={24} xs={24}>
                    <h4 className="first-itinery">Make Payment</h4>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row className="details-passenger">
                  <ANTD.Col md={24} xs={24}>
                    <h4 className="first-itinery">Upload Documents</h4>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Col>

              {/* fare--destails--start */}

              <ANTD.Col md={6} xs={24}>
                <div className="pkg-visa-price">
                  <h3>Fare Details</h3>
                  <ul className="visa-fare-box">
                    <li>
                      Base Fare <span id="basefare">₹11256.00</span>
                    </li>

                    <li>
                      Surcharges &amp; Taxes{" "}
                      <span id="subcharges">₹726.00</span>
                    </li>
                    <li id="insdisplay">
                      Insurance <span id="insurance">₹2016.00</span>
                    </li>
                  </ul>

                  <ul className="visa-fare-total">
                    <li>
                      Total{" "}
                      <span id="totalcharges" className="lspan">
                        ₹13998.00
                      </span>
                    </li>
                  </ul>
                </div>
              </ANTD.Col>

              {/* fare--destails--close */}
            </ANTD.Row>

            {/* second--close */}
          </Content>
        </ANTD.Layout>
      </div>
    </div>
  );
};

export default Travellersvisadetails;
