import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  TimePicker,
  Card,
  message,
  Modal,
} from "antd";
import { Helmet } from "react-helmet";
import CountryList from "../../common/CountryList";
import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import "./CarHire.scss";
import APIClient from "../../helpers/ApiClient";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { AppConstants } from "../../helpers/constants";
import Thankyou from "../Thankyou/Thankyou";
import { useHistory } from "react-router-dom";

const CarHire = () => {
  const { Option } = Select;
  const history = useHistory();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [TripType, setTriptype] = useState("local");
  const [outstationTripType, setOutstationTripType] = useState(1);
  const [transfersTripType, setTransfersTripType] = useState(1);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const validateMessages = {
    required: "",
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const onTripTypeChange = (e) => {
    setTriptype(e.target.value);
  };
  const onTransfersTripTypeChange = (e) => {
    setTransfersTripType(e.target.value);
  };
  const onOutstationTripTypeChange = (e) => {
    setOutstationTripType(e.target.value);
  };
  const initailsValues = [
    {
      SourceCity: "",
      PickUpLocation: "",
      DestinationCity: "",
      DropLocation: "",
    },
  ];
  const submitLocalForm = (value) => {
    let req = {
      ServiceType: 9,
      CarType: value.CarType,
      CarCapacity: value.CarCapacity,
      Occasion: 0,
      BusType: 0,
      VisitPurpose: 0,
      BookingCurrencyFor: 0,
      Name: value.Name,
      Email: value.Email,
      ISDCode: value.ISDCode,
      ContactNumber: value.ContactNumber,
      AlternateNumber: value.AlternateNumber ? value.AlternateNumber : "",
      TripType: TripType,
      SourceCity: value.SourceCity,
      DestinationCity: value.DestinationCity ? value.DestinationCity : "",
      PreferredClass: "",
      DepartureDate: value.DepartureDate,
      DepartureTime: value.DepartureTime,
      PickUpLocation: value.PickUpLocation,
      DropLocation: value.DropLocation,
      EndDate: value.EndDate,
      EndTime: value.EndTime,
      ReturnDate: value.ReturnDate,
      ReturnTime: value.ReturnTime,
      OnwardFlightNumber: "",
      OnwardFilghtName: "",
      ReturnFlightNumber: "",
      ReturnFilghtName: "",
      NumberOfPassengers: value.NumberOfPassengers,
      Remarks: "",
      CreatedBy: 0,
      ModifiedBy: 0,
      NumberOfBuses: 0,
      Currency: "",
      Quantity: "",
      Amount: 0,
      CarTripTypeOption: value.CarTripTypeOption,
      CarSegments: value.CarTripTypeOption === 3 ? value.CarSegments : [],
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          // setVisible(true);
          setTriptype("local");
          history.push("/Thankyou");
          // form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };
  const handleOk = () => {
    setVisible(false);
  };

  return (
    <div className="contact_wrapper from-contact-24">
      <Helmet>
        <title>Luxury Car on Rent - {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="ShopYourTrip offers best Luxury car on rent services. Call 9999292007 and save using the best online car rental service."
        />
      </Helmet>
      <Row justify="center">
        {visible ? (
          <Thankyou />
        ) : (
          <Col lg={14} md={20} xs={24}>
            <div className="inner_wrapper">
              <div className="contact_header">
                <h2>Car Hire</h2>
              </div>
              <Form
                layout="vertical"
                name="form"
                form={form}
                validateMessages={validateMessages}
                onFinish={submitLocalForm}
                initialValues={{
                  ISDCode: "+91",
                  TripType: "local",
                  CarSegments: initailsValues,
                }}
              >
                <Card bordered={false} className="mb-2">
                  <Row gutter={16}>
                    <Col md={8} sm={8} xs={24}>
                      <Form.Item
                        label="Name "
                        name="Name"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Input placeholder="Enter Name" />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={8} xs={24}>
                      <Form.Item
                        label="Contact Number"
                        name="ContactNumber"
                        className="phno"
                        rules={[
                          {
                            required: true,
                            message: "Phone Number  Required",
                          },
                          {
                            minLength: 10,
                            maxLength: 10,
                            pattern: "^[0-9]{10}$",
                            message: "Must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          addonBefore={
                            <Form.Item
                              style={{ width: "35%" }}
                              name="ISDCode"
                              className="phno"
                              rules={[
                                {
                                  required: true,
                                  message: "Phone Number Code Required",
                                },
                              ]}
                              noStyle
                            >
                              <Select
                                showSearch
                                // placeholder="Select Country"
                                style={{ width: "80px" }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {CountryList.map((item) => (
                                  <Option
                                    key={item.dial_code}
                                    value={item.dial_code}
                                  >
                                    {item.dial_code}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          }
                          className="inputbg"
                          placeholder="Enter Mobile No."
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Email"
                        name="Email"
                        rules={[
                          { required: true, message: "Required" },
                          {
                            type: "email",
                            message: "Email is not a valid email",
                          },
                        ]}
                      >
                        <Input type="email" placeholder="Enter Email" />
                      </Form.Item>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Alternate  Contact Number"
                        name="AlternateNumber"
                        rules={[
                          {
                            minLength: 10,
                            maxLength: 10,
                            pattern: "^[0-9]{10}$",
                            message: "Must be 10 digits",
                          },
                        ]}
                      >
                        <Input
                          style={{ width: "100%" }}
                          placeholder="Enter Number"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
                <Card bordered={false} className="mb-3">
                  <Row className="rowcard">
                    <Col md={24}>
                      <Form.Item name="TripType">
                        <Radio.Group
                          onChange={onTripTypeChange}
                          defaultValue={TripType}
                          value={TripType}
                        >
                          <Radio value="local">Local </Radio>
                          <Radio value="outStation">Outstation </Radio>
                          <Radio value="transfer">Transfers</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                  {TripType === "local" ? (
                    <>
                      <Row gutter={16}>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Source city"
                            name="SourceCity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Source city" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Pick up location"
                            name="PickUpLocation"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Pick up location" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Destination City"
                            name="DestinationCity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Destination City" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Drop Location"
                            name="DropLocation"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Drop Location" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Start Date"
                            name="DepartureDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Start Time"
                            name="DepartureTime"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <TimePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="End Date"
                            name="EndDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="End Time"
                            name="EndTime"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <TimePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Car type "
                            name="CarType"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select placeholder="Car type">
                              <Option value={1}>Ac</Option>
                              <Option value={2}>Non AC</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Car Capacity "
                            name="CarCapacity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select placeholder="Car Capacity">
                              <Option value={1}>Driver + 4 passengers</Option>
                              <Option value={2}>
                                Driver + upto 6 passengers
                              </Option>
                              <Option value={3}>Driver + 6+ passengers</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {TripType === "outStation" ? (
                    <>
                      <Row gutter={16} className="mb-2" align="middle">
                        <Col md={24}>
                          <Form.Item name="CarTripTypeOption">
                            <Radio.Group
                              onChange={onOutstationTripTypeChange}
                              defaultValue={outstationTripType}
                              value={outstationTripType}
                            >
                              <Radio value={1}>One Way</Radio>
                              <Radio value={2}>Roundtrip </Radio>
                              <Radio value={3}>Multi city</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      {outstationTripType === 3 ? (
                        <>
                          <Form.List name="CarSegments">
                            {(fields, { add, remove }) => (
                              <>
                                {fields.map(
                                  ({ key, name, fieldKey, ...restField }) => (
                                    <Row gutter={16} align="middle">
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "SourceCity"]}
                                          fieldKey={[fieldKey, "SourceCity"]}
                                          label="Source City"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Source City" />
                                        </Form.Item>
                                      </Col>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "PickUpLocation"]}
                                          fieldKey={[
                                            fieldKey,
                                            "PickUpLocation",
                                          ]}
                                          label="Pick up location"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Pick up location" />
                                        </Form.Item>
                                      </Col>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "DestinationCity"]}
                                          fieldKey={[
                                            fieldKey,
                                            "DestinationCity",
                                          ]}
                                          label="Destination City"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Destination City" />
                                        </Form.Item>
                                      </Col>
                                      <Col md={8} sm={8} xs={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, "DropLocation"]}
                                          fieldKey={[fieldKey, "DropLocation"]}
                                          label="Drop Location"
                                          rules={[
                                            {
                                              required: true,
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Drop Location" />
                                        </Form.Item>
                                      </Col>
                                      {key != 0 ? (
                                        <Col md={4} sm={4} xs={12}>
                                          <Button
                                            onClick={() => remove(name)}
                                            type="danger"
                                            shape="round"
                                            icon={<MinusCircleOutlined />}
                                          >
                                            Remove
                                          </Button>
                                        </Col>
                                      ) : (
                                        <Col md={4} sm={4} xs={12}>
                                          <Button
                                            onClick={() => add()}
                                            type="primary"
                                            shape="round"
                                            icon={<PlusOutlined />}
                                          >
                                            Add
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  )
                                )}
                                {/* <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              Add field
                            </Button>
                          </Form.Item> */}
                              </>
                            )}
                          </Form.List>

                          <Row gutter={16}>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Start Date"
                                name="DepartureDate"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <DatePicker className="dateStyle" />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Start Time"
                                name="DepartureTime"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <TimePicker className="dateStyle" />
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Car type "
                                name="CarType"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Select placeholder="Car type">
                                  <Option value={1}>Ac</Option>
                                  <Option value={2}>Non AC</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} sm={12} xs={24}>
                              <Form.Item
                                label="Car Capacity "
                                name="CarCapacity"
                                rules={[
                                  { required: true, message: "Required" },
                                ]}
                              >
                                <Select placeholder="Car Capacity">
                                  <Option value={5}>
                                    Driver + 4 passengers
                                  </Option>
                                  <Option value={7}>
                                    Driver + upto 6 passengers
                                  </Option>
                                  <Option value={9}>
                                    Driver + 6+ passengers
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row gutter={16}>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Source city"
                              name="SourceCity"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input placeholder="Enter Source city" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Pick up location"
                              name="PickUpLocation"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input placeholder="Enter Pick up location" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Destination City"
                              name="DestinationCity"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input placeholder="Enter Destination City" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={8} xs={24}>
                            <Form.Item
                              label="Drop Location"
                              name="DropLocation"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Input placeholder="Enter Drop Location" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Start Date"
                              name="DepartureDate"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <DatePicker className="dateStyle" />
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Start Time"
                              name="DepartureTime"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <TimePicker className="dateStyle" />
                            </Form.Item>
                          </Col>

                          {outstationTripType === 2 ? (
                            <>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="End Date"
                                  name="EndDate"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <DatePicker className="dateStyle" />
                                </Form.Item>
                              </Col>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item
                                  label="End Time"
                                  name="EndTime"
                                  rules={[
                                    { required: true, message: "Required" },
                                  ]}
                                >
                                  <TimePicker className="dateStyle" />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}

                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Car type "
                              name="CarType"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select placeholder="Car type">
                                <Option value={1}>Ac</Option>
                                <Option value={2}>Non AC</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col md={8} sm={12} xs={24}>
                            <Form.Item
                              label="Car Capacity "
                              name="CarCapacity"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <Select placeholder="Car Capacity">
                                <Option value={5}>Driver + 4 passengers</Option>
                                <Option value={7}>
                                  Driver + upto 6 passengers
                                </Option>
                                <Option value={9}>
                                  Driver + 6+ passengers
                                </Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </>
                  ) : null}
                  {TripType === "transfer" ? (
                    <>
                      <Row gutter={16} className="mb-2">
                        <Col md={24}>
                          <Form.Item name="CarTripTypeOption">
                            <Radio.Group
                              onChange={onTransfersTripTypeChange}
                              defaultValue={transfersTripType}
                              value={transfersTripType}
                            >
                              <Radio value={4}>Railway Station</Radio>
                              <Radio value={5}>Airport </Radio>
                              <Radio value={6}>Hotel</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Source city"
                            name="SourceCity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Source city" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Pick up location"
                            name="PickUpLocation"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Pick up location" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Destination City"
                            name="DestinationCity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Destination City" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={8} xs={24}>
                          <Form.Item
                            label="Drop Location"
                            name="DropLocation"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="Enter Drop Location" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Start Date"
                            name="DepartureDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Start Time"
                            name="DepartureTime"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <TimePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="End Date"
                            name="EndDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="End Time"
                            name="EndTime"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <TimePicker className="dateStyle" />
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Car type "
                            name="CarType"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select placeholder="Car type">
                              <Option value={1}>Ac</Option>
                              <Option value={2}>Non AC</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="Car Capacity "
                            name="CarCapacity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Select placeholder="Car Capacity">
                              <Option value={5}>Driver + 4 passengers</Option>
                              <Option value={7}>
                                Driver + upto 6 passengers
                              </Option>
                              <Option value={9}>Driver + 6+ passengers</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  <Row justify="center" className="my-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={handleCaptcha}
                    />
                  </Row>

                  <Form.Item className="center">
                    <Button
                      disabled={buttonDisabled}
                      type="primary"
                      htmlType="submit"
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Card>
              </Form>
            </div>
          </Col>
        )}
      </Row>
      {/* <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={handleOk}
        onCancel={handleOk}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6 className="text-white">
                Your message has been successfully sent
              </h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
          <Col>
            <div className="socialLinks">
              <span className="sharetext">Share at</span>
              <span>
                <FacebookOutlined />
              </span>
              <span>
                <TwitterOutlined />
              </span>
              <span>
                <LinkedinOutlined />
              </span>
              <span>
                <MailOutlined />
              </span>
            </div>
          </Col>
        </Row>
      </Modal> */}
    </div>
  );
};

export default CarHire;
