import React, { useState } from "react";
import "../TransportCard/TransportCard.scss";
import * as ANTD from "antd";
import { useHistory } from "react-router-dom";

const TransportCard = () => {
    // console.log("bus results card", props);
    //   const [sortBy, setSortBy] = useState("departureTime");
    let history = useHistory();
    const goTo = (path) => {
        history.push("/transportcheckout")
    }
    return (
        <div>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2017 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Saudi Public Transport Company</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus2.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2020 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Al Arabia Company For The Transportation of Piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus3.jpeg').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2019 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Dalah Company For Transportation of Piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="vehicle-type">Premium</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus4.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2020 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Al Madinah Al Menawarh for the transportation of piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus5.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2019 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Saudi Public Transport Company</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2019 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Saudi Public Transport Company</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus2.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2020 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Al Arabia Company For The Transportation of Piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="vehicle-type">Premium</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus3.jpeg').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2019 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Dalah Company For Transportation of Piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="vehicle-type">Premium</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus4.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2019 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Al Madinah Al Menawarh for the transportation of piligrims</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="vehicle-type">Premium</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
            <ANTD.Card className="card-results">
                <ANTD.Row>
                    <ANTD.Col md={7}>
                        <div className="images-card-block">
                            <div className="image-block">
                                <img src={require('../../../../assets/images/transport/transportbus.png').default} alt="" />
                            </div>
                            <div className="transport-names">
                                <h2>Bus</h2>
                                <p className="capacity">Max Capacity : 25</p>
                                <p className="validity">2017 - 2020</p>
                            </div>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={13}>
                        <div className="travels-name">
                            <h2>Saudi Public Transport Company</h2>
                        </div>
                        <div className="selected-bus-type">
                            <p>transport@gmail.com</p>
                        </div>
                    </ANTD.Col>
                    <ANTD.Col md={4}>
                        <div className="available-price-details">
                            <div className="starting-price">
                                <p className="normal">Normal</p>
                                <p className="starting-price-number" style={{ marginBottom: "0" }}>
                                    SAR <span>5,444</span>
                                </p>
                                <p>All Taxes Inclusive</p>
                            </div>
                            <div className="details">
                                <span onClick={goTo}>Details</span>
                            </div>
                        </div>
                    </ANTD.Col>
                </ANTD.Row>
            </ANTD.Card>
        </div>
    );
};
export default TransportCard;
