import React, { useEffect, useState } from "react";
import * as ANTD from "antd";

import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import {
  AntDesignOutlined,
  UploadOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
import moment from "moment";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Upload,
  Image,
  Modal,
  Button,
  Col,
  Radio,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Transfercategory = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [reportModal, setReportModalView] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [providersList, setProvidersList] = useState([]);
  const { Option } = ANTD.Select;
  const [AgentField, setAgentField] = useState(false);
  const [Agentlist, setAgentlist] = useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const [Visible, setVisible] = React.useState(false);
  const { TextArea } = ANTD.Input;
  const [categoryList, setCategoryList] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [form] = ANTD.Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  const [status, setStatus] = useState(1);
  const [isVisible, setisActive] = useState(1);
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };

  const categoryColumn = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Category Name",
      dataIndex: "categoryName",
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    },
    {
      title: "Category Code",
      dataIndex: "categoryCode",
      sorter: (a, b) => a.categoryName.localeCompare(b.categoryName),
    },
    {
      title: "Status",
      // dataIndex: 'status',
      key: "category",
      // render: (text, order) =>
      //   <ANTD.Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={false} />
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const updateCategoryForm = (currentFormData) => {
    setId(currentFormData.categoryId);

    form.setFieldsValue({
      ...currentFormData,
    });
    setIsEditMode(true);
  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

          // onClick={() => updateFlightComm(currentFlightComm)}
        >
          <EditOutlined
            onClick={() => updateCategoryForm(CurrentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCategory(CurrentItem.categoryId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
////
const changeStatus = (info, status) => {
  const id = info.categoryId;
  delete info.SNo;
  delete info.categoryId;
  let obj = {
    ...info,
    status: status
  };
  updateTransferCategory(obj, id);
};
////


  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;
  const searchView = () => {
    setShowTable(true);
  };

  const reset = () => {
    form.resetFields();
  };

  // search ***********************************

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = categoryList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setCategoryList(searchResults);
    } else {
      getTransferCategory();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setLoding(false);
    getTransferCategory();
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const onFinish = (val) => {
    let obj = {
      categoryName: val.categoryName,
      createdBy: 1,
      createdDate: new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
      status: status

    };
    isEditMode
      ? updateTransferCategory(obj, id)
      : saveTransferCategory(obj);
  };

  /** API CALLS */

  const getTransferCategory = () => {
    APIClient.get(`extranet/transfercategories`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((category, index) => {
            return {
              SNo: index + 1,
              ...category,
            };
          });
          setCategoryList(data);
          setLoding(false);
        } else if (resp.status == 400) {
          ANTD.message.error("Failed To Fetch Category Data", 3);
          setLoding(false);
        }
      })
      .catch(() => {});
  };

  const saveTransferCategory = (data) => {
    APIClient.post("extranet/transfercategory", data)
      .then((resp) => {
        if (resp.status == 200) {
          ANTD.message.success("Category Saved SuccessFully", 3);
          getTransferCategory();
          form.resetFields();
        } else if (resp.status == 400) {
          ANTD.message.error("Failed to Save Category", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateTransferCategory = (formMapData, categoryId) => {
    APIClient.put(`extranet/transfercategories/${categoryId}`, {}, formMapData)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Category Updated SuccessFully", 3);
          getTransferCategory();
          setIsEditMode(false);
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteCategory = (categoryId) => {
    APIClient.delete("extranet/transfercategories/" + categoryId)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Category Deleted  successfully", 3);
          getTransferCategory();
        }
        if (res.status == 403) {
          ANTD.message.error(res.message, 3);
          getTransferCategory();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTransferCategory();
  }, []);

  const validateMessages = {
    required: "",
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  }

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <h5>
                      Transfer Category{" "}
                      <HelpInfoHelper
                        screenName={"/admin/transfercategory"}
                      />
                    </h5>
                    {/* onClick={handleShow} */}
                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div>
                    &nbsp;
                    <ANTD.Form
                      {...formItemLayout}
                      layout={formLayout}
                      form={form}
                      initialValues={{ layout: formLayout }}
                      onFinish={onFinish}
                      validateMessages={validateMessages}
                    >
                      <ANTD.Row>
                        <ANTD.Col md={7} xs={24}>
                          <ANTD.Form.Item
                            label="Category Name"
                            name="categoryName"
                            rules={[
                              {
                                required: true,
                                message: "Please enter Category",
                              },
                            ]}
                          >
                            <ANTD.Input />
                          </ANTD.Form.Item>
                        </ANTD.Col>
                      </ANTD.Row>
                      <ANTD.Row className="reports-btns">
                        <ANTD.Col>
                          {/* <div > */}
                          <ANTD.Form.Item>
                            <Button type="primary" htmlType="submit">
                              {isEditMode ? "Update" : "Add"}
                            </Button>
                          </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col>
                          <ANTD.Form.Item>
                            <ANTD.Button type="danger" onClick={reset}>
                              Reset
                            </ANTD.Button>
                            {/* </div> */}
                          </ANTD.Form.Item>
                        </ANTD.Col>
                      </ANTD.Row>
                    </ANTD.Form>
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>View Report</h5>
                        <p>{categoryList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                  <div>
                    {loding ? (
                      <ANTD.Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : categoryList.length > 0 ? (
                      <ANTD.Table
                        className="table-scroll-none table-data"
                        bordered
                        dataSource={categoryList}
                        columns={categoryColumn}
                        rowKey={uniqueKey}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>
    </div>
  );
};

export default Transfercategory;
