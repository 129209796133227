import React from "react";
import { Collapse } from "antd";
import moment from "moment";
const { Panel } = Collapse;

const BuildCardHotelDetail = ({ hotelPkg }) => {
  let hotelPriceData = hotelPkg?.hotelPriceRes ?? null;
  let displayTotal = hotelPriceData ? hotelPriceData.displayTotal : 0;

  return (
    <Collapse
      bordered={false}
      defaultActiveKey={["1"]}
      expandIconPosition="right"
    >
      <Panel header={hotelPkg?.HotelName} key="1">
        <ul className="info-item-flexbox">
          {hotelPkg?.hotelPriceData?.hotelSearchData && (
            <>
              <li>
                <p className="date-item">check In Date:</p>
              </li>
              <li>
                <p className="date-item-1">
                  {moment(
                    hotelPkg?.hotelPriceData?.hotelSearchData.checkInDate
                  ).format("DD-MM-YYYY")}
                </p>
              </li>

              <li>
                <p className="date-item">check Out Date:</p>
              </li>
              <li>
                <p className="date-item-1">
                  {moment(
                    hotelPkg?.hotelPriceData?.hotelSearchData.checkOutDate
                  ).format("DD-MM-YYYY")}
                </p>
              </li>
            </>
          )}
        </ul>

        <div className="hotel-type-info">
          {hotelPkg?.Rooms &&
            hotelPkg?.Rooms.map((room, index) => (
              <>
                <p className="mb-1 font-weight-bold">Room: {index + 1}</p>

                <div className="d-flex align-item-center justify-content-between flexwrap">
                  <span className="font-weight-bold">Room Type: </span>
                  <span className="font-weight-bold">{room.RoomName} </span>
                </div>
              </>
            ))}

          <ul className="info-item-flexbox mb-1">
            {hotelPkg?.Rooms && (
              <>
                <li>
                  <p className="date-item">Amount:</p>
                </li>
                <li>
                  <p className="date-item-1">
                    {Number(displayTotal).toFixed(2)}
                  </p>
                </li>
              </>
            )}
          </ul>
        </div>
      </Panel>
    </Collapse>
  );
};

export default BuildCardHotelDetail;
