let PassengerData = {
  title: "",
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  gender: "",
  areaCode: "",
  ffNumber: "",
  paxType: "",
  mobile: "",
  passportNumber: "",
  passengerNationality: "",
  passportDOI: "",
  passportDOE: "",
  passportIssuedCountry: "",
  seatPref: "",
  mealPref: "",
  baggage: "",
  address1: "",
  tickets: [
    {
      ticketNumber: "",
      sourceTicketType: "flight",
    },
  ],
  returnTickets: [
    {
      ticketNumber: "",
      sourceTicketType: "flight",
    },
  ],
  lstSpecialServiceRequest: [{}],
  supplierParameters: "",
  countryCode: "",
};
let flightSegment = {
  isReturn: false,
  origin: "",
  destination: "",
  departureDateTime: "",
  arrivalDateTime: "",
  journeyDuration: "",
  flightNumber: "",
  operatingAirline: "",
  equipmentType: "",
  marketingAirline: "",
  departureTerminal: "",
  arrivalTerminal: "",
};

let flightBaggage = {
  airline: "",
  paxType: "",
  baggageInfo: "",
  cityPair: "",
  cabinBaggageInfo: "",
};

let flightDeatails = [
  {
    validatingCarrier: "",
    pccCode: "",
    supplierName: "",
    supplierParameter: "",
    adultCount: 1,
    childCount: 1,
    infantCount: 1,
    flightSegments: [flightSegment],
    fareFamilies: [
      {
        purchaseType: "",
        fareType: "",
        currency: "",
        supplierParameter: "",
        flightFares: [],
        fareRules: [
          {
            paxType: "",
            cityPair: "",
            fareBasis: "",
            supplierParameter: "",
            ruleDetails: "",
          },
        ],
        baggage: [flightBaggage],
        miniRules: [],
        segmentInfos: [
          {
            cityPair: "",
            bookingClass: "",
            seatRemaining: 0,
            cabinClass: "",
          },
        ],
        isRefundable: false,
        commission: 0,
        plb: 0,
        markup: 0,
        netFare: 0,
        AdultnetFare: "",
        ChildNetFare: "",
        InfantNetFare: "",
      },
    ],
    extraServices: [
      {
        type: "Meal",
        code: "VGSW",
        price: 180.0,
        currency: "INR",
        quantity: 1,
        descType: "Direct",
        wayType: "Full",
        origin: "DEL",
        destination: "HYD",
        text: "Cucumber, tomato, cheese in multigrain bread",
      },
    ],
    standardSSRs: [],
    traceId: "",
    flightRemarks: [],
  },
];

let sepSample = {
  traceId: "",
  userId: "",
  roleType: "",
  mappingType: "Separate",
  bookingDate: "",
  flightDetails: [...flightDeatails],
  passengers: [PassengerData],
  gstDetails: {
    gstAddressLine1: "",
    gstAddressLine2: "",
    gstCity: "",
    gstState: "",
    gstpinCode: "",
    gstEmailId: "",
    gstNumber: "",
    gstPhoneNo: "",
    gstCompanyName: "",
  },
};

const SeprateRequestCreator = (formData, flightType) => {
  sepSample.bookingDate = formData.bookingDate;
  sepSample.traceId = getUnieqTraceId();
  sepSample.userId = formData.userId ? formData.userId : "";
  if (formData.userId) {
    sepSample.roleType = 5;
  }
  sepSample.passengers = mappedPassengers(
    formData.passengers,
    formData.CustomerEmail,
    formData.CustomerMobile
  );
  addonwardFlightDetails(formData);

  if (flightType != 1) {
    addReturnFlightDetails(formData);
  }
  sepSample.flightDetails[0].fareFamilies[0].currency = formData.currency;

  return sepSample;
};

const addonwardFlightDetails = (formData) => {
  flightBaggage.baggageInfo = formData.onwardflightsegments[0].GdsCheckInWeight;
  flightBaggage.cabinBaggageInfo =
    formData.onwardflightsegments[0].GdsBagWeight;
  flightBaggage.cityPair =
    formData.onwardflightsegments[0].source +
    "-" +
    formData.onwardflightsegments[0].destination;

  sepSample.flightDetails[0].adultCount = formData.numAdults;
  sepSample.flightDetails[0].childCount = formData.numChlidrens;
  sepSample.flightDetails[0].infantCount = formData.numInfants;
  sepSample.flightDetails[0].flightSegments = mapSegment(
    formData.onwardflightsegments,
    false
  );
  if (formData.numAdults > 0) {
    let p = AdultFlightFares(formData.AdultBaseFare, formData.Adulttaxes, 1);

    sepSample.flightDetails[0].fareFamilies[0].flightFares.push(...p);

    sepSample.flightDetails[0].fareFamilies[0].AdultnetFare = Number(
      formData.AdultBaseFare + formData.Adulttaxes + formData.Adultconvfee
    );
  }
  if (formData.numChlidrens > 0) {
    let p = ChildFlightFares(formData.ChildBaseFare, formData.Childtaxes, 1);

    sepSample.flightDetails[0].fareFamilies[0].flightFares.push(...p);
    sepSample.flightDetails[0].fareFamilies[0].ChildNetFare = Number(
      formData.ChildBaseFare + formData.Childtaxes + formData.Childconvfee
    );
  }

  if (formData.numInfants > 0) {
    let p = InfantFlightFares(formData.InfantBaseFare, formData.infanttaxes, 1);

    sepSample.flightDetails[0].fareFamilies[0].flightFares.push(...p);
    sepSample.flightDetails[0].fareFamilies[0].InfantNetFare = Number(
      formData.InfantBaseFare + formData.infanttaxes + formData.Infantconvfee
    );
  }
};

const addReturnFlightDetails = (formData) => {
  let flightBaggage = {
    airline: "",
    paxType: "",
    baggageInfo: "",
    cityPair: "",
    cabinBaggageInfo: "",
  };
  flightBaggage.baggageInfo = formData.returnflightsegments[0].GdsCheckInWeight;
  flightBaggage.cabinBaggageInfo =
    formData.returnflightsegments[0].GdsBagWeight;
  flightBaggage.cityPair =
    formData.returnflightsegments[0].source +
    "-" +
    formData.returnflightsegments[0].destination;
  sepSample.flightDetails[1] = {
    validatingCarrier: "",
    pccCode: "",
    supplierName: "",
    supplierParameter: "",
    adultCount: 1,
    childCount: 1,
    infantCount: 1,
    flightSegments: [flightSegment],
    fareFamilies: [
      {
        purchaseType: "Instant",
        fareType: "Publish",
        currency: "INR",
        supplierParameter: "",
        flightFares: [],
        fareRules: [
          {
            paxType: "",
            cityPair: "",
            fareBasis: "",
            supplierParameter: "",
            ruleDetails: "",
          },
        ],
        baggage: [flightBaggage],
        miniRules: [],
        segmentInfos: [
          {
            cityPair: "",
            bookingClass: "",
            seatRemaining: 0,
            cabinClass: "",
          },
        ],
        isRefundable: false,
        commission: 0,
        plb: 0,
        markup: 0,
        netFare: 0,
        AdultnetFare: "",
        ChildNetFare: "",
        InfantNetFare: "",
      },
    ],

    standardSSRs: [],
    traceId: "",
    flightRemarks: [],
  };
  sepSample.flightDetails[1].adultCount = formData.numAdults;
  sepSample.flightDetails[1].childCount = formData.numChlidrens;
  sepSample.flightDetails[1].infantCount = formData.numInfants;
  sepSample.flightDetails[1].flightSegments = mapSegment(
    formData.returnflightsegments,
    true
  );
  if (formData.numAdults > 0) {
    let p = AdultFlightFares(
      formData.AdultBaseFareReturn,
      formData.AdulttaxesReturn,
      2
    );

    sepSample.flightDetails[1].fareFamilies[0].flightFares.push(...p);

    sepSample.flightDetails[1].fareFamilies[0].AdultnetFare = Number(
      formData.AdultBaseFareReturn +
        formData.AdulttaxesReturn +
        formData.AdultconvfeeReturn
    );
  }
  if (formData.numChlidrens > 0) {
    let p = ChildFlightFares(
      formData.ChildBaseFareReturn,
      formData.ChildtaxesReturn,
      2
    );

    sepSample.flightDetails[1].fareFamilies[0].flightFares.push(...p);
    sepSample.flightDetails[1].fareFamilies[0].ChildNetFare = Number(
      formData.ChildBaseFareReturn +
        formData.ChildtaxesReturn +
        formData.ChildconvfeeReturn
    );
  }

  if (formData.numInfants > 0) {
    let p = InfantFlightFares(
      formData.InfantBaseFareReturn,
      formData.infanttaxesReturn,
      2
    );

    sepSample.flightDetails[1].fareFamilies[0].flightFares.push(...p);
    sepSample.flightDetails[1].fareFamilies[0].InfantNetFare = Number(
      formData.InfantBaseFareReturn +
        formData.infanttaxesReturn +
        formData.InfantconvfeeReturn
    );
  }
};
const mapSegment = (seg, isreturn) => {
  return seg.map((item, index) => {
    let temp = {
      ...flightSegment,
    };
    temp.pnr = item.AirlinePnr ? item.AirlinePnr : "";
    temp.origin = item.source;
    temp.destination = item.destination;
    temp.flightNumber = item.FlightNo;
    temp.isReturn = isreturn;
    temp.marketingAirline = item.AirlinePnr;
    temp.arrivalDateTime = mapDateTime(item.ArivalDate, item.ArrivalTime);
    temp.departureDateTime = mapDateTime(
      item.DepartureDate,
      item.DepartureTime
    );
    return temp;
  });
};
const AdultFlightFares = (a, t, o, type) => {
  let arr = [
    {
      amount: a,
      paxType: "ADT",
      fareTag: "Base",
      fareCode: "Base",
      fareDescription: "",
      triptype: type,
    },
    {
      amount: t,
      paxType: "ADT",
      fareTag: "Tax",
      fareCode: "Tax",
      fareDescription: "",
      triptype: type,
    },
    // {
    //   amount: o,
    //   paxType: "ADT",
    //   fareTag: "Tax",
    //   fareCode: "OtherCharges",
    //   fareDescription: "",
    //   triptype: type,
    // },
  ];

  return arr;
};
const ChildFlightFares = (a, t, o, type) => {
  let arr = [
    {
      amount: a,
      paxType: "CHD",
      fareTag: "Base",
      fareCode: "Base",
      fareDescription: "",
      triptype: type,
    },
    {
      amount: t,
      paxType: "CHD",
      fareTag: "Tax",
      fareCode: "Tax",
      fareDescription: "",
      triptype: type,
    },
    // {
    //   amount: o,
    //   paxType: "CHD",
    //   fareTag: "Tax",
    //   fareCode: "OtherCharges",
    //   fareDescription: "",
    //   triptype: type,
    // },
  ];

  return arr;
};
const InfantFlightFares = (a, t, o, type) => {
  let arr = [
    {
      amount: a,
      paxType: "INF",
      fareTag: "Base",
      fareCode: "Base",
      fareDescription: "",
      triptype: type,
    },
    {
      amount: t,
      paxType: "INF",
      fareTag: "Tax",
      fareCode: "Tax",
      fareDescription: "",
      triptype: type,
    },
    // {
    //   amount: o,
    //   paxType: "INF",
    //   fareTag: "Tax",
    //   fareCode: "OtherCharges",
    //   fareDescription: "",
    //   triptype: type,
    // },
  ];

  return arr;
};

const mapDateTime = (d, t) => {
  return d.format("YYYY-MM-DD") + "T" + t.format("HH:mm:ss");
};

const mappedPassengers = (pas, email, mobile) => {
  return pas.map((origin, index) => {
    let temp = {
      ...PassengerData,
    };
    temp.title = origin.title;
    temp.firstName = origin.FirstName;
    temp.lastName = origin.LastName;
    temp.dob = origin.dob.format("YYYY-MM-DD");
    temp.tickets[0].ticketNumber = origin.eticket;
    temp.paxType = origin.PaxType;
    temp.passportIssuedCountry = origin.pcountry;
    temp.passportNumber = origin.pno;
    temp.passportDOE = origin.pdoe ? origin.pdoe.format("YYYY-MM-DD") : "";
    temp.passportDOI = origin.pdoe ? origin.pdoi.format("YYYY-MM-DD") : "";
    temp.mealPref = origin.mealPref ? origin.mealPref : "";
    temp.seatPref = origin.seatPref ? origin.seatPref : "";
    temp.baggage = origin.baggage ? origin.baggage : "";
    if (index == 0) {
      temp.email = email;
      temp.mobile = mobile;
    }

    return temp;
  });
};
const getUnieqTraceId = () => {
  let random = Math.floor(Math.random() * 100);
  let d = Date.now();
  return "tr" + d + "rn" + random;
};
export { SeprateRequestCreator };
