import React from "react";
import { Route, Switch } from "react-router-dom";
import AdminRoute from "../../helpers/AdminProtection";

import APILogs from "../../admin/Logs/APIAccessLogs";
import LedgerStatement from "../../admin/LedgerStatement/LedgerStatement";
import AccessPermissions from "../../admin/AccessPermissions/AccessPermissions";
import ActivitiesCommissions from "../../admin/AdminMarkup/ActivitiesCommissions";
import Activitiescategory from "../../admin/Activitiescategory/Activitiescategory";
import Transfercategory from "../../admin/ActivitiesTransfer/Transfercategory";
import Transferslab from "../../admin/ActivitiesTransfer/Transferslab";
import CreateTicket from "../../admin/ActivitiesTransfer/CreateTicket";
import Activitiesoption from "../../admin/Activitiesoption/Activitiesoption";
import Activitytimeslot from "../../admin/Activitytimeslot/Activitytimeslot";
import Activitytourrate from "../../admin/Activitytourrate/Activitytourrate";
import AddProvider from "../../admin/SupplierConfiguration/AddProvider";
import Admin from "../../admin/Permissions/Admin/Admin";
import AdminUser from "../../admin/AdminUser/AdminUser";
import AgentRegistration from "../../admin/AgentRegistration/AgentRegistration";
import Banners from "../../admin/Banners/Banners";
import BookingOffline from "../../admin/BookingOffline/BookingOffline";
import BusOperatorsService from "../../admin/OperatorsService/BusOperatorsService";
import CMS from "../../admin/CMS/cms";
import CancelRequest from "../../admin/CancelRequest/CancelRequest";
import CityHotel from "../../admin/CityHotel/CityHotel";
import CmsTermsOffers from "../../admin/CmsTermsOffers/CmsTermsOffers";
import ContactForm from "../../admin/ContactForms/ContactForm";
import Coupons from "../../admin/Coupons/Coupons";

import Dashboard from "../../admin/Dashboard/Dashboard";
import Driverinfo from "../../admin/Driverinfo/Driverinfo";
// import DBLogs from "../../admin/Logs/DBLogs";
import Deals from "../../admin/Deals/Deals";

import FlightEnquires from "../../admin/FlightEnquires/FlightEnquires";
// import FlightsMarkup from "../../admin/AdminMarkup/FlightsCommissions";
import FlightsCommissions from "../../admin/AdminMarkup/FlightsCommissions";
import HotelCommissions from "../../admin/AdminMarkup/HotelsCommissions";
import BusesCommissions from "../../admin/AdminMarkup/BusesCommissions";
import ManageAirlines from "../../admin/ManageAirlines/ManageAirlines";
import ManageBalance from "../../admin/ManageBalance/ManageBalance";
import ManageBus from "../../admin/ManageBus/ManageBus";
import Membershipinfo from "../../admin/Membershipinfo/Membershipinfo";
import NotFound from "../../components/ErrorPages/NotFound";
import PGLogs from "../../admin/Logs/PGLogs";

import PackagesDetailed from "../../admin/PackagesDetailed/PackagesDetailed";
import PartPayment from "../../admin/PartPayment/PartPayment";
import Promo from "../../admin/Promo/Promo";
import ProviderApi from "../../admin/SupplierConfiguration/ProviderApi";

import OperatorsService from "../../admin/OperatorsService/OperatorsService";
import B2Bpg from "../../admin/B2BPG/B2Bpg.jsx";
import BankDetails from "../../admin/BankDetails/BankDetails";

import Reports from "../../admin/Reports/Reports";
import Role from "../../admin/Role/Role";
import Settings from "../../admin/Settings/Settings";
import Siteusers from "../../admin/Siteusers/Siteusers";
import StoreVistors from "../../admin/Storevistors/Storevistors";
import SubscriberReport from "../../admin/SubscriberReport/SubscriberReport";
import SuperBusTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperBusTicket";
import SuperFlightTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperFlightTicket";
import SuperHotelTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperHotelTicket";
import TravelCalendar from "../../admin/TravelCalendar/TravelCalendar";
import Unauthorized from "../../components/ErrorPages/Unauthorized";
import Error404 from "../../components/ErrorPages/Error404";
import ViewPackages from "../../admin/ViewPackages/ViewPackages";
import ActivitiesPackages from "../../admin/Packages/ActivitiesPackages";
// import AirRefundTicket from "../../admin/AirRefundTicket/AirRefundTicket";
// import AirRefundCancellation from "../../admin/AirRefundCancellation/AirRefundCancellation";
// import Cartypes from "../../admin/Cartypes/Cartypes";
// import Carsorder from "../../admin/Carsorder/Carsorder";
// import Carsmapping from "../../admin/Carsmapping/Carsmapping";
// import Carsavailability from "../../admin/Carsavailability/Carsavailability";
import Blog from "../../admin/Blog/Blog";
import BlogComments from "../../admin/BlogComments/BlogComments";
import ResheduleRequest from "../../admin/ResheduleRequest/ResheduleRequest";
import Crm from "../../admin/crm/crm";
// import Discounts from "../../components/Discounts/Discounts";
import SetCommission from "../../admin/SetCommission/SetCommission";
// import ToursExtranetPackages from "../../admin/ToursExtranet/Packages";
// import ToursExtranetFlights from "../../admin/ToursExtranet/Flights";
// import ToursExtranetHotels from "../../admin/ToursExtranet/Hotels";
// import ToursExtranetTransfers from "../../admin/ToursExtranet/Transfers";
// import ToursExtranetVendor from "../../admin/ToursExtranet/Vendor";
import InsurancerReports from "../../admin/InsuranceReports/InsurancerReports";
import OfflineEnquiries from "../../admin/OfflineEnquiries/OfflineEnquiries";
import Margins from "../../admin/Margins/Margins";
import SuperActivitiesTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperActivitiesTicket";
import SuperBuildPackageTicket from "../../admin/SuperAdminBookingReports/SuperReportTickets/SuperBuildPackageTicket";
import CmsFareRules from "../../admin/CmsFareRules/CmsFareRules";
import HelpInfo from "../../admin/HelpInfo/HelpInfo";
import ReviewRating from "../../admin/ReviewRating/ReviewRating";
import Hotelfacilities from "../../admin/Hotelfacilities/Hotelfacilities";
import Hotelroomtypes from "../../admin/Hotelroomtypes/Hotelroomtypes";
import Hotelamenities from "../../admin/Hotelamenities/Hotelamenities";
import Hotelimagetypes from "../../admin/Hotelimagetypes/Hotelimagetypes";
import Propertytype from "../../admin/Propertytype/Propertytype";
import Aroundthishotel from "../../admin/Aroundthishotel/Aroundthishotel";
import Bedtype from "../../admin/Bedtype/Bedtype";
import Managehotel from "../../admin/Managehotel/Managehotel";
import Managerooms from "../../admin/Managerooms/Managerooms";
import BookingLimit from "../../admin/BookingLimit/BookingLimit";

import Managepolicies from "../../admin/Managepolicies/Managepolicies";

import Manageimages from "../../admin/Manageimages/Manageimages";
import Managevideos from "../../admin/Managevideos/Managevideos";
import Managearoundhotel from "../../admin/Managearoundhotel/Managearoundhotel";
import VisaDocument from "../../admin/VisaDocument/VisaDocument";
import Visafaq from "../../admin/Visafaq/Visafaq";
import ManageVisaMaster from "../../admin/ManageVisamaster/Managervisamaste";
import EmbassyDetail from "../../admin/EmbassyDetail/EmbassyDetails";
import DocumentReqdForVisa from "../../admin/DocumentRequiredmentforvisa/DocumentReqdForVisa";
import ActivitiesCities from "../../admin/ActivitiesCities/ActivitiesCities";

const AdminNavigations = ({ accessList, accessToken }) => {
  return (
    <div className="mob-M-Top">
      <Switch>
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path={["/", "/admin/dashboard"]} component={Dashboard} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/role" component={Role} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/accesspermissions" component={AccessPermissions} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/provider" component={AddProvider} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/user" component={AdminUser} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/ledger-statement" component={LedgerStatement} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/operator" component={OperatorsService} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/b2b" component={B2Bpg} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/bankdetails" component={BankDetails} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/packagesdetailed" component={PackagesDetailed} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/viewpackages" component={ViewPackages} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activities" component={ActivitiesPackages} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitiescommissions" component={ActivitiesCommissions} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitiescategory" component={Activitiescategory} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitiesCities" component={ActivitiesCities} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitiesoption" component={Activitiesoption} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitytimeslot" component={Activitytimeslot} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/activitytourrate" component={Activitytourrate} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/agentregistration" component={AgentRegistration} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/createticket" component={CreateTicket} />
      
        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/discounts"
          component={Discounts}
        /> */}
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/set-commission" component={SetCommission} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/cityhotel" component={CityHotel} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/termsofferscms" component={CmsTermsOffers} />

        <AdminRoute exact accessList={accessList} accessToken={accessToken} path={"/admin/blog"} component={Blog} />
        <AdminRoute exact accessList={accessList} accessToken={accessToken} path={"/admin/commentsblog"} component={BlogComments} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/bookoffline" component={BookingOffline} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/coupons" component={Coupons} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/deals" component={Deals} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/flightcommissions" component={FlightsCommissions} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/hotelcommissions" component={HotelCommissions} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/manageairlines" component={ManageAirlines} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/managebalance" component={ManageBalance} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/membershipinfo" component={Membershipinfo} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/partpayment" component={PartPayment} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/promo" component={Promo} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/storevistors" component={StoreVistors} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/apiprovider" component={ProviderApi} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/reports" component={Reports} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/subscriberreport" component={SubscriberReport} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/insurancereports" component={InsurancerReports} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/banners" component={Banners} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/travelcalendar" component={TravelCalendar} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/busoperator" component={BusOperatorsService} />
        {/* 
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightoperator"
          component={FlightOperatorsService}
        /> */}

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/buscommissions" component={BusesCommissions} />

        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/refundcancellation"
          component={AirRefundCancellation}
        /> */}
        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/refundticket"
          component={AirRefundTicket}
        /> */}
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/Siteusers" component={Siteusers} />

        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/flightticket"
          render={() => <FlightTicket />}
        /> */}
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/CMS" component={CMS} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/apilogs" component={APILogs} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/pglogs" component={PGLogs} />

        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/dblogs"
          component={DBLogs}
        /> */}
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/settings" component={Settings} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/hotelfacilities" component={Hotelfacilities} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/hotelroomtypes" component={Hotelroomtypes} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/hotelamenities" component={Hotelamenities} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/hotelimagetypes" component={Hotelimagetypes} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/propertytype" component={Propertytype} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/aroundthishotel" component={Aroundthishotel} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/bedtype" component={Bedtype} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managehotel" component={Managehotel} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managerooms" component={Managerooms} />

        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managepolicies" component={Managepolicies} />

        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/manageimages" component={Manageimages} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managearoundhotel" component={Managearoundhotel} />

        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managevideos" component={Managevideos} />
        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/driverinfo" component={Driverinfo} />

        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsmapping"
          component={Carsmapping}
        />

        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsavailability"
          component={Carsavailability}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          path="/admin/carsorder"
          component={Carsorder}
        />
         <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/cartypes"
          component={Cartypes}
        /> */}

        <AdminRoute accessList={accessList} accessToken={accessToken} path="/admin/managebus" component={ManageBus} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/admin" component={Admin} />

        <Route exact path="/admin/flight/ticket" component={SuperFlightTicket} />
        <Route exact path="/admin/hotel/ticket" component={SuperHotelTicket} />
        <Route exact path="/admin/bus/ticket" component={SuperBusTicket} />

        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/contactforms" component={ContactForm} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/flightenquiries" component={FlightEnquires} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/crm" component={Crm} />
        {/* <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-packages"
          component={ToursExtranetPackages}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-hotels"
          component={ToursExtranetHotels}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-flights"
          component={ToursExtranetFlights}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-transfers"
          component={ToursExtranetTransfers}
        />
        <AdminRoute
          accessList={accessList}
          accessToken={accessToken}
          exact
          path="/admin/toursextranet-vendor"
          component={ToursExtranetVendor}
        /> */}
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/offlineEnquiries" component={OfflineEnquiries} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/margins" component={Margins} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/CmsFareRules" component={CmsFareRules} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/HelpInfo" component={HelpInfo} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/bookinglimit" component={BookingLimit} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/reviews-rating" component={ReviewRating} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/visaDocs" component={VisaDocument} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/docsReqdVisa" component={DocumentReqdForVisa} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/embassyDetails" component={EmbassyDetail} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/managevisa" component={ManageVisaMaster} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/visafaqs" component={Visafaq} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/transfercategory" component={Transfercategory} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/transferslab" component={Transferslab} />
        <AdminRoute accessList={accessList} accessToken={accessToken} exact path="/admin/createTicket" component={CreateTicket} />
       
       
        <Route exact path="/admin/cancelrequest" component={CancelRequest} />
        <Route exact path="/admin/reshedule-request" component={ResheduleRequest} />
        <Route exact path="/admin/activities/ticket" component={SuperActivitiesTicket} />
        <Route exact path={"/admin/buildpackage/ticket"} component={SuperBuildPackageTicket} />

        <Route exact path="/unauthorized" component={Unauthorized} />
        <Route exact path="/404" component={Error404} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
};

export default AdminNavigations;
