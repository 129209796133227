import React from "react";
import { Result } from "antd";
import "./unauth.scss";
const CustomNoResultFound = ({ title }) => {
  return (
    <div className="error-404">
      <Result status="500" title={title} />
    </div>
  );
};

export default CustomNoResultFound;
