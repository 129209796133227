import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Layout,
  Form,
  Select,
  Button,
  Card,
  Input,
  Table,
  Popover,
  message,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import { CloseOutlined } from "@ant-design/icons";
import UpdatedComponent from "../ExportExcel";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
function OfflineEnquiries(props) {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [offlineEnquires, setOfflineEnquires] = useState([]);
  const [showVisaSearchBox, setVisaShowSearchBox] = useState(false);
  const [visaEnquires, setVisaEnquires] = useState([]);
  const [insuranceEnquires, setInsuarnceEnquires] = useState([]);
  const [showInsuranceSearchBox, setInsuranceShowSearchBox] = useState(false);
  const [serviceType, setServiceType] = useState(0);
  const tableColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
    },
    {
      title: "Trip Type",
      dataIndex: "TripType",
    },
    {
      title: "Source City",
      dataIndex: "SourceCity",
      sorter: (a, b) => a.SourceCity.localeCompare(b.SourceCity),
    },
    {
      title: "Destination City",
      dataIndex: "DestinationCity",
      sorter: (a, b) => a.DestinationCity.localeCompare(b.DestinationCity),
    },
    {
      title: "Prefered Class",
      dataIndex: "PreferredClass",
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
    },

    {
      title: "Return Date",
      dataIndex: "EndDate",
    },

    {
      title: "NumberOfPassangers",
      dataIndex: "NumberOfPassengers",
    },
  ];
  const visaColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
    },
    {
      title: "Purpose of Travel",
      dataIndex: "VisitPurpose",
      render: (VisitPurpose) => getVisaType(VisitPurpose),
    },
    {
      title: "Type of Visa",
      dataIndex: "TypeofVisa",
      render: (TypeofVisa) => getVisaType(TypeofVisa),
      sorter: (a, b) => a.TypeofVisa - b.TypeofVisa,
    },
    {
      title: "Nationality",
      dataIndex: "Nationality",
      sorter: (a, b) => a.Nationality.localeCompare(b.Nationality),
    },
    {
      title: "Travel Date",
      dataIndex: "TravelDate",
      render: (TravelDate) => moment(TravelDate).format("DD-MM-YYYY"),
    },
  ];

  const insuranceColumns = [
    {
      title: "S. No.",
      dataIndex: "SNo",
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Contact Number",
      dataIndex: "ContactNumber",
    },

    {
      title: "Number Of Passengers",
      dataIndex: "NumberOfPassengers",
      sorter: (a, b) => a.NumberOfPassengers - b.NumberOfPassengers,
    },

    {
      title: "Destination",
      dataIndex: "Destination",
      sorter: (a, b) => a.Destination.localeCompare(b.Destination),
    },
    {
      title: "TripType",
      dataIndex: "TripType",
      sorter: (a, b) => a.TripType.localeCompare(b.TripType),
    },
    {
      title: "Departure Date",
      dataIndex: "DepartureDate",
      // render: (DepartureDate) => moment(DepartureDate).format("DD-MM-YYYY"),
    },

    {
      title: "Return Date",
      dataIndex: "ReturnDate",
      // render: (ReturnDate) => moment(ReturnDate).format("DD-MM-YYYY"),
    },
  ];

  const getVisaType = (data) => {
    switch (data) {
      case 1:
        return "Business";
      case 2:
        return "Leisure";
      case 3:
        return "Vacation";
      default:
        return "";
    }
  };
  /* === === === === Search Button Starts === === === === */

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = offlineEnquires.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setOfflineEnquires(searchResults);
    } else {
      // GetFlightEnquires();
    }
  };
  const searchVisaTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = visaEnquires.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setVisaEnquires(searchResults);
    } else {
      // GetFlightEnquires();
    }
  };
  const searchInsuranceTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = insuranceEnquires.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setVisaEnquires(searchResults);
    } else {
      // GetFlightEnquires();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const searchVisaData = () => {
    setVisaShowSearchBox(true);
  };
  const searchInsuranceData = () => {
    setVisaShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeVisaSearch = () => {
    setVisaShowSearchBox(false);
  };
  const closeInsuranceSearch = () => {
    setInsuranceShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const closeVisaSearchInput = (
    <div>
      <CloseOutlined onClick={closeVisaSearch} />
    </div>
  );
  const closeInsuranceSearchInput = (
    <div>
      <CloseOutlined onClick={closeInsuranceSearch} />
    </div>
  );

  /* === === === === Search Button ends === === === === */

  /* === === === === Excel Button starts === === === === */

  const handleExcel = () => {
    const excelData = offlineEnquires;
    props.exportExcel(excelData, "FlightEnquiresDetails");
  };
  const handleVisaExcel = () => {
    const excelData = visaEnquires.map((item) => {
      return {
        S_No: item.SNo,
        Name: item.Name,
        Email: item.Email,
        ContactNumber: item.ContactNumber,
        PurposeofTravel: getVisaType(item.VisitPurpose),
        TypeofVisa: getVisaType(item.TypeofVisa),
        Nationality: item.Nationality,
        TravelDate: moment(item.TravelDate).format("DD-MM-YYYY"),
      };
    });
    props.exportExcel(excelData, "VisaEnquiresDetails");
  };
  const handleInsuranceExcel = () => {
    const excelData = insuranceEnquires.map((item) => {
      return {
        S_No: item.SNo,
        Name: item.Name,
        Email: item.Email,
        ContactNumber: item.ContactNumber,
        // PurposeofTravel: getVisaType(item.VisitPurpose),
        // TypeofVisa: getVisaType(item.TypeofVisa),
        Nationality: item.Nationality,
        TravelDate: moment(item.TravelDate).format("DD-MM-YYYY"),
      };
    });
    props.exportExcel(excelData, "InsuranceEnquiresDetails");
  };

  /* === === === === Excel Button Ends === === === === */
  const onFinish = (values) => {
    setOfflineEnquires([]);
    setInsuarnceEnquires([]);
    setVisaEnquires([]);
    setServiceType(values.serviceType);
    if (values.serviceType === 11) {
      ApiClient.get(`admin/visaEnquiry`)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.map((item, index) => {
              return {
                SNo: index + 1,
                ...item,
                CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
                ReturnDate: item.EndDate
                  ? moment(item.EndDate).format("DD-MM-YYYY")
                  : null,
                ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
              };
            });
            setVisaEnquires(data);
          } else {
            setVisaEnquires([]);
          }
        })
        .catch((e) => {
          setVisaEnquires([]);
          console.log("api error", e);
        });
    } else if (values.serviceType === 12) {
      ApiClient.get(`admin/InsuranceEnquiry`)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.map((item, index) => {
              return {
                SNo: index + 1,
                ...item,
                CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
                ReturnDate: item.ReturnDate
                  ? moment(item.ReturnDate).format("DD-MM-YYYY")
                  : null,
                ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
              };
            });
            setInsuarnceEnquires(data);
          } else {
            setInsuarnceEnquires([]);
          }
        })
        .catch((e) => {
          setInsuarnceEnquires([]);
          console.log("api error", e);
        });
    } else {
      ApiClient.get(`admin/offlineEnquiry?serviceType=${values.serviceType}`)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data.map((item, index) => {
              return {
                SNo: index + 1,
                ...item,
                CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
                DepartureDate: moment(item.DepartureDate).format("DD-MM-YYYY"),
                ReturnDate: item.EndDate
                  ? moment(item.EndDate).format("DD-MM-YYYY")
                  : null,
                ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
              };
            });
            setOfflineEnquires(data);
          } else {
            setOfflineEnquires([]);
          }
        })
        .catch((e) => {
          setOfflineEnquires([]);
          console.log("api error", e);
        });
    }
  };

  const ServiceTypeHeader = () => (
    <>
      Service Type <HelpInfoHelper screenName={"/admin/offlineEnquiries"} />
    </>
  );

  const getServiceType = (type) => {
    switch (type) {
      case 7:
        return "Flight";
      case 8:
        return "Bus Hire";
      case 9:
        return "Car Hire";
      case 10:
        return "Forex";
      case 11:
        return "Visa";
      case 12:
        return "Insurance";
      default:
        break;
    }
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24} className="mb-2">
              <Card bordered={false}>
                <Form layout="inline" onFinish={onFinish}>
                  <Form.Item name="serviceType" label={<ServiceTypeHeader />}>
                    <Select
                      style={{ width: 200 }}
                      placeholder="Select Service Type"
                    >
                      <Option value={7}>Flight Enquiry</Option>
                      <Option value={8}>Bus Hire</Option>
                      <Option value={9}>Car Hire</Option>
                      <Option value={10}>Forex</Option>
                      <Option value={11}>Visa</Option>
                      <Option value={12}>Insurance</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            {serviceType === 7 ||
            serviceType === 8 ||
            serviceType === 9 ||
            serviceType === 10 ? (
              offlineEnquires.length > 0 ? (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>
                              View {getServiceType(serviceType)} Enquiries
                            </h5>
                            <p>{offlineEnquires.length} rows found !</p>
                          </div>
                        </div>
                        <div className="action-images">
                          {showSearchBox && (
                            <Input
                              placeholder="Search"
                              onChange={(e) => searchTableData(e)}
                              suffix={closeSearchInput}
                              style={{ padding: "0px 12px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img
                            src={search}
                            alt="search"
                            onClick={(e) => searchData()}
                          />
                          <img src={excel} alt="excel" onClick={handleExcel} />
                          {/* <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => GetFlightEnquires()}
                        /> */}
                        </div>
                      </div>
                      <div>
                        <Table
                          className="table-scroll-none"
                          bordered
                          dataSource={offlineEnquires}
                          columns={tableColumns}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Col>
              ) : (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <p>No Records found !</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              )
            ) : null}
            {serviceType === 11 ? (
              visaEnquires.length > 0 ? (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>
                              View {getServiceType(serviceType)} Enquiries
                            </h5>
                            <p>{visaEnquires.length} rows found !</p>
                          </div>
                        </div>
                        <div className="action-images">
                          {showVisaSearchBox && (
                            <Input
                              placeholder="Search"
                              onChange={(e) => searchVisaTableData(e)}
                              suffix={closeVisaSearchInput}
                              style={{ padding: "0px 12px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img
                            src={search}
                            alt="search"
                            onClick={(e) => searchVisaData()}
                          />
                          <img
                            src={excel}
                            alt="excel"
                            onClick={handleVisaExcel}
                          />
                          {/* <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => GetFlightEnquires()}
                        /> */}
                        </div>
                      </div>
                      <div>
                        <Table
                          className="table-scroll-none"
                          bordered
                          dataSource={visaEnquires}
                          columns={visaColumns}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Col>
              ) : (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <p>No Records found !</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              )
            ) : null}
            {serviceType === 12 ? (
              insuranceEnquires.length > 0 ? (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <div>
                            <h5>
                              View {getServiceType(serviceType)} Enquiries
                            </h5>
                            <p>{insuranceEnquires.length} rows found !</p>
                          </div>
                        </div>
                        <div className="action-images">
                          {showInsuranceSearchBox && (
                            <Input
                              placeholder="Search"
                              onChange={(e) => searchInsuranceTableData(e)}
                              suffix={closeInsuranceSearchInput}
                              style={{ padding: "0px 12px" }}
                            />
                          )}
                          &nbsp;&nbsp;
                          <img
                            src={search}
                            alt="search"
                            onClick={(e) => searchInsuranceData()}
                          />
                          <img
                            src={excel}
                            alt="excel"
                            onClick={handleInsuranceExcel}
                          />
                          {/* <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => GetFlightEnquires()}
                        /> */}
                        </div>
                      </div>
                      <div>
                        <Table
                          className="table-scroll-none"
                          bordered
                          dataSource={insuranceEnquires}
                          columns={insuranceColumns}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      </div>
                    </Card>
                  </div>
                </Col>
              ) : (
                <Col span={24}>
                  <div className="card-bt-gap">
                    <Card bordered={false}>
                      <div className="card-add-heading">
                        <div className="rows-count">
                          <p>No Records found !</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              )
            ) : null}
          </Row>
        </Content>
      </div>
    </div>
  );
}

export default UpdatedComponent(OfflineEnquiries);
