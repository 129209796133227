import React, { useState } from "react";
import { Button } from "antd";
import HotelsList from "./HotelsList/HotelsList";
import { useSytContext } from "../../../common/providers/SytProvider";

const HotelContainer = ({
  isFromPackage = false,
  listOfHotels,
  traceId,
  isHotelSearchLoad,
  showNetFare,
}) => {
  const [pagination, SetPagination] = useState(51);
  const [activeTab, setActiveTab] = useState(null);

  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };

  const { hotelSearchBanners } = useSytContext();

  let bannreIndex = -1;
  return (
    <div className="list-of-items">
      {listOfHotels.map((availableHotel, itemIndex) => {
        return itemIndex < pagination ? (
          <>
            {itemIndex != 0 &&
              itemIndex % 3 == 0 &&
              hotelSearchBanners[++bannreIndex] && (
                <div className="hotels_search_list">
                  <img
                    style={{
                      width: "100%",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      hotelSearchBanners[bannreIndex].path.substring(1)
                    }
                    data={
                      hotelSearchBanners[bannreIndex + 1]
                        ? null
                        : (bannreIndex = -1)
                    }
                  />{" "}
                </div>
              )}

            <HotelsList
              keyId={"hotelKey" + itemIndex}
              availableHotel={availableHotel}
              hotelTraceId={traceId}
              isFromPackage={isFromPackage}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              isHotelSearchLoad={isHotelSearchLoad}
              showNetFare={showNetFare}
            />
          </>
        ) : itemIndex === pagination ? (
          <div className="showmore-wrapper">
            <Button onClick={showMore}>Show More</Button>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default HotelContainer;
