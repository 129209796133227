import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Radio,
  Layout,
  message,
  TimePicker,
  Popover,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";

import {
  OnlyFutureDateValidator,
  PaxAgeValidator,
} from "../../helpers/CustomValidators";
import queryString from "query-string";
import { SeprateRequestCreator } from "./RequestCreatorHelper";
import ApiClient from "../../helpers/ApiClient";
import { MinusCircle } from "react-feather";
// import { useAuthContext } from "../../common/providers/AuthProvider";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;

const BookingOffline = () => {
  const [form] = Form.useForm();
  // const { user } = useAuthContext();
  const [tripype, setTriptype] = useState("1");
  const [bookigFromType, setBookigFromType] = useState(1);
  const [IsAgent, setIsAgent] = useState(0);
  const [BookingType, setBookingType] = useState(0);
  

  const [agentList, setAgentList] = useState([]);

  const [isAdult, setIsAdult] = useState(true);
  const [isChild, setIsChild] = useState(false);
  const [isinfant, setIsInfant] = useState(false);

  // const [loadingTicket, setLoadingTicket] = useState(true);

  const initialState = {
    id: "",
    pax: {},
    passengers: [],
    oneWaySegment: [],
    returnSegment: [],
    oneWayflightFare: {},
    oneWayBaggageInfo: [],
    returnBaggageInfo: [],
  };
  const [ticketData, setTicketData] = useState(initialState);

  //update get RefNo from Query string

  const params = queryString.parse(window.location.search);

  const getTicketDetails = (ref) => {
    // setLoadingTicket(true);
    ApiClient.get("admin/myBookings/details", {
      travelType: "1",
      id: ref,
    })
      .then((res) => {
        if (res.statusCode === 200) {
          try {
            let origin_code;
            let destination_code;
            let onewaysegment = [];
            let passengers = [];
            let returnsegment = [];
            let baggageInfo,
              cabinBaggageInfo,
              returnbaggageInfo,
              returncabinBaggageInfo;
            res.data[0].oneWayBaggageInfo.forEach((item) => {
              baggageInfo = item.BaggageInfo;
              cabinBaggageInfo = item.cabinBaggageInfo;
            });

            res.data[0].returnBaggageInfo ??
              res.data[0].returnBaggageInfo.forEach((item) => {
                returnbaggageInfo = item.BaggageInfo;
                returncabinBaggageInfo = item.cabinBaggageInfo;
              });

            res.data[0].oneWaySegment.forEach((item) => {
              let temporigin_Arr = item.origin
                .replace(/[{()}]/g, "")
                .split(" ");
              let tempdest_Arr = item.destination
                .replace(/[{()}]/g, "")
                .split(" ");
              origin_code = temporigin_Arr[temporigin_Arr.length - 1];
              destination_code = tempdest_Arr[tempdest_Arr.length - 1];
              let segmentObject = {
                source: origin_code,
                destination: destination_code,
                DepartureDate: moment(item.departureDateTime),
                ArrivalTime: moment(item.arrivalDateTime),
                FlightNo: item.flightNumber,
                DepartureTime: moment(item.departureDateTime),
                ArivalDate: moment(item.arrivalDateTime),
                GdsBagWeight: cabinBaggageInfo,
                GdsCheckInWeight: baggageInfo,
                AirlinePnr: res.data[0].pnr,
              };

              onewaysegment.push(segmentObject);
            });

            res.data[0].passengers.forEach((item) => {
              let passObject = {
                FirstName: item.firstName,
                LastName: item.lastName,
                dob: item.dob ? moment(item.dob) : "",
                PaxType: item.paxType,
                pcountry: item.passengerNationality,
                title: item.title,
                baggage: item?.PassengerRequiredInfo?.baggage,
                seatPref: item?.PassengerRequiredInfo?.seatPref,
                mealPref: item?.PassengerRequiredInfo?.mealPref,
                pno: item.passportNumber ? moment(item.passportNumber) : "",
                pdoi: item.passportDOI ? moment(item.passportDOI) : "",
                pdoe: item.passportDOE ? moment(item.passportDOE) : "",
              };

              passengers.push(passObject);
            });

            res.data[0].returnSegment.forEach((item) => {
              let temporigin_Arr = item.origin
                .replace(/[{()}]/g, "")
                .split(" ");
              let tempdest_Arr = item.destination
                .replace(/[{()}]/g, "")
                .split(" ");
              origin_code = temporigin_Arr[temporigin_Arr.length - 1];
              destination_code = tempdest_Arr[tempdest_Arr.length - 1];
              let returnObject = {
                source: origin_code,
                destination: destination_code,
                DepartureDate: moment(item.departureDateTime),
                ArrivalTime: moment(item.arrivalDateTime),
                FlightNo: item.flightNumber,
                DepartureTime: moment(item.departureDateTime),
                ArivalDate: moment(item.arrivalDateTime),
                GdsBagWeight: returncabinBaggageInfo,
                GdsCheckInWeight: returnbaggageInfo,
                AirlinePnr: res.data[0].returnPnr,
              };

              returnsegment.push(returnObject);
            });

            let final_data = {
              NumStops: res.data[0].oneWaySegment.length - 1,
              bookingDate: moment(res.data[0].bookingDate),
              onwardflightsegments: onewaysegment,
              numInfants: res.data[0].pax.infantCount,
              numChlidrens: res.data[0].pax.childCount,
              numAdults: res.data[0].pax.adults,
              CustomerMobile: res.data[0].guestMobileNo,
              CustomerEmail: res.data[0].guestEmaiId,
              currency: res.data[0].oneWayflightFare.currency,
              onwardRemarks: "",
              passengers: passengers,
              returnflightsegments: returnsegment,
              AdultBaseFare: res.data[0].oneWayflightFare.ADT_fare,
              Adulttaxes: res.data[0].oneWayflightFare.ADT_Tax,
              Adultgst: "",
              Adultconvfee: "",
              Adultnetfare: res.data[0].oneWayflightFare.ADT_NET_fare,
              ChildBaseFare: res.data[0].oneWayflightFare.CHD_fare,
              Childtaxes: res.data[0].oneWayflightFare.CHD_Tax,
              Childgst: "",
              Childconvfee: "",
              Childnetfare: res.data[0].oneWayflightFare.CHD_NET_fare,
              InfantBaseFare: res.data[0].oneWayflightFare.INF_fare,
              infanttaxes: res.data[0].oneWayflightFare.INF_Tax,
              Infantgst: "",
              Infantconvfee: "",
              infantNetfare: res.data[0].oneWayflightFare.INF_NET_fare,

              AdultBaseFareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.ADT_fare
                : "",
              AdulttaxesReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.ADT_Tax
                : "",
              AdultnetfareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.ADT_NET_fare
                : "",
              ChildnetfareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.CHD_NET_fare
                : "",

              ChildconvfeeReturn: "",
              ChildBaseFareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.CHD_fare
                : "",
              infanttaxesReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.INF_Tax
                : "",
              infantNetfareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.INF_NET_fare
                : "",
              InfantconvfeeReturn: "",
              InfantBaseFareReturn: res.data[0].returnflightFare
                ? res.data[0].returnflightFare.INF_fare
                : "",
            };
            if (final_data.numInfants) setIsInfant(true);
            if (final_data.numAdults) setIsAdult(true);
            if (res.data[0].tripType.replace(/\b\w/g, l => l.toUpperCase()) == "OneWay") {
              setTriptype("1");
            } else if (res.data[0].tripType.replace(/\b\w/g, l => l.toUpperCase()) == "RoundTrip") {
              setTriptype("2");
            } else {
              setTriptype("3");
            }

            if (final_data.numChlidrens) setIsChild(true);
            if (final_data.numInfants) setIsInfant(true);

            form.setFieldsValue({ ...final_data });
          } catch (e) { }
        }
        // setLoadingTicket(false);
      })
      .catch((error) => {
        // setLoadingTicket(false);
        console.error(error);
      });
  };

  useEffect(() => {
    if (params.ref && params.ref.indexOf("SYT-F") != -1) {
      getTicketDetails(params.ref);
    }
  }, []);
  const getAllUsers = () => {
    ApiClient.get(`admin/user/`)
      .then((res) => {
        if (res.status == 200) {
          let agents = res.data
            .filter((item, index) => item.Role == 5)
            .map((agent) => agent.userDetails);
          setAgentList(agents);
        }
      })
      .catch(() => { });
  };
  useEffect(() => {
    addOnwardSegment(1) 
    addPassender(1);
    getAllUsers();
  }, []);

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  // const getAirportLists = () => {
  //   setAirportLists(flightCities);
  // };
  // const getAirlineLists = () => {
  //   setAirlineLists(AirlineNames);
  // };
  const addPassender = (n) => {
    for (let index = 0; index < n; index++)
      document.getElementById("passenger_add_btn").click();
  };

  const validationMessage = {
    required: " ",
  };
  const addOnwardSegment = (n) => {
    for (let index = 0; index < n; index++)
      document.getElementById("add_onward_flight_details").click();
  };
  const addReturnSegment = (n) => {
    for (let index = 0; index < n; index++)
      document.getElementById("passenger_add_btn").click();
  };

  const updateIsADult = (val) => {
    // val=val.target.value

    if (val > 0) {
      setIsAdult(true);
    } else {
      setIsAdult(false);
    }
  };

  const updateIsInfant = (val) => {
    if (val > 0) {
      setIsInfant(true);
    } else {
      setIsInfant(false);
    }
  };

  const updateIsChild = (val) => {
    if (val > 0) {
      setIsChild(true);
    } else {
      setIsChild(false);
    }
  };

  const AdultForm = ({ isminus, field, add, index, remove }) => {
    const adOptions = (
      <>
        <Option value="Mr.">Mr.</Option>
        <Option value="Ms.">Ms.</Option>
        <Option value="Mrs.">Mrs.</Option>
      </>
    );
    const [titleOptions, setTitles] = useState(adOptions);
    const paxChanged = (val) => {
      if (val == "INF") {
        setTitles(<Option value="Mstr">Mstr .</Option>);
      } else {
        setTitles(adOptions);
      }
    };
    return (
      <>
        <Row gutter={16}>
          <Col className="gutter-class" md={3} xs={7}>
            <Form.Item
              label="Pax Type"
              {...field}
              name={[field.name, "PaxType"]}
              fieldKey={[field.fieldKey, "PaxType"]}
              rules={[{ required: true }]}
            >
              <Select onChange={paxChanged}>
                <Option value="ADT">Adult</Option>
                <Option value="CHD">Child</Option>
                <Option value="INF">Infant</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={2} xs={7}>
            <Form.Item
              label="Title"
              {...field}
              name={[field.name, "title"]}
              fieldKey={[field.fieldKey, "title"]}
              rules={[{ required: true }]}
            >
              <Select>{titleOptions}</Select>
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={5} xs={16}>
            <Form.Item
              label="First Name "
              {...field}
              name={[field.name, "FirstName"]}
              fieldKey={[field.fieldKey, "FirstName"]}
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter First Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={5} xs={24}>
            <Form.Item
              label="Last Name "
              {...field}
              name={[field.name, "LastName"]}
              fieldKey={[field.fieldKey, "LastName"]}
              rules={[{ required: true }]}
            >
              <Input placeholder="Enter Last Name" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={3} xs={24}>
            <Form.Item
              label="DOB"
              {...field}
              name={[field.name, "dob"]}
              fieldKey={[field.fieldKey, "dob"]}
              rules={[
                {
                  validator: (_, value) => {
                    let paxType = form.getFieldValue([
                      "passengers",
                      field.name,
                      "PaxType",
                    ]);
                    return PaxAgeValidator(paxType, value);
                  },
                },
              ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={4} xs={24}>
            <Form.Item
              label="E-ticket"
              {...field}
              name={[field.name, "eticket"]}
              fieldKey={[field.fieldKey, "eticket"]}
            >
              <Input placeholder="Enter e-ticket" />
            </Form.Item>
          </Col>
          <Col span={1} style={{ alignSelf: "center" }}>
            {isminus > 1 ? (
              <MinusCircle
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            ) : null}
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-class" md={4} xs={7}>
            <Form.Item
              label="PassPort Country"
              {...field}
              name={[field.name, "pcountry"]}
              fieldKey={[field.fieldKey, "pcountry"]}
            // rules={[{ required: true }]}
            >
              <Select>
                <Option value="in">India</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={5} xs={7}>
            <Form.Item
              label="Passport DOI"
              {...field}
              name={[field.name, "pdoi"]}
              fieldKey={[field.fieldKey, "pdoi"]}
            // rules={[{ required: true }]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={6} xs={16}>
            <Form.Item
              label="Passport DOE"
              {...field}
              name={[field.name, "pdoe"]}
              fieldKey={[field.fieldKey, "pdoe"]}
            // rules={[
            //   { required: true },
            //   { validator: OnlyFutureDateValidator },
            // ]}
            >
              <DatePicker />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={6} xs={24}>
            <Form.Item
              label="Passport Number "
              {...field}
              name={[field.name, "pno"]}
              fieldKey={[field.fieldKey, "pno"]}
            // rules={[{ required: true }]}
            >
              <Input placeholder="Enter Passport Number" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-class" md={4} xs={16}>
            <Form.Item
              label="Seat Request "
              {...field}
              name={[field.name, "seatPref"]}
              fieldKey={[field.fieldKey, "seatPref"]}
            >
              <Input placeholder="Seat Request" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={4} xs={16}>
            <Form.Item
              label="Meal Request "
              {...field}
              name={[field.name, "mealPref"]}
              fieldKey={[field.fieldKey, "mealPref"]}
            >
              <Input placeholder="Meal Request" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={4} xs={16}>
            <Form.Item
              label="Extra Baggage Request "
              {...field}
              name={[field.name, "baggage"]}
              fieldKey={[field.fieldKey, "baggage"]}
            >
              <Input placeholder="Extra Baggage Request" />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  const FlightDetails = ({ isminus, field, add, index, remove }) => {
    const origin = useRef();
    const destination = useRef();
    const dateBox = useRef();
    const [showDate, setShowDate] = useState(false);
    const handleOnSubmit = (ref) => {
      ref.current.focus();
      if (ref === dateBox) {
        setShowDate(true);
      }
    };
    return (
      <div>
        <div className="flight-title">
          <h6>Flight-{index + 1} Details</h6>
        </div>
        <Row gutter={16}>
          <Col span={8}>
            <AirportAutoComplete
              formItemProps={{
                label: "Source City",
                name: [field.name, "source"],
                fieldKey: [field.fieldKey, "source"],

                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              selectProps={{
                placeholder: "Select Source",
              }}
              refName={origin}
              focusRef={destination}
              handleOnSubmit={handleOnSubmit}
              dataFlip={""}
            />
          </Col>

          <Col span={8}>
            <AirportAutoComplete
              formItemProps={{
                label: "Destination",
                name: [field.name, "destination"],
                fieldKey: [field.fieldKey, "destination"],
                rules: [
                  {
                    required: true,
                  },
                ],
              }}
              selectProps={{
                placeholder: "Select Destination",
              }}
              refName={destination}
              focusRef={dateBox}
              handleOnSubmit={handleOnSubmit}
              dataFlip={""}
            />
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-class" md={8} xs={24}>
            <div>
              <Row gutter={16}>
                <Col className="gutter-class" md={12} xs={24}>
                  <Form.Item
                    label="Departure Date"
                    {...field}
                    name={[field.name, "DepartureDate"]}
                    fieldKey={[field.fieldKey, "DepartureDate"]}
                    rules={[
                      { required: true },
                      { validator: OnlyFutureDateValidator },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      className="train-search-btn"
                      format={"DD-MM-YYYY"}
                      disabledDate={disabledOriginDate}
                      //  onChange={() => setTogglePax(true)}
                      ref={dateBox}
                    // open={showDate}
                    // onOpenChange={() => {
                    //   setShowDate((prev) => !prev);
                    // }}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-class" md={12} xs={24}>
                  <Form.Item
                    label="Departure Time"
                    {...field}
                    name={[field.name, "DepartureTime"]}
                    fieldKey={[field.fieldKey, "DepartureTime"]}
                    rules={[{ required: true }]}
                  >
                    <TimePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-class" md={8} xs={24}>
            <div>
              <Row gutter={16}>
                <Col className="gutter-class" md={12} xs={24}>
                  <Form.Item
                    label="Arrival Date"
                    {...field}
                    name={[field.name, "ArivalDate"]}
                    fieldKey={[field.fieldKey, "ArivalDate"]}
                    rules={[
                      { required: true },
                      { validator: OnlyFutureDateValidator },
                    ]}
                  >
                    {/* <DatePicker
                      placeholder="Arrival Date"
                      style={{ width: "100%" }}
                    /> */}
                    <DatePicker
                      style={{ width: "100%" }}
                      className="train-search-btn"
                      format={"DD-MM-YYYY"}
                      disabledDate={disabledOriginDate}
                    //  onChange={() => setTogglePax(true)}
                    // ref={dateBox}
                    // open={showDate}
                    // onOpenChange={() => {
                    //   setShowDate((prev) => !prev);
                    // }}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-class" md={12} xs={24}>
                  <Form.Item
                    label="Arrival Time "
                    {...field}
                    name={[field.name, "ArrivalTime"]}
                    fieldKey={[field.fieldKey, "ArrivalTime"]}
                    rules={[{ required: true }]}
                  >
                    <TimePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="gutter-class" md={8} xs={24}>
            <Row gutter={16}>
              <Col className="gutter-class" md={12} xs={24}>
                <Form.Item
                  label="Flight No"
                  {...field}
                  name={[field.name, "FlightNo"]}
                  fieldKey={[field.fieldKey, "FlightNo"]}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Flight No." />
                </Form.Item>
              </Col>
              <Col className="gutter-class" md={12} xs={24}>
                <Form.Item
                  label="Airline PNR"
                  {...field}
                  name={[field.name, "AirlinePnr"]}
                  fieldKey={[field.fieldKey, "AirlinePnr"]}
                  rules={[{ required: true }]}
                >
                  <Input placeholder="Airline PNR" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col className="gutter-class" md={8} xs={24}>
            <Form.Item
              label="GDS PNR"
              {...field}
              name={[field.name, "GdsPnr"]}
              fieldKey={[field.fieldKey, "GdsPnr"]}
            >
              <Input placeholder="GDS PNR" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={8} xs={24}>
            <Form.Item
              label="Cabin Baggage"
              {...field}
              name={[field.name, "GdsBagWeight"]}
              fieldKey={[field.fieldKey, "GdsBagWeight"]}
            >
              {/* <Select >
                  <Option value="Kg">Kg</Option>
                  <Option value="pc">pc</Option>
                </Select> */}
              <Input style={{ width: "80%" }} placeholder="Cabin Baggage" />
            </Form.Item>
          </Col>
          <Col className="gutter-class" md={7} xs={22}>
            <Form.Item
              label="Checkin Baggae"
              {...field}
              name={[field.name, "GdsCheckInWeight"]}
              fieldKey={[field.fieldKey, "GdsCheckInWeight"]}
            >
              {/* <Select>
                  <Option value="Kg">Kg</Option>
                  <Option value="pc">pc</Option>
                </Select> */}
              <Input
                style={{ width: "80%" }}
                placeholder="Checkin Baggage"
                {...field}
              />
            </Form.Item>
          </Col>
          <Col span={1} style={{ alignSelf: "center" }}>
            {isminus > 1 ? (
              <MinusCircle
                className="dynamic-delete-button"
                onClick={() => remove(field.name)}
              />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  };

  // const onBookingTypeChange = (e) => {
  //   setBookigFromType(e);
  // };

  const onFormSubmit = (formData) => {
    // HERE f is form data
    // formData.BookingReference
    formData.BookingReference = params.ref;
    let requestData = SeprateRequestCreator(formData, tripype);

    if (params.mode != "edit") {
      ApiClient.post("admin/booking/offline", requestData).then((res) => {
        if (res.statusCode == 200) {
          message.success("Added SuccessFully");
        } else {
          message.error("Error addding Data");
        }
      });
    } else {
      ApiClient.put(
        "admin/booking/offline/" + params.ref,
        {},
        requestData
      ).then((res) => {
        if (res.statusCode == 200) {
          message.success("Flight Details Updated Successfully", 3);
        } else if (res.statusCode == 400) {
          message.error(" Unable to update the Flight Details", 3);
        } else {
          message.error(" Error Uploading Data", 3);
        }
      });
    }
  };
  const valdationFailed = () => {
    message.error("Please Check All Fields");
  };
  const onTripTypeChange = (e) => {
    setTriptype(e.target.value);
  };

  const onBookingTypeChange = (e) => {
    setBookigFromType(e);
  };

  const AgentType = (e) => {
    setIsAgent(e);
  };

  const SetBookingType = (e) => {
    setBookingType(e);
    console.log(e)
  };

  const BookingLabelTitle = () => (
    <>
      Booking From {"  "}
      <HelpInfoHelper screenName={"/admin/bookoffline"} />
    </>
  );
  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <Form
                initialValues={{ ...ticketData }}
                layout="vertical"
                form={form}
                onFinish={onFormSubmit}
                validateMessages={validationMessage}
                onFinishFailed={valdationFailed}
              >
                <Card>
                  <Row gutter={16}>
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item
                        label={<BookingLabelTitle />}
                        name="BookingFrom"
                        rules={[{ required: true }]}
                      >
                        <Select
                          onChange={onBookingTypeChange}
                          placeholder="Please select"
                          value={bookigFromType}
                        >
                          <Option value={1}>Self</Option>
                          <Option value={2}>Specific Partner</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col className="gutter-class" md={8} xs={24}>
                      <Form.Item
                        label="Booking Reference No"
                        name={"Booking Reference"}
                        fieldKey={["Booking Reference No"]}
                      >
                        <Input style={{ width: "80%" }} placeholder="Booking Reference No" />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" md={8} xs={24}>
                      {bookigFromType == 2 ? (
                        <Form.Item label="Partner" name="userId" rules={[{ required: true }]}>
                          <Select
                            onChange={AgentType}
                            value={IsAgent}
                            showSearch
                            placeholder="Select Agent"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {agentList.map((item, index) => (
                              <Option key={index} value={item.UserID}>
                                {`${item.FirstName} ${item.LastName}`}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : null}
                    </Col>
                    <Col className="gutter-row" md={6} xs={24}>
                      {IsAgent != 0 && (
                      <Form.Item
                        label="Booking Type"
                        name="Booking Type"
                        rules={[{ required: true }]}
                      >
                          <Select
                          // value={BookingType}
                          onChange={SetBookingType}
                          placeholder="Please select"
                        >
                          <Option value={1}>Flight</Option>
                          <Option value={4}>Activitie</Option>
                          <Option value={2}>Hotel</Option>
                        </Select>
                      </Form.Item>
                      )}
                    </Col>
                    <Col className="gutter-class" md={6} xs={24}>
                      {BookingType != 0 && (
                      <Form.Item
                        label="Booking Reference No"
                        name={"BookingReference"}
                        fieldKey={["Booking Reference No"]}
                      >
                        <Input style={{ width: "80%" }} placeholder="Booking Reference No" />
                      </Form.Item>
                      )}
                    </Col>
                  </Row>
                </Card>
                
                <div className="card-gap">
                  <Card title="Flight Type" bordered={false}>
                    <div>
                      {/* <Radio.Group name="FligtCountryType">
                          <Radio value="1">Domestic</Radio>
                          <Radio value="2">International</Radio>
                        </Radio.Group> */}
                      <Radio.Group
                        name="FligtType"
                        onChange={onTripTypeChange}
                        value={tripype}
                      >
                        <Radio value="1">Oneway</Radio>
                        <Radio value="2">Round trip</Radio>
                        <Radio value="3">Multi Destination</Radio>
                      </Radio.Group>
                    </div>
                  </Card>
                </div>
                
                <div className="card-gap">
                  <div className="flight-depart-details">
                    <h4>Depart Details</h4>
                    <Card title="Onward Flight Details" bordered={false}>
                      <div className="">
                        <Row gutter="16">
                          <Col className="gutter-class" md={8} xs={24}>
                            <Form.Item
                              label="No of Stops"
                              name="NumStops"
                              rules={[{ required: true }]}
                            >
                              <Select placeholder="0">
                                <Option value="0">0</Option>
                                <Option value="1">1</Option>
                                <Option value="2">2</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={8} xs={24}>
                            <Form.Item label="Booking Date" name="bookingDate">
                              <DatePicker
                                style={{ width: "100%" }}
                                format={"DD-MM-YYYY"}
                                disabledDate={disabledOriginDate}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col className="gutter-class" md={8} xs={24}>
                              <Form.Item label="Airline PNR">
                                <Input placeholder="Airline PNR" name="AirlinePnr" />
                              </Form.Item>
                            </Col> */}
                        </Row>
                      </div>
                      <div className="flight-details">
                        <Form.List name="onwardflightsegments">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => {
                                  return (
                                    <FlightDetails
                                      isminus={fields.length}
                                      field={field}
                                      index={index}
                                      key={field.key}
                                      remove={remove}
                                    />
                                  );
                                })}
                                {fields.length < 3 ? (
                                  <Button
                                    type="primary"
                                    id="add_onward_flight_details"
                                    onClick={() => add()}
                                  >
                                    Add Flight Details
                                  </Button>
                                ) : null}
                              </>
                            );
                          }}
                        </Form.List>
                      </div>
                    </Card>

                    {tripype == 2 ? (
                      <Card title="Return Flight Details" bordered={false}>
                        <div className="">
                          <Row gutter="16">
                            <Col className="gutter-class" md={8} xs={24}>
                              <Form.Item
                                label="No of Stops"
                                name="NumStops"
                                rules={[{ required: true }]}
                              >
                                <Select placeholder="0">
                                  <Option value="0">0</Option>
                                  <Option value="1">1</Option>
                                  <Option value="2">2</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={8} xs={24}>
                              <Form.Item
                                label="Booking Date"
                                name="BookingDate"
                              >
                                <DatePicker style={{ width: "100%" }} />
                              </Form.Item>
                            </Col>
                            {/* <Col className="gutter-class" md={8} xs={24}>
                                <Form.Item label="Airline PNR">
                                  <Input placeholder="API Ref" name="AirlinePnr" />
                                </Form.Item>
                              </Col> */}
                          </Row>
                        </div>
                        <div className="flight-details">
                          <Form.List name="returnflightsegments">
                            {(fields, { add, remove }) => {
                              return (
                                <>
                                  {fields.map((field, index) => {
                                    return (
                                      <FlightDetails
                                        isminus={fields.length}
                                        field={field}
                                        index={index}
                                        key={field.key}
                                        remove={remove}
                                      />
                                    );
                                  })}
                                  {fields.length < 3 ? (
                                    <Button
                                      type="primary"
                                      id="add_return_flight_details"
                                      onClick={() => add()}
                                    >
                                      Add Flight Details
                                    </Button>
                                  ) : null}
                                </>
                              );
                            }}
                          </Form.List>
                        </div>
                      </Card>
                    ) : null}

                    <Card title="Pax Details" bordered={false}>
                      <div className="">
                        <Row gutter="16">
                          <Col className="gutter-class" md={6} xs={24}>
                            <Row gutter="16">
                              <Col className="gutter-class" md={8} xs={8}>
                                <Form.Item
                                  label="Adults"
                                  name="numAdults"
                                  rules={[{ required: true }]}
                                >
                                  <Select onChange={updateIsADult}>
                                    <Option value="0">0</Option>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                    <Option value="6">6</Option>
                                    <Option value="7">7</Option>
                                    <Option value="8">8</Option>
                                    <Option value="9">9</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={8} xs={8}>
                                <Form.Item
                                  label="Children"
                                  name="numChlidrens"
                                  rules={[{ required: true }]}
                                >
                                  <Select onChange={updateIsChild}>
                                    <Option value="0">0</Option>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                    <Option value="6">6</Option>
                                    <Option value="7">7</Option>
                                    <Option value="8">8</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={8} xs={8}>
                                <Form.Item
                                  label="Infants"
                                  name="numInfants"
                                  rules={[{ required: true }]}
                                >
                                  <Select onChange={updateIsInfant}>
                                    <Option value="0">0</Option>
                                    <Option value="1">1</Option>
                                    <Option value="2">2</Option>
                                    <Option value="3">3</Option>
                                    <Option value="4">4</Option>
                                    <Option value="5">5</Option>
                                    <Option value="6">6</Option>
                                    <Option value="7">7</Option>
                                    <Option value="8">8</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item
                              label="Customer Email"
                              name="CustomerEmail"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Email" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item
                              label="Customer Mobile"
                              name="CustomerMobile"
                              rules={[{ required: true }]}
                            >
                              <Input placeholder="Enter Customer mobile" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-class" md={6} xs={24}>
                            <p>
                              <strong>* Note:</strong> E-ticket number is{" "}
                              <strong>Not</strong> mandatory
                            </p>
                          </Col>
                        </Row>
                      </div>
                      <div className="flight-details">
                        <Form.List name="passengers">
                          {(fields, { add, remove }) => {
                            return (
                              <>
                                {fields.map((field, index) => {
                                  return (
                                    <AdultForm
                                      isminus={fields.length}
                                      field={field}
                                      index={index}
                                      key={field.key}
                                      remove={remove}
                                    />
                                  );
                                })}
                                {fields.length < 9 ? (
                                  <Button
                                    type="primary"
                                    id="passenger_add_btn"
                                    onClick={() => add()}
                                  >
                                    Add Passenger
                                  </Button>
                                ) : null}
                              </>
                            );
                          }}
                        </Form.List>
                      </div>
                    </Card>

                    <Card
                      title={
                        tripype == 2 ? "Onward Fare Details" : "Fare Details"    // Spelled fixed by Ravi on 27/05
                      }
                      bordered={false}
                    >
                      <div>
                        <Row>
                          <Col span={8}>
                            <Form.Item
                              label="Currency"
                              name="currency"
                              rules={[{ required: true }]}
                            >
                              <Select>
                                <Option value="INR">INR</Option>
                                <Option value="USD">USD</Option>
                                <Option value="GBP">USD</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        {isAdult ? (
                          <Row gutter={16}>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Adult Fare"
                                name="AdultBaseFare"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Adult Taxes"
                                name="Adulttaxes"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="GST/ VAT" name="Adultgst">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Conv. Fee" name="Adultconvfee">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Net. Fare" name="Adultnetfare">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                        {isChild ? (
                          <Row gutter={16}>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Child Fare"
                                name="ChildBaseFare"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Child Taxes"
                                name="Childtaxes"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="GST/ VAT" name="Childgst">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Conv. Fee" name="Childconvfee">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Net. Fare" name="Childnetfare">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                        {isinfant ? (
                          <Row gutter={16}>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Infant Fare"
                                name="InfantBaseFare"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item
                                label="Infant Taxes"
                                name="infanttaxes"
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="GST/ VAT" name="Infantgst">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Conv. Fee" name="Infantconvfee">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                            <Col className="gutter-class" md={4} xs={24}>
                              <Form.Item label="Net. Fare" name="infantNetfare">
                                <Input placeholder="Amount " type="number" />
                              </Form.Item>
                            </Col>
                          </Row>
                        ) : null}
                      </div>
                    </Card>
                    {tripype == 2 && (
                      <Card title="Return Fare Details" bordered={false}>
                        <div>
                          <Row>
                            <Col span={8}>
                              <Form.Item
                                label="Currency"
                                name="currency"
                                rules={[{ required: true }]}
                              >
                                <Select>
                                  <Option value="INR">INR</Option>
                                  <Option value="USD">USD</Option>
                                  <Option value="GBP">USD</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          {isAdult ? (
                            <Row gutter={16}>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Adult Fare "
                                  name="AdultBaseFareReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Adult Taxes "
                                  name="AdulttaxesReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="GST/ VAT "
                                  name="AdultgstReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Conv. Fee "
                                  name="AdultconvfeeReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Net. Fare "
                                  name="AdultnetfareReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : null}
                          {isChild ? (
                            <Row gutter={16}>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Child Fare "
                                  name="ChildBaseFareReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Child Taxes "
                                  name="ChildtaxesReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="GST/ VAT "
                                  name="ChildgstReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Conv. Fee "
                                  name="ChildconvfeeReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Net. Fare "
                                  name="ChildnetfareReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : null}
                          {isinfant ? (
                            <Row gutter={16}>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Infant Fare "
                                  name="InfantBaseFareReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Infant Taxes "
                                  name="infanttaxesReturn"
                                  rules={[{ required: true }]}
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="GST/ VAT "
                                  name="InfantgstReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Conv. Fee "
                                  name="InfantconvfeeReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={4} xs={24}>
                                <Form.Item
                                  label="Net. Fare "
                                  name="infantNetfareReturn"
                                >
                                  <Input placeholder="Amount " type="number" />
                                </Form.Item>
                              </Col>
                            </Row>
                          ) : null}
                        </div>
                      </Card>
                    )}

                    <Card title="Terms And Conditions" bordered={false}>
                      <Row gutter={16}>
                        <Col className="gutter-class" md={12} xs={24}>
                          <Form.Item name="onwardRemarks">
                            <TextArea
                              rows={4}
                              placeholder="Onward Terms and Conditions"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-class" md={12} xs={24}>
                          {tripype == 2 ? (
                            <Form.Item name="returnRemarks">
                              <TextArea
                                placeholder="Return Terms and Conditions"
                                rows={4}
                              />
                            </Form.Item>
                          ) : null}
                        </Col>
                        <Col className="gutter-class" md={24} xs={24}>
                          <Form.Item className="bookoffline-submit">
                            <Button htmlType="submit">Submit</Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default BookingOffline;
