import GoogleMapReact from "google-map-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Map.scss";

const Marker = (props) => {
  const isModalOpen = () => {
    props.updateModalState(true);
    // props.updateModalState1(props.hotelObj);
  };

  useEffect(() => {
    isModalOpen();
  }, []);

  return (
    <div>
      <div className="marker" onClick={() => isModalOpen()}></div>
    </div>
  );
};

/* const HotelDetInModal = (props) => {
  console.log("modal props", props);
  return (
    <div className="hotel-det-modal">
      <div
        className="det-modal-close"
        onClick={() => props.updateModalState(false)}
      >
        <span>X</span>
      </div>
      <div className="hotel-details-modal-wrapper">
        <div className="hotel-modal-header">
          <div className="hotel-modal-image">
            {props.hotelInfoInModal.images.length === 0 ? (
              <img src={hotelNoImg} alt="no_photo" />
            ) : (
              <img
                src={props.hotelInfoInModal.images[0]}
                alt={props.hotelInfoInModal.property_name}
              />
            )}
          </div>
          <div className="hotel-modal-info">
            <h6>{props.hotelInfoInModal.property_name}</h6>
            <div className="modal-star-rating">
              <BeautyStars
                value={props.hotelInfoInModal.star_rating}
                activeColor={"#f6952d"}
                size={"7px"}
                inactiveColor={"#eaebee"}
              />
            </div>
            <div className="hotel-modal-rating">
              <span className="rating-value">
                <span className="rating-count"> 4.5</span>/5
              </span>
              <span className="hotel-modal-reviews">
                <p>2594 reviews</p>
              </span>
            </div>
          </div>
        </div>
        <div className="hotel-modal-footer">
          <div className="price-and-count">
            <div className="price-and-count-wrapper">
              <p>Rs.327</p>
              <small>
                Price for
                <strong>
                  <span>2</span> persons
                </strong>
                for
                <strong>
                  <span>2</span> nights
                </strong>
              </small>
            </div>
            <ReactBootstrap.Button className="d-block d-md-none">
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            </ReactBootstrap.Button>
          </div>
          <div className="web-show-room-btn d-none d-md-block">
            <ReactBootstrap.Button block>show rooms</ReactBootstrap.Button>
          </div>
        </div>
      </div>
    </div>
  );
}; */

const Map = (props) => {

  const hotelsObjsAsMarker = props.hotelContext.hotelsListObj.hotelsListObj[0]; //List of hotels obj from context
  const [isPopUpModal, setIsPopUpModal] = useState(false);
  const [hotelInfoInModal, setHotelInfoInModal] = useState({});

  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: 17.42159, //hotelsObjsAsMarker.lat,
      lng: 78.33752, //hotelsObjsAsMarker.lon,
    },
    zoom: 11,
  });

  const updateModalState = (toggleValue) => {
    setIsPopUpModal(toggleValue);
  };
  /* const updateModalState1 = (hotelObj) => {
    setHotelInfoInModal(hotelObj);
  }; */

  /* Closing and navigate back to hotellist page */
  let history = useHistory();
  const goTo = (path) => {
    history.goBack();
  };

  return (
    <div className="map-container">
      <div className="map-header">
        <p className="hotel-count">
          {hotelsObjsAsMarker.length} <span> results</span>
        </p>
        <p
          className="map-close"
          onClick={() => {
            goTo();
          }}
        >
          close
        </p>
      </div>
      <div style={{ height: "100vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{
            // key: "AIzaSyBcWi1J7vn47wgG815QpbvCeklS_AnGzbU",
            key: "AIzaSyCqYTNTjqLua5ABqGCzngrw4acAB3ucGdE",
            language: "en",
            region: "in",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          distanceToMouse={() => {}}
        >
          <Marker
            lat={59.955413}
            lng={30.337844}
            text="My Marker"
            updateModalState={updateModalState}
            // updateModalState1={() => updateModalState1(marker)}
          />
          {/*  {hotelsObjsAsMarker.map((marker, index) => (
            <Marker
              lat={marker.lat}
              lng={marker.lon}
              key={marker.id}
              updateModalState={updateModalState}
              updateModalState1={() => updateModalState1(marker)}
            />
          ))} */}
        </GoogleMapReact>
      </div>
      {isPopUpModal ? (
        <div
          style={{ display: isPopUpModal ? "block" : "none" }}
          className="child-modal-container"
        >
          {/* <HotelDetInModal
            updateModalState={updateModalState}
            hotelInfoInModal={hotelInfoInModal}
          /> */}
        </div>
      ) : null}
    </div>
  );
};

export default Map;
