import React from "react";
import * as ANTD from "antd";
// import "./FlightResultsCardSkeleton.scss";

const FlightResultsCardSkeleton = () => {
  return (
    <div className="flight-result-container">
      <div className="flight-result-card">
        <ANTD.Row className="flight-result-row">
          <ANTD.Col md={18} xs={24} className="flight-details">
            <div>
              <ANTD.Row>
                <ANTD.Col md={2}>
                  <ANTD.Skeleton
                    active={true}
                    avatar={{ size: 80, shape: "square" }}
                  />
                </ANTD.Col>
                <ANTD.Col md={19} className="cities-between">
                  <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
                </ANTD.Col>
                <ANTD.Col md={3} className="stops-class">
                  <ANTD.Skeleton active={true} paragraph={{ rows: 1 }} />
                </ANTD.Col>
              </ANTD.Row>
            </div>
            <div>
              <ANTD.Col md={19} className="time-details">
                <ANTD.Skeleton active={true} paragraph={{ rows: 0 }} />
              </ANTD.Col>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={0} className="choose-block-container">
            <ANTD.Skeleton active={true} paragraph={{ rows: 3 }} />
          </ANTD.Col>
        </ANTD.Row>
      </div>
    </div>
  );
};

export default FlightResultsCardSkeleton;
