import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  message,
  Radio,
  Popconfirm,
  Select,
  Switch,
  Table,
  Row,
} from "antd";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";

import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";
import "../Promo/Promo.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Coupons = (props) => {
  const [id, setId] = useState(-1);

  const [modalVisible, setModalVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState([]);
  const [isServiceCoupons, SetIsServiceCoupons] = useState(1);
  const [redeemService, SetRedeemService] = useState(false);
  const [columns, setColumns] = useState([]);
  const [excelList, setExcelList] = useState([]);

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const updateCouponModel = (rec) => {
    setId(rec.couponId);

    setFormData({
      ...formData,
      Image: rec.image ? rec.image.slice(28) : "",
    });

    if (rec.serviceType == undefined && rec.couponsFor != 4) {
      // SetIsServiceCoupons(2);
      setId(rec.Id);
    }
    if (rec.couponsFor === 4) {
      SetRedeemService(true);
      if (rec.businessName == "Agent") {
        setBusinessType(true);
      }
    }

    form.setFieldsValue({
      Coupon: rec?.couponsFor ?? 2,
      businessType: rec.businessType.toString(),
      couponType: rec.couponType == "Fixed" ? 0 : 1,
      couponValue: rec.couponValue,
      serviceType: rec?.serviceType,
    });

    setIsEditMode(true);
    showModal();
  };

  const addNewCoupons = () => {
    setIsEditMode(false);
    form.resetFields();

    showModal();
  };

  const changeStatus = (info, status) => {
    if (info.serviceType != undefined) {
      const id = info.couponId;
      delete info.SNo;
      delete info.businessName;
      delete info.serviceName;
      let obj = {
        ...info,
        status: status,
        couponType: info.couponType == "Fixed" ? 0 : 1,
      };
      updateCoupons(obj, id);
    } else if (info.couponsFor === 4) {
      const id = info.couponId;
      delete info.SNo;
      delete info.businessName;
      delete info.serviceName;
      let obj = {
        ...info,
        status: status,
        couponType: info.couponType == "Fixed" ? 0 : 1,
      };
      updateCoupons(obj, id);
    } else {
      delete info.SNo;
      let obj = {
        ...info,
        status: status,
        couponType: info.couponType == "Fixed" ? 0 : 1,
      };
      const id = info.Id;
      updateRegisterCoupons(obj, id);
    }
  };

  const handelStatus = (data) => {
    return (
      <Switch
        checkedChildren="Active"
        unCheckedChildren="Inactive"
        checked={data.status == 1 ? true : false}
        onChange={() => {
          changeStatus(data, data.status == 1 ? 0 : 1);
        }}
      />
    );
  };

  const tableActions = (rec) => {
    return (
      <div>
        <span className="edit-icon" onClick={() => updateCouponModel(rec)}>
          <i className="fa fa-edit"></i>
        </span>
        &nbsp;&nbsp;&nbsp;
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => deleteCoupons(rec?.couponId, rec?.Id)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      </div>
    );
  };

  const ServiceCouponsColumns = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },

    {
      title: "Service Type",
      dataIndex: "serviceName",
      sorter: (a, b) => a.serviceName.localeCompare(b.serviceName),
    },
    {
      title: "Business Type",
      dataIndex: "businessName",
    },

    {
      title: "Coupons Type",
      dataIndex: "couponType",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];

  const registrationCouponsColumn = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Business Type",
      dataIndex: "businessType",
      render: (text, record) => (text == 5 ? "Agent" : "User"),
    },

    {
      title: "Coupons Type",
      dataIndex: "couponType",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },

    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  const redeemCouponsColumn = [
    {
      title: "SNo",
      dataIndex: "SNo",
    },
    {
      title: "Business Type",
      dataIndex: "businessType",
      render: (text, record) => (text == 5 ? "Agent" : "User"),
    },

    {
      title: "Coupons Type",
      dataIndex: "couponType",
    },

    {
      title: "Coupon Value",
      dataIndex: "couponValue",
    },

    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },

    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
  ];
  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(ServiceCouponsColumns);
        break;
      case 2:
        setColumns(registrationCouponsColumn);
        break;
      case 4:
        setColumns(redeemCouponsColumn);
        break;
      default:
        setColumns(ServiceCouponsColumns);
    }
  };

  const getApplicationType = (num) => {
    if (num == 1) {
      return "Web";
    } else if (num == 2) {
      return "Mobile";
    } else {
      return "Both";
    }
  };

  const getCouponsType = (num) => {
    if (num == 0) {
      return "Fixed";
    } else if (num == 1) {
      return "Percentage";
    } else if (num == "Percentage") {
      return 1;
    } else if (num == "Fixed") {
      return 2;
    } else {
      return "Invalid";
    }
  };

  /* =============== API calls ================*/

  useEffect(() => {
    if (isServiceCoupons == 1) {
      getCoupons(isServiceCoupons);
    } else if (isServiceCoupons == 4) {
      getCoupons(isServiceCoupons);
    } else {
      getRegistrationCoupons();
    }
  }, [isServiceCoupons]);

  // api call to get all avaialble promo Codes
  const getCoupons = (couponFor) => {
    ApiClient.get("admin/GetAllCoupons").then((res) => {
      if (res.status == 200) {
        let data = res.data
          .filter((item) => item.couponsFor === couponFor)
          .map((item, index) => {
            return {
              ...item,
              SNo: index + 1,
              couponType: getCouponsType(item.couponType),
            };
          });
        setDataSource(data);
        defineExcelData(data);
        isServiceCoupons == 1
          ? setTableHeaders(1)
          : isServiceCoupons == 4
          ? setTableHeaders(4)
          : setTableHeaders(2);
      } else {
        setDataSource(res.data);
        isServiceCoupons == 1
          ? setTableHeaders(1)
          : isServiceCoupons == 4
          ? setTableHeaders(4)
          : setTableHeaders(2);
      }
      setBusinessType(false);
    });
  };
  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Service_Type: item.serviceName,
        Business_Type: item.businessName,
        Discount_Type: item.couponType,
        Amount: item.couponValue,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const getRegistrationCoupons = () => {
    ApiClient.get("admin/getRegistrationCoupon").then((res) => {
      if (res.status == 200) {
        let data = res.data.map((item, index) => {
          return {
            ...item,
            SNo: index + 1,
            couponType: getCouponsType(item.couponType),
          };
        });
        setDataSource(data);
        isServiceCoupons == 1
          ? setTableHeaders(1)
          : isServiceCoupons == 4
          ? setTableHeaders(4)
          : setTableHeaders(2);
      } else {
        setDataSource(res.data);
        isServiceCoupons == 1
          ? setTableHeaders(1)
          : isServiceCoupons == 4
          ? setTableHeaders(4)
          : setTableHeaders(2);
      }
    });
  };
  // Delete specific promo Codes
  const deleteCoupons = (couponId, id) => {
    if (couponId != undefined) {
      ApiClient.delete("admin/DeleteCoupons/" + couponId).then((res) => {
        if (res.status == 200) {
          message.success("Coupon Deleted  successfully", 3);
          getRegistrationCoupons();
          getCoupons(isServiceCoupons);
        }
      });
    } else {
      ApiClient.delete("admin/deleteRegistrationCoupon/" + id).then((res) => {
        if (res.status == 200) {
          message.success("Coupon Deleted  successfully", 3);
          getRegistrationCoupons();
          getCoupons(isServiceCoupons);
        }
      });
    }
  };

  const addCoupons = (data) => {
    ApiClient.post("admin/Coupons", data).then((res) => {
      if (res.status == 200) {
        message.success("Coupon added successfully", 3);
        setModalVisible(false);
        form.resetFields();
        getCoupons(isServiceCoupons);
        setBusinessType(false);
      } else if (res.status == 400) {
        message.error(" Duplicate Coupon", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const addRegisterCoupons = (data) => {
    ApiClient.post("admin/createRegistrationCoupon", data).then((res) => {
      if (res.status == 200) {
        message.success("Coupan added successfully", 3);
        setModalVisible(false);
        form.resetFields();
        getRegistrationCoupons();
      } else if (res.status == 400) {
        message.error(" Duplicate Coupon", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const updateCoupons = (data, id) => {
    ApiClient.put("admin/updateCoupons/" + id, {}, data).then((res) => {
      if (res.status == 200) {
        message.success("Coupan Updated  successfully", 3);
        setModalVisible(false);
        //setFormData(initialFormData);
        getCoupons(isServiceCoupons);
        setBusinessType(false);
      } else if (res.status == 400) {
        message.error(" Duplicate Coupan Code", 3);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const updateRegisterCoupons = (data, id) => {
    ApiClient.put("admin/updateRegistrationCoupon/" + id, {}, data).then(
      (res) => {
        if (res.status == 200) {
          message.success("Coupan Updated  successfully", 3);
          setModalVisible(false);

          getRegistrationCoupons();
        } else if (res.status == 400) {
          message.error(" Duplicate Coupan Code", 3);
        } else {
          message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  // add new Promo Code api call
  const submitFormData = (formvalues) => {
    const data = {
      couponsFor: formvalues.Coupon,
      serviceType: formvalues.serviceType,
      businessType: formvalues.businessType,
      couponType: formvalues.couponType ?? 0,
      couponValue: formvalues.couponValue,
      status: 0,
      createdBy: 1,
      modifiedBy: 1,
      DeviceOS: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ApplicationType: 0,
      createdDate: new Date(),
      modifiedDate: new Date(),
      couponName: "",
      couponDiscount: 0,
      couponLimit: 0,
      image: "",
      couponTermsandcondition: "",
      validFrom: "",
      validTill: "",
      membershipId: formvalues.membershipId ?? 0,
    };

    if (isServiceCoupons == 1) {
      if (isEditMode) {
        setTableHeaders(formvalues.Coupon);
        updateCoupons(data, id);
      } else {
        setTableHeaders(formvalues.Coupon);
        addCoupons(data);
      }
    } else if (isServiceCoupons == 4) {
      if (isEditMode) {
        setTableHeaders(formvalues.Coupon);
        updateCoupons(data, id);
      } else {
        setTableHeaders(formvalues.Coupon);
        addCoupons(data);
      }
    } else {
      if (isEditMode) {
        setTableHeaders(formvalues.Coupon);
        updateRegisterCoupons(data, id);
      } else {
        setTableHeaders(formvalues.Coupon);
        addRegisterCoupons(data);
      }
    }
  };

  const handleCoupons = (value) => {
    if (value === 1) {
      SetIsServiceCoupons(1);
      SetRedeemService(false);
    } else if (value === 4) {
      SetIsServiceCoupons(4);
      SetRedeemService(true);
    } else {
      SetIsServiceCoupons(2);
      SetRedeemService(false);
    }
  };
  const [businessType, setBusinessType] = useState(false);
  const handleBusinessChanges = (value) => {
    if (value == 5) {
      setBusinessType(true);
    } else {
      setBusinessType(false);
    }
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getCoupons();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  // const handleExcel = () => {
  //   const excelCustomData = dataSource.map((item) => {
  //     return {
  //       Name: item.CoupanName,
  //       TermsAndConditions: item.CoupnTermsandcondition,

  //       Service_Type: item.serviceType,
  //       Valid_From: item.ValidFrom,
  //       Valid_Till: item.ValidTill,
  //       Discount_Type: item.couponType,
  //       Discount: item.CoupanDiscount,
  //       Amount: item.couponValue,
  //     };
  //   });

  //   props.exportExcel(excelCustomData, "CoupanExcel");
  // };

  const handleTable = (e) => {
    if (e.target.value) {
      SetIsServiceCoupons(e.target.value);
    }
    // e.target.value == 1
    //   ? SetIsServiceCoupons(1)
    //   : SetIsServiceCoupons(2);
  };
  const [membershipList, setMembershipList] = useState([]);

  const getMembership = () => {
    ApiClient.get("admin/memberships")
      .then((res) => {
        if (res.status == 200) {
          setMembershipList(res.data);
        } else {
          setMembershipList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMembership();
  }, []);

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>
                      View Coupons{" "}
                      <HelpInfoHelper screenName={"/admin/coupons"} />
                    </h5>
                    <p>{dataSource.length} rows found !</p>
                    <Radio.Group
                      className="mb-2"
                      defaultValue={1}
                      onChange={(e) => handleTable(e)}
                    >
                      <Radio value={1}>Services</Radio>
                      <Radio value={2}>Registration</Radio>
                      <Radio value={4}>Redeem Coupons</Radio>
                    </Radio.Group>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData(dataSource)}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    onClick={(e) => props.exportExcel(excelList, "Coupons")}
                  />
                  {/* <img
                          src={exchange}
                          alt="exchange"
                          onClick={() => getCoupons()}
                        /> */}
                  <p
                    className="add-deposit-icon"
                    onClick={() => {
                      addNewCoupons();
                    }}
                  >
                    <PlusCircleFilled />
                  </p>
                </div>
              </div>

              <Table
                className="table-scroll-none"
                bordered
                dataSource={dataSource}
                columns={columns}
                pagination={{
                  defaultPageSize: 25,
                  showSizeChanger: true,
                  pageSizeOptions: ["25", "50", "100", "125"],
                }}
              />
            </Card>
          </div>
        </div>
      </div>

      <Modal
        title={isEditMode ? "Update Coupons" : "Add Coupons"}
        className="promo-modal-header"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={[
          <>
            <Button type="primary" onClick={() => form.submit()}>
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              danger
              className="cancel-btn"
              onClick={(e) => {
                form.resetFields();
                setModalVisible(false);
              }}
            >
              Cancel
            </Button>
          </>,
        ]}
        width={"1100px"}
      >
        <Form
          initialValues={{ businessType: "2", couponType: 1 }}
          layout="vertical"
          form={form}
          onFinish={submitFormData}
        >
          <Row gutter={16}>
            <Col className="gutter-row" md={8} xs={24}>
              <Form.Item
                label="Apply Coupons"
                name="Coupon"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select placeholder="Please select" onChange={handleCoupons}>
                  <Select.Option value={1}>Services</Select.Option>
                  <Select.Option value={2}>Registration</Select.Option>
                  <Select.Option value={4}>Set Redeem</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {isServiceCoupons === 1 && !redeemService ? (
              <Col md={8} xs={24}>
                <Form.Item
                  label="Service"
                  name="serviceType"
                  rules={[{ required: true, message: "Service is required" }]}
                >
                  <Select placeholder="Select Service Type">
                    <Select.Option value={1}>Flights</Select.Option>
                    <Select.Option value={2}>Hotels</Select.Option>
                    <Select.Option value={3}>Bus</Select.Option>
                    <Select.Option value={4}>Activities</Select.Option>
                    <Select.Option value={5}>BuildPackage</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : null}

            <Col md={8} xs={24}>
              <Form.Item
                label="Business Type"
                name="businessType"
                rules={[
                  { required: true, message: "Business Type is required" },
                ]}
              >
                <Select
                  placeholder="Select Business Type"
                  onChange={handleBusinessChanges}
                >
                  <Select.Option value="5">Partner</Select.Option>
                  <Select.Option value="2">user</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            {businessType && redeemService ? (
              <Col md={8} xs={24}>
                <Form.Item
                  label="Membership"
                  name="membershipId"
                  rules={[
                    { required: true, message: "Membership is required" },
                  ]}
                >
                  <Select placeholder="Select Membership">
                    {membershipList.map((data, index) => (
                      <Select.Option key={index} value={data.MembershipID}>
                        {data.Name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : null}
            <Col md={8} xs={24}>
              <Form.Item
                label="Coupon Type"
                name="couponType"
                // rules={[
                //   { required: true, message: "Coupon Type is required" },
                // ]}
              >
                <Radio.Group>
                  <Radio value={1}>Percentage</Radio>
                  {redeemService ? null : <Radio value={0}>Fixed</Radio>}
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Coupon Value"
                name="couponValue"
                rules={[
                  { required: true, message: " CouponValue is required" },
                ]}
              >
                <Input placeholder=" couponValue" type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(Coupons);
