import moment from "moment";
import queryString from "query-string";
const dateFormat = "YYYY-MM-DD";

export const getFlightQueryParams = () => {};

export const getFlightSearchFromLocal = () => {};
const verifyParams = (params) => {
  return true;
};

export const getSearchRequestFromQuery = () => {
  const getTiming = (time) => {
    switch (time) {
      case "anyTime": {
        return "T00:00:00";
      }
      case "morning": {
        return "T08:00:00";
      }
      case "afternoon": {
        return "T14:00:00";
      }
      case "evening": {
        return "T19:00:00";
      }
      case "night": {
        return "T01:00:00";
      }
      default:
        return "T00:00:00";
    }
  };

  const searchParams = queryString.parse(window.location.search);
  if (!verifyParams(searchParams)) {
    document.location.replace("/error?erro='Invalid t'");
    return;
  }
  let requestObj = {
    originDestinations: [],
    adultCount: parseInt(searchParams.adultCount),
    childCount: parseInt(searchParams.childCount),
    infantCount: parseInt(searchParams.infantCount),
    cabinClass: searchParams.cabinClass,
    includeCarrier: searchParams.includeCarrier,
    excludeCarrier: "",
    stopOver: searchParams.stopOver,
    airTravelType: searchParams.airTravelType,
    flightDateFlex: 3,
    itineraryViewType: "1",
    departureTiming: searchParams.departureTiming ?? "",
    returnTiming: searchParams.returnTiming ?? "",
  };

  if (searchParams.airTravelType === "multidestination") {
    let temp = [];
    searchParams.origin.forEach((item, i) => {
      temp.push({
        origin: searchParams.origin[i],
        destination: searchParams.destination[i],
        departureDateTime: searchParams.departureDateTime[i],
        flightDateFlex: 3,
        // flightDateFlex: parseInt(searchParams.flightDateFlex[i]),
      });
    });
    requestObj.originDestinations = temp;
  } else {
    let timeFormat = "T00:00:00";
    if (requestObj.departureTiming) {
      timeFormat = getTiming(requestObj.departureTiming);
    }

    requestObj.originDestinations = [
      {
        departureDateTime: searchParams.departureDateTime + timeFormat,
        origin: searchParams.origin,
        destination: searchParams.destination,
        flightDateFlex: 1,
      },
    ];

    if (searchParams.airTravelType === "roundTrip") {
      if (requestObj.returnTiming) {
        timeFormat = getTiming(requestObj.returnTiming);
      }
      requestObj.originDestinations.push({
        departureDateTime: searchParams.returnDateTime + timeFormat,
        origin: searchParams.destination,
        destination: searchParams.origin,
        flightDateFlex: 1,
      });
    }
  }

  return requestObj;
};

const getOriginDestinations = (form, type) => {
  if (type == "multidestination") {
    return form.originDestinationFields.map((i) => {
      return {
        origin: i.origin,
        destination: i.destination,
        departureDateTime: i.departureDateTime.format(dateFormat),
        flightDateFlex: 0,
      };
    });
  } else {
    let data = {
      departureDateTime: form.departure.format(dateFormat),
      // departureDateTime:
      //   type == "oneWay"
      //     ? form.departure.format(dateFormat)
      //     : form.departure_arrival[0].format(dateFormat),
      origin: form.origin,
      destination: form.destination,
      flightDateFlex: 1,
    };
    if (type == "oneWay") {
      return [data];
    } else {
      return [
        data,
        {
          departureDateTime: form.arrival.format(dateFormat),
          // departureDateTime: form.departure_arrival[1].format(dateFormat),
          destination: form.origin,
          origin: form.destination,
          flightDateFlex: 1,
        },
      ];
    }
  }
};
export const createSearchFromForm = (
  form,
  airTravelType,
  // cabinClass,
  passengers,
  includeCarrier,
  stopOver
) => {
  let result = {
    originDestinations: getOriginDestinations(form, airTravelType),
    adultCount: passengers.adults,
    childCount: passengers.childs,
    infantCount: passengers.infants,
    // cabinClass: cabinClass,
    cabinClass: form.cabinClass,
    includeCarrier: includeCarrier,
    excludeCarrier: "",
    stopOver: stopOver,
    airTravelType: airTravelType,
    flightDateFlex: 0,
    itineraryViewType: "1",
  };
  return result;
};

export const createQueryParamsFromRequest = (searchRequest) => {
  let start_params = `adultCount=${searchRequest.adultCount}&airTravelType=${searchRequest.airTravelType}&cabinClass=${searchRequest.cabinClass}&childCount=${searchRequest.childCount}&infantCount=${searchRequest.infantCount}&stopOver=${searchRequest.stopOver}&includeCarrier=${searchRequest.includeCarrier}&`;
  if (searchRequest.airTravelType == "multidestination") {
    return (
      start_params + getMultidestinationQuery(searchRequest.originDestinations)
    );
  } else {
    let one_way_params =
      start_params +
      `departureDateTime=${searchRequest.originDestinations[0].departureDateTime}&flightDateFlex=${searchRequest.originDestinations[0].flightDateFlex}&origin=${searchRequest.originDestinations[0].origin}&destination=${searchRequest.originDestinations[0].destination}`;

    if (searchRequest.airTravelType == "oneWay") {
      if (searchRequest.departureTiming) {
        return (
          one_way_params + `&departureTiming=${searchRequest.departureTiming}`
        );
      }
      return one_way_params;
    } else {
      if (searchRequest.returnTiming) {
        return (
          one_way_params +
          `&returnDateTime=${searchRequest.originDestinations[1].departureDateTime}&returnTiming=${searchRequest.returnTiming}&departureTiming=${searchRequest.departureTiming}`
        );
      }
      return (
        one_way_params +
        `&returnDateTime=${searchRequest.originDestinations[1].departureDateTime}`
      );
    }
  }
};

const getMultidestinationQuery = (array) => {
  var parts = [];

  for (var i = 0; i < array.length; ++i) {
    parts.push(
      encodeURIComponent("origin") + "=" + encodeURIComponent(array[i].origin)
    );
    parts.push(
      encodeURIComponent("destination") +
        "=" +
        encodeURIComponent(array[i].destination)
    );
    parts.push(
      encodeURIComponent("departureDateTime") +
        "=" +
        encodeURIComponent(array[i].departureDateTime)
    );
    parts.push(
      encodeURIComponent("flightDateFlex") +
        "=" +
        encodeURIComponent(array[i].flightDateFlex)
    );
  }

  return parts.join("&");
};

export const MapRequestToForm = (request) => {
  if (!request) {
    return false;
  }

  let comon = {
    adults: request.adultCount,
    childs: request.childCount,
    infants: request.infantCount,
    cabinClass: request.cabinClass,
    airTravelType: request.airTravelType,
    originDestinationFields: [{}, {}],
  };
  if (request.airTravelType == "oneWay") {
    return {
      ...comon,
      origin: request.originDestinations[0].origin,
      destination: request.originDestinations[0].destination,
      departure: moment(request.originDestinations[0].departureDateTime),
    };
  }
  if (request.airTravelType == "roundTrip") {
    return {
      ...comon,
      origin: request.originDestinations[0].origin,
      destination: request.originDestinations[0].destination,
      departure: moment(request.originDestinations[0].departureDateTime),
      arrival: moment(request.originDestinations[1].departureDateTime),
    };
  }

  if (request.airTravelType == "multidestination") {
    return {
      ...comon,
      originDestinationFields: request.originDestinations.map((i) => {
        return {
          ...i,
          departureDateTime: moment(i.departureDateTime),
        };
      }),
    };
  }
  return comon;
};
