import React, { useEffect, useState } from "react";
import { Calendar, Card, Col, Form, Row, Select, Spin, Popover } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import "./TravelCalendar.scss";
import ApiClient from "../../helpers/ApiClient";
import { Link } from "react-router-dom";
import moment from "moment";
import { createReportFromApiReponse } from "./CalenderHelpers";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;

const TravelCalendar = () => {
  const [serviceType, setServiceType] = useState(0);
  const [bookings, setBookings] = useState({
    flights: [],
    hotels: [],
    buses: [],
    activities: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const getBookingListData = (travelType) => {
    setIsLoading(true);
    setBookings({
      flights: [],
      hotels: [],
      buses: [],
      activities: [],
    });
    let url = `admin/myBookings/report?travelType=${travelType}`;
    ApiClient.get(url)
      .then((res) => {
        if (res.statusCode == 200) {
          setBookings(createReportFromApiReponse(res.data));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getBookingListData(0);
  }, []);

  // console.log(bookings);
  /* =========Api Calls===*/

  //   to={`/admin/reports?travelType=3&${
  //     type === 1
  //       ? `dateType=1&fromDate=${formatedDate}&toDate=${formatedDate}`
  //       : `dateType=1&fromDate=${monthStartDate}&toDate=${monthEndDate}`
  //   }&edit=edit`}
  // >

  const cellContent = ({ flights, hotels, buses, activities }, date, type) => {
    let formatedDate = moment(date).format("YYYY-MM-DD");
    // let monthStartDate = moment(date).startOf("month").format("YYYY-MM-DD");
    // let monthEndDate = moment(date).endOf("month").format("YYYY-MM-DD");

    return (
      <div className="travelDateContent">
        {flights > 0 && (serviceType == 0 || serviceType == 1) ? (
          <Link
            to={`/admin/reports?travelType=1&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-plane"></i> {flights}
              </span>
            </div>
          </Link>
        ) : null}

        {buses > 0 && (serviceType == 0 || serviceType == 3) ? (
          <Link
            to={`/admin/reports?travelType=3&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-bus" /> {buses}
              </span>
            </div>
          </Link>
        ) : null}
        {activities > 0 && (serviceType == 0 || serviceType == 4) ? (
          <Link
            to={`/admin/reports?travelType=4&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-tasks" /> {activities}
              </span>
            </div>
          </Link>
        ) : null}

        {hotels > 0 && (serviceType == 0 || serviceType == 2) ? (
          <Link
            to={`/admin/reports?travelType=2&${`journeyDate=${formatedDate}&bookingStatus=3&edit=edit`}`}
          >
            <div>
              <span>
                <i className="fa fa-hotel" /> {hotels}
              </span>
            </div>
          </Link>
        ) : null}
      </div>
    );
  };

  const dateCellRender = (dated) => {
    let formatedDate = moment(dated).format("DD-MM-YYYY");
    let data = {
      flights: bookings.flights.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      buses: bookings.buses.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      hotels: bookings.hotels.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
      activities: bookings.activities.filter(
        (booking) => booking.formatedDate == formatedDate
      ).length,
    };
    return cellContent(data, dated, 1);
  };

  const monthCellRender = (dated) => {
    let month = moment(dated).month();
    let year = moment(dated).year();
    let data = {
      flights: bookings.flights.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      buses: bookings.buses.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      hotels: bookings.hotels.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
      activities: bookings.activities.filter(
        (booking) => booking.month == month && booking.year == year
      ).length,
    };
    return cellContent(data, dated, 2);
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <div className="travel-calendar">
          <Card bordered={false}>
            <h5>
              Travel Calendar{" "}
              <HelpInfoHelper screenName={"/admin/travelcalendar"} />
            </h5>
            <Form>
              <Row>
                <Col span={8}>
                  <Form.Item label="Service Type">
                    <Select
                      placeholder="Select Service Type"
                      onChange={(v) => {
                        getBookingListData(v);
                        setServiceType(v);
                      }}
                      defaultValue={0}
                    >
                      <Option value={0}>All</Option>
                      <Option value={1}>Flights</Option>
                      <Option value={3}>Buses</Option>
                      <Option value={2}>Hotels</Option>
                      <Option value={4}>Activities</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            {isLoading ? (
              <Spin />
            ) : (
              <Calendar
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default TravelCalendar;
