import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  message,
  Radio,
  Select,
  Spin,
  Table,
  Row,
  Upload,
  Popconfirm,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./Managehotel.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import APIClient from "../../../src/helpers/ApiClient";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../../src/helpers/ApiClient";
import "rc-datepicker/lib/style.css";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";

const Managehotel = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [form] = ANTD.Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = imgUrl;
  const [actHotelForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [facilityList, setFacilityList] = useState([]);
  const [addValue, setValue] = useState();
  const [currentId, setCurrentId] = useState();
  const [excelList, setExcelList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const handleRoleType = (val) => setRoleType(val);
  const [roleType, setRoleType] = useState(1);
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [propertytype, setPropertytype] = useState([]);
  const [HotelCities, setHotelCity] = useState([]);
  const [Currencylist, setCurrencylist] = useState([]);
  const { Panel } = ANTD.Collapse;

  useEffect(() => {
    getManagehotellist();
  }, []);

  const getManagehotellist = () => {
    APIClient.get("hotelsextranet/getAllhotel")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,

              ...item,
            };
          });
          let filteredNmaes = data?.filter(
            (item) => item.HotelId != 2 && item.HotelId != 5
          );
          setNameList(filteredNmaes);
          defineExcelData(filteredNmaes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (val) => {
    console.log("fromvalue", val);
    let nameObj = {
      Status: 0,
      CountryId: val.countryName,
      CityId: val.CityName,
      Starrating: val.Starrating,
      CurrenciesAccepted: val.CurrenciesAccepted,
      EmailAddress1: val.EmailAddress1,
      EmailAddress2: val.EmailAddress2,
      PhoneNumber2: val.PhoneNumber2,
      PhoneNumber1: val.PhoneNumber1,
      FaxNumber2: val.FaxNumber2,
      FaxNumber1: val.FaxNumber1,
      Latitude: val.Latitude,
      Longitude: val.Longitude,
      LocationGpsURL: val.LocationGpsURL,
      RoomsCount: val.RoomsCount,
      HotelImageId: val.HotelImageId,
      CheckIn: val.CheckIn,
      CheckOut: val.CheckOut,
      isAmendmentAllowed: val.isAmendmentAllowed,
      Address: val.Address,
      HotelShortDescription: val.HotelShortDescription,
      HotelLongDescription: val.HotelLongDescription,
      WhyBookwithus: val.WhyBookwithus,
      PropertyRules: val.PropertyRules,
      PoliciesMaster: val.PoliciesMaster,
      CheckInstructions: val.CheckInstructions,
      SpecialInstructions: val.SpecialInstructions,
      DisclaimerNotification: val.DisclaimerNotification,

      Name: val.Name,
      // Icon: "string",
      Description: val.Description,

      CreatedBy: 0,
      CreatedDate: new Date(),
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };
    console.log("testtthh", nameObj);
    isEdit ? UpdateHotelType(nameObj) : AddHoteltype(nameObj);
  };

  const UpdateHotelType = (nameObj) => {
    console.log(nameObj);
    ApiClient.put("hotelsextranet/updateHotel/" + currentId, {}, nameObj).then(
      (res) => {
        if (res.status == 200) {
          getManagehotellist();
          ANTD.message.success("Manage Hotels Updated successfully", 3);
          setAddMarkupModal(false);
        } else {
          ANTD.message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  const add = () => {
    let nameObjtest = {
      Name: addValue,
      Level: "1",
      CreatedBy: 1,
    };
  };
  const AddHoteltype = (nameObjtest) => {
    APIClient.post("hotelsextranet/createhotel", nameObjtest)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setAddMarkupModal(false);
          ANTD.message.success("Successfully added name", 3);
          getManagehotellist();
        } else if (response.status == 400) {
          ANTD.message.error("Bed Type Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Name: item.Name,

        Description: item.Description,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = nameList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setNameList(searchResults);
    } else {
      getManagehotellist();
      setNameList(nameList);
    }
  };

  function getInputValue(event) {
    setValue(event.target.value);
  }

  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  useEffect(() => {
    getCountries();
    getHotelCity();
    getCurrency();
    getHotelfacilities();
    getPropertytypes();
  }, []);

  /*****country data*****/
  const getCountries = () => {
    APIClient.get("hotelsextranet/getHotelCountry")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.filter((item) => item.CountryId != null);

          setCountries(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*****Property Type*****/

  const getPropertytypes = () => {
    APIClient.get("hotelsextranet/getpropertytype")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.filter((item) => item.PropertyTypeId != null);

          setPropertytype(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*****city data*****/
  const getHotelCity = () => {
    APIClient.get("hotelsextranet/getHotelCity")
      .then((resp) => {
        if (resp.status == 200) {
          setHotelCity(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Cities", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*****currency*****/
  const getCurrency = () => {
    APIClient.get("hotelsextranet/getallcurrency")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.filter((item) => item.Id != null);

          setCurrencylist(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*****hotelfacilites*****/
  const getHotelfacilities = () => {
    APIClient.get("hotelsextranet/getHotelFacilitiesFacilityType/1")
      .then((res) => {
        if (res.status == 200) {
          setFacilityList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);

  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteName(currentRecord.HotelId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.HotelId);
    actHotelForm.setFieldsValue({
      Name: rec.Name,
      Description: rec.Description,
    });

    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const deleteName = (id) => {
    APIClient.delete("hotelsextranet/deletehotel/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Amenities Deleted  successfully", 3);
          getManagehotellist();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      name: "Jhon",
      description: "bbb",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon11",
      description: "aaa",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon2",
      description: "ccc",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },

    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Country",
      dataIndex: "countryName",
      sorter: (a, b) => a.countryName.localeCompare(b.countryName),
    },
    {
      title: "City",
      dataIndex: "cityName",
      sorter: (a, b) => a.cityName.localeCompare(b.cityName),
    },

    {
      title: "Hotel Name",
      dataIndex: "HotelName",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "List of currencies",
      dataIndex: "CurrenciesAccepted",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Star Rating",
      dataIndex: "Starrating",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email Address1",
      dataIndex: "EmailAddress1",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Email Address2",
      dataIndex: "EmailAddress2",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Phone Number1",
      dataIndex: "PhoneNumber1",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Phone Number2",
      dataIndex: "PhoneNumber2",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Fax Number1",
      dataIndex: "FaxNumber1",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Fax Number2",
      dataIndex: "FaxNumber2",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Latitude",
      dataIndex: "Latitude",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Longitude",
      dataIndex: "Longitude",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Location Gps URL",
      dataIndex: "LocationGpsURL",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Rooms Count",
      dataIndex: "RoomsCount",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Check In",
      dataIndex: "CheckIn",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Check Out",
      dataIndex: "CheckOut",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "is Amendment Allowed",
      dataIndex: "isAmendmentAllowed",
      render: (item) => (item == 0 ? "false" : "true"),
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Address",
      dataIndex: "Address",
      // sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Hotel Short Description",
      dataIndex: "HotelShortDescription",
    },
    {
      title: "Hotel Long Description",
      dataIndex: "HotelLongDescription",
    },
    {
      title: "Why Book with us?",
      dataIndex: "WhyBookwithus",
    },
    {
      title: "Property Rules",
      dataIndex: "PropertyRules",
    },
    {
      title: "Policies Master",
      dataIndex: "PoliciesMaster",
    },
    {
      title: "Check Instructions",
      dataIndex: "CheckInstructions",
    },
    {
      title: "Special Instructions",
      dataIndex: "SpecialInstructions",
    },
    {
      title: "Disclaimer Notification",
      dataIndex: "DisclaimerNotification",
    },

    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Manage Hotels{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>{nameList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          props.exportExcel(excelList, "Manage Hotels")
                        }
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={(e) => {
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          handleRoleType(1);
                          setAddMarkupModal(true);
                          actHotelForm.resetFields();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none table-data"
                      bordered
                      dataSource={nameList}
                      rowKey={uniqueKey}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Manage Hotels</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-extranet-hotel"
        // visible={modalVisible}
        // onOk={(e) => setModalVisible(false)}
        // onCancel={(e) => setModalVisible(false)}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={null}
        width={"1100px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={actHotelForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => actHotelForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <ANTD.Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={actHotelForm}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={6} xs={24}>
              <Form.Item
                label="Country"
                rules={[{ required: true }]}
                name="countryName"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Country"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countries.map((item, index) => (
                    <Option key={index} value={item.CountryId}>
                      {`${item.CountryName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <Form.Item
                label="City"
                rules={[{ required: true }]}
                name="cityName"
              >
                <Select
                  showSearch
                  placeholder="Select City"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {HotelCities.map((item, index) => (
                    <Option key={index} value={item.CityId}>
                      {`${item.CityName}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="HotelName"
                label="Hotel Name"
                rules={[{ required: true }]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <Form.Item
                label="Star Rating"
                name="Starrating"
                rules={[{ required: true }]}
              >
                <Select>
                  <Select.Option value={1}>1 Star</Select.Option>
                  <Select.Option value={2}>2 Star</Select.Option>
                  <Select.Option value={3}>3 Star</Select.Option>
                  <Select.Option value={4}>4 Star</Select.Option>
                  <Select.Option value={5}>5 Star</Select.Option>
                </Select>
              </Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="List of currencies"
                Name="CurrenciesAccepted"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Currency"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Currencylist.map((item, index) => (
                    <Option key={index} value={item.Id}>
                      {`${item.CurrencyName}`}
                    </Option>
                  ))}
                </Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                Name="PropertyRules"
                label="Property Type"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Property "
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {propertytype.map((item, index) => (
                    <Option key={index} value={item.PropertyTypeId}>
                      {`${item.Name}`}
                    </Option>
                  ))}
                </Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Email Address1 "
                Name="EmailAddress1"
                rules={[
                  {
                    required: true,
                    message: "Please input your EmailAddress1!",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Email Address2 "
                Name="EmailAddress2"
                rules={[
                  {
                    required: true,
                    message: "Please input your EmailAddress2!",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name="PhoneNumber1"
                label="Phone Number1"
                rules={[
                  {
                    required: true,
                    message: "Please input your PhoneNumber1!",
                  },
                ]}
              >
                <ANTD.Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item name="PhoneNumber2" label="Phone Number2">
                <ANTD.Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Fax Number1" Name="FaxNumber1">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Fax Number2" Name="FaxNumber2">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Longitude" Name="Longitude">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Latitude" Name="Latitude">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Location Gps URL" Name="LocationGpsURL">
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Rooms Count"
                Name="RoomsCount"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Check In"
                Name="CheckIn"
                rules={[{ required: true }]}
              >
                <ANTD.Select placeholder="Please select">
                  <Option value="0">1AM</Option>
                  <Option value="1">2AM</Option>
                  <Option value="2">11PM</Option>
                  <Option value="3">12PM</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Check Out"
                rules={[{ required: true }]}
                Name="CheckOut"
              >
                <ANTD.Select placeholder="Please select">
                  <Option value="0">1AM</Option>
                  <Option value="1">2AM</Option>
                  <Option value="2">11PM</Option>
                  <Option value="3">12PM</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item
                label="is Amendment Allowed"
                Name="isAmendmentAllowed"
              >
                <ANTD.Radio.Group
                  onChange={onChange}
                  value={statusBtn}
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <ANTD.Radio value={1}>True</ANTD.Radio>
                  <ANTD.Radio value={2}>False</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="Address "
                Name="Address"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
          <ANTD.Row gutter={16} className="mb-bottom">
            <ANTD.Col md={24} xs={24}>
              <ANTD.Collapse accordion>
                <Panel header="Hotel facilities" key="1">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item
                        label="Hotel facilities"
                        Name="HotelFacilities"
                      >
                        {facilityList.length > 0
                          ? facilityList.map((item, index) => (
                              <ANTD.Checkbox
                                key={index}
                                value={item.HotelFacilityId}
                              >
                                {`${item.Name}`}
                              </ANTD.Checkbox>
                            ))
                          : null}
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Hotel Short Description" key="2">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="HotelShortDescription">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Hotel Long Description" key="3">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="HotelLongDescription">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Why Book with us?" key="4">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="WhyBookwithus">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Property Rules" key="5">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="PropertyRules">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Policies Master" key="6">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="PoliciesMaster">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Check-in Instructions" key="7">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="CheckInstructions">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Special Instructions" key="8">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item NAame="SpecialInstructions">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Disclaimer notification" key="9">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item Name="DisclaimerNotification">
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};

export default UpdatedComponent(Managehotel);
