import React, { useState } from "react";
import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
  AntDesignOutlined,
} from "@ant-design/icons";
import "../Holidaystotal/Holidaystotal.scss";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { Collapse } from "antd";

const Holidaystotal = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [formLayout, setFormLayout] = React.useState("vertical");
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row className="mobile-row-res">
              <ANTD.Col md={16} xs={24} offset={1} className="mobile-offset">
                <ANTD.Card>
                  <div>
                    <h5 className="booking-option-cart">
                      LEAD PASSENGER DETAILS
                    </h5>
                  </div>

                  <ANTD.Form
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{ layout: formLayout }}
                  >
                    <ANTD.Row className="form-rules">
                      <ANTD.Col md={2} xs={24}>
                        <ANTD.Form.Item
                          label="Mr"
                          rules={[{ required: true }]}
                          name="Title"
                        >
                          <ANTD.Select placeholder="Mr">
                            <ANTD.Select.Option value="Mr">
                              Mr
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="MRS">
                              Mrs.
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="Ms">
                              Ms.
                            </ANTD.Select.Option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={11} xs={24}>
                        <ANTD.Form.Item
                          label="First Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your First Name",
                            },
                          ]}
                        >
                          <ANTD.Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={11} xs={24}>
                        <ANTD.Form.Item
                          label="Last Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your family Name",
                            },
                          ]}
                        >
                          <ANTD.Input
                            className="doument-name"
                            placeholder="As shown on passport"
                            size={"large"}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                    <ANTD.Row>
                      <ANTD.Col md={8} xs={24}>
                        <ANTD.Form.Item
                          label="Email
"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                        >
                          <ANTD.Input
                            className="doument-name"
                            placeholder="Please Enter"
                            size={"large"}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={8} xs={24}>
                        <ANTD.Form.Item
                          label="Country"
                          rules={[{ required: true }]}
                          name="Country"
                        >
                          <ANTD.Select placeholder="Please Select">
                            <ANTD.Select.Option value="demo">
                              Please Select
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              India
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Sri Lanka
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Pakistan
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Bangladesh
                            </ANTD.Select.Option>

                            <ANTD.Select.Option value="demo">
                              Malaysia
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Singapore
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Nepal
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              United Arab Emirates
                            </ANTD.Select.Option>

                            <ANTD.Select.Option value="demo">
                              UK
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              USA
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Mainland China
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Hong Kong
                            </ANTD.Select.Option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>

                      <ANTD.Col md={8} xs={24}>
                        <ANTD.Form.Item
                          label="Phone Number"
                          rules={[{ required: true }]}
                          name="Phone Number"
                        >
                          <ANTD.Select
                            className="barselecticon"
                            placeholder="India (+91)"
                          >
                            <ANTD.Select.Option value="demo">
                              India (+91)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Sri Lanka (+94)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Pakistan (+92)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Bangladesh (+880)
                            </ANTD.Select.Option>

                            <ANTD.Select.Option value="demo">
                              Malaysia (+60)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Singapore (+65)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              Nepal (+977)
                            </ANTD.Select.Option>
                            <ANTD.Select.Option value="demo">
                              United Arab Emirates (+971)
                            </ANTD.Select.Option>
                          </ANTD.Select>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                    <ANTD.Row>
                      <ANTD.Col md={24} xs={24}>
                        <ANTD.Form.Item>
                          <TextArea rows={4} placeholder="Special Request" />
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>

                <ANTD.Card className="details-offer-card">
                  <div>
                    <h5 className="booking-option-cart">EXTRA DETAILS</h5>
                  </div>
                  <ANTD.Form
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{ layout: formLayout }}
                  >
                    <ANTD.Row>
                      <ANTD.Col md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Hyderabad City Tour"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                        >
                          <ANTD.Input
                            className="doument-name"
                            placeholder="Please Enter"
                            size={"large"}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col md={12} xs={24}>
                        <ANTD.Form.Item
                          label="Desert Safari"
                          rules={[
                            {
                              required: true,
                              message: "Please Enter",
                            },
                          ]}
                        >
                          <ANTD.Input
                            className="doument-name"
                            placeholder="Please Enter"
                            size={"large"}
                          />
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>

                <ANTD.Card className="details-offer-card">
                  <div>
                    <h5 className="booking-option-cart">
                      SELECT SUITABLE OPTION TO PROCEED FURTHER FOR PAYMENT
                    </h5>
                  </div>
                  <ANTD.Form
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{ layout: formLayout }}
                  >
                    <ANTD.Row className="payment-bottom">
                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item>
                          <ANTD.Radio>Payment</ANTD.Radio>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Form.Item>
                          <ANTD.Radio>Option</ANTD.Radio>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>

                <ANTD.Card className="details-offer-card">
                  <div>
                    <h5 className="booking-option-cart">
                      SELECT SUITABLE OPTION TO PROCEED FURTHER FOR PAYMENT
                    </h5>
                  </div>
                  <ANTD.Form
                    {...formItemLayout}
                    layout={formLayout}
                    initialValues={{ layout: formLayout }}
                  >
                    <ANTD.Row className="payment-bottom">
                      <ANTD.Col md={18} xs={24}>
                        <ANTD.Form.Item>
                          <ANTD.Checkbox>
                            By Clicking Pay Now You agree that you have read and
                            understood our Terms & Conditions
                          </ANTD.Checkbox>
                        </ANTD.Form.Item>
                      </ANTD.Col>
                      <ANTD.Col md={6} xs={24}>
                        <ANTD.Button className="book-cart-all">
                          Book Now
                        </ANTD.Button>
                      </ANTD.Col>
                    </ANTD.Row>
                  </ANTD.Form>
                </ANTD.Card>
              </ANTD.Col>

              <ANTD.Col
                md={6}
                xs={24}
                offset={1}
                className="mobile-offset total-card-bar"
              >
                <ANTD.Card className="card-total-option">
                  <Collapse bordered={false} defaultActiveKey={["1"]}>
                    <Panel
                      className="dataicon"
                      header="Dubai Premium Trio Package"
                      key="1"
                    >
                      <div className="total-cost-user">
                        <div className="line-cost">
                          <p>Dubai City Tour</p>
                        </div>

                        <div className="line-cost-1">
                          <ul className="info-item">
                            <li>
                              <p className="date-item">Transfer Option :</p>
                            </li>
                            <li>
                              <p className="date-item-1">Sharing Transfers</p>
                            </li>

                            <li>
                              <p className="date-item">Date :</p>
                            </li>
                            <li>
                              <p className="date-item-1">16/10/2021</p>
                            </li>
                            <li>
                              <p className="date-item">Time :</p>
                            </li>
                            <li>
                              <p className="date-item-1">09:00:00</p>
                            </li>
                            <li>
                              <p className="date-item">Pax :</p>
                            </li>
                            <li>
                              <p className="date-item-1">1Adult,</p>
                            </li>
                            <li>
                              <p className="date-item offdata">
                                Last Date to Cancel :
                              </p>
                            </li>
                            <li>
                              <p className="date-item-1 offdata">
                                15/10/2021 08:59:59
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="total-cost-user">
                        <div className="line-cost">
                          <p>Desert Safari</p>
                        </div>

                        <div className="line-cost-1">
                          <ul className="info-item">
                            <li>
                              <p className="date-item">Transfer Option :</p>
                            </li>
                            <li>
                              <p className="date-item-1">Sharing Transfers</p>
                            </li>

                            <li>
                              <p className="date-item">Date :</p>
                            </li>
                            <li>
                              <p className="date-item-1">16/10/2021</p>
                            </li>
                            <li>
                              <p className="date-item">Time :</p>
                            </li>
                            <li>
                              <p className="date-item-1">09:00:00</p>
                            </li>
                            <li>
                              <p className="date-item">Pax :</p>
                            </li>
                            <li>
                              <p className="date-item-1">1Adult,</p>
                            </li>
                            <li>
                              <p className="date-item offdata">
                                Last Date to Cancel :
                              </p>
                            </li>
                            <li>
                              <p className="date-item-1 offdata">
                                15/10/2021 08:59:59
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="total-cost-user">
                        <div className="line-cost">
                          <p>Dhow Cruise Dinner - Marina</p>
                        </div>

                        <div className="line-cost-1">
                          <ul className="info-item">
                            <li>
                              <p className="date-item">Transfer Option :</p>
                            </li>
                            <li>
                              <p className="date-item-1">Sharing Transfers</p>
                            </li>

                            <li>
                              <p className="date-item">Date :</p>
                            </li>
                            <li>
                              <p className="date-item-1">16/10/2021</p>
                            </li>
                            <li>
                              <p className="date-item">Time :</p>
                            </li>
                            <li>
                              <p className="date-item-1">09:00:00</p>
                            </li>
                            <li>
                              <p className="date-item">Pax :</p>
                            </li>
                            <li>
                              <p className="date-item-1">1Adult,</p>
                            </li>
                            <li>
                              <p className="date-item offdata">
                                Last Date to Cancel :
                              </p>
                            </li>
                            <li>
                              <p className="date-item-1 offdata">
                                15/10/2021 08:59:59
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </ANTD.Card>

                <ANTD.Card className="card-total-option">
                  <Collapse bordered={false} defaultActiveKey={["1"]}>
                    <Panel
                      className="dataicon"
                      header="Final Payment  "
                      key="1"
                    >
                      <div className="total-cost-user">
                        <div className="line-cost-1">
                          <ul className="info-item">
                            <li>
                              <p className="date-item">Total Amount</p>
                            </li>
                            <li>
                              <p className="date-item-1">₹ 4224.36</p>
                            </li>

                            <li>
                              <p className="date-item">Total VAT</p>
                            </li>
                            <li>
                              <p className="date-item-1">₹ 211.29</p>
                            </li>
                            <li>
                              <p className="date-item">
                                Internet Handling Charges (2.5%)
                              </p>
                            </li>
                            <li>
                              <p className="date-item-1">₹ 211.29</p>
                            </li>

                            <li>
                              <p className="date-item offdata-1">
                                Final Payment
                              </p>
                            </li>
                            <li>
                              <p className="date-item-1 offdata-1">₹ 4546.53</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>
      </div>
    </div>
  );
};
export default Holidaystotal;
