import { Card, Col, Row, Layout, Skeleton } from "antd";
import React from "react";
import "./ActivitiesSuccessSkeleton.scss";

const { Content } = Layout;

const ActivitiesSuccessSkeleton = ({ title }) => {
  return (
    <div className="promo providerapi">
      <Layout className="ourteam-bg">
        <div className="">
          <Content className="admin-container cms-pages-width retrieve-linebar">
            <Card className="retrieve-booking fst-book">
              <Row gutter={[16, 16]}>
                <Col md={12} xs={24}>
                  <h4 className="ticket-lti-bus">{title} ltinerary</h4>
                  <Skeleton active />

                  <Skeleton active />
                </Col>

                <Col md={12} xs={24} className="flight-email-dd-bus">
                  <Row gutter={[16, 16]}>
                    <Col md={24} xs={24}>
                      <Skeleton active paragraph={{ rows: 2 }} />
                    </Col>
                    <Col md={24} xs={24}>
                      <Skeleton.Button active size="large" shape="square" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Content>
        </div>
      </Layout>
    </div>
  );
};

export default ActivitiesSuccessSkeleton;
