import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Upload,
  Modal,
  Button,
  Radio,
  message,
  Col,
  Row,
  Card,
  Popover,
  Table,
  Popconfirm,
  Layout,
  Collapse,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import PersonalInfo from "./PersonalInfo";
import Profile from "./Profile";
import APIClient from "../../helpers/ApiClient";
import VerifyOtpModal from "../../common/OtpModal/VerifyOtpModal";
import {
  LoadingOutlined,
  PlusOutlined,
  createFromIconfontCN,
  GoogleOutlined,
  InstagramOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  FormOutlined,
  DeleteOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const { Content } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});

const BusinessAddress = () => {
  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-class" md={6} xs={24}>
          <Form.Item
            label="Company Name"
            name="CompanyName"
            rules={[
              {
                required: true,
                message: "Required Field",
              },
            ]}
          >
            <Input placeholder="Enter Company Name" />
          </Form.Item>
        </Col>
        <Col className="gutter-class" md={6} xs={24}>
          <Form.Item
            label="Email"
            name="Email"
            rules={[
              {
                required: true,
                message: "Required Field",
              },
            ]}
          >
            <Input placeholder="Enter Email" />
          </Form.Item>
        </Col>
        <Col className="gutter-class" md={6} xs={24}>
          <Form.Item label="Mobile Number" name="mno">
            <Row gutter={5}>
              <Col className="gutter-class" md={8}>
                <Form.Item
                  name="CountryCode"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select>
                    <Option value="1">91</Option>
                    <Option value="2">234</Option>
                    <Option value="3">123</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-class" md={16}>
                <Form.Item
                  name="MobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Required Field",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Mobile number"
                    name="MobileNumber"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col className="gutter-class" md={6} xs={24}>
          <Form.Item label="Alternate Number" name="ano">
            <Row gutter={5}>
              <Col className="gutter-class" md={8}>
                <Form.Item
                  name="AlternateCountryCode"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Select>
                    <Option value="1">91</Option>
                    <Option value="2">234</Option>
                    <Option value="3">123</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-class" md={16}>
                <Form.Item
                  name="AlternateMobileNumber"
                  rules={[
                    {
                      required: true,
                      message: "Required Field",
                    },
                  ]}
                >
                  <Input placeholder="Enter Alternate number" />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

const Settings = () => {
  const initialState = {
    Email: "",
    Mobile: "",
    Name: "",
    Gender: 0,
    DOB: "",
    ProfilePic: null,
  };
  const initialSettings = {
    UserID: 1,
    userCurrency: {},
    userDetails: {},
    role: {},
    userBusinessDetails: {},
    userEmailConfig: {},
    userGstVat: {},
    userSocialDetails: {},
    BusinessAddress: [],
    BalanceAlerts: [],
    UserDayLimits: [],
  };

  const [userSettings, setUserSettings] = useState(initialSettings);
  const [formLayout, setFormLayout] = useState("vertical");
  const [isBusiAddrEdit, setIsBusiAddrEdit] = useState(false);
  const [isbalAlertEdit, setIsBalAlertEdit] = useState(false);
  const [isDayLimitEdit, setIsDayLimitEdit] = useState(false);
  const [businessAddressID, setBusinessAddressID] = useState(-1);
  const [balanceAlertID, setBalanceAlertID] = useState(-1);
  const [dayLimitID, setDayLimitID] = useState(-1);
  const [BusinessDetailsForm] = Form.useForm();
  const [GstVatForm] = Form.useForm();
  const [EmailForm] = Form.useForm();
  const [BalanceForm] = Form.useForm();
  const [DailyLimit] = Form.useForm();
  const [CurrencyForm] = Form.useForm();
  const [PersonalInfoForm] = Form.useForm();
  const [PaymentGatewayForm] = Form.useForm();
  const [ConvenienceFeeForm] = Form.useForm();
  const [insuranceForm] = Form.useForm();
  const [form] = Form.useForm();
  const [ChangePasswordForm] = Form.useForm();
  const [ChangeTranactionPasswordForm] = Form.useForm();
  const [ProfilePicForm] = Form.useForm();
  const [BusinessForm] = Form.useForm();
  const [socialForm] = Form.useForm();
  const [PaymentGatewayDetails, setPaymentGatewayDetails] = useState([]);
  const [PGEdit, setPGEdit] = useState(false);
  const [PGEditId, setPGEditId] = useState(-1);
  const [CFEdit, setCFEdit] = useState(false);
  const [InsuranceEdit, setInsuranceEdit] = useState(false);
  const [userData, setUserData] = useState(initialState);
  const [convenienceFee, setconvenienceFee] = useState([]);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [insuranceData, setinsuranceData] = useState([]);
  const [formType, setFormType] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    getSettingsData();
    GetConvenienceFeeData();
    GetInsuranceData();
  }, []);

  const handleVerifyOtp = (otpValue) => {
    APIClient.post("admin/verifyserviceOtp", {
      Mobile: userSettings.userDetails.Phone,
      DialingCode: "+91",
      Otp: Number(otpValue),
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);
          if (formType === 1) {
            ChangePasswordForm.submit();
          } else {
            ChangeTranactionPasswordForm.submit();
          }
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const handleSendOTP = (type) => {
    APIClient.post("admin/sendserviceOtp", {
      Mobile: userSettings.userDetails.Phone,
      DialingCode: "+91",
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(true);
          setFormType(type);
        } else {
          if (res?.message) message.error(res.message, 3);
          else message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  const GetConvenienceFeeData = () => {
    APIClient.get("admin/convenienceFee")
      .then((response) => {
        if (response.status == 200) {
          setconvenienceFee(response.data);
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const GetInsuranceData = () => {
    setinsuranceData([]);
    APIClient.get("admin/getAllInsurance")
      .then((response) => {
        if (response.status == 200) {
          setinsuranceData(response.data);
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const UpdateConvenienceFeeData = (values) => {
    let data = {
      ...values,
      Value: values.ServiceValue,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };
    delete data.ServiceValue;

    APIClient.put("admin/convenienceFee/" + convId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Convenience Fee Added Successsfully");
          GetConvenienceFeeData();
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddConvenienceFee = (values) => {
    let data = {
      ...values,
      Value: values.ServiceValue,
      CreatedBy: "1",
      DeviceOS: 1,
      ApplicationType: 1,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
    };

    APIClient.post("admin/convenienceFee", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Convenience Fee Added Successsfully");
          GetConvenienceFeeData();
          return;
        } else if (response.status == 409) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*
  Insurance Update & Insert methods
  */ const UpdateInsuranceData = (values) => {
    let data = {
      ...values,
    };

    APIClient.put("admin/updateInsurance/" + insuranceId, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Insurance Record Update Successsfully.");
          GetInsuranceData();
          insuranceForm.resetFields();
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const AddInsuranceData = (values) => {
    let data = {
      ...values,
    };

    APIClient.post("admin/addInsurance", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Insurance Record Added Successsfully.");
          GetInsuranceData();
          insuranceForm.resetFields();
          return;
        } else if (response.status == 409) {
          message.error(response.message, 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteInsuranceData = (id) => {
    APIClient.delete("admin/deleteInsurance/" + id)
      .then((response) => {
        if (response.status == 200) {
          GetInsuranceData();
          message.success("insurance Record Deleted Successsfully");
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /**
   * End
   */

  const DeleteConvenienceFeeData = (id) => {
    APIClient.delete("admin/convenienceFee/" + id)
      .then((response) => {
        if (response.status == 200) {
          GetConvenienceFeeData();
          message.success("Convenience Fee Deleted Successsfully");
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  function onChange(date, dateString) { }

  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);
  onChange = (e) => {
    setDiscountBtn(e.target.value);
  };

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };

  const closeModal = () => {
    setModalVisible({
      visible: false,
    });
  };
  const [convId, setConvId] = useState(-1);
  const [insuranceId, setinsuranceId] = useState(-1);
  const columns = [
    {
      title: "Service",
      dataIndex: "ServiceType",
      refno: "",
      sorter: {
        compare: (a, b) => a.ServiceType - b.ServiceType,
        // multiple: 1
      },
      // sorter: true,
      key: "ServiceType",
      render: (text) => {
        switch (text) {
          case 3:
            return "Bus";

          case 1:
            return "Flight";

          case 2:
            return "Hotel";
          case 4:
            return "Activities";
          case 5:
            return "Build Package";

          default:
            return;
        }
      },
    },
    {
      title: "Convenience Fee Type",
      dataIndex: "ConvenienceFeeType",
      // sorter: {
      //     compare: (a,b) => a.destination - b.destination,
      //     multiple: 2
      // }
      // sorter: true,
      key: "ConvenienceFeeType",
      render: (text) => {
        switch (text) {
          case 0:
            return "Percentage";

          case 1:
            return "Fixed";

          default:
            return;
        }
      },
    },
    {
      title: "Value",
      dataIndex: "Value",
      key: "Value",
    },
    {
      dataIndex: "ID",
      title: "Actions",
      render: (text, record, index) => (
        <span
          className="edit-icon"
          onClick={() => {
            setCFEdit(true);
            setConvId(text);
            ConvenienceFeeForm.setFieldsValue({
              ServiceType: record.ServiceType,
              ServiceValue: record.Value,
              ConvenienceFeeType: record.ConvenienceFeeType === 1 ? 1 : 0,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </span>
      ),
      // render: updateformatWithIcon(),
    },
    {
      dataIndex: "Delete",
      title: "Delete",
      render: (text, record) => (
        // this.dataSource.length >= 1 ? (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => DeleteConvenienceFeeData(record.ID)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  const InsuraceColumns = [
    {
      title: "Service",
      dataIndex: "serviceType",
      refno: "",
      sorter: {
        compare: (a, b) => a.ServiceType - b.ServiceType,
        // multiple: 1
      },
      // sorter: true,
      key: "ServiceType",
      render: (text) => {
        switch (text) {
          case 3:
            return "Bus";

          case 1:
            return "Flight";

          case 2:
            return "Hotel";
          case 4:
            return "Activities";
          case 5:
            return "Build Package";

          default:
            return;
        }
      },
    },
    {
      title: "Insurance Coverage",
      dataIndex: "insuranceCoverage",
    },
    {
      title: "Premium Amount",
      dataIndex: "amount",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        switch (status) {
          case 0:
            return "In Active";

          case 1:
            return "Active";

          default:
            return;
        }
      },
    },
    {
      dataIndex: "InsuranceID",
      title: "Actions",
      render: (text, record, index) => (
        <span
          className="edit-icon"
          onClick={() => {
            setInsuranceEdit(true);
            setinsuranceId(text);
            insuranceForm.setFieldsValue({
              serviceType: record.serviceType,
              insuranceCoverage: record.insuranceCoverage,
              amount: record.amount,
              status: record.status,
              description_box_1: record.description_box_1,
              description_box_2: record.description_box_2,
              description_box_3: record.description_box_3,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </span>
      ),
      // render: updateformatWithIcon(),
    },

    {
      title: "Delete",
      render: (text, record) => (
        // this.dataSource.length >= 1 ? (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => DeleteInsuranceData(record.InsuranceID)}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  const columnsBusiness = [
    {
      title: "Address",
      dataIndex: "Address",
      key: "Address",
    },
    {
      title: "City",
      dataIndex: "CityName",
      key: "city",
    },
    {
      title: "Postal/Zip Code",
      dataIndex: "PostalCode",
      key: "PostalCode",
    },
    {
      title: "State",
      dataIndex: "StateName",
      key: "state",
    },
    {
      title: "Country",
      dataIndex: "CountryName",
      key: "country",
    },
    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <span
            className="edit-icon"
            onClick={() => {
              updateBusinessAddress(record);
            }}
          >
            <FormOutlined />
          </span>
          <Popconfirm
            title="Are u sure to delete?"
            onConfirm={() => deleteBusinessAddress(record.BusinessAddressID)}
          >
            <span className="delete-icon">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  const columnsService = [
    {
      title: "Service",
      dataIndex: "ServiceID",
      key: "ServiceID",
      render: (text) => getSeriveType(text),
    },
    {
      title: "Application Type",
      dataIndex: "ApplicationTypeID",
      key: "ApplicationTypeID",
      render: (text) => getApplicationType(text),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },

    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <span
            className="edit-icon"
            onClick={() => {
              updateBalanceAlert(record);
            }}
          >
            <FormOutlined />
          </span>
          <Popconfirm
            title="Are u sure to delete?"
            onConfirm={() => deleteBalanceAlert(record.BalanceAlertID)}
          >
            <span className="delete-icon">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnsDayLimit = [
    {
      title: "Service",
      dataIndex: "ServiceID",
      key: "ServiceID",
      render: (text) => getSeriveType(text),
    },
    {
      title: "Application Type",
      dataIndex: "ApplicationTypeID",
      key: "ApplicationTypeID",
      render: (text) => getApplicationType(text),
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },

    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
          }}
        >
          <span
            className="edit-icon"
            onClick={() => {
              updateDayLimit(record);
            }}
          >
            <FormOutlined />
          </span>
          <Popconfirm
            title="Are u sure to delete?"
            onConfirm={() => deleteDayLimit(record.DayLimitID)}
          >
            <span className="delete-icon">
              <DeleteOutlined />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const updateDayLimit = (record) => {
    setIsDayLimitEdit(true);
    setDayLimitID(record.DayLimitID);
    DailyLimit.setFieldsValue({
      ...record,
    });
  };

  const deleteDayLimit = (id) => {
    APIClient.delete("admin/dayLimit/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Service Balance Alert Deleted  successfully", 3);
        getSettingsData();
      }
    });
  };

  const deleteBalanceAlert = (id) => {
    APIClient.delete("admin/balanceAlert/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Service Balance Alert Deleted  successfully", 3);
        getSettingsData();
      }
    });
  };

  const updateBalanceAlert = (record) => {
    setIsBalAlertEdit(true);
    setBalanceAlertID(record.BalanceAlertID);
    BalanceForm.setFieldsValue({
      ...record,
    });
  };

  const deleteBusinessAddress = (id) => {
    APIClient.delete("admin/businessAddress/" + id).then((res) => {
      if (res.status == 200) {
        message.success("Business Address Deleted  successfully", 3);
        getSettingsData();
      }
    });
  };

  const updateBusinessAddress = (record) => {
    setIsBusiAddrEdit(true);
    setBusinessAddressID(record.BusinessAddressID);
    BusinessForm.setFieldsValue({
      Address: record.Address,
      PostalCode: record.PostalCode,
      StateName: record.StateName,
      CityName: record.CityName,
      CountryName: record.CountryName,
    });
  };

  const handleSocial = (value) => {
    let data = {
      UserID: 1,
      ...value,
      Status: 0,
      CreatedBy: 0,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 0,
      FirBaseToken: "string",
    };

    APIClient.post("admin/socialMedia", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Social Details Added SuccessFully", 3);
          getSettingsData();
          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const columnsGateway = [
    {
      title: "Gateway Name",
      dataIndex: "PGName",
      refno: "",
      sorter: {
        compare: (a, b) => a.source - b.source,
        // multiple: 1
      },
      // sorter: true,
      key: "name",
      // filter: this.getColumnSearchProp('source')
    },
    {
      title: "Merchant ID",
      dataIndex: "MerchantId",
      // sorter: {
      //     compare: (a,b) => a.destination - b.destination,
      //     multiple: 2
      // }
      // sorter: true,
      key: "MerchantID",
    },
    {
      title: "Application Secret",
      dataIndex: "SecretKey",
      key: "SecretKey",
    },

    {
      dataIndex: "Actions",
      title: "Actions",
      render: (text, record, index) => (
        <span
          className="edit-icon"
          onClick={() => {
            PaymentGatewayForm.resetFields();
            setPGEdit(true);
            if (record.PGImage != null) {
              setPgImageUrl(
                BASE + record.PGImage.substr(1, record.PGImage.length)
              );
            }

            setPGEditId(record.PGID);

            PaymentGatewayForm.setFieldsValue({
              PGName: record.PGName,
              MerchantId: record.MerchantId,
              SecretKey: record.SecretKey,
            });
          }}
        >
          <i className="fa fa-edit"></i>
        </span>
      ),
      // render: updateformatWithIcon(),
    },
    {
      dataIndex: "PGID",
      title: "Delete",
      render: (text, record) => (
        // this.dataSource.length >= 1 ? (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => {
            DeletePaymentGateway(text);
          }}
        >
          <span className="delete-icon">
            <i className="fa fa-trash"></i>
          </span>
        </Popconfirm>
      ),
      // ) : null,
    },
  ];

  const getUserProfile = () => {
    let userID = 1;

    APIClient.get(`admin/b2c/user/${userID}`)
      .then((response) => {
        if (response.status == 200) {
          setUserData((prev) => ({
            ...prev,
            ...response.data.userDetails,
          }));
          PersonalInfoForm.setFieldsValue({
            Email: response.data.userDetails.Email,
            Phone: response.data.userDetails.Mobile,
          });
          return;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSettingsData = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          BusinessDetailsForm.setFieldsValue({
            ...settings.userBusinessDetails,
          });
          PersonalInfoForm.setFieldsValue({
            ...settings.userDetails,
          });
          GstVatForm.setFieldsValue({ ...settings.userGstVat });
          EmailForm.setFieldsValue({
            ...settings.userEmailConfig,
          });
          CurrencyForm.setFieldsValue({
            ...settings.userCurrency,
          });
          socialForm.setFieldsValue({
            ...settings.userSocialDetails,
          });

          setUserSettings((prev) => ({ ...prev, ...settings }));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const personalInfoApi = (values) => {
    APIClient.post("admin/personalInfo", {
      UserID: 1,
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          message.success("Personal Information Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // GST/VAT API CALL
  const gstVatApi = (values) => {
    APIClient.post("admin/GstVat", {
      UserID: 1,
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          message.success("GST/VAT Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitBusinessDetails = (values) => {
    APIClient.post("admin/businessDetails", { UserID: 1, ...values })
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Details Updated SuccessFully", 3);

          return;
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitBusinessForm = (values) => {
    let data = {
      UserID: 1,
      ...values,
    };
    isBusiAddrEdit ? udateBusiAddr(data) : submitBusiAddr(data);
  };

  const submitBusiAddr = (formData) => {
    APIClient.post("admin/businessAddress", formData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Address Added SuccessFully", 3);
          getSettingsData();
          BusinessForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const udateBusiAddr = (formData) => {
    const data = {
      BusinessAddressID: businessAddressID,
      ...formData,
    };
    APIClient.post("admin/updateBusinessAddress", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Address Updated SuccessFully", 3);
          getSettingsData();
          setBusinessAddressID(-1);
          setIsBusiAddrEdit(false);
          BusinessForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitEmailConfig = (values) => {
    APIClient.post("admin/emailConfig", { UserID: 1, ...values })
      .then((response) => {
        if (response.status == 200) {
          message.success("Business Details Updated SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("User Not Found", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const balanceApi = (values) => {
    let data = {
      UserID: 1,
      ...values,
    };
    isbalAlertEdit ? updateBalanceApi(data) : submitBalanceApi(data);
  };

  const submitBalanceApi = (formData) => {
    APIClient.post("admin/balanceAlert", formData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Balance Updated SuccessFully", 3);
          getSettingsData();
          BalanceForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateBalanceApi = (formData) => {
    const data = {
      BalanceAlertID: balanceAlertID,
      ...formData,
    };

    APIClient.post("admin/balanceAlert", data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Balance Updated SuccessFully", 3);
          getSettingsData();
          setBalanceAlertID(-1);
          setIsBalAlertEdit(false);
          BalanceForm.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getSeriveType = (num) => {
    if (num == 2) {
      return "Flight";
    } else if (num == 3) {
      return "Hotels";
    } else {
      return "Not Applicable";
    }
  };

  const getApplicationType = (num) => {
    if (num == 1) {
      return "Web";
    } else if (num == 2) {
      return "Mobile";
    } else {
      return "Not Applicable";
    }
  };

  const dailyLimitApi = (values) => {
    let data = {
      UserID: 1,
      ...values,
    };
    isDayLimitEdit ? updateDayLimitApi(data) : submitDayLimitApi(data);
  };

  const submitDayLimitApi = (formData) => {
    APIClient.post("admin/dayLimit", formData)
      .then((response) => {
        if (response.status == 200) {
          getSettingsData();
          DailyLimit.resetFields();
          message.success("Day Limit Added SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updateDayLimitApi = (formData) => {
    const data = {
      DayLimitID: dayLimitID,
      ...formData,
    };

    APIClient.post("admin/updateDayLimit", data)
      .then((response) => {
        if (response.status == 200) {
          getSettingsData();
          setDayLimitID(-1);
          setIsDayLimitEdit(false);
          message.success("Day Limit Updated SuccessFully", 3);
          DailyLimit.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // currencyAPI
  const currencyApi = (values) => {
    APIClient.post("admin/currency", {
      UserID: 1,
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          message.success("Currency Updated SuccessFully", 3);
          return;
        } else if (response.status == 404) {
          message.error(" Not Found", 3);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const changePasswordApi = (values) => {
    let obj = {
      UserID: 1,
      OldPassword: values.oldpassword,
      NewPassword: values.newpassword,
    };
    APIClient.post("admin/changePassword", obj).then((response) => {
      if (response.status === 200) {
        message.success("Your password has been changed successfully", 3);
        ChangePasswordForm.resetFields();
      } else if (response.status === 400) {
        ChangePasswordForm.setFields([
          {
            name: "oldpassword",
            errors: ["Invalid Password"],
          },
        ]);
      } else if (response.status === 409) {
        message.error(response.message, 3);
        ChangePasswordForm.setFields([
          {
            name: "oldpassword",
            errors: [response.message],
          },
        ]);
      }
    });
  };
  const changeTranactionPasswordApi = (values) => {
    let obj = {
      UserID: 1,
      OldPassword: values.oldpassword,
      NewPassword: values.newpassword,
    };

    APIClient.post("admin/changeTransactionPassword", obj).then((response) => {
      if (response.status === 200) {
        message.success("Your password has been changed successfully", 3);
        ChangeTranactionPasswordForm.resetFields();
      } else if (response.status === 400) {
        ChangeTranactionPasswordForm.setFields([
          {
            name: "oldpassword",
            errors: ["Invalid Password"],
          },
        ]);
      } else if (response.status === 409) {
        message.error(response.message, 3);
        ChangeTranactionPasswordForm.setFields([
          {
            name: "oldpassword",
            errors: [response.message],
          },
        ]);
      }
    });
  };

  // paymentGateway api
  const GetPaymentGateway = () => {
    APIClient.get("admin/pgKeys")
      .then((response) => {
        if (response.status == 200) {
          setPaymentGatewayDetails(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeletePaymentGateway = (id) => {
    APIClient.delete("admin/pgKeys/" + id)
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          message.success("Deleted Successfully");
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdatePaymentGateway = (data) => {
    APIClient.put("admin/pgKeys/" + PGEditId, [], data)
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          PaymentGatewayForm.resetFields();
          setPGEdit(false);
          setPgImageUrl(null);
          message.success("Updated Successfully");
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const SetPaymentGateway = (values) => {
    values.PGImage = values.pgLogo.file.response.data.filepath;
    values.pgLogo = undefined;
    if (PGEdit) {
      UpdatePaymentGateway({
        CreatedBy: 0,
        DeviceOS: 0,
        DeviceOSVersion: "string",
        DeviceToken: "string",
        FirBaseToken: "string",
        ...values,
      });
      return;
    }

    APIClient.post("admin/pgKeys", {
      CreatedBy: 0,
      DeviceOS: 0,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      FirBaseToken: "string",
      ...values,
    })
      .then((response) => {
        if (response.status == 200) {
          GetPaymentGateway();
          message.success("Added Successfully");
          setPgImageUrl(null);
          return;
        }
        message.error("Something Went Wrong", 3);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [PgimageUrl, setPgImageUrl] = useState();
  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  function handleUploadChange(info) {
    if (info.file.status === "error") {
      message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      if (info.file.response.status == 200) {
        PaymentGatewayForm.setFieldsValue({
          image: info.file.response.data.filepath,
        });
        // setFormData({
        //   ...formData,
        //   Image: info.file.response.data.filepath,
        // });
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          setPgImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/singleImage";

  const handleProfilePic = (values) => {
    const UserID = 1;
    const { ProfilePic } = values;
    const { file } = ProfilePic;
    if (file.response.status == 200) {
      let profilePic = {
        ...userData,
        ProfilePic: file.response.data.filepath.substring(1),
      };
      APIClient.put(`admin/b2c/user/${UserID}`, {}, profilePic)
        .then((response) => {
          if (response.status == 200) {
            message.success("Profile Pic Updated SuccessFully", 3);
            getUserProfile();
          } else if (response.status == 404) {
            message.error("Not Found", 3);
          } else {
            message.error("Something Went Wrong", 3);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row gutter={16}>
            <Col className="gutter-class left-details" md={18} xs={24}>
              <Card bordered={false}>
                <Form layout={"vertical"}>
                  <h5>
                    Settings <HelpInfoHelper screenName={"/admin/settings"} />
                  </h5>
                  <Collapse accordion defaultActiveKey={["1"]}>
                    <Panel header="Personal Information" key="1">
                      <Form
                        name="personal_form"
                        onFinish={personalInfoApi}
                        form={PersonalInfoForm}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <PersonalInfo />
                          </div>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              onClick={() => {
                                PersonalInfoForm.submit();
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Panel>
                    <Panel header="Business" key="3">
                      <Form
                        form={BusinessForm}
                        name="BusinessForm"
                        onFinish={submitBusinessForm}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="Address"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                  name="Address"
                                >
                                  <Input placeholder="Enter Address" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="City"
                                  name="CityName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter City" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="Postal code/Zip code"
                                  name="PostalCode"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter zip code" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="State"
                                  name="StateName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter State" />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-class" md={6} xs={24}>
                                <Form.Item
                                  label="Country"
                                  name="CountryName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Country" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              style={{ marginRight: "5px" }}
                              onClick={() => {
                                BusinessForm.submit();
                              }}
                            >
                              {isBusiAddrEdit ? "Update" : "Submit"}
                            </Button>
                            <Button
                              type="primary"
                              style={{ marginLeft: "5px" }}
                              onClick={() => {
                                BusinessForm.resetFields();
                                setBusinessAddressID(-1);
                                setIsBusiAddrEdit(false);
                              }}
                            >
                              Reset
                            </Button>
                          </div>
                        </div>
                        {/* table */}
                        <Table
                          className="table-scroll-none fee-conven"
                          bordered
                          dataSource={userSettings.BusinessAddress}
                          columns={columnsBusiness}
                        />
                      </Form>
                    </Panel>
                    {/* <Panel header="GST/VAT" key="4">
                        <Form
                          name="gstvat_details_form"
                          onFinish={gstVatApi}
                          form={GstVatForm}
                        >
                          <div className="company-logos">
                            <div className="logo-section">
                              <div className="gst-section">
                                <Row gutter={16}>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="GST Company Name"
                                      name="GstCompanyName"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter GST Company Name" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="GST Number"
                                      name="GstNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter GST Number" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="GST Company Contact Number"
                                      name="GstCompanyContactNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter Contact Number" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="GST Company Email"
                                      name="GstCompanyEmail"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter GST Company Email" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="GST Company Address"
                                      name="GstCompanyAddress"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter GST Company Address" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                              <div className="vat-section">
                                <Row gutter={16}>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="VAT Company Name"
                                      name="VatCompanyName"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter VAT Company Name" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="VAT Number"
                                      name="VatNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter VAT Number" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="VAT Company Contact Number"
                                      name="VatCompanyContactNumber"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter Contact Number" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="VAT Company Email"
                                      name="VatCompanyEmail"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter VAT Company Email" />
                                    </Form.Item>
                                  </Col>
                                  <Col
                                    className="gutter-class"
                                    md={6}
                                    xs={24}
                                  >
                                    <Form.Item
                                      label="VAT Company Address"
                                      name="VatCompanyAddress"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Required Field",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Enter VAT Company Address" />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                            <div
                              className="upload-btns"
                              style={{ textAlign: "right" }}
                            >
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Panel> */}
                    {/* <Panel header="Email" key="5">
                        <Form
                          name="email_details_form"
                          onFinish={submitEmailConfig}
                          form={EmailForm}
                        >
                          <div className="company-logos">
                            <div className="logo-section">
                              <Row gutter={16}>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Email"
                                    name="Email"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Email"
                                      id="Email"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Email Password"
                                    name="EmailPassword"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Email Password"
                                      id="EmailPassword"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="SMTP Host"
                                    name="SMTPHost"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter SMTP Host"
                                      id="SMTPHost"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="SMTP Port"
                                    name="SMTPPort"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter SMTP Port"
                                      id="SMTPPort"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div
                              className="upload-btns"
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                type="primary"
                                style={{ marginRight: "5px" }}
                              >
                                Test
                              </Button>
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Panel>
                      <Panel header="Service Balance Alerts" key="6">
                        <Form
                          name="balance_details_form"
                          onFinish={balanceApi}
                          form={BalanceForm}
                        >
                          <div className="company-logos">
                            <div className="logo-section">
                              <Row gutter={16}>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Service"
                                    name="ServiceID"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Select Service">
                                      <Option value={2}>Flights</Option>
                                      <Option value={3}>Hotels</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Application Type"
                                    name="ApplicationTypeID"
                                  >
                                    <Select placeholder="Please Select">
                                      <Option value={1}>Website</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Amount"
                                    name="Amount"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      placeholder="Enter Amount"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div
                              className="upload-btns"
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                type="primary"
                                style={{ marginRight: "5px" }}
                                htmlType="submit"
                              >
                                {isbalAlertEdit ? "Update" : "Submit"}
                              </Button>
                              <Button
                                type="primary"
                                onClick={() => BalanceForm.resetFields()}
                              >
                                Clear
                              </Button>
                              {isbalAlertEdit ? (
                                <Button
                                  type="primary"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    BalanceForm.resetFields();
                                    setBalanceAlertID(-1);
                                    setIsBalAlertEdit(false);
                                  }}
                                >
                                  Reset
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </Form>
                        <Table
                          className="table-scroll-none fee-conven"
                          bordered
                          dataSource={userSettings.BalanceAlerts}
                          columns={columnsService}
                        />
                      </Panel> */}
                    {/* <Panel header="Day Limit" key="7">
                        <Form
                          name="daily_details_form"
                          onFinish={dailyLimitApi}
                          form={DailyLimit}
                        >
                          <div className="company-logos">
                            <div className="logo-section">
                              <Row gutter={16}>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Service"
                                    name="ServiceID"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <Select placeholder="Select Service">
                                      <Option value={2}>Flights</Option>
                                      <Option value={3}>Hotels</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Application Type"
                                    name="ApplicationTypeID"
                                  >
                                    <Select placeholder="Please Select">
                                      <Option value={1}>Website</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Amount"
                                    name="Amount"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: "100%" }}
                                      min={0}
                                      placeholder="Enter Amount"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div
                              className="upload-btns"
                              style={{ textAlign: "right" }}
                            >
                              <Button
                                type="primary"
                                style={{ marginRight: "5px" }}
                                htmlType="submit"
                              >
                                {isDayLimitEdit ? "Update" : "Submit"}
                              </Button>
                              <Button
                                type="primary"
                                onClick={() => DailyLimit.resetFields()}
                              >
                                Clear
                              </Button>
                              {isDayLimitEdit ? (
                                <Button
                                  type="primary"
                                  style={{ marginLeft: "5px" }}
                                  onClick={() => {
                                    DailyLimit.resetFields();
                                    setDayLimitID(-1);
                                    setIsDayLimitEdit(false);
                                  }}
                                >
                                  Reset
                                </Button>
                              ) : null}
                            </div>
                          </div>
                        </Form>
                        <Table
                          className="table-scroll-none fee-conven"
                          bordered
                          dataSource={userSettings.UserDayLimits}
                          columns={columnsDayLimit}
                        />
                      </Panel>
                      <Panel header="Currency" key="8">
                        <Form
                          name="currency_details_form"
                          onFinish={currencyApi}
                          form={CurrencyForm}
                        >
                          <div className="company-logos">
                            <div className="logo-section">
                              <Row gutter={16}>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Currency"
                                    name="CurrencyTypeID"
                                  >
                                    <Select placeholder="Select Currency">
                                      <Option value={1}>USD</Option>
                                      <Option value={2}>INR</Option>
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item
                                    label="Amount"
                                    name="Amount"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Required Field",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      min={0}
                                      placeholder="Enter Amount"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={6} xs={24}>
                                  <Form.Item name="Type">
                                    <Radio.Group>
                                      <Radio value={1} name="Type">
                                        Manual
                                      </Radio>
                                      <Radio value={2} name="Type">
                                        Through API
                                      </Radio>
                                    </Radio.Group>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                            <div
                              className="upload-btns"
                              style={{ textAlign: "right" }}
                            >
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Panel> */}
                    <Panel header="Social Media" key="9">
                      <Form
                        name="socialForm"
                        onFinish={handleSocial}
                        form={socialForm}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <IconFont type="icon-facebook" />
                                    </span>
                                  }
                                  name="FacebookUrl"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="Facebook AppId"
                                  name="FacebookAppID"
                                >
                                  <Input placeholder="Enter " />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <GoogleOutlined />
                                    </span>
                                  }
                                  name="GoogleUrl"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="Google AppId"
                                  name="GoogleAppID"
                                >
                                  <Input placeholder="Enter " />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <InstagramOutlined />
                                    </span>
                                  }
                                  name="Instagram"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <TwitterOutlined />
                                    </span>
                                  }
                                  name="Twitter"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <LinkedinOutlined />
                                    </span>
                                  }
                                  name="Linkedin"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label={
                                    <span>
                                      <YoutubeOutlined />
                                    </span>
                                  }
                                  name="Youtube"
                                >
                                  <Input placeholder="" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              onClick={() => {
                                socialForm.submit();
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Panel>
                    {/* <Panel header="Payment Gateway Details" key="10">
                      <Form
                        name="pg_details_form"
                        onFinish={SetPaymentGateway}
                        form={PaymentGatewayForm}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Payment Gateway Name"
                                  name="PGName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Gateway">
                                    {PaymentGatewayDetails.map((i) => (
                                      <Option value={i.PGName}>
                                        {i.PGName}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Application ID"
                                  name="MerchantId"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Application ID" />
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Application Secret"
                                  name="SecretKey"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Secret ID" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <Row>
                            <Form.Item name="pgLogo">
                              <Upload
                                name="image"
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={uploadActionUrl}
                                onChange={handleUploadChange}
                              >
                                {PgimageUrl ? (
                                  <img
                                    src={PgimageUrl}
                                    alt="avatar"
                                    style={{ width: "100%" }}
                                  />
                                ) : (
                                  uploadButton
                                )}
                              </Upload>
                            </Form.Item>
                          </Row>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              style={{ marginRight: "5px" }}
                              onClick={() => {
                                PaymentGatewayForm.resetFields();
                                setPGEdit(false);
                              }}
                            >
                              Clear
                            </Button>
                            <Button
                              type="primary"
                              onClick={() => {
                                PaymentGatewayForm.submit();
                              }}
                            >
                              {PGEdit ? "Update" : "Submit"}
                            </Button>
                          </div>
                        </div>
                        <Table
                          className="table-scroll-none fee-conven"
                          bordered
                          dataSource={PaymentGatewayDetails}
                          columns={columnsGateway}
                        />
                      </Form>
                    </Panel> */}
                    <Panel header="Convenience Fee" key="11">
                      <Form
                        form={ConvenienceFeeForm}
                        name="pg_details_form"
                        onFinish={
                          CFEdit ? UpdateConvenienceFeeData : AddConvenienceFee
                        }
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="Service:"
                                  name="ServiceType"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Service">
                                    <Option value={1}>Flight</Option>
                                    <Option value={2}>Hotel</Option>
                                    <Option value={3}>Bus</Option>
                                    <Option value={4}>Activities</Option>
                                    <Option value={5}>Build Package</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="TripType"
                                  name="TripType"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select TripType">
                                    <Option value={1}>One Way</Option>
                                    <Option value={2}>Round Trip</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="Value:"
                                  name="ServiceValue"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Value" />
                                </Form.Item>
                              </Col>
                              <Col md={6} xs={24}>
                                <Form.Item
                                  label="Convenience Fee Type:"
                                  name="ConvenienceFeeType"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value={0}>Percentage</Radio>
                                    <Radio value={1}>Fixed</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="IsInterNational"
                                  name="IsInterNational"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value={0}>InterNational</Radio>
                                    <Radio value={1}>Domestic</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>

                          </div>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              onClick={() => ConvenienceFeeForm.submit()}
                            >
                              {CFEdit ? "Update" : "Add"}
                            </Button>
                            &nbsp;
                            <Button
                              type="primary"
                              onClick={() => ConvenienceFeeForm.resetFields()}
                            >
                              Clear
                            </Button>
                          </div>

                          <Table
                            className="table-scroll-none fee-conven"
                            bordered
                            dataSource={convenienceFee}
                            columns={columns}
                          />
                        </div>
                      </Form>
                    </Panel>

                    <Panel header="Insurance" key="12">
                      <Form
                        form={insuranceForm}
                        name="pg_details_form"
                        onFinish={
                          InsuranceEdit ? UpdateInsuranceData : AddInsuranceData
                        }
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Service:"
                                  name="serviceType"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Select placeholder="Select Service">
                                    <Option value={1}>Flight</Option>
                                    <Option value={2}>Hotel</Option>
                                    <Option value={3}>Bus</Option>
                                    <Option value={4}>Activities</Option>
                                    <Option value={5}>Build Package</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Insurance Coverage:"
                                  name="insuranceCoverage"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Insurance Coverage" />
                                </Form.Item>
                              </Col>

                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Premium Amount:"
                                  name="amount"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input placeholder="Enter Amount" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Description (1):"
                                  name="description_box_1"
                                >
                                  <Input placeholder="Enter Description 1" />
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Description (2):"
                                  name="description_box_2"
                                >
                                  <Input placeholder="Enter Description 2" />
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item
                                  label="Description (3):"
                                  name="description_box_3"
                                >
                                  <Input placeholder="Enter Description 3" />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Row gutter={16}>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="Status:"
                                  name="status"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Radio.Group>
                                    <Radio value={0}>In Active</Radio>
                                    <Radio value={1}>Active</Radio>
                                  </Radio.Group>
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>
                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              onClick={() => insuranceForm.submit()}
                            >
                              {InsuranceEdit ? "Update" : "Add"}
                            </Button>
                            &nbsp;
                            <Button
                              type="primary"
                              onClick={() => insuranceForm.resetFields()}
                            >
                              Clear
                            </Button>
                          </div>

                          <div>
                            {/* onChange={onChange} expandedRowRender= {rowExpand}*/}
                            <Table
                              className="table-scroll-none fee-conven"
                              bordered
                              dataSource={insuranceData}
                              columns={InsuraceColumns}
                            />

                            {/* <Modal visible={this.state.visible} onCancel={closeModal}>
                                                <h6>This is the modal bodyyyyy</h6>
                                        </Modal> */}
                          </div>
                        </div>
                      </Form>
                    </Panel>

                    <Panel header="Change Password" key="13">
                      <Form
                        form={ChangePasswordForm}
                        name="ChangePassword"
                        onFinish={changePasswordApi}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="Old Password"
                                  name="oldpassword"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input.Password placeholder="Enter old password" />
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="New Password"
                                  name="newpassword"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input.Password placeholder="Enter new password" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>

                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              // onClick={() => {
                              //   ChangePasswordForm.submit();
                              // }}
                              onClick={() => {
                                handleSendOTP(1);
                              }}
                            >
                              Change Password
                            </Button>
                            &nbsp;
                          </div>
                        </div>
                      </Form>
                    </Panel>
                    <Panel header="Change Transaction Password" key="14">
                      <Form
                        form={ChangeTranactionPasswordForm}
                        name="ChangeTranactionPassword"
                        onFinish={changeTranactionPasswordApi}
                      >
                        <div className="company-logos">
                          <div className="logo-section">
                            <Row gutter={16}>
                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="Old Password"
                                  name="oldpassword"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input.Password placeholder="Enter old password" />
                                </Form.Item>
                              </Col>

                              <Col md={12} xs={24}>
                                <Form.Item
                                  label="New Password"
                                  name="newpassword"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Required Field",
                                    },
                                  ]}
                                >
                                  <Input.Password placeholder="Enter new password" />
                                </Form.Item>
                              </Col>
                            </Row>
                          </div>

                          <div
                            className="upload-btns"
                            style={{ textAlign: "right" }}
                          >
                            <Button
                              type="primary"
                              onClick={() => {
                                handleSendOTP("2");
                              }}
                            >
                              Change Password
                            </Button>
                            &nbsp;
                          </div>
                        </div>
                      </Form>
                    </Panel>
                  </Collapse>
                </Form>
              </Card>
            </Col>
            <Col className="gutter-class" md={6} xs={24}>
              <Card bordered={false}>
                <Form form={ProfilePicForm} onFinish={handleProfilePic}>
                  <Profile userData={userData} userRole={userSettings.role} />
                </Form>
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
      {verifyModalVisible && (
        <VerifyOtpModal
          visible={verifyModalVisible}
          setVerifyModalVisible={setVerifyModalVisible}
          handleVerifyOtp={handleVerifyOtp}
          handleResendOtp={handleSendOTP}
          mobile={userSettings.userDetails.Phone}
        />
      )}

      {/* <Modal title="Convenience fee" visible={modalVisible} onOk={(e) => setModalVisible(false)} onCancel={(e) => setModalVisible(false)} footer={[null]}>
        <Form>
          <Form.Item label="Service">
            <Select>
              <Option value={1}>Flight</Option>
              <Option value={2}>Bus</Option>
              <Option value={3}>Hotel</Option>
              <Option value={4}>Activities</Option>
            </Select>
          </Form.Item>

          <Form.Item label="Convenience Fee Type">
            <Radio.Group onChange={onChange} value={discountBtn}>
              <Radio value={1}>Percentage</Radio>
              <Radio value={2}>Fixed</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Value" name="username" rules={[{ required: true }]}>
            <Input />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
            <Button type="primary" danger className="cancel-btn">
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
    </div>
  );
};

export default Settings;
