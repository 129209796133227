import React, { useState, useEffect } from "react";

//import * as ReactBoostrap from "react-bootstrap";
// import * as ANTD from "antd";
import {
  Button,
  Row,
  Card,
  Col,
  Form,
  message,
  Input,
  Radio,
  Upload,
  Select,
  Table,
  Modal,
  Layout,
  Popconfirm,
  Switch,
  Image,
  Checkbox,
} from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./DocumentReqForVisa.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import ApiClient from "../../helpers/ApiClient";
import ReactQuill from "react-quill";
import modules from "../../helpers/reactQuillModules";

const DocumentReqForVisa = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(true);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("vertical");
  const { TextArea } = Input;

  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const options = [
    { label: "Documents", value: "Documents" },
    { label: "Others", value: "Others" },
  ];
  const handleAddClick = () => {
    form.resetFields();
    showModal();
    setIsEditMode(false);
  };
  const handleEditClick = (rec) => {
    form.setFieldsValue({
      CarType: rec.CarType,
      CarDescription: rec.CarDescription,
      Status: rec.Status,
    });
    setFormData({ ...rec, CarTypeID: rec.CarTypeID, CarType: rec.CarType });

    setIsEditMode(true);

    showModal();
  };
  const initialFormData = {
    name: "",
    description: "",
    status: "",
  };
  const [dataSource, setDataSource] = useState([]);
  const getAllCarType = () => {
    // ApiClient.get("carextranet/getallcartype").then((res) => {
    //   if (res.status == 200) {
    //     setDataSource(res.data);
    //   }
    // });
  };
  useEffect(() => {
    // getAllCarType();
  }, []);

  const [formData, setFormData] = useState({});
  const submitNewCarType = (formvalues) => {
    // setFormData(formvalues);
    // ApiClient.post("carextranet/createcartype", formvalues).then((response) => {
    //   if (response.status === 200) {
    //     setModalVisible(false);
    //     message.success("Cartype created successfully");
    //     setFormData({ initialFormData });
    //     getAllCarType();
    //     form.resetFields();
    //   } else {
    //     message.error(" Error Creating CarType ");
    //   }
    // });
  };

  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      submitUpdateCarType(formvalues);
    } else {
      submitNewCarType(formvalues);
    }
  };

  const submitUpdateCarType = (formvalues) => {
    // setFormData(formvalues);

    const updatedFormData = {
      ...formData,
      ...formvalues,
    };
    console.log("formData", formData.CarTypeID);

    // ApiClient.put(
    //   "carextranet/updatecartype/" + formData.CarTypeID,
    //   {},
    //   updatedFormData
    // ).then((response) => {
    //   console.log(response);
    //   if (response.status === 200) {
    //     setModalVisible(false);
    //     message.success("CarType updated successfully");
    //     setFormData({ initialFormData });
    //     getAllCarType();
    //     form.resetFields();
    //   } else {
    //     message.error(" Error Updating CarType ");
    //   }
    // });
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const deleteCarType = (id) => {
    // ApiClient.delete("carextranet/deletecartype/" + id).then((res) => {
    //   if (res.status == 200) {
    //     message.success("CarType Deleted  successfully", 3);
    //     getAllCarType();
    //   }
    // });
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteCarType(rec.CarTypeID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const { Option } = Select;
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };
  const columns = [
    {
      title: "Car Type",
      dataIndex: "CarType",
    },

    {
      title: "Description",
      dataIndex: "CarDescription",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },
    {
      title: "LastModified Date",
      dataIndex: "ModifiedDate",
    },

    {
      title: "Status",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (rec) => {
        return tableActions(rec);
      },
    },
  ];

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const changeStatus = (info) => {
    // let obj = {
    //   Id: info.CarTypeID,
    //   status: Status,
    // };
    // ApiClient.put("carextranet/toggleCarTypeStatus/" + info.CarTypeID)
    //   .then((response) => {
    //     if (response.status == 200) {
    //       message.success("Car Type Status updated successfully !");
    //       getAllCarType();
    //     } else {
    //       message.error(response.message, 3);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  };

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Document Required For Visa{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p></p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  {!dataSource === null ? (
                    <div>
                      <Table
                        className="table-scroll-none"
                        bordered
                        dataSource={dataSource}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 25,
                          showSizeChanger: true,
                          pageSizeOptions: ["25", "50", "100", "125"],
                        }}
                      />
                    </div>
                  ) : (
                    <b>No record Found</b>
                  )}
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Document Required For Visa"
        className="promo-modal-header cartypes-value"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <Form
          layout="vertical"
          name="basic"
          form={form}
          onFinish={handleSubmit}
        >
          <Row gutter={16}>
            <Col md={12} xs={24}>
              <Form.Item
                label="Visa"
                rules={[{ required: true }]}
                name="VisaDropdownlist"
              >
                <Select placeholder="Please Select Your Entry">
                  <Select.Option value="SingleVisa">Single Visa</Select.Option>
                  <Select.Option value="MultipleVisa">
                    Multiple Visa
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={12} xs={24}>
              <Form.Item
                label="Supporting documents"
                name="Supportingdocuments"
                rules={[{ required: true }]}
              >
                <Checkbox.Group
                  options={options}
                  defaultValue={["Other"]}
                  // onChange={onChange}
                />
              </Form.Item>
            </Col>

            <Col md={24} xs={24}>
              <Form.Item name="CarPickupProcedure">
                <ReactQuill modules={modules} placeholder="please input ..." />
              </Form.Item>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item label="Status" name="Status">
                <Radio.Group
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <Radio value={1}>Yes</Radio>
                  <Radio value={2}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                form.resetFields();
              }}
              danger
              className="cancel-btn"
            >
              Clear
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default DocumentReqForVisa;
