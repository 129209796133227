import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import "./Otp.scss";
const Otp = ({ userId, setUserId, setModalVisible }) => {
  const { setUser, setAccessToken, updateLoginFlag, resetIsLogin } =
    useAuthContext();
  const [userOtp, setUserOtp] = useState();

  const history = useHistory();

  const changeOtp = (e) => {
    setUserOtp(e.target.value);
  };

  const verifyOtp = () => {
    let verifyObj = {
      UserID: userId,
      Otp: userOtp,
      OtpType: 2,
    };

    APIClient.post("admin/verifyotp", verifyObj).then((resp) => {
      if (resp.status === 202) {
        setUserId(-1);
        setModalVisible(false);
        if (resp.data.userDetails.Role.RoleLevel == 2) {
          setUser(resp.data.userDetails);
          setAccessToken(resp.accessToken);
          updateLoginFlag(true);
          history.push("/");
        } else if (resp.data.userDetails.Role.RoleLevel == 3) {
          message.success("Registered Successfully. Login Now");
          history.push("/");
        } else {
          resetIsLogin();
        }
      } else if (resp.status == 201) {
        setModalVisible(false);
        message.success(resp.message, 3);
        history.push("/");
      } else if (resp.status == 400) {
        message.error("Invalid Otp", 3);
      } else if (resp.status == 500) {
        message.error("Unable to Login Please Contact Administration", 3);
      } else if (resp.status == 503) {
        message.error(resp.message, 3);
      }
    });
  };
  return (
    <div>
      {/* {showSuccessMessage ? (
        <div className="verified-text">
          <Result
            status="success"
            title="Registered successfully"
            extra={[<Button type="primary">Back To login</Button>]}
          />
        </div>
      ) : ( */}
      <div className="otp-body">
        <p>Enter Your OTP to verify</p>
        <Input
          placeholder="Enter Valid OTP"
          className="otp-input"
          size={"large"}
          onChange={changeOtp}
        />
        <div className="otp-continue-btn">
          <Button type={"primary"} size={"large"} onClick={verifyOtp}>
            Verify Otp
          </Button>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default Otp;
