import React from "react";
import "./FlightsCheckout.scss";
import { Card, Col, Row } from "antd";

import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";

import { FlightTimings } from "../SearchResults/Flight/Flight";
import { useAuthContext } from "../../common/providers/AuthProvider";
const DetailsCard = (props) => {
  const {
    isLogin: { agent },
  } = useAuthContext();
  let travelType = localStorage.getItem("airTravelType");
  let history = useHistory();

  const goTo = () => {
    history.goBack();
  };

  const FlightCard = ({ flightInfo }) => {
    let fareid = sessionStorage.getItem('FareId');
      let roundtripfareid = sessionStorage.getItem('FareId1');
      let faretype=''
      if(fareid !=undefined && roundtripfareid !=undefined)
      {
      if(flightInfo.isRoundTrip){
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==roundtripfareid)[0].coupanType;
      }
      else{
        faretype= flightInfo.fareFamilies.fareFamilies.filter(x=>x.fareId==fareid)[0].coupanType;
      }
    }
    return (
      <Card className="flight-details-card checkout_details_card">
        <div className="airline-details">
          <Row className="airline-row-mobile">
            <Col md={16}>
              <div className="airline-details-left">
                <Row className="airline-row-mobile">
                  <Col md={6} xs={12}>
                    <div className="airline-img">
                      <img src={flightInfo.airLineLogo} alt="" />
                      <div className="token-number-flught">
                      <p style={{ fontWeight: 700 }}>
                        {flightInfo.airLineName}
                      </p>
                      &nbsp;
                      <p style={{ fontWeight: 700 }}>
                        ({flightInfo.flightSegments[0].marketingAirline}-
                        {flightInfo.flightSegments[0].flightNumber})
                      </p>
                    </div>
                    </div>
                  </Col>
                  <Col md={8} className="src-dest-mobile">
                    <div className="airline-src-dest">
                      <p>{flightInfo.origin}</p>
                      <span className="airline-line"></span>
                      {flightInfo.flightSegments.map((segment, index) =>
                        index !== 0 ? <><span className="airline-line"></span><p>{segment.origin}</p></> : null
                      )}
                      <span className="airline-line"></span>
                      <p>
                        {
                          flightInfo.flightSegments[
                            flightInfo.flightSegments.length - 1
                          ].destination
                        }
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={8} className="direct-text">
              <p>
                Journey Date:{" "}
                {dateFormat(
                  flightInfo.flightSegments[0].departureDateTime,
                  "dd mmm (ddd)"
                )}{" "}
              </p>
              <p>
                {flightInfo.flightSegments.length === 1 ? (
                  "Direct"
                ) : (
                  <>
                    {flightInfo.flightSegments.length - 1}
                    stop(s)
                  </>
                )}
              </p>
              {agent ? (
                <p>
                  Fare Type:{" "}
                  {faretype}
                </p>
              ) : null}
            </Col>
          </Row>
          {agent ? (    <Row className="airline-row-mobile">
          <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType =="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Either Be Booked OR Hold </p>} </Col>
          </Row>   ) : null}
          {agent ? (    <Row className="airline-row-mobile">
          <Col md={24}>  {flightInfo.fareFamilies.fareFamilies[0].purchaseType !="Block" && <p className="hold"> {flightInfo.airLineName} Airline Can Only Be Booked But Can't Be Hold </p>} </Col>
          </Row>   ) : null}
        </div>
        <div className="trip-details">
          <Row>
            <FlightTimings
              flightInfo={flightInfo}
              airSearchData={props.flightSearchObj}
              fareFamilies={flightInfo.fareFamilies}
              isSearch ={true}
            />
          </Row>
          
        </div>
      </Card>
    );
  };

  return (
    <div className="flight-details-container">
      <div className="change-flight-details">
        <h3>Flight Details</h3>
        <p
          onClick={() => {
            goTo();
          }}
        >
          Change Flights
        </p>
      </div>

      {props.selectedFlight.length > 0 ? (
        travelType === "oneWay" ? (
          <FlightCard flightInfo={props.selectedFlight[0]} />
        ) : (
          props.selectedFlight.map((flight) => (
            <FlightCard flightInfo={flight} />
          ))
        )
      ) : null}
    </div>
  );
};

export default DetailsCard;
