import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  TimePicker,
  Popover,
} from "antd";
import moment from "moment";

import APIClient from "../../helpers/ApiClient";

import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  IdcardOutlined,
  PlusOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;

const Activitytourrate = (props) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);

  const [activitiesList, setActivitiesList] = useState([]);
  const [tourId, setTourId] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [activitiesOptionList, setActivitiesOptionList] = useState([]);
  const [activitiesRateList, setActivitiesRateList] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();
  const [excelList, setExcelList] = useState([]);

  const changeStatus = (info, status) => {
    let id = info.ID;
    delete info.SNo;
    delete info.ID;
    delete info.tourName;
    delete info.tourOptionName;
    delete info.transferName;
    let obj = {
      ...info,
      status: status,
    };
    updateActivitiesRates(obj, id);
  };

  const handelStatus = (data) => {
    if (data.status == 1) {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const columnActivitiesRates = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Tour Name",
      dataIndex: "tourName",
      sorter: (a, b) => a.optionName.localeCompare(b.optionName),
    },
    {
      title: "Tour Option",
      dataIndex: "tourOptionName",
      sorter: (a, b) => a.tourOptionName.localeCompare(b.tourOptionName),
    },

    {
      title: "Adult Price",
      dataIndex: "adultPrice",
      sorter: (a, b) => a.adultPrice.localeCompare(b.adultPrice),
    },

    {
      title: "Child Price",
      dataIndex: "childPrice",
      sorter: (a, b) => a.childPrice.localeCompare(b.childPrice),
    },
    {
      title: "Infant Price",
      dataIndex: "infantPrice",
      sorter: (a, b) => a.infantPrice.localeCompare(b.infantPrice),
    },
    {
      title: "Opening Hours",
      dataIndex: "startTime",
      sorter: (a, b) => a.startTime.localeCompare(b.startTime),
      render: (startTime) =>
        startTime ? moment(startTime).format("HH:mm:ss") : null,
    },

    {
      title: "Closing Hours",
      dataIndex: "departureTime",
      sorter: (a, b) => a.departureTime.localeCompare(b.departureTime),
      render: (departureTime) =>
        departureTime ? moment(departureTime).format("HH:mm:ss") : null,
    },

    {
      title: "Tour Child Age",
      dataIndex: "tourChildAge",
      sorter: (a, b) => a.tourChildAge.localeCompare(b.tourChildAge),
    },

    {
      title: "max Child Age",
      dataIndex: "maxChildAge",
      sorter: (a, b) => a.maxChildAge.localeCompare(b.maxChildAge),
    },
    {
      title: "max Infant Age",
      dataIndex: "maxInfantAge",
      sorter: (a, b) => a.maxInfantAge.localeCompare(b.maxInfantAge),
    },
    {
      title: "Minimum Pax",
      dataIndex: "minimumPax",
      sorter: (a, b) => a.minimumPax.localeCompare(b.minimumPax),
    },
    {
      title: "Status",
      key: "category",
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  /** Search ****************** */
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = activitiesRateList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setActivitiesRateList(searchResults);
    } else {
      getActivitiesRates();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setLoding(false);
    getActivitiesRates();
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const updateActivitiesFormData = (currentFormData) => {
    //setIsEditMode(false);
    setId(currentFormData.ID);

    form.setFieldsValue({
      ...currentFormData,
      startTime: moment(currentFormData.startTime, "HH:mm:ss"),
      departureTime: moment(currentFormData.startTime, "HH:mm:ss"),
      isSlot: currentFormData?.isSlot == true ? 1 : 2,
      tourName: currentFormData.tourId,
      transferName: currentFormData.transferId,
      tourOptionName: currentFormData.tourOptionId,
      isDefaultTransfer: currentFormData?.isDefaultTransfer == true ? 1 : 2,
      disableChild: currentFormData.disableChild == true ? 1 : 2,
      disableInfant: currentFormData.disableInfant == true ? 1 : 2,
      allowTodaysBooking: currentFormData.allowTodaysBooking == true ? 1 : 2,
      tranferCategoryid:currentFormData.tranferCategoryid
    });
    setModalVisible(true);
    setIsEditMode(true);
  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center" }}

          // onClick={() => updateFlightComm(currentFlightComm)}
        >
          <EditOutlined
            onClick={() => updateActivitiesFormData(CurrentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteActivitiesRates(CurrentItem.ID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };
  const selectTours = (e) => {
    setTourId(e);
  };

  const add = () => {
    if (isEditMode) {
      setIsEditMode(false);
      form.resetFields();
    }
    setModalVisible(true);
  };

  const onFinish = (val) => {
    let obj = {
      transferId: val.transferName,
      tourId: val.tourName,
      tourOptionId: val.tourOptionName,
      adultPrice: val.adultPrice ?? 0,
      childPrice: val.childPrice ?? 0,
      infantPrice: val.infantPrice ?? 0,
      withoutDiscountAmount: val.withoutDiscountAmount ?? 0,
      startTime: val.startTime,
      departureTime: val.departureTime,
      disableChild: val.disableChild,
      disableInfant: val.disableInfant,
      allowTodaysBooking: val.allowTodaysBooking,
      cutOff: 0,
      isSlot: val.isSlot,
      isDefaultTransfer: val.isDefaultTransfer,
      status: 1,
      adultBuyingPrice: val.adultBuyingPrice ?? 0,
      childBuyingPrice: val.childBuyingPrice ?? 0,
      infantBuyingPrice: val.infantBuyingPrice ?? 0,
      adultSellingPrice: val.adultSellingPrice ?? 0,
      childSellingPrice: val.childSellingPrice ?? 0,
      infantSellingPrice: val.infantSellingPrice ?? 0,
      companyBuyingPrice: val.companyBuyingPrice ?? 0,
      companySellingPrice: val.companySellingPrice ?? 0,
      agentBuyingPrice: val.agentBuyingPrice ?? 0,
      agentSellingPrice: val.agentSellingPrice ?? 0,
      subAgentBuyingPrice: 0,
      subAgentSellingPrice: 0,
      finalSellingPrice: 0,
      vatbuying: 0,
      vatselling: 0,
      currencyFactor: 0,
      agentPercentage: 0,
      transferBuyingPrice: 0,
      transferSellingPrice: 0,
      serviceBuyingPrice: 0,
      serviceSellingPrice: 0,
      rewardPoints: 0,
      tourChildAge: val.tourChildAge ?? 0,
      maxChildAge: val.maxChildAge ?? 0,
      maxInfantAge: val.maxInfantAge ?? 0,
      minimumPax: val.minimumPax ?? 0,
      createdBy: 1,
      createdDate: new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
      tranferCategoryid:val.tranferCategoryid,
    };
    setModalVisible(false);
    isEditMode ? updateActivitiesRates(obj, id) : saveActivitiesRates(obj);
  };

  /** API CALLS */

  const updateActivitiesRates = (data, id) => {
    APIClient.put(`extranet/activitiesTourRates/${id}`, {}, data)
      .then((response) => {
        if (response.status == 200) {
          message.success("Activities Rates Updated SuccessFully", 3);
          getActivitiesRates();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const saveActivitiesRates = (data) => {
    APIClient.post("extranet/activitiesTourRates", data)
      .then((resp) => {
        if (resp.status == 200) {
          message.success("Activities Rates Saved SuccessFully", 3);
          getActivitiesRates();
          form.resetFields();
        } else if (resp.status == 400) {
          message.error("Failed to Save Activities Rates", 3);
          setModalVisible(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllActivities = () => {
    APIClient.get("extranet/getAllActivities").then((resp) => {
      if (resp.status == 200) {
        let data = resp.data.map((item) => {
          return {
            tourId: item.tourId,
            tourName: item.tourName,
          };
        });
        setActivitiesList(data);
      }
    });
  };

  const getActivitiesOptions = () => {
    APIClient.get(`extranet/activitiesOptions/${tourId}`)
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((option, index) => {
            return {
              tourOptionId: option.tourOptionId,
              optionName: option.optionName,
            };
          });
          setActivitiesOptionList(data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Data", 3);
        }
      })
      .catch(() => {});
  };

  const getActivitiesRates = () => {
    APIClient.get("extranet/activitiesTourRates")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((rates, index) => {
            return {
              SNo: index + 1,
              ...rates,
            };
          });
          setActivitiesRateList(data);
          defineExcelData(data);
          setLoding(false);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Data", 3);
          setLoding(false);
        }
      })
      .catch(() => {});
  };

  const getTransferCategory = () => {
    APIClient.get(`extranet/transfercategories`)
      .then((resp) => {
        if (resp.status == 200) {
          setCategoryList(resp.data);
         
        
        }
      })
      .catch(() => {setLoding(false);});
  };
  const deleteActivitiesRates = (ID) => {
    APIClient.delete("extranet/activitiesTourRates/" + ID)
      .then((res) => {
        if (res.status == 200) {
          message.success("Activities Rates Deleted  successfully", 3);
          getActivitiesRates();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getTransferCategory();
    getAllActivities();
    getActivitiesRates();
  }, []);

  useEffect(() => {
    if (tourId != null) {
      getActivitiesOptions();
    }
  }, [tourId]);

  const validateMessages = {
    required: "",
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Activities: item.tourName,
        Activities_Option: item.tourOptionName,
        Adult_rice: item.adultPrice,
        Child_Price: item.childPrice,
        Infant_Price: item.infantPrice,
        Opening_Hours: moment(item.startTime).format("HH:mm:ss"),
        Closing_Hours: moment(item.departureTime).format("HH:mm:ss"),
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };
  const reset = () => {
    form.resetFields();
  };

  let lastIndex = 0;
  const uniqueId = () => {
    lastIndex++;
    return lastIndex;
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <div className="admin-container">
            <Row>
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    <div className="card-add-heading">
                      <div className="rows-count">
                        <div>
                          <h5>
                            View Actitvity Tour Rates{" "}
                            <HelpInfoHelper
                              screenName={"/admin/activitytourrate"}
                            />
                          </h5>
                          <p>{activitiesRateList.length} rows found !</p>
                        </div>
                      </div>
                      <div className="action-images">
                        {showSearchBox && (
                          <Input
                            placeholder="Search"
                            onChange={(e) => searchTableData(e)}
                            suffix={closeSearchInput}
                            style={{ padding: "0px 12px" }}
                          />
                        )}
                        &nbsp;&nbsp;
                        <img
                          src={search}
                          alt="search"
                          onClick={(e) => searchData()}
                        />
                        <img
                          src={excel}
                          alt="excel"
                          onClick={(e) =>
                            props.exportExcel(excelList, "Activities Tour Rate")
                          }
                        />
                        {/* <img src={pdf} alt="pdf" /> */}
                        {/* <img src={word} alt="word" /> */}
                        {/*  <img src={exchange} alt="exchange" /> */}
                        <p className="add-icon" onClick={add}>
                          <PlusOutlined />
                        </p>
                      </div>
                      {/* </div> */}
                    </div>

                    <div>
                      {loding ? (
                        <Spin
                          size="large"
                          tip="Loading..."
                          style={{ width: "100%" }}
                        />
                      ) : activitiesRateList.length > 0 ? (
                        <Table
                          className="table-scroll-none"
                          bordered
                          dataSource={activitiesRateList}
                          columns={columnActivitiesRates}
                          rowKey={uniqueId}
                          pagination={{
                            defaultPageSize: 25,
                            showSizeChanger: true,
                            pageSizeOptions: ["25", "50", "100", "125"],
                          }}
                        />
                      ) : (
                        <b>No Results Found!</b>
                      )}
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Actitvity Tour Rates</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header tour-rates-panel-admin"
          visible={modalVisible}
          onOk={(e) => setModalVisible(false)}
          onCancel={(e) => setModalVisible(false)}
          footer={[
            <div>
              <Button key="add" type="primary" onClick={() => form.submit()}>
                {isEditMode ? "Update" : "Add"}
              </Button>
              <Button
                type="primary"
                danger
                className="cancel-btn"
                onClick={reset}
              >
                Clear
              </Button>
            </div>,
          ]}
          width={"1000px"}
        >
          <Form
            layout="vertical"
            name="basic"
            form={form}
            onFinish={onFinish}
            initialValues={{
              disableChild: 0,
              disableInfant: 0,
              allowTodaysBooking: 0,
              isSlot: 0,
              isDefaultTransfer: 0,
            }}
            validateMessages={validateMessages}
          >
            <Row gutter={16}>
              <Col md={4} xs={24}>
                <Form.Item
                  label="Tour Name"
                  name="tourName"
                  rules={[{ required: true }]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Select Activities"
                    onSelect={(e) => selectTours(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activitiesList.map((item, index) => (
                      <Option key={index} value={item.tourId}>
                        {`${item.tourName}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item
                  label="Tour Option"
                  name="tourOptionName"
                  rules={[{ required: true }]}
                >
                  <Select
                    prefix={<IdcardOutlined className="site-form-item-icon" />}
                    showSearch
                    placeholder="Select Activities"
                    onSelect={(e) => selectTours(e)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {activitiesOptionList.map((item, index) => (
                      <Option key={index} value={item.tourOptionId}>
                        {`${item.optionName}`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
        
              <Col className="gutter-row" md={8} xs={24}>
                <Form.Item
                  label="Transfer Category"
                  name="tranferCategoryid"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  
                  <Select placeholder="Please Select">
                    {categoryList.map((item) => (
                      <Option key={item.categoryId} value={item.categoryId}>
                        {item.categoryName}
                      </Option>

                    ))}
                  </Select>
                </Form.Item>
              </Col>
        
              <Col md={4} xs={24}>
                <Form.Item
                  label="Transfer"
                  name="transferName"
                  rules={[{ required: true }]}
                >
                  <Select placeholder="Transfer Type">
                    <Option value={41865}>Without Transfers</Option>
                    <Option value={41843}>Sharing Transfers</Option>
                    <Option value={41844}>Private Transfers</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Adult Price"
                  name="adultPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Child Price"
                  name="childPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Infant Price"
                  name="infantPrice"
                  rules={[{ required: true }]}
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Without Discount"
                  name="withoutDiscountAmount"
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item
                  label="Opening hours"
                  name="startTime"
                  rules={[{ required: true }]}
                >
                  <TimePicker
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  />
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item
                  label="Closing hours"
                  name="departureTime"
                  rules={[{ required: true }]}
                >
                  <TimePicker
                    defaultOpenValue={moment("00:00:00", "HH:mm:ss")}
                  />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Adult Buying Price" name="adultBuyingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Adult Selling Price" name="adultSellingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Child Buying Price" name="childBuyingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Child Selling Price" name="childSellingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Infant Buying Price" name="infantBuyingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Infant Selling Price"
                  name="infantSellingPrice"
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Comp Buying Price" name="companyBuyingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Comp Selling Price"
                  name="companySellingPrice"
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Partner Buying Price" name="agentBuyingPrice">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item
                  label="Partner Selling Price"
                  name="agentSellingPrice"
                >
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Tour Child Age" name="tourChildAge">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Max Child Age" name="maxChildAge">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label=" Max Infant Age" name="maxInfantAge">
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="tour-rates-activity">
                <Form.Item label="Maximum Pax" name="minimumPax" rules={[{ required: true }]}>
                  <InputNumber style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item label="Disable Child" name="disableChild">
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item label="Disable Infant" name="disableInfant">
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item label="Todays Booking" name="allowTodaysBooking">
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={4} xs={24}>
                <Form.Item label="Is Slot" name="isSlot">
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col md={4} xs={24}>
                <Form.Item label="Is DefaultTransfer" name="isDefaultTransfer">
                  <Radio.Group>
                    <Radio value={1}>Yes</Radio>
                    <Radio value={0}>No</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

export default UpdatedComponent(Activitytourrate);
