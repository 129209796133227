import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Upload, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";

import { useAuthContext } from "../../common/providers/AuthProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import APIClient from "../../helpers/ApiClient";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;
    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      // setIsUploading(true);
      // return;

      setFileList(info.fileList);
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
      setFileList(info.fileList);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setFileList(info.fileList);
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const AgentLogo = () => {
  const { user } = useAuthContext();
  const { getBannersList } = useSytContext();
  const [agentLogoForm] = Form.useForm();

  const [agentLogo, setAgentLogo] = useState([]);

  const initialState = {
    ServiceType: 0,
    ApplicationType: 0,
    UserId: user?.UserID ?? 1,
    BannerType: 0,
    ModifiedBy: user?.UserID ?? 1,
    CreatedBy: user?.UserID ?? 1,
    imagesPath: [],
  };

  const submitAgentData = (val) => {
    if (val.agentLogo) {
      if (val.agentLogo.fileList.length > 0) {
        if (val.agentLogo.fileList[0].response.status === 200) {
          let data = {
            ...initialState,
            imagesPath: [val.agentLogo.fileList[0].response.data.filepaths[0]],
            BannerType: 6,
          };

          callPostApi(data);
        }
      }
    }
  };

  const callPostApi = (data) => {
    APIClient.post("admin/agentBanners", data)
      .then((response) => {
        if (response.status == 200) {
          getAgentBannersList();
          getBannersList();
          message.success("Banner Uploaded SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAgentBannersList();
  }, []);

  const getAgentBannersList = () => {
    APIClient.get("admin/agentBanners")
      .then((res) => {
        if (res.status === 200) {
          let logoList = res.data.images.filter(
            (item) => item.UserId === user?.UserID
          );
          if (logoList.length > 0) {
            let data = {
              uid: logoList[0].image_id,
              name: "slide.jpg",
              status: "done",
              url: BASE + logoList[0].path.substring(1),
              response: {
                status: 200,
                data: {
                  filepaths: [logoList[0].path],
                },
              },
            };

            setAgentLogo([data]);
          }
        }
      })
      .catch(() => {});
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("admin/agentBanners/" + id)
      .then((response) => {
        if (response.status == 200) {
          getAgentBannersList();
          getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch(() => {});
  };
  return (
    <div className="manage-markup-section ourteam-bg">
      <div className="container-fluid">
        <Row>
          <Col span={24}>
            <Card bordered={false} className="retrieve-booking">
              <h5 style={{ marginBottom: "25px" }}>Agent Logo</h5>

              <div className="company-logos">
                <div className="logo-section">
                  <Row>
                    <Col md={24} xs={24}>
                      <Form
                        form={agentLogoForm}
                        layout="vertical"
                        onFinish={submitAgentData}
                      >
                        <div className="upload-logo">
                          <Logos
                            category="agentlogo"
                            fileList={agentLogo}
                            setFileList={setAgentLogo}
                            formName="agentLogo"
                            limit={true}
                            limitSize={1}
                            deleteBanner={DeleteBannerImage}
                          />
                        </div>
                        <div
                          className="upload-btns"
                          style={{ textAlign: "right" }}
                        >
                          <Button type="primary" htmlType="submit">
                            Submit
                          </Button>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AgentLogo;
