import { SearchOutlined } from "@ant-design/icons";
import * as ANTD from "antd";
import { Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Holidaysfiltersbox from "../../../pages/Holidays/Holidaysfilters/Holidaysfiltersbox";
import "../HolidaysResults/Holidaysresults.scss";

const Holidaysresults = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { TabPane } = ANTD.Tabs;
  const { Option } = ANTD.Select;

  const menuvalue = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Food Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const menushows = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Poplar Attractions</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Museums & galleries</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );
  const menutours = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Guided Food Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Walking Tour</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Local Sightseeing </ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Art & Culture</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Air & Cruise Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const menutra = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Private Airport Transfers</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>City Transfers</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Lounges & Airport Services </ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Car Charter</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  return (
    <div>
      <div className="promo providerapi holidays-trips">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container">
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="">
                    <div className="all-pages-holidays">
                      <p>
                        Change destination&nbsp;
                        <i className="fa fa-caret-down" aria-hidden="true"></i>
                      </p>
                      <h3 className="holidays-headings">Hyderabad</h3>
                      <ANTD.Button className="holidays-btn">
                        India&nbsp;
                        <i
                          className="fa fa-long-arrow-right"
                          aria-hidden="true"
                        ></i>
                      </ANTD.Button>
                    </div>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="travel-img">
        <Content className="admin-container cms-pages-width activity-cms">
          <div>
            <h3>Hyderabad Activities & Experiences 2020</h3>
          </div>
          <ANTD.Row className="line-bar">
            <ANTD.Col md={6} xs={24} className="categories">
              <Holidaysfiltersbox />
            </ANTD.Col>{" "}
            {/*--col---md--6--close--*/}
            <ANTD.Col md={18} xs={24} className="categories left-bar-line">
              <ANTD.Row className="price-range">
                <ANTD.Col md={18} xs={24}>
                  <h5>Activities & Experiences in Hyderabad</h5>
                </ANTD.Col>
                <ANTD.Col md={6} xs={24}>
                  <ANTD.Form.Item>
                    <Input
                      className="nameofcmd"
                      placeholder="Search by activity name"
                      prefix={<SearchOutlined />}
                    />
                  </ANTD.Form.Item>
                </ANTD.Col>
              </ANTD.Row>
              <ANTD.Form>
                <ANTD.Row className="price-range">
                  <ANTD.Col md={6} xs={24} className="sort-ranges">
                    <ANTD.DatePicker
                      style={{ width: "100%", height: "30px" }}
                      placeholder="Availability"
                    />
                  </ANTD.Col>

                  <ANTD.Col md={6} xs={24} className="sort-ranges">
                    <ANTD.Form.Item>
                      <ANTD.Select placeholder="Price Range">
                        <Option value="0">0-2000 </Option>
                        <Option value="1">20000-4000</Option>
                        <Option value="2">4000-6000</Option>
                        <Option value="2">6000-8000</Option>
                        <Option value="2">8000-10000</Option>
                        <Option value="2">10000-12000</Option>
                        <Option value="2">12000-14000</Option>
                      </ANTD.Select>
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col xs={24} md={6} className="sort-ranges">
                    <ANTD.Form.Item
                      style={{
                        marginBottom: "0px",
                        border: "1px solid #b6b6b6",
                        padding: "0px 1px",
                        height: "30px",
                      }}
                    >
                      <ANTD.Checkbox>Instant Conformation</ANTD.Checkbox>
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col xs={24} md={6} className="sort-ranges d-sort">
                    <p>Sort:</p>&nbsp;
                    <ANTD.Form.Item className="sort-by-option">
                      <ANTD.Select placeholder="More recommended">
                        <Option value="0">More recommended</Option>
                        <Option value="1">Most popular</Option>
                        <Option value="2">Best reviewed</Option>
                        <Option value="2">Price (low to high)</Option>
                        <Option value="2">Recently added</Option>
                      </ANTD.Select>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Form>

              <ANTD.Row>
                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-2.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i
                                className="fa fa-heart heartbre"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-3.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i
                                className="fa fa-heart heartbre"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>
                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-4.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i
                                className="fa fa-heart heartbre"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row className="to-cities">
                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-2.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i
                                className="fa fa-heart heartbre"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-3.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i
                                className="fa fa-heart heartbre"
                                aria-hidden="true"
                              ></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>
                <ANTD.Col md={8} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-3.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                          <Link to="/holidaysnearplaces">
                            <ANTD.Button className="activites-calls">
                              <i className="fa fa-heart" aria-hidden="true"></i>
                              &nbsp; Add Cart
                            </ANTD.Button>
                          </Link>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>
            </ANTD.Col>{" "}
            {/* col---16--close--- */}
          </ANTD.Row>
        </Content>
      </ANTD.Layout>

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search">
          <div className="">
            <Content className="admin-container cms-pages-width activity-cms">
              <h3>More Recommended</h3>
              <div className="">
                <h5 className="">TOP Activities In INDIA</h5>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>imagica theme park</span>
                    </a>
                    <a href="#">
                      <span>dudhsagar waterfall</span>
                    </a>
                    <a href="#">
                      <span>scuba diving in goa</span>
                    </a>
                    <a href="#">
                      <span>Imagica Theme Park</span>
                    </a>
                    <a href="#">
                      <span>Madame Tussauds Delhi</span>
                    </a>
                    <a href="#">
                      <span>Scuba Diving In Goa</span>
                    </a>
                    <a href="#">
                      <span>Bungee Jumping Rishikesh</span>
                    </a>
                    <a href="#">
                      <span>Water Kingdom Mumbai</span>
                    </a>
                    <a href="#">
                      <span>Helicopter Ride In Mumbai</span>
                    </a>
                    <a href="#">
                      <span>ramoji film city tour</span>
                    </a>
                    <a href="#">
                      <span>Hot Air Balloon Jaipur</span>
                    </a>
                    <a href="#">
                      <span>Giant Swing Rishikesh</span>
                    </a>
                    <a href="#">
                      <span>igi airport lounge</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
              <div className="">
                <h5 className="">Top 10 Activities outside INDIA</h5>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Universal Studios Singapore</span>
                    </a>
                    <a href="#">
                      <span>Disneyland Paris</span>
                    </a>
                    <a href="#">
                      <span>Gardens By The Bay Tickets</span>
                    </a>
                    <a href="#">
                      <span>Ferrari World</span>
                    </a>
                    <a href="#">
                      <span>Night Safari Singapore</span>
                    </a>
                    <a href="#">
                      <span>Batu Caves</span>
                    </a>
                    <a href="#">
                      <span>Safari World Bangkok</span>
                    </a>
                    <a href="#">
                      <span>James Bond Island</span>
                    </a>
                    <a href="#">
                      <span>Singapore Flyer</span>
                    </a>
                    <a href="#">
                      <span>Burj Khalifa Tickets</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
              <div className="">
                <h5 className="">Top Cities in INDIA</h5>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Universal Studios Singapore</span>
                    </a>
                    <a href="#">
                      <span>Scuba Diving In Goa</span>
                    </a>
                    <a href="#">
                      <span>Disneyland Paris</span>
                    </a>
                    <a href="#">
                      <span>Madame Tussauds Delhi</span>
                    </a>
                    <a href="#">
                      <span>Ferrari World</span>
                    </a>
                    <a href="#">
                      <span>Gardens By The Bay Tickets</span>
                    </a>
                    <a href="#">
                      <span>Night Safari Singapore</span>
                    </a>
                    <a href="#">
                      <span>Bungee Jumping Rishikesh</span>
                    </a>
                    <a href="#">
                      <span>Batu Caves</span>
                    </a>
                    <a href="#">
                      <span>Safari World Bangkok</span>
                    </a>
                    <a href="#">
                      <span>Singapore Flyer</span>
                    </a>
                    <a href="#">
                      <span>James Bond Island</span>
                    </a>
                    <a href="#">
                      <span>Sriracha Tiger Zoo</span>
                    </a>
                    <a href="#">
                      <span>Sunway Lagoon</span>
                    </a>
                    <a href="#">
                      <span>Jurong Bird Park</span>
                    </a>
                    <a href="#">
                      <span>Burj Khalifa Tickets</span>
                    </a>
                    <a href="#">
                      <span>Dubai Tour</span>
                    </a>
                    <a href="#">
                      <span>Uluwatu Temple</span>
                    </a>
                    <a href="#">
                      <span>Water Kingdom Mumbai</span>
                    </a>
                    <a href="#">
                      <span>Ocean Park Hong Kong</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
              <div className="">
                <h5 className="">Travel Categories</h5>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Europe Train Tickets</span>
                    </a>
                    <a href="#">
                      <span>China Train Ticket</span>
                    </a>
                    <a href="#">
                      <span>Airport Transfer</span>
                    </a>
                    <a href="#">
                      <span>Japan Rail Pass</span>
                    </a>
                    <a href="#">
                      <span>Taiwan Rail</span>
                    </a>
                    <a href="#">
                      <span>Vietnam Rail</span>
                    </a>
                    <a href="#">
                      <span>Hotel</span>
                    </a>
                    <a href="#">
                      <span>WiFi and SIM Card</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>
    </div>
  );
};

export default Holidaysresults;
