import React from "react";
import "../BusResultCards/BusResultCards.scss";
import BusSort from "../../BusSort/BusSort";
import BusCard from "./BusCard";

const BusResultCards = ({
  updateSelectedBusData,
  trips,
  busSearchResultObj,
  setBusResultsList,
  showNetFare,
  setShowNetFare,
}) => {
  return (
    <div className="results-card-block">
      <BusSort
        busSearchResultObj={busSearchResultObj}
        setBusResultsList={setBusResultsList}
        showNetFare={showNetFare}
        setShowNetFare={setShowNetFare}
      />
      <BusCard
        updateSelectedBusData={updateSelectedBusData}
        trips={trips}
        searchId={busSearchResultObj.data.searchId}
        otherData={{
          origin: busSearchResultObj.data.origin,
          destination: busSearchResultObj.data.destination,
        }}
        showNetFare={showNetFare}
        journeyDate={busSearchResultObj.data.journeyDate}
      />
    </div>
  );
};
export default BusResultCards;
