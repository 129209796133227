import React, { useState, useEffect } from "react";
import "../FlightTicket/FlightTicket.scss";
import { PhoneOutlined } from "@ant-design/icons";
import * as ANTD from "antd";
import TextArea from "antd/lib/input/TextArea";

import dateFormat from "dateformat";

const CommonTicket = (props) => {
  let airBookRespObj = props.airBookRespData;

  let searchResp = JSON.parse(localStorage.getItem("searchResp"));

  const calculateBaseFares = () => {
    return props.ticketFareDetails.fareFamilies[0].flightFares
      .filter((flightFareObj) => flightFareObj.fareTag === "Base")
      .reduce((total, obj) => total + obj.amount, 0);
  };

  const calculateTaxFares = () => {
    return props.ticketFareDetails.fareFamilies[0].flightFares
      .filter((flightFareObj) => flightFareObj.fareTag === "Tax")
      .reduce((total, obj) => total + obj.amount, 0);
  };

  useEffect(() => {}, []);

  const dataSource = props.prepareFlightDetails();

  const columns = [
    {
      title: "Airline Name",
      dataIndex: "airlinename",
      key: "airlinename",
    },
    {
      title: "Flight Number",
      dataIndex: "flightnumber",
      key: "flightnumber",
    },
    {
      title: "Departure Time",
      dataIndex: "departuretime",
      key: "departuretime",
    },
    {
      title: "Departures",
      dataIndex: "departures",
      key: "departures",
    },
    {
      title: "Arrivals Time",
      dataIndex: "arrivalstime",
      key: "arrivalstime",
    },
    {
      title: "Arrivals",
      dataIndex: "arrivals",
      key: "arrivals",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Fare Class Type",
      dataIndex: "classtype",
      key: "classtype",
    },
    {
      title: "Baggage",
      dataIndex: "baggage",
      key: "baggage",
      render: (text) => (
        <a>
          <span>{"Checkin: 15KG"}</span>
          <br />
          <span>{"Hand baggage:7KG"}</span>
        </a>
      ),
    },
    {
      title: "Airline PNR",
      dataIndex: "airlinepnr",
      key: "airlinepnr",
    },
  ];

  const traveldataSource = props.preparePaxObjWithoutPassport();

  const travelcolumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Passenger Name",
      dataIndex: "paxname",
      key: "paxname",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Eticket No.",
      dataIndex: "eticketnumber",
      key: "eticketnumber",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  /*********** Passport Details ***********/
  const passportdataSource = props.preparePaxObjWithPassport();

  const passportcolumns = [
    {
      title: "S No.",
      dataIndex: "sno",
      key: "sno",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "D.O.B",
      dataIndex: "birthdate",
      key: "birthdate",
    },
    {
      title: "FF No.",
      dataIndex: "flyerno",
      key: "flyerno",
    },
    {
      title: "Passport No.",
      dataIndex: "passportno",
      key: "passportno",
    },
    {
      title: "Issued Country.",
      dataIndex: "issuedcountry",
      key: "issuedcountry",
    },
    {
      title: "Passport Exp Date",
      dataIndex: "expdate",
      key: "expdate",
    },
  ];
  /*********** Payment Gateway Details ***********/
  const pgdataSource = [
    {
      paymentgateway: "PayPal",
      paymenttype: "Net Banking",
      totalamount: "6,417.50 - static",
    },
  ];

  const pgcolumns = [
    {
      title: "Paymentgateway Name",
      dataIndex: "paymentgateway",
      key: "paymentgateway",
    },
    {
      title: "Payment Type",
      dataIndex: "paymenttype",
      key: "paymenttype",
    },
    {
      title: "Amount",
      dataIndex: "totalamount",
      key: "totalamount",
    },
  ];
  return (
    <div>
      <div className="fligh-ticket-container">
        <div className="flight-ticket-header">
          <div className="logo">
            <h4>Logo</h4>
          </div>
          <div className="travel-company-details">
            <ul>
              <li className="travel-company-name">TravelWorld.com</li>
              <li>
                <span>359 V.k.Road,</span> <span>Gachibowli</span>{" "}
                <span>Hyderabad-530041</span>
              </li>
              <li className="travel-phone-number">
                <PhoneOutlined />
                9874563512
              </li>
              <li>travelworld@gmail.com</li>
            </ul>
          </div>
        </div>
        <div className="passenger-details">
          <p>
            Passenger Contact:{" "}
            <span>
              {props.passengersDetails.areaCode}&nbsp;
              {props.passengersDetails.mobile}
            </span>
          </p>
          <p>
            Passenger Email: <span>{props.passengersDetails.email}</span>
          </p>
        </div>
        <div className="booking-flight-details">
          <div className="flight-ref-details">
            <ul>
              <li>
                Ref number: <span>{airBookRespObj.pnr}</span>
              </li>
              <li>
                Booking Date:
                <span>{dateFormat(new Date(), "dd-mmm-yyyy")}</span>
              </li>
            </ul>
          </div>
          <div className="flight-fare-details">
            <ul>
              <li>
                GDS/Airline PNR:{" "}
                <span className="bold-text">{airBookRespObj.pnr}</span>
              </li>
              <li>
                Status:{" "}
                <span className="confirmed">{airBookRespObj.status}</span>
              </li>
              <li>
                Fare Type:{" "}
                <span>{airBookRespObj.fareFamilies[0].fareType}</span>
              </li>
            </ul>
          </div>
          <div className="trip-details">
            <ul>
              <li>
                Trip Type: <span>{searchResp.airTravelType}</span>
              </li>
              <li>
                Trip Name:
                <div className="flight-from-to">
                  {airBookRespObj.flightSegments.map((flightSeg) => (
                    <>
                      <p>
                        {flightSeg.origin} - {flightSeg.origin}{" "}
                        <span> Terminal {flightSeg.departureTerminal}</span>
                      </p>
                      <p>
                        {flightSeg.destination} - {flightSeg.destination}{" "}
                        <span> Terminal {flightSeg.arrivalTerminal}</span>
                      </p>
                    </>
                  ))}
                </div>
              </li>
              <li>
                Travel Date:
                <span>
                  {" "}
                  {dateFormat(
                    airBookRespObj.flightSegments[0].departureDateTime,
                    "dd-mmm-yyyy"
                  )}
                </span>
              </li>
            </ul>
          </div>
        </div>
        {/* -------- Flight Details ------- */}
        <div className="booking-flight-details-info">
          <div className="flight-deatils-text">
            <h5>Flight Details</h5>
          </div>
          <div className="flight-details-table">
            <ANTD.Table
              dataSource={dataSource}
              columns={columns}
              className="table-scroll-none"
              bordered
            />
          </div>
        </div>
        {/* -------- Travel Details ------- */}
        {props.passengersDetails.passportNumber ? (
          <div className="booking-flight-details-info">
            <div className="flight-deatils-text">
              <h5>Travel Details</h5>
            </div>
            <div className="flight-details-table">
              <ANTD.Table
                dataSource={traveldataSource}
                columns={travelcolumns}
                className="table-scroll-none"
                bordered
              />
            </div>
            <div className="cancellation-block e-hide">
              <div className="cancellation-remarks">
                <div className="cancellation-title">
                  <h5>Cancellation Remarks:</h5>
                  <div className="flexible-text-area">
                    <TextArea type="text" />
                  </div>
                </div>
                <div className="cancel-btn">
                  <ANTD.Button type="primary">Cancel Selected</ANTD.Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/* ----- Passport Details------- */}
        <div className="passport-details-block">
          <ANTD.Table
            dataSource={passportdataSource}
            columns={passportcolumns}
            className="table-scroll-none"
            bordered
          />
        </div>
        {/* -------- Fare Details ------- */}
        <div className="fare-details">
          <ul>
            <li>
              <p>Fare Details:</p>
              <p className="colored-text">INR</p>
            </li>
            <li>
              <p>Base Fare</p>
              <p className="colored-text">{calculateBaseFares()}</p>
            </li>
            <li>
              <p>
                Surge and Taxes:YQ:{" "}
                {/* <span className="colored-text">{calculateTaxFares()}</span> */}
              </p>
              {/* <p>
                Other Taxes: <span className="colored-text">0</span>{" "}
              </p> */}
              <p className="colored-text">{calculateTaxFares()}</p>
            </li>
            <li>
              <p>conveneience Fee</p>
              <p className="colored-text">0.00</p>
            </li>
            <li>
              <p className="colored-text">Net Total</p>
              <p className="colored-text">
                {calculateBaseFares() + calculateTaxFares()}
              </p>
            </li>
            {/* <li className="markup-list e-hide">
              <p className="markup">
                <span>Markup </span> <ANTD.Input />
              </p>
            </li> */}
            <li>
              <p className="colored-text">Grand Total</p>
              <p className="colored-text">
                {calculateBaseFares() + calculateTaxFares()}
              </p>
            </li>
          </ul>
        </div>
        {/* ------ Payment Gateway Details ------ */}
        {/* <div className="paymentgateway-details">
          <ANTD.Table
            dataSource={pgdataSource}
            columns={pgcolumns}
            className="table-scroll-none"
            bordered
          />
        </div> */}
        <div className="general-text">
          <h5 className="colored-text">General</h5>
        </div>
      </div>
    </div>
  );
};

export default CommonTicket;
