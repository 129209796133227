import React, { useState, useEffect } from "react";
//import * as ReactBoostrap from "react-bootstrap";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Popover,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./Managepolicies.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";

const Managepolicies = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = imgUrl;
  const handleClose = () => setUpdateModal(false);
  const handleShow = () => setUpdateModal(true);
  const handleInfoShow = () => setInfoModal(true);
  const handleInfoClose = () => setInfoModal(false);
  const { Panel } = ANTD.Collapse;
  // const toggle = () => {
  //     setUpdateModal(!updateModal)
  // }
  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);
  // onChange = e => {
  //     console.log('radio checked', e.target.value);
  //     setDiscountBtn(e.target.value);
  // };
  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  const tableActions = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={showModal}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  /* const tableDelete = () => {
        return (
            <div className="edit-icon" style={{ textAlign: 'center' }}>
                <ANTD.Popconfirm title="Are you sure to delete?" >
                    <DeleteOutlined style={{ color: 'red', fontSize: '20px', textAlign: 'center' }} />
                </ANTD.Popconfirm>
            </div>
        )
    } */

  // const rowClasses = (row, rowIndex) => {
  //     // const style = { };

  //     // if( rowIndex == 1 ) {
  //     //     style.backgroundColor = '#ccc';
  //     // }
  //     return 'custom-row-class';
  // }
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      name: "Jhon",
      description: "test",
      label1: "test",
      label2: "test",
      /*actions: tableActions(),*/
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon",
      description: "test",
      label1: "test",
      label2: "test",
      /*actions: tableActions(),*/
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon",
      description: "test",
      label1: "test",
      label2: "test",
      /*actions: tableActions(),*/
      /* delete: tableDelete(), */
    },
    {
      name: "John",
      description: "test",
      label1: "test",
      label2: "test",
      /*actions: tableActions(),*/
      /* delete: tableDelete(), */
    },
    {
      name: "John",
      description: "test",
      label1: "test",
      label2: "test",
      /*actions: tableActions(),*/
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Label1",
      dataIndex: "label1",
    },
    {
      title: "Label2",
      dataIndex: "label2",
    },
    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    // {
    //   dataIndex: "actions",
    //   title: "Actions",
    // },
    /*  {
            title: 'Delete',
            dataIndex: 'delete'

        } */
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };

  // const paginationOptions = {
  //     sizePerPageList: [
  //         {
  //             text: '5', value: 5
  //         },
  //         {
  //             text: '10', value: 10
  //         },
  //         {
  //             text: '15', value: 15
  //         },
  //         {
  //             text: 'All', value: products.length
  //         }
  //     ]
  // }

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <h5 className="policies-class">
                      Manage Policies{" "}
                      <HelpInfoHelper screenName={"/admin/activitiesoption"} />
                    </h5>

                    <div className="add-icon d-none">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <div>
                    <ANTD.Form
                      layout={formLayout}
                      initialValues={{ layout: formLayout }}
                      name="basic"
                      initialValues={{ remember: true }}
                      onFinish={onFinish}
                      onFinishFailed={onFinishFailed}
                    >
                      <ANTD.Collapse accordion defaultActiveKey={["1"]}>
                        <Panel header="Reschedule Policy" key="1">
                          <ANTD.Row gutter={16}>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Hotel Name"
                                rules={[{ required: true }]}
                              >
                                <ANTD.Select placeholder="Please select">
                                  <Option value="0">The Park</Option>
                                  <Option value="1">The Park</Option>
                                  <Option value="2">The Park</Option>
                                </ANTD.Select>
                              </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Reschedule Policies
                                "
                                rules={[
                                  {
                                    type: "Number",
                                    required: true,
                                    message: "Please input your Pincode",
                                  },
                                ]}
                              >
                                <ANTD.Input />
                              </ANTD.Form.Item>
                            </ANTD.Col>
                          </ANTD.Row>
                          <Row className="reports-btns">
                            <Col>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                ></Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item>
                                <Button type="primary">Add</Button>

                                <Button type="danger">Reset</Button>
                              </Form.Item>
                            </Col>
                          </Row>

                          <div className="card-add-heading">
                            <div className="rows-count">
                              <div>
                                <h5>
                                  View Report{" "}
                                  <HelpInfoHelper
                                    screenName={"/admin/cartypes"}
                                  />
                                </h5>
                                <p>3 rows found !</p>
                              </div>
                            </div>
                            <div className="action-images">
                              <img src={search} alt="search" />
                              <img src={excel} alt="excel" />
                              <img src={pdf} alt="pdf" />
                            </div>
                          </div>
                          <div>
                            <ANTD.Table
                              className="table-scroll-none"
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </Panel>
                        <Panel header="Cancel policy" key="2">
                          <ANTD.Row gutter={16}>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Hotel Name"
                                rules={[{ required: true }]}
                              >
                                <ANTD.Select placeholder="Please select">
                                  <Option value="0">The Park</Option>
                                  <Option value="1">The Park</Option>
                                  <Option value="2">The Park</Option>
                                </ANTD.Select>
                              </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Cancel policy
                                "
                                rules={[
                                  {
                                    type: "Number",
                                    required: true,
                                    message: "Please input your Pincode",
                                  },
                                ]}
                              >
                                <ANTD.Input />
                              </ANTD.Form.Item>
                            </ANTD.Col>
                          </ANTD.Row>
                          <Row className="reports-btns">
                            <Col>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                ></Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item>
                                <Button type="primary">Add</Button>

                                <Button type="danger">Reset</Button>
                              </Form.Item>
                            </Col>
                          </Row>

                          <div className="card-add-heading">
                            <div className="rows-count">
                              <div>
                                <h5>
                                  View Report{" "}
                                  <HelpInfoHelper
                                    screenName={"/admin/cartypes"}
                                  />
                                </h5>
                                <p>3 rows found !</p>
                              </div>
                            </div>
                            <div className="action-images">
                              <img src={search} alt="search" />
                              <img src={excel} alt="excel" />
                              <img src={pdf} alt="pdf" />
                            </div>
                          </div>
                          <div>
                            <ANTD.Table
                              className="table-scroll-none"
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </Panel>
                        <Panel header="Child & Extra Bed Policy" key="3">
                          <ANTD.Row gutter={16}>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Hotel Name"
                                rules={[{ required: true }]}
                              >
                                <ANTD.Select placeholder="Please select">
                                  <Option value="0">The Park</Option>
                                  <Option value="1">The Park</Option>
                                  <Option value="2">The Park</Option>
                                </ANTD.Select>
                              </ANTD.Form.Item>
                            </ANTD.Col>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Form.Item
                                label="Child & Extra Bed Policy
                                "
                                rules={[
                                  {
                                    type: "Number",
                                    required: true,
                                    message: "Please input your Pincode",
                                  },
                                ]}
                              >
                                <ANTD.Input />
                              </ANTD.Form.Item>
                            </ANTD.Col>
                          </ANTD.Row>
                          <Row className="reports-btns">
                            <Col>
                              <Form.Item>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                ></Button>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item>
                                <Button type="primary">Add</Button>

                                <Button type="danger">Reset</Button>
                              </Form.Item>
                            </Col>
                          </Row>

                          <div className="card-add-heading">
                            <div className="rows-count">
                              <div>
                                <h5>
                                  View Report{" "}
                                  <HelpInfoHelper
                                    screenName={"/admin/cartypes"}
                                  />
                                </h5>
                                <p>3 rows found !</p>
                              </div>
                            </div>
                            <div className="action-images">
                              <img src={search} alt="search" />
                              <img src={excel} alt="excel" />
                              <img src={pdf} alt="pdf" />
                            </div>
                          </div>
                          <div>
                            <ANTD.Table
                              className="table-scroll-none"
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </Panel>
                      </ANTD.Collapse>
                    </ANTD.Form>
                  </div>
                </Card>
              </div>
              {/* 
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Manage Policies{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>3 rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />

                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div> */}
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title=" Manage Policies"
        className="promo-modal-header hotel-extranet-hotel"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <ANTD.Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Hotel Name" rules={[{ required: true }]}>
                <ANTD.Select placeholder="Please select">
                  <Option value="0">The Park</Option>
                  <Option value="1">The Park</Option>
                  <Option value="2">The Park</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
          <ANTD.Row gutter={16} className="mb-bottom">
            <ANTD.Col md={24} xs={24}>
              <ANTD.Collapse accordion>
                <Panel header="Reschedule Policy" key="1">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Cancel Policy" key="2">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Child & Extra Bed Policy" key="3">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <ANTD.Button type="primary" htmlType="submit">
              Add
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal>
      <ANTD.Modal
        title="Basic Modal"
        visible={userInfo}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </ANTD.Modal>
    </div>
  );
};

export default Managepolicies;
