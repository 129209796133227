import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Layout,
  Form,
  Modal,
  Input,
  message,Select,TimePicker
} from "antd";

import { Link, useHistory } from "react-router-dom";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useActivitiesContext } from "../../../common/providers/Activities/ActivitiesProvider";
import ApiClient from "../../../helpers/ApiClient";
import { getDateRange } from "../../../helpers/activitiesHelper";
import BuildPackageDoc from "../../../common/PdfDocuments/BuildPackage/BuildPackageCheckoutDoc";
import Login from "../../../components/Login/Login";
import CustomImg from "../../../common/CustomImg/CustomImg";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import {
  DownloadOutlined,
  MailOutlined,
  EditOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import queryString from "query-string";
import hotelNoImg from "../../../assets/images/hotels/no_img.png";
import moment from "moment";
import "./Buildquotationtotal.scss";

dayjs.extend(customParseFormat);
const { Content } = Layout;
const { Option } = Select;
const Buildquotationtotal = () => {
  let history = useHistory();
  const [emailForm] = Form.useForm();
  const [addMarkForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(true);
  const [buildPackage, setBuildpackage] = useState({
    HotelDetails: [],
    PackageDetails: {},
    TourDetails: [],
  });

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [amIFromAdmin, setAmIFromAdmin] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const { setQuotation } = useActivitiesContext();
  const [amMarkupAmount, setMarkup] = useState(0);
  const [starttime, setStartTime] = useState();
  const getActivitiesSearchFromQuery = () => {
    let param = queryString.parse(document.location.search);
    return param.packageRefNo;
  };
  const createQuotation = async () => {
    let param = getActivitiesSearchFromQuery();

    await ApiClient.get(`buildPackage/getPackage/${param}`)
      .then((results) => results)
      .then((resp) => {
        if (resp.status == 200) {
          if (resp.data?.PackageDetails) {
            const req = JSON.parse(
              resp.data?.PackageDetails?.buildPackageRequest
            );

            const tourAvlDate = getDateRange(
              req?.checkInDate,
              req?.checkOutDate
            );
            // console.log(tourAvlDate);

            let data = {
              ...resp.data,
              TourDetails: [],
            };
            if (resp.data.TourDetails.length > 0) {
              resp.data?.TourDetails?.map((tour) => {
                tour?.optionData?.map((optionData) => {
                  let i = tourAvlDate.indexOf(
                    moment(optionData.tourDate, "YYYY-MM-DD").format(
                      "DD-MM-YYYY"
                    )
                  );
                  
                  if (
                    data.TourDetails.hasOwnProperty(i) &&
                    data.TourDetails[i]?.length > 0
                  ) {
                    data.TourDetails[i].push(optionData);
                  } else {
                    data.TourDetails[i] = [optionData];
                  }
                });
              });
            }

            setBuildpackage(data);
            setMarkup(data.PackageDetails?.AgentMarkup)
            
          }

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    createQuotation();
    getQueryParam();
  }, []);

  const getQueryParam = () => {
    let adminParam = queryString.parse(document.location.search);
    if (adminParam.amIFromAdmin == "true") {
      setAmIFromAdmin(true);
    }
  };

  // Start Here Add Code For GetTime From Client by Karthik --13/May/2023 
  // function onChange(time, ID) {
  //   const Time = time?.format('HH:mm:ss');
  //   console.log(buildPackage.TourDetails);
  //   buildPackage.TourDetails.map((x) => {
  //     for (let i=0; i < x.length;) {
  //       if(x[i].ID == ID)
  //       if((x[i].optionName.includes('Pick') || x[i].optionName.includes('Drop')|| x[i].optionName.includes('Transfers')) && x[i].transferName === "Private Transfers" )
  //         x[i].startTime = Time 
  //         i++;
  //     }
  //   })
  // }
  // End Here Add Code For GetTime From Client by Karthik --13/May/2023 
  
  const BlockPackage = () => {
    setQuotation(buildPackage);
    return "/Quotationcheckout";
  };

  const addNewPackage = () => {
    let searchParam = JSON.parse(
      buildPackage.PackageDetails?.buildPackageRequest
    );

    let formData = {
      checkInDate: moment(searchParam.checkInDate).format("YYYY-MM-DD"),
      checkOutDate: moment(searchParam.checkOutDate).format("YYYY-MM-DD"),
      CityCode: searchParam.CityId,
      roomGuests: JSON.stringify(searchParam.roomGuests),
      nationality: searchParam.nationality,
      currency: "INR",
      traceId: "string",
      isActivitiy: searchParam.isActivityChecked === "true" ? true : false,
      isHotel: searchParam.isHotelChecked === "true" ? true : false,
    };

    const query = queryString.stringify(formData);
    history.push("/combinedbuildpkg?" + query);
  };

  const modifyPackage = () => {
    let param = getActivitiesSearchFromQuery();
    history.push("/combinedbuildpkg?packageRefNo=" + param);
  };
  ///add mrkup
  const submitAddMakrupForm = (val) => {
    AddMakrupForm(val);
 
  };


  const AddMakrupForm  = async (val) => {
    let param = getActivitiesSearchFromQuery();
   let data= {UserId:buildPackage.PackageDetails?.UserID, RefNo:buildPackage.PackageDetails?.PackageRefNo,Amount:val.markupAmount}
   
   ApiClient.put("buildPackage/updatePackageMarkup/"+ buildPackage.PackageDetails?.PackageRefNo,{}, data)
   .then((response) => {
     if (response.status == 200) {
        
         // setMarkup(val.markupAmount)
          createQuotation();
          message.success("Added successfully", 3);
     } else {
       message.error(response.message, 3);
     }
   })
   .catch((error) => {
     console.error(error);
   });
  };
  const getPaxCount = (pax) => {
    return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${
      pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
    } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
  };

  const getHotelPaxCount = (roomsArray) => {
    if (roomsArray.length > 0) {
      let pax = roomsArray[0];
      return `Adult(s) ${pax?.AdultCount !== "0" ? pax?.AdultCount : 0} ${
        pax?.ChildCount !== "0" ? ", Child(s) " + pax?.ChildCount : " "
      } `;
    }
  };

  const MainMenu = (
    <Menu>
      <Menu.Item key="32">
        <PDFDownloadLink
          document={<BuildPackageDoc buildPackage={buildPackage} showPrice={false} showTotalPrice={true} currencyValue={currencyValue} activeCurrency={activeCurrency} />}
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>With Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
      <Menu.Item key="33">
        <PDFDownloadLink
          document={
            <BuildPackageDoc buildPackage={buildPackage} showPrice={false} />
          }
          fileName="QuotationList.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? <>Loading document...</> : <>Without Rate Breakup</>
          }
        </PDFDownloadLink>
      </Menu.Item>
    </Menu>
  );

  const toggleEmailModal = () => {
    emailForm.resetFields();
    setEmailModalVisible((prev) => !prev);
  };

  const submitEmailForm = (val) => {
    if (buildPackage?.PackageDetails?.PackageRefNo) {
      var formData = {
        phoneNumber: "string",
        travelType: 5,
        trigger: 1,
        bookingReference: buildPackage?.PackageDetails?.PackageRefNo,
        email: val.Email,
        builpackageQuotation:1,
        isPriceShow:val.Brekuptype
      };
    }
    sendEmailSmsETicket(formData);
  };

  const sendEmailSmsETicket = (val) => {
    ApiClient.post("CommonUtility/notification", val).then((res) => {
      if (res.status === 200) {
        message.success(`Email Sent Successfully`);
        setEmailModalVisible(false);
      } else if (res.status === 400) {
        if (res?.message) message.success(res?.message, 3);
      } else {
        if (res?.message) message.success(res?.message, 3);
        else message.error("Failed");
      }
    });
  };

  const getTimebyUser = (optionData) => {
    if (
      (optionData.cityTourType.includes('Airport') ||
        optionData.cityTourType.includes('Transfer') &&
        optionData.transferName === "Private Transfers")
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="manage-markup-section buildquo-wrapper">
        <Content className="admin-container bar-admin-width">
          <Row>
            <Col md={24} xs={24}>
              <h4>Create Package</h4>
            </Col>
          </Row>

          <Row className="data-icons-info-bal">
            <Col md={24} xs={24}>
              <Card className="quo-border-sec">
                <Row>
                  <Col md={12} xs={24}>
                    <h6>YOUR PACKAGE HAS BEEN SAVED SUCCESSFULLY</h6>
                  </Col>
                  <Col md={12} xs={24} className="viewallquo">
                    <Link to="/mypackage">
                      <Button className="saved-btn">
                        View all saved Packages
                      </Button>
                    </Link>
                    &nbsp;
                    <Button
                      className="saved-btn"
                      onClick={() => addNewPackage()}
                    >
                      Add New Packages
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>

          {isLoading ? (
            <div>
              <p>loading....</p>
            </div>
          ) : (
            <>
              <Row className="data-icons-info-bal">
                <Col md={24} xs={24}>
                  <Card className="quo-border-sec">
                    <Row>
                      <Col md={12} xs={24}>
                        <h6 className="one-quo-acti">
                          {buildPackage.PackageDetails?.PackageRefNo}
                        </h6>
                        <h5 className="one-two-acti">
                          {buildPackage?.TourDetails[0] != undefined
                            ? buildPackage?.TourDetails[0]?.cityName
                            : buildPackage?.HotelDetails[0]?.HotelCityName}
                        </h5>
                      </Col>
                      <Col md={12} xs={24} className="total-quotationdata">
                      <Row >
                      <Col md={8} xs={24} className="total-quotationdata">
                      <p className="one-quo">Base Amount</p>
                        <h3>{activeCurrency} {currencyValue(buildPackage.PackageDetails?.TotalFare)}</h3>
                      </Col>
                      <Col md={8} xs={24} className="total-quotationdata">
                      <p className="one-quo">Markup Cost </p>
                        <h3>{activeCurrency} {currencyValue(amMarkupAmount)}</h3>
                      </Col>
                      <Col md={8} xs={24} className="total-quotationdata">
                      <p className="one-quo">Grand Total</p>
                        <h3>{activeCurrency} {currencyValue(parseFloat (buildPackage.PackageDetails?.TotalFare) +parseFloat(amMarkupAmount))}</h3>
                      </Col>
                      </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row className="hotel-tour-quotation ">
                <Col md={24} xs={24}>
                  <Card className="quo-border-sec">
                    <Row gutter={[16, 16]} className="top-block-quiet">
                      <Col md={4} sm={8} xs={24}>
                        <Button
                          className="dark-btn"
                          onClick={() => {
                            toggleEmailModal();
                          }}
                        >
                          <MailOutlined /> Send Email
                        </Button>
                      </Col>
                      <Col md={4} sm={8} xs={24}>
                        <Dropdown overlay={MainMenu} trigger={["click"]}>
                          <Button className="dark-btn">
                            <DownloadOutlined /> Download Package
                          </Button>
                        </Dropdown>
                      </Col>
                      <Col md={4} sm={8} xs={24}>
                        <Button
                          className="dark-btn"
                          onClick={() => modifyPackage()}
                        >
                          <EditOutlined />
                          Modify Package
                        </Button>
                      </Col>
                      <Col md={4} sm={8} xs={24}>
                        <Link to={BlockPackage()}>
                          <Button className="saved-btn">
                            <LinkOutlined />
                            Book Package
                          </Button>
                        </Link>
                      </Col>
                      <Col >
                          <Button className="saved-btn"  onClick={addMarkForm.submit}>
                            Add Markup
                          </Button>
                          </Col>
                          <Col>
                          <Form form={addMarkForm} layout="vertical" onFinish={submitAddMakrupForm}>
                          <Form.Item
                
                  name="markupAmount"
          
                >
                  <Input   placeholder="Amount"/>
                </Form.Item>
                </Form>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={24} xs={24}>
                        <ul className="timeline timeline-left">
                          {buildPackage.HotelDetails.length > 0 ? (
                            <Row className="tours-book-method">
                              <Col md={24} xs={24}>
                                <h4 className="hotel-room-info">Hotel</h4>
                              </Col>
                            </Row>
                          ) : null}
                          {buildPackage.HotelDetails.length > 0
                            ? buildPackage.HotelDetails.map((hotel, index) => {
                                return (
                                  <li
                                    key={"hot" + index}
                                    className="timeline-inverted timeline-item"
                                  >
                                    <div className="timeline-badge warning">
                                      <span className="font-12">
                                        <i
                                          className="fa fa-hospital-o"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </div>
                                    <div className="timeline-panel">
                                      <div className="timeline-body">
                                        <div className="info-hotel-main">
                                          <Row gutter={24}>
                                            <Col md={6} xs={24}>
                                              {hotel.hotelImage ? (
                                                <CustomImg
                                                  src={hotel.hotelImage}
                                                  className="img-cotation-total"
                                                />
                                              ) : (
                                                <img
                                                  className="img-cotation-total"
                                                  src={hotelNoImg}
                                                  alt="no-photo"
                                                />
                                              )}
                                            </Col>
                                            <Col md={18} xs={24}>
                                              <h6 className="review-name-hotel">
                                                {hotel.HotelName}
                                              </h6>
                                              {hotel.HotelAddress ? (
                                                <h6 className="hotel-address">
                                                  {hotel.HotelAddress}
                                                </h6>
                                              ) : null}

                                              <ul className="mt-1">
                                                <li>
                                                  <span className="font-weight-bold">
                                                    Check in Date :{" "}
                                                  </span>
                                                  <span>
                                                    {moment(
                                                      hotel.CheckInDate
                                                    ).format("DD-MM-YYYY")}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span className="font-weight-bold">
                                                    Check Out Date :{" "}
                                                  </span>
                                                  <span>
                                                    {moment(
                                                      hotel.CheckOutDate
                                                    ).format("DD-MM-YYYY")}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span className="font-weight-bold">
                                                    Room Type :{" "}
                                                  </span>

                                                  {hotel.Rooms.map((item) => {
                                                    return (
                                                      <span>
                                                        {item.RoomName}{" "}
                                                      </span>
                                                    );
                                                  })}
                                                </li>
                                                <li>
                                                  <span className="font-weight-bold">
                                                    No. of Pax :{" "}
                                                  </span>
                                                  <span>
                                                    {getHotelPaxCount(
                                                      hotel.Rooms
                                                    )}
                                                  </span>
                                                </li>

                                                <li>
                                                  <span className="font-weight-bold">
                                                    No of Nights :{" "}
                                                  </span>
                                                  <span>
                                                    {hotel.Rooms[0].noOfNight}
                                                  </span>
                                                </li>
                                                <li>
                                                  <span className="font-weight-bold">
                                                    Amount :{" "}
                                                  </span>
                                                  {hotel.Rooms.map((item) => {
                                                    return (
                                                      <span>
                                                        {item.pricePerRoom}
                                                      </span>
                                                    );
                                                  })}
                                                </li>
                                              </ul>
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            : null}

                          {buildPackage.TourDetails.length > 0 ? (
                            <Row className="tours-book-method">
                              <Col md={24} xs={24}>
                                <h4 className="hotel-room-info">Tours</h4>
                              </Col>
                            </Row>
                          ) : null}
                          {buildPackage.TourDetails.length > 0
                            ? buildPackage.TourDetails.map(
                                (tour, index) =>
                                  tour &&
                                  tour?.length > 0 &&
                                  tour.map((optionData, key) => (
                                    <li
                                      key={index + key}
                                      className="timeline-inverted timeline-item"
                                    >
                                      <div className="timeline-badge warning">
                                        <p className="mb-0">Day {index + 1}</p>
                                      </div>
                                      <div className="timeline-panel ">
                                        <div className="timeline-body">
                                          <h6>{optionData.tourName}</h6>
                                          <p className="font-weight-bold mb-3">
                                            Tour Date :{" "}
                                            {moment(
                                              optionData.tourDate,
                                              "YYYY-MM-DD"
                                            ).format("DD-MM-YYYY")}
                                          </p>
                                          <Row>
                                            <Col md={6} xs={24}>
                                              <img
                                                className="img-fluid "
                                                alt="example"
                                                src={optionData.tourImage}
                                              />
                                            </Col>
                                            <Col md={18} xs={24}>
                                              <p
                                                className="hotel-list-para"
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    optionData.tourShortDescription,
                                                }}
                                              ></p>
                                            </Col>
                                          </Row>
                                          <ul className="mt-4">
                                            <li>
                                              <span className="font-weight-bold">
                                                Tour Option :{" "}
                                                {optionData.optionName}
                                              </span>
                                            </li>
                                            <li>
                                              <span className="font-weight-bold">
                                                Transfer Option :{" "}
                                              </span>
                                              <span>
                                                {optionData.transferName}
                                              </span>
                                            </li>
                                            {optionData.duration != '' && !getTimebyUser(optionData) &&(
                                            <li>
                                              <span className="font-weight-bold">
                                                Duration :{" "}
                                              </span>
                                              <span>{optionData.duration}</span>
                                            </li>
                                            )}
                                            {/* Start Here Add Code For StartTime by Karthik --13/May/2023 */}
                                            {getTimebyUser(optionData) && (
                                              <li>
                                                <span className="font-weight-bold">Start Time :{" "}</span>
                                                <span>{optionData.startTime}</span>
                                              </li>
                                            )}
                                            {/* End Here Add Code For StartTime by Karthik --13/May/2023 */}
                                              
                                            <li>
                                              <span className="font-weight-bold">
                                                No. of Pax :{" "}
                                              </span>
                                              <span>
                                                {getPaxCount(optionData)}
                                              </span>
                                            </li>

                                            <li>
                                              <span className="font-weight-bold">
                                                Amount :{" "}
                                              </span>
                                              <span>
                                                {activeCurrency} {currencyValue(optionData.serviceTotal)}
                                              </span>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                              )
                            : null}
                        </ul>
                      </Col>
                    </Row>

                    <Row className="terms-reviewfroms">
                      <Col md={24} xs={24}>
                        <h4>Terms & Conditions</h4>
                        <ul className="listofterms">
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; Once the Quotation is created the status will
                            be shown as SAVED till travel date.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; The quotation will expired on Travel date if
                            the quotation is not converted to Booking.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; You can always view / search the quotation,
                            by quotation number or Creation date.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; Once quotation is created the travel dates
                            cannot be amended, if you need to amend the dates
                            you need to create a new quotation.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; You can modify the Quotation on same travel
                            search date, you can add, amend the hotel and
                            activities as per your choice.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; On Saved Quotation, The booking is not
                            forwarded to Supplier.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; On Block Rate & Availability till
                            Cancellation Deadline your booking can be Confirmed
                            or Vouchered depending on your choice.
                          </li>
                          <li>
                            <i className="fa fa-forward" aria-hidden="true"></i>
                            &nbsp; At the time of booking there will be auto Pre
                            Price Check. There may be chances the prices can
                            increase / decrease due to dynamic Inventory.
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Content>
        <Modal
          title={[
            <div>
              <h6 style={{ marginBottom: "0px" }}>
                <strong>Email Quotation</strong>
              </h6>
            </div>,
          ]}
          className="promo-modal-header"
          visible={emailModalVisible}
          onOk={toggleEmailModal}
          onCancel={toggleEmailModal}
          footer={[
            <div>
              <Button key="close" onClick={toggleEmailModal}>
                Cancel
              </Button>

              <Button
                key="add"
                type="primary"
                htmlType="submit"
                onClick={emailForm.submit}
              >
                Send
              </Button>
            </div>,
          ]}
        >
          <Form form={emailForm} layout="vertical" onFinish={submitEmailForm}>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    { required: true, message: "Required!" },
                    { type: "email", message: "Email is not a valid email" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

            </Row>
            <Row>
            <Col  span={24}>
            <Form.Item
                  label="Select Breakup Type"
                  name="Brekuptype"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
            <Select placeholder="Please Select">
                  
                      <Option value="1"> 
                       With Rate
                      </Option>
                      <Option value="2"> 
                       Without Rate
                      </Option>
          
                  </Select>
                  </Form.Item>
                      </Col>
                      </Row>
          </Form>
        </Modal>
      </div>

      {amIFromAdmin ? (
        <Login userInfo={true} setModalVisible={() => {}} type="ALL" />
      ) : null}
    </>
  );
};
export default Buildquotationtotal;
