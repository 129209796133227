import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Layout,
  message,
  Select,
} from "antd";

import ApiClient from "../../helpers/ApiClient";
import ReCAPTCHA from "react-google-recaptcha";
import "./Mytrips.scss";
import { useHistory } from "react-router-dom";
const { Content } = Layout;
const { Option } = Select;

const Mytrips = () => {
  let history = useHistory();
  const [form] = Form.useForm();
  const [buttonDisabled, setButtonDisabled] = useState(true);

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }
  const getType = (type) => {
    let url = "";
    switch (type) {
      case 1: {
        url = "flight/ticket";
        break;
      }
      case 3: {
        url = "bus/ticket";
        break;
      }
      case 2: {
        url = "hotel/ticket";
        break;
      }
      case 4: {
        url = "activities/ticket";
        break;
      }
    }

    return url;
  };

  const verifyTicket = (data) => {
    return new Promise((resolve, reject) => {
      ApiClient.get(
        `admin/myBookings/details?travelType=${data.type}&id=${data.ref}`
      ).then((resp) => {
        let errorMessage = "No Such Ticket Found";
        if (!resp) {
          reject("Server Error");
          return;
        }

        if (!resp.data) {
          reject("No Such Ticket Found");
          return;
        }
        if (resp.data.length > 0) {
          if (data.email) {
            let guestEmail = resp.data[0].guestEmaiId
              ? resp.data[0].guestEmaiId.toLowerCase()
              : "";
            if (guestEmail === data.email.toLowerCase()) {
              resolve();
            } else {
              reject("Email Does Not match Refference Id");
            }
          }

          resolve();
        } else {
          reject(errorMessage);
        }
      });
    });
  };

  const submitForm = (data) => {
    verifyTicket(data)
      .then(() => {
        let type = getType(data.type);
        let url = type + "?ref=" + data.ref;
        history.push(url);
      })
      .catch((e) => {
        message.error(e, 3);
      });
  };

  return (
    <div>
      <div className="promo providerapi">
        <Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container cms-pages-width retrieve-linebar">
              <Row className="fst-book">
                <Col md={24} xs={24}></Col>
              </Row>

              <Form
                layout="vertical"
                form={form}
                onFinish={submitForm}
                initialValues={{ type: 1 }}
              >
                <Row>
                  <Col md={12} xs={24}>
                    <Card className="retrieve-booking">
                      <h4>Retrieve Your Booking</h4>
                      <p className="booking-email-p">
                        Enter your booking number and email address below and
                        we’ll find your ticket for you. We sent your booking
                        number to you in your confirmation email.
                      </p>
                      <Form.Item
                        label="Service Type"
                        name="type"
                        rules={[
                          { required: true, message: "Field is Required" },
                        ]}
                      >
                        <Select>
                          <Option value={1}>Flights</Option>
                          <Option value={2}>Hotels</Option>
                          <Option value={3}>Bus</Option>
                          <Option value={4}>Activities</Option>
                        </Select>
                      </Form.Item>

                      <Row className="booking-value-id">
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Reference Number"
                            name="ref"
                            rules={[
                              {
                                required: true,
                                message: "Reference No is Required",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form.Item
                            label="Email address"
                            name="email"
                            rules={[
                              {
                                type: "email",
                                message: "Email is not a valid email",
                              },
                            ]}
                          >
                            <Input
                              className="contacts-value-de-retrive"
                              size={"large"}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row justify="center" className="mb-3">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                          onChange={handleCaptcha}
                        />
                      </Row>
                      <Row justify="center">
                        <Col md={12} xs={24}>
                          <Button
                            disabled={buttonDisabled}
                            className="google-btn-booking"
                            htmlType="submit"
                          >
                            Retrieve Booking
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>

                  <Col md={12} xs={24}>
                    <img
                      className="email-from-book"
                      src={
                        require("../../assets/images/email-tickets.png").default
                      }
                      alt="search-img"
                      srcset=""
                    />
                  </Col>
                </Row>
              </Form>
            </Content>
          </div>
        </Layout>
      </div>
    </div>
  );
};

export default Mytrips;
