import React, { useState, useEffect, useRef } from "react";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import HotelAutoSelect from "../../common/AutoCompleteSelect/HotelAutoSelect";
import { useHistory } from "react-router-dom";
import HotelPassengers from "./HotelPassengers/HotelPassengers";
import CountryList from "../../common/CountryList";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";
import "../Hotels/Hotels.scss";

const { RangePicker } = DatePicker;
const { Option } = Select;
const Hotels = (props) => {
  let history = useHistory();
  const [form] = Form.useForm();
  const dateFormat = "DD-MM-YYYY";
  const oriDateFormat = "YYYY-MM-DD";

  const returnDateBox = useRef();
  const [showDate, setShowDate] = useState(false);
  const [showReturnDate, setShowReturnDate] = useState(false);
  const [checkInDate, setCheckInDate] = useState(moment());
  const [checkOutDate, setCheckOutDate] = useState(moment());

  const city = useRef(null);
  const dateBox = useRef(null);
  const ccode = useRef(null);

  const defHotelPaxInfo = [
    {
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    },
  ];

  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];
  const validateMessages = {
    required: "",
  };

  const [rooms, setRooms] = useState(false);
  const searchBtn = useRef();
  const toggleCount = () => {
    setRooms((prev) => !prev);
  };

  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 1,
      noOfChilds: 0,
      childAge: [],
    });

    setHotelPaxInfo(paxInfo);
  };

  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };

  const submitForm = (values) => {
    let formData = {
      checkInDate: moment(values.checkInDate).format(oriDateFormat),
      checkOutDate: moment(values.checkOutDate).format(oriDateFormat),
      hotelCityCode: values.hotelCityCode,
      roomGuests: JSON.stringify(hotelPaxInfo),
      nationality: values.nationality,
      countryCode: values.nationality,
      currency: "INR",
      traceId: "string",
    };

    const query = queryString.stringify(formData);
    history.push("/hotels/listing?" + query);

    if (props.modifySearch) {
      props.hotelSearchAPI();
    }
  };

  useEffect(() => {
    if (props.modifySearch) {
      const hotelSearchParams = queryString.parse(window.location.search);
      let roomDetails = JSON.parse(hotelSearchParams.roomGuests);

      form.setFieldsValue({
        hotelCityCode: hotelSearchParams.hotelCityCode,
        checkInDate: moment(hotelSearchParams.checkInDate, "YYYY-MM-DD"),
        checkOutDate: moment(hotelSearchParams.checkOutDate, "YYYY-MM-DD"),
        nationality: hotelSearchParams.nationality,
      });
      setHotelPaxInfo(roomDetails);
    }
  }, []);

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const disabledDestDate = (currentDate) => {
    return currentDate < moment(checkOutDate).startOf("day");
  };

  const onChangeOriginDate = (momentdate, _) => {
    let originDate = "";

    if (momentdate) {
      originDate = momentdate ? moment(momentdate).startOf("day") : "";
      setCheckInDate(momentdate);
    }
    momentdate=  moment(momentdate).add(1, "days");
    setCheckOutDate(momentdate);
    const toDate = form.getFieldValue("checkOutDate");
    if (toDate) {
      let a = moment(toDate).startOf("day");
      let diffDays = a.diff(originDate, "days");
      if (diffDays < 0) {
        let newTodate = moment(momentdate).add(2, "days");
        form.setFieldsValue({
          checkOutDate: newTodate,
        });
      }
    }
  };

  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === dateBox) {
      setShowDate(true);
    }
  };

  return (
    <div className="hotels_search_box">
      <div className="hotels-wrapper">
        <Form
          form={form}
          initialValues={{
            nationality: "IN",
          }}
          className="hotel-search-form hotel-bg-panel-all"
          validateMessages={validateMessages}
          onFinish={submitForm}
        >
          <Row className="search-row">
            <Col md={6} xs={24} className="from-to-inputs hotel-select-jun">
              <HotelAutoSelect
                formItemProps={{
                  name: "hotelCityCode",
                  rules: [
                    { required: true, message: "Please Specify The City" },
                  ],
                }}
                selectProps={{ size: "large", placeholder: "Enter City Name" }}
                api={"hotels-v2/searchhotelcity/"}
                refName={city}
                focusRef={dateBox}
                handleOnSubmit={handleOnSubmit}
                modifySearch={props.modifySearch ? props.modifySearch : false}
              />
            </Col>
            {/* 
            <Col
              md={8}
              xs={24}
              className="from-to-inputs hotel-select-jun right-line-bdr"
            >
              <Form.Item
                className="checin-cheout-mob"
                name="checkin_checkout"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Dates",
                  },
                ]}
              >
                <RangePicker
                  style={{ width: "100%" }}
                  placeholder={["Check-In", "Check-Out"]}
                  size="large"
                  format={dateFormat}
                  disabledDate={disabledDate}
                  // onChange={(_, val) => {
                  //   if (val[1]) {
                  //     ccode.current.focus();
                  //   }
                  // }}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => setShowDate((prev) => !prev)}
                />
              </Form.Item>
            </Col> */}

            <Col md={4} xs={24}>
              <Form.Item
                name="checkInDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  allowClear={false}
                  style={{ width: "100%" }}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledOriginDate}
                  onChange={(date, dateString) => {
                    setShowDate((prev) => !prev);
                    if (date) {
                      onChangeOriginDate(date, dateString);
                      setShowReturnDate(true);
                      returnDateBox.current.focus();
                    }
                  }}
                  ref={dateBox}
                  open={showDate}
                  onOpenChange={() => {
                    setShowDate((prev) => !prev);
                  }}
                  placeholder="Check In Date"
                />
              </Form.Item>
            </Col>

            <Col md={4} xs={24}>
              <Form.Item
                className="returnDate"
                name="checkOutDate"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  allowClear={false}
                  className="train-search-btn"
                  size="large"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledDestDate}
                  ref={returnDateBox}
                  open={showReturnDate}
                  defaultPickerValue={checkInDate}
                  onOpenChange={() => {
                    setShowReturnDate((prev) => !prev);
                  }}
                  placeholder="Check Out Date"
                />
              </Form.Item>
            </Col>

            <Col md={3} xs={24} className="from-to-inputs hotel-select-jun">
              <Form.Item
                name="nationality"
                rules={[
                  {
                    required: true,
                    message: "Please Select Nationality",
                  },
                ]}
              >
                <Select
                  ref={ccode}
                  showSearch
                  size="large"
                  placeholder="Nationality"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {CountryList.map((item) => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={4} xs={24} className="from-to-inputs hotel-select-jun">
              <Form.Item>
                <Input
                  value={
                    "Rooms: " + paxInfo.length + ", guests: " + getPaxCount()
                  }
                  size="large"
                  onClick={toggleCount}
                />
                <div className="add-room-block">
                  <div
                    className="pax-modal"
                    id="pax-modal"
                    style={{
                      display: rooms ? "block" : "none",
                    }}
                  >
                    <div className="pax-modal-wrapper">
                      <div className="pax-modal-arrow"></div>
                      <ul className="first-item">
                        {paxInfo.map((pax, index) => (
                          <HotelPassengers
                            pax={pax}
                            index={index}
                            updatePaxInfoFromChild={updatePaxInfoFromChild}
                            paxInfo={paxInfo}
                          />
                        ))}

                        <li className="add-room-btn" onClick={addRoom}>
                          Add room
                        </li>
                      </ul>

                      <div>
                        <Button
                          block
                          className="pax-ready-btn"
                          onClick={() => {
                            toggleCount();
                            if (rooms) searchBtn.current.focus();
                          }}
                        >
                          Ready
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form.Item>
            </Col>
            <Col md={3} xs={24}>
              <Button
                size="large"
                className="primary-btn"
                ref={searchBtn}
                htmlType="submit"
              >
                <SearchOutlined /> Search
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Hotels;
