import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../Transportation/Transportation.scss";
import * as ANTD from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "rc-datepicker/lib/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";

const Transportation = () => {

    const [form] = ANTD.Form.useForm();
    const [formLayout, setFormLayout] = useState("inline");

    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const validateMessages = {
        required: ''
    };
    let history = useHistory();
    const goTo = (path) => {
        history.push("/transportresults")
    }


    return (
        <div className="hotels_search_box">
            <div className="hotels-wrapper">
                <ANTD.Form
                    form={form}
                    onValuesChange={onFormLayoutChange}
                    className="hotel-search-form transport-form"
                    validateMessages={validateMessages}
                >
                    <ANTD.Row gutter={16} className="search-row">
                        <ANTD.Col md={9} xs={24} className="from-to-inputs">
                            <ANTD.Form.Item
                                label="Travel Route"
                                className="hotel-autocomplete"
                                name="city"
                                rules={[{ required: true }]}
                            >
                                <ANTD.Input
                                    size="large" />
                            </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col md={4} xs={24} className="from-to-inputs">
                            <ANTD.Form.Item label="Travel Start Date">
                                <ANTD.DatePicker
                                    className="train-search-btn"
                                    size="large"
                                />
                            </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col md={6} xs={24} className="from-to-inputs">
                            <ANTD.Form.Item label="Number of Pax and Vehicles">
                                <ANTD.Input
                                    size="large"
                                    placeholder="2 Pax / 1 vehicle"
                                />
                            </ANTD.Form.Item>
                        </ANTD.Col>
                        <ANTD.Col md={5} xs={24}>
                            <ANTD.Form.Item className="mr-none">
                                <ANTD.Button
                                    size="large"
                                    className="train-search-btn primary-btn"
                                    onClick={goTo}
                                >
                                    <SearchOutlined />
                                </ANTD.Button>
                            </ANTD.Form.Item>
                        </ANTD.Col>
                    </ANTD.Row>

                </ANTD.Form>
            </div>
        </div>
    );
};

export default Transportation;
