import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./ImagesLightbox.scss";

const ImagesLightbox = ({ hotelImages }) => {
  return (
    <>
      <div className="carousel-wrapper">
        {hotelImages.length === 1 ? (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
          >
            {hotelImages.map((hotelImage, i) => (
              <div key={i + "hotelimg"}>
                <img src={hotelImage} alt="image" className="carousel-images" />
              </div>
            ))}
          </Carousel>
        ) : (
          <Carousel
            showStatus={false}
            showIndicators={false}
            dynamicHeight={false}
            centerMode={true}
            centerSlidePercentage={80}
            selectedItem={1}
          >
            {hotelImages.map((hotelImage, i) => (
              <div key={"hotel" + i}>
                <img
                  src={hotelImage}
                  alt="image1"
                  className="carousel-images"
                />
              </div>
            ))}
          </Carousel>
        )}
      </div>
    </>
  );
};
export default ImagesLightbox;
