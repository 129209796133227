import React, { useState, useEffect } from "react";
//import * as ReactBoostrap from "react-bootstrap";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./Managerooms.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";

import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";

const Managerooms = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = imgUrl;
  const handleClose = () => setUpdateModal(false);
  const handleShow = () => setUpdateModal(true);
  const handleInfoShow = () => setInfoModal(true);
  const handleInfoClose = () => setInfoModal(false);
  const { Panel } = ANTD.Collapse;
  // const toggle = () => {
  //     setUpdateModal(!updateModal)
  // }
  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const showModal = () => {
    setModalVisible({
      visible: true,
    });
  };
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }
  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);
  // onChange = e => {
  //     console.log('radio checked', e.target.value);
  //     setDiscountBtn(e.target.value);
  // };
  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  const tableActions = () => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={showModal}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  /* const tableDelete = () => {
        return (
            <div className="edit-icon" style={{ textAlign: 'center' }}>
                <ANTD.Popconfirm title="Are you sure to delete?" >
                    <DeleteOutlined style={{ color: 'red', fontSize: '20px', textAlign: 'center' }} />
                </ANTD.Popconfirm>
            </div>
        )
    } */

  // const rowClasses = (row, rowIndex) => {
  //     // const style = { };

  //     // if( rowIndex == 1 ) {
  //     //     style.backgroundColor = '#ccc';
  //     // }
  //     return 'custom-row-class';
  // }
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      name: "Jhon",
      description: "drrfff",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon",
      description: "drrfff",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon",
      description: "drrfff",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Description",
      dataIndex: "description",
    },
    // {
    //   title: "Created Date",
    //   dataIndex: "credate",
    // },
    // {
    //   title: "LastModified Date",
    //   dataIndex: "lmdate",
    // },

    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      dataIndex: "actions",
      title: "Actions",
    },
    /*  {
            title: 'Delete',
            dataIndex: 'delete'

        } */
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };

  // const paginationOptions = {
  //     sizePerPageList: [
  //         {
  //             text: '5', value: 5
  //         },
  //         {
  //             text: '10', value: 10
  //         },
  //         {
  //             text: '15', value: 15
  //         },
  //         {
  //             text: 'All', value: products.length
  //         }
  //     ]
  // }

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            {/*  <ANTD.Col span={24}>
                            <div className="card-bt-gap">
                                <ANTD.Card bordered={false}>
                                    <div className="card-add-heading">
                                        <h5>Car Types</h5>
                                    </div>

                               

                                </ANTD.Card>
                            </div>
                        </ANTD.Col> 
 */}

            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          Manage Rooms{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>3 rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      {/* <img src={word} alt="word" /> */}

                      {/*  <img src={exchange} alt="exchange" /> */}
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    {/* onChange={onChange} expandedRowRender= {rowExpand}*/}
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />

                    {/* <ANTD.Modal visible={this.state.visible} onCancel={closeModal}>
                                                <h6>This is the modal bodyyyyy</h6>
                                        </ANTD.Modal> */}
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title="Manage Rooms"
        className="promo-modal-header hotel-extranet-hotel"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <ANTD.Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Select Hotel" rules={[{ required: true }]}>
                <ANTD.Select placeholder="Please select">
                  <Option value="0">The Park</Option>
                  <Option value="1">The Park</Option>
                  <Option value="2">The Park</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="Room Name"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="How many Rooms"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="Size of room Length  Sq Ft"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="How many adults per room"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="How many Child per room"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                name={["user", "Model Name"]}
                label="View of room"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Check In"
                rules={[{ required: true }]}
                name="Start Time"
              >
                <ANTD.Select placeholder="Please select">
                  <Option value="0">1AM</Option>
                  <Option value="1">2AM</Option>
                  <Option value="2">11PM</Option>
                  <Option value="3">12PM</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Check Out"
                rules={[{ required: true }]}
                name="Start Time"
              >
                <ANTD.Select placeholder="Please select">
                  <Option value="0">1AM</Option>
                  <Option value="1">2AM</Option>
                  <Option value="2">11PM</Option>
                  <Option value="3">12PM</Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Rooms Count"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Room Combination"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Rate Plan Options"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Base"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Discounts"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Tax"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Extra Guest Charges"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Other Charges"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Total"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item
                label="Commission"
                rules={[
                  {
                    type: "Number",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24}>
              <ANTD.Form.Item label="Last cancelled Date time">
                <ANTD.DatePicker
                  style={{ width: "100%" }}
                  placeholder="Select From date"
                />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item label="Soldout">
                <ANTD.Radio.Group
                  onChange={onChange}
                  value={statusBtn}
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <ANTD.Radio value={1}>True</ANTD.Radio>
                  <ANTD.Radio value={2}>False</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item label="Offer ">
                <ANTD.Radio.Group
                  onChange={onChange}
                  value={statusBtn}
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <ANTD.Radio value={1}>True</ANTD.Radio>
                  <ANTD.Radio value={2}>False</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item label="is Amendment Allowed">
                <ANTD.Radio.Group
                  onChange={onChange}
                  value={statusBtn}
                  rules={[{ required: true, message: "Please choose Status!" }]}
                >
                  <ANTD.Radio value={1}>True</ANTD.Radio>
                  <ANTD.Radio value={2}>False</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="refundable-hotel">
              <ANTD.Form.Item>
                <ANTD.Checkbox>Refundable</ANTD.Checkbox>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="refundable-hotel">
              <ANTD.Form.Item>
                <ANTD.Checkbox>Non-Refundable</ANTD.Checkbox>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
          <ANTD.Row gutter={16} className="mb-bottom">
            <ANTD.Col xs={24} md={24}>
              <ANTD.Collapse accordion>
                <Panel header="Amenities" key="1">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <ANTD.Checkbox>Test</ANTD.Checkbox>
                        <ANTD.Checkbox>Test</ANTD.Checkbox>
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Room Short Description" key="2">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Room Long Description" key="3">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Inclusions" key="4">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Exclusions" key="5">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={24} xs={24}>
                      <ANTD.Form.Item>
                        <TextArea
                          placeholder=""
                          autoSize={{ minRows: 3, maxRows: 3 }}
                        />
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Hotel Image" key="6">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item name="phone">
                        <ANTD.Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </ANTD.Upload>
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
                <Panel header="Video" key="7">
                  <ANTD.Row gutter={16}>
                    <ANTD.Col md={6} xs={24}>
                      <ANTD.Form.Item name="phone">
                        <ANTD.Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                          beforeUpload={beforeUpload}
                          onChange={handleChange}
                        >
                          {imageUrl ? (
                            <img
                              src={imageUrl}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </ANTD.Upload>
                      </ANTD.Form.Item>
                    </ANTD.Col>
                  </ANTD.Row>
                </Panel>
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <ANTD.Button type="primary" htmlType="submit">
              Add
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal>
      <ANTD.Modal
        title="Basic Modal"
        visible={userInfo}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Some contents...</p>
        <p>Some contents...</p>
        <p>Some contents...</p>
      </ANTD.Modal>
    </div>
  );
};

export default Managerooms;
