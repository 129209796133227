import React from "react";
import { Helmet } from "react-helmet";
import CmsPages from "../../common/CMSPages/CmsPages";
import { AppConstants } from "../../helpers/constants";
const Faqs = () => {
  return (
    <>
      <Helmet>
        <title>FAQ's - {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="Go through all the most common frequently asked questions by our customers. If you don't find any contact us @9999292007."
        />
      </Helmet>
      <CmsPages cmsId={4} pageTitle="FAQS" />
    </>
  );
};

export default Faqs;
