import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
const { Option } = Select;
const ActivitiesAutoComplete = (props) => {
  useEffect(() => {
    getResults(props.refName.current.props.value);
  }, [
    props.refName.current,
    props.isCartModify,
    props.modifySearch,
    props.searchCity,
  ]);

  const getResults = (value) => {
    if (!value || value == "" || value == " ") {
      return;
    }
    const searchParams = queryString.parse(value);

    fetchData(searchParams.city, searchParams.city);
  };

  const onSelect = () => {
    // if (props.focusRef) {
    //   props.handleOnSubmit(props.focusRef);
    // }
  };

  const [details, setDetails] = useState({
    data: [],

    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityName) => {
    let arr = [];

    if (cityName != null) {
      results = results.filter((item) => item.city == cityName);
    }

    results.forEach((result) => {
      let activities = "";
      let hotelParams = "";
      if (result?.activities?.length > 0) {
        activities = `cityId=${result.activities[0].cityId}&countryId=${result.activities[0].countryId}`;
      }
      if (result?.hotels?.length > 0) {
        hotelParams = `hotelCityCode=${result.hotels[0].cityId}`;
      }
      arr.push({
        cityId: `city=${result.city}&${activities}&${hotelParams}`,
        cityName: `${result.city}, ${result.country}`,
      });
    });

    return arr;
  };

  function fetchData(value, cityName = null) {
    setDetails({ data: [], fetching: true });
    APIClient.get(`${props.api}${value}`)
      .then((res) => {
        if (res.status === 200 && res) {
          setDetails({
            data: createOptions(res.data, cityName),
            fetching: false,
          });
          return;
        }
        setDetails({ data: [], fetching: false });
      })
      .catch((error) => {
        console.error(error);
        setDetails({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={onSelect}
        defaultActiveFirstOption
      >
        {details.data.map((d) => (
          <Option value={d.cityId} key={"activityKey" + d.cityId}>
            {d.cityName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ActivitiesAutoComplete;
