import React, { useState } from "react";
import nohotelimage from "../assets/images/hotels/no_img.png";

const CustomImg = ({ src, alt = "hotel images" }) => {
  const [showImg, setShowImg] = useState(true);
  return (
    <img
      src={showImg ? src : nohotelimage}
      alt={alt}
      onError={() => {
        setShowImg(false);
      }}
    />
  );
};

export default CustomImg;
