import React from "react";
import { Button, Card, Col, Row } from "antd";
import { Link, NavLink } from "react-router-dom";
import "./bloggrid.scss";
const BASE = process.env.REACT_APP_BASE_URL;
const BlogGrid = ({ blog }) => {
  if (blog?.length > 0) {
    blog = blog.filter((item) => item.ShowonHomePage === 1);
  }

  return blog.length > 0 ? (
    <div className="blog_wrapper">
      <div className="blog_grid">
        <h2>Blogs</h2>
        <Row gutter={[24, 24]}>
          {blog.map((item, index) =>
            index < 6 ? (
              <Col md={8} xs={24} key={index}>
                <Link to={`/bloginfo/${item.BlogID}/${item.urlTitle}`}>
                  <Card
                    className="card "
                    // cover={
                    //   <img
                    //     className="img"
                    //     src={BASE + item.BlogTitleImage1.substring(1)}
                    //     alt="Hotel Image"
                    //   />
                    // }
                    style={{
                      minHeight: "200px",
                      backgroundRepeat: "no - repeat",
                      backgroundSize: "cover",
                      backgroundImage: `url(${
                        BASE + item.BlogTitleImage1.substring(1)
                      })`,
                      paddingTop: "25% !important",
                    }}
                  >
                    <p
                      className="title"
                      style={{
                        color: "white",
                        backgroundColor: "#00000094",
                        padding: "10px",
                      }}
                    >
                      {item.BlogTitle}
                    </p>
                  </Card>
                </Link>
              </Col>
            ) : null
          )}
        </Row>
        <div className="btn_wrapper mt-2">
          <Button size="large">
            <NavLink to="/blog">View More</NavLink>
          </Button>
        </div>
      </div>
    </div>
  ) : null;
};

export default BlogGrid;
