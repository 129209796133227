import { SearchOutlined } from "@ant-design/icons";
import * as ANTD from "antd";
import "moment/locale/en-in.js";
import "rc-datepicker/lib/style.css";
import React from "react";
import { Link } from "react-router-dom";
import "../buses-search/Buses.scss";

const Holidaysplaces = (props) => {
  // console.log(props);
  /* const globalBusData = props.gscontext.state.busData; */
  /* const { updateBusField, updateBusSearchResultObj } = props.gscontext; */
  /* const sourceOptions = SourceList.data; */

  return (
    <div className="hotels_search_box">
      <div className="hotels-wrapper">
        {/*  <ANTD.Radio.Group onChange={onChange} value={radioBtn}>
          <ANTD.Radio value={"oneWay"}>One way</ANTD.Radio>
          <ANTD.Radio value={"roundTrip"}>Round trip</ANTD.Radio>
        </ANTD.Radio.Group> */}
        <ANTD.Form
        // layout={formLayout}
        >
          <ANTD.Row className="search-row" gutter={16}>
            <ANTD.Col md={20} xs={24} className="from-to-inputs">
              <ANTD.Form.Item
                /*  label="Book Domestic and International Holiday Packages" */
                name="departfrom"
                rules={[{ required: true }]}
              >
                <ANTD.Input
                  placeholder="Search by destination, activity"
                  size={"large"}
                />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item className="mr-none">
                <Link to="/activities/results">
                  <ANTD.Button
                    size="large"
                    className="primary-btn train-search-btn"
                    htmlType="submit"
                    /* onClick={goTo} */
                  >
                    <SearchOutlined />
                  </ANTD.Button>
                </Link>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </div>
    </div>
  );
};

export default Holidaysplaces;
