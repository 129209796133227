import { getConvFee, getPromoDiscount } from "../promoConvhelper";
function getBusTicketPrice(ticketData) {
  let baseFare = 0;
  let postMarkup = 0;
  let taxes = 0;
  let serviceCharge = 0;
  let total = 0;
  let grandTotal = 0;
  let insuranceTotal = 0;
  let convamount = 0;
  let discount = 0;
  let redeemAmount = 0;

  if (ticketData?.postMarkup) {
    postMarkup = Number(ticketData?.postMarkup);
  }

  if (ticketData?.passengerInfo?.length > 0) {
    baseFare = ticketData.passengerInfo.reduce(
      (acc, cur) => acc + Number(cur.fares),
      0
    );
    taxes = ticketData.passengerInfo.reduce(
      (acc, cur) => acc + Number(cur.serviceTax),
      0
    );
    serviceCharge = ticketData.passengerInfo.reduce(
      (acc, cur) => acc + Number(cur.serviceCharge),
      0
    );
  }

  baseFare = Number(baseFare) + postMarkup;

  if (
    ticketData?.insuranceRequired === 1 &&
    ticketData?.insuranceData?.serviceType === 3
  ) {
    insuranceTotal =
      ticketData.passengerInfo.length *
      Number(ticketData?.insuranceData.amount);
  }

  total = Number(baseFare) + Number(taxes) + Number(serviceCharge);

  convamount = getConvFee(ticketData.convienenceData, total);
  grandTotal = total + convamount;
  discount = getPromoDiscount(ticketData?.promoData, grandTotal);

  grandTotal -= discount;

  // if(ticketData?.couponAmount){
  //   redeemAmount= Number(ticketData?.couponAmount)
  // }
  grandTotal -=redeemAmount
  grandTotal += Number(insuranceTotal);

  // if (ticketData?.promoData?.Discount) {
  //
  //   if (ticketData.promoData.tripType === "twoWay") {
  //     grandTotal -= Number(discount) / 2;
  //   } else {
  //     grandTotal -= Number(discount);
  //   }
  // }

  return {
    baseFare: Number(baseFare).toFixed(2),
    taxes: Number(taxes).toFixed(2),
    serviceCharge: Number(serviceCharge).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    grandTotal: Number(grandTotal).toFixed(2),
    discount: Number(discount).toFixed(2),
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemAmount:Number(redeemAmount).toFixed(2),
  };
}

export default getBusTicketPrice;
