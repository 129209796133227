import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tabs, Spin } from "antd";

import { useActivitiesContext } from "../../common/providers/Activities/ActivitiesProvider";
import ApiClient from "../../helpers/ApiClient";
import { getDateRange } from "../../helpers/activitiesHelper";
import Hotelpkgroom from "../../pages/Activitespkg/Hotelpkgroom/Hotelpkgroom";
import Buildpackagesearchnew from "../BuildYourPackage/Buildpackagesearchnew";
import Activiesresults from "../../pages/Activitespkg/Activitiesresults/Activitiesresults";
import HotelResults from "../../pages/Hotels/HotelResults/HotelResults";
import moment from "moment";
import queryString from "query-string";
import "../Combinedbuildpkg/Combinedbuildpkg.scss";

const { TabPane } = Tabs;

const Combinedbuildpkg = () => {
  const {
    state: {
      searchReqBuildActivities,
      selectedBuildActivities,
      selectedBuildHotel,
    },
    setSearchReqBuildActivities,
    setSelectedBuildActivities,
    setSelectedBuildHotel,
  } = useActivitiesContext();

  const [reqParams, setReqParams] = useState({
    activitiesParam: {},
    hotelParam: {},
  });
  const [selectedOptions, setSelectedOptions] = useState({
    isActivitySelected: false,
    isHotelSelected: false,
  });

  const [isModifySearch, setIsModifySearch] = useState(false);
  const [isCartModify, setIsCartModiy] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchBuildPackageSearch();
  }, []);

  const createQuotation = (id) => {
    setSelectedBuildHotel([]);
    setSelectedBuildActivities([]);
    ApiClient.get(`buildPackage/getPackage/${id}`)
      .then((results) => results)
      .then((resp) => {
        if (resp.status == 200 && resp.data?.PackageDetails) {
          try {
            let req = JSON.parse(resp.data.PackageDetails.buildPackageRequest);

            setPackageId(resp.data.PackageDetails?.PackageRefNo);

            const tourAvlDate = getDateRange(
              req?.checkInDate,
              req?.checkOutDate
            );

            handleReqData(req);

            if (resp.data.HotelDetails.length > 0) {
              let selectedhotels = resp.data.HotelDetails.map(
                (hotel) => hotel.roomResp
              );
              setSelectedBuildHotel(selectedhotels);
            }
            let selectedTours = [];
            if (resp.data.TourDetails.length > 0) {
              resp.data?.TourDetails?.map((tour) => {
                tour?.optionData?.map((optionData) => {
                  let i = tourAvlDate.indexOf(
                    moment(optionData.tourDate).format("DD-MM-YYYY")
                  );

                  if (
                    selectedTours.hasOwnProperty(i) &&
                    selectedTours[i]?.length > 0
                  ) {
                    selectedTours[i].push(optionData);
                  } else {
                    selectedTours[i] = [optionData];
                  }
                });
              });
            }
            setSelectedBuildActivities(selectedTours);
          } catch (error) {}
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const fetchBuildPackageSearch = () => {
    let combinedparam = queryString.parse(document.location.search);

    setReqParams({
      activitiesParam: {},
      hotelParam: {},
    });

    if (combinedparam?.packageRefNo) {
      setIsLoading(true);
      setIsCartModiy(true);
      createQuotation(combinedparam?.packageRefNo);
      return;
    }

    handleReqData(combinedparam);
  };

  const handleReqData = (combinedparam) => {
    setSelectedOptions({
      isActivitySelected: ["true", "True", true].includes(
        combinedparam.isActivitiy
      ),
      isHotelSelected: ["true", "True", true].includes(combinedparam.isHotel),
    });

    let activitiesReqObj = {
      TravelDate: combinedparam.checkInDate,
      CountryId: combinedparam?.countryId,
      CityId: combinedparam?.cityId,
    };

    let hotelsearchReqObj = {
      checkInDate: combinedparam.checkInDate,
      checkOutDate: combinedparam.checkOutDate,
      hotelCityCode: combinedparam?.hotelCityCode,
      // hotelCityCode: "1223",
      roomGuests: JSON.parse(combinedparam.roomGuests),
      nationality: combinedparam.nationality,
      countryCode: combinedparam.nationality,
      isHotelDescriptionRequried: true,
      currency: combinedparam.currency,
      traceId: "string",
    };
    setSearchReqBuildActivities(combinedparam);
    setIsModifySearch(true);

    setReqParams({
      activitiesParam: activitiesReqObj,
      hotelParam: hotelsearchReqObj,
    });
  };

  return isLoading ? (
    <div className="d-flex align-items-center justify-content-center py-4">
      <Spin tip="Loading..." />
    </div>
  ) : (
    <>
      <div className="promo providerapi ">
        <div className="ourteam-bg activitycnf">
          <div className="admin-container cms-pages-width build-new-pdr-v3">
            <div className="accordion mobile-accordi" id="accordionExample">
              <div className="card hotels-search-card-value">
                <div
                  className="card-head hide-icon-heading-two"
                  id="headingTwo"
                >
                  <h2
                    className="mb-0 modiiconbar"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Modify Search
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  className="collapse show"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div className="card-body">
                    <div className="main_div sub_div combined-zoner-res build-zoner-v3">
                      <Buildpackagesearchnew
                        buildPackageSearch={fetchBuildPackageSearch}
                        modifySearch={isModifySearch}
                        isCartModify={isCartModify}
                        searchReqBuildActivities={searchReqBuildActivities}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="promo providerapi conbined-bg-ha">
        <div className="admin-container buildpkg-width activity-cms">
          <div className="view-select-pkg-plan">
            {selectedBuildHotel.length > 0 ||
            selectedBuildActivities.length > 0 ? (
              <Button
                className="view-data-hotel-activity"
                data-toggle="modal"
                data-target="#myBuildActivitiesModal"
              >
                Show Package
              </Button>
            ) : null}
          </div>

          <Tabs
            className="combinedtabs-panel build-combined-hotel-acti hotel-build-hide"
            defaultActiveKey="1"
            centered
          >
            {selectedOptions?.isActivitySelected ? (
              <TabPane tab="Activities" key="1">
                {reqParams?.activitiesParam &&
                Object.keys(reqParams?.activitiesParam).length > 0 ? (
                  <Activiesresults
                    activitiesParam={reqParams?.activitiesParam}
                    isFromPackage={true}
                  />
                ) : null}
              </TabPane>
            ) : null}
            {selectedOptions?.isHotelSelected ? (
              <TabPane tab="Hotels" key="2">
                {reqParams?.hotelParam &&
                Object.keys(reqParams?.hotelParam).length > 0 ? (
                  <HotelResults
                    hotelParam={reqParams?.hotelParam}
                    isFromPackage={true}
                  />
                ) : null}
              </TabPane>
            ) : null}
          </Tabs>
        </div>
      </div>

      <div
        className="modal left fade tabs-left-bar"
        id="myBuildActivitiesModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myBuildActivitiesModalLabel"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close close-hide-show"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Hide</span>
              </button>
            </div>

            <div className="modal-body">
              <Row>
                <Col md={24} xs={24} className="trace-hotels ">
                  <Hotelpkgroom
                    searchReqBuildActivities={searchReqBuildActivities}
                    isCartModify={isCartModify}
                    packageId={packageId}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <Row className="fiters-value-hotel-1">
        <Col md={12} xs={12} className="hotel-center-cls">
          <h5
            className="hotel-sort-by-1"
            //  onClick={showModalsort}
          >
            <i className="fa fa-sort-amount-asc" aria-hidden="true"></i>
            &nbsp;Sort by
          </h5>
        </Col>
      </Row>
    </>
  );
};

export default Combinedbuildpkg;
