import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Checkflightinfo.scss";
import { Link } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";
const Checkflightinfo = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const { Panel } = ANTD.Collapse;

  const [CMSList, setCMSList] = useState([]);
  useEffect(() => {
    const getCMSList = () => {
      const CMSId = 10;
      APIClient.get("admin/cms/" + `${CMSId}`)
        .then((response) => {
          if (response.status == 200) {
            setCMSList(response.data.pages);
            // setDescription(response.data.pages.map(se=>{return se.Description}))
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getCMSList();
  }, []);

  //   var aray = [];
  //   CMSList.forEach((element) => {
  //     aray = element.Description;
  //   });

  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi checkflightinfo-new">
        <ANTD.Layout className="ourteam-bg">
          <div className="">
            <Content className="admin-container ">
              {/*  <ANTD.Row>
                                <ANTD.Col span={24}>
                                    <div className="">

                                        <div className="">
                                            <h2 className="common-headings">Our Team</h2>
                                            <p className="common-paras">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                                                when an unknown printer took a galley of type and scrambled it to make a type
                                                specimen book. It has survived not only five centuries, but also the leap into
                                                electronic typesetting, remaining essentially unchanged. It was popularised in
                                                the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
                                                and more recently with desktop publishing software like Aldus PageMaker including
                                                versions of Lorem Ipsum.</p>

                                        </div>

                                    </div>
                                </ANTD.Col>
                            </ANTD.Row> */}
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <ANTD.Layout className="ourteam-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="">
                <div className="">
                  <h2 className="common-headings">Check Flight Info</h2>

                  <div>
                    {/* {aray.map((res) => {
                      if (
                        res.title == "" ||
                        res.title == null ||
                        res.title == "undefine"
                      ) {
                        return (
                          <p className="common-paras">{res.description}</p>
                        );
                      }
                    })} */}
                  </div>
                </div>
              </div>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col span={24}>
              <ANTD.Collapse
                bordered={false}
                expandIcon={({ isActive }) => (
                  <CaretRightOutlined rotate={isActive ? 90 : 0} />
                )}
                className="site-collapse-custom-collapse"
                expandIconPosition="right"
              >
                {/* {aray.map((res, i) => (
                  <Panel
                    header={res.title}
                    key={i}
                    className="site-collapse-custom-panel"
                  >
                    <p>{res.description}</p>
                  </Panel>
                ))} */}
              </ANTD.Collapse>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};

export default Checkflightinfo;
