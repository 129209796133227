import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  message,
  Popconfirm,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Popover,
} from "antd";

import moment from "moment";

import ApiClient from "../../helpers/ApiClient";

import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";

import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { TextArea } = Input;

const Activitiesoption = (props) => {
  const [activitiesList, setActivitiesList] = useState([]);
  const [activitiesOptionList, setActivitiesOptionList] = useState([]);
  const [loding, setLoding] = useState(true);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [form] = Form.useForm();
  const [isEditMode, setIsEditMode] = useState(false);
  const [id, setId] = useState(-1);
  const [excelList, setExcelList] = useState([]);

  const changeStatus = (info, status) => {
    const id = info.tourOptionId;
    delete info.SNo;
    delete info.tourOptionId;
    delete info.tourName;
    delete info.transferName;
    let obj = {
      ...info,
      status: status,
    };
    updateActivitiesOption(obj, id);
  };

  const handelStatus = (data) => {
    if (data.status == 1) {
      setId(data.tourOptionId);

      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => changeStatus(data, 0)}
        />
      );
    } else {
      setId(data.tourOptionId);
      return (
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => changeStatus(data, 1)}
        />
      );
    }
  };

  const columnActivitiesOption = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "sno",
    },
    {
      title: "Tour Name",
      dataIndex: "tourName",
      sorter: (a, b) => a.optionName.localeCompare(b.optionName),
    },
    {
      title: "Option Name",
      dataIndex: "optionName",
      sorter: (a, b) => a.optionName.localeCompare(b.optionName),
    },
    // {
    //   title: 'Option ID',
    //   dataIndex: 'tourOptionId',
    //   sorter: (a, b) => a.tourOptionId.localeCompare(b.tourOptionId)
    // },
    {
      title: "Description",
      dataIndex: "optionDescription",
      sorter: (a, b) => a.optionDescription.localeCompare(b.optionDescription),
    },

    {
      title: "Transfer Name",
      dataIndex: "transferName",
      sorter: (a, b) => a.transferName.localeCompare(b.transferName),
    },
    {
      title: "Transfer Time",
      dataIndex: "transferTime",
      sorter: (a, b) => a.transferTime.localeCompare(b.transferTime),
    },
    {
      title: "Transfer Duration",
      dataIndex: "transferDuration",
      sorter: (a, b) => a.transferDuration.localeCompare(b.transferDuration),
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      render: (createdDate) =>
        createdDate ? moment(createdDate).format("DD-MM-YYYY") : null,
    },

    {
      title: "Modify Date",
      dataIndex: "modifiedDate",
      render: (modifiedDate) =>
        modifiedDate ? moment(modifiedDate).format("DD-MM-YYYY") : null,
    },
    {
      title: "Status",
      // dataIndex: 'status',
      key: "category",
      // render: (text, order) =>
      //   <Switch checkedChildren="Active" unCheckedChildren="Inactive" defaultChecked={false} />
      render: (currentItem) => handelStatus(currentItem),
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const updateActivitiesOptions = (currentFormData) => {
    setId(currentFormData.tourOptionId);
    let daysArrya = [];
    if (currentFormData.monday == 1) {
      daysArrya.push("mon");
    }
    if (currentFormData.tuesday == 1) {
      daysArrya.push("tue");
    }
    if (currentFormData.wednesday == 1) {
      daysArrya.push("wed");
    }
    if (currentFormData.thursday == 1) {
      daysArrya.push("thur");
    }
    if (currentFormData.friday == 1) {
      daysArrya.push("fri");
    }
    if (currentFormData.saturday == 1) {
      daysArrya.push("sat");
    }
    if (currentFormData.sunday == 1) {
      daysArrya.push("sun");
    }

    form.setFieldsValue({
      ...currentFormData,
      tourName: currentFormData.tourId,
      transferName: currentFormData.transferId,
      optionName: currentFormData.optionName,
      operationDays: daysArrya,
    });
    setIsEditMode(true);
  };

  const tableActions = (CurrentItem) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => updateActivitiesOptions(CurrentItem)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteActivitiesOption(CurrentItem.tourOptionId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const onFinish = (val) => {
    let operatingDays = {
      mon: 0,
      tue: 0,
      wed: 0,
      thur: 0,
      fri: 0,
      sat: 0,
      sun: 0,
    };

    if (val.operationDays) {
      val.operationDays.forEach((dayName) => {
        operatingDays[dayName] = 1;
      });
    }

    let obj = {
      optionName: val.optionName,
      tourId: val.tourName,
      optionDescription: val.optionDescription,
      transferId: val.transferName,
      transferTime: val.transferTime,
      transferDuration: val.transferDuration > "",
      status: 1,
      monday: operatingDays.mon,
      tuesday: operatingDays.tue,
      wednesday: operatingDays.wed,
      thursday: operatingDays.thur,
      friday: operatingDays.fri,
      saturday: operatingDays.sat,
      sunday: operatingDays.sun,
      createdBy: 0,
      createdDate: new Date(),
      modifiedBy: 0,
      modifiedDate: new Date(),
    };

    isEditMode ? updateActivitiesOption(obj, id) : saveActivitiesOption(obj);
  };

  /** Search ****************** */
  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = activitiesOptionList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setActivitiesOptionList(searchResults);
    } else {
      getActivitiesOptions();
    }
  };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setLoding(false);
    getActivitiesOptions();
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  /* *******************************API CALLS************************************/

  const saveActivitiesOption = (data) => {
    ApiClient.post("extranet/activitiesOptions", data)
      .then((resp) => {
        if (resp.status == 200) {
          message.success("Activities Options Saved SuccessFully", 3);
          getActivitiesOptions();
          form.resetFields();
        } else if (resp.status == 400) {
          message.error("Failed to Save Activities Options", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const updateActivitiesOption = (data, tourOptionId) => {
    ApiClient.put(`extranet/activitiesOptions/${tourOptionId}`, {}, data)
      .then((response) => {
        if (response.status == 200) {
          setId(-1);
          setIsEditMode(false);
          form.resetFields();
          message.success("Activities option Updated SuccessFully", 3);
          getActivitiesOptions();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getAllActivities = () => {
    ApiClient.get("extranet/getAllActivities").then((resp) => {
      if (resp.status == 200) {
        let data = resp.data.map((item) => {
          return {
            tourId: item.tourId,
            tourName: item.tourName,
          };
        });
        setActivitiesList(data);
      }
    });
  };
  const getActivitiesOptions = () => {
    ApiClient.get("extranet/activitiesOptions")
      .then((resp) => {
        if (resp.status == 200) {
          let data = resp.data.map((options, index) => {
            return {
              SNo: index + 1,
              ...options,
            };
          });
          setActivitiesOptionList(data);
          defineExcelData(data);
          setLoding(false);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Data", 3);
          setLoding(false);
        }
      })
      .catch(() => {});
  };

  const deleteActivitiesOption = (tourOptionId) => {
    ApiClient.delete("extranet/activitiesOptions/" + tourOptionId)
      .then((res) => {
        if (res.status == 200) {
          message.success("Activities Option Deleted Successfully.", 3);
          getActivitiesOptions();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getAllActivities();
    getActivitiesOptions();
  }, []);

  const validateMessages = {
    required: "",
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Tour_Name: item.tourName,
        Option_Name: item.optionName,
        Description: item.optionDescription,
        Transfer_Name: item.transferName,
        Transfer_Time: item.transferTime,
        Transfer_Duration: item.transferDuration,
        Created_Date: item.createdDate,
        Modify_Date: item.modifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  let lastIndex = 0;
  const uniqueRow = () => {
    lastIndex++;
    return lastIndex;
  };

  return (
    <div>
      <div className="manage-markup-section">
        <div className="container-fluid">
          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <h5>
                  Activities Options{" "}
                  <HelpInfoHelper screenName={"/admin/activitiesoption"} />
                </h5>

                <div className="add-icon d-none">
                  <i className="fa fa-plus"></i>
                </div>
              </div>
              <div>
                &nbsp;
                <Form
                  layout={"vertical"}
                  validateMessages={validateMessages}
                  form={form}
                  onFinish={onFinish}
                >
                  <Row gutter={16}>
                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Activities"
                        name="tourName"
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Activities"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {activitiesList.map((item, index) => (
                            <Option key={index} value={item.tourId}>
                              {`${item.tourName}`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Transfer"
                        name="transferName"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="Transfer Type">
                          <Option value={41865}>Without Transfers</Option>
                          <Option value={41843}>Sharing Transfers</Option>
                          <Option value={41844}>Private Transfers</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Option Name"
                        name="optionName"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        name="optionDescription"
                        label=" Description "
                        rules={[{ required: true }]}
                      >
                        <TextArea />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        name="transferTime"
                        label="Transfer Time"
                        // rules={[
                        //   { message: "Package Short Description Required!" },
                        // ]}
                      >
                        <TextArea />
                      </Form.Item>
                    </Col>

                    <Col md={8} sm={12} xs={24}>
                      <Form.Item
                        label="Operation Days"
                        name="operationDays"
                        rules={[
                          {
                            required: true,
                            message: "select operating day",
                          },
                        ]}
                      >
                        <Checkbox.Group>
                          <Checkbox value="mon">Mon</Checkbox>
                          <Checkbox value="tue">Tue</Checkbox>
                          <Checkbox value="wed">Wed</Checkbox>
                          <Checkbox value="thur">Thur</Checkbox>
                          <Checkbox value="fri">Fri</Checkbox>
                          <Checkbox value="sat">Sat</Checkbox>
                          <Checkbox value="sun">Sun</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="reports-btns">
                    <Col>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          {isEditMode ? "Update" : "Add"}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item>
                        {isEditMode ? (
                          <Button
                            type="danger"
                            onClick={() => {
                              setId(-1);
                              setIsEditMode(false);
                              form.resetFields();
                            }}
                          >
                            Cancel
                          </Button>
                        ) : (
                          <Button
                            type="danger"
                            onClick={() => form.resetFields()}
                          >
                            Reset
                          </Button>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Card>
          </div>

          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5>View Report</h5>
                    <p>{activitiesOptionList.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  <img
                    src={excel}
                    alt="excel"
                    onClick={(e) =>
                      props.exportExcel(excelList, "Activities Option")
                    }
                  />
                </div>
              </div>
              <div>
                {loding ? (
                  <Spin
                    size="large"
                    tip="Loading..."
                    style={{ width: "100%" }}
                  />
                ) : activitiesOptionList.length > 0 ? (
                  <Table
                    className="table-scroll-none"
                    bordered
                    dataSource={activitiesOptionList}
                    columns={columnActivitiesOption}
                    rowKey={uniqueRow}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                ) : (
                  <b>No Results Found!</b>
                )}
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatedComponent(Activitiesoption);
