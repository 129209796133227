import React from "react";
import { Card, Col, Row, Popover } from "antd";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { getFareTotal } from "./flightPriceHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import "./FlightsCheckout.scss";

const FareSummaryCard = ({ flightDetObj, flightSearchObj, title,selectedMeal,SelectedBagges }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();

  let { flightFares } = flightDetObj.fareFamilies[0];

  const {
    adultPrice,
    ChildPrice,
    infantPrice,
    totalBaseFare,
    tax,
    totalAmount,
    markup,
    gstOnMarkup,
  } = getFareTotal(flightSearchObj, flightFares);

  const paxFares = () => {
    return (
      <>
        {flightFares
          .filter((fareObj) => fareObj.fareTag === "Base")
          .map((paxFare, index) => (
            <div key={"paxList" + index} className="pax-count-acc-body">
              <p>
                {paxFare.paxType === "ADT" ? (
                  <>Adult(s) {flightSearchObj.adultCount}</>
                ) : paxFare.paxType === "CHD" ? (
                  <>Child(s) {flightSearchObj.childCount}</>
                ) : (
                  <>Infant(s) {flightSearchObj.infantCount}</>
                )}
              </p>
              <p>
                {activeCurrency}{" "}
                {paxFare.paxType === "ADT" ? (
                  <>{currencyValue(adultPrice)}</>
                ) : paxFare.paxType === "CHD" ? (
                  <>{currencyValue(ChildPrice)}</>
                ) : (
                  <>{currencyValue(infantPrice)}</>
                )}
              </p>
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <div className="sticky-card-container">
        <Card className="pax-count-card flight-cards-details">
          <p className="fareTitle">{title}</p>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Base Fare</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(totalBaseFare)}
              </p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={paxFares()}
                title=" Base Fare"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Meal Fare</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(selectedMeal)}
              </p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={paxFares()}
                title=" Base Fare"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Baggage Fare</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(SelectedBagges)}
              </p>

              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={paxFares()}
                title=" Base Fare"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
          <Row justify={"space-between"} className="grand_total_card_row">
            <Col>
              <p>Taxes and Fees</p>
            </Col>
            <Col className="d-flex">
              <p>
                {activeCurrency} {currencyValue(tax)}
              </p>
              <Popover
                overlayClassName="pricepopup"
                placement="left"
                content={
                  <>
                  <div className="pax-count-acc-body">
                    <div className="pax-type">
                      <p>Tax</p>
                    </div>

                    <div className="service-price">
                      <p>
                        {activeCurrency} {currencyValue(tax)}
                      </p>
                    </div>
                  </div>
                   <div className="pax-count-acc-body">
                   <div className="pax-type">
                     <p>Service fee</p>
                   </div>

                   <div className="service-price">
                     <p>
                       {activeCurrency} {currencyValue(markup)}
                     </p>
                   </div>
                 </div>
                 <div className="pax-count-acc-body">
                   <div className="pax-type">
                     <p>GST On Service fee(18%)</p>
                   </div>

                   <div className="service-price">
                     <p>
                       {activeCurrency} {currencyValue(gstOnMarkup)}
                     </p>
                   </div>
                 </div>
                 </>
                }
                title="Taxes and Fees"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>

          <div className="pax-total-price">
            <div className="pax-type">
              <p>Total:</p>
            </div>
            <div className="total">
              <p> {activeCurrency} </p>
              <p className="amount ml-1"> {currencyValue(parseFloat(totalAmount) + parseFloat(selectedMeal) + parseFloat(SelectedBagges)) }</p>
            </div>
          </div>
          <p className="all-taxes">Including all taxes and fees</p>
        </Card>
      </div>
    </>
  );
};

export default FareSummaryCard;
