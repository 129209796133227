import * as ANTD from "antd";
import React from "react";
import "./Activities.scss";
const Activities = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  return (
    <div className="activities-section">
      <ANTD.Layout className="Activity-bg">
        <div className="">
          <Content className="admin-container"></Content>
        </div>
      </ANTD.Layout>
      <ANTD.Layout className="Activity-bg">
        <Content className="admin-container cms-pages-width">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <h1 className="coming-soon">COMING SOON.....</h1>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};
export default Activities;
