import React, { useState } from "react";
import { Button } from "antd";
import ActivitiesList from "./ActivitiesList/ActivitiesList";
import { useSytContext } from "../../../common/providers/SytProvider";

const ActivitiesContainer = ({
  toursData,
  mainToursList,
  showNetFare,
  isFromPackage,
}) => {
  const [pagination, SetPagination] = useState(51);
  const [activeTab, setActiveTab] = useState(null);
  const showMore = () => {
    SetPagination((prev) => prev + 51);
  };
  const { activitySearchBanners } = useSytContext();

  let bannreIndex = -1;

  return (
    <div className="list-of-items">
      {toursData.map((availableTours, itemIndex) => {
        return itemIndex < pagination ? (
          <>
            {itemIndex != 0 &&
              itemIndex % 3 == 0 &&
              activitySearchBanners[++bannreIndex] && (
                <div className="activitys_search_list">
                  <img
                    style={{
                      width: "100%",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      activitySearchBanners[bannreIndex].path.substring(1)
                    }
                    data={
                      activitySearchBanners[bannreIndex + 1]
                        ? null
                        : (bannreIndex = -1)
                    }
                  />{" "}
                </div>
              )}
            <ActivitiesList
              key={itemIndex + "ActivitiesList"}
              toursData={availableTours}
              mainToursList={mainToursList}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              showNetFare={showNetFare}
              isFromPackage={isFromPackage}
            />
          </>
        ) : itemIndex === pagination ? (
          <div className="showmore-wrapper">
            <Button onClick={showMore}>Show More</Button>
          </div>
        ) : null;
      })}
    </div>
  );
};

export default ActivitiesContainer;
