import {
  CaretRightOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Popover,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
var CryptoJs = require("crypto-js");

const InsuranceReports = (props) => {
  //   const BASE = process.env.REACT_APP_BASE_URL;
  //   const creditKey = process.env.CREDIT_SECRET_KEY ?? "credit_card_info@123";
  //   const [reportRole, setReportRole] = useState(0);
  //   const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();
  let dateFormat = "DD-MM-YYYY";
  let oriDateFormat = "YYYY-MM-DD";

  const [showTable, setShowTable] = useState(false);

  const [isDateDisplay, setIsDateDisplay] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const params = queryString.parse(window.location.search);
    setParamValue(params);
  }, []);

  //   useEffect(() => {
  //     getProviders();
  //   }, []);
  //   const getProviders = () => {
  //     ApiClient.get("admin/b2b/user")
  //       .then((res) => {
  //         if (res.status == 200) {
  //           let filterProvider = res.data.filter((item) => item.Status === 1);
  //           setAgentList(res.data);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //       });
  //   };

  const setParamValue = (params) => {
    if (params && params.edit === "edit") {
      if (params.dateType === "1") {
        handleTimeAndDate(params.dateType);
        form.setFieldsValue({
          dateType: params.dateType,
          travelType: Number(params.travelType),
          fromDate: moment(params.fromDate, oriDateFormat),
          toDate: moment(params.toDate, oriDateFormat),
          bookingStatus: Number(params.bookingStatus),
        });
        setShowTable(true);
        setDataSource([]);
        setTableHeaders(Number(params.travelType));
        getBookingListData(params);
      }
    }
  };

  const renderReffrence = (ref, rec) => {
    const type = rec.type;
    console.log(rec, "record");
    let url = "";
    switch (type) {
      case "Flight": {
        url = "flight/ticket";
        break;
      }
      case "Bus": {
        url = "bus/ticket";
        break;
      }
      case "Hotel": {
        url = "hotel/ticket";
        break;
      }
      case "Activities": {
        url = "activities/ticket";
        break;
      }
    }

    return <Link to={url + "?ref=" + ref}>{ref}</Link>;
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    props.exportExcel(excelData, "Booking_Reports");
  };

  const getHotelStatus = (status) => {
    switch (status) {
      case 1: {
        return <p style={{ color: "#FFA500" }}>FAILED</p>;
      }
      case 2: {
        return <p style={{ color: "#008000" }}>CONFIRMED</p>;
      }
      case 3: {
        return <p style={{ color: "#FF0000" }}>CANCELED</p>;
      }
      case 4: {
        return <p style={{ color: "#FF0000" }}>PENDING</p>;
      }
      case 5: {
        return <p style={{ color: "#f9e218" }}>REJECTED</p>;
      }
      case 6: {
        return <p style={{ color: "#FF0000" }}>HOLD</p>;
      }
      case 7: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
      }
      case 8: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
      }
      case 9: {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FFA500" }}>FAILED</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>CONFIRMED</p>;
      }
      case "CANCELED": {
        return <p style={{ color: "#FF0000" }}>CANCELED</p>;
      }
      case "PENDING": {
        return <p style={{ color: "#FF0000" }}>PENDING</p>;
      }
      case "REJECTED": {
        return <p style={{ color: "#f9e218" }}>REJECTED</p>;
      }
      case "HOLD": {
        return <p style={{ color: "#FF0000" }}>HOLD</p>;
      }
      case "CANCELLATIONREQUESTED": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONREQUESTED</p>;
      }
      case "CANCELLATIONPENDING": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPENDING</p>;
      }
      case "CANCELLATIONPROGRESS": {
        return <p style={{ color: "#FF0000" }}>CANCELLATIONPROGRESS</p>;
      }

      default:
        return;
    }
  };

  const getStatus = (status) => {
    switch (status) {
      case "BLOCKED": {
        return <p style={{ color: "#FFA500" }}>{status}</p>;
      }
      case "CONFIRMED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "BOOKED": {
        return <p style={{ color: "#008000" }}>{status}</p>;
      }
      case "CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CREATED": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "HOLD": {
        return <p style={{ color: "#f9e218" }}>{status}</p>;
      }
      case "PARTIALLY CANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "ALREADYCANCELLED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "PENDING": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "FAILED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "REJECTED": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationRequest": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationPending": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      case "CancellationInProgress": {
        return <p style={{ color: "#FF0000" }}>{status}</p>;
      }
      default:
        return;
    }
  };

  const flightColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
    },
    // {
    //   title: "Update number",
    //   dataIndex: "referenceNumber",
    //   render: (text, record) =>
    //     record.BookingStatus == "BLOCKED" || record.bookOffline == 1 ? (
    //       <span>
    //         <span
    //           style={{ cursor: "pointer", color: "red" }}
    //           onClick={() => {
    //             setbookingRefNo(text);
    //             // setShowPnrModelVisible(true);
    //           }}
    //         >
    //           {record.BookingStatus == "CONFIRMED" ? "" : <Link to={`bookoffline?mode=edit&ref=${text}`}>Update PNR</Link>}
    //         </span>
    //       </span>
    //     ) : (
    //       ""
    //     ),
    // },
    // {
    //   title: "CCD",
    //   dataIndex: "creditCardInfo",
    //   render: (info, rec) =>
    //     rec.BookingStatus == "BLOCKED" && rec.bookOffline == 1 && info
    //       ? getCardInfo(info)
    //       : "",
    // },
    // {
    //   title: "View Upload",
    //   render: (t, rec) => {
    //     if (rec.BookingStatus === "BLOCKED" || rec.bookOffline == 1)
    //       return (
    //         <div style={{ textAlign: "center" }}>
    //           <EyeOutlined onClick={() => ShowUserAttachModal(rec.id)} />
    //         </div>
    //       );
    //   },
    // },
    // {
    //   title: "Services",
    //   dataIndex: "additionalServices",
    //   render: (data) => getAdditionalServices(additionalServices),
    // },

    // {
    //   title: "Provider",
    //   dataIndex: "provider",
    //   key: "provider",
    // },
    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight"
          ? record?.oneWaySegment[0]?.origin
          : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record?.oneWaySegment.length;
          return record?.oneWaySegment[length - 1]?.destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    // {
    //   title: "Arrival",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => (record.type == "Flight" ? text[0]?.arrivalTerminal : text),
    // },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight" && text[0]?.arrivalDateTime
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },
    // {
    //   title: "Departure",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => {
    //     if (record.type == "Flight") {
    //       let length = text.length;
    //       return text[length - 1]?.departureTerminal;
    //     } else {
    //       return text;
    //     }
    //   },
    // },
    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1]?.departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
    },

    {
      title: "Pax Name ",
      dataIndex: "guestName",
    },
    // {
    //   title: "Pax Email",
    //   dataIndex: "guestEmaiId",
    // },
    // {
    //   title: "Pax Mobile ",
    //   dataIndex: "guestMobileNo",
    // },
    // {
    //   title: "Adults",
    //   dataIndex: "pax",
    //   render: (text) => text.adults,
    // },
    // {
    //   title: "Childs",
    //   dataIndex: "pax",
    //   render: (text) => text.childCount,
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "pax",
    //   render: (text) => text.infantCount,
    // },
  ];

  const hotelColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },

    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      key: "CheckInDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      key: "CheckOutDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "HotelName",
      dataIndex: "HotelName",
      key: "HotelName",
    },

    // {
    //   title: "Operator Name",
    //   dataIndex: "operatorName",
    //   key: "operatorName",
    // },

    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (status) => getHotelStatus(status),
    },
    {
      title: "Rooms",
      dataIndex: "NoOfRooms",
    },
    // {
    //   title: "Adult Count",
    //   dataIndex: "pax",
    //   render: (value) => value.adults,
    // },
    // {
    //   title: "Child Count",
    //   dataIndex: "pax",
    //   render: (value) => value.childCount,
    // },
    // {
    //   title: "Guest Email",
    //   dataIndex: "guestEmaiId",
    // },
    // {
    //   title: "Guest Mobile ",
    //   dataIndex: "guestMobileNo",
    // },
    // {
    //   title: "Star Rating",
    //   dataIndex: "StarRating",
    // },
    // {
    //   title: "Fare",
    //   dataIndex: "Fare",
    // },
  ];
  const activitiesColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },

    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName?.localeCompare(b.guestName),
    },
    // {
    //   title: "PAx Email",
    //   dataIndex: "guestEmaiId",
    //   sorter: (a, b) => a.guestEmaiId?.localeCompare(b.guestEmaiId),
    // },
    // {
    //   title: "Pax Mobile ",
    //   dataIndex: "guestMobileNo",
    //   sorter: (a, b) => a.guestMobileNo?.localeCompare(b.guestMobileNo),
    // },
    // {
    //   title: "Adults",
    //   dataIndex: "pax",
    //   render: (text) => text.adults,
    // },
    // {
    //   title: "Childs",
    //   dataIndex: "pax",
    //   render: (text) => text.childCount,
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "pax",
    //   render: (text) => text.infantCount,
    // },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "BookingStatus",
      render: (BookingStatus) => getStatus(BookingStatus),
      sorter: (a, b) => a.BookingStatus?.localeCompare(b.BookingStatus),
    },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => (PartPayment?.isPartPayment ? "Part Payment" : "Full Payment"),
    // },
    // {
    //   title: "Due Amount",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => (PartPayment?.dueAmount ? <p style={{ color: "#FF0000", fontWeight: "600" }}>{Number(PartPayment?.dueAmount).toFixed(2)}</p> : ""),
    // },
    // {
    //   title: "Part Payment Amount",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => PartPayment?.partPaymentAmount ?? "",
    // },
    // {
    //   title: "Total Amount",
    //   dataIndex: "serviceTotal",
    // },
  ];
  const busColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    {
      title: "Booking Date",
      // dataIndex: "journeyDate",
      key: "bookingDate",
      render: (text) =>
        text.bookingDate != undefined
          ? moment(text.bookingDate).format("DD-MM-YYYY")
          : "",
      sorter: (a, b) => a.bookingDate?.localeCompare(b.bookingDate),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      sorter: (a, b) => a.journeyDate?.localeCompare(b.journeyDate),
      // key: "journeyDate",
    },
    {
      title: "Source",
      dataIndex: "from",
      key: "from",
    },
    {
      title: "Destination",
      dataIndex: "to",
      key: "from",
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
      sorter: (a, b) => a.guestName - b.guestName,
    },
    // {
    //   title: "PAx Email",
    //   dataIndex: "guestEmaiId",
    //   sorter: (a, b) => a.guestEmaiId - b.guestEmaiId,
    // },
    // {
    //   title: "Pax Mobile ",
    //   dataIndex: "guestMobileNo",
    //   sorter: (a, b) => a.guestMobileNo - b.guestMobileNo,
    // },
    {
      title: "BookingStatus",
      dataIndex: "BookingStatus",
      render: (BookingStatus) => getStatus(BookingStatus),
      sorter: (a, b) => a.BookingStatus - b.BookingStatus,
    },
  ];
  const allColumns = [
    {
      title: "Reference number",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
      sorter: (a, b) => a.referenceNumber?.localeCompare(b.referenceNumber),
    },
    // {
    //   title: "Reference number",
    //   render: (record) => {
    //     return record.type == "Flight"
    //       ? renderReffrence(record.id, record)
    //       : renderReffrence(record.RefNumber, record);
    //   },
    // },
    {
      title: "Service Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Source",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight"
          ? record?.oneWaySegment[0]?.origin
          : text;
      },
    },
    {
      title: "Destination",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record.oneWaySegment.length;
          return record?.oneWaySegment[length - 1]?.destination;
        } else {
          return "";
        }
      },
    },
    {
      title: "HotelName",
      dataIndex: "HotelName",
      key: "HotelName",
      render: (text, rec) => (rec.type == "Hotel" ? rec.HotelName : text),
    },
    {
      title: "Journey Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },
    // {
    //   title: "Arrival",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => (record.type == "Flight" ? text[0]?.arrivalTerminal : ""),
    // },
    {
      title: "Arrival Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight"
          ? moment(text[0]?.arrivalDateTime, oriDateFormat).format(dateFormat)
          : "",
    },

    // {
    //   title: "Departure",
    //   dataIndex: "oneWaySegment",
    //   render: (text, record) => {
    //     if (record.type == "Flight") {
    //       let length = text.length;
    //       return text[length - 1]?.departureTerminal;
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      title: "Departure Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1]?.departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return "";
        }
      },
    },
    {
      title: "CheckIn Date",
      dataIndex: "CheckInDate",
      key: "CheckInDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "CheckOut Date",
      dataIndex: "CheckOutDate",
      key: "CheckOutDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },

    // {
    //   title: "Adults",
    //   // dataIndex: "pax",
    //   // render: (text) => text.adults,
    //   render: (record) => (record.type == "Flight" ? record.pax.adults : record.AdultCount),
    // },
    // {
    //   title: "Childs",
    //   //dataIndex: "pax",
    //   // render: (text) => text.childCount,
    //   render: (text, record) => (record.type == "Flight" ? record.pax.childCount : record.ChildCount),
    // },
    // {
    //   title: "Infant",
    //   dataIndex: "pax",
    //   //render: (text) => text.infantCount,
    //   render: (text, record) => (record.type == "Flight" ? text.infantCount : 0),
    // },
    // {
    //   title: "Operator Name",
    //   dataIndex: "operatorName",
    //   key: "operatorName",
    // },

    // {
    //   title: "Pickup Location",
    //   dataIndex: "pickUpLocation",
    //   key: "pickUpLocation",
    // },
    // {
    //   title: "Drop Location",
    //   dataIndex: "dropLocation",
    //   key: "dropLocation",
    // },
    {
      title: "Booking Status",
      dataIndex: "BookingStatus",
      key: "status",
      // render: (status) => getStatus(status),
      render: (status, record) => {
        return record.type == "Flight"
          ? getStatus(status)
          : getHotelStatus(status);
      },
    },
    {
      title: "Pax Name ",
      dataIndex: "guestName",
    },
    // {
    //   title: "Pax Email",
    //   dataIndex: "guestEmaiId",
    // },
    // {
    //   title: "Pax Mobile ",
    //   dataIndex: "guestMobileNo",
    // },
    // {
    //   title: "Payment Mode",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => (PartPayment?.isPartPayment ? "Part Payment" : "Full Payment"),
    // },
    // {
    //   title: "Due Amount",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => (PartPayment?.dueAmount ? <p style={{ color: "#FF0000", fontWeight: "600" }}>{Number(PartPayment?.dueAmount).toFixed(2)}</p> : ""),
    // },
    // {
    //   title: "Part Payment Amount",
    //   dataIndex: "PartPayment",
    //   render: (PartPayment) => PartPayment?.partPaymentAmount ?? "",
    // },
    // {
    //   title: "Total Amount",
    //   dataIndex: "serviceTotal",
    // },
  ];

  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      case 3:
        setColumns(busColumns);
        break;
      case 4:
        setColumns(activitiesColumns);
        break;
      default:
        setColumns(allColumns);
    }
  };

  const getBookingListData = (values) => {
    setLoading(true);

    ApiClient.get("admin/myBookings/report", values)
      .then((res) => {
        setLoading(false);
        console.log("response", res);
        if (res.statusCode == 200) {
          let data = res.data.filter((item) => item.insuranceRequired == 1);
          console.log(res.data);
          setDataSource(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === "1") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {
    if (values.dateType == "0") {
      values.fromDate = moment(new Date().getTime() - 604800000).format(
        oriDateFormat
      );
      values.toDate = moment().format(oriDateFormat);
    } else if (values.dateType == "1") {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    if (values.journeyDate) {
      values.journeyDate = moment(values.journeyDate).format(oriDateFormat);
    } else {
      values.journeyDate = "";
    }

    setDataSource([]);
    setTableHeaders(values.travelType);
    delete values.dateType;
    getBookingListData(values);
  };

  const InsuranceReportsHeader = () => (
    <>
      Insurance Reports{" "}
      <HelpInfoHelper screenName={"/admin/insurancereports"} />
    </>
  );

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        <CaretRightOutlined rotate={isActive ? 90 : -90} />
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<InsuranceReportsHeader />} key="1">
                      <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{
                          dateType: "3",
                          bookingStatus: "",
                          emailId: "",
                          journeyDate: "",
                          paxName: "",
                          phoneNumber: "",
                          referenceNo: "",
                          serviceType: "",
                          service_type_2: "",
                          travelType: 0,
                          tripType: "",
                        }}
                        onFinish={onFormSubmit}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={8} xs={24}>
                            <Form.Item label="Time and Date" name="dateType">
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value="0">Last One Week </Option>
                                <Option value="1">Custom Dates</Option>
                                <Option value="3">All Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col className="gutter-row" md={8} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder="Select From date"
                                    format={dateFormat}
                                  />
                                </Form.Item>
                              </Col>
                              <Col className="gutter-row" md={8} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    placeholder="Select To date"
                                    format={dateFormat}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}
                          {/* <Col md={8} xs={24}>
                                <Form.Item label="Pax Name" name="paxName">
                                  <Input />
                                </Form.Item>
                              </Col> */}
                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Reports of">
                              <Select placeholder="Select" onSelect={(val) => setReportRole(val)}>
                                <Option value={0}>All</Option>
                                <Option value={1}>Users</Option>
                                <Option value={2}>Guest</Option>
                                <Option value={5}>Agent</Option>
                              </Select>
                            </Form.Item>
                          </Col> */}
                          {/* {reportRole === 5 && (
                            <Col md={8} xs={24}>
                              <Form.Item label="Agent" name="UserId">
                                <Select placeholder="Select Agent">
                                  {agentList.map((data, index) => (
                                    <Option key={index} value={data.UserID}>
                                      {data.userDetails.FirstName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )} */}

                          <Col className="gutter-row" md={8} xs={24}>
                            <Form.Item label="Service" name="travelType">
                              <Select placeholder="Select">
                                <Option value={0}>All</Option>
                                <Option value={1}>Flights</Option>
                                <Option value={2}>Hotels</Option>
                                <Option value={3}>Bus</Option>
                                {/* <Option value={4}>Activities</Option>
                                <Option value={5}>BuildPackage</Option> */}
                              </Select>
                            </Form.Item>
                          </Col>

                          {/* <Col md={8} xs={24}>
                                <Form.Item
                                  label="Service Type"
                                  name="serviceType"
                                >
                                  <Select placeholder="Select Service Type">
                                    <Option value={0}>All</Option>
                                    <Option value={1}>Flights</Option>
                                    <Option value={2}>Buses</Option>
                                  </Select>
                                </Form.Item>
                              </Col> */}
                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Travel Type" name="service_type_2">
                              <Select placeholder="Select Travel Type">
                                <Option value={1}>General</Option>
                                <Option value={2}>CouponFare</Option>
                                <Option value={3}>CorporateFare</Option>
                                <Option value={4}>SMEFare</Option>
                              </Select>
                            </Form.Item>
                          </Col> */}
                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Trip Type" name="tripType">
                              <Select placeholder="Select">
                                <Option value={1}>One way</Option>
                                <Option value={2}>Round Trip</Option>
                              </Select>
                            </Form.Item>
                          </Col> */}

                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Reference number" name="referenceNo">
                              <Input placeholder="Enter Reference Number" />
                            </Form.Item>
                          </Col> */}
                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Journey Date" name="journeyDate">
                              <DatePicker style={{ width: "100%" }} placeholder="Select Journey date" format={dateFormat} />
                            </Form.Item>
                          </Col> */}

                          {/* <Col md={8} xs={24}>
                                <Form.Item label="PNR Number" name="pnr">
                                  <Input />
                                </Form.Item>
                              </Col> */}
                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Pax Email" name="emailId" rules={[{ type: "email" }]}>
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label="Pax Mobile"
                              name="phoneNumber"
                              rules={[
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col> */}

                          {/* <Col md={8} xs={24}>
                            <Form.Item label="Booking Status" name="bookingStatus">
                              <Select placeholder="Please Select">
                                <Option value={1}>Created </Option>
                                <Option value={2}>Blocked</Option>
                                <Option value={3}>Confirmed</Option>
                                <Option value={4}>Canceled</Option> */}
                          {/* <Option value={4}>Cancel Sucess</Option>
                                    <Option value={5}>Refund Sucess</Option>
                                    <Option value={6}>Refund Failed</Option> */}
                          {/* </Select>
                            </Form.Item>
                          </Col> */}
                        </Row>
                        <Row className="reports-btns">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => setShowTable(true)}
                          >
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => form.resetFields()}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>View Reports {dataSource.length}</h5>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              scroll={{ x: true }}
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                  "10",
                                  "25",
                                  "50",
                                  "100",
                                  "125",
                                ],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(InsuranceReports);
