import React, { useEffect, useState } from "react";
import * as ANTD from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import ApiClient from "../../../helpers/ApiClient";
import { RightOutlined } from "@ant-design/icons";
import { filter } from "@antv/util";
import queryString from "query-string";

const TopBuses = () => {
  const [domRoutes, setdomRoutes] = useState([]);
  const [inRoutes, setInRoutes] = useState([]);
  let tomorrowDate = moment().format("DD-MM-YYYY");
  useEffect(() => {
    getRoutes();
  }, []);

  const getRoutes = async (type) => {
    await ApiClient.get("admin/deals", {})

      .then((res) => {
        if (res.status === 200) {
          let data = res.data
            .filter((item) => item.serviceType == 3)
            .map((deal) => ({
              ...deal,
              destination: queryString.parse(deal.destination),
              source: queryString.parse(deal.source),
            }));
          setdomRoutes(data);
        } else {
          console.log("error");
        }
      })
      .catch((e) => {
        setdomRoutes([]);
      });
  };
  return (
    <>
      <div className="container">
        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <h2 className="top-fl-route">Top Buses Routes</h2>
          </ANTD.Col>
        </ANTD.Row>
        <div className="sec-bg-top">
          <ANTD.Row>
            {domRoutes.length > 0 ? (
              domRoutes.map((route, index) => {
                return (
                  <ANTD.Col
                    md={12}
                    xs={24}
                    className="gap-cols-sec"
                    key={index}
                  >
                    <div className="tab-content current">
                      <ul>
                        <li className="top-flight-li">
                          <a className="hm_link">{`${route.source.cityName} to ${route.destination.cityName}`}</a>
                          <Link
                            to={`/bus/results?destinationId=${route.destination.cityId}&destinationName=${route.destination.cityName}&journeyDate=${tomorrowDate}&sourceId=${route.source.cityId}&sourceName=${route.source.cityName}`}
                            className="srch_btn"
                          >
                            Search Buses
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </ANTD.Col>
                );
              })
            ) : (
              <ANTD.Col md={24} xs={24} className="gap-cols-sec">
                <div className="tab-content current text-center">
                  <h6 className="mb-0">No top buses Available</h6>
                </div>
              </ANTD.Col>
            )}
          </ANTD.Row>
        </div>
      </div>
    </>
  );
};

export default TopBuses;
