import React, { useEffect } from "react";
import { Result } from "antd";
import queryString from "query-string";

const ErrorScreen = () => {
  let params = queryString.parse(document.location.search);

  const deleteAllCookies = () => {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  };

  useEffect(() => {
    if (params.rest) {
      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
    }
  });

  return (
    <div className="error-404">
      <Result
        subTitle="Sorry, There is a Critical Error on website"
        status="500"
        title="Error Code: 500"
        extra={
          <div>
            <b style={{ marginBottom: "16px" }}>
              {params.error ? params.error : null}
            </b>
          </div>
        }
      ></Result>
    </div>
  );
};

export default ErrorScreen;
