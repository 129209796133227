import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Modal, Result, Row, message } from "antd";
import APIClient from "../../helpers/ApiClient";
import EncHelper from "../../helpers/EncHelper";
import queryString from "query-string";
import { useAuthContext } from "../../common/providers/AuthProvider";
import { useHistory } from "react-router-dom";
import loginBan from "../../assets/images/login-ban-repo.png";
import "./Login.scss";
import ReCAPTCHA from "react-google-recaptcha";
import { useActivitiesContext } from "../../common/providers/Activities/ActivitiesProvider";

const CryptoJS = require("crypto-js");
const CryptoKey = process.env.REACT_APP_LOGIN_SECRETKEY;
const Login = ({
  userInfo = false,
  location,
  setModalVisible,
  type = "USER",
}) => {
  const {
    extraUserInfo,
    setExtraUserInfo,
    setUser,
    setAccessToken,
    setAdminAsUser,
    setAccessList,
    updateAgentLoginFlag,
    updateLoginFlag,
    updateDashboardFlag,

    resetIsLogin,
  } = useAuthContext();
  const {setSelectedBuildActivities} = useActivitiesContext();
  const [form] = Form.useForm();
  const [forgetForm] = Form.useForm();
  const [otpForm] = Form.useForm();
  const history = useHistory();
  const [userId, setUserId] = useState(-1);
  const [showSuccessMessage, setShowSuccesMessage] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  let extraVal = {
    DeviceToken: "string",
    DeviceType: "Web",
    FirBaseToken: "string",
  };

  useEffect(() => {
    form.resetFields();
  }, []);

  useEffect(() => {
    if (userInfo && extraUserInfo) {
      let data = EncHelper.decryptData(extraUserInfo);
      if (data) {
        onFinish(queryString.parse(data), false);
      }
    }
  }, [userInfo, extraUserInfo]);

  const handleRedirect = () => {
    let path = "/";
    if (location) {
      if (location?.state?.login) path = location.state?.from?.pathname ?? path;
    }

    history.push(path, {});
  };
  const [login, setLogin] = useState(false);
  const [data, setData] = useState({});
  const [picture, setPicture] = useState("");

  // const responseFacebook = (response) => {
  //   console.log(response);
  //   setData(response);
  //   setPicture(response.picture.data.url);
  //   if (response.accessToken) {
  //     setLogin(true);
  //   } else {
  //     setLogin(false);
  //   }
  // };

  const handleAdminAsUser = (id) => {
    setAdminAsUser({
      status: true,
      id,
    });
    setExtraUserInfo(null); 
  };

  const onFinish = (values, redirect = true) => {
    let obj = {
      UserName: values.username,
      Password: CryptoJS.AES.encrypt(values.password, CryptoKey).toString(), 
      ...extraVal,
    };

    APIClient.post("admin/login", obj)
      .then((resp) => {
        if (resp.status === 200) {
          setUser(resp.data.userDetails);
          setAccessToken(resp.accessToken);
          setSelectedBuildActivities([]);

          if (
            resp.data.userDetails.Role.RoleLevel == 1 &&
            (type === "USER" || type === "ALL")
          ) {
            let screens = [];
            resp.data.userDetails.Screens.forEach((element) => {
              screens.push(element.Name);
            });
            setAccessList(screens);
            setModalVisible(false);
            updateDashboardFlag(true);
            history.push("/");
          } else if (
            resp.data.userDetails.Role.RoleLevel == 3 &&
            (type === "AGENT" || type === "ALL")
          ) {
            setModalVisible(false);
            updateAgentLoginFlag(true);
            if (redirect) {
              handleRedirect();
            } else {
              handleAdminAsUser(values.id);
            }
          } else if (
            resp.data.userDetails.Role.RoleLevel == 2 &&
            (type === "USER" || type === "ALL")
          ) {
            setModalVisible(false);
            updateLoginFlag(true);
            if (redirect) {
              handleRedirect();
            } else {
              handleAdminAsUser(values.id);
            }
          } else {
            resetIsLogin();
          }
        } else if (resp.status == 500) {
          message.error("Unable to Login Please Constact Administration", 3);
        } else if (resp.status == 503) {
          message.error(resp.message, 3);
        } else if (resp.status == 400 || resp.status == 404) {
          if (type !== "ALL") {
            let errorMsg = resp?.message
              ? resp?.message
              : "Wrong Email or Password";
            form.setFields([
              {
                name: "username",
                errors: [" "],
                value: "",
              },
              {
                name: "password",
                errors: [errorMsg],
                value: "",
              },
            ]);
          }
        }
      })
      .catch((e) => {});
  };

  const [otpModal, setOtpModal] = useState(false);
  const [forgetModal, setForgetModal] = useState(false);
  const handleOk = (e) => {
    setOtpModal(false);
  };

  const handleCancel = (e) => {
    setOtpModal(false);
  };

  const submitforgetForm = (val) => {
    let mobileNumber;
    let email;
    let mobpattern = /^[0-9]{10}$/;
    if (mobpattern.test(Number(val.username))) {
      mobileNumber = val.username;
      email = null;
    }
    let emailpattern = /\S+@\S+\.\S+/;

    if (emailpattern.test(val.username)) {
      mobileNumber = null;
      email = val.username;
    }
    if (!mobileNumber && !email) {
      forgetForm.setFields([
        {
          name: "username",
          errors: ["Wrong Mobile Number or Email"],
        },
      ]);
      return;
    }
    let data = {
      type: "2",
      Mobile: mobileNumber,
      Email: email,
      ...extraVal,
    };
    APIClient.post("admin/sendotp", data)
      .then((res) => {
        if (res.status === 200) {
          setUserId(res.data.userDetails.UserId);
          setShowSuccesMessage(false);
          otpForm.resetFields();
          setForgetModal(false);
          setOtpModal(true);
        } else if (res.status === 401) {
          forgetForm.setFields([
            {
              name: "username",
              errors: [res.message],
            },
          ]);
        } else if (res.status === 404) {
          if (!!res.message) message.error(res.message);
        }
      })
      .catch((e) => {});
  };

  const verifyOtp = (val) => {
    let data = {
      UserId: userId,
      ...val,
    };
    APIClient.post("admin/updatePassword", data)
      .then((res) => {
        if (res.status === 200) {
          setShowSuccesMessage(true);
        } else if (res.status === 400) {
          otpForm.setFields([
            {
              name: "Otp",
              errors: ["Invalid OTP"],
            },
          ]);
        }
      })
      .catch((e) => {});
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  return (
    <>
      <Row className="img-pop-login">
        <Col md={12} xs={24}>
          <img className="pop-mobi-img" src={loginBan} alt="login image" />
        </Col>
        <Col md={12} xs={24}>
          <Form form={form} name="basic" onFinish={onFinish} size="large">
            <div className="login-text mb-3">
              <h3>{type === "USER" ? "User" : "Partner"} Login</h3>
            </div>

            {type === "USER" ? (
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                  {
                    type: "email",
                    message: "Email is not a valid email",
                  },
                ]}
              >
                <Input placeholder="Email" />
              </Form.Item>
            ) : (
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Input placeholder="Email or Agent Id" />
              </Form.Item>
            )}

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
            >
              <Input.Password placeholder="Password " />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              className="login-btn"
              size={"large"}
              // onClick={() => {
              //   goTo();
              // }}
            >
              Login
            </Button>
          </Form>

          <div className="forgot-text">
            <p
              className="mb-0 my-2"
              onClick={() => {
                setModalVisible(false);
                forgetForm.resetFields();
                setForgetModal(true);
              }}
            >
              Forgot Your Password
            </p>

            {/* <ReactFacebookLogin appId="232782425593562" autoLoad={true} fields="name,email,picture" scope="public_profile,user_friends" callback={responseFacebook} icon="fa-facebook" /> */}
          </div>
        </Col>
      </Row>

      <Modal
        visible={forgetModal}
        onOk={() => setForgetModal(false)}
        onCancel={() => setForgetModal(false)}
        footer={false}
        className="otp-modal remember-modal"
      >
        <div className="forgot-password">
          <div className="dont-remember-text">
            <h4>Don't remember password?</h4>
          </div>
          <Form
            form={forgetForm}
            onFinish={submitforgetForm}
            style={{ padding: 0 }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input placeholder="E-mail or Mobile" size="large" />
            </Form.Item>
            <Row justify="center" className="mb-3">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={handleCaptcha}
              />
            </Row>
            <Button type="primary" htmlType="submit" disabled={buttonDisabled}>
              I want to set a new password
            </Button>
          </Form>
        </div>
      </Modal>

      <Modal
        visible={otpModal}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="otp-modal"
      >
        <div>
          {showSuccessMessage ? (
            <div className="verified-text">
              <Result
                status="success"
                title="Password Changed successfully"
                extra={[
                  <Button
                    type="primary"
                    onClick={() => {
                      setOtpModal(false);
                      setModalVisible(true);
                    }}
                  >
                    Back To login
                  </Button>,
                ]}
              />
            </div>
          ) : (
            <div className="otp-body">
              <p>Enter Details</p>
              <Form form={otpForm} layout="vertical" onFinish={verifyOtp}>
                <Form.Item
                  name="Otp"
                  label="Enter OTP"
                  rules={[{ required: true, message: "OTP Required" }]}
                >
                  <Input placeholder="Enter  OTP" size={"large"} />
                </Form.Item>
                <Form.Item
                  name="Password"
                  label="Enter New Password"
                  rules={[{ required: true, message: "Password Required" }]}
                >
                  <Input.Password placeholder="Enter Password" />
                </Form.Item>

                <div className="otp-continue-btn">
                  <Button type={"primary"} size={"large"} htmlType="submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default Login;
