import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Popconfirm,
  Row,
  Select,
  Table,
  Tooltip,
  Popover,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import {
  CaretRightOutlined,
  CloseOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;
const PGLogs = (props) => {
  const { Content } = Layout;
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const { Option } = Select;
  const [showRequestModal, setRequestModal] = useState(false);
  const [pgLogs, setPgLogs] = useState([]);
  const [PGLogsForm] = Form.useForm();
  const [pgRequest, setPgRequest] = useState("");
  const [pgResponse, setPgResponse] = useState("");
  const [ApiRequest, setApiRequest] = useState("");
  const [ApiResponse, setApiResponse] = useState("");
  const [showAPIRequestModal, setAPIRequestModal] = useState(false);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const fromDateDis = moment();
  const [toDateDis, setToDateDis] = useState(moment());
  const [fromDateValue, setFromDateValue] = useState(moment());

  const getPGLogs = (req) => {
    setShowTable(true);

    ApiClient.get("admin/pgReports", req)
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              PGStatus:
                item.PGStatus === 0
                  ? "FAILED"
                  : item.PGStatus === 1
                  ? "SUCCESS"
                  : "PENDING",
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });
          setPgLogs(data);
          PGLogsForm.resetFields();
        }
      })
      .catch((e) => console.log("api error", e));
  };

  const submitform = (values) => {
    values.fromDate = values.fromDate
      ? values.fromDate.format("YYYY-MM-DD")
      : "";
    values.toDate = values.toDate ? values.toDate.format("YYYY-MM-DD") : "";

    getPGLogs(values);
  };

  function onChange(value) {
    console.log(`selected ${value}`);
  }

  const PGLogsReqRes = (record) => {
    return (
      <span
        style={{ cursor: "pointer", color: "green" }}
        onClick={(e) => {
          setPgRequest(record.PGRequest);
          setPgResponse(record.PGResponse);
          setRequestModal(true);
        }}
      >
        <EyeOutlined />
      </span>
    );
  };

  const getValueFromString = (data, ch) => {
    try {
      let json = JSON.parse(data);
      return json[ch];
    } catch (e) {
      console.log(e);
      return " ";
    }
  };
  function copyToClipboard(text) {
    const elem = document.createElement("textarea");
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand("copy");
    document.body.removeChild(elem);
  }

  const APIRequest = (rec) => {
    return (
      <span
        style={{ cursor: "pointer", color: "red" }}
        onClick={(e) => {
          setApiRequest(rec.ApiRequest);
          setApiResponse(rec.ApiResponse);
          setAPIRequestModal(true);
        }}
      >
        <EyeOutlined />
      </span>
    );
  };

  const getServiceType = (key) => {
    switch (key) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel";
      case 3:
        return "Bus";
      case 4:
        return "Activities";
      case 5:
        return "Build Package";

      default:
        return;
    }
  };

  const columns = [
    {
      title: "Reference ID",
      dataIndex: "ReferenceID",
      // render: (t, r, i) => {
      //   return PGLogsReqRes(r);
      // },
    },
    {
      title: "Pg Log",
      dataIndex: "ReferenceID",
      render: (t, r, i) => {
        return PGLogsReqRes(r);
      },
    },
    {
      title: "API Log",
      dataIndex: "ReferenceID",
      render: (t, r, i) => {
        return APIRequest(r);
      },
    },
    {
      title: "Order Id",
      dataIndex: "OrderID",
      sorter: (a, b) => a.OrderID?.localeCompare(b.OrderID),
    },
    {
      title: "PG Name",
      dataIndex: "pgName",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
    },
    {
      title: "Amount CRD",
      dataIndex: "Currency",
    },
    {
      title: "Payment Status",
      dataIndex: "PGStatus",
      render: (t) => (
        <p
          style={{ fontWeight: "600", color: t == "SUCCESS" ? "green" : "red" }}
        >
          {t}
        </p>
      ),
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      render: (serviceType) => getServiceType(serviceType),
    },
    {
      title: "Payment Mode",
      dataIndex: "PaymentMode",
      sorter: (a, b) => a.PaymentMode?.localeCompare(b.PaymentMode),
    },
    {
      title: "Transaction Id",
      dataIndex: "TransactionId",
    },
    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name?.localeCompare(b.Name),
    },
    {
      title: "Phone",
      dataIndex: "Phone",
    },
    {
      title: "Email",
      dataIndex: "Email",
      sorter: (a, b) => a.Email?.localeCompare(b.Email),
    },

    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
    },
  ];

  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = pgLogs.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setPgLogs(searchResults);
    } else {
      getPGLogs();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };

  const handleExcel = () => {
    const excelData = pgLogs.map((item) => {
      return {
        ...item,
      };
    });

    props.exportExcel(excelData, "pgLogsDetails");
  };
  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const disabledFromDate = (currentDate) => {
    return currentDate > fromDateDis;
  };
  const disabledtoDate = (currentDate) => {
    return currentDate > toDateDis;
  };
  const onChangeFromDate = (date) => {
    setFromDateValue(date);
    let newTodate = moment(date).add(7, "days");
    setToDateDis(newTodate);
  };

  const PGLogsHeader = () => (
    <>
      PG Logs <HelpInfoHelper screenName={"/admin/pglogs"} />
    </>
  );

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div className="panel_wrapper">
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        {/* <CaretRightOutlined rotate={isActive ? 90 : -90} /> */}
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    collapsible="header"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<PGLogsHeader />} key="1">
                      <Form
                        {...formItemLayout}
                        layout={formLayout}
                        initialValues={{
                          options: 1,
                          referenceNo: "",
                          serviceType: "",
                          paymentStatus: "",
                        }}
                        onFinish={submitform}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item label="Time and Date" name="options">
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>

                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledFromDate}
                                    onChange={(date, _) =>
                                      onChangeFromDate(date)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={6} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledtoDate}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}
                          <Col className="gutter-class" md={6} xs={24}>
                            <Form.Item
                              label="Reference Number"
                              name="referenceNo"
                            >
                              <Input placeholder="Enter" />
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item
                              label="Payment Status"
                              name="paymentStatus"
                            >
                              <Select
                                placeholder="Please select"
                                onChange={onChange}
                              >
                                <Option value="1">Success</Option>
                                <Option value="0">Fail</Option>
                                <Option value="2">Pending</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" md={6} xs={24}>
                            <Form.Item label="Service Type" name="serviceType">
                              <Select
                                placeholder="Please select"
                                // onChange={onChange}
                              >
                                <Option value={6}>All</Option>
                                <Option value={1}>Flights</Option>
                                <Option value={2}>Hotels</Option>
                                <Option value={3}>Bus</Option>
                                <Option value={4}>Activities</Option>
                                <Option value={5}>Build Package</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {/* <Col className="gutter-class" md={6} xs={16}>
                            <Form.Item>
                              <div
                                className="checkbox-flex"
                                style={{ paddingTop: "16%" }}
                              >
                                <Radio>B2B</Radio>
                                <Radio>B2C</Radio>
                              </div>
                            </Form.Item>
                          </Col> */}
                        </Row>
                        <Row className="reports-btns">
                          <Button type="primary" htmlType="submit">
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={(e) => {
                              setShowTable(false);
                              PGLogsForm.resetFields();
                            }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {pgLogs.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>View PG Logs</h5>
                              <p>{pgLogs.length} rows found !</p>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              className="table-scroll-none"
                              bordered
                              dataSource={pgLogs}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 25,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>
          <Modal
            visible={showRequestModal}
            title="PG Logs"
            onCancel={(e) => {
              setRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "PG Request: " +
                        pgRequest +
                        ", PG Response :" +
                        pgResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <p>
                <strong>PG Request:</strong>
              </p>
              <pre>{pgRequest}</pre>
            </div>
            <div>
              <p>
                <strong>PG Response:</strong>
              </p>
              <pre>{pgResponse}</pre>
            </div>
          </Modal>
          <Modal
            width="900px"
            visible={showAPIRequestModal}
            title="API LOGS"
            onCancel={(e) => {
              setAPIRequestModal(false);
            }}
            footer={false}
          >
            <div>
              <div className="copy-btn">
                <Popconfirm
                  title="Code Copied, Click on OK"
                  onConfirm={() =>
                    copyToClipboard(
                      "API Request: " +
                        ApiRequest +
                        ", API Response :" +
                        ApiResponse
                    )
                  }
                  onCancel={false}
                  okText="Ok"
                  cancelText="No"
                >
                  <Button>Copy</Button>
                </Popconfirm>
              </div>
              <h6>API Request</h6>
              <pre>{ApiRequest} </pre>
              <br />
              <h6>API Response</h6>
              <pre>{ApiResponse} </pre>
            </div>
          </Modal>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(PGLogs);
