import React from "react";
import * as ANTD from "antd";
import "../HolidaysResults/Holidaysresults.scss";
import { Link } from "react-router-dom";
import { Input } from "antd";

import {
  EnvironmentOutlined,
  AliyunOutlined,
  DownOutlined,
  BgColorsOutlined,
  AlertOutlined,
  CarOutlined,
  WifiOutlined,
  ShopOutlined,
  SearchOutlined,
} from "@ant-design/icons";

const Holidaysfiltersbox = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { TabPane } = ANTD.Tabs;
  const { Option } = ANTD.Select;

  const menuvalue = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Food Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const menushows = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Poplar Attractions</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Museums & galleries</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );
  const menutours = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Guided Food Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Walking Tour</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Local Sightseeing </ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Art & Culture</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Air & Cruise Tours</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const menunature = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Nature & Wildlife</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>For Locals</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Evening To-Dos </ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Cultural Experience</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  const menutra = (
    <ANTD.Menu>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Private Airport Transfers</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>City Transfers</ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Lounges & Airport Services </ANTD.Checkbox>
      </ANTD.Menu.Item>
      <ANTD.Menu.Item>
        <ANTD.Checkbox>Car Charter</ANTD.Checkbox>
      </ANTD.Menu.Item>
    </ANTD.Menu>
  );

  return (
    <div>
      <div className="promo providerapi"></div>

      <ANTD.Layout className="travel-img">
        <Content className="admin-container cms-pages-width activity-cms">
          <ANTD.Row className="">
            <ANTD.Col md={24} xs={24} className="categories">
              <h4>Categories</h4>
              <ANTD.Form.Item style={{ marginBottom: "5px" }}>
                <ANTD.Checkbox>
                  Activities & Experiences
                  <ANTD.Dropdown overlay={menunature} trigger={["click"]}>
                    <DownOutlined className="drop-icon" />
                  </ANTD.Dropdown>
                </ANTD.Checkbox>
              </ANTD.Form.Item>
              &nbsp;
              <ANTD.Button className="view-all-btn">View All</ANTD.Button>
            </ANTD.Col>{" "}
            {/*--col---md--6--close--*/}
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
    </div>
  );
};

export default Holidaysfiltersbox;
