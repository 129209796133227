export const getFareType = (num) => {
  // switch (num) {
  //   case 1:
  //     return "Publish";
  //   case 31:
  //     return "General";
  //   case 21:
  //     return "CouponFare";
  //   case 33:
  //     return "CorpoorateFare";
  //   case 34:
  //     return "SMEFare";
  //   default:
  //     return "";
  // }

  switch (num) {
    case 1:
        return "General";
      case 2:
        return "SME";
      case 3:
        return "Discounted";
      case 4:
        return "Corporate";
      case 5:
        return "NonRefundable";
      case 6:
          return "VFR";
      case 7:
          return "Smart";
      case 8:
            return "Stream";
      case 9:
            return "Redeemer";
      case 10:
            return "Boost";
      case 11:
            return "Trading";
      case 12: 
         return "Supreme6E"
      default:
        return "";
  }
};

export const getCabinType = (num) => {
  switch (num) {
    case 1:
      return "Economy Premium";
    case 2:
      return "Business";
    case 3:
      return "First Class";
    case 4:
      return "Economy";
    default:
      return "";
  }
};

export const getRoleType = (num) => {
  if (num === 2) {
    return "Users";
  } else if (num === 4) {
    return "Guest";
  } else if (num === 5) {
    return "Agent";
  }
};

export const getTypeSign = (num) => {
  if (num === 1) {
    return "%";
  }
  return "";
};

export const getBusType = (num) => {
  if (num == 1) {
    return "RTC Buses";
  } else if (num == 2) {
    return " Private Travels";
  }
};
