import React, { useState, useEffect } from "react";
import { Button, Card, Radio, Input, Select } from "antd";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "./ActivitySort.scss";

const { Option } = Select;
const ActivitySort = ({
  toursData,
  setToursData,
  showNetFare,
  setShowNetFare,
}) => {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [sorting, setSorting] = useState("price");
  const [searchBy, setSearchBy] = useState("tour");
  const [sortDirection, setSortDirection] = useState("asc");
  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortTours(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(dir);
      sortTours(selSorting, dir);
    }
  };

  useEffect(() => {
    sortTours("price", "asc");
  }, []);

  const sortTours = (selSorting, dir) => {
    let newlist = toursData.sort((tourA, tourB) => {
      if (selSorting === "name") {
        return dir === "asc"
          ? compareTours(tourA.tourName, tourB.tourName, selSorting)
          : compareTours(tourB.tourName, tourA.tourName, selSorting);
      } else if (selSorting === "rating") {
        return dir === "asc"
          ? compareTours(tourA.rating, tourB.rating, selSorting)
          : compareTours(tourB.rating, tourA.rating, selSorting);
      } else if (selSorting === "price") {
        return dir === "asc"
          ? compareTours(
              Math.round(tourA.amount),
              Math.round(tourB.amount),
              selSorting
            )
          : compareTours(
              Math.round(tourB.amount),
              Math.round(tourA.amount),
              selSorting
            );
      } else if (selSorting === "review") {
        return dir === "asc"
          ? compareTours(tourA.reviewCount, tourB.reviewCount, selSorting)
          : compareTours(tourB.reviewCount, tourA.reviewCount, selSorting);
      }
    });

    setToursData([...newlist]);
  };

  const compareTours = (a, b, selSorting) => {
    if (
      selSorting === "name" ||
      selSorting === "price" ||
      selSorting === "review"
    ) {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  const onSearch = (val) => {
    applyFilters(val);
  };

  const applyFilters = (text) => {
    let visibleCount = 0;

    let data = toursData.map((tours) => {
      let isVisible = true;
      if (
        tours.tourName?.toLowerCase().indexOf(text.toLowerCase()) === -1 &&
        tours.cityTourType?.toLowerCase().indexOf(text.toLowerCase()) === -1
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...tours, isVisible: isVisible };
    });
    data = data.filter((item) => item.isVisible);
    setToursData(data);
  };

  return (
    <div className="activity-sort-block sort-block">
      <Card>
        <div className="results-sort-block">
          <div className="flex-wrapper">
            <p className="sort-text">Sort by:</p>
            <Radio.Group onChange={handleChange} value={sorting}>
              <Radio value={"name"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "name" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Name
                    </p>
                  </div>
                </span>
              </Radio>
              <Radio value={"price"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "price" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Price
                    </p>
                    {/* <p className="total-fare-filter">
                    
                  </p> */}
                  </div>
                </span>
              </Radio>
              <Radio value={"rating"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "rating" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Rating
                    </p>
                  </div>
                </span>
              </Radio>
              <Radio value={"review"} onClick={handleClick}>
                <span className="checkmark">
                  <div className="active-background">
                    <p className="price-type">
                      <span>
                        {sorting === "review" ? getSortIcon(sortDirection) : ""}
                      </span>
                      Review
                    </p>
                  </div>
                </span>
              </Radio>
            </Radio.Group>
          </div>
          <div className='flex-wrapper rightsidebox'>
            <Input.Group compact>
              <Select
                defaultValue="tour"
                className="search-type-box"
                onSelect={(val) => setSearchBy(val)}
              >
                <Option value="tour">
                  <i className="fa fa-building-o" aria-hidden="true"></i>
                </Option>

                <Option value="loc">
                  <i className="fa fa-map-marker" aria-hidden="true"></i>
                </Option>
              </Select>

              <Select
                showSearch
                className="search-select-box"
                placeholder={`Search By ${
                  searchBy === "tour" ? "Name" : "Tour Type"
                }`}
                onSelect={onSearch}
                allowClear={true}
                onClear={() => onSearch("")}
                filterOption={(input, option) =>
                  option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {toursData.map((tour, i) => {
                  return searchBy === "tour" ? (
                    <Option key={"byName" + i} value={tour.tourName}>
                      <i className="fa fa-building-o" aria-hidden="true"></i>{" "}
                      {tour.tourName}
                    </Option>
                  ) : (
                    <Option key={"TourType" + i} value={tour.cityTourType}>
                      <i className="fa fa-map-marker" aria-hidden="true"></i>{" "}
                      {tour.cityTourType}
                    </Option>
                  );
                })}
              </Select>
            </Input.Group>
            {agent && user?.Role?.RoleLevel === 3 ? (
              <div
                className="netfareButton ml-1"
                onClick={() => setShowNetFare(!showNetFare)}
              >
                <Button> {showNetFare ? "Hide" : "Show"} Net Fare</Button>
              </div>
            ) : null}
          </div>
        </div>
      </Card>
    </div>
  );
};

export default ActivitySort;
