import React from "react";
import { Col, Row } from "antd";
import "./ActivitiesSearch.scss";
import Holidaysplaces from "../../../components/Holidaysplaces/Holidaysplaces";
const ActivitiesSearchBox = (props) => {
  return (
    <div className="hotels_search_box activity-searchbox">
      <div className="hotels-wrapper">
        <Row>
          <Col span={24}>
            <div className="hotel_search">
              <Holidaysplaces
                gscontext={props.gscontext}
                getBusAvailabilityData
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ActivitiesSearchBox;
