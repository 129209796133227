import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Table,
} from "antd";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";

import {
  EditOutlined,
  PlusCircleFilled,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Option } = Select;
const BlogComments = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [blogList, setBogList] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [blogCommentId, setBlogCommentID] = useState(null);
  const [form] = Form.useForm();

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };

  const showModal = () => {
    if (isEdit) {
      setIsEdit(false);
      form.resetFields();
    }
    setModalVisible({
      visible: true,
    });
  };

  const editBlogComment = (record) => {
    setIsEdit(true);
    setBlogCommentID(record.BlogCommentsID);

    form.setFieldsValue({
      BlogID: record.BlogID,
      Comment: record.Comment,
      Name: record.Name,
      Email: record.Email,
      Website: record.Website,
      ShowComment: record.ShowComment,
    });

    setModalVisible(true);
  };

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              editBlogComment(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteBlogComment(rec.BlogCommentsID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "Sno",
    },

    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },
    {
      title: "Website",
      dataIndex: "Website",
    },
    {
      title: "Comment",
      dataIndex: "Comment",
    },
    {
      title: "Show Comment",
      dataIndex: "ShowComment",
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
  ];

  const selectCustomFilter = (input, option) => {
    let istext =
      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    if (istext) {
      return true;
    }
  };

  /*==========Api Calls=====*/

  useEffect(() => {
    getBlogComments();
    getBlogs();
  }, []);

  const getBlogComments = () => {
    ApiClient.get("admin/blogcomments")
      .then((res) => {
        if (res.statusCode == 200) {
          const data = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
            };
          });
          setDataSource(data);
        } else {
          message("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const getBlogs = () => {
    ApiClient.get("admin/blog")
      .then((res) => {
        if (res.statusCode == 200) {
          setBogList(res.data);
        } else {
          message("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteBlogComment = (id) => {
    ApiClient.delete("admin/blogcomments/" + id)
      .then((res) => {
        if (res.statusCode == 200) {
          message.success("Blog Comment Deleted  successfully", 3);
          getBlogComments();
        } else {
          message.error("Error Deleting", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    let formData = {
      BlogID: values.BlogID,
      Comment: values.Comment,
      Name: values.Name,
      Email: values.Email,
      Website: values.Website,
      ShowComment: values.ShowComment,
      CreatedBy: 0,
      ModifiedBy: 0,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 1,
      FirBaseToken: "string",
    };

    isEdit ? putBlogComment(formData) : postBlogComment(formData);
  };

  const putBlogComment = (formData) => {
    ApiClient.put("admin/blogcomments/" + blogCommentId, {}, formData).then(
      (res) => {
        if (res.statusCode == 200) {
          message.success("Blog comment Updated  successfully", 3);
          setModalVisible(false);
          form.resetFields();
          getBlogComments();
        } else {
          message.error(" Error Uploading Data", 3);
        }
      }
    );
  };

  const postBlogComment = (formData) => {
    ApiClient.post("admin/blogcomments", formData).then((res) => {
      if (res.statusCode == 200) {
        message.success("Blog comment added successfully", 3);
        getBlogComments();
        setModalVisible(false);
        form.resetFields();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getBlogComments();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = (e) => {
    const excelCustomData = dataSource;

    props.exportExcel(excelCustomData, "dealsExcel");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Blog Comments{" "}
                          <HelpInfoHelper screenName={"/admin/commentsblog"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getBlogComments()}
                      />
                    </div>
                  </div>
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={isEdit ? "Update Blog Comment" : "Add Blog Comment"}
        className="promo-modal-header"
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        width={"1100px"}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            {isEdit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          {...formItemLayout}
          layout="vertical"
          onFinish={submitForm}
        >
          <Row>
            <Col md={8} xs={24}>
              <Form.Item
                label="Blog Name"
                name="BlogID"
                rules={[
                  { required: true, message: "From Country is required" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Blog"
                  filterOption={(val, op) => selectCustomFilter(val, op)}
                >
                  {blogList.map((i) => (
                    <Option value={i.BlogID}>{i.BlogTitle}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Name"
                name="Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                  },
                  { type: "email", message: "Email is not valid email" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Website"
                name="Website"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} xs={24}>
              <Form.Item
                label="Comment"
                name="Comment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col md={8} xs={24}>
              <Form.Item
                label="Show Comment"
                name="ShowComment"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(BlogComments);
