import React from "react";
import "../Visasearch/Visasearch.scss";
import Banner from "../../../components/banner/Banner";
import Holidays from "../../../components/Holidaysplaces/Holidaysplaces.scss";
import Holidaysplaces from "../../../components/Holidaysplaces/Holidaysplaces";
import Visaallplaces from "../../../components/Visaplacesall/Visaplacesall";

import Subscribe from "../../../components/HomeCarousel/HomeCarousel";
/* import * as ReactBoostrap from "react-bootstrap"; */

import { Button } from "antd";
import * as ANTD from "antd";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";

const VisaSearch = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { TabPane } = ANTD.Tabs;

  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#0775e2" }}
        onClick={onClick}
      />
    );
  };
  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#0775e2" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    rows: 2,
    // slidesPerRow: 2,
    infinite: true,
    pauseOnHover: true,
    // arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  return (
    <div className="holidays_search_container">
      {/* <section className="holidays_banner">
        <div className="heading_text">
       <h2>We make visas easy for you</h2>
        </div>
        <div>
          <Banner />
        </div>
        <div className="hotel_search">
        <Visaallplaces gscontext={props.gscontext} getBusAvailabilityData />
        </div>
      </section> */}

      {/* second--row--start */}

      <div className="promo providerapi visa-search-1">
        <ANTD.Layout className="holidays-search-apps">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <h4 className="visa-poplar mt-3">Popular Destinations</h4>

              {/*--start----end--*/}

              <ANTD.Row>
                <ANTD.Col md={4} xs={24}>
                  <Link to="/dubaivisa">
                    <ANTD.Card
                      className="visa-card orange-color"
                      bordered={false}
                    >
                      <h3 className="visa-pkg-name">
                        Dubai<span>Asia</span>
                      </h3>
                      <p className="visa-pkg-price">₹ 2,499/-</p>
                    </ANTD.Card>
                  </Link>
                </ANTD.Col>

                <ANTD.Col md={4} xs={24}>
                  <ANTD.Card
                    className="visa-card violet-color"
                    bordered={false}
                  >
                    <h3 className="visa-pkg-name">
                      Malaysia<span>Asia</span>
                    </h3>
                    <p className="visa-pkg-price">₹ 1,999/-</p>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={4} xs={24}>
                  <ANTD.Card className="visa-card blue-color" bordered={false}>
                    <h3 className="visa-pkg-name">
                      Singapore<span>Asia</span>
                    </h3>
                    <p className="visa-pkg-price">₹ 2,499/-</p>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={4} xs={24}>
                  <ANTD.Card
                    className="visa-card yellow-color"
                    bordered={false}
                  >
                    <h3 className="visa-pkg-name">
                      Srilanka<span>Asia</span>
                    </h3>
                    <p className="visa-pkg-price">₹ 4,499/-</p>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={4} xs={24}>
                  <ANTD.Card
                    className="visa-card orange-color"
                    bordered={false}
                  >
                    <h3 className="visa-pkg-name">
                      Thailand<span>Asia</span>
                    </h3>
                    <p className="visa-pkg-price">₹ 5,499/-</p>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={4} xs={24}>
                  <ANTD.Card
                    className="visa-card violet-color"
                    bordered={false}
                  >
                    <h3 className="visa-pkg-name">
                      Australia<span>Asia</span>
                    </h3>
                    <p className="visa-pkg-price">₹ 9,499/-</p>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      {/* --first---layout--close-- */}

      <section className="applying-visa">
        <div className="promo providerapi">
          <ANTD.Layout className="holidays-search-apps">
            <div className="">
              <Content className="admin-container cms-pages-width">
                <ANTD.Row>
                  <ANTD.Col md={24} xs={24}>
                    <h3 className="simple-visa">
                      Applying with Trusgo is this simple
                    </h3>
                  </ANTD.Col>
                </ANTD.Row>

                <ANTD.Row>
                  <ANTD.Col md={8} xs={24}>
                    <img
                      className="visa-data-img"
                      src={
                        require("../../../assets/images/visa/visa-t1.png")
                          .default
                      }
                      alt=""
                    />
                    <p className="visa-img-process">
                      Submit documents and pay online
                    </p>
                  </ANTD.Col>

                  <ANTD.Col md={8} xs={24}>
                    <img
                      className="visa-data-img"
                      src={
                        require("../../../assets/images/visa/visa-t2.png")
                          .default
                      }
                      alt=""
                    />
                    <p className="visa-img-process">
                      We verify documents & process your Visa application
                    </p>
                  </ANTD.Col>

                  <ANTD.Col md={8} xs={24}>
                    <img
                      className="visa-data-img"
                      src={
                        require("../../../assets/images/visa/visa-t3.png")
                          .default
                      }
                      alt=""
                    />
                    <p className="visa-img-process">Receive Visa</p>
                  </ANTD.Col>
                </ANTD.Row>
              </Content>
            </div>
          </ANTD.Layout>
        </div>
      </section>

      {/* --section---layout--close-- */}

      <div className="promo providerapi choose-section">
        <ANTD.Layout className="holidays-search-apps">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <h4 className="visa-poplar">Why choose us?</h4>

              <ANTD.Row>
                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p1.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">
                    Visa Services for all Countries
                  </p>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p2.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">
                    40 years of experience in Visa processing
                  </p>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p3.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">150+ Branches Worldwide</p>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row>
                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p4.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">End-to-End Visa Assistance</p>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p5.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">
                    Pick Up & Drop of Documents from your Doorstep
                  </p>
                </ANTD.Col>

                <ANTD.Col md={8} xs={24}>
                  <img
                    className="visa-data-img"
                    src={
                      require("../../../assets/images/visa/visa-p6.png").default
                    }
                    alt=""
                  />
                  <p className="visa-img-process">Safety & Confidentiality</p>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <ANTD.Row>
                <ANTD.Col md={24} xs={24}>
                  <ANTD.Tabs defaultActiveKey="1" className="all-tabs-country">
                    <TabPane tab="Asia" key="1">
                      <ANTD.Row>
                        <ANTD.Col md={24} xs={24}>
                          <ul className="central-asia">
                            <li>
                              <a href="">Tajikistan</a>
                            </li>
                            <li>
                              <a href="">Uzbekistan</a>
                            </li>
                            <li>
                              <a href="">Kazakhstan</a>
                            </li>
                            <li>
                              <a href="">Turkmenistan</a>
                            </li>
                            <li>
                              <a href="">Kyrgyzstan</a>
                            </li>
                          </ul>
                        </ANTD.Col>
                      </ANTD.Row>
                      <ANTD.Row className="asia-two">
                        <ANTD.Col md={24} xs={24}>
                          <ul className="central-asia">
                            <li>
                              <a href="">China</a>
                            </li>
                            <li>
                              <a href="">North Korea</a>
                            </li>
                            <li>
                              <a href="">South Korea</a>
                            </li>
                            <li>
                              <a href="">Japan</a>
                            </li>
                            <li>
                              <a href="">Hong kong</a>
                            </li>
                            <li>
                              <a href="">Taiwan</a>
                            </li>
                          </ul>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>
                    <TabPane tab="Europe" key="2">
                      Content of Tab Pane 2
                    </TabPane>
                    <TabPane tab="North America" key="3">
                      Content of Tab Pane 3
                    </TabPane>
                    <TabPane tab="South America" key="4">
                      Content of Tab Pane 3
                    </TabPane>
                    <TabPane tab="Australia" key="5">
                      Content of Tab Pane 3
                    </TabPane>
                    <TabPane tab="Africa" key="6">
                      Content of Tab Pane 3
                    </TabPane>
                    <TabPane tab="Gulf" key="7">
                      Content of Tab Pane 3
                    </TabPane>
                  </ANTD.Tabs>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>
    </div>
  );
};

export default VisaSearch;
