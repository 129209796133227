import React, { useState } from "react";
import {
  Card,
  Form,
  Input,
  Button,
  Row,
  Col,
  Radio,
  Select,
  DatePicker,
  message,
  Modal,
  InputNumber,
} from "antd";
import { Helmet } from "react-helmet";
import ReCAPTCHA from "react-google-recaptcha";
import CountryList from "../../common/CountryList";
import APIClient from "../../helpers/ApiClient";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { AppConstants } from "../../helpers/constants";
import { useHistory } from "react-router-dom";

const BusHire = () => {
  const { Option } = Select;
  const { TextArea } = Input;
  const history = useHistory();
  const [tripType, setTriptype] = useState("local");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (value) => {
    let req = {
      ServiceType: 8,
      CarType: 0,
      CarCapacity: 0,
      Occasion: value.Occasion,
      BusType: value.BusType,
      VisitPurpose: 0,
      BookingCurrencyFor: 0,
      Name: value.Name,
      Email: value.Email,
      ISDCode: value.ISDCode,
      ContactNumber: value.ContactNumber,
      AlternateNumber: value.AlternateNumber ? value.AlternateNumber : "",
      TripType: value.TripType,
      SourceCity: value.SourceCity,
      DestinationCity: value.DestinationCity ? value.DestinationCity : "",
      PreferredClass: "",
      DepartureDate: value.DepartureDate,
      DepartureTime: "",
      PickUpLocation: "",
      DropLocation: "",
      EndDate: value.EndDate,
      EndTime: "",
      ReturnDate: "",
      ReturnTime: "",
      OnwardFlightNumber: "",
      OnwardFilghtName: "",
      ReturnFlightNumber: "",
      ReturnFilghtName: "",
      NumberOfPassengers: value.NumberOfPassengers,
      Remarks: value.Remarks,
      CreatedBy: 0,
      ModifiedBy: 0,
      NumberOfBuses: value.NumberOfBuses,
      Currency: "",
      Quantity: "",
      Amount: 0,
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          // setVisible(true);
          setTriptype("local");
          history.push("/Thankyou");
          // form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };

  const handleOk = () => {
    setVisible(false);
  };

  const onTripTypeChange = (e) => {
    setTriptype(e.target.value);
  };

  return (
    <div className="contact_wrapper from-contact-24">
      <Helmet>
        <title>Luxury Bus on Rent - {AppConstants.DOMAIN_NAME}</title>
        <meta
          name="description"
          content="ShopYourTrip offers best Luxury Volvo Bus Rental Services. Traveling with family just got fun. We have 45 and 49-seater. Book Now."
        />
      </Helmet>
      <Row justify="center">
        <Col lg={14} md={20} xs={24}>
          <div className="inner_wrapper">
            <div className="contact_header">
              <h2>Bus Hire</h2>
            </div>
            <Form
              layout="vertical"
              name="ContactUsForm"
              form={form}
              initialValues={{ ISDCode: "+91", TripType: "local" }}
              validateMessages={validateMessages}
              onFinish={submitForm}
            >
              <Card bordered={false}>
                <Row gutter={16}>
                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Name "
                      name="Name"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <Input placeholder="Enter Name" />
                    </Form.Item>
                  </Col>

                  <Col md={8} sm={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="ContactNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              // placeholder="Select Country"
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[
                        { required: true, message: "Required" },
                        {
                          type: "email",
                          message: "Email is not a valid email",
                        },
                      ]}
                    >
                      <Input type="email" placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={24}>
                    <Form.Item
                      label="Alternate  Contact Number"
                      name="AlternateNumber"
                      rules={[
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        style={{ width: "100%" }}
                        placeholder="Enter Number"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <Radio.Group name="Triptype" onChange={onTripTypeChange}>
                    <Radio value="local">Local </Radio>
                    <Radio value="outstation">Out Station </Radio>
                  </Radio.Group>
                  <div className="card-gap">
                    <Row gutter={16}>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="From City"
                          name="SourceCity"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Input placeholder="From City" />
                        </Form.Item>
                      </Col>
                      {tripType === "outstation" && (
                        <Col md={8} sm={12} xs={24}>
                          <Form.Item
                            label="To City"
                            name="DestinationCity"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <Input placeholder="To City" />
                          </Form.Item>
                        </Col>
                      )}
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="From Date"
                          name="DepartureDate"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker className="dateStyle" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="To Date"
                          name="EndDate"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <DatePicker className="dateStyle" />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Bus Type "
                          name="BusType"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Bus type">
                            <Option value={1}>Ac Seating</Option>
                            <Option value={2}>Non AC Seating</Option>
                            <Option value={3}>Ac Sleeper</Option>
                            <Option value={4}>Non AC Sleeper</Option>
                            <Option value={5}>Multi Axle Volvo</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="Occasion "
                          name="Occasion"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <Select placeholder="Occasion">
                            <Option value={1}>School Trip</Option>
                            <Option value={2}>Marriage</Option>
                            <Option value={3}>Personal Trip</Option>
                            <Option value={4}>Office Trip</Option>
                            <Option value={5}>Others </Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="No. of Buses"
                          name="NumberOfBuses"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <InputNumber
                            placeholder="No. of Buses"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={8} sm={12} xs={24}>
                        <Form.Item
                          label="No. of Pax"
                          name="NumberOfPassengers"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <InputNumber
                            placeholder="No. of Pax"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={16} sm={12} xs={24}>
                        <Form.Item
                          label="Your Message"
                          name="Remarks"
                          rules={[{ required: true, message: "Required" }]}
                        >
                          <TextArea rows={1} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row justify="center" className="my-3">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        onChange={handleCaptcha}
                      />
                    </Row>

                    <Form.Item className="center">
                      <Button
                        disabled={buttonDisabled}
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </div>
                </Card>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
      {/* <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={handleOk}
        onCancel={handleOk}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6 className="text-white">
                Your message has been successfully sent
              </h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
          <Col>
            <div className="socialLinks">
              <span className="sharetext">Share at</span>
              <span>
                <FacebookOutlined />
              </span>
              <span>
                <TwitterOutlined />
              </span>
              <span>
                <LinkedinOutlined />
              </span>
              <span>
                <MailOutlined />
              </span>
            </div>
          </Col>
        </Row>
      </Modal> */}
    </div>
  );
};

export default BusHire;
