import React, { createContext, useEffect, useState, useContext } from "react";
import APIClient from "../../helpers/ApiClient";
import { useAuthContext } from "./AuthProvider";
const SytContext = createContext();

export function useSytContext() {
  return useContext(SytContext);
}

function SytProvider({ children }) {
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();

  const [logo, setLogo] = useState("");
  const [agentLogo, setAgentLogo] = useState(null);
  const [blog, setBlog] = useState([]);
  const [siteDetails, setSiteDetails] = useState({
    BusinessAddress: [],
    facebook: "https://www.facebook.com/",
    youtube: "https://www.youtube.com/",
    twitter: "https://twitter.com/",
    instagram: "https://www.instagram.com/",
  });

  const getSocialLinks = () => {
    let userID = 1;
    APIClient.get(`admin/settings/${userID}`)
      .then((res) => {
        if (res.status == 200) {
          const { settings } = res.data;

          let address = [];

          if (settings?.BusinessAddress?.length > 0) {
            address = settings?.BusinessAddress;
          }

          setSiteDetails((prev) => ({
            ...prev,
            BusinessAddress: address,
            facebook: settings.userSocialDetails.FacebookUrl,
            youtube: settings.userSocialDetails.Youtube,
            twitter: settings.userSocialDetails.Twitter,
            instagram: settings.userSocialDetails.Instagram,
          }));
        }
      })
      .catch((error) => {});
  };

  const [banners, setBanners] = useState({
    flightBanners: [],
    busBanners: [],
    hotelBanners: [],
    activityBanners: [],
    packBanners: [],
    agentLogos: [],
    flightSearchBanners: [],
    busSearchBanners: [],
    hotelSearchBanners: [],
    activitySearchBanners: [],
    packSearchBanners: [],
    footerBanners: [],
    bigHomeSliderBanners: [],
    smallHomeSliderBanners: [],
  });

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let flightBanners = [];
          let busBanners = [];
          let hotelBanners = [];
          let activityBanners = [];
          let packBanners = [];
          let agentLogos = [];

          let flightSearchBanners = [];
          let busSearchBanners = [];
          let hotelSearchBanners = [];
          let activitySearchBanners = [];
          let packSearchBanners = [];
          let footerBanners = [];
          let bigHomeSliderBanners = [];
          let smallHomeSliderBanners = [];

          let logo = res.data.filter((item) => item.BannerType === 1);
          if (logo.length > 0 && logo[0].images.length > 0) {
            setLogo(logo[0].images[0].path);
          }
          let agentLogo = res.data.filter((item) => item.BannerType === 6);
          if (agentLogo.length > 0 && agentLogo[0].images.length > 0) {
            agentLogos = agentLogo[0].images;
          }

          let flightList = res.data.filter((item) => item.BannerType === 7);
          if (flightList.length > 0 && flightList[0].images.length > 0) {
            flightBanners = flightList[0].images;
          }

          let flightSearchList = res.data.filter(
            (item) => item.BannerType === 13
          );
          if (
            flightSearchList.length > 0 &&
            flightSearchList[0].images.length > 0
          ) {
            flightSearchBanners = flightSearchList[0].images;
          }

          let busList = res.data.filter((item) => item.BannerType === 8);
          if (busList.length > 0 && busList[0].images.length > 0) {
            busBanners = busList[0].images;
          }

          let busSearchList = res.data.filter((item) => item.BannerType === 14);
          if (busSearchList.length > 0 && busSearchList[0].images.length > 0) {
            busSearchBanners = busSearchList[0].images;
          }

          let hotelList = res.data.filter((item) => item.BannerType === 9);
          if (hotelList.length > 0 && hotelList[0].images.length > 0) {
            hotelBanners = hotelList[0].images;
          }

          let hotelSearchList = res.data.filter(
            (item) => item.BannerType === 15
          );
          if (
            hotelSearchList.length > 0 &&
            hotelSearchList[0].images.length > 0
          ) {
            hotelSearchBanners = hotelSearchList[0].images;
          }

          let activityList = res.data.filter((item) => item.BannerType === 10);
          if (activityList.length > 0 && activityList[0].images.length > 0) {
            activityBanners = activityList[0].images;
          }

          let activitySearchList = res.data.filter(
            (item) => item.BannerType === 16
          );
          if (
            activitySearchList.length > 0 &&
            activitySearchList[0].images.length > 0
          ) {
            activitySearchBanners = activitySearchList[0].images;
          }

          let packageList = res.data.filter((item) => item.BannerType === 11);
          if (packageList.length > 0 && packageList[0].images.length > 0) {
            packBanners = packageList[0].images;
          }

          let packageSearchList = res.data.filter(
            (item) => item.BannerType === 17
          );
          if (
            packageSearchList.length > 0 &&
            packageSearchList[0].images.length > 0
          ) {
            packSearchBanners = packageSearchList[0].images;
          }

          let footerBannerList = res.data.filter(
            (item) => item.BannerType === 20
          );
          if (
            footerBannerList.length > 0 &&
            footerBannerList[0].images.length > 0
          ) {
            footerBanners = footerBannerList[0].images;
          }

          let bigHomeSliderList = res.data.filter(
            (item) => item.BannerType === 21
          );
          if (
            bigHomeSliderList.length > 0 &&
            bigHomeSliderList[0].images.length > 0
          ) {
            bigHomeSliderBanners = bigHomeSliderList[0].images;
          }
          let smallHomeSliderList = res.data.filter(
            (item) => item.BannerType === 22
          );
          if (
            smallHomeSliderList.length > 0 &&
            smallHomeSliderList[0].images.length > 0
          ) {
            smallHomeSliderBanners = smallHomeSliderList[0].images;
          }

          setBanners({
            flightBanners,
            busBanners,
            hotelBanners,
            activityBanners,
            packBanners,
            agentLogos,
            flightSearchBanners,
            busSearchBanners,
            hotelSearchBanners,
            activitySearchBanners,
            packSearchBanners,
            footerBanners,
            bigHomeSliderBanners,
            smallHomeSliderBanners,
          });
        }
      })
      .catch(() => {});
  };

  const getBlogs = () => {
    APIClient.get("admin/blog")
      .then((res) => {
        if (res.statusCode == 200) {
          let data = res.data
            .filter((item) => item.BlogStatus === 1)
            .map((item) => ({
              ...item,
              urlTitle: item.BlogTitle ? item.BlogTitle.replace(/ /g, "") : "",
            }));

          setBlog(data);
        } else {
          setBlog([]);
        }
      })
      .catch((error) => {
        setBlog([]);
      });
  };

  const handleAgentLogo = (agentLogos) => {
    if (agent && user && agentLogos?.length > 0) {
      let logo = agentLogos.filter((item) => item.UserId === user?.UserID);

      if (logo.length > 0) {
        setAgentLogo(logo[0].path);
      } else {
        setAgentLogo(null);
      }
    } else {
      setAgentLogo(null);
    }
  };

  useEffect(() => {
    getBannersList();
    getSocialLinks();
    getBlogs();
  }, []);

  useEffect(() => {
    if (agent && user) {
      handleAgentLogo(banners.agentLogos);
    }
  }, [agent, user, banners.agentLogos]);
  return (
    <SytContext.Provider
      value={{
        logo,
        blog,
        flightBanners: banners.flightBanners,
        busBanners: banners.busBanners,
        hotelBanners: banners.hotelBanners,
        activityBanners: banners.activityBanners,
        packBanners: banners.packBanners,
        agentLogo,
        flightSearchBanners: banners.flightSearchBanners,
        busSearchBanners: banners.busSearchBanners,
        hotelSearchBanners: banners.hotelSearchBanners,
        activitySearchBanners: banners.activitySearchBanners,
        packSearchBanners: banners.packSearchBanners,
        footerBanner: banners.footerBanners,
        bigHomeSliderBanners: banners.bigHomeSliderBanners,
        smallHomeSliderBanners: banners.smallHomeSliderBanners,
        getBannersList,
        siteDetails,
      }}
    >
      {children}
    </SytContext.Provider>
  );
}
export default SytProvider;
