import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import excel from "../assets/vector-icons/excel.png";
import exchange from "../assets/vector-icons/exchange.png";
import search from "../assets/vector-icons/search.png";
import APIClient from "../../../src/helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";
import { CloseOutlined, EyeOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useAuthContext } from "../../common/providers/AuthProvider";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import VerifyPasswordModal from "../../common/VerifyPasswordModal/VerifyPasswordModal";

const Siteusers = (props) => {
  const [showSearchBox, setShowSearchBox] = useState(false);
  const { Content } = ANTD.Layout;
  const [showAPIRequestModalvalue, setAPIRequestModalvalue] = useState(false);
  const [travellerInfo, setTravellerInfo] = useState([]);
  const [isLoading , setIsLoading] = useState(false);
  const [siteUsersList, setSiteUsersList] = useState([]);
  const [excelList, setExcelList] = useState([]);
  const [verifyModalVisible, setVerifyModalVisible] = useState(false);
  const [formType, setFormType] = useState(-1);

  const [currentRec, setCurrentRec] = useState({});
  const { user } = useAuthContext();
  useEffect(() => {
    getSiteUseList();
    // getTravellerInfo();
  }, []);

  const getTravellerInfo = (userid) => {
    var UserID = userid;
    setAPIRequestModalvalue(true);
    APIClient.get("admin/user/travellers/" + `${UserID}`)
      .then((response) => {
        if (response.status == 200) {
          setTravellerInfo(response.data.Travellers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getSiteUseList = () => {
    APIClient.get("admin/siteUsers")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
              CreatedDate: moment(item.CreatedDate).format("DD-MM-YYYY"),
              ModifiedDate: moment(item.ModifiedDate).format("DD-MM-YYYY"),
            };
          });

          setSiteUsersList(data);
          defineExcelData(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        UserID: item.UserID,
        Email: item.Email,
        DailingCode: item.DailingCode,
        Mobile: item.Mobile,
        Status: item.Status == 1 ? "InActive" : "Active",
        Currency: item.Currency,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = siteUsersList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item).toLowerCase().includes(searchVal.toLowerCase());
        }
      });
      setSiteUsersList(searchResults);
    } else {
      getSiteUseList();
    }
  };

  const handelStatus = (data) => {
    if (data.Status == 1) {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
          onChange={() => {
            setVerifyModalVisible(true);
            setCurrentRec(data);
            setFormType(1);
          }}
        />
      );
    } else {
      return (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          onChange={() => {
            setVerifyModalVisible(true);
            setCurrentRec(data);
            setFormType(2);
          }}
        />
      );
    }
  };

  const changeStatus = (values, Status) => {
    let UserID = values.UserID;

    let data = {
      ...values,
      Role: values.role.RoleID,
      Status: Status,
      CreatedBy: 0,
      ModifiedBy: user?.UserID,
    };
    UpdateStatus(data, UserID);
  };

  const UpdateStatus = (data, id) => {
    APIClient.put(`admin/user/${id}`, {}, data)
      .then((response) => {
        if (response.status == 200) {
          ANTD.message.success("Details Updated SuccessFully", 3);
          getSiteUseList();
        } else if (response.status == 404) {
          ANTD.message.error("Not Found", 3);
        } else {
          ANTD.message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const closeSearch = () => {
    setIsLoading(false);
    getSiteUseList();
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const APIRequest = (id) => {
    return (
      <span style={{ cursor: "pointer", color: "red" }} id={id} onClick={() => getTravellerInfo(id)}>
        <EyeOutlined />
      </span>
    );
  };
  const Gender = (gender) => {
    if (gender == 0) {
      return <span>Male</span>;
    } else {
      return <span>Female</span>;
    }
  };
  const Title = (title) => {
    if (title == 0) {
      return <span>Mr</span>;
    } else if (title == 1) {
      return <span>Ms</span>;
    } else {
      return <span>Mrs</span>;
    }
  };
  const Preferences = (preference) => {
    if (preference == 0) {
      return <span>Yes</span>;
    } else {
      return <span>No</span>;
    }
  };

  const columnsusers = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
      key: "SNo",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "name",
      sorter: (a, b) => a.Email.localeCompare(b.Email),
    },
    {
      title: "Balance",
      dataIndex: "Balance",
      key: "name",
      sorter: (a, b) => a.Balance.localeCompare(b.Balance),
    },

    {
      title: "Currency",
      dataIndex: "Currency",
      key: "name",
    },

    {
      title: "DailingCode",
      dataIndex: "DailingCode",
      key: "name",
    },

    {
      title: "Mobile",
      dataIndex: "Mobile",
      key: "name",
    },

    {
      title: "TD",
      dataIndex: "UserID",
      render: (userid) => APIRequest(userid),
    },
    {
      title: "Status",
      //
      render: (currentItem) => handelStatus(currentItem),
      // render: (status) => (
      //   <ANTD.Switch
      //     checkedChildren="Active"
      //     unCheckedChildren="Inactive"
      //     defaultChecked={!status}
      //   />
      // ),
    },
    {
      title: "Application Type",
      dataIndex: "DeviceType",
    },
    {
      title: "Created Date",
      dataIndex: "CreatedDate",
    },
    {
      title: "Modified Date",
      dataIndex: "ModifiedDate",
    },
  ];

  const columnssite = [
    {
      title: "Title	",
      dataIndex: "Gender",
      render: (title) => Title(title),
    },
    {
      title: "First Name	",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name	",
      dataIndex: "LastName",
    },
    {
      title: "Gender	",
      dataIndex: "Gender",
      render: (gender) => Gender(gender),
    },
    {
      title: "DOB",
      dataIndex: "DOB",
    },
    {
      title: "Passport Number",
      dataIndex: "PassportNumber",
    },

    {
      title: "NAL",
      dataIndex: "PassengerNationality",
    },

    {
      title: "Passport DOI",
      dataIndex: "PassportDOI",
    },

    {
      title: "Passport DOE",
      dataIndex: "PassportDOE",
    },

    {
      title: "Meal Preference",
      dataIndex: "MealPreference",
      render: (meal) => Preferences(meal),
    },

    {
      title: "Seat Preference",
      dataIndex: "SeatPreference",
      render: (seat) => Preferences(seat),
    },
  ];

  const handleVerifyPassword = (values) => {
    APIClient.post("admin/verifyTransactionPassword", {
      UserID: user.UserID,
      Password: values,
    })
      .then((res) => {
        if (res?.status === 200) {
          setVerifyModalVisible(false);

          ANTD.message.success(res.message);

          if (formType == 1) {
            changeStatus(currentRec, 0);
          } else if (formType == 2) {
            changeStatus(currentRec, 1);
          }
        } else {
          if (res?.message) ANTD.message.error(res.message, 3);
          else ANTD.message.error("Booking Failed", 3);
        }
      })
      .catch();
  };

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <ANTD.Card>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Siteusers Details <HelpInfoHelper screenName={"/admin/siteusers"} />
                        </h5>
                        <p>{siteUsersList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && <ANTD.Input placeholder="Search" onChange={(e) => searchTableData(e)} suffix={closeSearchInput} style={{ padding: "0px 12px" }} />}
                      &nbsp;&nbsp;
                      <img src={search} alt="search" onClick={(e) => searchData(siteUsersList)} />
                      <img src={excel} alt="excel" onClick={(e) => props.exportExcel(excelList, "SiteUsers")} />
                      <img src={exchange} alt="exchange" onClick={() => getSiteUseList()} />
                    </div>
                  </div>

                  <ANTD.Table
                    className="table-scroll-none"
                    style={{ color: "red" }}
                    columns={columnsusers}
                    bordered
                    rowKey={uniqueKey}
                    columns={columnsusers}
                    dataSource={siteUsersList.map((siteUser) => {
                      return siteUser;
                    })}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>

        <ANTD.Modal
          width="900px"
          visible={showAPIRequestModalvalue}
          title="Traveller Details"
          onCancel={(e) => {
            setAPIRequestModalvalue(false);
          }}
          footer={false}
        >
          <div>
            <ANTD.Table
              className="table-scroll-none"
              style={{ color: "red" }}
              columns={columnssite}
              dataSource={travellerInfo.map((info) => {
                return info;
              })}
              pagination={{
                defaultPageSize: 25,
                showSizeChanger: true,
                pageSizeOptions: ["25", "50", "100", "125"],
              }}
            />
          </div>
        </ANTD.Modal>
        {verifyModalVisible && <VerifyPasswordModal visible={verifyModalVisible} setVerifyModalVisible={setVerifyModalVisible} handleVerifyPassword={handleVerifyPassword} />}
      </div>
    </div>
  );
};
export default UpdatedComponent(Siteusers);
