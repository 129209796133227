import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Popconfirm,
  Row,
  Select,
  Table,
  Layout,
  Modal,
  Collapse,
  Radio,
  Switch,
  Upload,
  Checkbox,
  DatePicker,
  Tabs,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

import "./ManageBus.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";

const ManageBus = () => {
  const { Header, Footer, Sider, Content } = Layout;
  const [reportModal, setReportModalView] = useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showTable, setShowTable] = useState(false);
  const [pgModal, setPGDetailsModal] = useState(false);
  const [gstModal, setGSTModal] = useState(false);
  const { Option } = Select;
  const { TabPane } = Tabs;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  /**** Date Picker ****/
  function onDateChange(date, dateString) {
    console.log(date, dateString);
  }

  function callback(key) {
    console.log(key);
  }
  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const [showAminities, setShowAminities] = useState(false);
  const showModal = () => {
    setShowAminities(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setShowAminities(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setShowAminities(false);
  };
  const refNumber = () => {
    return (
      <a href="#!" style={{ textAlign: "center", textDecoration: "underline" }}>
        R-86342342
      </a>
    );
  };
  const GSTDetails = () => {
    return (
      <div
        style={{ textAlign: "center", color: "#1890ff", fontSize: "20px" }}
        onClick={(e) => setGSTModal(true)}
      >
        <EyeOutlined />
      </div>
    );
  };
  const PassportDetails = () => {
    return (
      <div
        style={{ textAlign: "center", color: "#1890ff", fontSize: "20px" }}
        className="edit-icon"
        onClick={(e) => setReportModalView(true)}
      >
        <EyeOutlined />
      </div>
    );
  };
  const PGDetails = () => {
    return (
      <div
        style={{ textAlign: "center", color: "#1890ff", fontSize: "20px" }}
        className="edit-icon"
        onClick={(e) => setPGDetailsModal(true)}
      >
        <EyeOutlined />
      </div>
    );
  };
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;
  function handleChange(value) {
    console.log(`selected ${value}`);
  }
  const tableActions = () => {
    return (
      <div style={{ display: "flex" }}>
        <div
          className="edit-icon"
          style={{ textAlign: "center", marginRight: "15px" }}
        >
          <EditOutlined
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div
          className="edit-icon"
          style={{ textAlign: "center", marginRight: "15px" }}
        >
          <Popconfirm title="Are you sure to delete?">
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
        <Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      </div>
    );
  };

  const dataSource = [
    {
      source: "Hyderabad",
      destination: "Bangalore",
      departuretime: "20:00",
      arrivaltime: "05:00",
      isprimeroute: <Checkbox />,
      isnextday: "True",
      isenabled: "True",
    },
  ];

  const farecolumns = [
    {
      title: "Fare",
      dataIndex: "source",
      key: "1",
    },
    {
      title: "Seatcat",
      dataIndex: "destination",
      key: "2",
    },
    {
      title: "FromDate",
      dataIndex: "departuretime",
      key: "3",
    },
    {
      title: "ToDate",
      dataIndex: "arrivaltime",
      key: "4",
    },
    {
      title: "ApplicableDay",
      dataIndex: "isprimeroute",
      key: "5",
    },
    {
      title: "IsDefaultFare",
      dataIndex: "isnextday",
      key: "6",
    },
  ];
  const fareDataSource = [
    {
      source: "568",
      destination: "Seater",
      departuretime: "18 Oct 2020",
      arrivaltime: "23 Nov 2020",
      isprimeroute: "",
      isnextday: "False",
    },
  ];

  const columns = [
    {
      title: "Source",
      dataIndex: "source",
      key: "1",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      key: "2",
    },
    {
      title: "DepartureTime",
      dataIndex: "departuretime",
      key: "3",
    },
    {
      title: "ArrivalTime",
      dataIndex: "arrivaltime",
      key: "4",
    },
    {
      title: "ArrivalTime",
      dataIndex: "arrivaltime",
      key: "5",
    },
    {
      title: "IsPrimeRoute",
      dataIndex: "isprimeroute",
      key: "6",
    },
    {
      title: "IsNextDay",
      dataIndex: "isnextday",
      key: "7",
    },
    {
      title: "IsEnabled",
      dataIndex: "isenabled",
      key: "8",
    },
  ];
  const [userInfo, setUserInfo] = useState(false);
  const openUserInfo = () => {
    setUserInfo(true);
  };
  const cancellationDataSource = [
    {
      slabhours: "24",
      slabpenalty: "10% Cancellation Charge",
      actions: tableActions(),
      //   status:
    },
  ];

  const cancellationColumn = [
    {
      title: "Slab Hours",
      dataIndex: "slabhours",
      key: "1",
    },
    {
      title: "Slab Penalty",
      dataIndex: "slabpenalty",
      key: "2",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "3",
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: '5',
    // },
  ];
  const boardingColumn = [
    {
      title: "BoardingPointName",
      dataIndex: "boardingpointname",
      key: "1",
    },
    {
      title: "LandMark",
      dataIndex: "landmark",
      key: "2",
    },
    {
      title: "Time",
      dataIndex: "boardingtime",
      key: "3",
    },
    {
      title: "Address",
      dataIndex: "boardingaddress",
      key: "4",
    },
    {
      title: "Contact Number",
      dataIndex: "contactnumber",
      key: "5",
    },
    {
      title: "Is PickUp Van",
      dataIndex: "ispickupvan",
      key: "6",
    },
  ];

  const boardingDataSource = [];
  const droppingColumn = [];
  const droppingDataSource = [];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
  };
  const [fileList, setFileList] = useState([]);

  const onUpload = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };
  function onChangeDate(date, dateString) {
    console.log(date, dateString);
  }
  const operatingcolumns = [
    {
      title: "FromDate",
      dataIndex: "fromDate",
      key: "1",
    },
    {
      title: "ToDate",
      dataIndex: "toDate",
      key: "2",
    },
    {
      title: "OpenDays",
      dataIndex: "openDays",
      key: "3",
    },
    {
      title: "IsEnabled",
      dataIndex: "isEnabled",
      key: "4",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "5",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "6",
    },
    {
      title: "Last Modified By",
      dataIndex: "lastModifiedBy",
      key: "7",
    },
    {
      title: "Last Modified On",
      dataIndex: "lastModifiedOn",
      key: "8",
    },
  ];
  const operatingDataSource = [
    {
      fromDate: "22 Sep 2020",
      toDate: "23 Nov 2020",
      openDays: "19",
      isEnabled: "23 Nov 2020",
      createdBy: "Travel Space",
      createdOn: "23 Nov 2020,01:35 PM",
      lastModifiedBy: "Travel Space",
      lastModifiedOn: "23 Nov 2020,01:35 PM",
    },
  ];

  const busDetailcolumns = [
    {
      title: "Source",
      dataIndex: "busDetailSource",
      key: "1",
    },
    {
      title: "Destination",
      dataIndex: "busDetailDestination",
      key: "2",
    },
    {
      title: "Service No",
      dataIndex: "busDetailServiceNo",
      key: "3",
    },
    {
      title: "Display Name",
      dataIndex: "busDetailDisplayName",
      key: "4",
    },
    {
      title: "Bus  Type",
      dataIndex: "bustype",
      key: "5",
    },
    {
      title: "Seat Type",
      dataIndex: "seatType",
      key: "6",
    },
    {
      title: "Reg No",
      dataIndex: "regNo",
      key: "7",
    },
    {
      title: "Layout Type",
      dataIndex: "layoutType",
      key: "8",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "9",
    },
  ];
  const busDetailDataSource = [
    {
      busDetailSource: "Bangalore",
      busDetailDestination: "Hyderabad",
      busDetailServiceNo: "1996874",
      busDetailDisplayName: "SANGAMITRA",
      bustype: "AC",
      seatType: "Seater",
      regNo: "98754564",
      layoutType: "2+2",
      actions: tableActions(),
    },
  ];
  const fromToData = [
    {
      quotaFor: "Agent",
      quotaType: "Permanent",
      agentName: "Agent one",
      seatNumber: "27,37",
      fromDate: "-",
      toDate: "-",
      relaesedHrs: "3",
      remarks: "",
      createdOn: "24 Nov 2020,12:07 PM",
      modifiedBy: "24 Nov 2020,12:07 PM",
      actions: tableActions(),
    },
  ];
  const fromToColumns = [
    {
      title: "Quota For",
      dataIndex: "quotaFor",
      key: "1",
    },
    {
      title: "Quota Type",
      dataIndex: "quotaType",
      key: "2",
    },
    {
      title: "Partner Name",
      dataIndex: "agentName",
      key: "3",
    },
    {
      title: "Seat Number",
      dataIndex: "seatNumber",
      key: "4",
    },
    {
      title: "From Date",
      dataIndex: "fromDate",
      key: "5",
    },
    {
      title: "Seat Type",
      dataIndex: "seatType",
      key: "6",
    },
    {
      title: "To Date",
      dataIndex: "toDate",
      key: "7",
    },
    {
      title: "Released Hrs",
      dataIndex: "relaesedHrs",
      key: "8",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "8",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "8",
    },
    {
      title: "Last Modified On",
      dataIndex: "modifiedBy",
      key: "8",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "9",
    },
  ];
  /***** Radio Buttons *****/
  const [value, setValue] = React.useState(1);
  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };
  const [type, setType] = React.useState(1);
  const onChangeType = (e) => {
    console.log("radio checked", e.target.value);
    setType(e.target.value);
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <h5>
                      Add Bus Details{" "}
                      <HelpInfoHelper screenName={"/admin/managebus"} />
                    </h5>
                    {/* onClick={handleShow} */}
                    <div className="add-icon e-hide">
                      <i className="fa fa-plus"></i>
                    </div>
                  </div>
                  <Tabs defaultActiveKey="1" onChange={callback}>
                    <TabPane tab="Bus Details" key="1">
                      <div>
                        &nbsp;
                        <Form
                          {...formItemLayout}
                          layout={formLayout}
                          initialValues={{ layout: formLayout }}
                        >
                          <Row gutter={16}>
                            <Col className="gutter-row" md={8} xs={24}>
                              <Form.Item label="Display Name">
                                <Input placeholder="Enter Travells Name" />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Seat Type"
                                rules={[{ required: true }]}
                                name="seaterType"
                              >
                                <Select placeholder="Please Select">
                                  <option>Seater</option>
                                  <option>Sleeper</option>
                                  <option>SemiSleeper</option>
                                  <option>Seater/Sleeper</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Bus Type"
                                rules={[{ required: true }]}
                                name="busType"
                              >
                                <Select placeholder="Please Select">
                                  <option>AC</option>
                                  <option>NonAC</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Service Number"
                                rules={[{ required: true }]}
                                name="serviceNumber"
                              >
                                <Input placeholder="Enter Service Number" />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Service Charge"
                                rules={[{ required: true }]}
                                name="serviceCharge"
                              >
                                <Input placeholder="Enter Service Charge" />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Layout Type"
                                rules={[{ required: true }]}
                                name="layoutType"
                              >
                                <Select placeholder="Please Select">
                                  <option>2+2</option>
                                  <option>2+1</option>
                                  <option>1+1</option>
                                  <option>1+1+1</option>
                                  <option>2+3</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Vehicle Make">
                                <Select placeholder="Please Select">
                                  <option>Espano Divo</option>
                                  <option>Hispano</option>
                                  <option>I-shift</option>
                                  <option>Isuzu</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Other Info">
                                <Select placeholder="Please Select">
                                  <option>Luxury</option>
                                  <option>Super Luxury</option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Gender Restriction">
                                <Switch
                                  checkedChildren="Yes"
                                  unCheckedChildren="No"
                                  defaultChecked
                                />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Aminities">
                                <p onClick={showModal}>Select Aminities</p>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item>
                                <Checkbox onChange={onChange}>mticket</Checkbox>
                                <Checkbox
                                  onChange={onChange}
                                  style={{ marginLeft: "0" }}
                                >
                                  Is Service Tax Applicable
                                </Checkbox>
                                <Checkbox
                                  onChange={onChange}
                                  style={{ marginLeft: "0" }}
                                >
                                  IsPartialCancellation
                                </Checkbox>
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item label="Status">
                                <Switch
                                  checkedChildren="Active"
                                  unCheckedChildren="Inactive"
                                  defaultChecked
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="reports-btns">
                            {/* <div > */}
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={(e) => setShowTable(true)}
                            >
                              Save
                            </Button>
                            {/* </div> */}
                          </Row>
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tab="Route" key="2">
                      <div>
                        &nbsp;
                        <Form
                          {...formItemLayout}
                          layout={formLayout}
                          initialValues={{ layout: formLayout }}
                        >
                          <Row gutter={16}>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Source"
                                rules={[{ required: true }]}
                                name="source"
                              >
                                <Input placeholder="Enter Source" />
                              </Form.Item>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Destination"
                                rules={[{ required: true }]}
                                name="destination"
                              >
                                <Input placeholder="Enter Destination" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row gutter={16}>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Departure Time"
                                rules={[{ required: true }]}
                                name="departuretime"
                                style={{ marginBottom: "0px" }}
                              >
                                <Row>
                                  <Form.Item
                                    label="HH"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Select
                                      defaultValue="01"
                                      style={{
                                        width: 120,
                                        marginRight: "15px",
                                      }}
                                      onChange={handleChange}
                                    >
                                      <Option value="01">01</Option>
                                      <Option value="02">02</Option>
                                      <Option value="03">03</Option>
                                      <Option value="04">04</Option>
                                      <Option value="05">05</Option>
                                      <Option value="06">06</Option>
                                      <Option value="07">07</Option>
                                      <Option value="08">08</Option>
                                      <Option value="09">09</Option>
                                      <Option value="10">10</Option>
                                      <Option value="11">11</Option>
                                      <Option value="12">12</Option>
                                      <Option value="13">13</Option>
                                      <Option value="14">14</Option>
                                      <Option value="15">15</Option>
                                      <Option value="16">16</Option>
                                      <Option value="17">17</Option>
                                      <Option value="18">18</Option>
                                      <Option value="19">19</Option>
                                      <Option value="20">20</Option>
                                      <Option value="21">21</Option>
                                      <Option value="22">22</Option>
                                      <Option value="23">23</Option>
                                      <Option value="24">24</Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item
                                    label="MM"
                                    style={{ marginBottom: "10px" }}
                                  >
                                    <Select
                                      defaultValue="00"
                                      style={{
                                        width: 120,
                                        marginRight: "15px",
                                      }}
                                      onChange={handleChange}
                                    >
                                      <Option value="00">00</Option>
                                      <Option value="01">01</Option>
                                      <Option value="02">02</Option>
                                      <Option value="03">03</Option>
                                      <Option value="04">04</Option>
                                      <Option value="05">05</Option>
                                      <Option value="06">06</Option>
                                      <Option value="07">07</Option>
                                      <Option value="08">08</Option>
                                      <Option value="09">09</Option>
                                      <Option value="10">10</Option>
                                      <Option value="11">11</Option>
                                      <Option value="12">12</Option>
                                      <Option value="13">13</Option>
                                      <Option value="14">14</Option>
                                      <Option value="15">15</Option>
                                      <Option value="16">16</Option>
                                      <Option value="17">17</Option>
                                      <Option value="18">18</Option>
                                      <Option value="19">19</Option>
                                      <Option value="20">20</Option>
                                      <Option value="21">21</Option>
                                      <Option value="22">22</Option>
                                      <Option value="23">23</Option>
                                      <Option value="24">24</Option>
                                      <Option value="25">25</Option>
                                      <Option value="26">26</Option>
                                      <Option value="27">27</Option>
                                      <Option value="28">28</Option>
                                      <Option value="29">29</Option>
                                      <Option value="30">30</Option>
                                      <Option value="31">31</Option>
                                      <Option value="32">32</Option>
                                      <Option value="33">33</Option>
                                      <Option value="34">34</Option>
                                      <Option value="35">35</Option>
                                      <Option value="36">36</Option>
                                      <Option value="37">37</Option>
                                      <Option value="38">38</Option>
                                      <Option value="39">39</Option>
                                      <Option value="40">40</Option>
                                      <Option value="41">41</Option>
                                      <Option value="42">42</Option>
                                      <Option value="43">43</Option>
                                      <Option value="44">44</Option>
                                      <Option value="45">45</Option>
                                      <Option value="46">46</Option>
                                      <Option value="47">47</Option>
                                      <Option value="48">48</Option>
                                      <Option value="49">49</Option>
                                      <Option value="50">50</Option>
                                      <Option value="51">51</Option>
                                      <Option value="52">52</Option>
                                      <Option value="53">53</Option>
                                      <Option value="54">54</Option>
                                      <Option value="55">55</Option>
                                      <Option value="56">56</Option>
                                      <Option value="57">57</Option>
                                      <Option value="58">58</Option>
                                      <Option value="59">59</Option>
                                    </Select>
                                  </Form.Item>
                                </Row>
                              </Form.Item>
                              <p>
                                <Checkbox onChange={onChange}>
                                  Is NextDay
                                </Checkbox>
                              </p>
                              <p>
                                <Checkbox onChange={onChange}>
                                  Is Enabled
                                </Checkbox>
                              </p>
                            </Col>
                            <Col md={8} xs={24}>
                              <Form.Item
                                label="Arrival Time"
                                rules={[{ required: true }]}
                                name="arrivaltime"
                              >
                                <Row>
                                  <Form.Item label="HH">
                                    <Select
                                      defaultValue="01"
                                      style={{
                                        width: 120,
                                        marginRight: "15px",
                                      }}
                                      onChange={handleChange}
                                    >
                                      <Option value="01">01</Option>
                                      <Option value="02">02</Option>
                                      <Option value="03">03</Option>
                                      <Option value="04">04</Option>
                                      <Option value="05">05</Option>
                                      <Option value="06">06</Option>
                                      <Option value="07">07</Option>
                                      <Option value="08">08</Option>
                                      <Option value="09">09</Option>
                                      <Option value="10">10</Option>
                                      <Option value="11">11</Option>
                                      <Option value="12">12</Option>
                                      <Option value="13">13</Option>
                                      <Option value="14">14</Option>
                                      <Option value="15">15</Option>
                                      <Option value="16">16</Option>
                                      <Option value="17">17</Option>
                                      <Option value="18">18</Option>
                                      <Option value="19">19</Option>
                                      <Option value="20">20</Option>
                                      <Option value="21">21</Option>
                                      <Option value="22">22</Option>
                                      <Option value="23">23</Option>
                                      <Option value="24">24</Option>
                                    </Select>
                                  </Form.Item>
                                  <Form.Item label="MM">
                                    <Select
                                      defaultValue="00"
                                      style={{
                                        width: 120,
                                        marginRight: "15px",
                                      }}
                                      onChange={handleChange}
                                    >
                                      <Option value="00">00</Option>
                                      <Option value="01">01</Option>
                                      <Option value="02">02</Option>
                                      <Option value="03">03</Option>
                                      <Option value="04">04</Option>
                                      <Option value="05">05</Option>
                                      <Option value="06">06</Option>
                                      <Option value="07">07</Option>
                                      <Option value="08">08</Option>
                                      <Option value="09">09</Option>
                                      <Option value="10">10</Option>
                                      <Option value="11">11</Option>
                                      <Option value="12">12</Option>
                                      <Option value="13">13</Option>
                                      <Option value="14">14</Option>
                                      <Option value="15">15</Option>
                                      <Option value="16">16</Option>
                                      <Option value="17">17</Option>
                                      <Option value="18">18</Option>
                                      <Option value="19">19</Option>
                                      <Option value="20">20</Option>
                                      <Option value="21">21</Option>
                                      <Option value="22">22</Option>
                                      <Option value="23">23</Option>
                                      <Option value="24">24</Option>
                                      <Option value="25">25</Option>
                                      <Option value="26">26</Option>
                                      <Option value="27">27</Option>
                                      <Option value="28">28</Option>
                                      <Option value="29">29</Option>
                                      <Option value="30">30</Option>
                                      <Option value="31">31</Option>
                                      <Option value="32">32</Option>
                                      <Option value="33">33</Option>
                                      <Option value="34">34</Option>
                                      <Option value="35">35</Option>
                                      <Option value="36">36</Option>
                                      <Option value="37">37</Option>
                                      <Option value="38">38</Option>
                                      <Option value="39">39</Option>
                                      <Option value="40">40</Option>
                                      <Option value="41">41</Option>
                                      <Option value="42">42</Option>
                                      <Option value="43">43</Option>
                                      <Option value="44">44</Option>
                                      <Option value="45">45</Option>
                                      <Option value="46">46</Option>
                                      <Option value="47">47</Option>
                                      <Option value="48">48</Option>
                                      <Option value="49">49</Option>
                                      <Option value="50">50</Option>
                                      <Option value="51">51</Option>
                                      <Option value="52">52</Option>
                                      <Option value="53">53</Option>
                                      <Option value="54">54</Option>
                                      <Option value="55">55</Option>
                                      <Option value="56">56</Option>
                                      <Option value="57">57</Option>
                                      <Option value="58">58</Option>
                                      <Option value="59">59</Option>
                                    </Select>
                                  </Form.Item>
                                </Row>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row className="reports-btns">
                            {/* <div > */}
                            <Button
                              type="primary"
                              htmlType="submit"
                              onClick={(e) => setShowTable(true)}
                            >
                              Update
                            </Button>
                            {/* </div> */}
                          </Row>
                        </Form>
                        <Table
                          rowSelection={{ ...rowSelection }}
                          columns={columns}
                          dataSource={dataSource}
                          style={{ marginTop: "15px" }}
                          pagination={false}
                          className="managebus-table table-data"
                        />
                      </div>
                    </TabPane>
                    <TabPane tab="Boarding/Dropping" key="3">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form.Item label="Service Route">
                            <Select placeholder="Please Select">
                              <option>Hyderabad</option>
                              <option>Bangalore</option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Table
                        columns={boardingColumn}
                        dataSource={boardingDataSource}
                        style={{ marginTop: "15px" }}
                        pagination={false}
                        className="managebus-table table-data"
                      />
                      <Table
                        rowSelection={{ ...rowSelection }}
                        columns={droppingColumn}
                        dataSource={droppingDataSource}
                        style={{ marginTop: "15px" }}
                        pagination={false}
                        className="managebus-table table-data"
                      />
                    </TabPane>
                    <TabPane tab="Seat Layout" key="4">
                      <Row>
                        <Col md={16}>
                          <p className="layout-type">Layout Type : 2+2</p>
                          <div
                            className="lower"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="lower-header">Lower</div>
                            <div className="lower-layout">
                              <ul>
                                <li className="active">1</li>
                                <li className="active">2</li>
                                <li className="active">3</li>
                                <li className="active">4</li>
                                <li className="active">5</li>
                                <li className="active">6</li>
                                <li className="active">7</li>
                                <li className="active">8</li>
                                <li className="active">9</li>
                                <li className="active">10</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li className="active">11</li>
                                <li className="active">12</li>
                                <li className="active">13</li>
                                <li className="active">14</li>
                                <li className="active">15</li>
                                <li className="active">16</li>
                                <li className="active">17</li>
                                <li className="active">18</li>
                                <li className="active">19</li>
                                <li className="active">20</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li className="active">21</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li className="active">22</li>
                                <li className="active">23</li>
                                <li className="active">24</li>
                                <li className="active">25</li>
                                <li className="active">26</li>
                                <li className="active">27</li>
                                <li className="active">28</li>
                                <li className="active">29</li>
                                <li className="active">30</li>
                                <li className="active">31</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li className="active">32</li>
                                <li className="active">33</li>
                                <li className="active">34</li>
                                <li className="active">35</li>
                                <li className="active">36</li>
                                <li className="active">37</li>
                                <li className="active">38</li>
                                <li className="active">39</li>
                                <li className="active">40</li>
                                <li className="active">41</li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                          <div className="upper">
                            <div className="upper-header">Upper</div>
                            <div className="upper-layout">
                              <ul>
                                <li className="active"></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                              <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                        <Col md={8}>
                          <div
                            className="img-block"
                            style={{ marginBottom: "15px" }}
                          >
                            {/* <img src={require('../../assets/images/select-seat.png').default} /> */}
                          </div>
                          <div className="special-selector">
                            <p
                              style={{
                                paddingBottom: "10px",
                                borderBottom: "1px solid #ccc",
                              }}
                            >
                              Special Seats
                            </p>
                            <Button type="primary">Save</Button>
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab="Cancellation Policy" key="5">
                      <Form style={{ textAlign: "center" }}>
                        <Row gutter={32}>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label="From"
                              rules={[{ required: true }]}
                              name="source"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label="To"
                              rules={[{ required: true }]}
                              name="destination"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Button type="primary" htmlType="submit">
                              Add
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                      <Table
                        columns={cancellationColumn}
                        dataSource={cancellationDataSource}
                        style={{ marginTop: "15px" }}
                        pagination={false}
                        className="managebus-table table-data"
                      />
                    </TabPane>
                    <TabPane tab="Gallery" key="6">
                      <Collapse accordion>
                        <Panel header="Front view" key="1">
                          <Row>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                          </Row>
                          <div style={{ textAlign: "right" }}>
                            <Button type="primary">Add more</Button>
                          </div>
                        </Panel>
                        <Panel header="Back view" key="2">
                          <Row>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                          </Row>
                          <div style={{ textAlign: "right" }}>
                            <Button type="primary">Add more</Button>
                          </div>
                        </Panel>
                        <Panel header="Side view" key="3">
                          <Row>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                          </Row>
                          <div style={{ textAlign: "right" }}>
                            <Button type="primary">Add more</Button>
                          </div>
                        </Panel>
                        <Panel header="Inner view" key="4">
                          <Row>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                            <Col md={6}>
                              <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                fileList={fileList}
                                onChange={onUpload}
                                onPreview={onPreview}
                              >
                                {fileList.length < 5 && "+ Upload"}
                              </Upload>
                            </Col>
                          </Row>
                          <div style={{ textAlign: "right" }}>
                            <Button type="primary">Add more</Button>
                          </div>
                        </Panel>
                      </Collapse>
                      {/* <Row>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Upload
                                                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                            listType="picture-card"
                                                            fileList={fileList}
                                                            onChange={onUpload}
                                                            onPreview={onPreview}
                                                        >
                                                            {fileList.length < 5 && '+ Upload'}
                                                        </Upload>
                                                    </Col>
                                                </Row>
                                                <Button type="primary">Add</Button> */}
                    </TabPane>
                    <TabPane tab="Fare" key="7">
                      {/* <Row gutter={16}> */}
                      <Col md={8} xs={24}>
                        <Form.Item label="Service Route">
                          <Select placeholder="Please Select">
                            <option>Hyderabad</option>
                            <option>Bangalore</option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <div>
                        <Table
                          columns={farecolumns}
                          dataSource={fareDataSource}
                          pagination={false}
                          className="fare-table table-data"
                        />
                      </div>
                      {/* </Row> */}
                    </TabPane>
                    <TabPane tab="Seat Quota" key="8">
                      <Row>
                        <Col md={12}>
                          <div
                            className="lower"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="lower-header">Lower</div>
                            <div className="lower-layout seat-quota">
                              <ul>
                                <li>1</li>
                                <li>2</li>
                                <li>3</li>
                                <li>4</li>
                                <li>5</li>
                                <li>6</li>
                                <li>7</li>
                                <li>8</li>
                                <li>9</li>
                                <li>10</li>
                              </ul>
                              <ul>
                                <li>11</li>
                                <li>12</li>
                                <li>13</li>
                                <li>14</li>
                                <li>15</li>
                                <li>16</li>
                                <li>17</li>
                                <li>18</li>
                                <li>19</li>
                                <li>20</li>
                              </ul>
                              <ul className="single-seat">
                                <li>41</li>
                              </ul>
                              <ul>
                                <li>21</li>
                                <li>22</li>
                                <li>23</li>
                                <li>24</li>
                                <li>25</li>
                                <li>26</li>
                                <li>27</li>
                                <li>28</li>
                                <li>29</li>
                                <li>30</li>
                              </ul>
                              <ul>
                                <li>31</li>
                                <li>32</li>
                                <li>33</li>
                                <li>34</li>
                                <li>35</li>
                                <li>36</li>
                                <li>37</li>
                                <li>38</li>
                                <li>39</li>
                                <li>40</li>
                              </ul>
                            </div>
                          </div>
                          <div className="upper">
                            <div className="upper-header">Upper</div>
                            <div className="upper-layout"></div>
                          </div>
                        </Col>
                        <Col md={12}>
                          <p>Selected Seats :</p>
                          <Form
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            layout="horizontal"
                            className="selected-seat-form"
                          >
                            <Form.Item label="Quota For">
                              <Radio.Group
                                onChange={onChangeRadio}
                                value={value}
                              >
                                <Radio value={1}>Partner</Radio>
                                <Radio value={2}>HeadOffice</Radio>
                                <Radio value={3}>Male</Radio>
                                <Radio value={4}>Female</Radio>
                                <Radio value={5}>Family</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Partner">
                              <Select>
                                <Select.Option value="demo">Demo</Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item label="Quota Type">
                              <Radio.Group onChange={onChangeType} type={value}>
                                <Radio value={1}>Permanent</Radio>
                                <Radio value={2}>Custom</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item label="From Date">
                              <DatePicker
                                onChange={onDateChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item label="To Date">
                              <DatePicker
                                onChange={onDateChange}
                                style={{ width: "100%" }}
                              />
                            </Form.Item>
                            <Form.Item label="Release Time(Hrs)">
                              <Select>
                                <Select.Option value="12:00">
                                  12:00
                                </Select.Option>
                              </Select>
                            </Form.Item>
                            <Form.Item label="Remarks">
                              <TextArea />
                            </Form.Item>
                            <Form.Item style={{ textAlign: "center" }}>
                              <Button type="primary">Save</Button>
                            </Form.Item>
                          </Form>
                        </Col>
                      </Row>
                      <div>
                        <Form
                          name="horizontal_login"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 24 }}
                        >
                          <Row style={{ alignItems: "flex-end" }} gutter={16}>
                            <Col md={7}>
                              <Form.Item label="From Date">
                                <DatePicker
                                  onChange={onDateChange}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={7}>
                              <Form.Item label="To Date">
                                <DatePicker
                                  onChange={onDateChange}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                            <Col md={7}>
                              <Form.Item label="Agent">
                                <Select>
                                  <Select.Option value="12:00">
                                    12:00
                                  </Select.Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col md={3}>
                              <Form.Item>
                                <Button type="primary" htmlType="submit">
                                  Search
                                </Button>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Table
                            columns={fromToColumns}
                            dataSource={fromToData}
                            pagination={false}
                            className="fare-table table-data"
                            style={{ marginTop: "24px" }}
                          />
                        </Form>
                      </div>
                    </TabPane>
                    <TabPane tab="Operating Days" key="9">
                      <Row gutter={32}>
                        <Col md={6}>
                          <DatePicker
                            onChange={onChangeDate}
                            className="operating-date-picker"
                          />
                        </Col>
                        <Col md={6}>
                          <DatePicker
                            onChange={onChangeDate}
                            className="operating-date-picker"
                          />
                        </Col>
                        <Col md={12}>
                          <Switch
                            checkedChildren="Enable"
                            unCheckedChildren="Disable"
                            defaultChecked
                          />
                        </Col>
                      </Row>
                      <div>
                        <Table
                          columns={operatingcolumns}
                          dataSource={operatingDataSource}
                          pagination={false}
                          className="fare-table table-data"
                          style={{ marginTop: "24px" }}
                        />
                      </div>
                    </TabPane>
                  </Tabs>
                  <div className="e-hide">
                    &nbsp;
                    <Form
                      {...formItemLayout}
                      layout={formLayout}
                      initialValues={{ layout: formLayout }}
                    >
                      <Row gutter={16}>
                        <Col className="gutter-row" md={8} xs={24}>
                          <Form.Item label="Time and Date">
                            <Select placeholder="Please select">
                              <Option value="0">Last 1 hour </Option>
                              <Option value="1">Today</Option>
                              <Option value="2">Yesterday</Option>
                              <Option value="3">Last 1 month</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Reports of">
                            <Select placeholder="All">
                              <option>All</option>
                              <option>All Users</option>
                              <option>Guests</option>
                              <option>All Agents</option>
                              <option>Specific Agent</option>
                              <option>All Distributors</option>
                              <option>Specific Distributor</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Master Distributor">
                            <Select placeholder="Master Distributor">
                              <option>Master Distributor1</option>
                              <option>Master Distributor2</option>
                              <option>Master Distributor3</option>
                              <option>Master Distributor4</option>
                              <option>Master Distributor5</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Distributor">
                            <Select placeholder="Distributor">
                              <option>Distributor1</option>
                              <option>Distributor2</option>
                              <option>Distributor3</option>
                              <option>Distributor4</option>
                              <option>Distributor5</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Agent">
                            <Select placeholder="Agent">
                              <option>Agent1</option>
                              <option>Agent2</option>
                              <option>Agent3</option>
                              <option>Agent4</option>
                              <option>Agent5</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Service Type">
                            <Select placeholder="Select Service Type">
                              <option>Flights</option>
                              <option>Hotels</option>
                              <option>Trains</option>
                              <option>Buses</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="API Provider">
                            <Select placeholder="API Provider">
                              <option>Provider1</option>
                              <option>Provider2</option>
                              <option>Provider3</option>
                              <option>Provider4</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Operator">
                            <Select placeholder="Operator">
                              <option>Operator1</option>
                              <option>Operator2</option>
                              <option>Operator3</option>
                              <option>Operator4</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="From Date">
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select From date"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="To Date">
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select To date"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Trip Type">
                            <Select placeholder="Please Select">
                              <option>One way</option>
                              <option>Round Trip</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Reference number">
                            <Input placeholder="Enter Reference Number" />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Journey Date">
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select Journey date"
                            />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Application Type">
                            <Select placeholder="Please Select">
                              <option>Website</option>
                              <option>App</option>
                              <option>Both</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="PNR/API Ref Number">
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Page size">
                            <Select placeholder="Please Select">
                              <option>50</option>
                              <option>100</option>
                              <option>150</option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Recharge Number">
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Pax Name/Email/Mobile">
                            <Input placeholder="" />
                          </Form.Item>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form.Item label="Booking Status">
                            <Select placeholder="Please Select">
                              <option>Booked</option>
                              <option>Cancelled</option>
                              <option>Blocked</option>
                              <option>Booking Failed</option>
                              <option>Booking Cancelled</option>
                              <option>Blocking failed</option>
                              <option>Cancellation failed</option>
                              <option>Partially cancelled</option>
                              <option>Failed</option>
                              <option>Pending</option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="reports-btns">
                        {/* <div > */}
                        <Button
                          type="primary"
                          onClick={(e) => setShowTable(true)}
                        >
                          Search
                        </Button>
                        <Button
                          type="danger"
                          onClick={(e) => setShowTable(false)}
                        >
                          Reset
                        </Button>
                        {/* </div> */}
                      </Row>
                    </Form>
                  </div>
                </Card>
              </div>
            </Col>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>View Bus Details</h5>
                        <p>5 rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      {/* <img src={word} alt="word" /> */}
                      <img src={search} alt="search" />
                      <img src={exchange} alt="exchange" />
                    </div>
                    {/* </div> */}
                  </div>
                  <Table
                    columns={busDetailcolumns}
                    dataSource={busDetailDataSource}
                    pagination={false}
                    className="fare-table table-data"
                    style={{ marginTop: "24px" }}
                  />
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title="Add Amnities"
        visible={showAminities}
        onOk={handleOk}
        onCancel={handleCancel}
        width={820}
      >
        <Row gutter={[16, 16]}>
          <Col md={6}>
            <Checkbox onChange={onChange}>AirConditioner</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Blanket</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>CCCTV</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>CentralTV</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>ChargingPoint</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Emeregencyexit</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>FacialTissues</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>FireExtinguisher</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Food</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Hammer</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>HeadSets</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Heater</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>LiveBusTracking</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>MassageChair</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Meal</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Movie</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Motorizedcalfsupport</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Newspaper</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>PersonalTV</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Pillow</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>ReadingLight</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Satelite</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Snacks</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Softdrink</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Television</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Washroom</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>WaterBottle</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>Wifi</Checkbox>
          </Col>
          <Col md={6}>
            <Checkbox onChange={onChange}>FirstAidKit</Checkbox>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
export default ManageBus;
