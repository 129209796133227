import React, { useState, useRef } from "react";

import { Radio, Col, Row, Form, Select, DatePicker, Button, Input } from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import AirportAutoComplete from "../../common/AutoCompleteSelect/AirportAutoCompleteSelect";
import { OnlyFutureDateValidator } from "../../helpers/CustomValidators";

const MultiCityLIst = ({ setTogle }) => {
  let dept = useRef([]);
  let to = useRef([]);
  let mdateBox = useRef([]);
  const [refresh, setRefresh] = useState(false);
  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const OriginDestinationField = ({ count, field, add, index, remove }) => {
    const [showMDate, setShowMDate] = useState(false);

    const handleOnSubmit = (ref, isfieldKey, fieldKey) => {
      if (isfieldKey) {
        ref.current[fieldKey].focus();
        if (ref === mdateBox) {
          setShowMDate(true);
        }
      }
    };

    return (
      <Row gutter={16}>
        <Col md={8} xs={12}>
          <AirportAutoComplete
            formItemProps={{
              label: "Depart From",
              ...field,
              name: [field.name, "origin"],
              fieldKey: [field.fieldKey, "origin"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "Select Source" }}
            refName={dept}
            focusRef={to}
            isfieldKey={true}
            fieldKey={field.name}
            handleOnSubmit={handleOnSubmit}
          />
        </Col>
        <Col md={8} xs={12}>
          <AirportAutoComplete
            formItemProps={{
              label: "Flying To",
              ...field,
              name: [field.name, "destination"],
              fieldKey: [field.fieldKey, "destination"],

              rules: [{ required: true, message: "Please Select a City" }],
            }}
            selectProps={{ size: "large", placeholder: "Select destination" }}
            refName={to}
            isfieldKey={true}
            fieldKey={field.name}
            focusRef={mdateBox}
            handleOnSubmit={handleOnSubmit}
          />
        </Col>
        <Col md={8} xs={12}>
          <Form.Item
            label="Departure Date"
            {...field}
            name={[field.name, "departureDateTime"]}
            fieldKey={[field.fieldKey, "departureDateTime"]}
            rules={[{ required: true, message: "Select a Date" }]}
          >
            <DatePicker
              className="train-search-btn"
              size="large"
              style={{ width: "100%" }}
              format={"DD-MM-YYYY"}
              ref={(el) => (mdateBox.current[field.name] = el)}
              open={showMDate}
              disabledDate={disabledOriginDate}
              onChange={() => {
                if (field.name === count) {
                  setTogle(true);
                  setRefresh(true);
                } else {
                  dept.current[field.key + 1].focus();
                  setShowMDate((prev) => !prev);
                }
              }}
              onOpenChange={() => setShowMDate((prev) => !prev)}
            />
          </Form.Item>
        </Col>
        {field.name === 2 || field.name === 3 ? (
          <MinusCircleOutlined
            className="minus_multicity"
            onClick={() => remove(index)}
          />
        ) : null}
      </Row>
    );
  };

  const intitalValues = DefaultMulticityLoad();

  return (
    <Col md={20} xs={20}>
      <div id="multiCityForm" className="multicity">
        <Form.List name="originDestinationFields">
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => {
                  return (
                    <OriginDestinationField
                      count={fields.length - 1}
                      field={field}
                      index={index}
                      key={field.key}
                      remove={remove}
                    />
                  );
                })}

                {fields.length < 4 ? (
                  <PlusCircleOutlined className="plus-icon" onClick={add} />
                ) : null}
              </>
            );
          }}
        </Form.List>
      </div>
    </Col>
  );
};

export const DefaultMulticityLoad = () => {
  const value =
    localStorage.getItem("multicity") != undefined
      ? {
          originDestinationFields: JSON.parse(
            localStorage.getItem("multicity")
          ).map((i) => {
            return {
              ...i,
              departureDateTime: i.departureDateTime
                ? moment(i.departureDateTime)
                : "",
            };
          }),
        }
      : {
          originDestinationFields: [{}, {}],
        };
  return value;
};

export default React.memo(MultiCityLIst);
