import React, { useState, useRef, useEffect } from "react";
import { Button, Col, DatePicker, Form, Row, message } from "antd";
import { useHistory } from "react-router-dom";

import BusesAutoSelect from "../../common/AutoCompleteSelect/BusesAutoSelect";

import moment from "moment";
import queryString from "query-string";
import arrowImg from "../../assets/images/flight-arrow.png";
import { SearchOutlined } from "@ant-design/icons";
import "../buses-search/Buses.scss";
import { OriDesValidate } from "../../helpers/CustomValidators";

const Buses = ({ modifySearch = false, busesSearchAPI }) => {
  const city = useRef(null);
  const searchBtn = useRef();

  let history = useHistory();
  const tocityBox = useRef(null);
  const fromDateBox = useRef(null);

  const dateFormat = "DD-MM-YYYY";
  const [form] = Form.useForm();

  const validateMessages = {
    required: "",
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const [showDate, setShowDate] = useState(false);
  const [dataFlip, setDataFlip] = useState(false);
  const handleOnSubmit = (ref) => {
    ref.current.focus();
    if (ref === fromDateBox) {
      setShowDate(true);
    }
  };

  const submitForm = (values) => {
    let source = queryString.parse(values.sourceId);
    let destination = queryString.parse(values.destinationId);

    if (source.cityId === destination.cityId) {
      message.error("Origin and Destination cannot be same", 3);
      return false;
    }

    let formData = {
      sourceId: source.cityId,
      destinationId: destination.cityId,
      journeyDate: moment(values.journeyDate).format("DD-MM-YYYY"),
      sourceName: source.cityName,
      destinationName: destination.cityName,
    };
    const query = queryString.stringify(formData);

    history.push("/bus/results?" + query);

    if (modifySearch) {
      busesSearchAPI();
    }
  };
  useEffect(() => {
    if (modifySearch) {
      let param = queryString.parse(document.location.search);
      form.setFieldsValue({
        sourceId: `cityName=${param.sourceName}&cityId=${param.sourceId}`,
        destinationId: `cityName=${param.destinationName}&cityId=${param.destinationId}`,
        journeyDate: moment(param.journeyDate, "DD-MM-YYYY"),
      });
    }
  }, []);

  const handleSwap = () => {
    const sourceId = form.getFieldValue("sourceId");
    const destinationId = form.getFieldValue("destinationId");

    if (sourceId && destinationId) {
      form.setFieldsValue({
        sourceId: destinationId,
        destinationId: sourceId,
      });
      setDataFlip((prev) => !prev);
    }
  };

  return (
    <div className="bus-search-wrapper">
      <Form
        form={form}
        className="bus-panel-bg"
        name="busForm"
        validateMessages={validateMessages}
        onFinish={submitForm}
      >
        <Row className="search-row bus-alert-sec bus-cr-search">
          <Col md={8} xs={24} className="from-to-inputs bus-cols-info">
            <BusesAutoSelect
              formItemProps={{
                name: "sourceId",
                rules: [
                  { required: true, message: "Please Specify The City" },
                  {
                    validator: (_, value) => {
                      let destinationId = form.getFieldValue("destinationId");
                      return OriDesValidate(destinationId, value);
                    },
                  },
                ],
              }}
              selectProps={{
                size: "large",
                placeholder: "Origin",
              }}
              api={"buses/cities/search/"}
              refName={city}
              focusRef={tocityBox}
              handleOnSubmit={handleOnSubmit}
              modifySearch={modifySearch ? modifySearch : false}
              dataFlip={dataFlip}
            />

            <img
              className="arrow-bus-inr"
              src={arrowImg}
              alt="Arrow img"
              onClick={handleSwap}
            />
          </Col>
          <Col
            md={8}
            xs={24}
            className="from-to-inputs bus-cols-info destination"
          >
            <BusesAutoSelect
              formItemProps={{
                name: "destinationId",
                rules: [
                  { required: true, message: "Please Specify The City" },
                  {
                    validator: (_, value) => {
                      let sourceId = form.getFieldValue("sourceId");
                      return OriDesValidate(sourceId, value);
                    },
                  },
                ],
              }}
              selectProps={{
                size: "large",
                placeholder: "Destination",
              }}
              api={"buses/cities/search/"}
              refName={tocityBox}
              focusRef={fromDateBox}
              handleOnSubmit={handleOnSubmit}
              modifySearch={modifySearch ? modifySearch : false}
              dataFlip={dataFlip}
            />
          </Col>
          <Col md={4} xs={24} className="from-to-inputs bus-cols-info">
            <Form.Item name="journeyDate" rules={[{ required: true }]}>
              <DatePicker
                allowClear={false}
                size="large"
                className="search-inputs from-return-date"
                placeholder="Journey Date"
                ref={fromDateBox}
                format={dateFormat}
                open={showDate}
                disabledDate={disabledOriginDate}
                onOpenChange={() => {
                  setShowDate((prev) => !prev);
                }}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col md={4} xs={24} className="bus-cols-info">
            <Button
              size="large"
              className="primary-btn train-search-btn bus-btn-pri"
              ref={searchBtn}
              htmlType="submit"
            >
              <SearchOutlined /> Search
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Buses;
