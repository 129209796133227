import React, { useState } from "react";
import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../Holidayscart/Holidayscart.scss";
import { Link } from "react-router-dom";

const Shoppingcart = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <div className="card-add-heading">
                    <h4 className="shopping-heading">Shopping cart</h4>
                  </div>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            {/*--shopping---cart--open---*/}
            <ANTD.Row>
              <ANTD.Col md={24} xs={24} className="cart-shop">
                <div>
                  <img
                    className="cart-img"
                    src={
                      require("../../../assets/images/holidays/cart-img.png")
                        .default
                    }
                    alt=""
                  />
                  <h4>Your shopping cart is empty</h4>
                  <p>Check out our top recommendations for you</p>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            {/*--shopping---cart--close---*/}

            <ANTD.Row>
              <ANTD.Col md={24} xs={24}>
                <h3 className="booking-option">Popular destinations</h3>
              </ANTD.Col>
            </ANTD.Row>

            {/*--start----end--*/}

            <ANTD.Row>
              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-1.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Hong Kong</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-2.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Taipei</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-3.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Phuket</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-4.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Kuala Lumpur</h4>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Row className="second-row-img">
              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-1.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Kuala Lumpur</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-2.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Kuala Lumpur</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-3.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Kuala Lumpur</h4>
              </ANTD.Col>

              <ANTD.Col md={6} xs={24}>
                <img
                  className="cart-shopping-img"
                  src={
                    require("../../../assets/images/holidays/shopping-d-4.png")
                      .default
                  }
                  alt=""
                />
                <h4 className="cart-h4">Kuala Lumpur</h4>
              </ANTD.Col>
            </ANTD.Row>

            {/*--india----end--*/}

            <ANTD.Row>
              <ANTD.Col md={24} xs={24} className="explore-btn">
                <ANTD.Button className="all-near-places">
                  Explore All Destinations
                </ANTD.Button>
              </ANTD.Col>
            </ANTD.Row>
          </Content>
        </div>
      </div>
    </div>
  );
};
export default Shoppingcart;
