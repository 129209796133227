export const getHotelBookingStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#FFA500" }}>FAILED</p>;

    case 2:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 3:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 4:
      return <p style={{ color: "#FF0000" }}>PENDING</p>;

    case 5:
      return <p style={{ color: "#f9e218" }}>REJECTED</p>;

    case 6:
      return <p style={{ color: "#FF0000" }}>HOLD</p>;

    case 7:
      return <p style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</p>;

    case 8:
      return <p style={{ color: "#FF0000" }}>CANCELLATION PENDING</p>;

    case 9:
      return <p style={{ color: "#FF0000" }}>CANCELLATION IN PROGRESS</p>;

    default:
      return "";
  }
};

export const getBusBookingStatus = (status) => {
  switch (status) {
    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 5:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 7:
      return <p style={{ color: "#008000" }}>PARTIALLY CANCELLED</p>;

    default:
      return "";
  }
};

export const getActivitiesBookingStatus = (status) => {
  switch (status) {
    case 0:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 1:
      return <p style={{ color: "#FF0000" }}>ALREADY CANCELLED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    default:
      return "";
  }
};

export const getFlightTicketStatus = (status) => {
  switch (status) {
    case 1:
      return <p style={{ color: "#f9e218" }}>CREATED</p>;

    case 2:
      return <p style={{ color: "#FFA500" }}>BLOCKED</p>;

    case 3:
      return <p style={{ color: "#008000" }}>CONFIRMED</p>;

    case 4:
      return <p style={{ color: "#FF0000" }}>CANCELLED</p>;

    case 5:
      return <p style={{ color: "#008000" }}>PARTIALLY CANCELLED</p>;

    case 6:
      return <p style={{ color: "#FF0000" }}>CANCELLATION REQUESTED</p>;

      case 10:
        return <p style={{ color: "#FF0000" }}>HOLD</p>;

    default:
      return "";
  }
};

//  HotelBookingStatus
//     FAILED = 1,
//     CONFIRMED = 2,
//     CANCELLED = 3,
//     PENDING = 4,
//     REJECTED = 5,
//     HOLD = 6,
//     CancellationRequest = 7,
//     CancellationPending = 8,
//     CancellationInProgress = 9

//  BusBookingStatus
//     BLOCKED = 2,
//     CONFIRMED = 3
//     CANCELLED = 5,
//     PARTIALLYCANCELLED = 7,

// ActivitiesBookingStatus
//     CANCELLED = 0,
//     ALREADYCANCELLED = 1,
//     BLOCKED = 2,
//     CONFIRMED = 3

// flightTicketStatus
//     CREATED = 1,
//     BLOCKED = 2,
//     CONFIRMED = 3,
//     CANCELLED = 4,
//     PARTIALLY_CANCELLED = 5,
//     CANCELLATIONREQUESTED = 6
