import {
  CaretRightOutlined,
  CloseOutlined,
  EditOutlined,
  PlusCircleFilled,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Collapse,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Popover,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import exchange from "../../assets/vector-icons/exchange.png";
import search from "../../assets/vector-icons/search.png";
import AutoCompleteUserSelect from "../../common/AutoCompleteSelect/AutoCompleteUserSelect";
import { useAuthContext } from "../../common/providers/AuthProvider";
import APIClient from "../../helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import "./crm.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Option } = Select;
const { Panel } = Collapse;
const oriDateFormat = "YYYY-MM-DD";
let dateFormat = "DD-MM-YYYY";
const prefixSelector = +91;
const Crm = () => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [Filterform] = Form.useForm();
  const [form] = Form.useForm();
  const validateMessages = {
    required: "",
  };

  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [users, setUsers] = useState([]);
  const [CrmLeadModal, setCrmLeadModal] = useState(false);
  const [isConversationMode, setisConversationMode] = useState(false);
  const [IsEditMode, setIsEditMode] = useState(false);
  const [crmLeads, setCrmLeads] = useState([]);
  const [RecordCounts, setRecordCounts] = useState("");
  const [conversation, setConversation] = useState();

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [lead, setLead] = useState(0);

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const disabledPastDate = (currentDate) => {
    return currentDate <= moment();
  };

  let initialState = {
    organisation: "",
    address: "",
    GSTCompanyName: "",
    GSTINNumber: "",
    GSTAdress: "",
    GSTEmail: "",
    customerdesignation: "",
    customercompanyname: "",
    DOB: "",
    customerAnniversaryDate: "",
    customerFavouriteDestinations: "",
    passportnumber: "",
    passportcountry: "",
    passportexpiry: "",
    departurefrom: "",
    destinationCountry: "",
    destinationCity: "",
    adults: 0,
    child: 0,
    infants: 0,
  };
  const [content, setContent] = useState(initialState);

  const getContent = (content, type) => {
    setContent((prev) => ({
      ...prev,
      [type]: content,
    }));
  };

  const onFormSubmit = (values) => {
    if (values.options == 4) {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    let data = {
      options: values.options ?? 1,
      serviceType: values.serviceType ?? "",
      fromDate: values.fromDate ?? "",
      toDate: values.toDate ?? "",
      status: values.status ?? "",
      userId: values.reportsOf
        ? values.reportsOf === 0
          ? ""
          : values.reportsOf
          ? values.reportsOf
          : values.customer
        : "",
    };
    getStatmentsData(data);
  };
  const getStatmentsData = (data) => {
    setLoading(true);
    setCrmLeads([]);
    APIClient.get(
      `admin/getAllFilteredCrmLeads?status=${data.status}&userId=${data.userId}&fromDate=${data.fromDate}&toDate=${data.toDate}&serviceType=${data.serviceType}&options=${data.options}`
    )
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          setCrmLeads(data);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleTimeAndDate = (value) => {
    if (value === 4) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  useEffect(() => {
    getCrmleads();
    getUsers();
  }, []);

  const getCrmleads = () => {
    APIClient.get("admin/getAllCrmLeads")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,
              ...item,
            };
          });
          if (user.UserID != 1) {
            setCrmLeads(data.filter((item) => item.userId == user.UserID));
          } else {
            setCrmLeads(data);
            setRecordCounts(res);
          }
        }
      })
      .catch((error) => {});
  };

  const getUsers = () => {
    APIClient.get("admin/user")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.filter((item) => item?.role?.Level == 1);
          setUsers(data);
        }
      })
      .catch((error) => {});
  };

  const UpdateCrmLead = (currentData) => {
    setConversation(currentData.conversation_Data);
    let obj = {
      organisation: currentData?.organisation,
      address: currentData?.address,
      GSTCompanyName: currentData?.GSTCompanyName,
      GSTINNumber: currentData?.GSTINNumber,
      GSTAdress: currentData?.GSTAdress,
      GSTEmail: currentData?.GSTEmail,
      customerdesignation: currentData?.customerdesignation,
      customercompanyname: currentData?.customercompanyname,
      DOB: currentData.DOB ? moment(currentData.DOB, "DD-MM-YYYY") : "",
      customerAnniversaryDate: currentData.customerAnniversaryDate
        ? moment(currentData.customerAnniversaryDate, "DD-MM-YYYY")
        : "",
      customerFavouriteDestinations: currentData?.customerFavouriteDestinations,
      passportnumber: currentData.passportnumber,
      passportcountry: currentData.passportcountry,
      passportexpiry: currentData.passportexpiry,
      departurefrom: currentData.departurefrom,
      destinationCountry: currentData.destinationCountry,
      destinationCity: currentData.destinationCity,
      adults: currentData.adults,
      child: currentData.child,
      infants: currentData.infants,
    };
    setContent(obj);

    form.setFieldsValue({
      name: currentData.name,
      mobile: currentData.mobile,
      alternate_mobile: currentData.alternate_mobile,
      email: currentData.email,
      city: currentData.city,
      state: currentData.state,
      country: currentData.country,
      serviceType: currentData.serviceType,
      lead_from: currentData.lead_from,
      status: currentData.status,
      conversation: "",
    });
    setCrmLeadModal(!CrmLeadModal);
    setLead(currentData);
  };

  // const DeleteCrmLead = (UserID) => {
  //   APIClient.delete("admin/user/" + UserID)
  //     .then((res) => {
  //       if (res.status == 200) {
  //         message.success("Deleted  successfully", 3);
  //         getCrmleads();
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  const searchData = () => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const tableActions = (data) => {
    return (
      <>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div className="add-conv-icon" style={{ textAlign: "center" }}>
            <PlusCircleFilled
              onClick={() => {
                setIsEditMode(false);
                setisConversationMode(true);
                UpdateCrmLead(data);
              }}
              style={{
                color: "#1890ff",
                fontSize: "20px",
                textAlign: "center",
              }}
            />
          </div>
          <div className="edit-icon" style={{ textAlign: "center" }}>
            <EditOutlined
              onClick={() => {
                setisConversationMode(false);
                setIsEditMode(true);

                UpdateCrmLead(data);
              }}
              style={{
                color: "#1890ff",
                fontSize: "20px",
                textAlign: "center",
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const add = () => {
    setisConversationMode(false);
    form.resetFields();
    setContent(initialState);
    setIsEditMode(false);

    setCrmLeadModal(true);
  };

  const clear = () => {
    setCrmLeadModal(false);
  };

  const tableColumns = [
    {
      title: "S.No",
      dataIndex: "SNo",
    },

    {
      title: "Name",
      dataIndex: "name",
    },

    {
      title: "Mobile",
      dataIndex: "mobile",
    },

    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "City",
      dataIndex: "city",
    },
    {
      title: "State",
      dataIndex: "state",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Lead From",
      dataIndex: "lead_from",
    },

    {
      title: "Sevice Type",
      dataIndex: "serviceType",
      render: (serviceType) => selectServiceType(serviceType),
    },

    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Actions",
      render: (currentItem) => tableActions(currentItem),
    },
  ];

  const selectServiceType = (type) => {
    switch (type) {
      case 1:
        return "Flight";
      case 2:
        return "Hotel ";
      case 3:
        return "Bus ";
      case 4:
        return "Activity ";
      case 5:
        return "Build Package";

      default:
        return "";
    }
  };

  const submitForm = (values) => {
    let data = {
      createdBy: user.UserID,
      userId: user.UserID,
      name: values.name,
      mobile: values.mobile,
      alternate_mobile: values.alternate_mobile,
      email: values.email,
      city: values.city,
      state: values.state,
      country: values.country,
      lead_from: values.lead_from,
      serviceType: values.serviceType,
      travelDate: moment(values.travelDate).format("DD-MM-YYYY"),
      nextfollowup: moment(values.nextfollowup).format("DD-MM-YYYY"),
      organisation: content.organisation,
      address: content.address,
      GSTCompanyName: content.GSTCompanyName,
      GSTINNumber: content.GSTINNumber,
      GSTAdress: content.GSTAdress,
      GSTEmail: content.GSTEmail,
      customerdesignation: content.customerdesignation,
      customercompanyname: content.customercompanyname,
      DOB: content.DOB,
      customerAnniversaryDate: content.customerAnniversaryDate,
      customerFavouriteDestinations: content.customerdesignation,
      passportnumber: content.passportnumber,
      passportexpiry: content.passportexpiry,
      passportcountry: content.passportcountry,
      departurefrom: content.departurefrom,
      destinationCountry: content.destinationCountry,
      destinationCity: content.destinationCity,
      adults: content.adults,
      child: content.child,
      infants: content.infants,
      conversation: values.conversation,
      status: values.status,
      sendMail: values.sendMail ?? 0,
    };

    let statusData = {
      status: values.status,

      modifiedBy: user.UserID,
    };

    let conversationData = {
      conversation: values.conversation,
      userId: user.UserID,
      lead_Id: lead.Id,
      createdBy: user.UserID,
      serviceType: lead.serviceType,
      status: values.status,
    };

    if (isConversationMode == true) {
      submitUpdateMap(conversationData, statusData, lead.Id);
    } else if (isConversationMode == false && IsEditMode == false) {
      submitMapDetails(data);
    }

    if (IsEditMode) {
      UpdateCrmLeadData(data, lead.Id);
    }
  };

  const submitMapDetails = (formMapData) => {
    APIClient.post("admin/createCrmLead", formMapData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Lead Ceated SuccessFully", 3);

          setCrmLeadModal(false);
          getCrmleads();
          form.resetFields();
        } else if (response.status == 201) {
          message.error("Inserted Successfully", 3);
          setCrmLeadModal(false);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setCrmLeadModal(false);
        } else if (response.status == 409) {
          message.error("Entered Mobile Number or Mail Already existed", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitUpdateMap = (conversationData, LeadData, leadId) => {
    APIClient.post(`admin/createConversation`, conversationData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Updated Successfully", 3);
          setCrmLeadModal(false);
          getCrmleads();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setCrmLeadModal(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    APIClient.put(`admin/UpdateCrmLeadStatus/${leadId}`, {}, LeadData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Updated Successfully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setCrmLeadModal(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const UpdateCrmLeadData = (crmLeadData, LeadId) => {
    APIClient.put(`admin/UpdateCrmLead/${LeadId}`, {}, crmLeadData)
      .then((response) => {
        if (response.status == 200) {
          message.success("Updated Successfully", 3);
          setCrmLeadModal(false);
          getCrmleads();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (response.status == 500) {
          message.error("Duplicate Entry For Email or Mobile", 3);
          setCrmLeadModal(false);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = crmLeads.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });

      setCrmLeads(searchResults);
    } else {
      getCrmleads();
    }
  };

  // const handleExcel = () => {
  //   const excelData = crmLeads.map((item) => {
  //     return {
  //       S_No: item.SNo,
  //       Email: item.Email,
  //       AgencyName: item.AgencyName,
  //       AgentName: item.FirstName + "" + item.LastName,
  //       Mobile: item.Mobile,
  //       Wallet: item.Wallet,
  //       Credit: item.Credit,
  //       CreditDate: item.CreditDate,
  //       ExpiryDate: item.ExpiryDate,
  //       DOB: item.DOB,
  //       Status: item.Status,
  //     };
  //   });
  //   props.exportExcel(excelData, "Registration");
  // };

  const CrmTitle = () => (
    <div>
      Crm Leads <HelpInfoHelper screenName={"/admin/crm"} />
    </div>
  );

  return (
    <>
      <div className="manage-markup-section">
        <div className="container-fluid">
          {user.UserID == 1 && (
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <Tooltip placement="top" title={isActive ? "Show" : "Hide"}>
                  <CaretRightOutlined rotate={isActive ? 90 : -90} />
                </Tooltip>
              )}
              expandIconPosition="right"
              className="panel_wrapper"
              defaultActiveKey={["1"]}
            >
              <Panel header={<CrmTitle />} key="1">
                <Form
                  form={Filterform}
                  layout={"vertical"}
                  initialValues={{
                    options: 1,
                  }}
                  onFinish={onFormSubmit}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" md={6} xs={24}>
                      <Form.Item label="Time and Date" name="options">
                        <Select
                          placeholder="Please Select"
                          onChange={handleTimeAndDate}
                        >
                          <Option value={1}>Last One Hour</Option>
                          <Option value={2}>Yesterday</Option>
                          <Option value={3}>Last Week</Option>
                          <Option value={4}>Custom Dates</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <Form.Item label="Reports of" name="reportsOf">
                        <Select placeholder="Select">
                          {users.length > 0
                            ? users.map((item) => {
                                return (
                                  <Option value={item.UserID}>
                                    {item.userDetails.FirstName}
                                  </Option>
                                );
                              })
                            : ""}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col md={6} xs={24}>
                      <AutoCompleteUserSelect
                        formItemProps={{
                          label: "Customer",
                          name: "customer",
                          // rules: [{ required: true }],
                        }}
                        selectProps={{
                          placeholder: "Mobile No.",
                          allowClear: true,
                        }}
                        api={"admin/getUserByMobile/"}
                      />
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item label="ServiceType" name="serviceType">
                        <Select placeholder="Select">
                          <Option value={1}>Flights</Option>
                          <Option value={2}>Hotels</Option>
                          <Option value={3}>Bus</Option>
                          <Option value={4}>Activities</Option>
                          <Option value={5}>Build Package</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item label="Status" name="status">
                        <Select placeholder="Select">
                          <Option value={"Created"}>Created</Option>
                          <Option value={"Hot"}>Hot</Option>
                          <Option value={"Confirmed"}>Confirmed</Option>
                          <Option value={"Not Confirmed"}>Not Confirmed</Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    {isDateDisplay ? (
                      <>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="From Date"
                            name="fromDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select From date"
                              format={dateFormat}
                              disabledDate={disabledFutureDate}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" md={6} xs={24}>
                          <Form.Item
                            label="To Date"
                            name="toDate"
                            rules={[{ required: true, message: "Required" }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="Select To date"
                              format={dateFormat}
                              disabledDate={disabledFutureDate}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                  </Row>
                  <Row className="reports-btns">
                    <Button type="primary" htmlType="submit">
                      Search
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => Filterform.resetFields()}
                    >
                      Reset
                    </Button>
                  </Row>
                </Form>
              </Panel>
              <div className="record_Count">
                <Col>
                  <Row>
                    <Col span={3}>
                      <p>ALL</p>
                      <p>{RecordCounts.allLeads}</p>
                    </Col>
                    <Col span={3}>
                      <p>Confirmed</p>
                      <p>{RecordCounts.confirmed_leads}</p>
                    </Col>
                    <Col span={3}>
                      <p>Not Confirmed</p>
                      <p>{RecordCounts.not_confirmed}</p>
                    </Col>
                    <Col span={3}>
                      <p>Hot</p>
                      <p>{RecordCounts.hot}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col span={3}>
                      <p>ALL</p>
                      {RecordCounts.allLeads}
                      <p></p>
                    </Col>
                    <Col span={3}>
                      <p>WhatsApp Leads</p>
                      <p>{RecordCounts.whatsappLeads}</p>
                    </Col>
                    <Col span={3}>
                      <p>Facebook Leads</p>
                      <p>{RecordCounts.facebookLeads}</p>
                    </Col>
                    <Col span={3}>
                      <p>Twitter Leads</p>
                      <p>{RecordCounts.twitterLeads}</p>
                    </Col>
                  </Row>
                </Col>
              </div>
            </Collapse>
          )}

          <div className="card-bt-gap">
            <Card bordered={false}>
              <div className="card-add-heading">
                <div className="rows-count">
                  <div>
                    <h5> CRM Leads</h5>
                    <p>{crmLeads.length} rows found !</p>
                  </div>
                </div>
                <div className="action-images">
                  {showSearchBox && (
                    <Input
                      placeholder="Search"
                      onChange={(e) => searchTableData(e)}
                      suffix={closeSearchInput}
                      style={{ padding: "0px 12px" }}
                    />
                  )}
                  &nbsp;&nbsp;
                  <img
                    src={search}
                    alt="search"
                    onClick={(e) => searchData()}
                  />
                  {/* <img src={excel} alt="excel" onClick={handleExcel} /> */}
                  <img
                    src={exchange}
                    alt="exchange"
                    onClick={() => getCrmleads()}
                  />
                  <div onClick={add} className="add-deposit-icon">
                    <PlusCircleFilled onClick={() => {}} />
                  </div>
                </div>
              </div>
              {crmLeads.length > 0 ? (
                <div>
                  <Table
                    className="table-scroll-none"
                    bordered
                    // dataSource={tableContent}
                    dataSource={crmLeads}
                    columns={tableColumns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "50", "100", "125"],
                    }}
                  />
                </div>
              ) : loading ? (
                <Spin size="large" tip="Loading..." style={{ width: "100%" }} />
              ) : (
                <b>No Results Found!</b>
              )}
            </Card>
          </div>
        </div>
      </div>

      <Modal
        className="crm-module-admin"
        width={"1000px"}
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>
                {isConversationMode == true
                  ? "Add Conversation"
                  : IsEditMode == true
                  ? "Update CRM Lead"
                  : "Add New CRM Lead"}
              </strong>
            </h6>
          </div>,
        ]}
        visible={CrmLeadModal}
        onCancel={(e) => setCrmLeadModal(false)}
        footer={[
          <div>
            <Button key="submit" type="danger" onClick={clear}>
              Cancel
            </Button>
            <Button key="add" type="primary" onClick={form.submit}>
              {isConversationMode
                ? "Update"
                : IsEditMode == true
                ? "Update"
                : "Add"}
            </Button>
          </div>,
        ]}
      >
        <Form
          layout={"vertical"}
          form={form}
          onFinish={submitForm}
          validateMessages={validateMessages}
        >
          <div>
            {isConversationMode == false && (
              <>
                <Row gutter={16}>
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Full Name"
                      name="name"
                      rules={[
                        { required: true },
                        {
                          message: "Must be Alphabet",
                        },
                      ]}
                    >
                      <Input placeholder="Enter FirstName" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Mobile Number"
                      name="mobile"
                      rules={[
                        { required: true },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        placeholder="Enter Mobile Number"
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Alternate Mobile Number"
                      name="alternate_mobile"
                      rules={[
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={prefixSelector}
                        placeholder="Enter Mobile Number"
                      />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          required: true,
                        },
                      ]}
                    >
                      <Input placeholder="Enter Email" />
                    </Form.Item>
                  </Col>
                </Row>
                {/* {"End of Row"} */}
                <Row gutter={16}>
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter City" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter State" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Country"
                      name="country"
                      rules={[{ required: true }]}
                    >
                      <Input placeholder="Enter Country" />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item
                      label="Lead From"
                      name="lead_from"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select Service Type">
                        <Option value={"Whatsapp"}>Whatsapp</Option>
                        <Option value={"Facebook"}>Facebook</Option>
                        <Option value={"Linkedin"}>Linkedin</Option>
                        <Option value={"Twitter"}>Twitter</Option>
                        <Option value={"Google"}>Google</Option>
                        <Option value={"Referrals"}>Referrals</Option>
                        <Option value={"Newspaper"}>Newspaper</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                {/* {"End of Row"} */}
                <Row gutter={16}>
                  <Col className="gutter-row" md={6} xs={24}>
                    {" "}
                    <Form.Item
                      label="Service Type"
                      name="serviceType"
                      rules={[{ required: true }]}
                    >
                      <Select placeholder="Select Service Type">
                        <Option value={1}>Flights</Option>
                        <Option value={2}>Hotels</Option>
                        <Option value={3}>Bus</Option>
                        <Option value={4}>Activities</Option>
                        <Option value={5}>Build Package</Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item label="Travel Date" name="travelDate">
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Select Travel Date"
                        format={dateFormat}
                        disabledDate={disabledPastDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" md={6} xs={24}>
                    <Form.Item label="Next Follow Up Date" name="nextfollowup">
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Next Follow Up Date"
                        format={dateFormat}
                        disabledDate={disabledPastDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* {"End of Row"} */}

                <Collapse accordion>
                  <Panel header={"Organisation Details"} key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Organisation">
                          <Input
                            label="Organisation"
                            name="organisation"
                            placeholder="Enter Organisation Name"
                            value={content.organisation}
                            onChange={(e) => {
                              getContent(e.target.value, "organisation");
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Address">
                          <Input
                            placeholder="Enter Address"
                            value={content.address}
                            onChange={(e) =>
                              getContent(e.target.value, "address")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header={"GST Details"} key="2">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="GSTCompanyName">
                          <Input
                            placeholder="Enter GSTCompanyName "
                            value={content.GSTCompanyName}
                            onChange={(e) =>
                              getContent(e.target.value, "GSTCompanyName")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="GSTINNumber">
                          <Input
                            placeholder="Enter GSTINNumber"
                            value={content.GSTINNumber}
                            onChange={(e) =>
                              getContent(e.target.value, "GSTINNumber")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="GSTAdress">
                          <Input
                            placeholder="Enter GSTAdress"
                            value={content.GSTAdress}
                            onChange={(e) =>
                              getContent(e.target.value, "GSTAdress")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="GSTEmail">
                          <Input
                            placeholder="Enter GSTEmail"
                            value={content.GSTEmail}
                            onChange={(e) =>
                              getContent(e.target.value, "GSTEmail")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header={"Where The Customer is Working"} key="3">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Customer Designation">
                          <Input
                            placeholder="Customer Designation"
                            value={content.customerdesignation}
                            onChange={(e) =>
                              getContent(e.target.value, "customerdesignation")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Customer Company Name">
                          <Input
                            placeholder="Customer Company Name"
                            value={content.customercompanyname}
                            onChange={(e) =>
                              getContent(e.target.value, "customercompanyname")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Customer DOB">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="DOB"
                            format={dateFormat}
                            disabledDate={disabledFutureDate}
                            value={content.DOB}
                            onChange={(e) => getContent(e, "DOB")}
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Customer Anniversary Date">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Anniversary Date"
                            format={dateFormat}
                            disabledDate={disabledPastDate}
                            value={content.customerAnniversaryDate}
                            onChange={(e) =>
                              getContent(e, "customerAnniversaryDate")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Customer Favourite Destinations">
                          <Input
                            placeholder="Customer Favourite Destinations"
                            value={content.customerFavouriteDestinations}
                            onChange={(e) =>
                              getContent(
                                e.target.value,
                                "customerFavouriteDestinations"
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header={"Passport Details"} key="4">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Passport Number">
                          <Input
                            placeholder="Passport Number"
                            value={content.passportnumber}
                            onChange={(e) =>
                              getContent(e.target.value, "passportnumber")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Passport  Issuing Country ">
                          <Input
                            placeholder="Passport  Issuing Country"
                            value={content.passportcountry}
                            onChange={(e) =>
                              getContent(e.target.value, "passportcountry")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Passport Expiry">
                          <DatePicker
                            style={{ width: "100%" }}
                            placeholder="Passport Expiry Date"
                            format={dateFormat}
                            disabledDate={disabledPastDate}
                            value={content.passportexpiry}
                            onChange={(e) => {
                              getContent(e, "passportexpiry");
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>

                  <Panel header={"Travel Details"} key="5">
                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Departure From">
                          <Input
                            placeholder="Departure From"
                            value={content.departurefrom}
                            onChange={(e) =>
                              getContent(e.target.value, "departurefrom")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Destination Country">
                          <Input
                            placeholder="Destination Country"
                            value={content.destinationCountry}
                            onChange={(e) =>
                              getContent(e.target.value, "destinationCountry")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Destination City">
                          <Input
                            placeholder="Destination City"
                            value={content.destinationCity}
                            onChange={(e) =>
                              getContent(e.target.value, "destinationCity")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Adult(s) Count">
                          <Input
                            placeholder="Adult(s)"
                            value={content.adults}
                            onChange={(e) =>
                              getContent(e.target.value, "adults")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Child Count">
                          <Input
                            placeholder="Child Count"
                            value={content.child}
                            onChange={(e) =>
                              getContent(e.target.value, "child")
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col className="gutter-row" md={6} xs={24}>
                        <Form.Item label="Infant(s) Count">
                          <Input
                            placeholder="Infant(s) Count"
                            value={content.infants}
                            onChange={(e) =>
                              getContent(e.target.value, "infants")
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </>
            )}

            <Row gutter={16} className="mt-3">
              <Col className="gutter-row" md={12} xs={24}>
                <Form.Item
                  label="Status"
                  name="status"
                  rules={[{ required: true }]}
                >
                  <Radio.Group>
                    {isConversationMode == false && IsEditMode == false && (
                      <Radio value={"Created"}>Created</Radio>
                    )}

                    {(isConversationMode == true || IsEditMode == true) && (
                      <>
                        <Radio
                          value={"Hot"}
                          disabled={lead.status == "Confirmed" ? true : false}
                        >
                          Hot
                        </Radio>
                        <Radio
                          value={"Confirmed"}
                          disabled={lead.status == "Hot" ? true : false}
                        >
                          Confirmed
                        </Radio>
                        <Radio
                          value={"Not Confirmed"}
                          disabled={
                            lead.status == "Confirmed" || lead.status == "Hot"
                              ? true
                              : false
                          }
                        >
                          Not Confirmed
                        </Radio>
                      </>
                    )}
                  </Radio.Group>
                </Form.Item>
              </Col>

              {isConversationMode == false && (
                <Col className="gutter-row" md={12} xs={24}>
                  <Form.Item
                    label="Send Mail"
                    name="sendMail"
                    rules={[{ required: false }]}
                  >
                    <Radio.Group>
                      <Radio value={1}>Send Mail</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
            </Row>

            <Row gutter={16}>
              {IsEditMode == false && (
                <Col className="gutter-row" md={12} xs={24}>
                  <Form.Item
                    label="Conversation"
                    name="conversation"
                    rules={[{ required: true }]}
                  >
                    <Input.TextArea
                      placeholder="Conversation"
                      rows={3}
                    ></Input.TextArea>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </div>
        </Form>
        <div>
          {isConversationMode == true &&
            conversation?.length > 0 &&
            conversation?.map((item) => {
              return (
                <div className="ConversationData">
                  <Row gutter={12}>
                    <Col span={4}>
                      <p className="Table">Created Date</p>
                      <p>{moment(item.created_at).format("MMM, DD-YYYY")}</p>
                    </Col>
                    <Col span={4}>
                      <p className="Table">User Name</p>{" "}
                      <p>
                        {item?.User ? item?.User?.userDetails?.FirstName : ""}
                      </p>
                    </Col>
                    <Col span={4}>
                      <p className="Table">Modified Date</p>{" "}
                      <p>{moment(item.created_at).format("MMM, DD-YYYY")}</p>
                    </Col>
                    <Col span={4}>
                      <p className="Table">Status</p> <p>{item.status}</p>
                    </Col>
                    <Col span={4}>
                      <p className="Table">Service Type</p>{" "}
                      <p>{selectServiceType(item.serviceType)}</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <p style={{ fontWeight: "600" }}>{item.conversation}</p>
                    </Col>
                  </Row>
                </div>
              );
            })}
        </div>
      </Modal>
    </>
  );
};

export default UpdatedComponent(Crm);
