import React, { createContext, useState, useContext } from "react";
import { useLocalStorage } from "../../../helpers/useStorage";
import { useSessionStorage } from "../../../helpers/useStorage";

export const HotelContext = createContext();

export function useHotelContext() {
  return useContext(HotelContext);
}

const HotelProvider = ({ children }) => {
  const [hotelCheckOutData, setHotelCheckOutData] = useSessionStorage(
    "hotelcheckoutdata",
    {}
  );
  // const [selectedHotel, setSelectedHotel] = useLocalStorage(
  //   "selectedhotel",
  //   {}
  // );

  return (
    <HotelContext.Provider
      value={{
        state: { hotelCheckOutData },
        setHotelCheckOutData,
      }}
    >
      {children}
    </HotelContext.Provider>
  );
};
export default HotelProvider;
