import React, { useState, useEffect } from "react";
import "./ActivitiesFilter.scss";
import { Card, Checkbox, Collapse, Rate, Slider } from "antd";

const { Panel } = Collapse;

const ActivitiesFilter = ({ count, data, setToursData }) => {
  const listOfTours = data;
  const [priceRange, setPriceRange] = useState([]);
  const [filters, setFilters] = useState({});

  const [resultCount, setResultCount] = useState(count);

  const filtersObj = {
    price: { minPrice: 0, maxPrice: 0, maxPriceRange: 0, minPriceRange: 0 },
    cityTourType: [],
    rating: [
      {
        id: 0,
        label: "0 Star",
        isChecked: false,
      },
    ],
  };

  const onChange = (event, filterType, filterId) => {
    let { checked } = event.target;

    setFilters({
      ...filters,
      [filterType]: filters[filterType].map((filt) =>
        filt.id == filterId ? { ...filt, isChecked: checked } : filt
      ),
    });
    scrollToListTop();
  };

  //Scroll to Top of the List
  const scrollToListTop = () => {
    document.getElementsByClassName("list-container")[0].scrollIntoView({
      behavior: "smooth",
    });
  };

  const checkIfExist = (filterTypeObj, id) =>
    filterTypeObj.filter((obj) => obj["id"] === id).length === 0;

  const setDynamicFilters = () => {
    for (let i = 0; i < listOfTours.length; i++) {
      let TourTotPrice = Math.round(Number(listOfTours[i].amount));

      if (i === 0) {
        filtersObj.price.minPrice = TourTotPrice;
      }
      let minFare = TourTotPrice;
      if (TourTotPrice > filtersObj.price.maxPrice) {
        filtersObj.price.maxPrice = filtersObj.price.maxPriceRange =
          TourTotPrice;
      }
      if (minFare < filtersObj.price.minPrice) {
        filtersObj.price.minPrice = minFare;
      }
      filtersObj.price.minPriceRange = filtersObj.price.minPrice;

      const { cityTourType, cityTourTypeId } = listOfTours[i];
      if (cityTourType)
        checkIfExist(filtersObj.cityTourType, cityTourType) &&
          filtersObj.cityTourType.push({
            id: cityTourTypeId,
            label: `${cityTourType}`,
            isChecked: false,
          });

      //Setting Star Rating Filters
      const { rating } = listOfTours[i];
      if (rating)
        checkIfExist(filtersObj.rating, rating) &&
          filtersObj.rating.push({
            id: rating,
            label: `${rating} Star`,
            isChecked: false,
          });
    }

    //Sort the Rating Filter
    filtersObj.rating.sort((a, b) => a.id - b.id);
    filtersObj.cityTourType = filtersObj.cityTourType.filter(
      (thing, index, self) => index === self.findIndex((t) => t.id === thing.id)
    );

    setFilters(filtersObj);
    setPriceRange([filtersObj.price.minPrice, filtersObj.price.maxPrice]);
  };

  const checkedFilters = (filterType) => {
    return filters[filterType].filter((filter) => filter.isChecked);
  };

  const applyFilters = () => {
    let visibleCount = 0;

    let data = listOfTours.map((tour) => {
      let isVisible = true;
      const startingFare = Math.round(Number(tour.amount));
      if (
        !(
          startingFare >= filters.price.minPrice &&
          startingFare <= filters.price.maxPrice
        )
      ) {
        isVisible = false;
      }

      const cityTourTypeChecked = checkedFilters("cityTourType");

      if (
        cityTourTypeChecked.length &&
        !cityTourTypeChecked
          .map((ratg) => ratg.id)
          .includes(tour.cityTourTypeId)
      ) {
        isVisible = false;
      }

      const ratingChecked = checkedFilters("rating");
      if (
        ratingChecked.length &&
        !ratingChecked.map((ratg) => ratg.id).includes(tour.rating)
      ) {
        isVisible = false;
      }

      isVisible && visibleCount++;

      return { ...tour, isVisible: isVisible };
    });
    data = data.filter((item) => item.isVisible);
    setToursData(data);
    setResultCount(visibleCount);
  };

  useEffect(() => {
    setDynamicFilters();
  }, [count]);

  useEffect(() => {
    Object.keys(filters).length && applyFilters();
  }, [filters]);

  const handleClear = (filterType) => {
    let initFilterType;
    if (filterType === "price") {
      initFilterType = {
        ...filters[filterType],
        minPrice: filters.price.minPriceRange,
        maxPrice: filters.price.maxPriceRange,
      };
      setPriceRange([filters.price.minPriceRange, filters.price.maxPriceRange]);
    } else {
      initFilterType = filters[filterType].map((filt) => ({
        ...filt,
        isChecked: false,
      }));
    }
    setFilters({
      ...filters,
      [filterType]: initFilterType,
    });
    scrollToListTop();
  };

  const extraPanel = (filterType) => (
    <span
      onClick={(e) => {
        e.stopPropagation();
        handleClear(filterType);
      }}
    >
      Clear
    </span>
  );
  const priceChangeCompleteHandler = (priceVal) => {
    setFilters({
      ...filters,
      price: { ...filters.price, minPrice: priceVal[0], maxPrice: priceVal[1] },
    });
  };

  const priceChangeHandler = (price) => {
    setPriceRange(price);
  };

  function getCount(key, val) {
    switch (key) {
      case "rating":
        return listOfTours.filter((item) => item.rating === val).length;

      default:
        return 0;
    }
  }

  return (
    <>
      <div className="side-bar e-hide">
        <div className="filter-elements">
          <div className="filter-top-bar">
            <span className="results-count">
              Showing {resultCount} Of {count} Activities
            </span>
            <br />
            <br />
            <span>Filters</span>
            <span className="clearall">Clear all</span>
          </div>
        </div>
      </div>
      <div>
        <Card>
          <div className="flight-filters slider-icon-1">
            <div className="flight-result-indicator">
              <p>
                Showing {resultCount} Of {count} Activities
              </p>
            </div>
            <div className="overall-filter-container">
              <div className="overall-filter-header">
                <p className="filter-text">Filters</p>
                <p className="clear-text" onClick={setDynamicFilters}>
                  Clear all
                </p>
              </div>
              <div className="overall-filter-body">
                <div className="stops-filter">
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel header="Price" key="1" extra={extraPanel("price")}>
                      {filters.price && (
                        <Slider
                          range
                          step={1}
                          defaultValue={[
                            filters.price.minPrice,
                            filters.price.maxPrice,
                          ]}
                          value={priceRange}
                          min={filters.price.minPriceRange}
                          max={filters.price.maxPriceRange}
                          onChange={priceChangeHandler}
                          onAfterChange={priceChangeCompleteHandler}
                        />
                      )}
                      <div className="slider-label">
                        <span>₹ {priceRange[0]}</span>
                        <span>₹ {priceRange[1]}</span>
                      </div>
                    </Panel>
                  </Collapse>
                </div>
                <div className="stops-filter tripAd-filters">
                  <Collapse
                    className="customscroll"
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header="Tour Category"
                      key="1"
                      extra={extraPanel("cityTourType")}
                    >
                      {filters.cityTourType &&
                        filters.cityTourType.map((ratg) => (
                          <p key={"cityTourType" + ratg.id}>
                            <Checkbox
                              checked={ratg.isChecked}
                              onChange={(e) =>
                                onChange(e, "cityTourType", ratg.id)
                              }
                            >
                              {ratg.label}
                            </Checkbox>
                          </p>
                        ))}
                    </Panel>
                  </Collapse>
                </div>

                <div className="stops-filter star-filters">
                  <Collapse
                    defaultActiveKey={["1"]}
                    expandIconPosition={"right"}
                  >
                    <Panel
                      header={"Star Rating"}
                      key="1"
                      extra={extraPanel("rating")}
                    >
                      {filters.rating &&
                        filters.rating.map((ratg) => (
                          <p key={"rating" + ratg.id}>
                            <Checkbox
                              checked={ratg.isChecked}
                              onChange={(e) => onChange(e, "rating", ratg.id)}
                            >
                              {/* {ratg.label} */}
                              <Rate
                                className="starRating"
                                disabled
                                value={Number(ratg.id)}
                                allowHalf={true}
                              />
                              <span className="count">
                                ({getCount("rating", ratg.id)})
                              </span>
                            </Checkbox>
                          </p>
                        ))}
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default ActivitiesFilter;
