import React, { useState, useCallback, useEffect } from "react";
import { Form, Spin, Select } from "antd";
import APIClient from "../../helpers/ApiClient";
import queryString from "query-string";
const { Option } = Select;
const ActivitiesAutoComplete = (props) => {
  useEffect(() => {
    let value = props.refName.current.props.value;

    if (!value || value == "" || value == " ") {
      return;
    }
    const hotelSearchParams = value.split("&&");
    let city = hotelSearchParams[1].split("-")[0] ?? "";
    let cityCode = hotelSearchParams[1].split("-")[1] ?? "";
    fetchData(city, cityCode);
  }, props.refName.current);

  const onSelect = () => {
    if (props.focusRef) {
      props.handleOnSubmit(props.focusRef);
    }
  };

  const [details, setDetails] = useState({
    data: [],

    fetching: false,
  });
  const debounceOnChange = useCallback(debounce(fetchData, 800), []);

  const createOptions = (results, cityId) => {
    let arr = [];

    if (cityId != null) {
      results = results.filter((item) => item.cityId == cityId);
    }

    results.forEach((result) => {
      let country = `${result.countryName}-${result.countryId}`;
      let city = `${result.cityName}-${result.cityId}`;
      arr.push({
        cityId: `${country}&&${city}`,
        cityName: `${result.cityName},${result.countryName}`,
      });
    });

    return arr;
  };

  function fetchData(value, cityId = null) {
    setDetails({ data: [], fetching: true });
    APIClient.get(`${props.api}${value}`)
      .then((res) => {
        if (res.status === 200 && res) {
          setDetails({
            data: createOptions(res.data, cityId),
            fetching: false,
          });
          return;
        }
        setDetails({ data: [], fetching: false });
      })
      .catch((error) => {
        console.error(error);
        setDetails({ data: [], fetching: false });
      });
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, wait);
    };
  }
  return (
    <Form.Item {...props.formItemProps}>
      <Select
        style={{ width: "100%" }}
        showSearch
        ref={props.refName}
        notFoundContent={
          details.fetching ? <Spin size="small" /> : "No Matches found."
        }
        filterOption={false}
        onSearch={debounceOnChange}
        {...props.selectProps}
        onSelect={onSelect}
        defaultActiveFirstOption
      >
        {details.data.map((d) => (
          <Option value={d.cityId} key={"activityKey" + d.cityId}>
            {d.cityName}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default ActivitiesAutoComplete;
