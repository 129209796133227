import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import airasia from "../../assets/images/webcheckin/airasia.png";
import airindia from "../../assets/images/webcheckin/airindia.png";
import american from "../../assets/images/webcheckin/american.png";
import british from "../../assets/images/webcheckin/british.png";
import emirates from "../../assets/images/webcheckin/emirates.png";
import etihad from "../../assets/images/webcheckin/etihad.png";
import express from "../../assets/images/webcheckin/express.png";
import go from "../../assets/images/webcheckin/go.png";
import indigo from "../../assets/images/webcheckin/indigo.png";
import malasysia from "../../assets/images/webcheckin/malasysia.png";
import qatar from "../../assets/images/webcheckin/qatar.png";
import singapore from "../../assets/images/webcheckin/singapore.png";
import spicejet from "../../assets/images/webcheckin/spicejet.png";
import thai from "../../assets/images/webcheckin/thai.png";
import united from "../../assets/images/webcheckin/united.png";
import vistara from "../../assets/images/webcheckin/vistara.png";
import ApiClient from "../../helpers/ApiClient";
import "./Webcheckin.scss";

const Webcheckin = () => {
  const [allAirlines, setAllAirlines] = useState([]);

  const getAllWebcheckin = () => {
    ApiClient.get("airlinewebcheckin/getallairlinewebcheckin")
      .then((result) => {
        return result;
      })
      .then((resp) => {
        if (resp.status === 200) {
          setAllAirlines(resp.data);
        } else {
          setAllAirlines([]);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getAllWebcheckin();
  }, []);

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi web-check-in-page"></div>

      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <Row>
            <Col md={24} xs={24}>
              <div className="">
                <div className="">
                  <h2 className="web-heading-check">Web Check In</h2>
                  <span className="span-web-check">
                    Choose any of the Airline for web Check In
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          <Row gutter={[12, 12]} className="my-2">
            {allAirlines.map((item) => (
              <Col key={item.Id} md={4} xs={24}>
                <a
                  href={item.AirlineURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="list-web-checkin">
                    {item.AirlineLogo && (
                      <img
                        className="list-cc-img-web"
                        src={item.AirlineLogo}
                        alt="Indigo image"
                      />
                    )}

                    <span>{item.AirlineName}</span>
                  </div>
                </a>
              </Col>
            ))}
            {/* <Col md={4} xs={24}>
              <a
                href="https://www.goindigo.in/how-to-check-in.html?linkNav=how-to-check-in_header"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={indigo}
                    alt="Indigo image"
                  />
                  <span>Indigo</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://book.spicejet.com/Search.aspx?op=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={spicejet}
                    alt="Spice Jet image"
                  />
                  <span>Spice Jet</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://www.flygofirst.com/plan-my-trip/web-check-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img className="list-cc-img-web" src={go} alt="GoAir" />
                  <span>GoAir</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://www.airvistara.com/in/en/vistara-experience/on-ground/check-in/web-check-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={vistara}
                    alt="Air Vistara"
                  />
                  <span>Air Vistara</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://icheck.sita.aero/iCheckWebAI/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={airindia}
                    alt="Air India"
                  />
                  <span>Air India</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://icheck.sita.aero/iCheckWebAI/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={express}
                    alt="Air India Ex"
                  />
                  <span>Air India Ex</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.airasia.com/check-in/en/gb"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={airasia}
                    alt="Air Asia"
                  />
                  <span>Air Asia</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.singaporeair.com/en_UK/plan-and-book/your-booking/checkin/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={singapore}
                    alt="Singapore Air"
                  />
                  <span>Singapore Air</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://www.thaiairways.com/en_IN/manage/my_booking.page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img className="list-cc-img-web" src={thai} alt="Thai" />
                  <span>Thai</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://www.etihad.com/en-in/manage/check-in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={etihad}
                    alt="Etihad Airways"
                  />
                  <span>Etihad Airways</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://checkin.si.amadeus.net/static/PRD/MH/#/identification"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={malasysia}
                    alt="Malasysia Air"
                  />
                  <span>Malasysia Air</span>
                </div>
              </a>
            </Col>
            <Col md={4} xs={24}>
              <a
                href="https://www.emirates.com/in/english/manage-booking/online-check-in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={emirates}
                    alt="Emirates"
                  />
                  <span>Emirates</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.qatarairways.com/en/services-checking-in.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={qatar}
                    alt="Qatar Airways"
                  />
                  <span>Qatar Airways</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.britishairways.com/travel/home/public/en_in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={british}
                    alt="British Airways"
                  />
                  <span>British Airways</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.united.com/en/us/checkin"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={united}
                    alt="United Airlines"
                  />
                  <span>United Airlines</span>
                </div>
              </a>
            </Col>

            <Col md={4} xs={24}>
              <a
                href="https://www.americanairlines.in/reservation/flightCheckInViewReservationsAccess.do"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="list-web-checkin">
                  <img
                    className="list-cc-img-web"
                    src={american}
                    alt="American Airlines"
                  />
                  <span>American</span>
                </div>
              </a>
            </Col> */}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Webcheckin;
