import React, { useState } from "react";
import "./TransportFilter.scss";
import { Slider } from "antd";
import * as ANTD from "antd";
import { CaretRightOutlined } from '@ant-design/icons';
const TransportFilter = (props) => {

    const { Panel } = ANTD.Collapse;
    function callback(key) {
        console.log(key);
    }
    function onChange(e) {
        console.log(`checked = ${e.target.checked}`);
    }

    function onChange(value) {
        console.log('onChange: ', value);
    }

    function onAfterChange(value) {
        console.log('onAfterChange: ', value);
    }
    const stopsFilter =
        <div className="filter-labels">
            <p>
                <span>Stops</span>
                <span>Clear</span>
            </p>
        </div>
    const sliderFilter =
        <div className="filter-labels">
            <p>
                <span>Price</span>
                <span>Clear</span>
            </p>
        </div>
    const airlinesFilter =
        <div className="filter-labels">
            <p>
                <span>Airlines</span>
                <span>Clear</span>
            </p>
        </div>
    const faretype =
        <div className="filter-labels">
            <p>
                <span>Fare Type</span>
                <span>Clear</span>
            </p>
        </div>
    const faretypes =
        <div className="filter-labels">
            <p>
                <span>Fare Types</span>
                <span>Clear</span>
            </p>
        </div>
    const connectingLocations =
        <div className="filter-labels">
            <p>
                <span>Connecting Locations</span>
                <span>Clear</span>
            </p>
        </div>
    const departureFilter =
        <div className="filter-labels">
            <p>
                <span>Departure Time</span>
                <span>Clear</span>
            </p>
        </div>
    const arrivalfilter =
        <div className="filter-labels">
            <p>
                <span>Arrival Time</span>
                <span>Clear</span>
            </p>
        </div>
    return (
        <div>
            <ANTD.Card>
                <div className="flight-filters">
                    <div className="flight-result-indicator">
                        <p>6 Results</p>
                    </div>
                    <div className="overall-filter-container">
                        <div className="overall-filter-header">
                            <p className="filter-text">Filters</p>
                            <p className="clear-text">Clear all</p>
                        </div>
                        <div className="overall-filter-body">
                            <div className="stops-filter">
                                <ANTD.Collapse defaultActiveKey={['1']} onChange={callback}>
                                    <Panel header={stopsFilter} key="1">
                                        <p><ANTD.Checkbox onChange={onChange}>0Stop(s)</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>1Stop(s)</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>2Stop(s)</ANTD.Checkbox></p>
                                    </Panel>
                                </ANTD.Collapse>
                            </div>
                            <div className="slider-filter">
                                <ANTD.Collapse defaultActiveKey={['1']} onChange={callback}>
                                    <Panel header={sliderFilter} key="1">
                                        <ANTD.Slider
                                            range
                                            step={10}
                                            defaultValue={[20, 50]}
                                            onChange={onChange}
                                            onAfterChange={onAfterChange}
                                        />
                                    </Panel>
                                </ANTD.Collapse>
                            </div>
                            <div className="airlines-filter">
                                <ANTD.Collapse defaultActiveKey={['1']} onChange={callback}>
                                    <Panel header={departureFilter} key="1">
                                        <p><ANTD.Checkbox onChange={onChange}>08:00 - 12:00</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>12:00 - 18:00</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>18:00 - 00:00</ANTD.Checkbox></p>
                                    </Panel>
                                </ANTD.Collapse>
                            </div>
                            <div className="airlines-filter">
                                <ANTD.Collapse defaultActiveKey={['1']} onChange={callback}>
                                    <Panel header={arrivalfilter} key="1">
                                        <p><ANTD.Checkbox onChange={onChange}>00:00 - 08:00</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>08:00 - 12:00</ANTD.Checkbox></p>
                                        <p><ANTD.Checkbox onChange={onChange}>12:00 - 18:00</ANTD.Checkbox></p>
                                    </Panel>
                                </ANTD.Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </ANTD.Card>
        </div>
    )
}

export default TransportFilter;
