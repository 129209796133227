import React from "react";
import { Card, Row, Col, Button } from "antd";
import Slider from "react-slick";

const HomeSlider = ({ images }) => {
  const MobileSlidersettings = {
    arrows: false,
    speed: 1000,
    slidesToShow: images?.length > 1 ? 2 : 1,
    slidesToScroll: 1,
    initialSlide: 0,
    infinite: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <Slider {...MobileSlidersettings}>
      {images.map((item, i) => (
        <div className="div" key={i + "homebanner"}>
          <img
            className="step-img-tai"
            src={process.env.REACT_APP_BASE_URL + item.path.substring(1)}
            alt="homebanner img"
          />
          {/* <div className="rate-btn-from">
            <div>
              <Button className="book-sg-country">Book Now</Button>
            </div>
          </div> */}
        </div>
      ))}
    </Slider>
  );
};

export default HomeSlider;
