//#region lates code
import React, { useContext, useEffect, useState } from "react";
import "./FareDetails.scss";
import { Button, Card, Col, Form, Input, Row, Radio, message } from "antd";
import moment from "moment";
import { GlobalStatesContext } from "../../../common/providers";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import {
  getConvFee,
  getPromoDiscount,
} from "../../../common/AllTickets/promoConvhelper";
import APIClient from "../../../helpers/ApiClient";

import { CloseOutlined } from "@ant-design/icons";

export const getBusGrandTotal = (
  selectedBusData,
  ConvFee,
  promoData,
  selectedInsuranceData,
  redeemAmount
) => {
  let adminCommission = 0;
  let agentMarkup = 0;
  let markup = 0;
  let insuranceTotal = 0;
  let discount = 0;
  let convamount = 0;
  let redeemTotal = 0;
  let fareInfoData =
    selectedBusData.length > 0
      ? selectedBusData.map((selectBusObj) => selectBusObj.fareInfo)
      : null;

  fareInfoData = fareInfoData.filter((i) => i != undefined);

  let totalBaseFare = fareInfoData.reduce(
    (acc, value) => Number(value.baseFare) + Number(acc),
    0
  );

  let totalGst = fareInfoData.reduce(
    (acc, value) => Number(value.gst) + Number(acc),
    0
  );

  let totalCommission = fareInfoData.reduce(
    (acc, value) => Number(value.commission) + Number(acc),
    0
  );

  let total = Number(totalBaseFare) + Number(totalGst);

  convamount = getConvFee(ConvFee, total);

  if (
    selectedInsuranceData.status === 1 &&
    selectedInsuranceData?.serviceType === 3
  ) {
    insuranceTotal =
      selectedBusData[0].seatsInfo.length *
      Number(selectedInsuranceData.amount);
  }

  total = total + Number(convamount);

  discount = getPromoDiscount(promoData, total);
  total -= discount;
  if (redeemAmount?.CouponAmt) {
    redeemTotal = Number(redeemAmount?.CouponAmt);
  }
  total -= redeemTotal;
  let totalFareCalculations = Number(
    Number(total) + Number(insuranceTotal)
  ).toFixed(2);

  agentMarkup = fareInfoData.reduce(
    (acc, value) => Number(value.agentMarkup) + Number(acc),
    0
  );

  adminCommission = fareInfoData.reduce(
    (acc, value) => Number(value.adminCommission) + Number(acc),
    0
  );
  markup = fareInfoData.reduce(
    (acc, value) => Number(value.markup) + Number(acc),
    0
  );
  if (totalCommission) totalFareCalculations -= totalCommission;

  return {
    totalBaseFare: Number(totalBaseFare).toFixed(2),
    totalGst: Number(totalGst).toFixed(2),
    discount: Number(discount).toFixed(2),
    totalFareCalculations,
    totalServiceAmount: Number(total).toFixed(2),
    convamount: Number(convamount).toFixed(2),
    totalCommission: totalCommission ? Number(totalCommission) : 0,
    markup,
    agentMarkup,
    adminCommission,
    insuranceTotal: Number(insuranceTotal).toFixed(2),
    redeemTotal: redeemTotal,
  };
};

const FareDetails = ({ selectedBusData, isPromoVisible }) => {
  const [form] = Form.useForm();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const {
    state: {
      otherData: { ConvFee, promoData, selectedInsuranceData, redeemAmount },
    },
    AddConvFee,
    validatePromoCode,
    validateRedeemCoupon,
    RemovePromo,
    getInsuranceByServiceType,
    RemoveRedeemCoupon,
  } = useContext(GlobalStatesContext);

  const { activeCurrency, currencyValue } = useCurrencyContext();
  const [promoCode, setPromoCode] = useState([]);
  const [redeemData, setRedeemData] = useState({});
  let dateFormat = "YYYY-MM-DD";
  let {
    totalBaseFare,
    totalGst,
    discount,
    convamount,
    totalFareCalculations,
    totalServiceAmount,
    insuranceTotal,
    totalCommission,
    redeemTotal,
  } = getBusGrandTotal(
    selectedBusData,
    ConvFee,
    promoData,
    selectedInsuranceData,
    redeemAmount
  );

  useEffect(() => {
    getInsuranceByServiceType(3);
  }, []);

  useEffect(() => {
    if (!agent) {
      AddConvFee(3);
      getpromo();
    }
  }, [agent]);

  useEffect(() => {
    if (user) {
      getRedeemCoupon(user);
    }
  }, [user]);

  const getpromo = () => {
    APIClient.get("admin/promo")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter((item) =>
            moment(item.ValidTill, dateFormat).isSameOrAfter(moment())
          );
          if (data.length > 0) {
            let busPromo = data.filter(
              (promo) =>
                promo.ServiceType === 3 &&
                (promo.ApplicationType === 1 || promo.ApplicationType === 3)
            );

            setPromoCode(busPromo);
          }
        }
      })
      .catch((error) => {
        setPromoCode([]);

        console.error(error);
      });
  };

  const getRedeemCoupon = (userData) => {
    setRedeemData({});
    let obj = {
      userId: userData?.UserID,
      roleType: userData?.Role?.RoleId,
      membershipId: userData?.Membership,
    };
    APIClient.post("admin/userAmtWithReedemPer", obj)
      .then((res) => {
        if (res.status === 200) {
          setRedeemData(res.data);
        }
      })
      .catch((error) => {
        setRedeemData({});
      });
  };

  const handleApply = (code) => {
    form.setFieldsValue({
      promo: code,
    });

    form.submit();
  };

  return (
    <>
      <div className="busFareDetail_wrapper">
        <Card>
          <div className="price_wrapper">
            <p className="text">Base Fare</p>
            <p className="price">
              {activeCurrency}{" "}
              {totalBaseFare ? currencyValue(totalBaseFare) : ""}
            </p>
          </div>

          <div className="price_wrapper">
            <p className="text">GST Amount</p>
            <p className="price">
              {activeCurrency} {totalGst ? currencyValue(totalGst) : ""}
            </p>
          </div>
          {!agent && user?.Role?.RoleLevel !== 3 && (
            <>
              <div className="price_wrapper">
                <p className="text">Convenience Fee</p>
                <p className="price">
                  {activeCurrency} {currencyValue(convamount)}
                </p>
              </div>
              {promoData.status && (
                <div className="price_wrapper">
                  <p className="text">Discount</p>
                  <p className="price">
                    - {activeCurrency}{" "}
                    {promoData.status ? currencyValue(discount) : " 0.00"}
                  </p>
                </div>
              )}
            </>
          )}

          <div className="price_wrapper total_price">
            <p className="text">Total:</p>
            <p className="price">
              {activeCurrency} {currencyValue(totalServiceAmount)}
            </p>
          </div>
        </Card>
      </div>

      <div className="busFareDetail_wrapper">
        <Card>
          {selectedInsuranceData.status === 1 &&
          selectedInsuranceData?.serviceType === 3 ? (
            <div className="price_wrapper">
              <p className="text">Insurance Amount</p>
              <p className="price">
                {activeCurrency} {currencyValue(insuranceTotal)}
              </p>
            </div>
          ) : null}

          <div className="price_wrapper total_price">
            <p className="text">Grand Total:</p>
            <p className="price">
              {activeCurrency}{" "}
              {totalFareCalculations
                ? currencyValue(totalFareCalculations)
                : ""}
            </p>
          </div>
          {agent ? (
            <div className="price_wrapper total_price">
              <p className="price-comm">Commission Earned:</p>
              <p className="price-comm">
                {activeCurrency}{" "}
                {totalCommission ? currencyValue(totalCommission) : ""}
              </p>
            </div>
          ) : null}
        </Card>
      </div>

      {!agent && user?.Role?.RoleLevel !== 3 && isPromoVisible ? (
        <div className="buspromo_wrapper busFareDetail_wrapper">
          {promoData.status == false ? (
            <Card>
              <p className="name">Apply Promo</p>

              <Form
                layout="vertical"
                form={form}
                onFinish={(d) => {
                  validatePromoCode({
                    ServiceType: 3,
                    Amount: Number(totalServiceAmount),
                    PromoCode: d.promo,
                    UserId: user?.UserID ?? 1,
                  });
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col md={16} sm={12} xs={12}>
                    <Form.Item name="promo">
                      <Input
                        className="inputbg"
                        placeholder="Enter Your Promo code"
                        autoComplete="off"
                      />
                    </Form.Item>
                  </Col>
                  <Col md={8} sm={12} xs={12}>
                    <Form.Item>
                      <Button type="primary" onClick={() => form.submit()}>
                        Apply
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              {promoCode.length ? (
                promoCode.map((item, key) => {
                  return (
                    <>
                      <div className="promo-cp-coupons" key={key}>
                        <div className="inline-cp-promo">
                          <Form>
                            <Form.Item>
                              <Radio
                                onClick={() => handleApply(item.Code)}
                              ></Radio>
                            </Form.Item>
                          </Form>
                          <p className="promo-key-cp">{item.Code}</p>
                          {item.DiscountType === 1 ? (
                            <p className="save-cp-offer">
                              Save {item.Discount + " %"}
                            </p>
                          ) : (
                            <p className="save-cp-offer">
                              Save {activeCurrency}&nbsp;
                              {currencyValue(item.Discount)}
                            </p>
                          )}
                        </div>

                        <div className="promo-percentage-cp">
                          <p>{item.Description}</p>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="promo-cp-coupons">
                  <div className="promo-percentage-cp pl-0 pt-0">
                    <p>No Promo Code Available</p>
                  </div>
                </div>
              )}
            </Card>
          ) : (
            <Card className="flight-cards-details">
              <div className="promo-card-header">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  className="name"
                >
                  Promo Coupon{" "}
                  <CloseOutlined
                    onClick={() => {
                      RemovePromo();
                      form.resetFields();
                    }}
                  />
                </p>
              </div>
              <div className="promo-input">
                <p className="mb-0">
                  <span className="applied"> {promoData.Code} </span> Promo Code
                  Applied
                </p>
              </div>
            </Card>
          )}
        </div>
      ) : null}
      {user && redeemData?.couponAmt > 0 ? (
        redeemAmount.status === true ? (
          <Card className="mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <p className="name mb-0">
                Redeemed From Coupon Wallet: {redeemTotal}
              </p>
              {!isPromoVisible ? null : (
                <CloseOutlined
                  onClick={() => {
                    RemoveRedeemCoupon();
                  }}
                />
              )}
            </div>
          </Card>
        ) : !isPromoVisible ? null : (
          <Card>
            <p className="font-weight-bold mb-0">
              Coupon Wallet: {/* {activeCurrency} */}
              {"("}
              {redeemData?.couponAmt} {")"}
            </p>
            {/* <p className="mb-2">
              You can use {redeemData?.reedemPercentage}% of the Total Amount
            </p> */}

            <div className="btn_wrapper">
              {" "}
              <Button
                type="primary"
                onClick={() => {
                  validateRedeemCoupon({
                    userId: user?.UserID ?? 0,
                    roleType: user?.Role?.RoleId ?? 0,
                    membershipId: user?.Membership ?? 0,
                    couponAmt: redeemData?.couponAmt ?? 0,
                  });
                }}
              >
                Redeem Coupon
              </Button>
            </div>
          </Card>
        )
      ) : null}
    </>
  );
};
export default FareDetails;
