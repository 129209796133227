import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Form,
  Select,
  Table,
  message,
  Popconfirm,
} from "antd";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import ApiClient from "../../helpers/ApiClient";
import { SendOutlined } from "@ant-design/icons";
import "../Promo/Promo.scss";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

const ManageAirlines = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [providersList, setProvidersList] = useState([]);
  useEffect(() => {
    getProviders();
    getPrevData();
  }, []);

  const getPrevData = () => {
    ApiClient.get("admin/providerAirline")
      .then((res) => {
        if (res.status == 200) {
          // let filterProvider = res.data.filter(
          //   (item) => item.ServiceType === 1
          // );
          setDataSource(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getProviders = () => {
    ApiClient.get("admin/providers")
      .then((res) => {
        if (res.statusCode == 200) {
          let filterProvider = res.data.filter(
            (item) => item.ServiceType === 1
          );
          setProvidersList(filterProvider);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const onProviderChange = () => {
    form.setFieldsValue({
      AirLines: undefined,
    });
  };

  // const getAirlines = (pId) => {
  //   let arr = [""];
  //   dataSource.forEach((item, index) => {
  //     if (item.ProviderId == pId) {
  //       arr = JSON.parse(item.AirLines);
  //     }
  //   });
  //   // if (arr.length > 0) {
  //   //   let temp = arr[0].split(",");

  //   //   return temp;
  //   // }
  //   let temp = arr[0].split(",");
  //   return temp;
  // };

  const makeString = (arr) => {
    let str = "";
    arr.forEach((i) => {
      if (i === arr[arr.length - 1]) {
        str += i;
      } else {
        str += i + ",";
      }
    });
    return str;
  };

  const onFormSubmit = async (values) => {
    let data = {
      ProviderName: getProviderName(values.ProviderId),
      Remarks: "string",
      CreatedBy: 0,
      ModifiedBy: 0,
      ProviderId: values.ProviderId,
      AirLines: [makeString(values.AirLines)],
    };

    const response = await ApiClient.post("admin/providerAirline", data);
    if (response.status == 400) {
      updateAirline(data, getIdFromSource(data.ProviderId));
    } else {
      if (response.status === 200) {
        getPrevData();
        message.success("Airlines added successfully !", 4);
        form.resetFields();
      } else {
        message.error("Something went wrong !", 4);
      }
    }
  };

  const updateAirline = (data, id) => {
    ApiClient.put("admin/providerAirline/" + id, {}, data).then((res) => {
      if (res.status === 200) {
        getPrevData();
        message.success("Updated successfully !");
      } else {
        message.error("Something went wrong !");
      }
    });
  };

  const getIdFromSource = (i) => {
    let id = -1;
    dataSource.forEach((item, index) => {
      if (item.ProviderId == i) {
        id = item.ID;
        return;
      }
    });

    return id;
  };
  const getProviderName = (id) => {
    let res = "";

    providersList.forEach((item) => {
      if (item.ProviderID == id) {
        res = item.Provider;
      }
    });
    return res;
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "SNo",
      key: "SNo",
      render: (text, object, index) => {
        return index + 1;
      },
    },

    {
      title: "ProviderName",
      dataIndex: "ProviderName",
      // sorter: (a, b) => a.ProviderName.localeCompare(b.ProviderName),
    },
    {
      title: "AirLines",
      dataIndex: "AirLines",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "name",
      render: (status) => getStatus(status),
    },
    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
  ];

  const getStatus = (status) => {
    return (
      <div style={!status ? { color: "#008000" } : { color: "#ff0000" }}>
        <b>{!status ? "Active" : "Inactive"}</b>
      </div>
    );
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              updateFormData(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div> */}
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteManageAirline(rec.ID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const deleteManageAirline = (id) => {
    ApiClient.delete("admin/providerAirline/" + id).then((res) => {
      if (res.status === 200) {
        getPrevData();
        message.success("deleted successfully !", 4);
      } else {
        message.error("Something went wrong !", 4);
      }
    });
  };

  return (
    <Row style={{ padding: "34px" }} justify="center">
      <Col md={24}>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Row>
            <Col xs={24} md={6} className="mx-2">
              <Form.Item label="Select Provider" name="ProviderId">
                <Select onChange={onProviderChange}>
                  {providersList.map((data, index) => (
                    <Option key={index} value={data.ProviderID}>
                      {data.Provider}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={6} className="mx-2">
              <AirlineAutoCompleteSelect
                formItemProps={{
                  label: "AirLines",
                  name: "AirLines",
                }}
                selectProps={{
                  placeholder: "Search Airline Name",
                  mode: "tags",
                }}
                api={"flights/airlineNames/search/"}
                keytext="nameAirline"
                keyvalue="nameAirline"
              />
            </Col>
            <Col xs={24} md={6} className="mx-2 mt-4">
              <Button
                htmlType="submit"
                style={{
                  backgroundColor: "#f39c12",
                  display: "flex",
                  alignItems: "center",
                  color: "#fff",
                  borderRadius: 30,
                  fontSize: 14,
                  fontWeight: 700,
                  padding: "18px",
                }}
              >
                Submit <SendOutlined />
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
      <Col span={24} md={24}>
        <div className="promo providerapi">
          <div className="manage-markup-section">
            <div className="admin-container">
              <Row>
                <Col span={24}>
                  <Card>
                    <Table
                      className="table-scroll-none"
                      style={{ color: "red" }}
                      // columns={columnsusers}
                      bordered
                      // rowKey={uniqueKey}
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default ManageAirlines;
