import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Table,
  Upload,
  Popover,
} from "antd";
import ReactQuill from "react-quill";
import modules from "../../helpers/reactQuillModules";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../helpers/ApiClient";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";

import {
  EditOutlined,
  PlusCircleFilled,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
const dateFormat = "DD/MM/YYYY";
const Blog = (props) => {
  const [content, setContent] = useState({ Description: "" });

  const BASE = process.env.REACT_APP_BASE_URL;
  const uploadActionUrl = BASE + "media/upload/multipleImages";
  const { Content } = Layout;
  const [modalVisible, setModalVisible] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  const [showSearchBox, setShowSearchBox] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [blogId, setblogId] = useState(null);
  const [form] = Form.useForm();

  const initialImages = {
    previewVisible: false,
    previewImage: "",
    previewTitle: "",
    fileList: [],
  };
  const [blogTitleImage, setBlogTitleImage] = useState(initialImages);

  const [blogTitleBigImage, setBlogTitleBigImage] = useState(initialImages);

  const formItemLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 23 },
  };

  const showModal = () => {
    if (isEdit) {
      setIsEdit(false);
      form.resetFields();
      setBlogTitleImage(initialImages);
      setBlogTitleBigImage(initialImages);
    }
    setModalVisible({
      visible: true,
    });
  };

  const editBlog = (record) => {
    setIsEdit(true);
    setblogId(record.BlogID);
    let blogTitleImage = [
      {
        uid: "1",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.BlogTitleImage1.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.BlogTitleImage1],
          },
        },
      },
      {
        uid: "2",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.BlogTitleImage2.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.BlogTitleImage2],
          },
        },
      },
    ];
    let blogTitleBigImage = [
      {
        uid: "1",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.BlogTitleBigImage1.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.BlogTitleBigImage1],
          },
        },
      },
      {
        uid: "2",
        name: "slide.jpg",
        status: "done",
        url: BASE + record.BlogTitleBigImage2.substring(1),
        response: {
          status: 200,
          data: {
            filepaths: [record.BlogTitleBigImage2],
          },
        },
      },
    ];
    setContent({ Description: record.Description });
    form.setFieldsValue({
      BlogCategoryName: record.BlogCategoryName,
      BlogStatus: record.BlogStatus,
      BlogTitle: record.BlogTitle,
      BlogTitleImage: { fileList: blogTitleImage },
      CountryName: record.CountryName,
      BlogTitleBigImage: { fileList: blogTitleBigImage },
      Tags: record.Tags,
      // Description: record.Description,
      PostByName: record.PostByName,
      PostedDate: moment(record.PostedDate),
      ShowonHomePage: record.ShowonHomePage,
    });

    setBlogTitleImage((prev) => ({ ...prev, fileList: blogTitleImage }));
    setBlogTitleBigImage((prev) => ({ ...prev, fileList: blogTitleBigImage }));
    setModalVisible(true);
  };

  /********** Uploading Image **********/
  const handleCancel = (type) => {
    if (type === "BlogTitleImage") {
      setBlogTitleImage((prev) => ({ ...prev, previewVisible: false }));
    } else if (type === "BlogTitleBigImage") {
      setBlogTitleBigImage((prev) => ({ ...prev, previewVisible: false }));
    }
  };

  const handlePreview = async (file, type) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    if (type === "BlogTitleImage") {
      setBlogTitleImage((prev) => ({
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle:
          file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      }));
    } else if (type === "BlogTitleBigImage") {
      setBlogTitleBigImage((prev) => ({
        ...prev,
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle:
          file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
      }));
    }
  };

  const handleChange = (data, type) => {
    if (type === "BlogTitleImage") {
      setBlogTitleImage((prev) => ({ ...prev, fileList: data.fileList }));
    } else if (type === "BlogTitleBigImage") {
      setBlogTitleBigImage((prev) => ({ ...prev, fileList: data.fileList }));
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => {
              editBlog(rec);
            }}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => {
              deleteBlog(rec.BlogID);
            }}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </Popconfirm>
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "Sno",
    },

    {
      title: "Blog Title",
      dataIndex: "BlogTitle",
      sorter: (a, b) => a.FromCountry.localeCompare(b.FromCountry),
    },
    {
      title: "Blog Category Name",
      dataIndex: "BlogCategoryName",
      sorter: (a, b) => a.ToCountry.localeCompare(b.ToCountry),
    },
    {
      title: "Country Name",
      dataIndex: "CountryName",
      sorter: (a, b) => a.FromAmount.localeCompare(b.FromAmount),
    },

    {
      title: "Tags",
      dataIndex: "Tags",
    },
    {
      title: "Description",
      dataIndex: "Description",
      render: (text) => (
        <div
          style={{
            maxWidth: "217px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {text}
        </div>
      ),
    },
    {
      title: "Post By Name",
      dataIndex: "PostByName",
    },
    {
      title: "Posted Date",
      dataIndex: "PostedDate",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Show on Home Page",
      dataIndex: "ShowonHomePage",
      render: (data) => (data ? "Yes" : "No"),
    },
    {
      title: "Actions",
      render: (currentDeal) => tableActions(currentDeal),
    },
    {
      title: "Blog Status",
      dataIndex: "BlogStatus",
      key: "name",
      render: (status) => getStatus(status),
    },
  ];

  const getStatus = (status) => {
    return (
      <div style={!status ? { color: "#ff0000" } : { color: "#008000" }}>
        <b>{!status ? "Inactive" : "Active"}</b>
      </div>
    );
  };

  /*==========Api Calls=====*/

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = () => {
    ApiClient.get("admin/blog")
      .then((res) => {
        if (res.statusCode === 200) {
          const data = res.data.map((item, index) => {
            return {
              ...item,
              Sno: index + 1,
            };
          });
          setDataSource(data);
        } else {
          message("Server Error");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const deleteBlog = (id) => {
    ApiClient.delete("admin/blog/" + id)
      .then((res) => {
        if (res.statusCode === 200) {
          message.success("Blog Deleted  successfully", 3);
          getBlogs();
        } else {
          message.error("Error Deleting", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitForm = (values) => {
    if (values.BlogTitleImage.fileList.length === 2) {
      if (
        values.BlogTitleImage.fileList[0].response.status !== 200 &&
        values.BlogTitleImage.fileList[1].response.status !== 200
      ) {
        form.setFields([
          {
            name: "BlogTitleImage",
            errors: ["Image Error. Change Image"],
          },
        ]);
        return;
      }
    } else {
      form.setFields([
        {
          name: "BlogTitleImage",
          errors: ["2 Images Required"],
        },
      ]);
      return;
    }

    if (values.BlogTitleBigImage.fileList.length === 2) {
      if (
        values.BlogTitleBigImage.fileList[0].response.status !== 200 &&
        values.BlogTitleBigImage.fileList[1].response.status !== 200
      ) {
        form.setFields([
          {
            name: "BlogTitleBigImage",
            errors: ["Image Error. Change Image"],
          },
        ]);
        return;
      }
    } else {
      form.setFields([
        {
          name: "BlogTitleBigImage",
          errors: ["2 Images Required"],
        },
      ]);
      return;
    }

    let formData = {
      CountryName: values.CountryName,
      BlogTitle: values.BlogTitle,
      BlogCategoryName: values.BlogCategoryName,

      BlogTitleImage1:
        values.BlogTitleImage.fileList[0].response.data.filepaths[0],
      BlogTitleImage2:
        values.BlogTitleImage.fileList[1].response.data.filepaths[0],
      BlogTitleBigImage1:
        values.BlogTitleBigImage.fileList[0].response.data.filepaths[0],
      BlogTitleBigImage2:
        values.BlogTitleBigImage.fileList[1].response.data.filepaths[0],

      Tags: values.Tags,
      Description: content.Description,
      ShowonHomePage: values.ShowonHomePage,
      BlogStatus: values.BlogStatus,
      PostByName: values.PostByName,
      PostedDate: values.PostedDate,
      CreatedBy: 0,
      ModifiedBy: 0,
      DeviceOS: 3,
      DeviceOSVersion: "string",
      DeviceToken: "string",
      ApplicationType: 1,
      FirBaseToken: "string",
    };

    isEdit ? putDealData(formData) : postCityHotel(formData);
  };

  const putDealData = (formData) => {
    ApiClient.put("admin/blog/" + blogId, {}, formData).then((res) => {
      if (res.statusCode === 200) {
        message.success("Blog Updated  successfully", 3);
        setModalVisible(false);
        form.resetFields();
        setBlogTitleImage(initialImages);
        setBlogTitleBigImage(initialImages);
        getBlogs();
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  const postCityHotel = (formData) => {
    ApiClient.post("admin/blog", formData).then((res) => {
      if (res.statusCode === 200) {
        message.success("Blog added successfully", 3);
        getBlogs();
        setModalVisible(false);
        form.resetFields();
        setBlogTitleImage(initialImages);
        setBlogTitleBigImage(initialImages);
      } else {
        message.error(" Error Uploading Data", 3);
      }
    });
  };

  /*======= Search and Export Options=====*/

  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      getBlogs();
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const handleExcel = (e) => {
    const excelCustomData = dataSource;

    props.exportExcel(excelCustomData, "dealsExcel");
  };

  const getContent = (content, type) => {
    if (type == "Description") {
      setContent({ Description: content });
    }
  };
  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          View Blogs{" "}
                          <HelpInfoHelper screenName={"/admin/blog"} />
                        </h5>
                        <p>{dataSource.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData(dataSource)}
                      />
                      <img src={excel} alt="excel" onClick={handleExcel} />
                      <img
                        src={exchange}
                        alt="exchange"
                        onClick={() => getBlogs()}
                      />
                      <p className="add-deposit-icon" onClick={showModal}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>
                  <div>
                    <Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </Content>
      </div>

      <Modal
        title={isEdit ? "Update Blog" : "Add Blog"}
        className="promo-modal-header admin-bc-level"
        visible={modalVisible}
        onCancel={(e) => setModalVisible(false)}
        width={"1100px"}
        footer={[
          <Button key="back" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={form.submit}>
            {isEdit ? "Update" : "Add"}
          </Button>,
        ]}
      >
        <Form
          form={form}
          {...formItemLayout}
          layout="vertical"
          onFinish={submitForm}
        >
          <Row>
            <Col md={6} xs={24}>
              <Form.Item
                label="Country Name"
                name="CountryName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Blog Title"
                name="BlogTitle"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input.TextArea rows={2} />
              </Form.Item>
            </Col>

            <Col md={6} xs={24}>
              <Form.Item
                label="Blog Category Name"
                name="BlogCategoryName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Tags"
                name="Tags"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Show on Home Page"
                name="ShowonHomePage"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Yes</Radio>
                  <Radio value={0}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Post By Name"
                name="PostByName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Posted Date"
                name="PostedDate"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} format={dateFormat} />
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Blog Status"
                name="BlogStatus"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>Active</Radio>
                  <Radio value={0}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Blog Title Images"
                name="BlogTitleImage"
                rules={[{ required: true, message: "Images are required" }]}
              >
                <Upload
                  name="image"
                  action={uploadActionUrl}
                  listType="picture-card"
                  fileList={blogTitleImage.fileList}
                  onPreview={(data) => handlePreview(data, "BlogTitleImage")}
                  data={{ category: "blogtitleimage" }}
                  onChange={(data) => handleChange(data, "BlogTitleImage")}
                >
                  {blogTitleImage.fileList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal
                visible={blogTitleImage.previewVisible}
                title={blogTitleImage.previewTitle}
                footer={null}
                onCancel={() => handleCancel("BlogTitleImage")}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={blogTitleImage.previewImage}
                />
              </Modal>
            </Col>
            <Col md={6} xs={24}>
              <Form.Item
                label="Blog Title Big Images"
                name="BlogTitleBigImage"
                rules={[{ required: true, message: "Images are required" }]}
              >
                <Upload
                  name="image"
                  action={uploadActionUrl}
                  listType="picture-card"
                  fileList={blogTitleBigImage.fileList}
                  onPreview={(data) => handlePreview(data, "BlogTitleBigImage")}
                  data={{ category: "blogtitlebigimage" }}
                  onChange={(data) => handleChange(data, "BlogTitleBigImage")}
                >
                  {blogTitleBigImage.fileList.length >= 2 ? null : uploadButton}
                </Upload>
              </Form.Item>
              <Modal
                visible={blogTitleBigImage.previewVisible}
                title={blogTitleBigImage.previewTitle}
                footer={null}
                onCancel={() => handleCancel("BlogTitleBigImage")}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={blogTitleBigImage.previewImage}
                />
              </Modal>
            </Col>
            <Col md={24} xs={24}>
              <Form.Item
                label="Description"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <ReactQuill
                  modules={modules}
                  value={content.Description}
                  placeholder="please input ..."
                  onChange={(e) => getContent(e, "Description")}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};
export default UpdatedComponent(Blog);
