import React, { useState, useMemo } from "react";
import * as ANTD from "antd";
import profileImg from "../assets/profileUser.png";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const Profile = ({ userData, userRole }) => {
  const { ProfilePic } = userData;
  const BASE = process.env.REACT_APP_BASE_URL;
  const [imageUrl, setImageUrl] = useState();
  const [upLoadImage, setUpLoadImage] = useState(false);
  const uploadActionUrl = BASE + "media/upload/singleImage";
  const memoizedProfilePic = useMemo(() => checkForProfilePic(ProfilePic), [
    ProfilePic,
  ]);

  function checkForProfilePic(ProfilePic) {
    if (ProfilePic && ProfilePic != null) {
      setImageUrl(BASE + ProfilePic);
      return ProfilePic;
    }
  }
  // if (
  //   userData.ProfilePic &&
  //   userData.ProfilePic != null &&
  //   userData.ProfilePic != ""
  // ) {
  //   setImageUrl(BASE + userData.ProfilePic);
  // }
  function getImages(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function handleChange(info) {
    if (info.file.status === "uploading") {
      setImageUrl(null);
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      getImages(info.file.originFileObj, (imageUrl) => {
        setImageUrl(imageUrl);
        setUpLoadImage(false);
      });
    }
  }

  const uploadLogoButton = (
    <div>
      {upLoadImage ? (
        <LoadingOutlined />
      ) : (
        <img
          src={profileImg}
          alt=""
          style={{
            width: "100%",
            height: "auto",
          }}
        />
      )}
      <div className="ant-upload-text">Upload Image</div>
    </div>
  );

  // function beforeUploadLogo(file) {
  //   const checkJpgOrPng =
  //     file.type === "image.png" || file.type === "image.jpeg";
  //   if (!checkJpgOrPng) {
  //     ANTD.message.error("You can only upload JPG/PNG file!");
  //   }

  //   const checkFileSize = file.size / 1024 / 1024 < 2;
  //   if (!checkFileSize) {
  //     ANTD.message.error(" Image must be smaller than 2MB!");
  //   }

  //   return checkJpgOrPng && checkFileSize;
  // }

  return (
    <div>
      <div className="rightProfile">
        <div className="profile_pic">
          <ANTD.Form.Item name="ProfilePic" style={{ margin: 0 }}>
            <ANTD.Upload
              name="image"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={uploadActionUrl}
              onChange={handleChange}
              data={{ category: "profilepic" }}
            >
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="avatar"
                  style={{ width: "100%", height: "auto" }}
                />
              ) : (
                uploadLogoButton
              )}
            </ANTD.Upload>
          </ANTD.Form.Item>
          <div>
            <h6 style={{ margin: "10px 0" }}>{userData.Name}</h6>
          </div>
        </div>
        <div className="profile-details">
          <div className="profile-list-item">
            <p>
              <strong>Role</strong>
            </p>
            <p>Administrator</p>
          </div>
          <div className="profile-list-item">
            <p>
              <strong>Status</strong>
            </p>
            <p>{userRole.Status == 1 ? "Active" : "InActive"}</p>
          </div>
        </div>
        <div className="profile-update">
          <div className="member-since">
            <p>Member Since</p>
            <p>
              {userRole.CreatedDate != "" && userRole.CreatedDate != null
                ? moment(userRole.CreatedDate).format("MMM DD YYYY")
                : null}
            </p>
          </div>
          <div className="update-btn">
            <ANTD.Button htmlType="submit">UPDATE</ANTD.Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Profile);
