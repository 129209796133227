import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";

const HelpInfoHelper = ({ screenName }) => {
  const [screensHelpContent, setScreensHelpContent] = useState("");

  const getScreensHelpinfo = (screenUrl) => {
    APIClient.get(`admin/getHelpInformationByurl?screenUrl=${screenUrl}`)
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status === 1) {
            setScreensHelpContent(res.data.helpDescription);
          }
        } else {
          setScreensHelpContent("");
        }
      })
      .catch((error) => {
        setScreensHelpContent("");
      });
  };
  useEffect(() => {
    if (screenName) {
      getScreensHelpinfo(screenName);
    }
  }, []);

  const helpInfoContent = (
    <div>
      <p className="mb-0">{screensHelpContent ?? ""}</p>
    </div>
  );

  return (
    screensHelpContent && (
      <Popover content={helpInfoContent} overlayClassName="help-popover">
        <InfoCircleTwoTone />
      </Popover>
    )
  );
};

export default HelpInfoHelper;
