import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  Col,
  Checkbox,
  DatePicker,
  Input,
  Layout,
  Row,
  Form,
  Select,
} from "antd";
import BuildPackageAutoComplete from "../../common/AutoCompleteSelect/BuildPackageAutoComplete";
import { useHistory } from "react-router-dom";
import { useActivitiesContext } from "../../common/providers/Activities/ActivitiesProvider";
import CountryList from "../../common/CountryList";
import HotelPassengers from "../Hotels/HotelPassengers/HotelPassengers";
import moment from "moment";
import queryString from "query-string";
import "./buildpackage.scss";

const { Content } = Layout;
const { Option } = Select;
const dateFormat = "DD-MM-YYYY";

const Buildpackagesearchnew = ({
  buildPackageSearch,
  modifySearch,
  isCartModify,
  searchReqBuildActivities = null,
}) => {
  const {
    // setSearchReqBuildActivities,
    setSelectedBuildActivities,
    setSelectedBuildHotel,
  } = useActivitiesContext();

  let history = useHistory();
  const city = useRef(null);
  const searchBtn = useRef();
  const [form] = Form.useForm();
  const [rooms, setRooms] = useState(false);
  const [searchCity, setSearchCity] = useState("");

  const [isActivityChecked, setIsActivityChecked] = useState(true);
  const [isHotelChecked, setIsHotelChecked] = useState(true);

  const defHotelPaxInfo = [
    {
      noOfAdults: 2,
      noOfChilds: 0,
      childAge: [],
    },
  ];
  const [hotelPaxInfo, setHotelPaxInfo] = useState(defHotelPaxInfo);
  const paxInfo = [...hotelPaxInfo];

  const addRoom = () => {
    paxInfo.push({
      noOfAdults: 2,
      noOfChilds: 0,
      childAge: [],
    });

    setHotelPaxInfo(paxInfo);
  };
  let updatePaxInfoFromChild = (paxInfo) => {
    setHotelPaxInfo(paxInfo);
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  const submitForm = ({ City, NoOfNights, Nationalilty, date }) => {
    if (!isCartModify) {
      let searchReq = queryString.parse(City);

      let formData = {
        ...searchReq,
        checkInDate: moment(date).format("YYYY-MM-DD"),
        checkOutDate: moment(date).add(NoOfNights, "days").format("YYYY-MM-DD"),
        roomGuests: JSON.stringify(hotelPaxInfo),
        nationality: Nationalilty,
        currency: "INR",
        traceId: "string",
        isActivitiy: isActivityChecked,
        isHotel: isHotelChecked,
      };

      //setSelectedBuildActivities([]);
     // setSelectedBuildHotel([]);

      // setSearchReqBuildActivities(formData);
      const query = queryString.stringify(formData);

      history.push("/combinedbuildpkg?" + query);
      if (modifySearch) {
        buildPackageSearch();
      }
    }
  };

  const toggleCount = () => {
    setRooms((prev) => !prev);
  };

  let getPaxCount = () => {
    return paxInfo.reduce(
      (total, pax) => total + pax.noOfAdults + pax.noOfChilds,
      0
    );
  };

  const selectPackage = (e, choosePackage) => {
    if (choosePackage == "Activities") {
      setIsActivityChecked(e.target.checked);
    }
    if (choosePackage == "hotels") {
      setIsHotelChecked(e.target.checked);
    }
  };

  useEffect(() => {
    if (modifySearch && !isCartModify) {
      const builSearchParam = queryString.parse(window.location.search);

      let roomDetails = JSON.parse(builSearchParam.roomGuests);
      let start = moment(builSearchParam.checkInDate, "YYYY-MM-DD");
      let end = moment(builSearchParam.checkOutDate, "YYYY-MM-DD");
      let city = queryString.stringify({
        city: builSearchParam.city,
        cityId: builSearchParam?.cityId,
        countryId: builSearchParam?.countryId,
        hotelCityCode: builSearchParam?.hotelCityCode,
      });
      setSearchCity(city);
      setHotelPaxInfo(roomDetails);
      form.setFieldsValue({
        City: city,
        NoOfNights: `${moment.duration(end.diff(start)).asDays()}`,
        date: moment.utc(builSearchParam.checkInDate),
        Nationalilty: builSearchParam.nationality,
      });
      setIsActivityChecked(
        ["true", "True"].includes(builSearchParam.isActivitiy)
      );
      setIsHotelChecked(["true", "True"].includes(builSearchParam.isHotel));
    }
  }, [modifySearch]);

  useEffect(() => {
    if (isCartModify) {
      if (searchReqBuildActivities) {
        let roomDetails = JSON.parse(searchReqBuildActivities.roomGuests);
        let start = moment(searchReqBuildActivities.checkInDate, "YYYY-MM-DD");
        let end = moment(searchReqBuildActivities.checkOutDate, "YYYY-MM-DD");
        let city = queryString.stringify({
          city: searchReqBuildActivities.city,
          cityId: searchReqBuildActivities?.cityId,
          countryId: searchReqBuildActivities?.countryId,
          hotelCityCode: 59695,
        });

        form.setFieldsValue({
          City: city,
          NoOfNights: `${moment.duration(end.diff(start)).asDays()}`,
          date: start,
          Nationalilty: searchReqBuildActivities.nationality,
        });
        setHotelPaxInfo(roomDetails);
        setIsActivityChecked(searchReqBuildActivities.isActivitiy);
        setIsHotelChecked(searchReqBuildActivities.isHotel);
      }
    }
  }, [isCartModify]);
  return (
    <div className="inner-parameters">
      <Layout className="">
        <Content className="admin-container cms-pages-width pdr-pkg-v3">
          <Form
            initialValues={{
              Nationalilty: "IN",
            }}
            layout="vertical"
            form={form}
            onFinish={submitForm}
          >
            <Row className="build-sectionpage-new">
              <Col
                md={6}
                xs={24}
                className="build-cols buildautocomplete one-pkg-data-2"
              >
                <BuildPackageAutoComplete
                  formItemProps={{
                    label: "Search City",
                    name: "City",
                    rules: [
                      { required: true, message: "City Name is required" },
                    ],
                  }}
                  selectProps={{
                    size: "large",
                    placeholder: "Search by Destination",
                  }}
                  api={"mapping/buildPackage/cities/"}
                  refName={city}
                  form={form}
                  searchCity={searchCity}
                  modifySearch={modifySearch}
                  isCartModify={isCartModify}
                />
              </Col>

              <Col md={6} xs={24} className="build-cols one-pkg-data-2">
                <Form.Item
                  label="Number of Nights"
                  rules={[
                    { required: true, message: "No. of Nights is required" },
                  ]}
                  name="NoOfNights"
                >
                  <Select
                    className="biuild-input modify-activity-hotel"
                    placeholder="No. of Nights"
                  >
                    <Option value="1">1 Nights</Option>
                    <Option value="2">2 Nights</Option>
                    <Option value="3">3 Nights</Option>
                    <Option value="4">4 Nights</Option>
                    <Option value="5">5 Nights</Option>
                    <Option value="6">6 Nights</Option>
                    <Option value="7">7 Nights</Option>
                    <Option value="8">8 Nights</Option>
                    <Option value="9">9 Nights</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="build-cols one-pkg-data-2">
                <Form.Item
                  label="Select Date"
                  rules={[{ required: true, message: "Date is required" }]}
                  name="date"
                >
                  <DatePicker
                    className="biuild-input"
                    placeholder="Select Date"
                    size={"large"}
                    format={dateFormat}
                    disabledDate={disabledOriginDate}
                  />
                </Form.Item>
              </Col>

              <Col md={4} xs={24} className="build-cols ">
                <Form.Item
                  label="Nationnality"
                  rules={[{ required: true }]}
                  name="Nationalilty"
                >
                  <Select
                    className="biuild-input modify-activity-hotel"
                    placeholder="Nationality"
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {CountryList.map((item) => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={4} xs={24} className="build-cols first-build-btn">
                <Form.Item>
                  {/* <Link to="/combinedbuildpkg"> */}
                  <Button
                    className="build-pk-btn inner-build-btn"
                    ref={searchBtn}
                    htmlType="submit"
                  >
                    Search Now
                  </Button>
                  {/* </Link> */}
                </Form.Item>
              </Col>
            </Row>

            <Row className="build-row-second second-bottom-row">
              <Col md={3} xs={12} className=" ha-build build-menu-ah">
                <Form.Item>
                  <Checkbox
                    checked={isActivityChecked}
                    onChange={(e) => selectPackage(e, "Activities")}
                  >
                    Activities
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col md={3} xs={12} className=" ha-build build-menu-ah">
                <Form.Item>
                  <Checkbox
                    checked={isHotelChecked}
                    onChange={(e) => selectPackage(e, "hotels")}
                  >
                    Hotels
                  </Checkbox>
                </Form.Item>
              </Col>
              {isHotelChecked ? (
                <Col md={6} xs={24} className=" build-rooms-pkg">
                  <Form.Item rules={[{ required: false }]} name="Rooms">
                    <Input
                      value={
                        "Rooms: " +
                        paxInfo.length +
                        ", Guests: " +
                        getPaxCount()
                      }
                      size="large"
                      onClick={toggleCount}
                    />
                    <div className="add-room-block">
                      <div
                        className="pax-modal"
                        id="pax-modal"
                        style={{
                          display: rooms ? "block" : "none",
                        }}
                      >
                        <div className="pax-modal-wrapper">
                          <div className="pax-modal-arrow"></div>
                          <ul className="first-item">
                            {paxInfo.map((pax, index) => (
                              <HotelPassengers
                                pax={pax}
                                key={index}
                                index={index}
                                updatePaxInfoFromChild={updatePaxInfoFromChild}
                                paxInfo={paxInfo}
                              />
                            ))}

                            <li className="add-room-btn" onClick={addRoom}>
                              Add room
                            </li>
                          </ul>

                          <div>
                            <Button
                              block
                              className="pax-ready-btn"
                              onClick={() => {
                                toggleCount();
                                if (rooms) searchBtn.current.focus();
                              }}
                            >
                              Ready
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              ) : null}

              <Col md={4} xs={24} className="build-cols last-build-btn">
                <Form.Item>
                  {/* <Link to="/combinedbuildpkg"> */}
                  <Button
                    className="build-pk-btn cp-build-search"
                    ref={searchBtn}
                    htmlType="submit"
                  >
                    Search Now
                  </Button>
                  {/* </Link> */}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Content>
      </Layout>
    </div>
  );
};
export default Buildpackagesearchnew;
