import { PlusOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Upload,
  message,
  Modal,
  Layout,
  Row,
  Popover,
  Input,
} from "antd";
import React, { useEffect, useState } from "react";
import APIClient from "../../helpers/ApiClient";
import "./Banners.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const { Content } = Layout;
const { Panel } = Collapse;
const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;

    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Banners = () => {
  const [companyForm] = Form.useForm();
  const [flightForm] = Form.useForm();
  const [footerBannerForm] = Form.useForm();
  const [bigBannerForm] = Form.useForm();
  const [smallBannerForm] = Form.useForm();
  const [flightSearchForm] = Form.useForm();
  const [busForm] = Form.useForm();
  const [busSearchForm] = Form.useForm();
  const [hotelForm] = Form.useForm();
  const [hotelSearchForm] = Form.useForm();
  const [activitySearchForm] = Form.useForm();
  const [activityForm] = Form.useForm();
  const [packageSearchForm] = Form.useForm();
  const [packageForm] = Form.useForm();
  const [companyLogo, setCompanyLogo] = useState([]);
  const [flightBanners, setFlightBanners] = useState([]);
  const [flightSearchBanners, setFlightSearchBanners] = useState([]);
  const [busBanners, setBusBanners] = useState([]);
  const [busSearchBanners, setBusSearchBanners] = useState([]);
  const [hotelBanners, setHotelBanners] = useState([]);
  const [hotelSearchBanners, setHotelSearchBanners] = useState([]);
  const [activityBanners, setActivityBanners] = useState([]);
  const [activitySearchBanners, setActivitySearchBanners] = useState([]);
  const [packBanners, setPackBanners] = useState([]);
  const [footerBanners, setFooterBanners] = useState([]);
  const [packSearchBanners, setPackSearchBanners] = useState([]);
  const [bigSliderBanners, setBigSliderBanners] = useState([]);
  const [smallSliderBanners, setSmallSliderBanners] = useState([]);
  const initialState = {
    ServiceType: 0,
    ApplicationType: 0,
    BannerType: 0,
    ModifiedBy: 0,
    CreatedBy: 0,
    imagesPath: ["string"],
  };
  const [bannerData, setbannerData] = useState(initialState);
  const submitCompanyData = (val) => {
    if (val.companyLogo) {
      if (val.companyLogo.fileList.length > 0) {
        if (val.companyLogo.fileList[0].response.status === 200) {
          let data = {
            ...bannerData,
            imagesPath: [
              val.companyLogo.fileList[0].response.data.filepaths[0],
            ],
            BannerType: 1,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFlightData = (val) => {
    if (val.flightBanners) {
      if (val.flightBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.flightBanners.fileList.length; i++) {
          if (val.flightBanners.fileList[i].response.status === 200) {
            array.push(
              val.flightBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 7,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFlightSearchBannerData = (val) => {
    if (val.flightSearchBanners) {
      if (val.flightSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.flightSearchBanners.fileList.length; i++) {
          if (val.flightSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.flightSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 13,
          };
          callPostApi(data);
        }
      }
    }
  };

  const submitFooterBannerData = (val) => {
    if (val.footerBanners) {
      if (val.footerBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.footerBanners.fileList.length; i++) {
          if (val.footerBanners.fileList[i].response.status === 200) {
            array.push(
              val.footerBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 20,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitBusData = (val) => {
    if (val.busBanners) {
      if (val.busBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.busBanners.fileList.length; i++) {
          if (val.busBanners.fileList[i].response.status === 200) {
            array.push(val.busBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 8,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitBusSearchBannerData = (val) => {
    if (val.busSearchBanners) {
      if (val.busSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.busSearchBanners.fileList.length; i++) {
          if (val.busSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.busSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 14,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHotelData = (val) => {
    if (val.hotelBanners) {
      if (val.hotelBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.hotelBanners.fileList.length; i++) {
          if (val.hotelBanners.fileList[i].response.status === 200) {
            array.push(val.hotelBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 9,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHotelSearchBannerData = (val) => {
    if (val.hotelSearchBanners) {
      if (val.hotelSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.hotelSearchBanners.fileList.length; i++) {
          if (val.hotelSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.hotelSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 15,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitActivityData = (val) => {
    if (val.activityBanners) {
      if (val.activityBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.activityBanners.fileList.length; i++) {
          if (val.activityBanners.fileList[i].response.status === 200) {
            array.push(
              val.activityBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 10,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitActivitySearchBannerData = (val) => {
    if (val.activitySearchBanners) {
      if (val.activitySearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.activitySearchBanners.fileList.length; i++) {
          if (val.activitySearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.activitySearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 16,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitPackageData = (val) => {
    if (val.packBanners) {
      if (val.packBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.packBanners.fileList.length; i++) {
          if (val.packBanners.fileList[i].response.status === 200) {
            array.push(val.packBanners.fileList[i].response.data.filepaths[0]);
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 11,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHomeBigBannerData = (val) => {
    if (val.bigSliderBanners) {
      if (val.bigSliderBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.bigSliderBanners.fileList.length; i++) {
          if (val.bigSliderBanners.fileList[i].response.status === 200) {
            array.push(
              val.bigSliderBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 21,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitHomeSmallBannerData = (val) => {
    if (val.smallSliderBanners) {
      if (val.smallSliderBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.smallSliderBanners.fileList.length; i++) {
          if (val.smallSliderBanners.fileList[i].response.status === 200) {
            array.push(
              val.smallSliderBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 22,
          };
          callPostApi(data);
        }
      }
    }
  };
  const submitPackageSearchBannerData = (val) => {
    if (val.packSearchBanners) {
      if (val.packSearchBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.packSearchBanners.fileList.length; i++) {
          if (val.packSearchBanners.fileList[i].response.status === 200) {
            array.push(
              val.packSearchBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 17,
          };
          callPostApi(data);
        }
      }
    }
  };

  const callPostApi = (data) => {
    APIClient.post("admin/banners", data)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Banner Uploaded SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (
          response.status == 409 ||
          response?.statusCode == 401 ||
          response.status == 500
        ) {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getBannersList();
  }, []);

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let logoList = res.data.filter((item) => item.BannerType === 1);
          if (logoList.length > 0) {
            if (logoList[0].images.length > 0) {
              let data = {
                uid: logoList[0].images[0].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + logoList[0].images[0].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [logoList[0].images[0].path],
                  },
                },
              };
              setCompanyLogo([data]);
            }
          }

          let flightList = res.data.filter((item) => item.BannerType === 7);
          if (flightList.length > 0) {
            let array = [];
            for (let i = 0; i < flightList[0].images.length; i++) {
              array.push({
                uid: flightList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + flightList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [flightList[0].images[i].path],
                  },
                },
              });
            }
            setFlightBanners(array);
          }

          let flightSearchList = res.data.filter(
            (item) => item.BannerType === 13
          );
          if (flightSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < flightSearchList[0].images.length; i++) {
              array.push({
                uid: flightSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + flightSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [flightSearchList[0].images[i].path],
                  },
                },
              });
            }
            setFlightSearchBanners(array);
          }

          let busList = res.data.filter((item) => item.BannerType === 8);
          if (busList.length > 0) {
            let array = [];
            for (let i = 0; i < busList[0].images.length; i++) {
              array.push({
                uid: busList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + busList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [busList[0].images[i].path],
                  },
                },
              });
            }
            setBusBanners(array);
          }
          let busSearchList = res.data.filter((item) => item.BannerType === 14);
          if (busSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < busSearchList[0].images.length; i++) {
              array.push({
                uid: busSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + busSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [busSearchList[0].images[i].path],
                  },
                },
              });
            }
            setBusSearchBanners(array);
          }

          let hotelList = res.data.filter((item) => item.BannerType === 9);
          if (hotelList.length > 0) {
            let array = [];
            for (let i = 0; i < hotelList[0].images.length; i++) {
              array.push({
                uid: hotelList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + hotelList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [hotelList[0].images[i].path],
                  },
                },
              });
            }
            setHotelBanners(array);
          }
          let hotelSearchList = res.data.filter(
            (item) => item.BannerType === 15
          );
          if (hotelSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < hotelSearchList[0].images.length; i++) {
              array.push({
                uid: hotelSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + hotelSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [hotelSearchList[0].images[i].path],
                  },
                },
              });
            }
            setHotelSearchBanners(array);
          }

          let activityList = res.data.filter((item) => item.BannerType === 10);
          if (activityList.length > 0) {
            let array = [];
            for (let i = 0; i < activityList[0].images.length; i++) {
              array.push({
                uid: activityList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + activityList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [activityList[0].images[i].path],
                  },
                },
              });
            }
            setActivityBanners(array);
          }
          let activitySearchList = res.data.filter(
            (item) => item.BannerType === 16
          );
          if (activitySearchList.length > 0) {
            let array = [];
            for (let i = 0; i < activitySearchList[0].images.length; i++) {
              array.push({
                uid: activitySearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + activitySearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [activitySearchList[0].images[i].path],
                  },
                },
              });
            }
            setActivitySearchBanners(array);
          }

          let packageList = res.data.filter((item) => item.BannerType === 11);
          if (packageList.length > 0) {
            let array = [];
            for (let i = 0; i < packageList[0].images.length; i++) {
              array.push({
                uid: packageList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + packageList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [packageList[0].images[i].path],
                  },
                },
              });
            }
            setPackBanners(array);
          }
          let packageSearchList = res.data.filter(
            (item) => item.BannerType === 17
          );
          if (packageSearchList.length > 0) {
            let array = [];
            for (let i = 0; i < packageSearchList[0].images.length; i++) {
              array.push({
                uid: packageSearchList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + packageSearchList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [packageSearchList[0].images[i].path],
                  },
                },
              });
            }
            setPackSearchBanners(array);
          }

          let footerBannersList = res.data.filter(
            (item) => item.BannerType === 20
          );
          if (footerBannersList.length > 0) {
            let array = [];
            for (let i = 0; i < footerBannersList[0].images.length; i++) {
              array.push({
                uid: footerBannersList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + footerBannersList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [footerBannersList[0].images[i].path],
                  },
                },
              });
            }
            setFooterBanners(array);
          }

          let bigHomeSliderBannerList = res.data.filter(
            (item) => item.BannerType === 21
          );
          if (bigHomeSliderBannerList.length > 0) {
            let array = [];
            for (let i = 0; i < bigHomeSliderBannerList[0].images.length; i++) {
              array.push({
                uid: bigHomeSliderBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url:
                  BASE + bigHomeSliderBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [bigHomeSliderBannerList[0].images[i].path],
                  },
                },
              });
            }
            setBigSliderBanners(array);
          }
          let smallHomeSliderBannerList = res.data.filter(
            (item) => item.BannerType === 22
          );
          if (smallHomeSliderBannerList.length > 0) {
            let array = [];
            for (
              let i = 0;
              i < smallHomeSliderBannerList[0].images.length;
              i++
            ) {
              array.push({
                uid: smallHomeSliderBannerList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url:
                  BASE +
                  smallHomeSliderBannerList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [smallHomeSliderBannerList[0].images[i].path],
                  },
                },
              });
            }
            setSmallSliderBanners(array);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("admin/banners/" + id)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className="manage-markup-section">
      <Content className="container-fluid">
        <Row>
          <Col span={24}>
            <Card bordered={false}>
              <h5>
                Upload Images <HelpInfoHelper screenName={"/admin/banners"} />
              </h5>
              <Collapse accordion defaultActiveKey={["1"]}>
                <Panel header="Company Logo's" key="1">
                  <Form
                    form={companyForm}
                    layout="vertical"
                    onFinish={submitCompanyData}
                  >
                    <div className="company-logos">
                      <div className="logo-section">
                        <Row>
                          <Col md={8} xs={24}>
                            <div className="upload-logo">
                              <p>Company Logo</p>

                              <Logos
                                category="companylogo"
                                fileList={companyLogo}
                                setFileList={setCompanyLogo}
                                formName="companyLogo"
                                limit={true}
                                limitSize={1}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="upload-btns"
                        style={{ textAlign: "right" }}
                      >
                        <Button type="primary" htmlType="submit">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                </Panel>
                <Panel header="Home Pages Banners" key="2">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={flightForm}
                            layout="vertical"
                            onFinish={submitFlightData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Flight Banner</p>
                              <Logos
                                category="flightbanners"
                                fileList={flightBanners}
                                setFileList={setFlightBanners}
                                formName="flightBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={busForm}
                            layout="vertical"
                            onFinish={submitBusData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Bus Banner</p>
                              <Logos
                                category="busbanners"
                                fileList={busBanners}
                                setFileList={setBusBanners}
                                formName="busBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={hotelForm}
                            layout="vertical"
                            onFinish={submitHotelData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Hotel Banner</p>
                              <Logos
                                category="hotelbanners"
                                fileList={hotelBanners}
                                setFileList={setHotelBanners}
                                formName="hotelBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={activityForm}
                            layout="vertical"
                            onFinish={submitActivityData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Activities Banner</p>
                              <Logos
                                category="activitybanners"
                                fileList={activityBanners}
                                setFileList={setActivityBanners}
                                formName="activityBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={packageForm}
                            layout="vertical"
                            onFinish={submitPackageData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Package Banner</p>
                              <Logos
                                category="packbanners"
                                fileList={packBanners}
                                setFileList={setPackBanners}
                                formName="packBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Panel>
                {/**search Results banners */}
                <Panel header="Search  Result Banners" key="3">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={flightSearchForm}
                            layout="vertical"
                            onFinish={submitFlightSearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Flight Search Banners</p>
                              <Logos
                                category="flightsearchbanners"
                                fileList={flightSearchBanners}
                                setFileList={setFlightSearchBanners}
                                formName="flightSearchBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={busSearchForm}
                            layout="vertical"
                            onFinish={submitBusSearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Bus Search Banners</p>
                              <Logos
                                category="bussearchbanners"
                                fileList={busSearchBanners}
                                setFileList={setBusSearchBanners}
                                formName="busSearchBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={hotelSearchForm}
                            layout="vertical"
                            onFinish={submitHotelSearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Hotel Search Banners</p>
                              <Logos
                                category="hotelsearchbanners"
                                fileList={hotelSearchBanners}
                                setFileList={setHotelSearchBanners}
                                formName="hotelSearchBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={8} xs={24}>
                          <Form
                            form={activityForm}
                            layout="vertical"
                            onFinish={submitActivitySearchBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Activities Search Banners</p>
                              <Logos
                                category="activitysearchbanners"
                                fileList={activitySearchBanners}
                                setFileList={setActivitySearchBanners}
                                formName="activitySearchBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        {/* <Col md={8} xs={24}>
                          <Form form={packageSearchForm} layout="vertical" onFinish={submitPackageSearchBannerData} className="py-2">
                            <div className="upload-logo">
                              <p>Package Search Banners</p>
                              <Logos
                                category="packsearchbanners"
                                fileList={packSearchBanners}
                                setFileList={setPackSearchBanners}
                                formName="packSearchBanners"
                                limit={true}
                                limitSize={2}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col> */}
                      </Row>
                    </div>
                  </div>
                </Panel>
                {/**End search Results banners */}

                <Panel header="Footer Banners" key="4">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={8} xs={24}>
                          <Form
                            form={footerBannerForm}
                            layout="vertical"
                            onFinish={submitFooterBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Footer Banners</p>
                              <Logos
                                category="footerbanners"
                                fileList={footerBanners}
                                setFileList={setFooterBanners}
                                formName="footerBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Panel>
                <Panel header="Home Slider Banners" key="5">
                  <div className="company-logos">
                    <div className="logo-section">
                      <Row>
                        <Col md={12} xs={24}>
                          <Form
                            form={bigBannerForm}
                            layout="vertical"
                            onFinish={submitHomeBigBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Home Big Slider Banners</p>
                              <Logos
                                category="bigSliderBanners"
                                fileList={bigSliderBanners}
                                setFileList={setBigSliderBanners}
                                formName="bigSliderBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button
                                type="primary"
                                htmlType="submit"
                                // onClick={(val) => {
                                //   submitHomeBigBannerData(val);
                                // }}
                              >
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                        <Col md={12} xs={24}>
                          <Form
                            form={smallBannerForm}
                            layout="vertical"
                            onFinish={submitHomeSmallBannerData}
                            className="py-2"
                          >
                            <div className="upload-logo">
                              <p>Home Small Slider Banners</p>
                              <Logos
                                category="smallSliderBanners"
                                fileList={smallSliderBanners}
                                setFileList={setSmallSliderBanners}
                                formName="smallSliderBanners"
                                limit={true}
                                limitSize={3}
                                deleteBanner={DeleteBannerImage}
                              />
                            </div>

                            <div className="upload-btns">
                              <Button type="primary" htmlType="submit">
                                Submit
                              </Button>
                            </div>
                          </Form>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Panel>
              </Collapse>
            </Card>
          </Col>
        </Row>
      </Content>
    </div>
  );
};

export default Banners;
