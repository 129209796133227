import * as ANTD from "antd";
import { Rate } from "antd";
import React, { useState } from "react";
import "./activitiestourdetail.scss";

const ActivitiesReview = (props) => {
  let reviewData = props.Reviews;
  const [pagination, SetPagination] = useState(5);
  const showMore = () => {
    SetPagination((prev) => prev + 5);
  };
  return (
    <div className="container">
      <ANTD.Row>
        <ANTD.Col md={24} xs={24}>
          <ANTD.Card>
            <ANTD.Row>
              <ANTD.Col md={4} xs={24}>
                <h2>5/5</h2>
              </ANTD.Col>
              <ANTD.Col md={12} xs={24}>
                <ANTD.Col>
                  <ANTD.Row>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(5)}
                        allowHalf={true}
                      />
                    </div>
                  </ANTD.Row>
                  <ANTD.Row>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(4)}
                        allowHalf={true}
                      />
                    </div>
                  </ANTD.Row>
                  <ANTD.Row>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(3)}
                        allowHalf={true}
                      />
                    </div>
                  </ANTD.Row>
                  <ANTD.Row>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(2)}
                        allowHalf={true}
                      />
                    </div>
                  </ANTD.Row>
                  <ANTD.Row>
                    <div className="hotel-star">
                      <Rate
                        className="starRating"
                        disabled
                        value={Number(1)}
                        allowHalf={true}
                      />
                    </div>
                  </ANTD.Row>
                </ANTD.Col>
              </ANTD.Col>
              <ANTD.Col md={8} xs={24}>
                <ANTD.Col>
                  <ANTD.Row>
                    <ANTD.Progress percent={80} status="active" />
                  </ANTD.Row>
                  <ANTD.Row>
                    <ANTD.Progress percent={60} status="active" />
                  </ANTD.Row>
                  <ANTD.Row>
                    <ANTD.Progress percent={40} status="active" />
                  </ANTD.Row>
                  <ANTD.Row>
                    <ANTD.Progress percent={20} status="active" />
                  </ANTD.Row>
                  <ANTD.Row>
                    <ANTD.Progress percent={10} status="active" />
                  </ANTD.Row>
                </ANTD.Col>
              </ANTD.Col>
            </ANTD.Row>

            {reviewData.tourReview.map((review, index) => {
              return index < pagination ? (
                <ANTD.Row className="review-top">
                  <ANTD.Col md={4} xs={24}>
                    <ANTD.Avatar size={90} src={review.imagePath} />
                    <p>
                      <span>{review.guestName}</span>
                    </p>
                    <p>
                      <span>{review.visitMonth}</span>
                    </p>
                  </ANTD.Col>
                  <ANTD.Col md={16} xs={24}>
                    <h3>{review.reviewTitle}</h3>
                    <p>{review.reviewContent}</p>
                  </ANTD.Col>
                  <ANTD.Col md={4} xs={24}>
                    <p>{review.rating}/5</p>
                    {review.rating && (
                      <div className="hotel-star">
                        <Rate
                          className="starRating"
                          disabled
                          value={Number(review.rating)}
                          allowHalf={true}
                        />
                      </div>
                    )}
                  </ANTD.Col>
                </ANTD.Row>
              ) : index === pagination ? (
                <div className="showmore-wrapper">
                  <ANTD.Button onClick={showMore}>Show More</ANTD.Button>
                </div>
              ) : null;
            })}
          </ANTD.Card>
        </ANTD.Col>
      </ANTD.Row>
    </div>
  );
};

export default ActivitiesReview;
