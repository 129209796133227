import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import LogoImg from "../../../assets/images/logos/trip-logo.png";
import hotelImg from "../../../assets/images/hotels/hotelsmall.png";
import NohotelImg from "../../../assets/images/hotels/no_img.png";

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    padding: 20,
  },
  section: {
    padding: "0 10px",
  },
  innerSection: {
    position: "relative",
    paddingLeft: 30,
  },
  borderleft: {
    position: "absolute",
    width: 3,
    height: "100%",
    backgroundColor: "#e9ecef",
  },
  heading: {
    fontSize: 14,
    marginBottom: 25,
    color: "#274581",
  },
  title: {
    fontSize: 12,
    marginBottom: 5,
    color: "#274581",
  },
  subtitle: {
    fontSize: 10,
    marginBottom: 5,
  },
  stitle: {
    fontSize: 10,
  },
  textDanger: {
    color: "#dc3545",
  },
  flex5: {
    flex: 0.49,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 5,
    flexWrap: "wrap",
  },

  wHalf: {
    width: "50%",
    marginBottom: 5,
  },
  card: {
    position: "relative",
    padding: 20,
    border: "1px solid #e9ecef",
    borderRadius: 2,
  },
  hotelimgWrapper: {
    width: 100,
  },
  hotelLogo: {
    width: 10,
  },
  tourImg: {
    width: 100,
  },
  mb10: {
    marginBottom: 10,
  },
  contentWrapper: {
    position: "relative",
    marginBottom: 10,
  },

  typeTitle: {
    backgroundColor: "#1e4167",
    width: 40,
    padding: "4px 5px",
    fontSize: 12,
    textAlign: "left",
    borderRadius: 4,
    color: "#fff",
    marginLeft: -50,
    marginBottom: 10,
  },

  timelineBadge: {
    position: "absolute",
    top: 9,
    left: -46,
    zIndex: 10,
    width: 35,
    height: 35,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    textAlign: "center",
    fontSize: 9,
    color: "#fff",
    backgroundColor: "#ffbc34",
  },
  description: { width: 350, fontSize: 10 },
  hotelDetails: { width: 350, paddingLeft: 10 },
  addressDetails: {
    textAlign: "right",
    fontSize: 10,
    paddingBottom: 5,
  },
  logoSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});

const Description = ({ tourShortDescription }) => {
  let returnContentConst=<Text style={{ display: "inline" }}>{''}</Text>;;
  if (tourShortDescription) {
    const parsedHtml = ReactHtmlParser(tourShortDescription);
    parsedHtml.forEach((element) => {
      if (element?.props && element?.props?.children) {
        element.props.children.forEach((content) => {
          returnContentConst = (
            <Text style={{ display: "inline" }}>{content}</Text>
          );
        });
      } else {
        if (element)
          returnContentConst = (
            <Text style={{ display: "inline" }}>{element}</Text>
          );
      }
    });
    return returnContentConst;
  } else {
    return returnContentConst;
  }
};

const HotelImg = ({ img }) => {
  if (img) {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={img} />
      </View>
    );
  } else {
    return (
      <View style={styles.hotelimgWrapper}>
        <Image style={{ width: "100%" }} src={NohotelImg} />
      </View>
    );
  }
};

const getHotelPaxCount = (roomsArray) => {
  if (roomsArray.length > 0) {
    let pax = roomsArray[0];
    return `Adult(s) ${pax?.AdultCount !== "0" ? pax?.AdultCount : 0} ${
      pax?.ChildCount !== "0" ? ", Child(s) " + pax?.ChildCount : " "
    } `;
  }
};

const getPaxCount = (pax) => {
  return `Adult(s) ${pax?.adult !== "0" ? pax?.adult : 0} ${
    pax?.child !== "0" ? ", Child(s) " + pax?.child : " "
  } ${pax?.infant !== "0" ? ", Infant(s) " + pax?.infant : " "}`;
};

const BuildPackageDoc = ({
   buildPackage,
    showPrice = false,
    showTotalPrice=false,
    currencyValue = () => {},
    activeCurrency
   }) => (
  
  <Document>
    {buildPackage.HotelDetails?.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}> INCLUSIONS DETAILS - HOTEL </Text>
          <View style={styles.innerSection}>
            <View style={styles.borderleft}></View>

            {buildPackage.HotelDetails?.length > 0 ? (
              <Text style={styles.typeTitle}>Hotel</Text>
            ) : null}
            {buildPackage.HotelDetails.length > 0
              ? buildPackage.HotelDetails.map((hotel, index) => {
                  return (
                    <View key={index + "hotel"} style={styles.contentWrapper}>
                      <View style={styles.timelineBadge}>
                        <Image style={styles.hotelLogo} src={hotelImg} />
                      </View>

                      <View key={index} style={styles.card}>
                        <View
                          style={{
                            ...styles.mb10,
                            ...styles.row,
                          }}
                        >
                          <HotelImg img={hotel.hotelImage} />
                          <View style={styles.hotelDetails}>
                            <Text style={styles.title}>{hotel.HotelName}</Text>
                            <View style={styles.row}>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Check in Date :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {moment(hotel.CheckInDate).format("DD-MM-YYYY")}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Check Out Date :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {moment(hotel.CheckOutDate).format(
                                  "DD-MM-YYYY"
                                )}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Room Type :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {hotel.Rooms[0].RoomName}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                No. of Pax :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {getHotelPaxCount(hotel.Rooms)}
                              </Text>

                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                No. of Nights :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {hotel.Rooms[0].noOfNight}
                              </Text>
                              {showPrice && (
                                <Text
                                  style={{ ...styles.stitle, ...styles.wHalf }}
                                >
                                  Amount :
                                </Text>
                              )}
                              {showPrice && (
                                <Text
                                  style={{ ...styles.stitle, ...styles.wHalf }}
                                >
                                  INR {hotel.Rooms[0].pricePerRoom}
                                </Text>
                              )}
                            </View>
                          </View>
                        </View>
                      </View>
                    </View>
                  );
                })
              : null}
          </View>
        </View>
      </Page>
    ) : null}

    {buildPackage.TourDetails?.length ? (
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
        <View style={styles.logoSection}>
              <View style={{ ...styles.flex5 }}>
                {buildPackage.logo ? (
                  <Image
                    src={`${process.env.REACT_APP_API_BASE_DOMAIN}`+'api/v1'+buildPackage.logo}
                    style={{ height: 50, width: 100, marginBottom: 10 }}
                  ></Image>
                ) : (
                  <Image style={{ width: 100, heigh: 50 }} src={LogoImg} />
                )}
              </View>

              {buildPackage.agentDetails.data ? (
                <View style={{ ...styles.flex5, ...styles.addressDetails }}>
                  <Text>{buildPackage.agentDetails.data?.AgencyName} </Text>
                  <Text>
                    {buildPackage.agentDetails.data?.Address1}{" "}
                    {buildPackage.agentDetails.data?.Address2}
                  </Text>
                  {buildPackage.agentDetails.data?.City ||
                  buildPackage.agentDetails.data?.State ? (
                    <Text>
                      {buildPackage.agentDetails.data?.City}{" "}
                      {buildPackage.agentDetails.data?.State}
                    </Text>
                  ) : null}

                  <Text>Email: {buildPackage.agentDetails.data?.Email}</Text>
                  {buildPackage.agentDetails.data?.Phone ? (
                    <Text>Contact No.: {buildPackage.agentDetails.data?.Phone}</Text>
                  ) : null}
                </View>
              ) : buildPackage?.adminDetails.data?.length > 0 ? (
                <View style={{ ...styles.flex5, ...styles.addressDetails }}>
                  <Text>{buildPackage?.adminDetails?.data.Address} </Text>
                  <Text>
                    {buildPackage?.adminDetails.data?.CityName}{" "}
                    {buildPackage?.adminDetails.data?.StateName}
                  </Text>
                  <Text>
                    {buildPackage?.adminDetails.data?.CountryName}{" "}
                    {buildPackage?.adminDetails.data?.PostalCode}
                  </Text>
                </View>
              ) : null}
            </View>
        <View style={styles.section}>
          <Text style={styles.heading}> DAY WISE ITINERARY - TOUR</Text>
          <Text style={styles.title}> {showTotalPrice ==true?`TOTAL COST : ${activeCurrency + ' ' + currencyValue(parseFloat(buildPackage.PackageDetails?.TotalFare) +parseFloat(buildPackage.PackageDetails?.AgentMarkup))}`:""}</Text>
</View>
          <View style={styles.innerSection}>
            <View style={styles.borderleft}></View>
            {buildPackage.TourDetails?.length > 0 ? (
              <Text style={styles.typeTitle}>Tours</Text>
            ) : null}

            {buildPackage.TourDetails?.length > 0
              ? buildPackage.TourDetails.map(
                  (tourList, index) =>
                    tourList &&
                    tourList?.length > 0 &&
                    tourList.map((tour) => {
                      return (
                        <View key={index} style={styles.contentWrapper}>
                          <View style={styles.timelineBadge}>
                            <Text>Day {index + 1}</Text>
                          </View>

                          <View key={index} style={styles.card}>
                            <Text style={styles.title}>{tour.tourName}</Text>

                            <Text style={styles.subtitle}>
                              Tour Date :{" "}
                              {moment(tour.tourDate, "YYYY-MM-DD").format(
                                "DD-MM-YYYY"
                              )}
                            </Text>

                            <View
                              style={{
                                ...styles.mb10,
                                ...styles.row,
                              }}
                            >
                              <Image
                                style={styles.tourImg}
                                src={tour.tourImage}
                              />
                              <View style={styles.description}>
                                <Description
                                  tourShortDescription={
                                    tour.tourShortDescription
                                  }
                                />
                              </View>
                            </View>

                            <View style={styles.row}>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Tour Option :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {tour.optionName}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Transfer Option :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {tour.transferName}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Duration :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {tour.duration}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                Start Time :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {tour.startTime}
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                No. of Pax :
                              </Text>
                              <Text
                                style={{ ...styles.stitle, ...styles.wHalf }}
                              >
                                {getPaxCount(tour)}
                              </Text>
                              {showPrice && (
                                <Text
                                  style={{ ...styles.stitle, ...styles.wHalf }}
                                >
                                  Amount :
                                </Text>
                              )}
                              {showPrice && (
                                <Text
                                  style={{ ...styles.stitle, ...styles.wHalf }}
                                >
                                  INR {tour.serviceTotal}
                                </Text>
                              )}
                            </View>
                          </View>
                        </View>
                      );
                    })
                )
              : null}
          
          </View>
        </View>
      </Page>
    ) : null}
  </Document>
);

export default BuildPackageDoc;
