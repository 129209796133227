import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  message,
  Radio,
  Select,
  Spin,
  Table,
  Row,
  Upload,
  Popconfirm,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./Propertytype.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import APIClient from "../../../src/helpers/ApiClient";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../../src/helpers/ApiClient";
import "rc-datepicker/lib/style.css";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";

const Propertytype = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [form] = ANTD.Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = imgUrl;
  const [actHotelForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [addValue, setValue] = useState();
  const [currentId, setCurrentId] = useState();
  const [excelList, setExcelList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const handleRoleType = (val) => setRoleType(val);
  const [roleType, setRoleType] = useState(1);
  const [addMarkupModal, setAddMarkupModal] = useState(false);

  useEffect(() => {
    getPropertytype();
  }, []);

  const getPropertytype = () => {
    APIClient.get("hotelsextranet/getpropertytype")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,

              ...item,
            };
          });
          let filteredNmaes = data?.filter(
            (item) => item.PropertyTypeId != 2 && item.PropertyTypeId != 5
          );
          setNameList(filteredNmaes);
          defineExcelData(filteredNmaes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onFinish = (val) => {
    let nameObj = {
      Status: 0,
      Name: val.Name,
      // Icon: "string",
      Description: val.Description,

      CreatedBy: 0,
      CreatedDate: new Date(),
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };
    console.log("testtthh", nameObj);
    isEdit ? UpdateHotelType(nameObj) : AddHoteltype(nameObj);
  };

  const UpdateHotelType = (nameObj) => {
    console.log(nameObj);
    ApiClient.put(
      "hotelsextranet/updatepropertytypebyId/" + currentId,
      {},
      nameObj
    ).then((res) => {
      if (res.status == 200) {
        getPropertytype();
        ANTD.message.success("Property Type Updated successfully", 3);
        setAddMarkupModal(false);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  const add = () => {
    let nameObjtest = {
      Name: addValue,
      Level: "1",
      CreatedBy: 1,
    };
  };
  const AddHoteltype = (nameObjtest) => {
    APIClient.post("hotelsextranet/createpropertytype", nameObjtest)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setAddMarkupModal(false);
          ANTD.message.success("Successfully added name", 3);
          getPropertytype();
        } else if (response.status == 400) {
          ANTD.message.error("Property Type Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        Name: item.Name,
        Description: item.Description,
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = nameList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setNameList(searchResults);
    } else {
      getPropertytype();
      setNameList(nameList);
    }
  };

  function getInputValue(event) {
    setValue(event.target.value);
  }

  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);

  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteName(currentRecord.PropertyTypeId)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.PropertyTypeId);
    actHotelForm.setFieldsValue({
      Name: rec.Name,
      Description: rec.Description,
    });

    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const deleteName = (id) => {
    APIClient.delete("hotelsextranet/deletepropertytypebyId/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Amenities Deleted  successfully", 3);
          getPropertytype();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      name: "Jhon",
      description: "bbb",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon11",
      description: "aaa",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon2",
      description: "ccc",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },

    {
      title: "Name",
      dataIndex: "Name",
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },

    {
      title: "Description",
      dataIndex: "Description",
      sorter: (a, b) => a.Description.localeCompare(b.Description),
    },

    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Property Type{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>{nameList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          props.exportExcel(excelList, "Property Type")
                        }
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={(e) => {
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          handleRoleType(1);
                          setAddMarkupModal(true);
                          actHotelForm.resetFields();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={nameList}
                      rowKey={uniqueKey}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Property Type</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-extranet"
        // visible={modalVisible}
        // onOk={(e) => setModalVisible(false)}
        // onCancel={(e) => setModalVisible(false)}
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={null}
        width={"1100px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={actHotelForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => actHotelForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <ANTD.Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={actHotelForm}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Name"
                name="Name"
                rules={[{ required: true, message: "Please enter nmae" }]}
              >
                <ANTD.Input onChange={getInputValue} />
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={24} xs={24}>
              <Form.Item
                name="Description"
                label="Description"
                rules={[{ required: true }]}
              >
                <TextArea />
              </Form.Item>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};

export default UpdatedComponent(Propertytype);
