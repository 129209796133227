import React, { useEffect, useRef } from "react";
import { Button, Col, DatePicker, Form, Row } from "antd";
import { useHistory } from "react-router-dom";
import ActivitiesAutoComplete from "../../../common/AutoCompleteSelect/ActivitiesAutoComplete";
import moment from "moment";
import queryString from "query-string";
import { SearchOutlined } from "@ant-design/icons";

const oriDateString = "YYYY-MM-DD";
const dateFormat = "DD-MM-YYYY";

const AcivitiesSearchForm = (props) => {
  const [form] = Form.useForm();
  let history = useHistory();

  const city = useRef(null);
  const dateBox = useRef(null);

  const handleOnSubmit = (ref) => {
    ref.current.focus();
  };

  const onSubmitForm = (val) => {
    let countryCities = val.activitiesCityCode;
    let param = queryString.parse(document.location.search);

    let query = {
      country: countryCities.split("&&")[0],
      city: countryCities.split("&&")[1],
      travelDate: moment(val.journeyDate).format(oriDateString),
    };

    let queryParams = queryString.stringify(query);

    if (props.modify) {
      history.replace("/activities/results?" + queryParams);
      props.onModify();
    } else {
      history.push("/activities/results?" + queryParams);
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  useEffect(() => {
    if (props.modify) {
      const activitiesSearchParams = queryString.parse(window.location.search);

      form.setFieldsValue({
        activitiesCityCode: `${activitiesSearchParams.country}&&${activitiesSearchParams.city}`,
        journeyDate: moment(activitiesSearchParams.travelDate),
      });
    }
  }, []);

  return (
    <div className="hotel_search acitiv-pans">
      <Form form={form} onFinish={onSubmitForm}>
        <Row className="search-row activity-cols-num" gutter={16}>
          <Col
            md={14}
            xs={24}
            className="from-to-inputs new-activity-pro br-right"
          >
            <ActivitiesAutoComplete
              formItemProps={{
                label: "City",
                name: "activitiesCityCode",
                rules: [{ required: true, message: "City Name is required" }],
              }}
              selectProps={{
                size: "large",
                placeholder: "Search by Destination",
              }}
              api={"activities/searchCity/"}
              refName={city}
              focusRef={dateBox}
              handleOnSubmit={handleOnSubmit}
              modifySearch={props.modify ? props.modify : false}
            />
          </Col>
          <Col md={6} xs={24} className="from-to-inputs new-activity-pro">
            <Form.Item
              name="journeyDate"
              rules={[{ required: true }]}
              initialValue={moment()}
            >
              <DatePicker
                style={{ width: "100%" }}
                allowClear={false}
                ref={dateBox}
                size="large"
                className="search-inputs journeyDate"
                placeholder="Journey Date"
                format={dateFormat}
                disabledDate={disabledOriginDate}
              />
            </Form.Item>
          </Col>
          <Col md={4} xs={24} className="new-activity-pro">
            <Form.Item className="mr-none">
              <Button
                size="large"
                className="primary-btn train-search-btn button-promo-activity"
                htmlType="submit"
              >
                <SearchOutlined />
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AcivitiesSearchForm;
