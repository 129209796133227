import React, { useContext } from "react";

import Flight, { FlightSelection } from "../Flight/Flight";

import { Col, Row } from "antd";
import { useSytContext } from "../../../common/providers/SytProvider";

const CombinedView = ({
  changeFlightResultsView,
  selectedFlight,
  flightSearchObj,
  setFlightFareRules,
  combinedOnewayFlightList,
  combinedTwowayFlightList,
  showNetFare,
  promoData,
}) => {
  const { flightSearchBanners } = useSytContext();

  let bannreIndex = -1;
  return (
    <>
      {flightSearchObj.airTravelType === "oneWay" ||
      flightSearchObj.airTravelType === "multidestination" ? (
        combinedOnewayFlightList.length > 0 ? (
          combinedOnewayFlightList.map((flight, i) => {
            let filteredPromoData =
              promoData.length > 0
                ? promoData?.filter(
                    (item) => item.airlineCode === flight.airLine
                  ).length > 0
                  ? promoData?.filter(
                      (item) =>
                        item.airlineCode?.toUpperCase() ===
                        flight.airLine?.toUpperCase()
                    )
                  : promoData?.filter((item) => item.airlineCode === "ALL")
                : null;

            return (
              <>
                {i != 0 && i % 3 == 0 && flightSearchBanners[++bannreIndex] && (
                  <div className="combine-roundtrip-view" key={i}>
                    <img
                      style={{
                        width: "100%",
                        height: "90px",
                        objectFit: "cover",
                      }}
                      src={
                        process.env.REACT_APP_BASE_URL +
                        flightSearchBanners[bannreIndex].path.substring(1)
                      }
                      data={
                        flightSearchBanners[bannreIndex + 1]
                          ? null
                          : (bannreIndex = -1)
                      }
                    />{" "}
                  </div>
                )}

                <div className="combine-roundtrip-view">
                  <Row>
                    <Col md={18} xs={24}>
                      <Flight
                        flightInfo={flight}
                        key={flight.flightId}
                        flightSearchObj={flightSearchObj}
                        setFlightFareRules={setFlightFareRules}
                        fareFamilies={flight.fareFamilies}
                        promoData={filteredPromoData}
                      />
                    </Col>
                    <Col md={6} xs={24}>
                      <div className="choose-btn-wrapper">
                        <FlightSelection
                          flightInfo={flight}
                          changeFlightResultsView={changeFlightResultsView}
                          flightSearchObj={flightSearchObj}
                          selectedFlight={selectedFlight}
                          showNetFare={showNetFare}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })
        ) : (
          <div>No flights available</div>
        )
      ) : combinedTwowayFlightList.length > 0 ? (
        combinedTwowayFlightList.map((flights, i) => {
          return (
            <>
              {i != 0 && i % 3 == 0 && flightSearchBanners[++bannreIndex] && (
                <div className="combine-roundtrip-view" key={"banner" + i}>
                  <img
                    style={{
                      width: "100%",
                      height: "90px",
                      objectFit: "cover",
                    }}
                    src={
                      process.env.REACT_APP_BASE_URL +
                      flightSearchBanners[bannreIndex].path.substring(1)
                    }
                    data={
                      flightSearchBanners[bannreIndex + 1]
                        ? null
                        : (bannreIndex = -1)
                    }
                  />{" "}
                </div>
              )}
              <div className="combine-roundtrip-view ">
                <Row>
                  <Col md={18} xs={24}>
                    {flights.flightDetails.map((flight) => (
                      <Flight
                        flightInfo={flight}
                        key={flight.flightId}
                        flightSearchObj={flightSearchObj}
                        setFlightFareRules={setFlightFareRules}
                        fareFamilies={flights.fareFamilies}
                      />
                    ))}
                  </Col>
                  <Col md={6} xs={24}>
                    <div className="choose-btn-wrapper">
                      <FlightSelection
                        flightInfo={flights}
                        changeFlightResultsView={changeFlightResultsView}
                        flightSearchObj={flightSearchObj}
                        selectedFlight={selectedFlight}
                        showNetFare={showNetFare}
                      />
                    </div>
                  </Col>
                </Row>
              </div>{" "}
            </>
          );
        })
      ) : (
        <div>No flights available</div>
      )}

      {/* {flights
          .filter((f) => f[0].isVisible)
          .map((flight_list, ItemIndex) => {
            return !checkIfDisplayAdd(ItemIndex) ? (
              <div className="combine-roundtrip-view">
                <Row>
                  <Col md={18} xs={24}>
                    {flight_list.map((flight, key_index) => (
                      <Flight
                        flightInfo={flight}
                        key={key_index + "_" + flight.flightId}
                        airSearchData={airSearchData}
                        styleclassName={
                          flight_list.length == 1 ? "full_height" : ""
                        }
                      />
                    ))}
                  </Col>
                  <Col md={6} xs={24}>
                    <div className="choose-btn-wrapper">
                      <FlightSelection
                        flights={flight_list}
                        airSearchData={airSearchData}
                        onFlightsSelected={onFlightsSelected}
                      />
                    </div>
                  </Col>
                </Row>
              </div>
            ) : (
              <BannerAdDisplay src={getBannerAdsSrc(ItemIndex)} />
            );
          })} */}
    </>
  );
};

export default CombinedView;
