import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  Modal,
  message,
  Radio,
  Select,
  Spin,
  Table,
  Row,
  Upload,
  Popconfirm,
} from "antd";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
import "./Manageimages.scss";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import APIClient from "../../../src/helpers/ApiClient";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../../src/helpers/ApiClient";
import "rc-datepicker/lib/style.css";
import moment from "moment";
import UpdatedComponent from "../ExportExcel";

const BASE = process.env.REACT_APP_BASE_URL;
const uploadActionUrl = BASE + "media/upload/multipleImages";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;

    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Manageimages = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [form] = ANTD.Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = imgUrl;
  const [actHotelForm] = Form.useForm();
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [nameList, setNameList] = useState([]);
  const [addValue, setValue] = useState();
  const [currentId, setCurrentId] = useState();
  const [excelList, setExcelList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const handleRoleType = (val) => setRoleType(val);
  const [roleType, setRoleType] = useState(1);
  const [addMarkupModal, setAddMarkupModal] = useState(false);
  const [managehotelname, setManagehotelname] = useState([]);
  const [roomtypes, setRoomtypes] = useState([]);
  const [imgtypes, setImgtypes] = useState([]);
  const [isDateDisplay, setIsDateDisplay] = useState(false);
  const [footerBanners, setFooterBanners] = useState([]);
  const [footerBannerForm] = Form.useForm();
  const initialState = {
    ServiceType: 0,
    ApplicationType: 0,
    BannerType: 0,
    ModifiedBy: 0,
    CreatedBy: 0,
    imagesPath: ["string"],
  };
  const [bannerData, setbannerData] = useState(initialState);

  useEffect(() => {
    getManagemedia();
    getManagehotelname();
    getRoomtype();
    getImagetype();
  }, []);

  const handleTimeAndDate = (value) => {
    if (value === "2") {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };

  /*banners--upload--open*/

  useEffect(() => {
    getBannersList();
  }, []);

  const callPostApi = (data) => {
    APIClient.post("admin/banners", data)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Banner Uploaded SuccessFully", 3);
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else if (
          response.status == 409 ||
          response?.statusCode == 401 ||
          response.status == 500
        ) {
          message.error(response.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const DeleteBannerImage = (id) => {
    APIClient.delete("admin/banners/" + id)
      .then((response) => {
        if (response.status == 200) {
          getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const submitFooterBannerData = (val) => {
    if (val.footerBanners) {
      if (val.footerBanners.fileList.length > 0) {
        let array = [];

        for (let i = 0; i < val.footerBanners.fileList.length; i++) {
          if (val.footerBanners.fileList[i].response.status === 200) {
            array.push(
              val.footerBanners.fileList[i].response.data.filepaths[0]
            );
          }
        }
        if (array.length > 0) {
          let data = {
            ...bannerData,
            imagesPath: array,
            BannerType: 20,
          };
          callPostApi(data);
        }
      }
    }
  };

  const getBannersList = () => {
    APIClient.get("admin/banners")
      .then((res) => {
        if (res.status === 200) {
          let footerBannersList = res.data.filter(
            (item) => item.BannerType === 20
          );
          if (footerBannersList.length > 0) {
            let array = [];
            for (let i = 0; i < footerBannersList[0].images.length; i++) {
              array.push({
                uid: footerBannersList[0].images[i].image_id,
                name: "slide.jpg",
                status: "done",
                url: BASE + footerBannersList[0].images[i].path.substring(1),
                response: {
                  status: 200,
                  data: {
                    filepaths: [footerBannersList[0].images[i].path],
                  },
                },
              });
            }
            setFooterBanners(array);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  /*banners--upload--close*/

  const getManagemedia = () => {
    APIClient.get("hotelsextranet/getmanagemedia")
      .then((res) => {
        if (res.status == 200) {
          let data = res.data.map((item, index) => {
            return {
              SNo: index + 1,

              ...item,
            };
          });
          let filteredNmaes = data?.filter(
            (item) => item.MediaRefNo != 2 && item.MediaRefNo != 5
          );
          setNameList(filteredNmaes);
          defineExcelData(filteredNmaes);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getManagehotelname = () => {
    APIClient.get("hotelsextranet/getAllhotel")
      .then((resp) => {
        if (resp.status == 200) {
          setManagehotelname(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getImagetype = () => {
    APIClient.get("hotelsextranet/getHotelImages")
      .then((resp) => {
        if (resp.status == 200) {
          setImgtypes(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRoomtype = () => {
    APIClient.get("hotelsextranet/getHotelRoomType")
      .then((resp) => {
        if (resp.status == 200) {
          setRoomtypes(resp.data);
        } else if (resp.status == 400) {
          message.error("Failed To Fetch Countries", 3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFinish = (val) => {
    let nameObj = {
      Status: 0,
      MediaFor: val.MediaFor,
      HotelImageTypeId: val.ImageName,
      RoomId: val.RoomName,
      HotelId: val.HotelName,
      Images: val.Images,
      CreatedBy: 0,
      CreatedDate: new Date(),
      ModifiedBy: 0,
      ModifiedDate: new Date(),
    };
    console.log("testtthh", nameObj);
    isEdit ? UpdateHotelType(nameObj) : AddHoteltype(nameObj);
  };

  const UpdateHotelType = (nameObj) => {
    console.log(nameObj);
    ApiClient.put(
      "hotelsextranet/updatemanagemedia/" + currentId,
      {},
      nameObj
    ).then((res) => {
      if (res.status == 200) {
        getManagemedia();
        ANTD.message.success("Around this Hotel Type Updated successfully", 3);
        setAddMarkupModal(false);
      } else {
        ANTD.message.error(" Error Uploading Data", 3);
      }
    });
  };

  const add = () => {
    let nameObjtest = {
      Name: addValue,
      Level: "1",
      CreatedBy: 1,
    };
  };
  const AddHoteltype = (nameObjtest) => {
    APIClient.post("hotelsextranet/managemedia", nameObjtest)
      .then((response) => {
        return response;
      })
      .then((response) => {
        if (response.status == 200) {
          setAddMarkupModal(false);
          ANTD.message.success("Successfully added name", 3);
          getManagemedia();
        } else if (response.status == 400) {
          ANTD.message.error("Bed Type Already existed", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const defineExcelData = (data) => {
    const filteredData = data.map((item) => {
      const obj = {
        CreatedDate: item.CreatedDate,
        ModifiedDate: item.ModifiedDate,
        Status: item.status === 1 ? "Active" : "Inactive",
      };
      return obj;
    });
    setExcelList(filteredData);
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = nameList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setNameList(searchResults);
    } else {
      getManagemedia();
      setNameList(nameList);
    }
  };

  function getInputValue(event) {
    setValue(event.target.value);
  }

  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(
        info.file.originFileObj,
        (imageUrl) => setImgUrl(imageUrl),
        setLoading(false)
      );
    }
  };
  const searchData = (searchData) => {
    setShowSearchBox(true);
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);

  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };
  const tableActions = (currentRecord) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => editCurrentItem(currentRecord)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteName(currentRecord.MediaRefNo)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };

  const editCurrentItem = (rec) => {
    setCurrentId(rec.MediaRefNo);
    actHotelForm.setFieldsValue({
      Name: rec.Name,
      HotelId: rec.HotelName,

      HotelRoomTypeId: rec.RoomName,
    });

    setIsEdit(true);
    setAddMarkupModal(true);
  };

  const deleteName = (id) => {
    APIClient.delete("hotelsextranet/deletemanagemedia/" + id)
      .then((res) => {
        if (res.status == 200) {
          ANTD.message.success("Amenities Deleted  successfully", 3);
          getManagemedia();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  const dataSource = [
    {
      name: "Jhon",
      description: "bbb",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon11",
      description: "aaa",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
    {
      name: "Jhon2",
      description: "ccc",

      actions: tableActions(),
      /* delete: tableDelete(), */
    },
  ];

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "SNo",
    },

    {
      title: "Media Type",
      dataIndex: "MediaFor",
      render: (item) => (item == 1 ? "Hotel" : "Room"),
      sorter: (a, b) => a.Name.localeCompare(b.Name),
    },
    {
      title: "Hotel Name",
      dataIndex: "HotelName",
    },
    {
      title: "Image Type",
      dataIndex: "ImageName",
    },
    {
      title: "Room Type",
      dataIndex: "RoomName",
    },

    {
      title: "Status",
      dataIndex: "no",
      key: "airline",
      render: (text, order) => (
        <ANTD.Switch
          checkedChildren="Active"
          unCheckedChildren="Inactive"
          defaultChecked
        />
      ),
    },
    {
      title: "Actions",
      render: (rec) => tableActions(rec),
    },
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  return (
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    <div className="rows-count">
                      <div>
                        <h5>
                          Manage Media{" "}
                          <HelpInfoHelper screenName={"/admin/cartypes"} />
                        </h5>
                        <p>{nameList.length} rows found !</p>
                      </div>
                    </div>
                    <div className="action-images">
                      {showSearchBox && (
                        <ANTD.Input
                          placeholder="Search"
                          onChange={(e) => searchTableData(e)}
                          suffix={closeSearchInput}
                          style={{ padding: "0px 12px" }}
                        />
                      )}
                      &nbsp;&nbsp;
                      <img
                        src={search}
                        alt="search"
                        onClick={(e) => searchData()}
                      />
                      <img
                        src={excel}
                        alt="excel"
                        onClick={(e) =>
                          props.exportExcel(excelList, "Manage Media")
                        }
                      />
                      <p
                        className="add-deposit-icon"
                        onClick={(e) => {
                          if (isEdit) {
                            setIsEdit(false);
                          }
                          handleRoleType(1);
                          setAddMarkupModal(true);
                          actHotelForm.resetFields();
                        }}
                      >
                        <PlusCircleFilled />
                      </p>
                    </div>
                  </div>

                  <div>
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={nameList}
                      rowKey={uniqueKey}
                      columns={columns}
                      pagination={{
                        defaultPageSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>{isEdit ? "Update" : "Add"} Manage Media</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-extranet"
        visible={addMarkupModal}
        onCancel={(e) => setAddMarkupModal(false)}
        footer={null}
        width={"1100px"}
        footer={[
          <div>
            <Button
              key="add"
              type="primary"
              htmlType="submit"
              onClick={actHotelForm.submit}
            >
              {isEdit ? "Update" : "Add"}
            </Button>
            <Button
              key="submit"
              type="danger"
              onClick={() => actHotelForm.resetFields()}
            >
              Clear
            </Button>
          </div>,
        ]}
      >
        <ANTD.Form
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          form={actHotelForm}
        >
          <ANTD.Row gutter={16}>
            <Col md={24} xs={24}>
              <Form.Item
                label="Meida Type"
                name="MediaFor"
                rules={[{ required: true, message: "Required" }]}
              >
                <Select
                  placeholder="Select Type"
                  onSelect={(val) => handleRoleType(val)}
                  onChange={handleTimeAndDate}
                >
                  <Select.Option value="1">Hotel</Select.Option>
                  <Select.Option value="2">Room</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Hotel Name"
                rules={[{ required: true }]}
                name="HotelName"
              >
                <Select
                  showSearch
                  placeholder="Select Type"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {managehotelname.map((item, index) => (
                    <Option key={index} value={item.HotelId}>
                      {`${item.HotelName}`}
                    </Option>
                  ))}
                </Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={24} xs={24}>
              <ANTD.Form.Item
                label="Image Type"
                rules={[{ required: true }]}
                name="ImageName"
              >
                <Select
                  showSearch
                  placeholder="Select Type"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {imgtypes.map((item, index) => (
                    <Option key={index} value={item.HotelImageId}>
                      {`${item.Name}`}
                    </Option>
                  ))}
                </Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            {isDateDisplay ? (
              <>
                <Col md={24} xs={24}>
                  <ANTD.Form.Item
                    label="Room Type"
                    rules={[{ required: true }]}
                    name="RoomName"
                  >
                    <Select
                      showSearch
                      placeholder="Select Type"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {roomtypes.map((item, index) => (
                        <Option key={index} value={item.HotelRoomTypeId}>
                          {`${item.RoomTypeName}`}
                        </Option>
                      ))}
                    </Select>
                  </ANTD.Form.Item>
                </Col>
              </>
            ) : null}

            <ANTD.Col xs={24} md={24}>
              {/* <ANTD.Form.Item name="phone" label="Image">
                <ANTD.Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </ANTD.Upload>
              </ANTD.Form.Item> */}

              <Form
                form={footerBannerForm}
                layout="vertical"
                onFinish={submitFooterBannerData}
                className="py-2"
              >
                <ANTD.Form.Item name="Images" label="Image">
                  <div className="upload-logo">
                    <Logos
                      category="footerbanners"
                      fileList={footerBanners}
                      setFileList={setFooterBanners}
                      formName="footerBanners"
                      limit={true}
                      limitSize={3}
                      deleteBanner={DeleteBannerImage}
                    />
                  </div>
                </ANTD.Form.Item>
              </Form>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};

export default UpdatedComponent(Manageimages);
