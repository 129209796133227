import React, { useState, useEffect } from "react";
import * as ANTD from "antd";
import "./Holidaypackages.scss";
import { Link } from "react-router-dom";
import { Carousel } from "antd";
import { Collapse } from "antd";
import {
  LineOutlined,
  MailOutlined,
  StarOutlined,
  EnvironmentOutlined,
  ScanOutlined,
} from "@ant-design/icons";
import { Tabs, Grid } from "antd";
const { useBreakpoint } = Grid;
const Holidaypackages = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const { TabPane } = Tabs;
  const { Panel } = ANTD.Collapse;
  const { md } = useBreakpoint();
  const [key, setKey] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [showUpdateBtn, setShowUpdateBtn] = useState(false);
  const handleModalpkg = () => {
    setShowUpdateBtn(false);

    setModalVisible(true);
  };

  return (
    <div className="collapseCustomWhite">
      <section className="pkg-v3-bg">
        <div className="admin-container cms-pages-width-v3">
          <Collapse
            activeKey={md ? ["1"] : key}
            showArrow={false}
            onChange={(val) => {
              setKey(val);
            }}
          >
            <Panel
              showArrow={false}
              header={<span className="hotels-hide-search">Modify Search</span>}
              key="1"
            >
              <ANTD.Form>
                <ANTD.Row className="v3-top-pkg">
                  <ANTD.Col md={6} xs={24}>
                    <ANTD.Form.Item className="rent-cars-value">
                      <ANTD.Input
                        className="v3-input"
                        placeholder="STARTING FROM"
                      />
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col md={6} xs={24}>
                    <ANTD.Form.Item className="rent-cars-value">
                      <ANTD.DatePicker
                        className="v3-input-1"
                        style={{ width: "100%", height: "45px" }}
                        placeholder="TRAVELLING ON"
                      />
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col md={6} xs={24}>
                    <ANTD.Form.Item className="rent-cars-value">
                      <ANTD.Input
                        className="v3-input"
                        placeholder="ROOMS & GUESTS"
                      />
                    </ANTD.Form.Item>
                  </ANTD.Col>

                  <ANTD.Col md={6} xs={24}>
                    <ANTD.Form.Item className="rent-cars-value">
                      <Link to="/carresults">
                        <ANTD.Button className="pkg-v3-search">
                          Search
                        </ANTD.Button>
                      </Link>
                    </ANTD.Form.Item>
                  </ANTD.Col>
                </ANTD.Row>
              </ANTD.Form>
            </Panel>
          </Collapse>
        </div>
      </section>

      <ANTD.Layout className="ourteam-bg hday-panel-21">
        <Content className="admin-container cms-pages-width-v3">
          <ANTD.Row>
            <ANTD.Col md={18} xs={24}>
              <ANTD.Row className="hd-row-1">
                <ANTD.Col md={15} xs={24}>
                  <div>
                    <Carousel autoplay>
                      <div>
                        <img
                          className="day-pk-img"
                          src={
                            require("../../assets/images/Dubai-bc.jpg").default
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="day-pk-img"
                          src={
                            require("../../assets/images/Dubai-bc.jpg").default
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="day-pk-img"
                          src={
                            require("../../assets/images/Dubai-bc.jpg").default
                          }
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className="day-pk-img"
                          src={
                            require("../../assets/images/Dubai-bc.jpg").default
                          }
                          alt=""
                        />
                      </div>
                    </Carousel>
                  </div>
                  <div className="photo-gallery-v3">
                    <p>
                      <i className="fa fa-picture-o" aria-hidden="true"></i>
                      &nbsp; View Gallery
                    </p>
                  </div>
                </ANTD.Col>
                <ANTD.Col md={9} xs={24}>
                  <div>
                    <img
                      className="day-tt-v3"
                      src={
                        require("../../assets/images/blog-info-1.jpg").default
                      }
                      alt=""
                    />
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row className="row-bc-222">
                <ANTD.Col md={16} xs={24}>
                  <div className="h-21-bc">
                    <p>3N Dubai</p>

                    <h3>Dubai EXPO Holiday - Super Saver</h3>
                    <span>
                      Discover what makes Expo 2020 Dubai unique. Explore the
                      possibilities when millions of minds come together.
                    </span>
                  </div>
                </ANTD.Col>
                <ANTD.Col md={8} xs={24}>
                  <p className="share-day-21">
                    <i className="fa fa-share" aria-hidden="true"></i>
                    &nbsp;Share
                  </p>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row className="">
                <ANTD.Col md={24} xs={24}>
                  <Tabs defaultActiveKey="1" className="inner-line-v3">
                    <TabPane tab="ITINERARY" key="1" className="inner-day-21">
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Day Plan" key="1">
                          <ANTD.Row>
                            <ANTD.Col
                              md={3}
                              xs={24}
                              className="width-padding-v3"
                            >
                              <div className="day-flex-times">
                                <p className="month-name-date">Dec</p>
                                <ul className="date-month-dist">
                                  <li>
                                    <a href="#">05</a>
                                  </li>
                                  <li>
                                    <a href="#cars-v3-scrooll">06</a>
                                  </li>
                                  <li>
                                    <a href="#hotel-v3-scrooll">07</a>
                                  </li>
                                  <li>
                                    <a href="">08</a>
                                  </li>
                                </ul>
                              </div>
                            </ANTD.Col>

                            <ANTD.Col md={21} xs={24}>
                              <ANTD.Row className="flight-cc-one">
                                <ANTD.Col md={18} xs={24}>
                                  <ANTD.Card className="flight-card-w3">
                                    <div className="iti-flight-w3">
                                      <div className="w3-flight-1">
                                        <div className="day-flex-11">
                                          <div>
                                            <img
                                              className="f1-day-flight"
                                              src={
                                                require("../../assets/images/h-day-1.png")
                                                  .default
                                              }
                                              alt=""
                                            />
                                            <p className="sg-day-1">
                                              IndiGo (6E-151)
                                            </p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w3-flight-2">
                                        <div className="w3-city-1">
                                          <div className="w3-loc-1">
                                            <span className="w3-code">HYD</span>
                                            <div className="w3-break-line">
                                              <div className="w3-inner-line"></div>
                                            </div>
                                            <span className="w3-code">BLR</span>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w3-flight-3">
                                        <span className="stop-type-w3">
                                          Direct
                                        </span>
                                        <span className="stop-type-cls">
                                          class: Economy
                                        </span>
                                      </div>
                                    </div>

                                    <div className="w3-timings-21">
                                      <div className="refundText">
                                        {" "}
                                        Refundable
                                      </div>
                                      <div className="timing-wrap-w3">
                                        <div className="plan-w3-21">
                                          <ul>
                                            <li>
                                              <p>08:40</p>
                                            </li>
                                            <li>
                                              <LineOutlined />
                                            </li>
                                            <li>
                                              <div className="w3-time">
                                                <p>1 h 15 min</p>
                                              </div>
                                            </li>
                                            <li>
                                              <LineOutlined />
                                            </li>
                                            <li>
                                              <p>09:40</p>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </ANTD.Card>
                                </ANTD.Col>
                                <ANTD.Col md={6} xs={24}>
                                  <ANTD.Card className="flight-card-w3">
                                    <div className="w3-btn-left">
                                      <div className="w3-share-mail">
                                        <div className="w3-price-show">
                                          <span className="cl-type">INR</span>
                                          <span className="cl-currency">
                                            1801.00
                                          </span>
                                        </div>

                                        <div className="mail-share">
                                          <MailOutlined />
                                        </div>
                                      </div>

                                      <div className="w3-cls-btn11">
                                        <ANTD.Button className="w3-sec-btn">
                                          Choose
                                        </ANTD.Button>
                                      </div>
                                    </div>
                                  </ANTD.Card>
                                </ANTD.Col>
                              </ANTD.Row>
                              {/*flight--close*/}
                              <ANTD.Row>
                                <ANTD.Col md={24} xs={24}>
                                  <div className="joining-line"></div>
                                </ANTD.Col>
                              </ANTD.Row>
                              {/*cars--open*/}
                              <ANTD.Row
                                className="cars-ratings-w3"
                                id="cars-v3-scrooll"
                              >
                                <ANTD.Col md={8} xs={24}>
                                  <img
                                    src={
                                      require("../../assets/images/carrental/car-rental-re-1.jpg")
                                        .default
                                    }
                                    alt=""
                                  />
                                </ANTD.Col>
                                <ANTD.Col md={12} xs={24}>
                                  <div className="seats-chair">
                                    <p className="nisha-cars">Nissan Versa </p>
                                    <ul className="seat-label-cars">
                                      <li>
                                        <i
                                          className="fa fa-wheelchair"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;5seat(s){" "}
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-suitcase"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;2luggage(s){" "}
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-bullseye"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;Auto{" "}
                                      </li>
                                      <li>
                                        <i
                                          className="fa fa-futbol-o"
                                          aria-hidden="true"
                                        ></i>
                                        &nbsp;A/C{" "}
                                      </li>
                                    </ul>
                                  </div>
                                </ANTD.Col>
                                <ANTD.Col
                                  md={4}
                                  xs={24}
                                  className="cars-btn-price"
                                >
                                  <p className="packages-num">Price from</p>
                                  <div className="price-drops">
                                    <h5 className="cost-of-values">
                                      <i
                                        className="fa fa-inr"
                                        aria-hidden="true"
                                      ></i>
                                      &nbsp;4,500
                                    </h5>
                                    &nbsp;
                                    {/* <div className="debtndrops">
                                      <i
                                        className="fa fa-chevron-down"
                                        aria-hidden="true"
                                      ></i>
                                    </div> */}
                                  </div>
                                </ANTD.Col>
                              </ANTD.Row>
                              {/*cars--close*/}
                              <ANTD.Row>
                                <ANTD.Col md={24} xs={24}>
                                  <div className="joining-line">
                                    Check-in to Hotel in Dubai @2 PM
                                  </div>
                                </ANTD.Col>
                              </ANTD.Row>
                              {/*hotel--open*/}
                              <ANTD.Row
                                className="hotel-md-w3"
                                id="hotel-v3-scrooll"
                              >
                                <ANTD.Col md={24} xs={24}>
                                  <div
                                    className="search-list-hotel-1"
                                    onClick={handleModalpkg}
                                  >
                                    <div className="results-w3-1">
                                      <div className="w3-grid-1">
                                        <div className="w3-img-11">
                                          <img
                                            className=""
                                            src={
                                              require("../../assets/images/no-hotel.png")
                                                .default
                                            }
                                            alt=""
                                          />
                                          <div className="gallery-icon-w3">
                                            <i
                                              className="fa fa-picture-o"
                                              aria-hidden="true"
                                            ></i>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="w3-grid-2">
                                        <div className="tittle-w3-hotel">
                                          <a href="#">
                                            <span className="hotel-name">
                                              Royal Falcon Hotel
                                            </span>
                                          </a>
                                        </div>
                                        <div className="hotel-start-info">
                                          <ul>
                                            <li>
                                              <StarOutlined />
                                            </li>
                                            <li>
                                              <StarOutlined />
                                            </li>
                                            <li>
                                              <StarOutlined />
                                            </li>
                                            <li>
                                              <StarOutlined />
                                            </li>
                                            <li>
                                              <StarOutlined />
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="loc-w3-num">
                                          <span>
                                            <EnvironmentOutlined />
                                          </span>
                                          <span className="hotel-address-location">
                                            Salahuddin Road, 25871
                                          </span>
                                        </div>
                                        <div className="add-w3-hotel">
                                          <p>
                                            With a stay at Royal Falcon Hotel,
                                            you ll be centrally located in
                                            Dubai, within a 5-minute drive of
                                            Gold Souk and Al Ghurair Centre.
                                            This hotel is 2.9 mi (4.7 km) from
                                            City Centre Deira and 3.1 mi (5 km)
                                            from Dubai Creek.
                                          </p>
                                        </div>
                                      </div>

                                      <div className="w3-grid-3">
                                        <div className="price-mar-w3">
                                          <div className="w3-hr-info">
                                            INR <span>3212.66</span>
                                          </div>
                                          <div className="hr-btn-one">
                                            <ANTD.Button className="choose-hr-w3">
                                              Choose
                                            </ANTD.Button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </ANTD.Col>
                              </ANTD.Row>
                              {/*hotel--close*/}
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                        <TabPane tab="Flights 2" key="2">
                          <ANTD.Row className="">
                            <ANTD.Col md={18} xs={24}>
                              <ANTD.Card className="flight-card-w3">
                                <div className="iti-flight-w3">
                                  <div className="w3-flight-1">
                                    <div className="day-flex-11">
                                      <div>
                                        <img
                                          className="f1-day-flight"
                                          src={
                                            require("../../assets/images/h-day-1.png")
                                              .default
                                          }
                                          alt=""
                                        />
                                        <p className="sg-day-1">
                                          IndiGo (6E-151)
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w3-flight-2">
                                    <div className="w3-city-1">
                                      <div className="w3-loc-1">
                                        <span className="w3-code">HYD</span>
                                        <div className="w3-break-line">
                                          <div className="w3-inner-line"></div>
                                        </div>
                                        <span className="w3-code">BLR</span>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w3-flight-3">
                                    <span className="stop-type-w3">Direct</span>
                                    <span className="stop-type-cls">
                                      class: Economy
                                    </span>
                                  </div>
                                </div>

                                <div className="w3-timings-21">
                                  <div className="refundText"> Refundable</div>
                                  <div className="timing-wrap-w3">
                                    <div className="plan-w3-21">
                                      <ul>
                                        <li>
                                          <p>08:40</p>
                                        </li>
                                        <li>
                                          <LineOutlined />
                                        </li>
                                        <li>
                                          <div className="w3-time">
                                            <p>1 h 15 min</p>
                                          </div>
                                        </li>
                                        <li>
                                          <LineOutlined />
                                        </li>
                                        <li>
                                          <p>09:40</p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </ANTD.Card>
                            </ANTD.Col>
                            <ANTD.Col md={6} xs={24}>
                              <ANTD.Card className="flight-card-w3">
                                <div className="w3-btn-left">
                                  <div className="w3-share-mail">
                                    <div className="w3-price-show">
                                      <span className="cl-type">INR</span>
                                      <span className="cl-currency">
                                        1801.00
                                      </span>
                                    </div>

                                    <div className="mail-share">
                                      <MailOutlined />
                                    </div>
                                  </div>

                                  <div className="w3-cls-btn11">
                                    <ANTD.Button className="w3-sec-btn">
                                      Choose
                                    </ANTD.Button>
                                  </div>
                                </div>
                              </ANTD.Card>
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                        <TabPane tab="Hotels 2" key="3">
                          <ANTD.Row className="">
                            <ANTD.Col md={24} xs={24}>
                              <div
                                className="search-list-hotel-1"
                                onClick={handleModalpkg}
                              >
                                <div className="results-w3-1">
                                  <div className="w3-grid-1">
                                    <div className="w3-img-11">
                                      <img
                                        className=""
                                        src={
                                          require("../../assets/images/no-hotel.png")
                                            .default
                                        }
                                        alt=""
                                      />
                                      <div className="gallery-icon-w3">
                                        <i
                                          className="fa fa-picture-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w3-grid-2">
                                    <div className="tittle-w3-hotel">
                                      <a href="#">
                                        <span className="hotel-name">
                                          Royal Falcon Hotel
                                        </span>
                                      </a>
                                    </div>
                                    <div className="hotel-start-info">
                                      <ul>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="loc-w3-num">
                                      <span>
                                        <EnvironmentOutlined />
                                      </span>
                                      <span className="hotel-address-location">
                                        Salahuddin Road, 25871
                                      </span>
                                    </div>
                                    <div className="add-w3-hotel">
                                      <p>
                                        With a stay at Royal Falcon Hotel, you
                                        ll be centrally located in Dubai, within
                                        a 5-minute drive of Gold Souk and Al
                                        Ghurair Centre. This hotel is 2.9 mi
                                        (4.7 km) from City Centre Deira and 3.1
                                        mi (5 km) from Dubai Creek.
                                      </p>
                                    </div>
                                  </div>

                                  <div className="w3-grid-3">
                                    <div className="price-mar-w3">
                                      <div className="w3-hr-info">
                                        INR <span>3212.66</span>
                                      </div>
                                      <div className="hr-btn-one">
                                        <ANTD.Button className="choose-hr-w3">
                                          Choose
                                        </ANTD.Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                        <TabPane tab="Transfers 2" key="4">
                          <ANTD.Row className="cars-ratings-act  vc-3">
                            <ANTD.Col md={8} xs={24}>
                              <img
                                src={
                                  require("../../assets/images/carrental/car-rental-re-1.jpg")
                                    .default
                                }
                                alt=""
                              />
                            </ANTD.Col>
                            <ANTD.Col md={12} xs={24}>
                              <div className="seats-chair">
                                <p className="nisha-cars">Nissan Versa </p>
                                <ul className="seat-label-cars">
                                  <li>
                                    <i
                                      className="fa fa-wheelchair"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;5seat(s){" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-suitcase"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;2luggage(s){" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-bullseye"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;Auto{" "}
                                  </li>
                                  <li>
                                    <i
                                      className="fa fa-futbol-o"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;A/C{" "}
                                  </li>
                                </ul>
                              </div>
                            </ANTD.Col>
                            <ANTD.Col md={4} xs={24} className="cars-btn-price">
                              <p className="packages-num">Price from</p>
                              <div className="price-drops">
                                <h5 className="cost-of-values">
                                  <i
                                    className="fa fa-inr"
                                    aria-hidden="true"
                                  ></i>
                                  &nbsp;4,500
                                </h5>
                                &nbsp;
                                {/* <div className="debtndrops">
                                  <i
                                    className="fa fa-chevron-down"
                                    aria-hidden="true"
                                  ></i>
                                </div> */}
                              </div>
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                        <TabPane tab="Activities 2" key="5">
                          <ANTD.Row className="">
                            <ANTD.Col md={24} xs={24}>
                              <div
                                className="search-list-hotel-1"
                                onClick={handleModalpkg}
                              >
                                <div className="results-w3-1">
                                  <div className="w3-grid-1">
                                    <div className="w3-img-11">
                                      <img
                                        className=""
                                        src={
                                          require("../../assets/images/no-hotel.png")
                                            .default
                                        }
                                        alt=""
                                      />
                                      <div className="gallery-icon-w3">
                                        <i
                                          className="fa fa-picture-o"
                                          aria-hidden="true"
                                        ></i>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="w3-grid-2">
                                    <div className="tittle-w3-hotel">
                                      <a href="#">
                                        <span className="hotel-name">
                                          Royal Falcon Hotel
                                        </span>
                                      </a>
                                    </div>
                                    <div className="hotel-start-info">
                                      <ul>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                        <li>
                                          <StarOutlined />
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="loc-w3-num">
                                      <span>
                                        <EnvironmentOutlined />
                                      </span>
                                      <span className="hotel-address-location">
                                        Salahuddin Road, 25871
                                      </span>
                                    </div>
                                    <div className="add-w3-hotel">
                                      <p>
                                        With a stay at Royal Falcon Hotel, you
                                        ll be centrally located in Dubai, within
                                        a 5-minute drive of Gold Souk and Al
                                        Ghurair Centre. This hotel is 2.9 mi
                                        (4.7 km) from City Centre Deira and 3.1
                                        mi (5 km) from Dubai Creek.
                                      </p>
                                    </div>
                                  </div>

                                  <div className="w3-grid-3">
                                    <div className="price-mar-w3">
                                      <div className="w3-hr-info">
                                        INR <span>3212.66</span>
                                      </div>
                                      <div className="hr-btn-one">
                                        <ANTD.Button className="choose-hr-w3">
                                          Choose
                                        </ANTD.Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                        <TabPane tab="Summary" key="6">
                          <ANTD.Row className="">
                            <ANTD.Col md={2} xs={24}>
                              <p className="summary-day">Day 1</p>
                            </ANTD.Col>
                            <ANTD.Col md={22} xs={24}>
                              <ul className="summary-plan-v3">
                                <li>
                                  Arrival in Dubai via&nbsp;<b>flight</b>
                                </li>
                                <li>Transfer to hotel via private vehicle.</li>
                                <li>
                                  Check-in to&nbsp;<b>Hotel in Dubai</b>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                          <ANTD.Row className="tt-row-v3">
                            <ANTD.Col md={2} xs={24}>
                              <p className="summary-day">Day 2</p>
                            </ANTD.Col>
                            <ANTD.Col md={22} xs={24}>
                              <ul className="summary-plan-v3">
                                <li>
                                  Arrival in Dubai via&nbsp;<b>flight</b>
                                </li>
                                <li>Transfer to hotel via private vehicle.</li>
                                <li>
                                  Check-in to&nbsp;<b>Hotel in Dubai</b>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                          <ANTD.Row className="tt-row-v3">
                            <ANTD.Col md={2} xs={24}>
                              <p className="summary-day">Day 3</p>
                            </ANTD.Col>
                            <ANTD.Col md={22} xs={24}>
                              <ul className="summary-plan-v3">
                                <li>
                                  Arrival in Dubai via&nbsp;<b>flight</b>
                                </li>
                                <li>Transfer to hotel via private vehicle.</li>
                                <li>
                                  Check-in to&nbsp;<b>Hotel in Dubai</b>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                          <ANTD.Row className="tt-row-v3">
                            <ANTD.Col md={2} xs={24}>
                              <p className="summary-day">Day 4</p>
                            </ANTD.Col>
                            <ANTD.Col md={22} xs={24}>
                              <ul className="summary-plan-v3">
                                <li>
                                  Arrival in Dubai via&nbsp;<b>flight</b>
                                </li>
                                <li>Transfer to hotel via private vehicle.</li>
                                <li>
                                  Check-in to&nbsp;<b>Hotel in Dubai</b>
                                </li>
                              </ul>
                            </ANTD.Col>
                          </ANTD.Row>
                        </TabPane>
                      </Tabs>
                    </TabPane>

                    <TabPane tab="VISA" key="2">
                      <ANTD.Row className="">
                        <ANTD.Col md={24} xs={24}>
                          <div className="img-funtion-visa">
                            <p>
                              <span>
                                <img
                                  className="visa-vv"
                                  src={
                                    require("../../assets/images/visa-v.png")
                                      .default
                                  }
                                  alt=""
                                />
                              </span>
                              <span></span>
                            </p>
                            <ANTD.Form.Item
                              label="
                              Include Visa?"
                              name="TripType"
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <ANTD.Radio.Group>
                                <ANTD.Radio value="false">Yes</ANTD.Radio>
                                <ANTD.Radio value="true">No</ANTD.Radio>
                              </ANTD.Radio.Group>
                            </ANTD.Form.Item>
                          </div>
                        </ANTD.Col>
                      </ANTD.Row>
                      <ANTD.Row className="">
                        <ANTD.Col md={24} xs={24}>
                          <p className="read-faqs-v3">Read FAQs</p>
                          <div>
                            <Collapse accordion>
                              <Panel
                                header="How long does it take to get a visa?"
                                key="1"
                              >
                                <p>
                                  After the submission at embassy, it usually
                                  takes 2-3 working days. Please note no visas
                                  are processed on Friday and Saturday.
                                </p>
                              </Panel>
                              <Panel
                                header="Is Okay to Board included in the fees?"
                                key="2"
                              >
                                <p>
                                  After the submission at embassy, it usually
                                  takes 2-3 working days. Please note no visas
                                  are processed on Friday and Saturday.
                                </p>
                              </Panel>
                              <Panel
                                header="What are the list of documents required for visa processing?"
                                key="3"
                              >
                                <p>
                                  After the submission at embassy, it usually
                                  takes 2-3 working days. Please note no visas
                                  are processed on Friday and Saturday.
                                </p>
                              </Panel>
                            </Collapse>
                          </div>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>
                    <TabPane tab="POLICIES" key="3">
                      <ANTD.Row className="">
                        <ANTD.Col md={24} xs={24}>
                          <div className="h-21-bc">
                            <h6>CANCELLATION & DATE CHANGE</h6>
                            <span>
                              How much will it cost to cancel or change the
                              start date after making a booking?
                            </span>
                          </div>
                          <Tabs defaultActiveKey="1">
                            <TabPane tab="CANCELLATION" key="1">
                              <ANTD.Row className="">
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">₹20,000</p>
                                      <p className="v3-p4">Per Person</p>
                                      <p className="v3-p5">
                                        Cancellation Charge
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">₹20,000</p>
                                      <p className="v3-p4">Per Person</p>
                                      <p className="v3-p5">
                                        Cancellation Charge
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">₹20,000</p>
                                      <p className="v3-p4">Per Person</p>
                                      <p className="v3-p5">
                                        Cancellation Charge
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                              </ANTD.Row>
                              <ANTD.Row className="cance-pkg-modi">
                                <ANTD.Col md={24} xs={24}>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                </ANTD.Col>
                              </ANTD.Row>
                              <ANTD.Row className="cance-pkg-modi">
                                <ANTD.Col md={24} xs={24}>
                                  <h6>
                                    <b>Important Information</b>
                                  </h6>
                                  <p className="visa-disc-v3">
                                    1.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    1.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    2.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    3.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    4.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                </ANTD.Col>
                              </ANTD.Row>
                            </TabPane>
                            <TabPane tab="DATE CHANGE" key="2">
                              <ANTD.Row className="">
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">₹20,000</p>
                                      <p className="v3-p4">Per Person</p>
                                      <p className="v3-p5">
                                        Cancellation Charge
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">₹20,000</p>
                                      <p className="v3-p4">Per Person</p>
                                      <p className="v3-p5">
                                        Cancellation Charge
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                                <ANTD.Col md={8} xs={24}>
                                  <div className="policies-v3">
                                    <p className="v3-p1">Till</p>
                                    <p className="v3-p2">4th Nov</p>

                                    <div className="v3-rating-policy">
                                      <p className="v3-p3">Non Refundable</p>

                                      <p className="v3-p5">
                                        Date Change is not allowed
                                      </p>
                                    </div>
                                  </div>
                                </ANTD.Col>
                              </ANTD.Row>
                              <ANTD.Row className="cance-pkg-modi">
                                <ANTD.Col md={24} xs={24}>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                  <p>
                                    <ScanOutlined />
                                    &nbsp; These are non-refundable amounts as
                                    per the current components attached. In the
                                    case of component change/modifications, the
                                    policy will change accordingly.
                                  </p>
                                </ANTD.Col>
                              </ANTD.Row>
                              <ANTD.Row className="cance-pkg-modi">
                                <ANTD.Col md={24} xs={24}>
                                  <h6>
                                    <b>Important Information</b>
                                  </h6>
                                  <p className="visa-disc-v3">
                                    1.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    1.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    2.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    3.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                  <p className="visa-disc-v3">
                                    4.Visa Disclaimer : Please note that the
                                    final decision regarding a visa application
                                    rests solely with the respective
                                    Embassy/Consulate.
                                  </p>
                                </ANTD.Col>
                              </ANTD.Row>
                            </TabPane>
                          </Tabs>
                        </ANTD.Col>
                      </ANTD.Row>
                    </TabPane>
                    <TabPane tab="SIMILAR PACKAGES" key="4">
                      Content of Tab Pane 3
                    </TabPane>
                  </Tabs>
                </ANTD.Col>
              </ANTD.Row>
            </ANTD.Col>

            <ANTD.Col md={6} xs={24} className="right-left-ve">
              <ANTD.Row className="">
                <ANTD.Col md={24} xs={24}>
                  <div className="left-price-flex">
                    <div className="price-all-v3-from">
                      <p>
                        <span className="price-v3-1">₹ 65,589</span>
                        <span className="price-v3-2">per person</span>
                      </p>
                    </div>
                    <div>
                      <Collapse accordion>
                        <Panel header="View All offers applied" key="1">
                          <ul className="offers-app-v3">
                            <li>
                              <div className="flexOne-app">
                                <p className="append-v3">GREATDEALS</p>
                                <p className="applied-v3">
                                  Coupon applied successfully
                                </p>
                              </div>
                              <p className="amount-app-v3">₹20,004</p>
                            </li>
                            <li className="brdrTop1">
                              <div className="flexOne-app">
                                <p className="append-v3">Total Savings</p>
                                <p className="applied-v3">per person</p>
                              </div>
                              <p className="amount-app-v3">₹20,004</p>
                            </li>
                          </ul>
                        </Panel>
                      </Collapse>
                    </div>
                    <ANTD.Button className="book-v3-price22">
                      Book Now
                    </ANTD.Button>
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row className="v3-additions-1">
                <ANTD.Col md={24} xs={24}>
                  <div className="left-price-flex">
                    <h3 className="charges-v3-add">Changes/Additions</h3>
                    <p className="v3-padding-20">No Changes to show</p>
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row className="v3-additions-1">
                <ANTD.Col md={24} xs={24}>
                  <div className="left-change-date">
                    <div className="v3-panel-date">
                      <img
                        className="change-img-vv"
                        src={
                          require("../../assets/images/change-date.png").default
                        }
                        alt=""
                      />
                      <p className="v3-date-pic1">
                        Pay ₹ 11430 lesser for this package on 21 November, 2021
                      </p>
                    </div>
                    <p className="v3-date-pic2">CHANGE DATE</p>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </ANTD.Layout>
      {/*modals-start--hotel*/}
      <ANTD.Modal
        title={[
          <div>
            <h6 style={{ marginBottom: "0px" }}>
              <strong>Hotel</strong>
            </h6>
          </div>,
        ]}
        className="promo-modal-header hotel-popup-set"
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        width={"800px"}
        footer={[
          <div>
            {showUpdateBtn ? (
              <ANTD.Button key="add" type="primary">
                Update
              </ANTD.Button>
            ) : (
              <ANTD.Button key="add" type="primary">
                Close
              </ANTD.Button>
            )}
          </div>,
        ]}
      >
        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-hotel-modalpop">
              <h4 className="v3-popup-heading">
                Howard Johnson Bur Dubai Hotel - MMT Special
              </h4>
            </div>
            <div className="hotel-start-info">
              <ul>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
              </ul>
            </div>

            <div className="carousel-v3-modal">
              <Carousel autoplay>
                <div>
                  <img
                    className="pop-up-img1"
                    src={require("../../assets/images/Dubai-bc.jpg").default}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="pop-up-img1"
                    src={require("../../assets/images/Dubai-bc.jpg").default}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="pop-up-img1"
                    src={require("../../assets/images/Dubai-bc.jpg").default}
                    alt=""
                  />
                </div>
                <div>
                  <img
                    className="pop-up-img1"
                    src={require("../../assets/images/Dubai-bc.jpg").default}
                    alt=""
                  />
                </div>
              </Carousel>
            </div>

            <div className="date-popup-check">
              <div className="inner-room-modal">
                <p>Check In</p>
                <p>2 PM</p>
              </div>
              <div className="inner-room-modal-1">
                <p>Check Out</p>
                <p>12 PM</p>
              </div>
            </div>
          </ANTD.Col>
        </ANTD.Row>
        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v3">About</p>
              <p className="hotel-v3-plan-w">
                Centrally located in the heart of Bur Dubai, the Howard Johnson
                Hotel is a mere 3 minutes walk from the Meena Bazaar and about
                7-10 minutes from the Dubai Museum, The airport is a mere 8 km
                from the hotel. The Al Fahidi metro station is at a walking
                distance of 0.2 km from the hotel. Dubai Mall and Burj Khalifa
                is 15-20 mins Drive from Hotel.
              </p>
              <p>
                Centrally located in the heart of Bur Dubai, the Howard Johnson
                Hotel is a mere 3 minutes walk from the Meena Bazaar and about
                7-10 minutes from the Dubai Museum, The airport is a mere 8 km
                from the hotel. The Al Fahidi metro station is at a walking
                distance of 0.2 km from the hotel. Dubai Mall and Burj Khalifa
                is 15-20 mins Drive from Hotel.
              </p>
              <p>
                Centrally located in the heart of Bur Dubai, the Howard Johnson
                Hotel is a mere 3 minutes walk from the Meena Bazaar and about
                7-10 minutes from the Dubai Museum, The airport is a mere 8 km
                from the hotel. The Al Fahidi metro station is at a walking
                distance of 0.2 km from the hotel. Dubai Mall and Burj Khalifa
                is 15-20 mins Drive from Hotel.
              </p>
            </div>
            <div className="v3-graph-hotel">
              <p className="about-v3">Amenities</p>
              <ul className="amenities-v3">
                <li>Staff Hygiene</li>
                <li>Sanitizers Installed</li>
                <li>Disinfection</li>
                <li>Staff Hygiene</li>
                <li>Staff Hygiene</li>
                <li>Staff Hygiene</li>
                <li>Sanitizers Installed</li>
                <li>Disinfection</li>
              </ul>
            </div>
          </ANTD.Col>
        </ANTD.Row>
        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v4">Select Rooms</p>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v3">Deluxe Room</p>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}>
            <div>
              <img
                className="roomimg1-v3"
                src={require("../../assets/images/Dubai-bc.jpg").default}
                alt=""
              />
            </div>
          </ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">Room with Breakfast</p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}></ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">
                Room with 2 meals - Breakfast & Lunch or Dinner
              </p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div className="rating-v3-in">
              <p>+ ₹1,496</p>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}></ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">
                Room with All meals - Breakfast, Lunch & Dinner
              </p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div className="rating-v3-in">
              <p>+ ₹1,496</p>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="line-graph-hotel-v3"></div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v3">Standard Room</p>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}>
            <div>
              <img
                className="roomimg1-v3"
                src={require("../../assets/images/Dubai-bc.jpg").default}
                alt=""
              />
            </div>
          </ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">Room with Breakfast</p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}></ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">
                Room with 2 meals - Breakfast & Lunch or Dinner
              </p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div className="rating-v3-in">
              <p>+ ₹1,496</p>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={6} xs={24}></ANTD.Col>
          <ANTD.Col md={12} xs={24}>
            <div>
              <p className="about-v3">
                Room with All meals - Breakfast, Lunch & Dinner
              </p>
              <ul className="available-v3-room">
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary Breakfast is available.
                </li>
                <li>
                  <i className="fa fa-caret-right" aria-hidden="true"></i>&nbsp;
                  Complimentary a la carte Dinner is available. It is served
                  with Continental options. 1 dinner are allowed per person
                </li>
              </ul>
            </div>
          </ANTD.Col>
          <ANTD.Col md={6} xs={24}>
            <div className="rating-v3-in">
              <p>+ ₹1,496</p>
              <ANTD.Button className="include-v3-btn">INCLUDE</ANTD.Button>
            </div>
          </ANTD.Col>
        </ANTD.Row>
        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="line-graph-hotel-v3"></div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v3">Location</p>
            </div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="line-graph-hotel-v3"></div>
          </ANTD.Col>
        </ANTD.Row>

        <ANTD.Row>
          <ANTD.Col md={24} xs={24}>
            <div className="v3-graph-hotel">
              <p className="about-v3">User ratings and Reviews</p>
              <p className="review-name-v3">Testv, Oct 20 2021</p>
            </div>
            <div className="hotel-start-info">
              <ul>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
                <li>
                  <StarOutlined />
                </li>
              </ul>
            </div>
            <p>
              Overall good stay. Pocket friendly and the most beautiful thing is
              they take a extra step to listen your needs and try their best to
              fulfill it. Travel desk is in house and entertainment at its best.
              Located i
            </p>
          </ANTD.Col>
        </ANTD.Row>
      </ANTD.Modal>
    </div>
  );
};

export default Holidaypackages;
