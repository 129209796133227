import React, { useState, useEffect } from "react";
import "../Dashboard/Dashboard.scss";
import * as ANTD from "antd";
import { Link } from "react-router-dom";
import Flightsalescount from "../../admin/Flightsalescount/Flightsalescount";
import Bussalescount from "../../admin/Bussalescount/Bussalescount";
import HotelSalescount from "../../admin/Hotelsalescount/Hotelsalescount";
import Activitysalescount from "../../admin/Acvitiessalescount/Acvitiessalescount";
import FlightSalesCountSkeleton from "../Flightsalescount/FlightSalesCountSkeleton";
import BusSalesCountSkeleton from "../Bussalescount/BusSalesCountSkeleton";
import HotelSalesCountSkeleton from "../Hotelsalescount/HotelSalesCountSkeleton";
import ApiClient from "../../helpers/ApiClient";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({
    buses: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    flights: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    hotels: {
      recent_bookings: [],
      upcoming_bookings: [],
    },
    userCounts: {},
  });
  useEffect(() => {
    getDashboardData();
  }, []);
  const getDashboardData = () => {
    ApiClient.get("admin/dashboard")
      .then((res) => {
        if (res && res.status == 200) {
          setDashboardData((prev) => ({ ...prev, ...res.data }));
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div>
      {isLoading ? (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>
                Dashboard <HelpInfoHelper screenName={"/admin/dashboard"} />
              </h4>
            </div>
          </div>

          <div>
            <ANTD.Row gutter={[16, 16]}>
              {/* card--1--open */}
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card>
                  <ANTD.Skeleton active paragraph={{ rows: 2 }} />
                </ANTD.Card>
              </ANTD.Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card>
                  <ANTD.Skeleton active paragraph={{ rows: 2 }} />
                </ANTD.Card>
              </ANTD.Col>

              {/* card--4--close */}

              {/* card--5--open */}
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card>
                  <ANTD.Skeleton active paragraph={{ rows: 2 }} />
                </ANTD.Card>
              </ANTD.Col>

              {/* card--5--close */}
            </ANTD.Row>
          </div>
          {/* first--order--close--- */}

          {/* flight--sales--open */}

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <FlightSalesCountSkeleton />
              </ANTD.Col>
            </ANTD.Row>
          </div>

          {/* flight--sales--close */}

          {/* Bus--sales--open */}

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <BusSalesCountSkeleton />
              </ANTD.Col>
            </ANTD.Row>
          </div>

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <HotelSalesCountSkeleton />
              </ANTD.Col>
            </ANTD.Row>
          </div>
          {/* Bus--sales--close */}
        </div>
      ) : (
        <div className="dashboard">
          <div className="dash-board-header">
            <div className="dash-board-title">
              <h4>Dashboard</h4>
            </div>

            <div className="dash-board-header-btn"></div>
          </div>

          <div className="order-count-cards">
            <ANTD.Row gutter={[16, 16]}>
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card className="order-card card-bg-1">
                  <ANTD.Row>
                    <ANTD.Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">
                        Site Partners Count
                      </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.agents_count}
                      </p>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={8}>
                      <i
                        className="fa fa-desktop dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </ANTD.Col>
                  </ANTD.Row>
                  <div className="bottom-dashboad-bdr">
                    <Link to="/admin/agentregistration">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </ANTD.Card>
              </ANTD.Col>

              {/* card--1--close */}

              {/* card--4--open */}
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card className="order-card card-bg-4">
                  <ANTD.Row>
                    <ANTD.Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Subscribers </h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.subscribers}
                      </p>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={8}>
                      <i
                        className="fa fa-clock-o dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </ANTD.Col>
                  </ANTD.Row>
                  <div className="bottom-dashboad-bdr-3">
                    <Link to="/admin/subscriberreport">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </ANTD.Card>
              </ANTD.Col>

              {/* card--4--close */}

              {/* card--5--open */}
              {/* <ANTD.Col xs={24} md={6}>
                <ANTD.Card className="order-card card-bg-5">
                  <ANTD.Row>
                    <ANTD.Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Customer Requests</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.customer_requests}
                      </p>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </ANTD.Col>
                  </ANTD.Row>

                  <div className="bottom-dashboad-bdr-4">
                    <p className="dashboard-datails-p">
                      View Details&nbsp;
                      <i
                        className="fa fa-arrow-circle-right"
                        aria-hidden="true"
                      ></i>
                    </p>
                  </div>
                </ANTD.Card>
              </ANTD.Col> */}

              {/* card--5--close */}

              {/* card--5--open */}
              <ANTD.Col xs={24} md={6}>
                <ANTD.Card className="order-card card-bg-b2">
                  <ANTD.Row>
                    <ANTD.Col xs={24} md={16}>
                      <h4 className="dash-boadrd-heading">Site Users Count</h4>
                      <p className="count-dashboard">
                        {dashboardData.userCounts.users_count}
                      </p>
                    </ANTD.Col>
                    <ANTD.Col xs={24} md={8}>
                      <i
                        className="fa fa-user dash-board-icon-all"
                        aria-hidden="true"
                      ></i>
                    </ANTD.Col>
                  </ANTD.Row>

                  <div className="bottom-dashboad-bdr-4">
                    <Link to="/admin/siteusers">
                      <p className="dashboard-datails-p">
                        View Details&nbsp;
                        <i
                          className="fa fa-arrow-circle-right"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </Link>
                  </div>
                </ANTD.Card>
              </ANTD.Col>
            </ANTD.Row>
          </div>
          {/* first--order--close--- */}

          {/* flight--sales--open */}

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <Flightsalescount flightsData={dashboardData.flights} />
              </ANTD.Col>
            </ANTD.Row>
          </div>

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <Bussalescount busData={dashboardData.buses} />
              </ANTD.Col>
            </ANTD.Row>
          </div>
          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <HotelSalescount hotelData={dashboardData.hotels} />
              </ANTD.Col>
            </ANTD.Row>
          </div>

          <div className="order-count-cards">
            <ANTD.Row>
              <ANTD.Col xs={24} md={24}>
                <Activitysalescount activitiesData={dashboardData.activities} />
              </ANTD.Col>
            </ANTD.Row>
          </div>
        </div>
      )}
    </div>
  );
};
export default Dashboard;
