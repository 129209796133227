import React from "react";
import "../HolidaysSearch/HolidaysSearch.scss";
import Banner from "../../../components/banner/Banner";
import Holidays from "../../../components/Holidaysplaces/Holidaysplaces.scss";
import Holidaysplaces from "../../../components/Holidaysplaces/Holidaysplaces";

import Subscribe from "../../../components/HomeCarousel/HomeCarousel";
/* import * as ReactBoostrap from "react-bootstrap"; */

import { Button } from "antd";
import * as ANTD from "antd";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import HomeCarousel from "../../../components/HomeCarousel/HomeCarousel";

const HolidaysSearch = (props) => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  const CarouselNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#0775e2" }}
        onClick={onClick}
      />
    );
  };
  const CarouselPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline-table", background: "#0775e2" }}
        onClick={onClick}
      />
    );
  };

  const settings = {
    infinite: true,
    className: "center",
    centerMode: true,
    slidesToShow: 1,
    // autoplay: true,
    autoplaySpeed: 2000,
    rows: 2,
    // slidesPerRow: 2,
    infinite: true,
    pauseOnHover: true,
    // arrows: true,
    nextArrow: <CarouselNextArrow />,
    prevArrow: <CarouselPrevArrow />,
  };

  return (
    <div className="holidays_search_container">
      <section className="holidays_banner">
        <div className="heading_text">
          <h2>Book Domestic and International Holiday Packages</h2>
        </div>
        <div>
          <Banner />
        </div>
        <div className="hotel_search">
          <Holidaysplaces gscontext={props.gscontext} getBusAvailabilityData />
        </div>
      </section>

      <section className="holidays_advantages">
        <div className="holidays-advantages-wrapper">
          <div className="holidays-adv-box left-box">
            <img
              src={
                require("../../../assets/images/holidays/holidays-1.png")
                  .default
              }
              alt=""
            />
            <div className="holidays-adv-box-wrapper">
              <p> Handpicked experiences</p>
              <small>Read real user reviews</small>
            </div>
          </div>
          <div className="holidays-adv-box right-box">
            <img
              src={
                require("../../../assets/images/holidays/holidays-2.png")
                  .default
              }
              alt=""
            />
            <div className="holidays-adv-box-wrapper">
              <p> Best price guaranteed</p>
              <small>Discounts up to 60% off</small>
            </div>
          </div>

          <div className="holidays-adv-box right-box">
            <img
              src={
                require("../../../assets/images/holidays/holidays-3.png")
                  .default
              }
              alt=""
            />
            <div className="holidays-adv-box-wrapper">
              <p> Seamless and safe booking</p>
              <small>Hassle-free e-ticket entry </small>
            </div>
          </div>
        </div>
      </section>

      <HomeCarousel />

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search-apps">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <h4>More Recommended</h4>
              <ANTD.Row className="card-images">
                <ANTD.Col md={6} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-1.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Ramoji Film City Day Tour</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-2.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hussain Sagar Lake Boat Ride</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-3.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hyderabad Zoological Park </a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <ANTD.Card
                    className="body-card"
                    hoverable
                    style={{ width: 240 }}
                    cover={
                      <img
                        alt="example"
                        src={
                          require("../../../assets/images/holidays/holidays-city-4.png")
                            .default
                        }
                      />
                    }
                  >
                    <ul className="locations-hol">
                      <li>
                        <a href="#">Hyderabad Salar Jung Museum</a>
                      </li>
                      <li>
                        <span>
                          <i className="fa fa-star-o" aria-hidden="true"></i>
                          &nbsp;
                          <span className="hol-1">4.3</span> |
                          <span className="hol-2">(9 reviews)</span>
                          <span className="hol-2">200+ Booked</span>
                        </span>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <del>
                            <span>₹</span> 5,100
                          </del>
                        </p>
                      </li>
                      <li>
                        <p className="mg-bot">
                          <span>
                            <b>₹ 4,800</b>
                          </span>
                        </p>
                        <p className="mg-bot">
                          <span>Available from 26 Sep 2020</span>
                        </p>
                      </li>
                    </ul>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row>
                <ANTD.Col md={24} xs={24} className="explore-btn">
                  <ANTD.Button className="all-near-places">
                    View All
                  </ANTD.Button>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      {/* second--row--start */}

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search-apps">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <h4>Popular Destinations</h4>

              {/*--start----end--*/}

              <ANTD.Row>
                <ANTD.Col md={6} xs={24}>
                  <img
                    className="cart-shopping-img"
                    src={
                      require("../../../assets/images/holidays/shopping-d-1.png")
                        .default
                    }
                    alt=""
                  />
                  <h4 className="cart-h4">Hong Kong</h4>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <img
                    className="cart-shopping-img"
                    src={
                      require("../../../assets/images/holidays/shopping-d-2.png")
                        .default
                    }
                    alt=""
                  />
                  <h4 className="cart-h4">Taipei</h4>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <img
                    className="cart-shopping-img"
                    src={
                      require("../../../assets/images/holidays/shopping-d-3.png")
                        .default
                    }
                    alt=""
                  />
                  <h4 className="cart-h4">Phuket</h4>
                </ANTD.Col>

                <ANTD.Col md={6} xs={24}>
                  <img
                    className="cart-shopping-img"
                    src={
                      require("../../../assets/images/holidays/shopping-d-4.png")
                        .default
                    }
                    alt=""
                  />
                  <h4 className="cart-h4">Kuala Lumpur</h4>
                </ANTD.Col>
              </ANTD.Row>

              <ANTD.Row>
                <ANTD.Col md={24} xs={24} className="explore-btn">
                  <ANTD.Button className="all-near-places">
                    Explore All Destinations
                  </ANTD.Button>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search-apps">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <ANTD.Row>
                <ANTD.Col md={10} xs={24} className="apps-holidays">
                  <h4>Download the Apps</h4>
                  <p className="ios-andriod-app">
                    <img
                      src={
                        require("../../../assets/images/holidays/holidays-andriod.png")
                          .default
                      }
                      alt=""
                    />
                  </p>

                  <p className="ios-andriod-app">
                    <img
                      src={
                        require("../../../assets/images/holidays/holidays-ios.png")
                          .default
                      }
                      alt=""
                    />
                  </p>
                </ANTD.Col>

                <ANTD.Col md={14} xs={24}>
                  <img
                    className="img-travels-1"
                    src={
                      require("../../../assets/images/holidays/hyd-travel.jpg")
                        .default
                    }
                    alt=""
                  />
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>

      <div className="promo providerapi">
        <ANTD.Layout className="holidays-search">
          <div className="">
            <Content className="admin-container cms-pages-width">
              <div className="">
                <h4 className="">Top Cities to Visit</h4>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Things To Do in Goa</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Mumbai</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Bangalore</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Delhi</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Singapore</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Jaipur</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Phuket</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Bangkok</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Udaipur</span>
                    </a>
                    <a href="#">
                      <span>Things To Do in Hyderabad</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
              <div className="">
                <h4 className="">Top Things to Do</h4>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Places To Visit in Bangalore</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Delhi</span>
                    </a>
                    <a href="#">
                      <span>laces To Visit in Mumbai</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Goa</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Hyderabad</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Pune</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Chennai</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Udaipur</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Kolkata</span>
                    </a>
                    <a href="#">
                      <span>Places To Visit in Amritsar</span>
                    </a>
                    <a href="#">
                      <span>Scuba Diving in Goa</span>
                    </a>
                    <a href="#">
                      <span>Museums in Delhi</span>
                    </a>
                    <a href="#">
                      <span>Delhi Transport</span>
                    </a>
                    <a href="#">
                      <span>Amusement Park in Delhi</span>
                    </a>
                    <a href="#">
                      <span>Massage in Bangalore</span>
                    </a>
                    <a href="#">
                      <span>Kolkata Museum</span>
                    </a>
                    <a href="#">
                      <span>Museum in Mumba</span>
                    </a>
                    <a href="#">
                      <span>Theme Park in Chennai</span>
                    </a>
                    <a href="#">
                      <span>Bangalore Museum</span>
                    </a>
                    <a href="#">
                      <span>Aquarium in Mumbai</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
              <div className="">
                <h4 className="">Top Activities</h4>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Universal Studios Singapore</span>
                    </a>
                    <a href="#">
                      <span>Scuba Diving In Goa</span>
                    </a>
                    <a href="#">
                      <span>Disneyland Paris</span>
                    </a>
                    <a href="#">
                      <span>Madame Tussauds Delhi</span>
                    </a>
                    <a href="#">
                      <span>Ferrari World</span>
                    </a>
                    <a href="#">
                      <span>Gardens By The Bay Tickets</span>
                    </a>
                    <a href="#">
                      <span>Night Safari Singapore</span>
                    </a>
                    <a href="#">
                      <span>Bungee Jumping Rishikesh</span>
                    </a>
                    <a href="#">
                      <span>Batu Caves</span>
                    </a>
                    <a href="#">
                      <span>Safari World Bangkok</span>
                    </a>
                    <a href="#">
                      <span>Singapore Flyer</span>
                    </a>
                    <a href="#">
                      <span>James Bond Island</span>
                    </a>
                    <a href="#">
                      <span>Sriracha Tiger Zoo</span>
                    </a>
                    <a href="#">
                      <span>Sunway Lagoon</span>
                    </a>
                    <a href="#">
                      <span>Jurong Bird Park</span>
                    </a>
                    <a href="#">
                      <span>Burj Khalifa Tickets</span>
                    </a>
                    <a href="#">
                      <span>Dubai Tour</span>
                    </a>
                    <a href="#">
                      <span>Uluwatu Temple</span>
                    </a>
                    <a href="#">
                      <span>Water Kingdom Mumbai</span>
                    </a>
                    <a href="#">
                      <span>Ocean Park Hong Kong</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>

              <div className="">
                <h4 className="">Travel Categories</h4>
              </div>
              <ANTD.Row>
                <ANTD.Col span={24}>
                  <div className="top-cities">
                    <a href="#">
                      <span>Europe Train Tickets</span>
                    </a>
                    <a href="#">
                      <span>China Train Ticket</span>
                    </a>
                    <a href="#">
                      <span>Airport Transfer</span>
                    </a>
                    <a href="#">
                      <span>Japan Rail Pass</span>
                    </a>
                    <a href="#">
                      <span>Taiwan Rail</span>
                    </a>
                    <a href="#">
                      <span>Vietnam Rail</span>
                    </a>
                    <a href="#">
                      <span>Hotel</span>
                    </a>
                    <a href="#">
                      <span>WiFi and SIM Card</span>
                    </a>
                  </div>
                </ANTD.Col>
              </ANTD.Row>
            </Content>
          </div>
        </ANTD.Layout>
      </div>
    </div>
  );
};

export default HolidaysSearch;
