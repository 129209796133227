export const getPromoDiscount = (promoData, total) => {
  let promoAmount = 0;
  if (promoData && promoData?.Discount && promoData?.status) {
    if (promoData.DiscountType == 1) {
      promoAmount = Number((total / 100) * promoData.Discount);
    } else {
      promoAmount = Number(promoData.Discount);
    }
  }

  return Number(promoAmount);
};

export const getConvFee = (ConvFee, total , totalpaxcount) => {
  let convamount = 0;
  if (ConvFee && ConvFee?.amount && ConvFee?.status) {
    if (ConvFee.type == 1) {
      convamount = Number(ConvFee.amount)  * totalpaxcount;
    } else {
      convamount = Number(
        (total / 100) * Number(ConvFee.amount ? ConvFee.amount : 0)
      );
    }
  }

  return Number(convamount);
};
