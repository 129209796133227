import React, { useState } from "react";
import * as ANTD from "antd";
import {
  PlusOutlined,
  EditOutlined,
  IdcardOutlined,
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "../Holidaysshoppingcart/Holidaysshoppingcart.scss";
import { Link } from "react-router-dom";

const Holidaysshoppingcart = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  return (
    <div>
      <div className="promo providerapi">
        <div className="manage-markup-section">
          <Content className="admin-container">
            <ANTD.Row>
              <ANTD.Col span={24}>
                <div className="card-bt-gap">
                  <div className="card-add-heading">
                    <h4 className="shopping-heading">Shopping cart</h4>
                  </div>
                </div>
              </ANTD.Col>
            </ANTD.Row>

            <ANTD.Form>
              {/*--row----open--*/}

              <ANTD.Row>
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <ANTD.Form.Item>
                            <ANTD.Checkbox>All</ANTD.Checkbox>
                          </ANTD.Form.Item>
                        </li>

                        <li>
                          <p className="activity-p">Activity Description</p>
                        </li>

                        <li>
                          <p className="activity-p1">Date</p>
                        </li>

                        <li>
                          <p className="activity-p2">Quantity</p>
                        </li>

                        <li>
                          <p className="activity-p3">Sub Total</p>
                        </li>
                      </ul>
                    </div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--row----close--*/}

              {/*--second----open--*/}

              <ANTD.Row className="card-value-img">
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <ANTD.Form.Item>
                            <ANTD.Checkbox></ANTD.Checkbox>
                          </ANTD.Form.Item>
                        </li>

                        <li>
                          <img
                            className="img-cart"
                            src={
                              require("../../../assets/images/holidays/shopping-d-4.png")
                                .default
                            }
                            alt=""
                          />
                        </li>

                        <li>
                          <p className="activity-pimg">Party in Hyderabad</p>
                          <span className="activity-pimg1">
                            Package Type:Party and Adventure Package
                          </span>
                        </li>

                        <li>
                          <p className="activity-pimg2">22 Jan 2020</p>
                          <span className="activity-pimg2">Local Time</span>
                        </li>

                        <li>
                          <p className="activity-pimg3">Peson</p>
                        </li>

                        <li>
                          <p className="activity-pimg4">
                            <ANTD.Form.Item style={{ marginBottom: "4px" }}>
                              <div className="site-input-number-wrapper">
                                <ANTD.InputNumber
                                  size="large"
                                  min={1}
                                  max={100}
                                  defaultValue={1}
                                />
                              </div>
                            </ANTD.Form.Item>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="border-cart"></div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--second----close--*/}

              {/*--third----open--*/}

              <ANTD.Row>
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <p className="delete-cart">
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            &nbsp;Delet
                          </p>
                        </li>

                        <li>
                          <p className="edit-cart">
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Edit
                          </p>
                        </li>

                        <li>
                          <p className="sub-total">
                            <i className="fa fa-inr" aria-hidden="true"></i>
                            &nbsp;
                            <b>6000</b>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--third----close--*/}

              {/*--fourth----open--*/}

              <ANTD.Row className="card-value-img">
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <ANTD.Form.Item>
                            <ANTD.Checkbox></ANTD.Checkbox>
                          </ANTD.Form.Item>
                        </li>

                        <li>
                          <img
                            className="img-cart"
                            src={
                              require("../../../assets/images/holidays/shopping-d-4.png")
                                .default
                            }
                            alt=""
                          />
                        </li>

                        <li>
                          <p className="activity-pimg">Party in Hyderabad</p>
                          <span className="activity-pimg1">
                            Package Type:Party and Adventure Package
                          </span>
                        </li>

                        <li>
                          <p className="activity-pimg2">22 Jan 2020</p>
                          <span className="activity-pimg2">Local Time</span>
                        </li>

                        <li>
                          <p className="activity-pimg3">Peson</p>
                        </li>

                        <li>
                          <p className="activity-pimg4">
                            <ANTD.Form.Item style={{ marginBottom: "4px" }}>
                              <div className="site-input-number-wrapper">
                                <ANTD.InputNumber
                                  size="large"
                                  min={1}
                                  max={100}
                                  defaultValue={1}
                                />
                              </div>
                            </ANTD.Form.Item>
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="border-cart"></div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--fourth----close--*/}

              {/*--fifth----open--*/}

              <ANTD.Row>
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <p className="delete-cart">
                            <i className="fa fa-trash" aria-hidden="true"></i>
                            &nbsp;Delet
                          </p>
                        </li>

                        <li>
                          <p className="edit-cart">
                            <i
                              className="fa fa-pencil-square-o"
                              aria-hidden="true"
                            ></i>
                            &nbsp;Edit
                          </p>
                        </li>

                        <li>
                          <p className="sub-total">
                            <i className="fa fa-inr" aria-hidden="true"></i>
                            &nbsp;
                            <b>6000</b>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--fifth----close--*/}

              {/*--sixth----open--*/}

              <ANTD.Row className="all-total-cost">
                <ANTD.Col md={24} xs={24} className="cart-body">
                  <ANTD.Card bordered={false}>
                    <div className="dev-cart">
                      <ul className="cart-des">
                        <li>
                          <ANTD.Form.Item>
                            <ANTD.Checkbox>All</ANTD.Checkbox>
                          </ANTD.Form.Item>
                        </li>

                        <li>
                          <p className="activity-p">Delete Activity</p>
                        </li>

                        <li>
                          <p className="activity-p2"></p>
                        </li>

                        <li>
                          <p className="activity-ptotal">
                            2 Activity Total:
                            <i className="fa fa-inr" aria-hidden="true"></i>
                            &nbsp;12000
                          </p>
                          <span className="activity-ptotal">
                            Get 96 credits for this booking
                          </span>
                        </li>

                        <li>
                          <p className="activity-paynow">
                            <ANTD.Button className="all-near-places">
                              Pay Now
                            </ANTD.Button>
                          </p>
                        </li>
                      </ul>
                    </div>
                  </ANTD.Card>
                </ANTD.Col>
              </ANTD.Row>

              {/*--sixth----close--*/}
            </ANTD.Form>
          </Content>
        </div>
      </div>
    </div>
  );
};
export default Holidaysshoppingcart;
