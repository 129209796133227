import React, { useState, useEffect } from "react";
//import * as ReactBoostrap from "react-bootstrap";
import {
  Button,
  Card,
  Col,
  Collapse,
  Form,
  Upload,
  message,
  Modal,
  Layout,
  Row,
  Popover,
} from "antd";
import * as ANTD from "antd";
import {
  PlusCircleFilled,
  UploadOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
//import "./Cartypes.scss";
import excel from "../assets/vector-icons/excel.png";
import pdf from "../assets/vector-icons/pdf.png";
// import word from "../assets/vector-icons/word.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
//import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";
//import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
//import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { DatePickerInput } from "rc-datepicker";
import "rc-datepicker/lib/style.css";
import ApiClient from "../../helpers/ApiClient";

const Logos = ({
  category,
  fileList,
  setFileList,
  formName,
  limit,
  limitSize,
  deleteBanner,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const uploadActionUrl =
    process.env.REACT_APP_BASE_URL + "media/upload/multipleImages";
  const uploadLogoButton = !isUploading ? (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload </div>
    </div>
  ) : (
    <div className="ant-upload-text">Loading... </div>
  );

  function beforeUpload(file) {
    const checkJpgOrPng =
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg";
    if (!checkJpgOrPng) {
      message.error("You can only upload jpg, jpeg and png file!");
    }

    // const checkFileSize = file.size < 256000;
    // if (!checkFileSize) {
    //   message.error(" Image must be smaller than 250kb!");
    // }

    //return checkJpgOrPng && checkFileSize;
    return checkJpgOrPng;
  }

  function getImages(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  function handleChange(info) {
    setFileList(info.fileList);
    if (info.file.status === "uploading") {
      setIsUploading(false);
    }
    if (info.file.status === "removed") {
      setIsUploading(false);
    }
    if (info.file.status === "error") {
      setIsUploading(false);
      return;
    }
    if (info.file.status === "done") {
      setIsUploading(false);
    }
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getImages(file.originFileObj);
    }
    setPreviewVisible(true);
    setPreviewImage(file.url || file.preview);

    setPreviewTitle(file.name);
  };

  const handleCancel = () => {
    setPreviewVisible(false);
  };

  return (
    <>
      <Form.Item name={formName}>
        <Upload
          name="image"
          listType="picture-card"
          className="avatar-uploader"
          fileList={fileList}
          action={uploadActionUrl}
          onChange={handleChange}
          onPreview={handlePreview}
          data={{ category: category }}
          onRemove={(val) => deleteBanner(val.uid)}
          beforeUpload={beforeUpload}
        >
          {limit
            ? fileList.length >= limitSize
              ? null
              : uploadLogoButton
            : uploadLogoButton}
        </Upload>
      </Form.Item>
      <Modal
        visible={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img src={previewImage} alt="imagePreview" style={{ width: "100%" }} />
      </Modal>
    </>
  );
};

const Driverinfo = () => {
  const BASE = process.env.REACT_APP_BASE_URL;
  // const uploadActionUrl = BASE + "media/upload/singleImage";
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [form] = ANTD.Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const date = new Date();
  const [updateModal, setUpdateModal] = React.useState(false);
  const [deleteModal, setdeleteModal] = React.useState(false);
  const [moreInfoModal, setInfoModal] = React.useState(false);
  const [formLayout, setFormLayout] = useState("vertical");
  const { TextArea } = ANTD.Input;
  const [imgUrl, setImgUrl] = useState();
  const [thumbnailImage, setThumbnailImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [busSearchBanners, setBusSearchBanners] = useState([]);
  const handleClose = () => setUpdateModal(false);
  const handleShow = () => setUpdateModal(true);
  const handleInfoShow = () => setInfoModal(true);
  const handleInfoClose = () => setInfoModal(false);
  // const imageUrl = imgUrl;
  const [isEditMode, setIsEditMode] = useState(true);
  const [formData, setFormData] = useState({});
  const initialFormData = {};
  // const toggle = () => {
  //     setUpdateModal(!updateModal)
  // }
  const [dataSource, setDataSource] = useState([]);
  const getallcardriverinfo = () => {
    ApiClient.get("carextranet/getallcardriverinfo").then((res) => {
      if (res.status == 200) {
        setDataSource(res.data);
      }
    });
  };
  const DeleteThumbnailImage = (id) => {
    console.log(id);
    const filteredThumbnail = thumbnailImage.filter((item) => item.uid != id);
    setThumbnailImage(filteredThumbnail);
  };

  const DeleteBannerImage = (id) => {
    ApiClient.delete("admin/banners/" + id)
      .then((response) => {
        if (response.status == 200) {
          // getBannersList();
          message.success("Deleted Successsfully");
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleAddClick = () => {
    showModal();
    setIsEditMode(false);
  };

  useEffect(() => {
    getallcardriverinfo();
  }, []);

  const handleSubmit = (formvalues) => {
    if (isEditMode) {
      // submitNewDriverInfo(formvalues);
      submitUpdateDriverInfo(formvalues);
    } else {
      submitNewDriverInfo(formvalues);
    }
  };

  const deleteToggle = () => {
    setdeleteModal(!deleteModal);
  };
  const layout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 },
  };
  const showModal = () => {
    form.resetFields();
    setModalVisible({
      visible: true,
    });
  };
  const submitNewDriverInfo = (formvalues) => {
    setFormData(formvalues);
    console.log("formvalues", formvalues);
    ApiClient.post("carextranet/createcardriverinfo", formvalues).then(
      (response) => {
        if (response.status === 200) {
          setModalVisible(false);
          ANTD.message.success("CarDriverInfo created successfully");
          setFormData({ initialFormData });
          getallcardriverinfo();
        } else {
          ANTD.message.error(" Error Creating CardriverInfo ");
        }
      }
    );
  };

  const submitUpdateDriverInfo = (formvalues) => {
    const updatedFormData = {
      ...formData,
      ...formvalues,
    };
    console.log("formData", formData.DriverID);

    ApiClient.put(
      "carextranet/updatecardriverinfo/" + formData.DriverID,
      {},
      updatedFormData
    ).then((response) => {
      console.log(response);
      if (response.status === 200) {
        setModalVisible(false);
        ANTD.message.success("CarDriverInfo updated successfully");
        setFormData({ initialFormData });
        getallcardriverinfo();
      } else {
        ANTD.message.error(" Error Updating CarDriverInfo ");
      }
    });
  };

  const deleteDriverInfo = (id) => {
    ApiClient.delete("carextranet/deletecardriverinfo/" + id).then((res) => {
      if (res.status == 200) {
        ANTD.message.success("CarType Deleted  successfully", 3);
        getallcardriverinfo();
      }
    });
  };

  const handleEditClick = (rec) => {
    console.log(rec);
    form.setFieldsValue({
      ...rec,
      // CarType: getCarTypeName(rec.CarType),
      CarTypeID: rec.CarTypeID,
      PastExp_CarTypes: rec.PastExp_CarTypes?.CarTypeID,
      PastExp1_CarTypes: rec.PastExp1_CarTypes?.CarTypeID,
    });
    setFormData({ ...rec, DriverID: rec.DriverID });

    setIsEditMode(true);

    setModalVisible({
      visible: true,
    });
  };

  /********** Uploading Image **********/

  const [upLoadImage, setUpLoadImage] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  function handleChange(info) {
    if (info.file.status === "error") {
      ANTD.message.error(" Error Uploading Image", 3);
      setUpLoadImage(false);
      console.log("setUpLoadImage");
      return;
    }
    if (info.file.status === "uploading") {
      setUpLoadImage(true);
      return;
    }
    if (info.file.status === "done") {
      console.log("done");
      if (info.file.response.status == 200) {
        setFormData({
          ...formData,
          Image: info.file.response.data.filepath,
        });
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          setImageUrl(imageUrl);
          setUpLoadImage(false);
        });
      }
      setUpLoadImage(false);
    }
  }
  /********** Uploading Image **********/

  // const onFinishFailed = (errorInfo) => {
  //   console.log("Failed:", errorInfo);
  // };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  /***** Radio-btn*****/
  const [discountBtn, setDiscountBtn] = useState(1);
  // onChange = e => {
  //     console.log('radio checked', e.target.value);
  //     setDiscountBtn(e.target.value);
  // };
  /****** User Info ******/
  const [userInfo, setUserInfo] = useState(false);

  const openUserInfo = () => {
    setUserInfo(true);
  };

  const handleOk = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  const handleCancel = (e) => {
    console.log(e);
    setUserInfo(false);
  };

  let onChange = (jsDate, dateString) => {
    console.log(jsDate);
    console.log(dateString);
  };
  const tableActions = (rec) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <EditOutlined
            onClick={() => handleEditClick(rec)}
            style={{ color: "#1890ff", fontSize: "20px", textAlign: "center" }}
          />
        </div>
        <div className="edit-icon" style={{ textAlign: "center" }}>
          <ANTD.Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => deleteDriverInfo(rec.DriverID)}
          >
            <DeleteOutlined
              style={{ color: "red", fontSize: "20px", textAlign: "center" }}
            />
          </ANTD.Popconfirm>
        </div>
      </div>
    );
  };
  /* const tableDelete = () => {
        return (
            <div className="edit-icon" style={{ textAlign: 'center' }}>
                <ANTD.Popconfirm title="Are you sure to delete?" >
                    <DeleteOutlined style={{ color: 'red', fontSize: '20px', textAlign: 'center' }} />
                </ANTD.Popconfirm>
            </div>
        )
    } */

  // const rowClasses = (row, rowIndex) => {
  //     // const style = { };

  //     // if( rowIndex == 1 ) {
  //     //     style.backgroundColor = '#ccc';
  //     // }
  //     return 'custom-row-class';
  // }
  const { Option } = ANTD.Select;
  const prefixSelector = (
    <ANTD.Form.Item name="prefix" noStyle>
      <ANTD.Select style={{ width: 80 }} placeholder="+91">
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </ANTD.Select>
    </ANTD.Form.Item>
  );
  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const uploadButton = (
    <div>
      {upLoadImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      ANTD.message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      ANTD.message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  }

  // const products = [
  //     {
  //         Name: 'A',
  //         Email: 'A@gmail.com',
  //         Mobile: '9876543210',
  //         Role: 'Accounts',
  //         Status: 'Checkbox'
  //     },
  //     {
  //         Name: 'B',
  //         Email: 'B@gmail.com',
  //         Mobile: '9876543210',
  //         Role: 'Business',
  //         Status: 'Checkbox'
  //     },
  //     {
  //         Name: 'C',
  //         Email: 'C@gmail.com',
  //         Mobile: '9876543210',
  //         Role: 'GST',
  //         Status: 'Checkbox'
  //     }
  // ]
  // const columns = [
  //     {
  //         dataField: 'Name',
  //         filter: textFilter(),
  //         text: 'Name',
  //         sort: true,

  //     },
  //     {
  //         dataField: 'Email',
  //         text: 'Email',
  //         sort: true
  //     },
  //     {
  //         dataField: 'Mobile',
  //         text: 'Mobile',
  //         sort: true
  //     },
  //     {
  //         dataField: 'Role',
  //         text: 'Role',
  //         sort: true
  //     },
  //     {
  //         dataField: 'Status',
  //         text: 'Status',
  //         formatter: statusCheckbox,
  //     },
  //     {
  //         dataField: 'Actions',
  //         text: 'Actions',
  //         formatter: updateformatWithIcon,
  //         // events: {
  //         //     onClick: (e, column, columnIndex, row, rowIndex) => {
  //         //         handleShow();
  //         //     }
  //         // }
  //     },
  //     {
  //         dataField: 'Delete',
  //         text: 'Delete',
  //         formatter: deleteformatWithIcon,
  //         events: {
  //             onClick: (e, column, columnIndex, row, rowIndex) => {
  //                 deleteToggle();
  //             }
  //         }
  //     }
  // ]

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },

    {
      title: "Age",
      dataIndex: "Age",
    },

    {
      title: "Address",
      dataIndex: "Address",
    },

    {
      title: "City",
      dataIndex: "City",
    },

    {
      title: "State",
      dataIndex: "State",
    },

    {
      title: "Country",
      dataIndex: "Country",
    },

    {
      title: "Mobile Number",
      dataIndex: "MobileNo",
    },

    // {
    //   title: "Created Date",
    //   dataIndex: "Credate",
    // },

    {
      title: "Pincode",
      dataIndex: "PinCode",
    },

    // {
    //   title: "LastModified Date",
    //   dataIndex: "Lmdate",
    // },

    // {
    //   title: "Status",
    //   dataIndex: "No",
    //   key: "airline",
    //   render: (text, order) => (
    //     <ANTD.Switch
    //       checkedChildren="Active"
    //       unCheckedChildren="Inactive"
    //       defaultChecked
    //     />
    //   ),
    // },
    {
      title: "Actions",
      render: (rec) => {
        console.log(rec);
        return tableActions(rec);
      },
    },
    /*  {
            title: 'Delete',
            dataIndex: 'delete'
         
        } */
  ];

  /***** Radio-btn*****/
  const [statusBtn, setStatusBtn] = useState(1);
  onChange = (e) => {
    console.log("radio checked", e.target.value);
    setStatusBtn(e.target.value);
  };

  // const paginationOptions = {
  //     sizePerPageList: [
  //         {
  //             text: '5', value: 5
  //         },
  //         {
  //             text: '10', value: 10
  //         },
  //         {
  //             text: '15', value: 15
  //         },
  //         {
  //             text: 'All', value: products.length
  //         }
  //     ]
  // }

  return (
    // <div>
    //     <div className="manage-markup-section">
    //         <div className="container-fluid">
    //             <div className="row">
    //                 <div className="col-md-12">
    //                     <div className="heading">
    //                         <h5>Add Users</h5>
    //                         <div className="add-icon" onClick={handleShow}>
    //                             <i className="fa fa-plus"></i>
    //                         </div>
    //                         {/* <p>User..............</p> */}
    //                     </div>
    //                 </div>
    //                 <div className="col-md-12">

    //                     <div className="role-table">
    //                         {/* <div className="col-sm-12"> */}
    //                         <div className="card table-card">
    //                             <div className="card-block row">
    //                                 {/* <div className="col-sm-12 col-lg-12 col-xl-12"> */}
    //                                 <div className="table-responsive table-padd">
    //                                     <div className="table-heading-count">
    //                                         <div className="rows-count">
    //                                             <div>
    //                                                 <h5>View Users</h5>
    //                                                 <p>5 rows found !</p>
    //                                             </div>
    //                                         </div>
    //                                         <div className="action-images">
    //                                             <img src={excel} alt="excel" />
    //                                             <img src={pdf} alt="pdf" />
    //                                             {/* <img src={word} alt="word" /> */}
    //                                             <img src={search} alt="search" />
    //                                             <img src={exchange} alt="exchange" />
    //                                         </div>
    //                                     </div>
    //                                     {/* sortIcon={sortIcon} rowStyle={ rowCustomStyles } rowClasses='custom-row-class'*/}
    //                                     {/* selectRow={selectRow} */}
    //                                     <BootstrapTable id="custom-react-table" keyField="id" data={products} columns={columns}
    //                                         pagination={paginationFactory(paginationOptions)} bootstrap4 hover
    //                                         headerClasses="header-class" filter={filterFactory()} />
    //                                     <ReactBoostrap.Modal size="lg" show={updateModal} onHide={handleClose}
    //                                         className="modal-body" centered={true}>
    //                                         {/* toggle={toggle} */}
    //                                         <ReactBoostrap.Modal.Header closeButton>
    //                                             <h5>Add User</h5>
    //                                         </ReactBoostrap.Modal.Header>
    //                                         <ReactBoostrap.Modal.Body style={{ 'max-height': 'calc(100vh - 210px)', 'overflow-y': 'auto' }}>
    //                                             <form className="theme-form">
    //                                                 <div className="row">
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">
    //                                                                 Role <span className="mandatory-star">*</span>
    //                                                             </label>
    //                                                             <select className="form-control" name="" id="">
    //                                                                 <option value="">Please Select</option>
    //                                                                 <option value="">Business</option>
    //                                                                 <option value="">Accounts</option>
    //                                                                 <option value="">GST</option>
    //                                                             </select>
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">
    //                                                                 First Name <span className="mandatory-star">*</span>
    //                                                             </label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp"
    //                                                                 placeholder="Enter First Name" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Last Name <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp"
    //                                                                 placeholder="Enter Last Name" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Email <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp"
    //                                                                 placeholder="Enter Email" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Mobile No <span className="mandatory-star">*</span></label>
    //                                                             <select className="form-control" name="" id="">
    //                                                                 <option value="">India (+91)</option>
    //                                                                 <option value="">Afganisthan (+93)</option>
    //                                                                 <option value="">Albania (+355)</option>
    //                                                                 <option value="">Algeria (+213)</option>
    //                                                             </select>
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Password <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Transaction Password <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" placeholder="Enter Role" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Gender <span className="mandatory-star">*</span></label>
    //                                                             <div className="form-gender-types">
    //                                                                 <ReactBoostrap.FormCheck name="radio1" type="radio" label="Male" />
    //                                                                 <ReactBoostrap.FormCheck name="radio1" className="female-type" type="radio" label="Female" />
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Date of borth <span className="mandatory-star">*</span></label>
    //                                                             <DatePickerInput
    //                                                                 displayFormat="DD/MM/YYYY"
    //                                                                 returnFormat="YYYY-MM-DD"
    //                                                                 className="my-react-component"
    //                                                                 onChange={onChange}
    //                                                                 defaultValue={date}
    //                                                                 showOnInputClick
    //                                                                 placeholder="placeholder"
    //                                                                 locale="de"
    //                                                                 iconClassName="fa fa-calendar" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Alternate Email <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" placeholder="Enter Role" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Transaction Password</label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" placeholder="Enter Role" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Alternate Mobile No</label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" placeholder="Enter Role" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">City <span className="mandatory-star">*</span></label>
    //                                                             <input className="form-control" id="exampleInputEmail1" type="email" aria-describedby="emailHelp" placeholder="Enter Role" />
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Currency <span className="mandatory-star">*</span></label>
    //                                                             <select className="form-control" name="" id="">
    //                                                                 <option value="">INR</option>
    //                                                             </select>
    //                                                         </div>
    //                                                     </div>
    //                                                     <div className="col-md-6">
    //                                                         <div className="form-group">
    //                                                             <label className="col-form-label pt-0" htmlFor="exampleInputEmail1">Status <span className="mandatory-star">*</span></label>
    //                                                             <div className="form-gender-types">
    //                                                                 <ReactBoostrap.FormCheck name="radio-st" type="radio" label="Active" />
    //                                                                 <ReactBoostrap.FormCheck name="radio-st" className="female-type" type="radio" label="Inactive" />
    //                                                             </div>
    //                                                         </div>
    //                                                     </div>
    //                                                 </div>
    //                                             </form>
    //                                         </ReactBoostrap.Modal.Body>
    //                                         <ReactBoostrap.Modal.Footer className="footer-buttons">
    //                                             <div>
    //                                                 <button className="btn btn-primary mr-1">Submit</button>
    //                                                 <button className="btn btn-danger">Clear</button>
    //                                             </div>
    //                                             {/* <ReactBoostrap.Button color="primary">Save Changes</ReactBoostrap.Button> */}
    //                                             {/* <ReactBoostrap.Button color="secondary" onClick={toggle}>Cancel</ReactBoostrap.Button> */}
    //                                         </ReactBoostrap.Modal.Footer>
    //                                     </ReactBoostrap.Modal>
    //                                     <ReactBoostrap.Modal show={deleteModal}
    //                                         className="modal-body" centered={true}>
    //                                         {/* <ReactBoostrap.Modal.Header toggle={deleteToggle}>Delete Modal title</ReactBoostrap.Modal.Header> */}
    //                                         <ReactBoostrap.Modal.Body>
    //                                             <div className="delete-modal-body">
    //                                                 <i className="fa fa-question"></i>
    //                                                 <h5>Are you sure to delete...</h5>
    //                                             </div>
    //                                             <div className="delete-modal-btns">
    //                                                 <ReactBoostrap.Button className="btn btn-md yes-btn">Yes</ReactBoostrap.Button>
    //                                                 <ReactBoostrap.Button onClick={deleteToggle} className="btn btn-md cancel-btn">Cancel</ReactBoostrap.Button>
    //                                             </div>
    //                                         </ReactBoostrap.Modal.Body>
    //                                         {/* <ReactBoostrap.Modal.Footer>

    //                                                 </ReactBoostrap.Modal.Footer> */}
    //                                     </ReactBoostrap.Modal>
    //                                     <ReactBoostrap.Modal show={moreInfoModal} onHide={handleInfoClose}>
    //                                         <ReactBoostrap.Modal.Header closeButton>
    //                                             <h5>User Info</h5>
    //                                         </ReactBoostrap.Modal.Header>
    //                                         <ReactBoostrap.Modal.Body>
    //                            Mandi                 {/* More Info Body........ */}
    //                                             <div>
    //                                                 <div>
    //                                                     <p><strong>Alternate Email :</strong> aa @gmail.com</p>
    //                                                     <p><strong>Alternate Mobile :</strong>8863932420</p>
    //                                                     <p><strong>Date of birth :</strong>01/01/2001</p>
    //                                                     <p><strong>City :</strong>Vizag</p>
    //                                                 </div>
    //                                             </div>
    //                                         </ReactBoostrap.Modal.Body>
    //                                     </ReactBoostrap.Modal>
    //                                 </div>
    //                                 {/* </div> */}
    //                            Mandi </div>
    //                         </div>
    //                         {/* </div> */}
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
    <div className="promo">
      <div className="manage-markup-section">
        <Content className="admin-container">
          <ANTD.Row>
            {/*  <ANTD.Col span={2Mandi4}>
                            <div className="card-bt-gap">
                                <ANTD.Card bordered={false}>
                                    <div className="card-add-heading">
                                        <h5>Car Types</h5>
                                    </div>

                               

                                </ANTD.Card>
                            </div>
                        </ANTD.Col> 
 */}

            <ANTD.Col span={24}>
              <div className="card-bt-gap">
                <ANTD.Card bordered={false}>
                  <div className="card-add-heading">
                    {/* <div className="table-heading-count"> */}
                    <div className="rows-count">
                      <div>
                        <h5>
                          Driverinfo{" "}
                          <HelpInfoHelper screenName={"/admin/driverinfo"} />
                        </h5>
                        {/*   <p>3 rows found !</p> */}
                      </div>
                    </div>
                    <div className="action-images">
                      <img src={search} alt="search" />
                      <img src={excel} alt="excel" />
                      <img src={pdf} alt="pdf" />
                      {/* <img src={word} alt="word" /> */}

                      {/*  <img srMandic={exchange} alt="exchange" /> */}
                      <p className="add-deposit-icon" onClick={handleAddClick}>
                        <PlusCircleFilled />
                      </p>
                    </div>
                    {/* </div> */}
                  </div>

                  <div>
                    {/* onChange={onChange} expandedRowRender= {rowExpand}*/}
                    <ANTD.Table
                      className="table-scroll-none"
                      bordered
                      dataSource={dataSource}
                      columns={columns}
                      pagination={{
                        defaultPagMandieSize: 25,
                        showSizeChanger: true,
                        pageSizeOptions: ["25", "50", "100", "125"],
                      }}
                    />

                    {/* <ANTD.Modal visible={this.state.visible} onCancel={closeModal}>
                                                <h6>This is the modal bodyyyyy</h6>
                                        </ANTD.Modal> */}
                  </div>
                </ANTD.Card>
              </div>
            </ANTD.Col>
          </ANTD.Row>
        </Content>
      </div>

      <ANTD.Modal
        title="Driver Information"
        className="promo-modal-header "
        visible={modalVisible}
        onOk={(e) => setModalVisible(false)}
        onCancel={(e) => setModalVisible(false)}
        footer={null}
        width={"1100px"}
      >
        <ANTD.Form
          form={form}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          // onFinishFailed={onFinishFailed}
        >
          <ANTD.Row gutter={16}>
            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item
                name="Name"
                label="Name"
                rules={[
                  { required: true, message: "Please input your firstname!" },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item
                name="Age"
                label="Age"
                rules={[{ required: true, message: "Please input your Age!" }]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item
                label="Driving Experience"
                rules={[{ required: true }]}
                name="DrivingExperience"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="1">1</ANTD.Select.Option>
                  <ANTD.Select.Option value="2">2</ANTD.Select.Option>
                  <ANTD.Select.Option value="3">3</ANTD.Select.Option>
                  <ANTD.Select.Option value="4">4</ANTD.Select.Option>
                  <ANTD.Select.Option value="5">5</ANTD.Select.Option>
                  <ANTD.Select.Option value="6">6</ANTD.Select.Option>
                  <ANTD.Select.Option value="7">7</ANTD.Select.Option>
                  <ANTD.Select.Option value="8">8</ANTD.Select.Option>
                  <ANTD.Select.Option value="9">9</ANTD.Select.Option>
                  <ANTD.Select.Option value="10">10</ANTD.Select.Option>
                  <ANTD.Select.Option value="11">11</ANTD.Select.Option>
                  <ANTD.Select.Option value="12">12</ANTD.Select.Option>
                  <ANTD.Select.Option value="13">13</ANTD.Select.Option>
                  <ANTD.Select.Option value="14">14</ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item
                label="Driver Qualitficaiton"
                rules={[{ required: true }]}
                name="DriverQualitficaiton"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="Inter">Inter</ANTD.Select.Option>
                  <ANTD.Select.Option value="Degree">Degree</ANTD.Select.Option>
                  <ANTD.Select.Option
                    value="PostGraudation
"
                  >
                    Post Graudation
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item label="Gender" name="Gender">
                <ANTD.Radio.Group onChange={onChange} value={statusBtn}>
                  <ANTD.Radio value="Male">Male</ANTD.Radio>
                  <ANTD.Radio value="Female">Female</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24}>
              <ANTD.Form.Item label="TravelType" name="TravelType">
                <ANTD.Radio.Group onChange={onChange} value={statusBtn}>
                  <ANTD.Radio value={0}>Local</ANTD.Radio>
                  <ANTD.Radio value={1}>Outstation</ANTD.Radio>
                  <ANTD.Radio value={2}>Airport Transfers</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          {/* <ANTD.Row gutter={16}></ANTD.Row> */}

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="Address1"
                label="Address1"
                rules={[
                  { required: true, message: "Please input your Address1!" },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="Address2"
                label="Address2"
                rules={[
                  { required: true, message: "Please input your Address2!" },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="LandMark"
                label="LandMark"
                rules={[
                  { required: true, message: "Please input your LandMark!" },
                ]}
                submitUpdateCarType
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="City"
                rules={[{ required: true }]}
                label="City"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="Hyderabad">
                    Hyderabad
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Bangalore">
                    Bangalore
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Delhi">Delhi</ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="State"
                rules={[{ required: true }]}
                name="State"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="AndhraPredesh">
                    Andhra Predesh
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Telangana">
                    Telangana
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Telangana">
                    Telangana
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="Country"
                rules={[{ required: true }]}
                name="Country"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="India">India</ANTD.Select.Option>
                  <ANTD.Select.Option value="Italy">Italy</ANTD.Select.Option>
                  <ANTD.Select.Option value="Jamaica">
                    Jamaica
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Kenya">Kenya</ANTD.Select.Option>
                  <ANTD.Select.Option value="Malaysia">
                    Malaysia
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PinCode"
                label="Pincode"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item name="MobileNo" label="Mobile Number">
                <ANTD.Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="AlternateMobileNo"
                label="Alternate Mobile Number"
              >
                <ANTD.Input
                  addonBefore={prefixSelector}
                  style={{ width: "100%" }}
                />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="Govt IDentification Type"
                rules={[{ required: true }]}
                name="GovtIDentificationType"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value="AdharCard">
                    Aadhaar Card
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="VoterId">
                    Voter ID
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Pan">Pan Card</ANTD.Select.Option>
                  <ANTD.Select.Option value="Driving">
                    Driving Licence
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="Passport">
                    Passport
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="GovtNumber"
                label="GovtNumber"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    required: true,
                    message: "Please input your Govt Number",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp_CompanyName"
                label="Past Exp-CompanyName"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp_CompanyAddress"
                label="Past Exp-CompanyAddress"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your CompanyAddress",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp_CompanyPointofContact"
                label="Past Exp-Company Point of Contact"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Exp-Company Point of Contact",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp_CompanyMobileNo"
                label="Past Exp-Company MobileNo"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp_CompanyEmailAddress"
                label="PastExp-Company Email Address"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your PastExp-Company Email Address",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="PastExp-CarTypes"
                rules={[{ required: true }]}
                name="PastExp_CarTypes"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value={1}>1</ANTD.Select.Option>
                  <ANTD.Select.Option value={2}>Convertible</ANTD.Select.Option>
                  <ANTD.Select.Option value={3}>
                    Coupe 4-Door
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp1_CompanyName"
                label="Past Exp1-CompanyName"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp1_CompanyAddress"
                label="PastExp1-Company Address"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp1_CompanyPointofContact"
                label="PastExp1-Company Pointof Contact"
                rules={[
                  {
                    pattern: new RegExp(/^[0-9]*$/),
                    required: true,
                    message: "Please input your CompanyPointofContact",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp1_CompanyMobileNo"
                label="PastExp1-Company MobileNo"
                rules={[
                  {
                    required: true,
                    message: "Please input your CompanyMobileNo",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row gutter={16}>
            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="PastExp1_CompanyEmailAddress"
                label="PastExp1-Company Email Address"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                label="PastExp1-CarTypes"
                rules={[{ required: true }]}
                name="PastExp1_CarTypes"
              >
                <ANTD.Select>
                  <ANTD.Select.Option value={1}>Hatchback</ANTD.Select.Option>
                  <ANTD.Select.Option value={2}>Convertible</ANTD.Select.Option>
                  <ANTD.Select.Option value={3}>
                    Coupe 4-Door
                  </ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item
                name="OtherInfo"
                label="Other Info"
                rules={[
                  {
                    type: "string",
                    required: true,
                    message: "Please input your Pincode",
                  },
                ]}
              >
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24}>
              <ANTD.Form.Item label="Status" name="Status">
                <ANTD.Radio.Group onChange={onChange} value={statusBtn}>
                  <ANTD.Radio value={0}>True</ANTD.Radio>
                  <ANTD.Radio value={1}>False</ANTD.Radio>
                </ANTD.Radio.Group>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col xs={24} md={8}>
              <ANTD.Form.Item label="ProfileImage">
                {/* <ANTD.Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={uploadActionUrl}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    uploadButton
                  )}
                </ANTD.Upload> */}
                <Logos
                  category="bussearchbanners"
                  fileList={busSearchBanners}
                  setFileList={setBusSearchBanners}
                  formName="busSearchBanners"
                  limit={true}
                  limitSize={2}
                  deleteBanner={DeleteBannerImage}
                />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
            <ANTD.Button type="primary" htmlType="submit">
              {isEditMode ? "Update" : "Add"}
            </ANTD.Button>
            <ANTD.Button type="primary" danger className="cancel-btn">
              Clear
            </ANTD.Button>
          </ANTD.Form.Item>
        </ANTD.Form>
      </ANTD.Modal>
    </div>
  );
};

export default Driverinfo;
