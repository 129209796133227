import React, { useState, useEffect } from "react";
import "../TransportResults/TransportResults.scss";
import * as ReactBootstrap from "react-bootstrap";
import * as ANTD from "antd";
// import queryString from "query-string";
// import Buses from "../../../components/buses-search/Buses";
// import Filter from "../../../common/Filters/Filter";
// import BusResultCards from "./BusResultCards/BusResultCards";
// import busResultObject from "./BusAvailability.json";
// import ApiClient from "./../../../helpers/ApiClient";
// import BusFilter from "./BusFilter/BusFilter";
import FilterSkeletonLayout from "../../../components/FilterSkeleton/FilterSkeleton";
import TransportSearch from "../TransportSearch/TransportSearch";
import Transportation from "../../../components/Transportation/Transportation";
import TransportCard from "./TransportCard/TransportCard";
import TransportFilter from "./TransportFilter/TransportFilter";

const TransportResults = () => {
  // console.log("bus results props", props);
  const [isLoading, setisLoading] = useState(false);
  const [trips, setTrips] = useState([]);
  const [twowayTrips, setTwowayTrips] = useState([]);
  const [radioBtn, setRadioBtn] = useState("oneWay");
  const { Option } = ANTD.Select;
  // const globalBusData = props.gscontext.state.busData;
  // const { busSearchResultObj } = props.gscontext.state;
  //   const {
  //     updateBusField,
  //     updateBusSearchResultObj,
  //     resetBusSearchObj,
  //     updateEmptySelectedBusData,
  //   } = props.gscontext;
  //   const [open, setOpen] = useState(false);
  //   const [busSelectedFilter, setBusSelectedFilter] = useState({
  //     busOperators: [],
  //     boardingPoints: [],
  //     droppingPoints: [],
  //   });
  function handleChange(value) {
    console.log(`selected ${value}`);
  }

  return (
    <div>
      <div>
        <div className="bus-results-container">
          <div className="results-header-block d-none d-md-block">
            <div className="container-block">
              <div className="modify-class-type-selector d-none">
                <div>
                  <ANTD.Select
                    defaultValue="One way"
                    style={{ width: 120 }}
                    onChange={handleChange}
                    className="modify-selectors"
                  >
                    <Option value="One way">One way</Option>
                    <Option value="Round trip">RoundTrip</Option>
                  </ANTD.Select>
                </div>
                <div>
                  <span className="class-type">Class :</span>
                  <ANTD.Select
                    defaultValue="Economy"
                    style={{ width: 160 }}
                    onChange={handleChange}
                    className="modify-selectors"
                  >
                    <Option value="EconomyPremium">Economy Premium</Option>
                    <Option value="Business">Business</Option>
                    <Option value="FirstClass">First Class</Option>
                    <Option value="Economy">Economy</Option>
                  </ANTD.Select>
                </div>
              </div>
              <div className="search-modify-block">
                <Transportation />
              </div>
            </div>
          </div>

          {/* Responsive bottom filter block */}
          <div className="sticky-filter d-none">
            <div className="sticky-filter-container">
              <div className="sticky-filter-row">
                <div className="ac-filter">
                  <label className="ac-filter-container">
                    <input type="checkbox" />
                    <span className="checkmark">
                      <p>
                        {" "}
                        <i className="fa fa-snowflake-o"></i> A/C
                      </p>
                    </span>
                  </label>
                </div>
                <div className="ac-filter">
                  <label className="ac-filter-container">
                    <input type="checkbox" />
                    <span className="checkmark">
                      <p>
                        {" "}
                        <i className="fa fa-snowflake-o"></i> Luxury
                      </p>
                    </span>
                  </label>
                </div>
                <div className="ac-filter">
                  <label className="ac-filter-container">
                    <input type="checkbox" />
                    <span className="checkmark">
                      <p>
                        {" "}
                        <i className="fa fa-snowflake-o"></i> Non A/C
                      </p>
                    </span>
                  </label>
                </div>
                <div className="ac-filter">
                  <label className="ac-filter-container">
                    <input type="checkbox" />
                    <span className="checkmark">
                      {" "}
                      <p>
                        {" "}
                        <i className="fa fa-clock-o"></i> 5PM-11PM
                      </p>{" "}
                    </span>
                  </label>
                </div>
                <div className="ac-filter">
                  <label className="ac-filter-container">
                    <input type="checkbox" />
                    <span className="checkmark">
                      <p>
                        <i className="fa fa-filter"></i> Filter
                      </p>
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* --------- Responsive Sort and Filters ----------- */}
        <div
          className="responsive-sort-filters d-none"
          id="responsive-sort-filters"
        >
          <div className="sort-filters-container">
            <div className="sort-filters-heading">
              <h4>
                <i className="fa fa-arrow-left"></i> Sort and Filters
              </h4>
            </div>
          </div>
          <div className="sort-filters-tab-container">
            <ReactBootstrap.Tabs
              defaultActiveKey="sort"
              id="uncontrolled-tab-example"
            >
              <ReactBootstrap.Tab eventKey="sort" title="Sort">
                <div className="sort-tab-content">
                  <ul>
                    <li>
                      <ReactBootstrap.Form.Check
                        type="radio"
                        label="Departure AM To PM"
                        name="sortRadios"
                        id="formHorizontalRadios1"
                      />
                    </li>
                    <li>
                      <ReactBootstrap.Form.Check
                        type="radio"
                        label="Departure PM To AM"
                        name="sortRadios"
                        id="formHorizontalRadios2"
                      />
                    </li>
                    <li>
                      <ReactBootstrap.Form.Check
                        type="radio"
                        label="Bus Fare High To Low"
                        name="sortRadios"
                        id="formHorizontalRadios3"
                      />
                    </li>
                    <li>
                      <ReactBootstrap.Form.Check
                        type="radio"
                        label="Bus Fare Low To High"
                        name="sortRadios"
                        id="formHorizontalRadios4"
                      />
                    </li>
                  </ul>
                  <div className="done-btn">
                    <button>Done</button>
                  </div>
                </div>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab eventKey="filters" title="Filters">
                <div className="filter-tab-content">
                  {/* <BusFilter
                  data={busListMainObj.data.filters}
                  count={trips.length}
                  busFilter={busSelectedFilter}
                  applyFilter={applyFilter}
                /> */}
                  <div className="done-btn">
                    <button>Done</button>
                  </div>
                </div>
              </ReactBootstrap.Tab>
            </ReactBootstrap.Tabs>
          </div>
        </div>
        {/* --------- Responsive Boarding and Dropping Points ----------- */}
        <div className="responsive-boarding-dropping-points e-hide ">
          <div className="responsive-boarding-dropping-points-container">
            <div className="responsive-boarding-dropping-points-closer">
              <p>
                <i className="fa fa-times"></i>
              </p>
            </div>
          </div>
          <div className="responsive-boarding-dropping-points-tab-container">
            <ReactBootstrap.Tabs
              defaultActiveKey="boardingpoint"
              id="uncontrolled-tab-boardingpoint"
            >
              <ReactBootstrap.Tab
                eventKey="boardingpoint"
                title="Boarding Point"
              >
                <div className="boardingpoint-tab-content">
                  <ul>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab
                eventKey="Dropping Point"
                title="Dropping Point"
              >
                <div className="droppingpoint-tab-content">
                  <ul>
                    <li>
                      <div className="dropping-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="dropping-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="dropping-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                    <li>
                      <div className="boarding-point-checkbox">
                        <ReactBootstrap.Form.Check
                          type="radio"
                          name="boardingpoints"
                        />
                      </div>
                      <div className="boarding-point-address">
                        <h5>Hanumanpeta</h5>
                        <p>Press Club Opp Prathap Travels Office</p>
                      </div>
                      <div className="boarding-point-time">
                        <span>07:00 PM</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </ReactBootstrap.Tab>
            </ReactBootstrap.Tabs>
            <div className="continue-block">
              <div className="price-container">
                <p>Price:</p>
                <p>₹ 1025</p>
              </div>
              <div className="continue-btn">
                <button>Continue</button>
              </div>
            </div>
          </div>
        </div>
        {/* --------- Responsive Seat Selection ----------- */}
        <div className="seat-selection-container d-none">
          <div className="travels-name-container">
            <div className="travels-heading">
              <div className="travels-back-icon">
                <i className="fa fa-arrow-left"></i>
              </div>
              <div className="travels-name-text">
                <h5>SRS Travels</h5>
                <p>Vijayawada - Visakhapatnam</p>
              </div>
            </div>
          </div>
          <div className="travels-tab-container">
            <ReactBootstrap.Tabs
              defaultActiveKey="seats"
              id="uncontrolled-tab-seats"
            >
              <ReactBootstrap.Tab
                eventKey="seats"
                title="seats"
                className="lower-seater-tab-content"
              >
                <ReactBootstrap.Tabs
                  defaultActiveKey="lowerseat"
                  id="uncontrolled-tab-example"
                  variant="pills"
                  className="seat-layout-header"
                >
                  <ReactBootstrap.Tab
                    eventKey="lowerseat"
                    title="Lower"
                    className="lower-seat-content"
                  >
                    <div className="seat-layout-outer">
                      <div className="seat-layout-title-block">
                        <div className="seat-layout-title-block-inner-data">
                          <div className="steering-icon">
                            <i className="fa fa-circle"></i>
                          </div>
                          <div className="seat-layout-title-text">
                            <p>Lower</p>
                          </div>
                        </div>
                      </div>
                      <div className="seater-seating-arrangement">
                        <div className="inner-seating-container">
                          <div className="first-seater-row">
                            <ul>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                            </ul>
                          </div>
                          <div className="empty-row"></div>
                          <div className="second-seater-row">
                            <ul>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                              <li>
                                <img className="seat-layout-container" />
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="empty-row"></div>
                        <div className="empty-row"></div>
                        <div className="inner-sleeper-container">
                          <ul className="first-sleeper-row">
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ReactBootstrap.Tab>
                  <ReactBootstrap.Tab eventKey="upperseat" title="Upper">
                    {/* ------------ Upper Code ---------- */}
                    <div className="sleeper-layout-outer">
                      <div className="seat-layout-title-block">
                        <div className="seat-layout-title-block-inner-data">
                          <div className="seat-layout-title-text">
                            <p>Upper</p>
                          </div>
                        </div>
                      </div>
                      <div className="seater-seating-arrangement">
                        <div className="inner-sleeper-container">
                          <div className="first-seater-row">
                            <ul>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                            </ul>
                          </div>
                          <div className="empty-row"></div>
                          <div className="second-seater-row">
                            <ul>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                              <li>
                                <img className="sleeper-seat" />
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="empty-row"></div>
                        <div className="empty-row"></div>
                        <div className="inner-sleeper-container">
                          <ul className="first-sleeper-row">
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                            <li>
                              <img className="sleeper-seat" />
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </ReactBootstrap.Tab>
                </ReactBootstrap.Tabs>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab eventKey="aminities" title="aminities">
                <div className="amenities-tab">
                  <div className="aminities-tab-header">
                    <h4>Amenities</h4>
                  </div>
                  <div className="aminities-tab-body">
                    <ul>
                      <li>
                        <p>
                          <i className="fa fa-wifi"></i>
                          <span>WiFi</span>
                        </p>
                        <p>
                          <i className="fa fa-lightbulb-o"></i>
                          <span>Reading Light</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          <i className="fa fa-fire-extinguisher"></i>
                          <span>Fire Extinguisher</span>
                        </p>
                        <p>
                          <i className="fa fa-gavel"></i>
                          <span>Hammer</span>
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </ReactBootstrap.Tab>
              <ReactBootstrap.Tab eventKey="policy" title="policy">
                <div className="cancellation-block">
                  <div className="cancellation-fee">
                    <div className="cancellation-header">
                      <h5>
                        <i className="fa fa-ticket"></i>Cancellation Policy
                      </h5>
                    </div>
                    <ul className="cancellation-body">
                      <li>
                        <div>Time before Departure</div>
                        <div>Refund%</div>
                      </li>
                      <li>
                        <div>Cancelled within 36 Hrs</div>
                        <div>80%</div>
                      </li>
                      <li>
                        <div>Cancelled within 48 Hrs</div>
                        <div>50%</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </ReactBootstrap.Tab>
            </ReactBootstrap.Tabs>
            <div className="collapsed-data">
              <ReactBootstrap.Collapse>
                <ul className="responsive-price-list">
                  <li>
                    <p>Seat No.</p>
                    <p>u9</p>
                  </li>
                  <li>
                    <p>Base Fare</p>
                    <p>₹ 1024</p>
                  </li>
                  <li>
                    <p>GST</p>
                    <p>₹ 49</p>
                  </li>
                  <li>
                    <p>Total Fare</p>
                    <p>₹ 1073</p>
                  </li>
                </ul>
              </ReactBootstrap.Collapse>
            </div>
            <div className="travels-tab-continue-block">
              <div
                className="show-price-responsive"
                // onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                // aria-expanded={open}
              >
                <div className="collapse-icon">
                  <i className="fa fa-angle-up"></i>
                </div>
                <div>
                  <div className="price-container">
                    <p> Total Fare:</p>
                    <p>₹ 1025</p>
                  </div>
                  <div className="seat-no">
                    <span>Seat No : L1</span>
                  </div>
                </div>
              </div>
              <div className="continue-btn">
                <button>Continue</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="transport-container-block">
        <ANTD.Row gutter={[16, 16]}>
          <ANTD.Col xs={24} md={6} className="filters-block">
            <TransportFilter />
          </ANTD.Col>
          <ANTD.Col xs={24} md={18}>
            {/* <div className="results-card-block">
                            <div className="results-sort-block">
                                <p className="sort-header">Sort by:</p>
                                <ul className="sort-body">
                                    <li className="">
                                        <span >Dep Time</span>
                                    </li>
                                    <li className="">
                                        <span>Arr Time</span>
                                    </li>
                                    <li >
                                        <span>Bus Fare</span>
                                    </li>
                                    <li>
                                        <span>Duration</span>
                                    </li>
                                </ul>
                            </div>
                        </div> */}
            <div>
              <h2 className="results-found-text"> 5 Results Found</h2>
            </div>
            <div>
              <TransportCard />
            </div>
          </ANTD.Col>
        </ANTD.Row>
      </div>
    </div>
  );
};

export default TransportResults;
