import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Space,
  TimePicker,
  Modal,
} from "antd";
import ReCAPTCHA from "react-google-recaptcha";

import APIClient from "../../helpers/ApiClient";
import AutoCompleteSelect from "../../common/AutoCompleteSelect/AutoCompleteSelect";
import AirlineAutoCompleteSelect from "../../common/AutoCompleteSelect/AirlineAutoCompleteSelect";
import CountryList from "../../common/CountryList";
import Thankyou from "../Thankyou/Thankyou";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import "./Flightenquiry.scss";

const FlightEnquiry = () => {
  const { Option } = Select;
  const [isReturn, setIsReturn] = useState(null);
  const [form] = Form.useForm();
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "HH:mm";
  const [visible, setVisible] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const validateMessages = {
    required: "",
  };

  function handleCaptcha(value) {
    if (value) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }

  const submitForm = (value) => {
    if (isReturn == null) {
      message("Select one Way or Return option", 3);
      return;
    }

    let req = {
      ServiceType: 7,
      CarType: 0,
      CarCapacity: 0,
      Occasion: 0,
      BusType: 0,
      VisitPurpose: 0,
      BookingCurrencyFor: 0,
      Name: value.Name,
      Email: value.Email,
      ISDCode: value.ISDCode,
      ContactNumber: value.ContactNumber,
      AlternateNumber: "",
      TripType: value.TripType,
      SourceCity: value.SourceCity,
      DestinationCity: value.DestinationCity,
      PreferredClass: value.PreferredClass,
      DepartureDate: value.DepartureDate,
      DepartureTime: value.DepartureTime,
      PickUpLocation: "",
      DropLocation: "",
      EndDate: "",
      EndTime: "",
      ReturnDate: value.ReturnDate ? value.ReturnDate : "",
      ReturnTime: value.ReturnTime ? value.ReturnTime : "",
      OnwardFlightNumber: value.OnwardFlightNumber,
      OnwardFilghtName: value.OnwardFilghtName,
      ReturnFlightNumber: value.ReturnFlightNumber
        ? value.ReturnFlightNumber
        : "",
      ReturnFilghtName: value.ReturnFilghtName ? value.ReturnFilghtName : "",
      NumberOfPassengers: value.NumberOfPassengers,
      Remarks: value.Remarks,
      CreatedBy: 0,
      ModifiedBy: 0,
      NumberOfBuses: 0,
      Currency: "",
      Quantity: "",
      Amount: 0,
    };

    APIClient.post("admin/offlineEnquiry", req)
      .then((response) => {
        if (response.status == 200) {
          setVisible(true);
          form.resetFields();
        } else if (response.status == 404) {
          message.error("Not Found", 3);
        } else {
          message.error("Something Went Wrong", 3);
        }
      })
      .catch((error) => {});
  };

  const handleIsReturn = ({ value }) => {
    if (value == "roundTrip") setIsReturn(true);
    else setIsReturn(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  return (
    <>
      {visible ? (
        <Thankyou />
      ) : (
        <div className="manage-markup-section flight-enquiry-24">
          <div className="container">
            <Form
              layout="vertical"
              form={form}
              onFinish={submitForm}
              initialValues={{ ISDCode: "+91" }}
              validateMessages={validateMessages}
            >
              <Card className="cardRounded">
                <Row gutter={24}>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Name"
                      name="Name"
                      rules={[{ required: true }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Email"
                      name="Email"
                      rules={[{ required: true }, { type: "email" }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={8} xs={24}>
                    <Form.Item
                      label="Contact Number"
                      name="ContactNumber"
                      className="phno"
                      rules={[
                        {
                          required: true,
                          message: "Phone Number  Required",
                        },
                        {
                          minLength: 10,
                          maxLength: 10,
                          pattern: "^[0-9]{10}$",
                          message: "Must be 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore={
                          <Form.Item
                            style={{ width: "35%" }}
                            name="ISDCode"
                            className="phno"
                            rules={[
                              {
                                required: true,
                                message: "Phone Number Code Required",
                              },
                            ]}
                            noStyle
                          >
                            <Select
                              showSearch
                              // placeholder="Select Country"
                              style={{ width: "80px" }}
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {CountryList.map((item) => (
                                <Option
                                  key={item.dial_code}
                                  value={item.dial_code}
                                >
                                  {item.dial_code}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        }
                        className="inputbg"
                        placeholder="Enter Mobile No."
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
              <div className="card-gap">
                <Card bordered={false}>
                  <h5>Itinerary Details</h5>
                  <Row gutter={[16, 8]}>
                    <Col md={24}>
                      <Form.Item
                        label="Trip Type"
                        name="TripType"
                        rules={[{ required: true, message: "Required" }]}
                      >
                        <Radio.Group
                          onChange={({ target }) => handleIsReturn(target)}
                        >
                          <Radio value="oneWay">One Way</Radio>
                          <Radio value="roundTrip">RoundTrip</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col md={6} xs={24}>
                      <AutoCompleteSelect
                        formItemProps={{
                          label: "Source City",
                          name: "SourceCity",
                          rules: [{ required: true }],
                        }}
                        selectProps={{ placeholder: "Select Source" }}
                        api={"flights/updatedAirPort/search/"}
                        keytext="nameAirport"
                        keyvalue="codeIataAirport"
                      />
                    </Col>
                    <Col md={6} xs={24}>
                      <AutoCompleteSelect
                        formItemProps={{
                          label: "Destination City",
                          name: "DestinationCity",
                          rules: [{ required: true }],
                        }}
                        selectProps={{ placeholder: "Select Destination" }}
                        api={"flights/updatedAirPort/search/"}
                        keytext="nameAirport"
                        keyvalue="codeIataAirport"
                      />
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Preferred Class"
                        name="PreferredClass"
                        rules={[{ required: true }]}
                      >
                        <Select placeholder="Select Class">
                          <Option value="Economy">Economy</Option>
                          <Option value="premiumEconomy">
                            Economy Premium
                          </Option>
                          <Option value="Business">Business</Option>
                          <Option value="first">First Class</Option>
                          <Option value="premiumFirst">
                            Premium First Class
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {/* <Col md={12} xs={24}>
                   
                  </Col> */}
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Deperture Date"
                        name="DepartureDate"
                        rules={[{ required: true }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format={dateFormat}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Deperture Time"
                        name="DepartureTime"
                        rules={[{ required: true }]}
                      >
                        <TimePicker
                          style={{ width: "100%" }}
                          format={timeFormat}
                          placeholder="--:--"
                        />
                      </Form.Item>
                    </Col>

                    {isReturn ? (
                      <>
                        <Col md={6} xs={24}>
                          <Form.Item
                            label="Return Date"
                            name="ReturnDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              format={dateFormat}
                            />
                          </Form.Item>
                        </Col>
                        <Col md={6} xs={24}>
                          <Form.Item
                            label="Return Time"
                            name="ReturnTime"
                            rules={[{ required: true }]}
                          >
                            <TimePicker
                              style={{ width: "100%" }}
                              format={timeFormat}
                              placeholder="--:--"
                            />
                          </Form.Item>
                        </Col>
                      </>
                    ) : null}
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Flight Number"
                        name="OnwardFlightNumber"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {isReturn ? (
                      <Col md={6} xs={24}>
                        <Form.Item
                          label="Return Flight Number"
                          name="ReturnFlightNumber"
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    ) : null}

                    <Col md={6} xs={24}>
                      <AirlineAutoCompleteSelect
                        formItemProps={{
                          label: "Airline",
                          name: "OnwardFilghtName",
                          rules: [{ required: true }],
                        }}
                        selectProps={{ placeholder: "Search Airline Name" }}
                        api={"flights/airlineNames/search/"}
                        keytext="nameAirline"
                        keyvalue="codeIataAirline"
                      />
                    </Col>
                    {isReturn ? (
                      <Col md={6} xs={24}>
                        <AirlineAutoCompleteSelect
                          formItemProps={{
                            label: "Return Airline",
                            name: "ReturnFilghtName",
                            rules: [{ required: true }],
                          }}
                          selectProps={{ placeholder: "Search Airline Name" }}
                          api={"flights/airlineNames/search/"}
                          keytext="nameAirline"
                          keyvalue="codeIataAirline"
                        />
                      </Col>
                    ) : null}
                    <Col md={6} xs={24}>
                      <Form.Item
                        label="Number of Passengers"
                        name="NumberOfPassengers"
                        rules={[{ required: true }]}
                      >
                        <InputNumber
                          className="contacts-value-de"
                          min={1}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[0, 16]}>
                    <Col md={10} xs={24}>
                      <Form.Item
                        label="Remarks"
                        name="Remarks"
                        rules={[{ required: true }]}
                      >
                        <Input.TextArea rows={3} placeholder="....." />
                      </Form.Item>
                    </Col>

                    {/* <Col md={14} xs={24} className="flight-env-21">
                    <Space size="middle">
                      <Button type="primary" disabled>
                        Submit
                      </Button>
                      <Button type="primary" danger>
                        Cancel
                      </Button>
                    </Space>
                  </Col> */}
                  </Row>
                  <Row justify="center" className="my-3">
                    <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={handleCaptcha}
                    />
                  </Row>
                  <Row justify="center">
                    <Space size="middle">
                      <Button
                        disabled={buttonDisabled}
                        type="primary"
                        onClick={() => form.submit()}
                      >
                        Submit
                      </Button>
                      <Button type="primary" danger>
                        Cancel
                      </Button>
                    </Space>
                  </Row>
                </Card>
              </div>
            </Form>
          </div>
        </div>
      )}
      {/* <Modal
        visible={visible}
        title="Thank you for getting in touch!"
        onOk={handleOk}
        onCancel={handleOk}
        footer={[null]}
        className="successModalBox"
      >
        <Row justify="center">
          <Col>
            <div className="sucessModal">
              <CheckCircleOutlined className="modalIcon" />
              <h6 className="text-white">
                Your message has been successfully sent
              </h6>
              <p>
                We appreciate you submitting a contact form with us. Once we
                receive your
              </p>
              <p>
                information we'll respond as soon as we can. In the meantime,
                sit back, relax
              </p>
              <p>and enjoy the view</p>
            </div>
          </Col>
          <Col>
            <div className="socialLinks">
              <span className="sharetext">Share at</span>
              <span>
                <FacebookOutlined />
              </span>
              <span>
                <TwitterOutlined />
              </span>
              <span>
                <LinkedinOutlined />
              </span>
              <span>
                <MailOutlined />
              </span>
            </div>
          </Col>
        </Row>
      </Modal> */}
    </>
  );
};

export default FlightEnquiry;
