import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import "./cmspages.scss";
import ReactHtmlParser from "react-html-parser";

import { CaretRightOutlined } from "@ant-design/icons";
import APIClient from "../../helpers/ApiClient";

const { Panel } = Collapse;
const CmsPages = ({ cmsId, pageTitle }) => {
  const [CMSList, setCMSList] = useState([]);

  const getCMSList = () => {
    APIClient.get(`admin/cms/${cmsId}`)
      .then((response) => {
        if (response?.status == 200) {
          setCMSList(response.data);
        } else {
          setCMSList([]);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getCMSList();
  }, []);

  return (
    <div className="collapseCustomWhite">
      <div className="promo providerapi ourteam-values"></div>

      <div className="ourteam-bg">
        <div className="admin-container cms-pages-width">
          <h2 className="common-headings">{pageTitle}</h2>

          {/* <div className="check-accordions">
            <Collapse
              bordered={false}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              expandIconPosition="right"
              className="site-collapse-custom-collapse"
            >
              {CMSList.map((res, i) => (
                <Panel
                  header={res.Title}
                  key={i}
                  className="site-collapse-custom-panel"
                >
                  <div className="text-wrapper">
                    {ReactHtmlParser(res.Description)}
                  </div>
                </Panel>
              ))}
            </Collapse> 
 </div>*/}
          {CMSList.map((res) => (
            <div key={res.Id} className="cms-records-wrapper">
              <p className="title">{res.Title}</p>

              <div className="des-wrapper">
                {ReactHtmlParser(res.Description)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CmsPages;
