import React, { useEffect, useState } from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Button, Card, Col, Layout, Skeleton, Row } from "antd";
import APIClient from "../../../helpers/ApiClient";
import "./Busticketsuccess.scss";
import BusIcon from "../../../assets/images/trus-bus-tic.png";
const { Content } = Layout;

const Busticketsuccess = ({ busTicketsDetails }) => {
  const [ticket1, setTicket1] = useState({
    ArrivalTime: "",
    bookingRefNo: "",
    pnr: "",
    bookingStatus: "",
    operator: "",
    busTypeName: "",
    boardingPoint: "",
    droppingPoint: "",
    departureTime: "",
    sourceName: "",
    destinationName: "",
    JourneyDate: "",
    mobileNo: "",
    emailId: "",
    loading: false,
  });
  const [ticket2, setTicket2] = useState({
    ArrivalTime: "",
    bookingRefNo: "",
    pnr: "",
    bookingStatus: "",
    operator: "",
    busTypeName: "",
    boardingPoint: "",
    droppingPoint: "",
    departureTime: "",
    sourceName: "",
    destinationName: "",
    JourneyDate: "",
    mobileNo: "",
    loading: false,
  });

  const getTicketDetails = (ref, type) => {
    APIClient.get("buses/ticketDetails/?refNo=" + ref)
      .then((res) => {
        if (res.statusCode == 200) {
          if (res.data.bookingStatus != "Cancelled") {
            if (type === 1) {
              setTicket1({ ...res.data, loading: false });
            } else if (type === 2) {
              setTicket2({ ...res.data, loading: false });
            }
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (busTicketsDetails.refNo1) {
      setTicket1((prev) => ({ ...prev, loading: true }));
      getTicketDetails(busTicketsDetails.refNo1, 1);
    }
  }, [busTicketsDetails.refNo1]);

  useEffect(() => {
    if (busTicketsDetails.refNo2) {
      setTicket2((prev) => ({ ...prev, loading: true }));
      getTicketDetails(busTicketsDetails.refNo2, 2);
    }
  }, [busTicketsDetails.refNo2]);
  return (
    <Content className="admin-container cms-pages-width retrieve-linebar">
      <Card className="retrieve-booking busmtb">
        <Row gutter={[16, 16]}>
          <Col lg={12} md={16} sm={16} xs={24}>
            <h4 className="ticket-lti-bus">Bus ltinerary</h4>
            {/* sub--row--start */}
            {/* <h6 className="round-bus-tic">Oneway:</h6> */}
            {ticket1.loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : busTicketsDetails.refNo1 === null &&
              ticket1.bookingRefNo === "" ? (
              "Booking Failed"
            ) : (
              <div className="fic-tickets-data-bus">
                <div className="bd-bus-info">
                  <Row gutter={[8, 8]}>
                    <Col md={7} sm={24} xs={24}>
                      <p className="travel-bus">{ticket1.operator}</p>
                      <p className="subtitle">{ticket1.busTypeName}</p>
                    </Col>
                    <Col md={9} sm={12} xs={24}>
                      <div className="tic-indi-details-bus">
                        <span>{ticket1.sourceName}</span>
                        <span className="airline-tic-files-bus"></span>

                        <span>{ticket1.destinationName}</span>
                      </div>
                    </Col>
                    <Col md={8} sm={12} xs={24}>
                      <div className="airline-dte-tic-bus-add-value">
                        <span>{ticket1.departureTime}</span>

                        <ArrowRightOutlined />

                        <span>
                          {ticket1.ArrivalTime ? ticket1.ArrivalTime : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>

                <Row gutter={[8, 8]}>
                  <Col md={3} sm={6} xs={24}>
                    <img
                      className="bus-success-pay"
                      src={BusIcon}
                      alt="image bus "
                    />
                  </Col>

                  <Col md={12} sm={18} xs={24}>
                    <div className="airline-dte-tic-bus">
                      <div className="points">
                        <p className="title">Boarding point</p>
                        <p>{ticket1.boardingPoint}</p>
                      </div>

                      <ArrowRightOutlined />

                      <div className="points">
                        <p className="title">Dropping point</p>
                        <p>{ticket1.droppingPoint}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={9} sm={24} xs={24}>
                    <p className="pnr">PNR: {ticket1.pnr}</p>
                  </Col>
                </Row>
              </div>
            )}

            {busTicketsDetails.twoWay ? (
              <>
                <h6 className="round-bus-tic">Roundtrip:</h6>
                {ticket2.loading ? (
                  <Skeleton active paragraph={{ rows: 2 }} />
                ) : busTicketsDetails.refNo2 === null &&
                  ticket2.bookingRefNo === "" ? (
                  "Booking Failed"
                ) : (
                  <div className="fic-tickets-data-bus">
                    <div className="bd-bus-info">
                      <Row gutter={[16, 16]}>
                        <Col md={7} sm={24} xs={24}>
                          <p className="travel-bus">{ticket2.operator}</p>
                          <p className="subtitle">{ticket2.busTypeName}</p>
                        </Col>
                        <Col md={9} sm={12} xs={24}>
                          <div className="tic-indi-details-bus">
                            <span>{ticket2.sourceName}</span>
                            <span className="airline-tic-files-bus"></span>

                            <span>{ticket2.destinationName}</span>
                          </div>
                        </Col>
                        <Col md={8} sm={12} xs={24}>
                          <div className="airline-dte-tic-bus-add-value">
                            <span>{ticket2.departureTime}</span>

                            <ArrowRightOutlined />

                            <span>
                              {ticket2.ArrivalTime ? ticket2.ArrivalTime : ""}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>

                    <Row gutter={[8, 8]}>
                      <Col md={3} sm={6} xs={24}>
                        <img
                          className="bus-success-pay"
                          src={BusIcon}
                          alt="bus image1"
                        />
                      </Col>

                      <Col md={12} sm={18} xs={24}>
                        <div className="airline-dte-tic-bus">
                          <div className="points">
                            <p className="title">Boarding point</p>
                            <p>{ticket2.boardingPoint}</p>
                          </div>

                          <ArrowRightOutlined />

                          <div className="points">
                            <p className="title">Dropping point</p>
                            <p>{ticket2.droppingPoint}</p>
                          </div>
                        </div>
                      </Col>

                      <Col md={9} sm={24} xs={24}>
                        <p className="pnr">PNR: {ticket2.pnr}</p>
                      </Col>
                    </Row>
                  </div>
                )}
              </>
            ) : null}
          </Col>

          <Col lg={12} md={8} sm={8} xs={24} className="flight-email-dd-bus">
            {ticket1.loading ? (
              <Skeleton active paragraph={{ rows: 2 }} />
            ) : busTicketsDetails.refNo1 === null &&
              ticket1.bookingRefNo === "" ? null : (
              <>
                <div className="paymet-booking-tic">
                  <h4>Bus Ticket Booking Payment Successful</h4>
                  <p>
                    A confirmation email has been sent your provided email
                    address
                  </p>
                  <p>Ref-No : {busTicketsDetails.refNo1}</p>
                  <p>
                    Status : <span className="succes-pay-value">Success</span>
                  </p>
                </div>

                <Row>
                  <Col lg={12} md={18} xs={24}>
                    <Button className="tic-retrive-book" htmlType="submit">
                      <Link to={`bus/ticket?ref=${busTicketsDetails.refNo1}`}>
                        Retrieve Booking
                      </Link>
                    </Button>
                  </Col>
                </Row>
              </>
            )}
            {busTicketsDetails.twoWay ? (
              ticket2.loading ? (
                <Skeleton active paragraph={{ rows: 2 }} />
              ) : busTicketsDetails.refNo2 === null &&
                ticket2.bookingRefNo === "" ? null : (
                <>
                  <div
                    style={{ marginTop: "10px" }}
                    className="paymet-booking-tic"
                  >
                    <p>Ref-No : {busTicketsDetails.refNo2}</p>
                    <p>
                      Status : <span className="succes-pay-value">Success</span>
                    </p>
                  </div>

                  <Row>
                    <Col lg={12} md={18} xs={24}>
                      <Button className="tic-retrive-book" htmlType="submit">
                        <Link to={`bus/ticket?ref=${busTicketsDetails.refNo2}`}>
                          Retrieve Booking
                        </Link>
                      </Button>
                    </Col>
                  </Row>
                </>
              )
            ) : null}
          </Col>
        </Row>
      </Card>
    </Content>
  );
};

export default Busticketsuccess;
