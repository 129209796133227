import React from "react";
import * as ANTD from "antd";
import "./Thankyou.scss";
import { Row, Col } from "antd";
import {
  CheckCircleOutlined,
  FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";

const Thankyou = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;

  function htmlDecode(html) {
    return html.replace(/&([a-z]+);/gi, (match, entity) => {
      const entities = {
        amp: "&",
        apos: "'",
        gt: ">",
        lt: "<",
        nbsp: "\xa0",
        quot: '"',
      };
      entity = entity.toLowerCase();
      if (entities.hasOwnProperty(entity)) {
        return entities[entity];
      }
      return match;
    });
  }
  const scriptCode = `<script type="text/javascript">gtag("event", "conversion", {
    send_to: "AW-10838377079/JEppCLiCl5ADEPeEkrAo",
    value: 10.0,
    currency: "INR",
  });
  </script>`;

  return (
    <div className="collapseCustomWhite">
      <div className=" providerapi">
        <ANTD.Layout className="ourteam-bg">
          <div className="thankyou-panel">
            <div className="page-width-1">
              <ANTD.Card>
                <Row justify="center">
                  <Col md="24" xs={24}>
                    <img
                      className="img-thanku"
                      src={
                        require("../../assets/images/thank-you-img.jpg").default
                      }
                      alt="search-img"
                    />

                    <h3>Thank you for getting in touch</h3>

                    <div className="sucessModal-v3">
                      <CheckCircleOutlined className="modalIcon-v3" />
                      <h6>Your message has been successfully sent</h6>
                      <p>
                        We appreciate you submitting a contact form with us.
                        Once we receive your information we'll respond as soon
                        as we can. In the meantime, sit back, relax and enjoy
                        the view
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row justify="center">
                  <Col md="24" xs={24}>
                    <div className="socialLinks-v3">
                      <span className="sharetext">Share at</span>
                      <span>
                        <FacebookOutlined />
                      </span>
                      <span>
                        <TwitterOutlined />
                      </span>
                      <span>
                        <LinkedinOutlined />
                      </span>
                      <span>
                        <MailOutlined />
                      </span>
                    </div>
                  </Col>
                </Row>
              </ANTD.Card>
            </div>
          </div>
        </ANTD.Layout>
      </div>
      <div dangerouslySetInnerHTML={{ __html: htmlDecode(scriptCode) }} />
    </div>
  );
};

export default Thankyou;
