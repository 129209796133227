import {
  CaretRightOutlined,
  CloseOutlined,
  // EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
  Upload,
} from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../../components/Bookingreports/Bookingreports.scss";
import ApiClient from "../../helpers/ApiClient";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import UpdatedComponent from "../ExportExcel";
import {
  allColumns,
  activitiesColumns,
  busColumns,
  dateFormat,
  oriDateFormat,
  hotelColumns,
  renderReffrence,
  getStatus,
  buildPackageColumns,
} from "./superReportHelper";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";
let uiDateFormat = "DD-MM-YYYY";
const { Option } = Select;
const { Panel } = Collapse;
const { Content } = Layout;

const SuperBookingreports = (props) => {
  const [reportRole, setReportRole] = useState(0);
  const [travelType, setTravelType] = useState(0);
  const [agentList, setAgentList] = useState([]);
  const [form] = Form.useForm();

  const [showTable, setShowTable] = useState(false);

  const [isDateDisplay, setIsDateDisplay] = useState(false);

  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [showCardInfo, setShowCardInfo] = useState(false);
  const [showPnrModelVisible, setShowPnrModelVisible] = useState(false);

  const [loading, setLoading] = useState(false);
  // const [services, setServices] = useState({
  //   isVisible: false,
  //   additionalServices: [],
  // });
  const [cardInfo, setCardInfo] = useState({
    cardType: "",
    cardNumber: "",
    expirationDate: "",
    CVV: "",
  });
  const [modalVisible, setModalVisible] = useState(false);

  const [loadingAttachments, setLoadingAttachments] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [bookingRefNo, setbookingRefNo] = useState("");
  const [toDisableDate, setToDisableDate] = useState(moment());
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    setParamValue(params);
  }, []);

  useEffect(() => {
    getProviders();
  }, []);
  const getProviders = () => {
    ApiClient.get("admin/b2b/user")
      .then((res) => {
        if (res.status == 200) {
          let filterProvider = res.data.filter((item) => item.Status === 1);
          setAgentList(res.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const setParamValue = (params) => {
    if (params && params.edit === "edit") {
      handleTimeAndDate(params.dateType);
      form.setFieldsValue({
        travelType: Number(params.travelType),
        journeyDate: moment(params.journeyDate, oriDateFormat),
        bookingStatus: Number(params.bookingStatus),
      });
      setShowTable(true);
      setDataSource([]);
      setTableHeaders(Number(params.travelType));
      getBookingListData(params);
    }
  };

  const closeSearch = () => {
    setShowSearchBox(false);
  };

  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );

  const searchData = () => {
    setShowSearchBox(true);
  };
  const handleExcel = () => {
    const excelData = dataSource;
    props.exportExcel(excelData, "Booking_Reports");
  };

  // const getCardInfo = (info) => {
  //   console.log(info, "info");
  //   //Decrypt
  //   const bytes = CryptoJs.AES.decrypt(info, creditKey);
  //   const decryptedText = JSON.parse(bytes.toString(CryptoJs.enc.Utf8));

  //   return (
  //     <span
  //       style={{ cursor: "pointer", color: "red" }}
  //       onClick={() => {
  //         setCardInfo((prev) => ({
  //           ...prev,
  //           ...decryptedText,
  //           expirationDate: moment(decryptedText?.expirationDate).format(
  //             "MM/YYYY"
  //           ),
  //         }));
  //         setShowCardInfo(true);
  //       }}
  //     >
  //       <EyeOutlined />
  //     </span>
  //   );
  // };

  const updatePnr = (value) => {
    let params = {
      PNR: value.pnr,
      BookingRefNo: bookingRefNo,
    };
    ApiClient.post("admin/booking/updatePnrOffline", params)
      .then((res) => {
        setShowPnrModelVisible(false);
        if (res.status === 200) {
          message.success(res.message, 3);
        } else {
          message.error(res.message, 3);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // const ShowUserAttachModal = (id) => {
  //   // setIsEdit(false);
  //   setModalVisible(true);
  //   getUserAttachments(id);
  // };
  const flightColumns = [
    {
      title: "Bked By",
      dataIndex: "BookedBy",
    },
    {
      title: "Provider",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "RefNo",
      dataIndex: "referenceNumber",
      render: (text, record) => renderReffrence(text, record),
    },

    {
      title: "opr",
      dataIndex: "operator",
      key: "operator",
    },
    {
      title: "Update No",
      dataIndex: "referenceNumber",
      render: (text, record) =>
        record.BookingStatus == "BLOCKED" || record.bookOffline == 1 ? (
          <span>
            <span
              style={{ cursor: "pointer", color: "red" }}
              onClick={() => {
                setbookingRefNo(text);
                // setShowPnrModelVisible(true);
              }}
            >
              {record.BookingStatus == "CONFIRMED" ? (
                ""
              ) : (
                <Link to={`bookoffline?mode=edit&ref=${text}`}>Update PNR</Link>
              )}
            </span>
          </span>
        ) : (
          ""
        ),
    },
    {
      title: "Src",
      dataIndex: "from",
      render: (text, record) => {
        return record.type == "Flight"
          ? record?.oneWaySegment[0]?.origin
          : text;
      },
    },
    {
      title: "Dest",
      dataIndex: "to",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = record?.oneWaySegment.length;
          return record?.oneWaySegment[length - 1]?.destination;
        } else {
          return text;
        }
      },
    },
    {
      title: "Trip Type",
      dataIndex: "tripType",
    },
    {
      title: "Bkng Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      render: (text) => moment(text, oriDateFormat).format(dateFormat),
    },
    {
      title: "Jrny Date",
      dataIndex: "journeyDate",
      key: "journeyDate",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : null),
    },
    {
      title: "Arr Trml",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight" ? text[0]?.arrivalTerminal : text,
    },
    {
      title: "Arr Date",
      dataIndex: "oneWaySegment",
      render: (text, record) =>
        record.type == "Flight" && text[0]?.arrivalDateTime
          ? moment(text[0].arrivalDateTime, oriDateFormat).format(dateFormat)
          : text,
    },
    {
      title: "Dept Trml",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return text[length - 1]?.departureTerminal;
        } else {
          return text;
        }
      },
    },
    {
      title: "Dept Date",
      dataIndex: "oneWaySegment",
      render: (text, record) => {
        if (record.type == "Flight") {
          let length = text.length;
          return moment(
            text[length - 1]?.departureDateTime,
            oriDateFormat
          ).format(dateFormat);
        } else {
          return text;
        }
      },
    },
    {
      title: "Bkng Status",
      dataIndex: "BookingStatus",
      render: (status) => getStatus(status),
    },

    {
      title: "Pax Name ",
      dataIndex: "guestName",
    },
    {
      title: "Amt(Rs.)",
      dataIndex: "totalPrice",
    },
    {
      title: "Pax Email",
      dataIndex: "guestEmaiId",
    },
    {
      title: "Pax Mobile ",
      dataIndex: "guestMobileNo",
    },
    {
      title: "Adults",
      dataIndex: "pax",
      render: (text) => text.adults,
    },
    {
      title: "Childs",
      dataIndex: "pax",
      render: (text) => text.childCount,
    },
    {
      title: "Infant",
      dataIndex: "pax",
      render: (text) => text.infantCount,
    },
  ];

  /* Creating service dynamic column headers */
  const setTableHeaders = (travelType) => {
    switch (travelType) {
      case 1:
        setColumns(flightColumns);
        break;
      case 2:
        setColumns(hotelColumns);
        break;
      case 3:
        setColumns(busColumns);
        break;
      case 4:
        setColumns(activitiesColumns);
        break;
      case 5:
        setColumns(buildPackageColumns);
        break;
      default:
        setColumns(allColumns);
    }
  };

  const getBookingListData = (values) => {
    setLoading(true);

    ApiClient.get("admin/myBookings/report", values)
      .then((res) => {
        setLoading(false);

        if (res.statusCode == 200) {
          setDataSource(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    let prevData = dataSource;
    if (searchVal.length > 0) {
      const searchResults = dataSource.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setDataSource(searchResults);
    } else {
      setDataSource(prevData);
    }
  };

  const handleTimeAndDate = (value) => {
    if (value === 5) {
      setIsDateDisplay(true);
    } else {
      setIsDateDisplay(false);
    }
  };
  const onFormSubmit = (values) => {
    if (values.options == 5) {
      values.fromDate = moment(values.fromDate).format(oriDateFormat);
      values.toDate = moment(values.toDate).format(oriDateFormat);
    } else {
      values.fromDate = "";
      values.toDate = "";
    }
    if (values.journeyDate) {
      values.journeyDate = moment(values.journeyDate).format(oriDateFormat);
    } else {
      values.journeyDate = "";
    }

    setDataSource([]);
    setTableHeaders(values.travelType);

    getBookingListData(values);
  };

  const uploadProps = {
    name: "image",
    // action: uploadActionUrl,
    data: { category: "userattachment" },
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        console.log(file, fileList);
        setFileList(fileList);
      }
    },
    defaultFileList: [...fileList],
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "download ",
      showRemoveIcon: true,
    },
  };

  // const getUserAttachments = (id) => {
  //   setLoadingAttachments(true);
  //   let params = {
  //     UserID: "",
  //     Refrenceno: id,
  //   };
  //   ApiClient.get("admin/userAttachmentfilter", params)
  //     .then((res) => {
  //       if (res.status === 200) {
  //         if (res.data.length === 0) {
  //           setFileList([]);
  //           setLoadingAttachments(false);
  //         } else {
  //           mapToFileList(res.data[0]);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };

  // const mapToFileList = (data) => {
  //   let files = [];
  //   let i = 1;
  //   console.log(data);
  //   for (i; i < 7; i++) {
  //     if (data[`Attachment${i}`]) {
  //       let rec = {
  //         uid: i,
  //         name: "slide.jpg",
  //         status: "done",
  //         url: BASE + data[`Attachment${i}`].substring(1),
  //         response: {
  //           status: 200,
  //           data: {
  //             filepaths: [data[`Attachment${i}`]],
  //           },
  //         },
  //       };
  //       files.push(rec);
  //     }
  //   }
  //   setFileList(files);

  //   setLoadingAttachments(false);
  // };

  const BookingReportsHeader = () => (
    <>
      Booking Reports <HelpInfoHelper screenName={"/admin/reports"} />
    </>
  );

  const disabledFutureDate = (currentDate) => {
    return currentDate >= moment();
  };
  const onChangeFromDate = (momentdate, _) => {
    setToDisableDate(momentdate);
  };
  const disabledSelectedDate = (currentDate) => {
    return currentDate < moment(toDisableDate).startOf("day");
  };

  return (
    <div>
      <div className="manage-markup-section">
        <Content className="container-fluid">
          <Row>
            <Col span={24}>
              <div className="card-bt-gap">
                <div>
                  <Collapse
                    bordered={false}
                    expandIcon={({ isActive }) => (
                      <Tooltip
                        placement="top"
                        title={isActive ? "Show" : "Hide"}
                      >
                        <CaretRightOutlined rotate={isActive ? 90 : -90} />
                      </Tooltip>
                    )}
                    expandIconPosition="right"
                    className="panel_wrapper"
                    defaultActiveKey={["1"]}
                  >
                    <Panel header={<BookingReportsHeader />} key="1">
                      <Form
                        form={form}
                        layout={"vertical"}
                        initialValues={{
                          options: 3,
                          bookingStatus: "",
                          emailId: "",
                          journeyDate: "",
                          paxName: "",
                          phoneNumber: "",
                          referenceNo: "",
                          fareType: "",
                          travelType: 3,
                          Role: 0,
                          tripType: "",
                        }}
                        onFinish={onFormSubmit}
                      >
                        <Row gutter={16}>
                          <Col className="gutter-row" md={8} xs={24}>
                            <Form.Item
                              label="Time and Date"
                              name="options"
                              rules={[{ required: true }]}
                            >
                              <Select
                                placeholder="Please select"
                                onChange={handleTimeAndDate}
                              >
                                <Option value={1}>Last One Hour</Option>
                                <Option value={2}>Yesterday</Option>
                                <Option value={3}>Last Week</Option>
                                <Option value={5}>Custom Dates</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {isDateDisplay ? (
                            <>
                              <Col md={8} sm={12} xs={24}>
                                <Form.Item label="From Date" name="fromDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    format={uiDateFormat}
                                    disabledDate={disabledFutureDate}
                                    onChange={(date, dateString) =>
                                      onChangeFromDate(date, dateString)
                                    }
                                  />
                                </Form.Item>
                              </Col>

                              <Col md={8} sm={12} xs={24}>
                                <Form.Item label="To Date" name="toDate">
                                  <DatePicker
                                    format="DD-MM-YYYY"
                                    style={{ width: "100%" }}
                                    disabledDate={disabledSelectedDate}
                                  />
                                </Form.Item>
                              </Col>
                            </>
                          ) : null}

                          <Col md={8} xs={24}>
                            <Form.Item label="Reports of" name="Role">
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setReportRole(val)}
                              >
                                <Option value={0}>All</Option>
                                <Option value={2}>Users</Option>
                                <Option value={4}>Guest</Option>
                                <Option value={5}>Partner</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          {reportRole === 5 && (
                            <Col md={8} xs={24}>
                              <Form.Item label="Partner" name="UserId">
                                <Select  showSearch
                  
                  
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    } placeholder="Select Partner">  
                                  {agentList.map((data, index) => (
                                    <Option key={index} value={data.UserID}>
                                      {data.userDetails.AgencyName +"-"+ data.userDetails.FirstName}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </Col>
                          )}

                          <Col className="gutter-row" md={8} xs={24}>
                            <Form.Item label="Service" name="travelType">
                              <Select
                                placeholder="Select"
                                onSelect={(val) => setTravelType(val)}
                              >
                                <Option value={0}>All</Option>
                                <Option value={1}>Flights</Option>
                                <Option value={2}>Hotels</Option>
                                <Option value={3}>Bus</Option>
                                <Option value={4}>Activities</Option>
                                {/* <Option value={5}>BuildPackage</Option> */}
                              </Select>
                            </Form.Item>
                          </Col>

                          {travelType === 1 ? (
                            <>
                              <Col md={8} xs={24}>
                                <Form.Item label="Coupan Type" name="fareType">
                                  <Select placeholder="Select Travel Type">
                                    <Option value="PUBLISH">Publish</Option>
                                    <Option value="GENERAL">General </Option>
                                    <Option value="COUPONFARE">
                                      Coupon Fare
                                    </Option>
                                    <Option value="CORPORATEFARE">
                                      Corporate Fare
                                    </Option>
                                    <Option value="SMEFARE">SME Fare</Option>
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col md={8} xs={24}>
                                <Form.Item label="Trip Type" name="tripType">
                                  <Select placeholder="Select">
                                    <Option value="oneWay">One way</Option>
                                    <Option value="roundTrip">
                                      Round Trip
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </Col>{" "}
                            </>
                          ) : null}

                          <Col md={8} xs={24}>
                            <Form.Item
                              label="Reference number"
                              name="referenceNo"
                            >
                              <Input placeholder="Enter Reference Number" />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Form.Item label="Journey Date" name="journeyDate">
                              <DatePicker
                                style={{ width: "100%" }}
                                placeholder="Select Journey date"
                                format={dateFormat}
                              />
                            </Form.Item>
                          </Col>

                          {/* <Col md={8} xs={24}>
                              <Form.Item label="PNR Number" name="pnr">
                                <Input />
                              </Form.Item>
                            </Col> */}
                          <Col md={8} xs={24}>
                            <Form.Item
                              label="Pax Email"
                              name="emailId"
                              rules={[{ type: "email" }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col md={8} xs={24}>
                            <Form.Item
                              label="Pax Mobile"
                              name="phoneNumber"
                              rules={[
                                {
                                  minLength: 10,
                                  maxLength: 10,
                                  pattern: "^[0-9]{10}$",
                                  message: "Must be 10 digits",
                                },
                              ]}
                            >
                              <Input placeholder="" />
                            </Form.Item>
                          </Col>

                          <Col md={8} xs={24}>
                            <Form.Item
                              label="Booking Status"
                              name="bookingStatus"
                            >
                              <Select placeholder="Please Select">
                                <Option value={2}>Blocked</Option>
                                <Option value={3}>Confirmed</Option>
                                <Option value={4}>Cancelled</Option>
                                {/* <Option value={4}>Cancel Sucess</Option>
                                  <Option value={5}>Refund Sucess</Option>
                                  <Option value={6}>Refund Failed</Option> */}
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="reports-btns">
                          <Button
                            type="primary"
                            htmlType="submit"
                            onClick={() => setShowTable(true)}
                          >
                            Search
                          </Button>
                          <Button
                            type="danger"
                            onClick={() => {
                              setReportRole(0);
                              setTravelType(0);
                              form.resetFields();
                            }}
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            </Col>
            {showTable && (
              <Col span={24}>
                <div className="card-bt-gap">
                  <Card bordered={false}>
                    {dataSource.length > 0 ? (
                      <>
                        <div className="card-add-heading">
                          <div className="rows-count">
                            <div>
                              <h5>View Reports {dataSource.length}</h5>
                            </div>
                          </div>
                          <div className="action-images">
                            {showSearchBox && (
                              <Input
                                placeholder="Search"
                                onChange={(e) => searchTableData(e)}
                                suffix={closeSearchInput}
                                style={{ padding: "0px 12px" }}
                              />
                            )}
                            &nbsp;&nbsp;
                            <img
                              src={search}
                              alt="search"
                              onClick={(e) => searchData()}
                            />
                            <img
                              src={excel}
                              alt="excel"
                              onClick={handleExcel}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            <Table
                              scroll={{ x: true }}
                              bordered
                              dataSource={dataSource}
                              columns={columns}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ["25", "50", "100", "125"],
                              }}
                            />
                          </div>
                        </div>
                      </>
                    ) : loading ? (
                      <Spin
                        size="large"
                        tip="Loading..."
                        style={{ width: "100%" }}
                      />
                    ) : (
                      <b>No Results Found!</b>
                    )}
                  </Card>
                </div>
              </Col>
            )}
          </Row>

          <Modal
            className="admin_modal"
            visible={showCardInfo}
            title="Card Details"
            style={{ textAlign: "center" }}
            onCancel={(e) => {
              setShowCardInfo(false);
            }}
            footer={false}
          >
            <div style={{ textAlign: "left" }}>
              <p>
                <b>Card Type : </b> {cardInfo.cardName ? cardInfo.cardName : ""}
              </p>
              <p>
                <b>Card Number : </b>{" "}
                {cardInfo.cardNumber ? cardInfo.cardNumber : ""}
              </p>
              <p>
                <b>Expiration Date : </b>{" "}
                {cardInfo.expirationDate ? cardInfo.expirationDate : ""}
              </p>
              <p>
                <b>Security Code : </b> {cardInfo.CVV ? cardInfo.CVV : ""}
              </p>
            </div>
          </Modal>

          <Modal
            title="Files"
            className="promo-modal-header"
            visible={modalVisible}
            onCancel={(e) => setModalVisible(false)}
            width={"400px"}
            // footer={[
            //   <Button key="back" onClick={() => setModalVisible(false)}>
            //     Cancel
            //   </Button>,
            //   <Button
            //     key="submit"
            //     type="primary"
            //     onClick={attachmentform.submit}
            //   >
            //     {isEdit ? "Update" : "Add"}
            //   </Button>,
            // ]}
            footer={[null]}
          >
            {loadingAttachments ? (
              <p>Loading... </p>
            ) : fileList.length === 0 ? (
              "No Files"
            ) : (
              <Form
                // form={attachmentform}
                layout="vertical"
                // onFinish={handleAttachment}
              >
                <Form.Item
                  // label="Select Files"
                  // name="attachment"
                  rules={[{ required: true, message: "Images are required" }]}
                >
                  <Upload {...uploadProps}>
                    {/* {fileList.length >= 6 ? null : (
                        <Button icon={<UploadOutlined />}>Upload</Button>
                      )} */}
                  </Upload>
                </Form.Item>
              </Form>
            )}
          </Modal>
          <Modal
            title={[
              <div>
                <h6 style={{ marginBottom: "0px" }}>
                  <strong>Update PNR</strong>
                </h6>
              </div>,
            ]}
            className="promo-modal-header"
            visible={showPnrModelVisible}
            onOk={(e) => setShowPnrModelVisible(false)}
            onCancel={(e) => setShowPnrModelVisible(false)}
            footer={[null]}
          >
            <Form layout={"vertical"} form={form} onFinish={updatePnr}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="Airline PNR"
                    name="pnr"
                    rules={[{ required: true, message: "Please enter role" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Update
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </Content>
      </div>
    </div>
  );
};

export default UpdatedComponent(SuperBookingreports);
