import React, { useState, useEffect, useContext } from "react";
import { Button, Card, Modal, Radio, Table } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { useAuthContext } from "../../../common/providers/AuthProvider";
import moment from "moment";
import {
  createDataSourceFromFlightsAndColumns,
  createMatrixColumns,
} from "./AirlineMatrixHelper";
import "./Sort.scss";

const Sort = ({
  updateFlightAirSearchRespObj,
  flightAirSearchResp,
  airlineMatrixReset,
  ResetAirlineMatrix,
  selectedTripType,
  showNetFare,
  setShowNetFare,
}) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const {
    isLogin: { agent },
    user,
  } = useAuthContext();
  const [sorting, setSorting] = useState("price");
  const [sortDirection, setSortDirection] = useState("asc");

  const [matrixSelected, setMatrixSelected] = useState({
    visible: false,
    count: "",
  });
  let [columns, setColumns] = useState([]);
  let [dataSource, setDataSource] = useState([]);
  const [airlineMatrix, setAirlineMatrix] = useState(false);

  const handleChange = (e) => {
    const selSorting = e.target.value;
    setSorting(selSorting);
    setSortDirection("asc");
    sortFlights(selSorting, "asc");
  };

  const handleClick = (e) => {
    const selSorting = e.target.value;
    if (sorting === selSorting) {
      let dir = sortDirection === "asc" ? "desc" : "asc";
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
      sortFlights(selSorting, dir);
    }
  };

  useEffect(() => {
    sortFlights("price", "asc");
  }, []);

  useEffect(() => {
    if (airlineMatrixReset) {
      setMatrixSelected({
        visible: false,
        count: "",
      });
      ResetAirlineMatrix(false);
    }
  }, [airlineMatrixReset]);

  const getSortIcon = (val) => {
    return val === "asc" ? <CaretUpOutlined /> : <CaretDownOutlined />;
  };

  let flightDetails =
    selectedTripType === 1
      ? flightAirSearchResp.flightDetails
      : flightAirSearchResp.ibFlightDetails;

  const sortFlights = (selSorting, dir) => {
    if (!flightDetails) return;

    let sortedList = [];

    if (
      flightAirSearchResp.airTravelType == "roundTrip" &&
      flightAirSearchResp.resultsType == "Combined"
    ) {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
                flightA.flightDetails[0].airLineName,
                flightB.flightDetails[0].airLineName,
                selSorting
              )
            : compareFlights(
                flightB.flightDetails[0].airLineName,
                flightA.flightDetails[0].airLineName,
                selSorting
              );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultPublishFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultPublishFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightDetails[0].flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightDetails[0].flightSegments[
              flightA.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightDetails[0].flightSegments[
              flightB.flightDetails[0].flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        }
      });
    } else {
      sortedList = flightDetails.sort((flightA, flightB) => {
        if (selSorting === "airline") {
          return dir === "asc"
            ? compareFlights(
                flightA.airLineName,
                flightB.airLineName,
                selSorting
              )
            : compareFlights(
                flightB.airLineName,
                flightA.airLineName,
                selSorting
              );
        } else if (selSorting === "price") {
          const priceA = flightA.fareFamilies.fareFamilies[0].adultPublishFare;
          const priceB = flightB.fareFamilies.fareFamilies[0].adultPublishFare;

          return dir === "asc"
            ? compareFlights(priceA, priceB, selSorting)
            : compareFlights(priceB, priceA, selSorting);
        } else if (selSorting === "departure") {
          const depTimeA = new Date(
            flightA.flightSegments[0].departureDateTime
          ).getTime();
          const depTimeB = new Date(
            flightB.flightSegments[0].departureDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(depTimeA, depTimeB, selSorting)
            : compareFlights(depTimeB, depTimeA, selSorting);
        } else if (selSorting === "arrival") {
          const arrTimeA = new Date(
            flightA.flightSegments[
              flightA.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();
          const arrTimeB = new Date(
            flightB.flightSegments[
              flightB.flightSegments.length - 1
            ].arrivalDateTime
          ).getTime();

          return dir === "asc"
            ? compareFlights(arrTimeA, arrTimeB, selSorting)
            : compareFlights(arrTimeB, arrTimeA, selSorting);
        }
      });
    }

    if (selectedTripType === 1) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: [...sortedList],
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: [...sortedList],
      });
    }
  };

  const compareFlights = (a, b, selSorting) => {
    if (selSorting === "airline" || selSorting === "price") {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    } else {
      return a - b;
    }
  };

  const airMatrixSelected = () => {
    if (
      flightAirSearchResp.airTravelType == "roundTrip" &&
      flightAirSearchResp.resultsType == "Combined"
    ) {
    } else {
      if (columns.length > 0 && dataSource.length > 0) {
        if (Object.keys(dataSource[0]) > 1) {
          return;
        }
      }
      let temp_columns = createMatrixColumns(flightDetails);
      let temp_dataSource = createDataSourceFromFlightsAndColumns(
        columns,
        flightDetails
      );

      temp_columns = temp_columns.map((item) => {
        return {
          ...item,
          title: (
            <div
              className="cursor_pointer airlineTitle"
              onClick={() => {
                headerRowClicked(item.dataIndex);
                if (item.title === "All Flights") {
                  setMatrixSelected({ visible: false, count: "" });
                } else {
                  setMatrixSelected({ visible: true, count: item.count });
                }
              }}
            >
              {item.title} <i className="fa fa-filter" aria-hidden="true"></i>{" "}
              <span className="matrixCount">({item.count})</span>
            </div>
          ),
        };
      });

      setColumns(temp_columns);
      setDataSource(temp_dataSource);
    }
  };
  useEffect(() => {
    //dont render matrix once done
    airMatrixSelected();
  }, [flightDetails]);

  const showModal = () => {
    setAirlineMatrix(true);
  };
  const handleOk = (e) => {
    setAirlineMatrix(false);
  };

  const handleCancel = (e) => {
    setAirlineMatrix(false);
  };

  const filterFLightsByNames = (name) => {
    let new_flights = flightDetails.map((flight) => {
      let visible = false;
      if (name == "type") {
        visible = true;
      } else {
        if (name == flight.airLineName) {
          visible = true;
        }
      }
      return {
        ...flight,
        isVisible: visible,
      };
    });

    if (selectedTripType === 1) {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,
        flightDetails: new_flights,
      });
    } else {
      updateFlightAirSearchRespObj({
        ...flightAirSearchResp,

        ibFlightDetails: new_flights,
      });
    }
  };

  const headerRowClicked = (name) => {
    filterFLightsByNames(name);
    setAirlineMatrix(false);
  };

  const getMinumumTimeFromCriteria = (selectedSort) => {
    let flight = [];
    let fare = 0;
    if (
      flightAirSearchResp.airTravelType === "roundTrip" &&
      flightAirSearchResp.resultsType === "Combined"
    ) {
      flight = flightAirSearchResp.flightDetails[0].flightDetails[0];
      fare =
        flightAirSearchResp.flightDetails[0].fareFamilies.fareFamilies[0]
          .adultPublishFare;
    } else {
      flight =
        selectedTripType === 1
          ? flightAirSearchResp.flightDetails[0]
          : flightAirSearchResp.ibFlightDetails[0];
      fare =
        selectedTripType === 1
          ? flightAirSearchResp.flightDetails[0].fareFamilies.fareFamilies[0]
              .adultPublishFare
          : flightAirSearchResp.ibFlightDetails[0].fareFamilies.fareFamilies[0]
              .adultPublishFare;
    }

    switch (selectedSort) {
      case "price":
        return fare;
      case "departure":
        return moment(flight.flightSegments[0].departureDateTime).format(
          "hh:mm A"
        );
      case "arrival":
        return moment(
          flight.flightSegments[flight.flightSegments.length - 1]
            .arrivalDateTime
        ).format("hh:mm A");
      case "airline":
        return flight.airLineName;
    }
    return " ";
  };
  return (
    <div className="sort-block">
      <Card>
        <div className="radio-btns-sort">
          <div className="sort-text">
            <span>Sort by: </span>
          </div>

          <Radio.Group onChange={handleChange} value={sorting}>
            <Radio value={"airline"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Airline</p>
                  <p className="total-fare-filter">
                    <span>
                      {sorting === "airline" ? getSortIcon(sortDirection) : ""}
                    </span>
                    {getMinumumTimeFromCriteria("airline")}
                  </p>
                </div>
              </span>
            </Radio>
            <Radio value={"price"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Price</p>
                  <p className="total-fare-filter">
                    <span>
                      {sorting === "price" ? getSortIcon(sortDirection) : ""}
                    </span>{" "}
                    <span>
                      {activeCurrency +
                        " " +
                        currencyValue(getMinumumTimeFromCriteria("price"))}
                    </span>
                  </p>
                </div>
              </span>
            </Radio>
            <Radio value={"departure"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Departure</p>
                  <p className="total-fare-filter">
                    <span>
                      {sorting === "departure"
                        ? getSortIcon(sortDirection)
                        : ""}
                    </span>{" "}
                    <span>{getMinumumTimeFromCriteria("departure")}</span>
                  </p>
                </div>
              </span>
            </Radio>
            <Radio value={"arrival"} onClick={handleClick}>
              <span className="checkmark">
                <div className="active-background">
                  <p className="price-type">Arrival</p>
                  <p className="total-fare-filter">
                    <span>
                      {sorting === "arrival" ? getSortIcon(sortDirection) : ""}
                    </span>{" "}
                    <span>{getMinumumTimeFromCriteria("arrival")}</span>
                  </p>
                </div>
              </span>
            </Radio>
          </Radio.Group>
          {flightAirSearchResp.airTravelType === "roundTrip" &&
          flightAirSearchResp.resultsType === "Combined" ? null : (
            <div className="matrixWrapper">
              <span onClick={showModal} className="airline-text">
                Airline Matrix{" "}
                {matrixSelected.visible ? (
                  <>
                    <i className="fa fa-filter" aria-hidden="true"></i>{" "}
                    <span className="matrixCount">
                      ({matrixSelected.count})
                    </span>{" "}
                  </>
                ) : null}
              </span>
            </div>
          )}
          {agent && user?.Role?.RoleLevel === 3 ? (
            <div
              className="netfareButton"
              onClick={() => setShowNetFare(!showNetFare)}
            >
              <Button> {showNetFare ? "Hide" : "Show"} Net Fare</Button>
            </div>
          ) : null}
        </div>
      </Card>

      <Modal
        className="air-trix-new"
        title="Airline Matrix"
        visible={airlineMatrix}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"800px"}
      >
        <Table
          scroll={{ x: true }}
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className="airline-matrix-table "
          bordered
        />
      </Modal>
    </div>
  );
};

export default Sort;
