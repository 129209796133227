import React from "react";

import { Col, Row, Layout, Card } from "antd";
import moment from "moment";
import getBusTicketPrice from "./busHelper";
import { getBusBookingStatus } from "../AllTicketStatus";
import { SafetyCertificateOutlined } from "@ant-design/icons";
import "../NewTicket.scss";
import CancellationPolicy from "../../../pages/Buses/BusResults/BusResultCards/CancellationPolicy";

const TicketBus = ({ ticketData }) => {
  let {
    baseFare,
    taxes,
    serviceCharge,
    convamount,
    grandTotal,
    discount,
    insuranceTotal,
  } = getBusTicketPrice(ticketData);
  let dateFormat = "MMM, DD-YYYY";

  return (
    <>
      <span className="tic-gredient-f"></span>
      <div className="cms-pages-width ourteam-bg">
        <Row>
          <Col md={24} xs={24}>
            <p className="tic-city-name-d2">
              {ticketData.sourceName} To {ticketData.destinationName}
            </p>
            <Card className="card-tic-f3">
              <Row gutter={[16, 8]}>
                <Col md={10} sm={24} xs={24}>
                  <Row gutter={[16, 8]}>
                    <Col md={10} sm={10} xs={24}>
                      <div className="city-f3">
                        <p className="word-break">{ticketData.sourceName}</p>
                        <span className="word-break">
                          {ticketData.operator}
                        </span>
                      </div>
                    </Col>
                    <Col md={4} sm={4} xs={24}>
                      <div className="city-f3 px-2">
                        <p>
                          <i
                            className="fa fa-long-arrow-right"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    </Col>
                    <Col md={10} sm={10} xs={24}>
                      <div className="city-f3">
                        <p className="word-break">
                          {ticketData.destinationName}
                        </p>
                        <span>
                          {/* {ticketData.tripType == "TwoWay"
                          ? "RoundTrip"
                          : "Oneway"} */}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={14} sm={24} xs={24}>
                  <Row>
                    <Col md={7} sm={8} xs={24}>
                      <div className="city-f4">
                        <p>
                          {moment(ticketData.JourneyDate, "DD-MM-YYYY").format(
                            dateFormat
                          )}
                        </p>
                        <span>Journey Date</span>
                      </div>
                    </Col>
                    <Col md={17} sm={16} xs={24}>
                      <div className="city-f4">
                        <p className="word-break">{ticketData.pnr}</p>
                        <span>PNR/Booking No</span>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="border-hr"></div>
              <Row className="row-top-f4">
                <Col md={7} xs={24} className="city-f4">
                  <p>{ticketData.bookingRefNo}</p>
                  <span>Ticket Ref Number</span>
                </Col>
                <Col md={7} xs={24} className="city-f4">
                  {getBusBookingStatus(ticketData.BookingStatus)}

                  <span>Status</span>
                </Col>
                <Col md={6} xs={24} className="city-f4">
                  <p>{ticketData.noOfSeats}</p>
                  <span>Seat(s)</span>
                </Col>
                <Col md={4} xs={24} className="city-f4">
                  <p>Paid</p>
                  <span>Payment Status</span>
                </Col>
              </Row>
            </Card>

            <Card className="card-tic-f1">
              <Row className="info-flight-t22">
                <Col md={24} xs={24} className="city-tt-f4">
                  <p>Bus Details</p>
                  <span>
                    Here are the details of your bus from{" "}
                    {ticketData.sourceName} to {ticketData.destinationName} on{" "}
                    {moment(ticketData.JourneyDate, "DD-MM-YYYY").format(
                      dateFormat
                    )}
                  </span>
                </Col>
              </Row>

              <Row>
                <Col md={8} xs={24} className="city-tt-f5">
                  <div className="logo-f3-text">
                    <div className="city-tt-f4">
                      <p className="word-break">{ticketData.operator}</p>
                      <span>Operator </span>
                    </div>
                  </div>
                </Col>
                <Col md={16} xs={24} className="city-tt-f4">
                  <div className="city-from-d3">
                    <div className="city-f55 ">
                      <p className="word-break">{ticketData.sourceName}</p>
                      <span>{ticketData.departureTime}</span>
                    </div>
                    <div className="city-f55">
                      <p className="dotted-line-flight"></p>
                    </div>
                    <div className="city-f55">
                      <p className="word-break">{ticketData.destinationName}</p>
                      <span>{ticketData.ArrivalTime}</span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="baggagge-bottom-sec">
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <p>Bus Type</p>
                      <span className="word-break">
                        {ticketData.busTypeName}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <p>Departure Time</p>
                      <span>{ticketData.departureTime}</span>
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <p>Boarding Point</p>
                      <span className="word-break">
                        {ticketData.boardingPoint}
                      </span>
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={24} className="city-tt-f6">
                  <div className="baggagge-f4">
                    <div className="bus-ticket-info-type">
                      <p>Droping Point </p>
                      <span className="word-break">
                        {ticketData.droppingPoint}
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Contact Details</p>
                </Col>
              </Row>

              <Row>
                <Col md={8} sm={12} xs={24} className="contact-person-d4">
                  <span>Name</span>
                  <p>
                    {ticketData.passengerInfo[0].titles}{" "}
                    {ticketData.passengerInfo[0].names}
                  </p>
                </Col>

                <Col md={8} sm={12} xs={24} className="contact-person-d4">
                  <span> Mobile Number</span>
                  <p>{ticketData.mobileNo}</p>
                </Col>
                <Col md={8} sm={12} xs={24} className="contact-person-d4">
                  <span>Email</span>
                  <p>{ticketData.EmailId}</p>
                </Col>
              </Row>
            </Card>
            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Passengers Details</p>
                </Col>
              </Row>
              {ticketData.passengerInfo.length > 0
                ? ticketData.passengerInfo.map((item) => (
                    <Row>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Name</span>
                        <p>
                          {item.titles} {item.names}
                        </p>
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Age</span>
                        <p> {item.ages}</p>
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span>Seat No. </span>
                        <p>{item.seatNos}</p>
                      </Col>
                      <Col md={6} xs={24} className="contact-person-d4">
                        <span> Gender</span>
                        <p>{item.genders === "M" ? "Male" : "Female"}</p>
                      </Col>
                    </Row>
                  ))
                : null}
            </Card>

            {ticketData?.insuranceRequired === 1 &&
            ticketData.insuranceData &&
            ticketData?.insuranceData?.serviceType === 3 ? (
              <Card className="insurance-section card-tic-f2">
                <h5 style={{ fontSize: "16px" }}>THE SMART INSURANCE COVER</h5>
                <p>EASY CLAIM PROCESS | NO QUESTIONS ASKED</p>

                <Row gutter={[16, 16]} align="stretch" className="mt-4">
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_1}
                    </div>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_2}
                    </div>
                  </Col>
                  <Col md={8} sm={24} xs={24}>
                    <div className="insurance-box">
                      {ticketData?.insuranceData.description_box_3}
                    </div>
                  </Col>
                </Row>

                <div className="insurance-coverage">
                  <SafetyCertificateOutlined />
                  <span>
                    Insurance Coverage Amount :{" "}
                    {ticketData?.insuranceData.insuranceCoverage}
                  </span>
                </div>
              </Card>
            ) : null}
            <Card className="card-tic-f2">
              <Row className="details-airlines-contact">
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Fare Details</p>
                </Col>
              </Row>

              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Base Fare</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {baseFare}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p> Taxes</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {taxes}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Service Charges</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {serviceCharge}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Convenience Fee</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {convamount}</p>
                </Col>
              </Row>
              {ticketData?.insuranceRequired === 1 &&
              ticketData.insuranceData ? (
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Insurance Amount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <p>INR {insuranceTotal}</p>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Discount</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {discount}</p>
                </Col>
              </Row>
              <Row>
                <Col md={18} xs={12} className="contact-person-d4">
                  <p>Total</p>
                </Col>
                <Col md={6} xs={12} className="contact-person-d4">
                  <p>INR {grandTotal}</p>
                </Col>
              </Row>

              {ticketData?.BookingStatus != 3 ? (
                <Row>
                  <Col md={18} xs={12} className="contact-person-d4">
                    <p>Refund Amount</p>
                  </Col>
                  <Col md={6} xs={12} className="contact-person-d4">
                    <p>INR {ticketData.RefundAmount}</p>
                  </Col>
                </Row>
              ) : null}
            </Card>
            <Card className="card-tic-f2">
              <Row>
                <Col md={20} xs={24} className="city-tt-f4">
                  <p>Terms and Conditions</p>
                </Col>
              </Row>

              <Row className="details-airlines-contact booking-flight-details-info pt-0">
                <Col md={24} xs={24} className="contact-person-d4">
                  <div className="termCon_wrapper">
                    <p>
                      1. ShopYourTrip* is ONLY a bus ticket agent. It does not
                      operate bus services of its own. In order to provide a
                      comprehensive choice of bus operators, departure times and
                      prices to customers, it has tied up with many bus
                      operators.ShopYourTrip advice to customers is to choose
                      bus operators they are aware of and whose service they are
                      comfortable with{" "}
                    </p>
                    <div className="description">
                      <p className="title">
                        ShopYourTrip responsibilities include:
                      </p>
                      <p>
                        (1) Issuing a valid ticket (a ticket that will be
                        accepted by the bus operator) for its network of bus
                        operators
                      </p>

                      <p>
                        (2) Providing refund and support in the event of
                        cancellation
                      </p>
                      <p>
                        (3) Providing customer support and information in case
                        of any delays / inconvenience
                      </p>
                      <p className="title">
                        ShopYourTrip responsibilities do NOT include:
                      </p>
                      <p>
                        (1) The bus operator's bus not departing / reaching on
                        time.
                      </p>
                      <p> (2) The bus operator's employees being rude. </p>
                      <p>
                        (3) The bus operator's bus seats etc not being up to the
                        customer's expectation.
                      </p>
                      <p>
                        (4) The bus operator canceling the trip due to
                        unavoidable reasons.
                      </p>
                      <p>
                        (5) The baggage of the customer getting lost / stolen /
                        damaged.
                      </p>
                    </div>
                    <p>
                      2. The departure time mentioned on the ticket are only
                      tentative timings. However the bus will not leave the
                      source before the time that is mentioned on the ticket.
                    </p>
                    <p>
                      3. Passengers are required to furnish the following at the
                      time of boarding the bus:
                    </p>
                    <div className="description">
                      <p>
                        Passengers are required to furnish the following at the
                        time of boarding the bus:
                      </p>

                      <p>
                        (1) A copy of the ticket (A print out of the ticket or
                        the print out of the ticket e-mail).
                      </p>

                      <p> (2) A valid identity proof </p>
                      <p>
                        Failing to do so, they may not be allowed to board the
                        bus.
                      </p>
                    </div>
                    <p>
                      4. In case one needs the refund to be credited back to
                      his/her bank account, please write your cash coupon
                      details to * The discount
                    </p>

                    <p>
                      5. Delivery charges (if any), will not be refunded in the
                      event of ticket cancellation.
                    </p>

                    <p>
                      6. In case a booking confirmation e-mail and sms gets
                      delayed or fails because of technical reasons or as a
                      result of incorrect e-mail ID / phone number provided by
                      the user etc, a ticket will be considered 'booked' as long
                      as the ticket shows up on the confirmation page of
                      ShopYourTrip
                    </p>
                    <p>
                      7. Grievances and claims related to the bus journey should
                      be reported to ShopYourTrip support team within 2 days of
                      your travel date.
                    </p>
                    <div className="cancellation-wrapper">
                      <p className="policytitle">Cancellation Policy</p>
                      <CancellationPolicy
                        data={ticketData.cancellationPolicy}
                        cancellation={ticketData.partialCancellationAllowed}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default TicketBus;
