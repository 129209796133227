import * as ANTD from "antd";
import React from "react";
//import { Link } from "react-feather";
import { Link } from "react-router-dom";
import "./buildpackagesearchbox.scss";
const BuilpackageSearchBox = () => {
  const { Header, Footer, Sider, Content } = ANTD.Layout;
  const [formLayout, setFormLayout] = React.useState("vertical");
  const formItemLayout =
    formLayout === "vertical"
      ? {
          labelCol: { span: 24 },
          wrapperCol: { span: 23 },
        }
      : null;

  return (
    <div className="hotels_search_box buildpackage-searchbox">
      <div className="hotels-wrapper">
        <ANTD.Form
          {...formItemLayout}
          layout={formLayout}
          initialValues={{ layout: formLayout }}
        >
          <ANTD.Row className="build-alert-sec">
            <ANTD.Col md={10} xs={24} className="build-cols">
              <ANTD.Form.Item
                label="Select Destination"
                rules={[{ required: true }]}
              >
                <ANTD.Input
                  className="biuild-input"
                  placeholder="Enter destination"
                  size={"large"}
                />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={10} xs={24} className="build-cols">
              <ANTD.Form.Item
                label="Number Of Nights"
                rules={[{ required: true }]}
              >
                <ANTD.Select
                  className="biuild-input"
                  placeholder="No. of Nights"
                >
                  <ANTD.Select.Option value="demo">
                    No. of Nights
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">1 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">2 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">3 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">4 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">5 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">6 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">7 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">8 Nights</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">9 Nights</ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={4} xs={24} className="build-cols">
              <ANTD.Button className="build-pk-btn-top">
                Add&nbsp;<i className="fa fa-plus" aria-hidden="true"></i>
              </ANTD.Button>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col md={8} xs={24} className="build-cols">
              <ANTD.Form.Item label="Selete Date" rules={[{ required: true }]}>
                <ANTD.DatePicker
                  className="biuild-input"
                  placeholder="Select Date"
                  size={"large"}
                />
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={8} xs={24} className="build-cols">
              <ANTD.Form.Item label="Nationality" rules={[{ required: true }]}>
                <ANTD.Select className="biuild-input" placeholder="Nationality">
                  <ANTD.Select.Option value="demo">
                    Nationality
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">Afghan</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">Albanian</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">Andorran</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">
                    Azerbaijani
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">
                    Australian
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">
                    Barbadian
                  </ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">Canadian</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">India</ANTD.Select.Option>
                  <ANTD.Select.Option value="demo">Chinese</ANTD.Select.Option>
                </ANTD.Select>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={8} xs={24} className="build-cols">
              <ANTD.Form.Item label="Rooms" rules={[{ required: true }]}>
                <ANTD.Input />
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>

          <ANTD.Row>
            <ANTD.Col md={6} xs={24} className="build-cols">
              <ANTD.Form.Item>
                <ANTD.Button className="build-pk-btn">
                  Select Services
                </ANTD.Button>
              </ANTD.Form.Item>
            </ANTD.Col>

            <ANTD.Col md={3} xs={24} className="build-cols ha-build">
              <ANTD.Form.Item>
                <ANTD.Checkbox>Activities</ANTD.Checkbox>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={3} xs={24} className="build-cols ha-build">
              <ANTD.Form.Item>
                <ANTD.Checkbox>Hotels</ANTD.Checkbox>
              </ANTD.Form.Item>
            </ANTD.Col>
            <ANTD.Col md={6} xs={24} className="build-cols">
              <ANTD.Form.Item>
                <Link to="/combinedbuildpkg">
                  <ANTD.Button className="build-pk-btn">Search Now</ANTD.Button>
                </Link>
              </ANTD.Form.Item>
            </ANTD.Col>
          </ANTD.Row>
        </ANTD.Form>
      </div>
    </div>
  );
};
export default BuilpackageSearchBox;
