import React, { useContext } from "react";
import { Col, Row, Skeleton } from "antd";
import { useCurrencyContext } from "../../../common/providers/CurrencyProvider";
import { Link } from "react-router-dom";
import moment from "moment";
import slide_1_1 from "../../../assets/images/HomeCarousel/slide_1_1.jpg";

const GridView = ({ dealsData, isLoading }) => {
  const { activeCurrency, currencyValue } = useCurrencyContext();
  const BASE = process.env.REACT_APP_BASE_URL;
  const gridSpan = [16, 8, 8, 8, 8, 8, 16, 8, 8, 8];
  let tomorrowDate = moment().add(5, "days").format("YYYY-MM-DD");
  let counter = -1;
  function getColspan() {
    counter++;
    if (counter > 9) counter = -1;
    return gridSpan[counter];
  }

  return (
    <div className="deal-list-item">
      {isLoading ? (
        <div className="deals-list-container">
          <Row gutter={[8, 8]}>
            {gridSpan.map((item, index) => {
              let colSpan = getColspan();
              return (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: colSpan }}
                >
                  <div
                    className="slide-wrapper"
                    style={{
                      backgroundColor: "rgba(245,246,247,0.23)",
                      padding: "1rem",
                    }}
                  >
                    <Skeleton active />
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      ) : (
        <div className="deals-list-container">
          <Row gutter={[1, 4]}>
            {dealsData.map((deal, index) => {
              let colSpan = getColspan();

              return (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: colSpan }}
                >
                  <Link
                    to={`/flight/results?adultCount=1&airTravelType=oneWay&cabinclassName=Economy&childCount=0&infantCount=0&departureDateTime=${tomorrowDate}&flightDateFlex=1&origin=${deal.FromAirportCode}&destination=${deal.ToAirportCode}`}
                  >
                    <div className="slide-wrapper">
                      <div className="slide-over-layer"></div>
                      <div className="slide-hoverlayer"></div>
                      <img
                        className="carousel-img"
                        src={
                          deal.Image
                            ? BASE + deal.Image.substring(1)
                            : slide_1_1
                        }
                        alt="slide_1_1"
                      />
                      <div className="content-on-slide">
                        <div className="top-text">
                          <p>{deal.FromCountryID.Country}</p>
                        </div>
                        <div className="bottom-text">
                          <div className="hr-top">
                            <p>
                              From: {deal.FromAirportName} (
                              {deal.FromAirportCode})
                            </p>
                            <div className="text-flight">
                              <i className="fa fa-plane"></i>
                              <h5>
                                {deal.ToAirportName} ({deal.ToAirportCode})
                              </h5>
                            </div>
                          </div>
                          {/* <hr /> */}
                          <div className="price-bottom-hr">
                            <h6>FROM </h6>
                            <span> {currencyValue(deal.FromAmount)}</span>
                            <p>{activeCurrency}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      )}

      {/* <div className="deals-list-container">
        <Row>
          <Col span={8} className="d-none">
            <div className="slide-wrapper">
              <div className="slide-over-layer"></div>
              <div className="slide-hoverlayer"></div>
              <img className="carousel-img" src={slide_4_2} alt="slide_4_2" />
              <div className="content-on-slide">
                <div className="top-text">
                  <p>United states of america</p>
                </div>
                <div className="bottom-text">
                  <div className="hr-top">
                    <p>from: Denver (CO) (DEN)</p>
                    <div className="text-flight">
                      <i className="fa fa-plane"></i>
                      <h5> Baltimore (MD)</h5>
                    </div>
                  </div>
                  {/* <hr /> }
                  <div className="price-bottom-hr">
                    <h6>FROM </h6>
                    <span>55</span>
                    <p>USD</p>
                  </div>
                  <div className="check-details-btn d-none">
                    <Button className="btn-styles">
                      Check details
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col span={8} className="d-none">
            <div>
              <div className="carousel-img custom-slide bulb-slide">
                <p>
                  City break? Check out <br />
                  deals to Capitals
                </p>
                <div className="hotel-letter cash-icon-carousel">
                  {/* <i className="fa fa-lightbulb"></i> }
                  <i className="fa fa-money" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </Col>
          <Col span={8} className="d-none">
            <div className="slide-wrapper">
              <div className="slide-over-layer"></div>
              <div className="slide-hoverlayer"></div>
              <img className="carousel-img" src={slide_4_3} alt="slide_4_3" />
              <div className="content-on-slide">
                <div className="top-text">
                  <p>from: Atlanta (GA) (ATL)</p>
                </div>
                <div className="bottom-text">
                  <div className="hr-top">
                    <p> Houston (TX)</p>
                    <div className="text-flight">
                      <i className="fa fa-plane"></i>
                      <h5> Houston</h5>
                    </div>
                  </div>
                  {/* <hr /> }
                  <div className="price-bottom-hr">
                    <h6>FROM </h6>
                    <span>64</span>
                    <p>USD</p>
                  </div>
                  <div className="check-details-btn d-none">
                    <Button className="btn-styles">
                      Check details
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div> 
      <div className="show-more-btn">
        <button>
          Show me more deals <i className="fa fa-angle-down"></i>
        </button>
        <div className="scroll-to-top">
          <p>Back to top of page</p>
        </div>
      </div>*/}
    </div>
  );
};
export default GridView;
