import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Input,
  Row,
  Table,
  message,
  Switch,
  Popconfirm,
  Popover,
} from "antd";
import {
  DeleteOutlined,
  CloseOutlined,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import excel from "../assets/vector-icons/excel.png";
import search from "../assets/vector-icons/search.png";
import exchange from "../assets/vector-icons/exchange.png";
import ApiClient from "../../../src/helpers/ApiClient";
import UpdatedComponent from "../ExportExcel";
import "./SubscriberReport.scss";
import HelpInfoHelper from "../../common/HelpInfoHelper/HelpInfoHelper";

const SubscriberReport = (props) => {
  const [subscriberList, setSubscriberList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);

  useEffect(() => {
    getSubscriberList();
  }, []);

  const getSubscriberList = () => {
    ApiClient.get("admin/subscriberReports")
      .then((response) => {
        if (response.status == 200) {
          setSubscriberList(response.data);
          setTableData(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DeleteSubscribtion = (Record) => {
    var Id = Record.ID;
    ApiClient.delete("admin/subscriberReport/" + `${Id}`)
      .then((res) => {
        if (res.status == 200) {
          message.success("Deleted  successfully", 3);
          getSubscriberList();
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const searchTableData = (e) => {
    var searchVal = e.target.value;
    if (searchVal.length > 0) {
      const searchResults = subscriberList.filter(function (item) {
        if (item != undefined) {
          return JSON.stringify(item)
            .toLowerCase()
            .includes(searchVal.toLowerCase());
        }
      });
      setTableData(searchResults);
    } else {
      setTableData(subscriberList);
    }
  };
  const closeSearch = () => {
    setShowSearchBox(false);
  };
  const closeSearchInput = (
    <div>
      <CloseOutlined onClick={closeSearch} />
    </div>
  );
  const searchData = (searchData) => {
    setShowSearchBox(true);
  };

  let lastIndex = 0;
  const uniqueKey = () => {
    lastIndex++;
    return lastIndex;
  };

  const columns = [
    {
      title: "Sr. No.",
      render: (item, record, index) => <>{index + 1}</>,
    },
    {
      title: "Email ID",
      dataIndex: "Email",

      sorter: {
        compare: (a, b) => a.Email.length - b.Email.length,
      },
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (status) => (
        <Switch
          checkedChildren="Subscribe"
          unCheckedChildren="Unsubscribe"
          defaultChecked={!status}
        />
      ),
    },
    {
      title: "Delete",
      render: (text, record) => (
        <Popconfirm
          title="Are u sure to delete?"
          onConfirm={() => DeleteSubscribtion(record)}
        >
          <DeleteOutlined
            style={{ color: "red", fontSize: "20px", textAlign: "center" }}
          />
        </Popconfirm>
      ),
    },
  ];

  const handleExcel = () => {
    const excelData = tableData.map((item, i) => {
      return {
        S_No: i + 1,
        Email: item.Email,
        Status: item.Status,
      };
    });

    props.exportExcel(excelData, "Subscriber Report");
  };

  return (
    <div className="manage-markup-section">
      <div className="container-fluid">
        <Row>
          <Col span={24}>
            <div className="card-bt-gap">
              <Card bordered={false}>
                <div className="card-add-heading">
                  <div className="rows-count">
                    <div>
                      <h5>
                        Subscriber Report{" "}
                        <HelpInfoHelper
                          screenName={"/admin/subscriberreport"}
                        />
                      </h5>
                      <p>{subscriberList.length} rows found !</p>
                    </div>
                  </div>
                  <div className="action-images">
                    {showSearchBox && (
                      <Input
                        placeholder="Search"
                        onChange={(e) => searchTableData(e)}
                        suffix={closeSearchInput}
                        style={{ padding: "0px 12px" }}
                      />
                    )}
                    &nbsp;&nbsp;
                    <img
                      src={search}
                      alt="search"
                      onClick={(e) => searchData(subscriberList)}
                    />
                    <img src={excel} alt="excel" onClick={handleExcel} />
                    <img
                      src={exchange}
                      alt="exchange"
                      onClick={() => getSubscriberList()}
                    />
                  </div>
                </div>

                <div>
                  <Table
                    className="table-scroll-none"
                    bordered
                    rowKey={uniqueKey}
                    dataSource={tableData}
                    columns={columns}
                    pagination={{
                      defaultPageSize: 25,
                      showSizeChanger: true,
                      pageSizeOptions: ["25", "50", "100", "125"],
                    }}
                  />
                </div>
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default UpdatedComponent(SubscriberReport);
